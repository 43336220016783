const handleValidation = (state, fields) => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (field === 'CompanyCode') {
      const numbers = /^[0-9]+$/;
      if (!state[field].match(numbers)) {
        validate[field] = {
          error: true,
          message: `Please input numeric characters only!!`,
        };
      } else {
        delete validate[field];
      }
    } else {
      delete validate[field];
    }
    return validate;
  });

  return validate;
};

export default handleValidation;
