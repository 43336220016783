const userRegisterField = [
  'Firstname',
  'Lastname',
  'Email',
  'Username',
  'Password',
  'ConfirmPassword',
];

export default userRegisterField;
