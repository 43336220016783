import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import TransportationCard from './TransportationCard';
import styles from './../../styles';
const NextArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' next'} onClick={onClick} />;
};
const PrevArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' prev'} onClick={onClick} />;
};
class TransportationCarousel extends React.Component {
  render() {
    const settings = {
      className: 'slider variable-width',
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    let { transportationSum, selectTransportation } = this.props;
    return (
      <div style={styles.marginLeft5px}>
        <Slider {...settings}>
          {selectTransportation.length > 0 &&
            transportationSum.length > 0 &&
            transportationSum.map((day, index) => {
              let id = day.data[0].ServiceItemId;
              let serviceType = day.data[0].Description;
              let duration = day.data[0].ItemName.split('-')[1];
              let overtime = day.data.filter(
                e => e.Description.toLowerCase().indexOf('overtime') !== -1
              ).length;
              let item = selectTransportation.filter(
                e => e.ServiceItemId === id
              )[0];
              return (
                <div key={index} style={styles.widthCard}>
                  <TransportationCard
                    image={item ? item.ImageUrl : ''}
                    name={item ? item.Name : ''}
                    rating={item ? item.TransportationRatingName : ''}
                    seat={item ? item.TransportationSeatTypeName : ''}
                    duration={duration}
                    typeService={serviceType}
                    day={day.day}
                    date={day.date}
                    overtime={overtime}
                  />
                </div>
              );
            })}
        </Slider>
      </div>
    );
  }
}
NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
TransportationCarousel.propTypes = {
  transportationSum: PropTypes.array,
  selectTransportation: PropTypes.array,
};
export default TransportationCarousel;
