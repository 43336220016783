import {
  GET_USER_INFO,
  RESET_GET_USER_INFO_STATUS,
} from './constant/actionTypes';
import { getUserInfoApi } from './../../../../api/userApi';
const getUserInfo = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_INFO,
      payload: getUserInfoApi(),
    });
  };
};
const resetGetUserInfoStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_USER_INFO_STATUS });
  };
};
export { getUserInfo, resetGetUserInfoStatus };
