//-----library-----
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
//-----component-----
import HeaderRoomCard from './HeaderRoomCard';
import DescRoomCard from './DescriptionRoomCard';
//-----style------
import styles from './styles';

const RoomCard = props => {
  const [openDesc, setOpenDesc] = useState(false);

  const handleChange = () => {
    setOpenDesc(prevState => !prevState);
  };

  let {
    desc,
    amenities,
    name,
    type,
    capacity,
    image,
    images,
    serviceType,
    isPromo,
    room,
    onClose,
    closeModalAccommodation,
    setAccommodationRoom,
    isInstantConfirmation,
  } = props;
  return (
    <Segment.Group style={styles.segmentNoPadding}>
      <HeaderRoomCard
        handleChange={handleChange}
        openDesc={openDesc}
        name={name}
        type={type}
        capacity={capacity}
        image={image}
        images={images}
        isPromo={isPromo}
        isInstantConfirmation={isInstantConfirmation}
        data={room}
      />
      <Collapse isOpened={openDesc} style={styles.noMargin}>
        <DescRoomCard
          desc={desc}
          amenities={amenities}
          service={serviceType}
          room={room}
          onClose={onClose}
          closeModalAccommodation={closeModalAccommodation}
          setAccommodationRoom={setAccommodationRoom}
        />
      </Collapse>
    </Segment.Group>
  );
};

RoomCard.propTypes = {
  desc: PropTypes.string,
  amenities: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
  capacity: PropTypes.number,
  image: PropTypes.string,
  images: PropTypes.array,
  serviceType: PropTypes.array,
  isPromo: PropTypes.bool,
  room: PropTypes.object,
  onClose: PropTypes.func,
  closeModalAccommodation: PropTypes.func,
  setAccommodationRoom: PropTypes.func,
  isInstantConfirmation: PropTypes.bool,
};
export default RoomCard;
