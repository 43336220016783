const COLOR_WHITE = '#fefefe';
const COLOR_GOLD = '#e6ca6b';
const COLOR_RED = '#f44336';
const COLOR_BLUE = '#6b82e6';
const COLOR_BLACK = '#000000';
const styles = {
  //   whiteColor: {
  //     color: COLOR_WHITE,
  //   },
  redColor: {
    color: COLOR_RED,
  },
  blueColor: {
    color: COLOR_BLUE,
  },
  goldColor: {
    color: COLOR_GOLD,
  },
  //   backgroundGoldTransparent: {
  //     background: 'rgba(230, 202, 107,0.1)',
  //   },
  segment: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '10px 30px 10px 30px',
    margin: '5px 0px 20px 0px',
  },
  segmentPackage: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    // padding: '10px',
    margin: '5px 0px 0px 0px',
    zIndex: 3,
  },
  segementDetail: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '0px 15px',
    zIndex: 2,
    minHeight: '100px',
  },
  segmentButton: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    // padding: '10px',
    margin: '0px 350px 20px 350px',
    zIndex: 1,
    width: '155px',
  },
  menuActive: {
    background: 'rgba(230, 202, 107, 0.1)',
    borderRight: '4px solid #e6ca6b',
    padding: '10px',
    margin: '0px',
  },
  menuNonActive: {
    padding: '10px',
    margin: '0px',
  },
  tabActive: {
    padding: '15px',
    fontWeight: 'bold',
    borderBottom: '3px solid #e6ca6b',
    color: 'black',
  },
  tabNonActive: {
    padding: '15px',
    fontWeight: 'bold',
    color: '#252525',
  },
  bottonDownload: {
    margin: '5px 3px 5px 3px',
    padding: '5px',
    color: COLOR_GOLD,
    // border: '2px solid #e6ca6b',
    backgroundColor: 'rgba(255,255,255,0)',
    textAlign: 'center',
  },
  buttonGold: {
    padding: '10px',
    color: COLOR_BLACK,
    background: COLOR_GOLD,
  },
  buttonGoldUser: {
    background: COLOR_GOLD,
    color: 'black',
    borderStyle: 'solid',
    borderColor: COLOR_GOLD,
    // width: '100%',
  },
  buttonGoldMargin10px: {
    padding: '10px',
    color: COLOR_BLACK,
    background: COLOR_GOLD,
    margin: '10px',
  },
  buttonTextBlue: {
    padding: '3px 10px',
    color: COLOR_BLUE,
    background: COLOR_WHITE,
  },
  buttonNegative: {
    padding: '10px',
    color: COLOR_WHITE,
    background: COLOR_RED,
  },
  iconButton: {
    color: COLOR_GOLD,
    margin: '0px',
  },
  buttonGoldNoPadding: {
    color: COLOR_BLACK,
    background: COLOR_GOLD,
  },
  //   // titleTable: {
  //   //   borderBottom: '1px solid #252525',
  //   //   borderTop: 'none',
  //   // },
  tableHeader: {
    padding: '3px',
    borderBottom: '1px solid #252525',
    borderTop: 'none',
  },
  tableBody: {
    padding: '3px',
    // border: 'none',
  },
  //   tableNoBorder: {
  //     border: 'none',
  //     padding: '3px',
  //   },
  //   tableNoBorderTitle: {
  //     border: 'none',
  //     padding: '3px 0px',
  //   },
  //   noPaddingNoMargin: {
  //     padding: '0px',
  //     margin: '0px',
  //   },
  //   //ganjil 1, 3, 5
  //   orderedItemOdd: {
  //     // paddingTop: '10px',
  //     // paddingBottom: '10px',
  //     padding: '10px',
  //     margin: '0px 30px 0px 40px',
  //   },
  //   // genap 2, 4, 6
  //   orderedItemEven: {
  //     // paddingTop: '10px',
  //     // paddingBottom: '10px',
  //     padding: '10px',
  //     margin: '0px 30px 0px 40px',
  //     background: 'rgba(230, 202, 107, 0.1)',
  //   },
  ribbonCancelled: {
    color: 'white',
    borderColor: '#ba000d',
    background: 'linear-gradient(to right, #ff7961, #ba000d)',
  },
  ribbonOnHold: {
    color: COLOR_GOLD,
    borderColor: '#805031',
    background: 'linear-gradient(to right, #1b1c1d, #1b1c1d)',
  },
  ribbonCreated: {
    color: 'white',
    borderColor: '#00b5ad',
    background: 'linear-gradient(to right, #5ce8df, #00b5ad)',
  },
  ribbonComplete: {
    color: 'white',
    borderColor: '#00599e',
    background: 'linear-gradient(to right, #66b4ff, #00599e)',
  },
  ribbonPayment: {
    color: 'white',
    borderColor: '#b84200',
    background: 'linear-gradient(to right, #ffa24e, #b84200)',
  },
  ribbonQuotation: {
    color: 'white',
    borderColor: '#700096',
    background: 'linear-gradient(to right, #d867fc, #700096)',
  },
  goldCardColor15px: {
    padding: '15px',
    background: 'rgba(230, 202, 107,0.1)',
  },
  updateUserButton: {
    backgroundColor: 'white',
    color: '#6b82e6',
    marginRight: '5px',
  },
  marginLeftFive: {
    marginLeft: '5px',
  },
  //   marginTopFive: {
  //     marginTop: '5px',
  //   },
  //   marginTopFiveRight: {
  //     marginTop: '5px',
  //     textAlign: 'right',
  //   },
  //   marginTopFiveLeft: {
  //     marginTop: '5px',
  //     textAlign: 'left',
  //   },
  //   marginTopTwentyFiveLeft: {
  //     marginTop: '23px',
  //     textAlign: 'left',
  //   },
  image: {
    maxWidth: '300px',
    maxHeight: '400px',
  },
  //   progressiveImage: {
  //     maxWidth: '300px',
  //     maxHeight: '400px',
  //     position: 'absolute',
  //     // left: '60px',
  //   },
  //   progressiveImageCompanyDetail: {
  //     maxWidth: '300px',
  //     maxHeight: '400px',
  //     backgroundSize: 'cover',
  //     borderBottom: '0px',
  //     // padding: '0px 0px 0px 0px',
  //     position: 'absolute',
  //     right: '0px',
  //     left: '64px',
  //   },
  accountManagerButton: {
    background: 'none',
    border: 'none',
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    font: 'inherit',
    margin: '10px 3px 5px 3px',
    float: 'right',
    /*border is optional*/
    borderBottom: '1px',
    cursor: 'pointer',
    color: COLOR_BLUE,
  },
  accountManagerLabel: {
    background: 'none',
    border: 'none',
    padding: '0',
    font: 'inherit',
    color: COLOR_BLACK,
    fontWeight: 'bold',
    fontSize: '13px',
  },
  accountManagerName: {
    background: 'none',
    border: 'none',
    font: 'inherit',
    // color: COLOR_BLACK,
    fontSize: '14px',
    display: 'block',
    marginTop: '5px', //top right bottom left
  },
  verticalAlign: {
    verticalAlign: 'top',
  },
  noPadding: {
    padding: '0px',
  },
  paddingTop20pxBottom5pxMarginLeftRight10px: {
    padding: '20px 0px 5px 0px',
    margin: '0px 10px',
  },
  paddingTop10pxMarginLeftRight10px: {
    padding: '10px 0px 0px 0px',
    margin: '0px 10px',
  },
  noPaddingMarginLeftRight10px: {
    padding: '0px',
    margin: '0px 10px',
  },
  marginTopBottom5px: {
    margin: '5px 0px',
  },
  paddingTopBottom5pxLeftRight3px: {
    padding: '5px 3px',
  },
  noPaddingMarginBottom20pxLeftRight15px: {
    padding: '0px',
    margin: '0px 15px 20px 15px',
  },
  marginTopBottom5pxWidth90px: {
    margin: '5px 0px',
    width: '90px',
  },
  noMargin: {
    margin: '0px',
  },
  width100percent: {
    width: '100%',
  },
  notewidth100percent: {
    width: '100%',
    borderColor: '#B9BBB6',
    color: COLOR_BLACK,
    opacity: 1,
  },
  searchInput: {
    width: '100%',
    padding: '9px',
    borderBottom: '2px solid #252525',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  borderRight10pxDashedWhite: {
    borderRight: '10px dashed white',
  },
  colorWhiteMarginLeft20px: {
    color: COLOR_WHITE,
    marginLeft: '20px',
  },
  marginLeft20MarginTop10pxColorWhite: {
    color: COLOR_WHITE,
    marginLeft: '20px',
    marginTop: '10px',
  },
  marginLeft20MarginTop10pxColorRed: {
    color: COLOR_RED,
    marginLeft: '20px',
    marginTop: '10px',
  },
  marginTop10px: {
    marginTop: '10px',
  },
  colorGoldMarginTop20px: {
    color: COLOR_GOLD,
    marginTop: '20px',
  },
  colorGoldMarginBottomTop15pxLeftRight0px: {
    color: COLOR_GOLD,
    margin: '15px 0px',
  },
  paddingTop10Left20: {
    padding: '10px 0px 20px 0px',
  },
  marginTopBottom15pxRight10px: {
    border: '0px',
    margin: '15px 0px 15px 10px',
  },
  paddingTopBottom5px: {
    padding: '5px 0px',
  },
  paddingTopBottom10px: {
    padding: '10px 0px',
  },
  paddingLeftRight12px: {
    padding: '0px 12px',
  },
  marginTop10pxBottom5px: {
    margin: '20px 0px 5px 0px',
  },
  paddingLeftRight20px: {
    padding: '0px 20px',
  },
  padding15px: {
    padding: '15px',
  },
  margin10px0px5px0px: {
    margin: '10px 0px 5px 0px',
  },
  margin10px0px0px0px: {
    margin: '10px 0px 0px 0px', //top right bottom left
  },
  displayBlockFontBold: {
    display: 'block',
    fontWeight: 'bold',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  displayNone: {
    display: 'none',
  },
  backgroundWhite: {
    background: '#fbfbfb',
  },
  paddingTopBottom10pxLeftRight35px: {
    padding: '10px 35px',
  },
  paddingTopBottom5pxLeftRight35px: {
    padding: '5px 35px',
  },
  margin10px: {
    margin: '10px',
  },
  marginTop10Bottom5px: {
    margin: '10px 0px 5px',
  },
  backgroundModal: {
    background: '#fbfbfb',
  },
  buttonGoldResetPass: {
    background: COLOR_GOLD,
    color: 'black',
    borderStyle: 'solid',
    borderColor: COLOR_GOLD,
    // width: '100%',
  },
  paddingBottom5px: {
    paddingBottom: '5px',
  },
  margin10: { margin: '10px' },
  padding15: { padding: '15px' },
};
export default styles;
