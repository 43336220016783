import React, { Component } from 'react';
// import styles from '../../../components/guest/styles';
import styles from '../styles';
import { Segment, Grid, Button, Icon, Popup } from 'semantic-ui-react';
import GuestForm from '../../shared/components/guest/FormGuest';
import PropTypes from 'prop-types';
import { getCountries } from '../../shared/actions/countriesAction';
import {
  getIdentityTypes,
  getGuestTitleTypes,
} from '../../shared/actions/tourTransactionAction';
import { connect } from 'react-redux';
import { selectTourLeader } from '../../../genericFunction/validation';
import UploadGuest from '../../../components/button/BtnUploadTemplate';
import { withTranslation } from 'react-i18next';

class GuestsFormWithButtonDelete extends Component {
  componentDidMount = () => {
    this.props.getCountries();
    this.props.getIdentityTypes();
    this.props.getGuestTitleTypes();
  };
  render() {
    const { t } = this.props;
    let {
      guests,
      countries,
      identityTypes,
      guestTitleTypes,
      loadingCountry,
      loadingTourTransaction,
      handleChange,
      isCustomOrReadyPackage,
    } = this.props;
    let tourLeader = selectTourLeader(guests);
    let idTourLeader = tourLeader ? tourLeader.Id : 0;
    let adults = guests.filter(
      e => e.GuestCategory === 'ADULT' && e.Id !== idTourLeader
    );
    let children = guests.filter(e => e.GuestCategory === 'CHILDREN');
    let infants = guests.filter(e => e.GuestCategory === 'INFANT');
    return (
      <div style={styles.marginBottom14px}>
        <Segment.Group style={styles.segmentNoPadding}>
          <Segment style={styles.noBorder}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  {/* Guest Form */}
                  <h2>{t('guestForm')}</h2>
                </Grid.Column>
                <UploadGuest uploadTemplate={this.props.uploadTemplate} />
              </Grid.Row>
              {guests.length === 0 && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    {/* No Guest */}
                    <h5>{t('noGuest')} </h5>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Segment>
          <Segment style={styles.noBorder}>
            <Grid>
              {isCustomOrReadyPackage
                ? tourLeader && <h5>{t('tourLeaader')} </h5>
                : tourLeader && (
                    <h5>{`${t('adult')} ( > 12 ${t('years')} )`}</h5>
                  )}
              {/* ? tourLeader && <h5>Tour Leader</h5>
                : tourLeader && <h5>Adult &#40; &#62; 12 Years &#41;</h5>} */}
              {tourLeader && (
                <Grid.Row key={tourLeader.Id}>
                  <Grid.Column width={15}>
                    <GuestForm
                      listCountry={countries}
                      listTitleType={guestTitleTypes}
                      listIdentityType={identityTypes}
                      loadingCountry={loadingCountry}
                      loadingIdentityType={loadingTourTransaction}
                      loadingTitleType={loadingTourTransaction}
                      identityType={tourLeader.IdentityType}
                      identityNbr={tourLeader.IdentityNbr}
                      countryId={tourLeader.CountryId}
                      guestTitle={tourLeader.GuestTitle}
                      firstName={tourLeader.FirstName}
                      lastName={tourLeader.LastName}
                      handleChange={handleChange}
                      id={tourLeader.Id}
                      validate={tourLeader.errors}
                      disabled={tourLeader.IsDelete}
                    />
                  </Grid.Column>
                  <Grid.Column
                    width={1}
                    verticalAlign="bottom"
                    textAlign="right"
                  >
                    {/* <Button
                      style={styles.buttonDelete}
                      name="IsDelete"
                      objectname={tourLeader.Id}
                      value={tourLeader.IsDelete}
                      onClick={handleChange}
                      size="massive"
                    >
                      {tourLeader.IsDelete ? (
                        <Icon name="undo" />
                      ) : (
                        <Icon name="delete" />
                      )}
                    </Button> */}
                  </Grid.Column>
                </Grid.Row>
              )}
              {isCustomOrReadyPackage && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    {(adults.length > 1 ||
                      children.length !== 0 ||
                      infants.length !== 0) && <h5>{t('tourMember')} </h5>}
                    {adults.length > 1 && (
                      <h5>{`${t('adult')} ( > 12 ${t('years')} )`}</h5>
                    )}
                    {/* {(adults.length > 1 ||
                      children.length !== 0 ||
                      infants.length !== 0) && <h5>Tour Member</h5>}
                    {adults.length > 1 && (
                      <h5>Adult &#40; &#62; 12 Years &#41;</h5>
                    )} */}
                  </Grid.Column>
                </Grid.Row>
              )}
              {adults.length !== 0 &&
                adults.map(adult => (
                  <Grid.Row key={adult.Id}>
                    <Grid.Column width={15}>
                      <GuestForm
                        key={adult.Id}
                        listCountry={countries}
                        listTitleType={guestTitleTypes}
                        listIdentityType={identityTypes}
                        loadingCountry={loadingCountry}
                        loadingIdentityType={loadingTourTransaction}
                        loadingTitleType={loadingTourTransaction}
                        identityType={adult.IdentityType}
                        identityNbr={adult.IdentityNbr}
                        countryId={adult.CountryId}
                        guestTitle={adult.GuestTitle}
                        firstName={adult.FirstName}
                        lastName={adult.LastName}
                        handleChange={handleChange}
                        id={adult.Id}
                        validate={adult.errors}
                        disabled={adult.IsDelete}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={1}
                      verticalAlign="bottom"
                      textAlign="right"
                    >
                      <Button
                        style={styles.buttonDelete}
                        name="IsDelete"
                        objectname={adult.Id}
                        value={adult.IsDelete}
                        onClick={handleChange}
                        size="massive"
                      >
                        {adult.IsDelete ? (
                          <Popup
                            content="Undo"
                            trigger={<Icon name="undo" />}
                          />
                        ) : (
                          <Popup
                            content="Delete Guest"
                            trigger={<Icon name="delete" />}
                          />
                        )}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                ))}
            </Grid>
          </Segment>
          {children.length !== 0 && (
            <Segment style={styles.noBorder}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {/* <h5>Children &#40; 2-12 Years &#41;</h5> */}
                    <h5>{`${t('children')} ( 2-12 ${t('years')} )`} </h5>
                  </Grid.Column>
                </Grid.Row>

                {children.map(child => (
                  <Grid.Row key={child.Id}>
                    <Grid.Column width={15}>
                      <GuestForm
                        listCountry={countries}
                        listTitleType={guestTitleTypes}
                        listIdentityType={identityTypes}
                        loadingCountry={loadingCountry}
                        loadingIdentityType={loadingTourTransaction}
                        loadingTitleType={loadingTourTransaction}
                        identityType={child.IdentityType}
                        identityNbr={child.IdentityNbr}
                        countryId={child.CountryId}
                        guestTitle={child.GuestTitle}
                        firstName={child.FirstName}
                        lastName={child.LastName}
                        handleChange={handleChange}
                        id={child.Id}
                        validate={child.errors}
                        disabled={child.IsDelete}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={1}
                      verticalAlign="bottom"
                      textAlign="right"
                    >
                      <Button
                        style={styles.buttonDelete}
                        name="IsDelete"
                        objectname={child.Id}
                        value={child.IsDelete}
                        onClick={handleChange}
                        size="massive"
                      >
                        {child.IsDelete ? (
                          <Popup
                            content="Undo"
                            trigger={<Icon name="undo" />}
                          />
                        ) : (
                          <Popup
                            content="Delete Guest"
                            trigger={<Icon name="delete" />}
                          />
                        )}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </Segment>
          )}
          {infants.length !== 0 && (
            <Segment style={styles.noBorder}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {/* <h5>Infants &#40; &#60; 2 Years &#41;</h5>{' '} */}
                    <h5>{`${t('infats')} ( < 2 ${t('years')} )`}</h5>
                  </Grid.Column>
                </Grid.Row>
                {infants.map(infant => (
                  <Grid.Row key={infant.Id}>
                    <Grid.Column width={15}>
                      <GuestForm
                        listCountry={countries}
                        listTitleType={guestTitleTypes}
                        listIdentityType={identityTypes}
                        loadingCountry={loadingCountry}
                        loadingIdentityType={loadingTourTransaction}
                        loadingTitleType={loadingTourTransaction}
                        identityType={infant.IdentityType}
                        identityNbr={infant.IdentityNbr}
                        countryId={infant.CountryId}
                        guestTitle={infant.GuestTitle}
                        firstName={infant.FirstName}
                        lastName={infant.LastName}
                        handleChange={handleChange}
                        id={infant.Id}
                        validate={infant.errors}
                        disabled={infant.IsDelete}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={1}
                      verticalAlign="bottom"
                      textAlign="right"
                    >
                      <Button
                        style={styles.buttonDelete}
                        name="IsDelete"
                        objectname={infant.Id}
                        value={infant.IsDelete}
                        onClick={handleChange}
                        size="massive"
                      >
                        {infant.IsDelete ? (
                          <Popup
                            content={t('undo')} //"Undo"
                            trigger={<Icon name="undo" />}
                          />
                        ) : (
                          <Popup
                            content={t('deleteGuest')} //"Delete Guest"
                            trigger={<Icon name="delete" />}
                          />
                        )}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </Segment>
          )}
        </Segment.Group>
      </div>
    );
  }
}
GuestsFormWithButtonDelete.propTypes = {
  guests: PropTypes.object,
  getCountries: PropTypes.func,
  getIdentityTypes: PropTypes.func,
  getGuestTitleTypes: PropTypes.func,
  countries: PropTypes.array,
  identityTypes: PropTypes.array,
  guestTitleTypes: PropTypes.array,
  loadingCountry: PropTypes.bool,
  loadingTourTransaction: PropTypes.bool,
  handleChange: PropTypes.func,
  isCustomOrReadyPackage: PropTypes.bool,
  uploadTemplate: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    countries: state.countries.countries.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    identityTypes: state.tourTransaction.identityTypes.map(type => {
      return { text: type.Description, value: type.Name };
    }),
    guestTitleTypes: state.tourTransaction.guestTitleTypes.map(type => {
      return { text: type.Description, value: type.Name };
    }),
    loadingCountry: state.countries.loading,
    loadingTourTransaction: state.tourTransaction.loading,
  };
};
export default connect(
  mapStateToProps,
  {
    getCountries,
    getIdentityTypes,
    getGuestTitleTypes,
  }
)(withTranslation()(GuestsFormWithButtonDelete));
