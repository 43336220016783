import React, { Component } from 'react';
import { Grid, Table, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles';
import stylesProfile from '../../styles';
import Input from '../../../../components/input/WithLabel';
import setPagination from '../../services/helper/setPagination';
import Pagination from '../../../shared/components/pagination/CustomPagination';
import { withTranslation } from 'react-i18next';
class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      activePage: 1,
    };
  }
  handleButton = (e, { value }) => {
    this.props.handleModalResetPasswordUser(value);
  };
  handleChange = (e, { name, value }) =>
    this.setState({ [name]: value, activePage: 1 });
  filtered = listData => {
    let { searchInput } = this.state;
    listData = listData ? listData : [];
    return listData.filter(
      data =>
        data.Username.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1
    );
  };
  pageChange = (e, { activePage }) => {
    this.setState({ activePage });
  };
  render() {
    const { t } = this.props;
    let { userList } = this.props;
    let { searchInput, activePage } = this.state;
    let filterData = this.filtered(userList);
    let pagedData = setPagination(filterData, 7);
    let listData = pagedData[activePage - 1];
    let isDataExist = listData && listData.length > 0;
    let numbersFrom = isDataExist
      ? listData.length === 7
        ? activePage * listData.length
        : (activePage - 1) * 7 + listData.length
      : 0;
    let numbersTo = numbersFrom > 0 ? numbersFrom - (listData.length - 1) : 0;
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign="left" width={4}>
              <Input
                inputStyles={styles.searchInput}
                transparent
                icon="search"
                iconPosition="left"
                placeholder={t('searchUsername')} //"Search Username"
                name="searchInput"
                value={searchInput}
                handleChange={this.handleChange}
              />
            </Grid.Column>
            <Grid.Column textAlign="right" width={12}>
              <Button
                style={styles.buttonGold}
                onClick={this.props.handleCreateNewUser}
              >
                {/* Add New User */} {t('addNewUser')}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {/* Username */} {t('username')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {/* Firstname */} {t('firstName')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {/* Lastname */}
                      {t('lastName')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {/* Status */} {t('status')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {/* Action */} {t('action')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {isDataExist ? (
                    listData.map((user, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{user.Username}</Table.Cell>
                        <Table.Cell>{user.FirstName}</Table.Cell>
                        <Table.Cell>{user.LastName}</Table.Cell>
                        <Table.Cell>
                          {/* {user.IsInactive ? 'Inactive' : 'Active'} */}
                          {user.IsInactive
                            ? `${t('inactive')}`
                            : `${t('active')}`}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            style={stylesProfile.buttonGold}
                            onClick={this.handleButton}
                            value={user}
                          >
                            {/* Reset Password */}
                            {t('resetPassword')}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan="5">
                        {/* <h5>There is no user in this company</h5> */}
                        <h5>{t('noUserInCompany')}</h5>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
              <Pagination
                pagedData={pagedData}
                numbersTo={numbersTo}
                numbersFrom={numbersFrom}
                data={userList}
                pageChange={this.pageChange}
                activePage={activePage}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
UserInfo.propTypes = {
  userList: PropTypes.array,
  handleCreateNewUser: PropTypes.func,
  handleModalResetPasswordUser: PropTypes.func,
  t: PropTypes.func,
};
const mapStateToProps = () => {
  return {};
};
export default connect(mapStateToProps)(withTranslation()(UserInfo));
