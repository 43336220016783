const GOLD_COLOR = '#e6ca6b';

const style = {
  backgroundModal: {
    background: '#fbfbfb',
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  boldGoldColor: {
    color: GOLD_COLOR,
    fontWeight: 'bold',
  },
  textBold: {
    fontWeight: 'bold',
  },
  justify: {
    textAlign: 'justify',
  },
  buttonGold: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
  },
  inputStyles: {
    width: '100%',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  borderNone: { border: 'none' },
  paddingTopZero: { paddingTop: '0px' },
  marginTopLeftTen: { marginTop: '10px', marginLeft: '10px' },
  noMargin: { margin: 0 },
};
export default style;
