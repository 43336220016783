import * as types from './constant/actionTypes';

const initialState = {
  schedule: '',
  errors: {},
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // --------------------------------------------------------------------------------------------
    // Print Schedule
    // --------------------------------------------------------------------------------------------
    case types.GET_SCHEDULE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_SCHEDULE_FULFILLED: {
      return {
        ...state,
        schedule: action.payload.data,
        getScheduleStatus: 'success',
        loading: false,
      };
    }
    case types.GET_SCHEDULE_REJECTED: {
      return { ...state, loading: false, getScheduleStatus: 'failed' };
    }
    case types.RESET_GET_SCHEDULE: {
      return { ...state, schedule: '', getScheduleStatus: '' };
    }
    default:
      return state;
  }
}
