import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid, Header } from 'semantic-ui-react';
import Filter from '../../../formFilter/FormFilter';
import ListCard from './ListCard';
import styles from './styles';
import {
  getTransportationUnitsFilter,
  resetStatusTransportation,
} from '../../../../actions/transportationAction';
import { ButtonCloseModal } from '../../../common';
import { useTranslation } from 'react-i18next';

// const options = [
//   { key: 'HS', value: 'highest seat', text: 'Highest Seat' },
//   { key: 'LS', value: 'lowest seat', text: 'Lowest Seat' },
// ];

const ModalListTransportationUnits = props => {
  const { t } = useTranslation();

  const [formFilter, setFormFilter] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [activeSort, setActiveSort] = useState('');
  const [searchName, setSearchName] = useState('');

  //===========store==========
  const transportationProfileCategoryStatus = useSelector(
    state => state.transportation.getTransportationProfileCategoryStatus
  );
  const listTransportationProfileCategory = useSelector(
    state => state.transportation.transportationProfileCategories
  );
  const listTransportationType = useSelector(
    state => state.transportation.transportationTypes
  );
  const transportationTypeStatus = useSelector(
    state => state.transportation.getTransportationTypeStatus
  );
  const listTransportationSeatType = useSelector(
    state => state.transportation.transportationSeatTypes
  );
  const transportationSeatTypeStatus = useSelector(
    state => state.transportation.getTransportationSeatTypeStatus
  );
  const listTransportationUnitsFilter = useSelector(
    state => state.transportation.transportationUnitsFilter
  );
  const loading = useSelector(state => state.transportation.loading);
  const loadingTransportationUnitsFilter = useSelector(
    state => state.transportation.loadingTransportationUnitsFilter
  );

  //==========action==========
  const dispatch = useDispatch();
  const getTransportationUnitsFilterAction = data =>
    dispatch(getTransportationUnitsFilter(data));
  const resetStatusTransportationAction = () =>
    dispatch(resetStatusTransportation());
  //==========lifecycle========
  useEffect(() => {
    if (
      transportationProfileCategoryStatus === 'success' &&
      transportationTypeStatus === 'success' &&
      transportationSeatTypeStatus === 'success'
    ) {
      let data = [
        {
          name: 'Transportation Unit Rate',
          data: showOptions(listTransportationProfileCategory),
        },
        {
          name: 'Transportation Type',
          data: showOptions(listTransportationType),
        },
        {
          name: 'Capacities',
          data: showOptionsCapacities(listTransportationSeatType),
        },
      ];

      setFormFilter(data);
      resetStatusTransportationAction();
    } // eslint-disable-next-line
  }, [
    transportationProfileCategoryStatus,
    transportationTypeStatus,
    transportationSeatTypeStatus,
  ]);
  // componentDidUpdate = () => {
  //   if (
  //     this.props.transportationProfileCategoryStatus === 'success' &&
  //     this.props.transportationTypeStatus === 'success' &&
  //     this.props.transportationSeatTypeStatus === 'success'
  //   ) {
  //     let data = [
  //       {
  //         name: 'Transportation Unit Rate',
  //         data: this.showOptions(this.props.listTransportationProfileCategory),
  //       },
  //       {
  //         name: 'Transportation Type',
  //         data: this.showOptions(this.props.listTransportationType),
  //       },
  //       {
  //         name: 'Capacities',
  //         data: this.showOptionsCapacities(
  //           this.props.listTransportationSeatType
  //         ),
  //       },
  //     ];
  //     this.setState({
  //       ...this.state,
  //       formFilter: data,
  //     });
  //     this.props.resetStatusTransportationAction();
  //   }
  // };

  const showOptions = data => {
    return data.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  const showOptionsCapacities = listData => {
    let accumulator = 1;
    return listData.map(item => {
      let options = {
        key: item.Id,
        text: `${accumulator} to ${item.Name} Passenggers`,
        value: item.Name,
        checked: false,
      };
      accumulator = item.Name;
      return options;
    });
  };
  // filterRestaurant = (data, formFilter) => {
  //   let dataFilter, indexForm;
  //   return data.filter(data => {
  //     let { SpecialityId } = data;
  //     dataFilter = true;
  //     indexForm = formFilter.findIndex(e => e.name === 'Restaurant Speciality');
  //     if (formFilter[indexForm].data.some(e => e.checked) && SpecialityId) {
  //       dataFilter =
  //         dataFilter &&
  //         formFilter[indexForm].data.findIndex(
  //           e => e.key === SpecialityId.toLowerCase() && e.checked
  //         ) !== -1;
  //     }
  //     return dataFilter;
  //   });
  // };
  const filteredData = formFilter => {
    let RatingId, typeId, seatTypeId, data;
    let { from, to, date } = props;
    formFilter.map(filter =>
      filter.name === 'Transportation Unit Rate'
        ? (RatingId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
        : filter.name === 'Transportation Type'
        ? (typeId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
        : (seatTypeId = filter.data
            .filter(e => e.checked)
            .map(e => e.value)
            .join(','))
    );
    data = {
      fromCity: from,
      toCities: to,
      RatingId,
      typeId,
      seatTypeId,
      requestedDate: date,
    };
    getTransportationUnitsFilterAction(data);
  };
  const handleChangeCheckbox = (e, { name, value }) => {
    let dataList = [...formFilter];
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    setFormFilter(dataList);
    filteredData(dataList);
  };
  const handleResetFilter = () => {
    let dataList = [...formFilter];
    dataList = dataList.map(filter =>
      filter.data.some(e => e.checked)
        ? { name: filter.name, data: resetFilter(filter.data) }
        : filter
    );
    setFormFilter(dataList);
    filteredData(formFilter);
  };
  const resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const handleChange = (e, { name, value }) => {
    if (name === 'searchName') {
      setSearchName(value);
    } else if (name === 'activeSort') {
      setActiveSort(value);
    }
    setActivePage(1);
  };

  const filteredByName = (listTransportation, searchName) => {
    return listTransportation.filter(
      transport =>
        transport.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };
  const compare = (a, b) => {
    let comparison = 0;

    if (a.TransportationSeatTypeId > b.TransportationSeatTypeId) {
      comparison = 1;
    } else if (b.TransportationSeatTypeId > a.TransportationSeatTypeId) {
      comparison = -1;
    }
    return comparison;
  };
  const sortData = (listTransport, type) => {
    return type === 'lowest seat'
      ? listTransport.sort(compare)
      : type === 'highest seat' && listTransport.sort(compare).reverse();
  };
  const filterTransfer = data => {
    let { isThereActivity } = props;
    return isThereActivity
      ? data.filter(
          item =>
            item.TransportationItemServiceTypes.filter(
              item => item.ServiceType.search('Transfer') === -1
            ).length > 0
        )
      : data;
  };

  let {
    open,
    onClose,
    name,
    from,
    to,
    setTransportItem,
    isThereActivity,
  } = props;
  let listTransportationUnitsFiltered = filterTransfer(
    listTransportationUnitsFilter
  );
  listTransportationUnitsFiltered = filteredByName(
    listTransportationUnitsFiltered,
    searchName
  );
  listTransportationUnitsFiltered = activeSort
    ? sortData(listTransportationUnitsFiltered, activeSort)
    : listTransportationUnitsFiltered;

  const sortOptions = t('sortOptionsTransportation', { returnObjects: true });

  return (
    <Modal open={open} onClose={onClose} size="large" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {name} <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description>
          <Grid>
            <Grid.Column width={5}>
              <Header as="h5">
                {/* Showing {listTransportationUnitsFiltered.length} Transportation */}
                {t('showingTransportation', {
                  count: listTransportationUnitsFiltered.length,
                })}
              </Header>
              <Filter
                loading={loading}
                formFilter={formFilter}
                handleChange={handleChangeCheckbox}
                resetFilter={handleResetFilter}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <ListCard
                from={from}
                to={to}
                data={listTransportationUnitsFiltered}
                loading={loadingTransportationUnitsFilter}
                activePage={activePage}
                pageChange={pageChange}
                activeSort={activeSort}
                options={sortOptions} //options
                searchName={searchName}
                handleChange={handleChange}
                setTransportItem={setTransportItem}
                onClose={onClose}
                isThereActivity={isThereActivity}
              />
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
ModalListTransportationUnits.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  date: PropTypes.string,
  resetStatusTransportation: PropTypes.func,
  transportationProfileCategoryStatus: PropTypes.string,
  listTransportationProfileCategory: PropTypes.array,
  listTransportationType: PropTypes.array,
  transportationTypeStatus: PropTypes.string,
  listTransportationSeatType: PropTypes.array,
  transportationSeatTypeStatus: PropTypes.string,
  listTransportationUnitsFilter: PropTypes.array,
  getTransportationUnitsFilter: PropTypes.func,
  loading: PropTypes.bool,
  loadingTransportationUnitsFilter: PropTypes.bool,
  setTransportItem: PropTypes.func,
  isThereActivity: PropTypes.bool,
  t: PropTypes.func,
};

export default ModalListTransportationUnits;
