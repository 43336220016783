import {
  GET_TOUR_TYPES,
  GET_TOUR_PAX_TYPES,
  RESET_STATUS_TRANSACTION,
  GET_GUEST_TITLE_TYPE,
  GET_IDENTITY_TYPE,
  POST_DEMO_JOIN_TOUR,
  POST_DEMO_PRICE,
  POST_CREATE_TOUR,
  POST_JOIN_TOUR,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2,
  POST_TOUR_TRANSACTIONS_CONFIRMATION,
  POST_TOUR_TRANSACTIONS_TOP_UP,
  RESET_DEMO_PRICE,
  GET_TOUR_TRANSACTION_BILLED,
  POST_CREATE_TOUR_ON_BE_HALF,
  POST_JOIN_TOUR_ON_BE_HALF,
  GET_GUEST_LIST_TEMPLATE,
  RESET_DEMO_JOIN_TOUR,
} from './actionTypes';
import {
  getTourTypesApi,
  getTourPaxTypesApi,
  getGuestTitleTypesApi,
  getIdentityTypesApi,
  postDemoJoinTourApi,
  postDemoPriceApi,
  postCreateTourApi,
  postJoinTourApi,
  getSupplementItemByFixedPackageIdApi,
  postTourTransactionsConfirmationDemoV2Api,
  postTourTransactionsConfirmationApi,
  postTourTransactionsTopUpApi,
  getTourTransactionBilledApi,
  postCreateTourOnBehalfApi,
  postJoinTourOnBehalfApi,
  getGuestListTemplateApi,
  postJoinTourVariableDateApi,
  postDemoJoinTourVariableDateApi,
} from '../../../api/tourTransactionApi';

const getTourTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TYPES,
      payload: getTourTypesApi(),
    });
  };
};

const getTourPaxTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_PAX_TYPES,
      payload: getTourPaxTypesApi(),
    });
  };
};

const resetStatusTransaction = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TRANSACTION,
    });
  };
};

const getGuestTitleTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_GUEST_TITLE_TYPE,
      payload: getGuestTitleTypesApi(),
    });
  };
};

const getIdentityTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_IDENTITY_TYPE,
      payload: getIdentityTypesApi(),
    });
  };
};

const postDemoJoinTour = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_DEMO_JOIN_TOUR,
      payload: postDemoJoinTourApi(id, data),
    });
  };
};

const postDemoPrice = data => {
  return dispatch => {
    return dispatch({
      type: POST_DEMO_PRICE,
      payload: postDemoPriceApi(data),
    });
  };
};

const postCreateTour = data => {
  return dispatch => {
    return dispatch({
      type: POST_CREATE_TOUR,
      payload: postCreateTourApi(data),
    });
  };
};

const postJoinTour = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_JOIN_TOUR,
      payload: postJoinTourApi(id, data),
    });
  };
};

const postCreateTourOnBehalf = data => {
  return dispatch => {
    return dispatch({
      type: POST_CREATE_TOUR_ON_BE_HALF,
      payload: postCreateTourOnBehalfApi(data),
    });
  };
};

const postJoinTourOnBehalf = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_JOIN_TOUR_ON_BE_HALF,
      payload: postJoinTourOnBehalfApi(id, data),
    });
  };
};

const getSupplementItemByFixedPackageId = id => {
  return dispatch => {
    return dispatch({
      type: GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID,
      payload: getSupplementItemByFixedPackageIdApi(id),
    });
  };
};

const postTourTransactionsConfirmationDemoV2 = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2,
      payload: postTourTransactionsConfirmationDemoV2Api(data),
    });
  };
};
const postTourTransactionsConfirmation = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_TRANSACTIONS_CONFIRMATION,
      payload: postTourTransactionsConfirmationApi(data),
    });
  };
};

const postTourTransactionsTopUp = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_TRANSACTIONS_TOP_UP,
      payload: postTourTransactionsTopUpApi(data),
    });
  };
};

const resetDemoPrice = () => {
  return dispatch => {
    return dispatch({ type: RESET_DEMO_PRICE });
  };
};

const getTourTransactionBilled = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TRANSACTION_BILLED,
      payload: getTourTransactionBilledApi(id),
    });
  };
};

const getGuestListTemplate = () => {
  return dispatch => {
    return dispatch({
      type: GET_GUEST_LIST_TEMPLATE,
      payload: getGuestListTemplateApi(),
    });
  };
};

const postJoinTourVariable = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_JOIN_TOUR,
      payload: postJoinTourVariableDateApi(id, data),
    });
  };
};

const postDemoJoinTourVariableDate = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_DEMO_JOIN_TOUR,
      payload: postDemoJoinTourVariableDateApi(id, data),
    });
  };
};

const resetDemoJoinTour = () => {
  return dispatch => {
    return dispatch({ type: RESET_DEMO_JOIN_TOUR });
  };
};

export {
  getTourTypes,
  getTourPaxTypes,
  resetStatusTransaction,
  getGuestTitleTypes,
  getIdentityTypes,
  postDemoJoinTour,
  postDemoPrice,
  postCreateTour,
  postJoinTour,
  getSupplementItemByFixedPackageId,
  postTourTransactionsConfirmationDemoV2,
  postTourTransactionsConfirmation,
  postTourTransactionsTopUp,
  resetDemoPrice,
  getTourTransactionBilled,
  postCreateTourOnBehalf,
  postJoinTourOnBehalf,
  getGuestListTemplate,
  postJoinTourVariable,
  postDemoJoinTourVariableDate,
  resetDemoJoinTour,
};
