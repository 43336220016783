import apiClient from './apiClient';
import {
  GET_ACCOMMODATION_TYPES_URL,
  GET_ACCOMMODATION_RATINGS_URL,
  GET_ACCOMMODATION_LOCATIONS_URL,
  GET_ACCOMMODATIONS_URL,
  GET_ACCOMMODATION_ROOMS_URL,
  GET_ACCOMMODATION_FACILITIES_URL,
  GET_ACCOMMODATION_PROFILES_URL,
  GET_ACCOMMODATION_FILTER_PARAMETER_URL,
} from './apiUrl';

const getAccommodationTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_ACCOMMODATION_TYPES_URL);
};

const getAccommodationRatingsApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_ACCOMMODATION_RATINGS_URL);
};

const getAccommodationLocationsApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_ACCOMMODATION_LOCATIONS_URL);
};

const getAccommodationsApi = (data, bodyReq) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(
    `${GET_ACCOMMODATIONS_URL}?cityId=${data.cityID}&ratingId=${data.ratingId ||
      ''}&areaId=${data.areaId || ''}&locationId=${data.locationId ||
      ''}&typeId=${data.typeId || ''}&facilityId=${data.facilityId ||
      ''}&promoOnly=${data.promoOnly || false}&requestedDate=${encodeURI(
      data.requestedDate
    )}&useExtraBed=${data.useExtraBed}&useChildExtraBed=${
      data.useChildExtraBed
    }&useSharingBed=${data.useSharingBed}&sharingRoomPax=${
      data.sharingRoomPax
    }&singleRoomPax=${data.singleRoomPax}&checkOutDate=${encodeURI(
      data.checkOutDate
    )}`,
    bodyReq
  );
};

const getAccommodationRoomsApi = (data, bodyReq) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(
    `${GET_ACCOMMODATION_ROOMS_URL}?profileId=${
      data.profileId
    }&requestedDate=${encodeURIComponent(
      data.requestedDate
    )}&endDate=${encodeURIComponent(data.checkOutDate)}&useExtraBed=${
      data.useExtraBed
    }&useChildExtraBed=${data.useChildExtraBed}&useSharingBed=${
      data.useSharingBed
    }&sharingRoomPax=${data.sharingRoomPax}&singleRoomPax=${
      data.singleRoomPax
    }`,
    bodyReq
  );
};

const getAccommodationFacilitiesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_ACCOMMODATION_FACILITIES_URL);
};
const getAccommodationProfileByIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_ACCOMMODATION_PROFILES_URL}?ID=${id}`);
};

const getAccommodationFilterParameterApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    `${GET_ACCOMMODATION_FILTER_PARAMETER_URL}?cityId=${
      data.cityId
    }&requestedDate=${data.requestedDate}&useExtraBed=${
      data.useExtraBed
    }&useChildExtraBed=${data.useChildExtraBed}&useSharingBed=${
      data.useSharingBed
    }`
  );
};
export {
  getAccommodationTypesApi,
  getAccommodationRatingsApi,
  getAccommodationLocationsApi,
  getAccommodationsApi,
  getAccommodationRoomsApi,
  getAccommodationFacilitiesApi,
  getAccommodationProfileByIdApi,
  getAccommodationFilterParameterApi,
};
