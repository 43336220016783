import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Segment,
  Pagination,
  Header,
} from 'semantic-ui-react';
import Input from '../../../../../../components/input/WithLabel';
import { LoaderModal } from '../../../common';
// import { ucwords } from '../../../../../../genericFunction/convertWord';
import Card from './Card';
import styles from './styles';
import MapCard from '../../../map/MapCard';
import { useTranslation } from 'react-i18next';

const ListCard = props => {
  const { t } = useTranslation();
  let {
    data,
    location,
    activePage,
    pageChange,
    searchName,
    handleChange,
    loading,
    setExcursion,
    handleOpenMap,
  } = props;
  const pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };

  // location = ucwords(location);
  let pagedData = pagination(data, 3);
  let listData = pagedData[activePage - 1];
  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                name="searchLocation"
                type="input"
                icon="map marker alternate"
                iconPosition="left"
                inputStyles={styles.inputStyles}
                label={t('excursionFound', { count: data.length })} //{`${data.length} Excursion Found`}
                labelStyles={styles.boldGoldColor}
                value={location}
                disabled={true}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={7}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                name="searchName"
                type="input"
                icon="search"
                iconPosition="left"
                inputStyles={styles.inputStyles}
                label={t('searchName')} //"Search Name"
                labelStyles={styles.textBold}
                placeholder={t('searchByExcursionName')} //"Search by excursion name"
                value={searchName}
                handleChange={handleChange}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={16}>
            <MapCard
              labelText={t('viewExcursionAround')} //"View excursion around your accommodation"
              handleOpenMap={handleOpenMap}
              labelStyle={styles.labelViewMap}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <LoaderModal loading={loading} />
            {listData && !loading
              ? listData.map((data, index) => {
                  return (
                    <Card key={index} data={data} setExcursion={setExcursion} />
                  );
                })
              : !loading && (
                  <Header textAlign="center" as="h4">
                    {/* There is no result of excursion for your destination */}
                    {t('noResultOfExcursionInDestination')}
                  </Header>
                )}
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            width={16}
            style={styles.paddingTopTen}
          >
            {pagedData && !loading && pagedData.length > 1 && (
              <Pagination
                totalPages={pagedData.length}
                onPageChange={pageChange}
                activePage={activePage}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

ListCard.propTypes = {
  data: PropTypes.array,
  location: PropTypes.string,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  searchName: PropTypes.string,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
  setExcursion: PropTypes.func,
  handleOpenMap: PropTypes.func,
  t: PropTypes.func,
};
export default ListCard;
