import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';

const lineDivider = props => {
  const defaultStyles = props.styles ? props.styles : styles.lineDivider;

  return (
    <div>
      <hr styles={defaultStyles} />
    </div>
  );
};

lineDivider.propTypes = {
  styles: PropTypes.object,
};

export default lineDivider;
