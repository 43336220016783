import * as types from './constant/actionTypes';

const initialState = {
  resetStaffPassword: {},
  postResetStaffPasswordStatus: '',
  errors: {},
  loading: false,
  updateProfile: {},
  putUpdatePofileStatus: '',
  userProfile: {},
  getUserProfileStatus: '',
  changePasswordStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // POST_RESET_STAFF_PASSWORD
    //---------------------------------
    case types.POST_RESET_STAFF_PASSWORD:
      return { ...state };
    case types.POST_RESET_STAFF_PASSWORD_PENDING:
      return { ...state, loading: true };
    case types.POST_RESET_STAFF_PASSWORD_FULFILLED:
      return {
        ...state,
        resetStaffPassword: action.payload.data,
        postResetStaffPasswordStatus: 'success',
        loading: false,
      };
    case types.POST_RESET_STAFF_PASSWORD_REJECTED:
      return {
        ...state,
        resetStaffPassword: {},
        postResetStaffPasswordStatus: 'failed',
        loading: false,
      };
    //---------------------------------
    // PUT_UPDATE_PROFILE
    //---------------------------------
    case types.PUT_UPDATE_PROFILE:
      return { ...state };
    case types.PUT_UPDATE_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.PUT_UPDATE_PROFILE_FULFILLED:
      return {
        ...state,
        updateProfile: action.payload.data,
        putUpdatePofileStatus: 'success',
        loading: false,
      };
    case types.PUT_UPDATE_PROFILE_REJECTED:
      return {
        ...state,
        updateProfile: {},
        putUpdatePofileStatus: 'failed',
        loading: false,
      };
    //---------------------------------
    // GET_USER_PROFILE
    //---------------------------------
    case types.GET_USER_PROFILE:
      return { ...state };
    case types.GET_USER_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_PROFILE_FULFILLED:
      return {
        ...state,
        userProfile: action.payload.data,
        errors: {},
        getUserProfileStatus: 'success',
        loading: false,
      };
    case types.GET_USER_PROFILE_REJECTED:
      return {
        ...state,
        userProfile: {},
        errors: action.payload.response.data,
        getUserProfileStatus: 'failed',
        loading: false,
      };
    //---------------------------------
    // POST_CHANGE_PASSWORD
    //---------------------------------
    case types.POST_CHANGE_PASSWORD:
      return { ...state };
    case types.POST_CHANGE_PASSWORD_PENDING:
      return { ...state, loading: true };
    case types.POST_CHANGE_PASSWORD_FULFILLED:
      return {
        ...state,
        changePasswordStatus: 'success',
        loading: false,
      };
    case types.POST_CHANGE_PASSWORD_REJECTED:
      return {
        ...state,
        changePasswordStatus: 'failed',
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
