const templateGuest = {
  No: {
    prop: 'id',
    type: Number,
  },
  'Guest Category': {
    prop: 'GuestCategory',
    type: String,
  },
  'Identity Type': {
    prop: 'IdentityType',
    type: String,
  },
  'Identity No.': {
    prop: 'IdentityNbr',
    type: String,
  },
  'First Name': {
    prop: 'FirstName',
    type: String,
  },
  'Last Name': {
    prop: 'LastName',
    type: String,
  },
  'Country Id': {
    prop: 'CountryId',
    type: String,
  },
  'Guest Title': {
    prop: 'GuestTitle',
    type: String,
  },
  'Guest Type': {
    prop: 'GuestType',
    type: String,
  },
  'Is Delete': {
    prop: 'IsDelete',
    type: Boolean,
  },
};

export { templateGuest };
