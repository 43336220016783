const COLOR_WHITE = '#fefefe';
const COLOR_GOLD = '#e6ca6b';
const COLOR_RED = '#f44336';
// const COLOR_BLUE = '#6b82e6';
const COLOR_BLACK = '#000000';
const styles = {
  //   whiteColor: {
  //     color: COLOR_WHITE,
  //   },
  //   redColor: {
  //     color: COLOR_RED,
  //   },
  //   blueColor: {
  //     color: COLOR_BLUE,
  //   },
  goldColor: {
    color: COLOR_GOLD,
  },
  backgroundGoldTransparent: {
    background: 'rgba(230, 202, 107,0.1)',
  },
  segment: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '10px 30px 10px 30px',
    margin: '5px 0px 20px 0px',
  },
  //   segmentNoPadding: {
  //     border: 'none',
  //     boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
  //     margin: '5px 0px 20px 0px',
  //   },
  segmentPaddingLeftRight15px: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '10px 15px 10px 15px',
    margin: '5px 0px 20px 0px',
  },
  buttonGold: {
    padding: '15px',
    color: COLOR_BLACK,
    background: COLOR_GOLD,
  },
  buttonGoldBorderSolid: {
    background: COLOR_GOLD,
    color: COLOR_BLACK,
    borderStyle: 'solid',
    borderColor: COLOR_GOLD,
    // width: '100%',
  },
  buttonGoldNoPadding: {
    padding: '5px 0px',
    background: COLOR_GOLD,
    color: 'black',
    borderStyle: 'solid',
    borderColor: COLOR_GOLD,
  },
  buttonTransparentBlack: {
    backgroundColor: 'transparent',
    color: COLOR_BLACK,
    padding: '0px',
    margin: '0px',
  },
  //   buttonDelete: {
  //     padding: '15px',
  //     color: COLOR_RED,
  //     background: COLOR_WHITE,
  //   },
  //   textAlignCenter: {
  //     textAlign: 'center',
  //   },
  //   borderBottom: {
  //     borderBottom: '1px solid black',
  //   },
  //   withBackground: {
  //     padding: '10px',
  //     background: 'rgba(230, 202, 107,0.1)',
  //   },
  //   withNoPaddingBackground: {
  //     background: 'rgba(230, 202, 107,0.1)',
  //   },
  //   borderRedBackgroundRed: {
  //     border: '1px solid rgb(244, 67, 54)',
  //     background: 'rgba(244, 67, 54, 0.4)',
  //   },
  borderGoldBackgroundTransparentGold: {
    border: '1px solid rgba(230, 202, 107)',
    background: 'rgba(230, 202, 107,0.3)',
  },
  noPadding: {
    padding: '0px',
  },
  padding10px: {
    padding: '10px',
  },
  paddingLeft25px: {
    paddingLeft: '25px',
  },
  paddingTopBottom5px: {
    padding: '5px 0px',
  },
  paddingTopBottom20px: {
    padding: '20px 0px 20px 0px',
  },
  paddingTop10pxBottom5px: {
    padding: '10px 0px 5px 0px',
  },
  paddingBottom5px: {
    padding: '0px 0px 5px 0px',
  },
  paddingTop10RightLeft15Bottom5: {
    padding: '10px 15px 5px 15px',
  },
  paddingTopBottom10pxNoMargin: {
    padding: '10px 0px',
    margin: '0px',
  },
  paddingRight25BottomLeft10: {
    padding: '0px 25px 10px 10px',
  },
  noMargin: {
    margin: '0px',
  },
  margin10pxColorRed: {
    color: COLOR_RED,
    margin: '10px',
  },
  marginTopBottom20pxNoPadding: {
    margin: '20px 10px',
    padding: '0px',
  },
  marginLeftBottomRight10pxNoPadding: {
    margin: '0px 10px 10px 10px',
    padding: '0px',
  },
  width80Percen: {
    width: '80%',
  },
  width100PercenPaddingTopBottom5LeftRight10: {
    width: '100%',
    padding: '5px 10px',
  },
  width100Percen: {
    width: '100%',
  },
  backgroundWhite: {
    background: COLOR_WHITE,
  },
  backgroundGoldNoPadding: {
    background: COLOR_GOLD,
    padding: '0px',
  },
  messageGold: {
    border: '1px solid rgba(230, 202, 107)',
    background: 'rgba(230, 202, 107,0.3)',
  },
  minHeight5pxNoMarginNoPadding: {
    minHeight: '5px',
    margin: '0px',
    padding: '0px',
  },
  backGroundWhiteGrey: { background: '#fbfbfb' },
};
export default styles;
