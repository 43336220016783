import React from 'react';
import { Grid } from 'semantic-ui-react';
import TourPackageCard from '../../../shared/components/tourPackageCard/TourPackageCard';
import PropTypes from 'prop-types';
import styles from './styles';
const TourPackages = props => {
  const { packages, token, openModal } = props;
  return (
    <Grid columns={3} style={styles.paddingTourPackages}>
      {packages.slice(0, 6).map((item, i) => (
        <TourPackageCard
          key={i}
          token={token}
          item={item}
          openModal={openModal}
        />
      ))}
    </Grid>
  );
};

TourPackages.propTypes = {
  packages: PropTypes.array,
  token: PropTypes.string,
  openModal: PropTypes.func,
};

export default TourPackages;
