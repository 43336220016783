import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  ActivityNoImage,
  ActivityWithImage,
  ActivityWithLine,
} from '../activity';
import {
  getHourAndMinute,
  isSameDate,
} from '../../../../../genericFunction/moment';
import TimeLineAddActivity from './TimeLineAddActivity';
import {
  CHECKIN,
  CHECKOUT,
  DRIVING,
  RECREATION,
  RETURNACCOMMODATION,
  LEAVEACCOMMODATION,
  DEPARTURE,
  ARRIVAL,
  FREETIME,
  TRANSIT,
  FLIGHTTIME,
  EAT,
  DAYSTART,
  DAYEND,
  VIRTUALCHECKIN,
  VIRTUALCHECKOUT,
  VIRTUALRETURNACCOMMODATION,
  VIRTUALLEAVEACCOMMODATION,
  FREETIMELOCKED,
  QUEUETIME,
} from '../../../../../constant/activityTypes';
import { FIXED } from '../../../../../constant/packageTypes';
import { SumSecond } from '../../../../../genericFunction/itineraryBuilder/timeHelper';
import TimeLineDaySeparator from './TimeLineDaySeparator';
class TimeLineActivity extends React.Component {
  renderActivity = () => {
    const {
      activity,
      index,
      movements,
      openModalSetActivity,
      day,
      deleteActivity,
      openTransportationModal,
      deleteTransportItem,
      transportStore,
      currentPackage,
      isStatic,
      dailyData,
    } = this.props;
    // let isActivityInCurrentDate = isSameDate(activity.DateTime, dailyData.Date);
    switch (activity.MovementName) {
      case CHECKIN:
        return (
          <ActivityWithImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case CHECKOUT:
        return (
          <ActivityWithImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case DRIVING:
        return (
          <ActivityWithLine
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            openTransportationModal={openTransportationModal}
            deleteTransportItem={deleteTransportItem}
            transportStore={transportStore}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case RECREATION:
        return (
          <ActivityWithImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            deleteActivity={deleteActivity}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case RETURNACCOMMODATION:
        return (
          <ActivityWithImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case LEAVEACCOMMODATION:
        return (
          <ActivityWithImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case DEPARTURE:
        return (
          <ActivityNoImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case ARRIVAL:
        return (
          <ActivityNoImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case FREETIME:
        return (
          <ActivityWithLine
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            deleteActivity={deleteActivity}
            openTransportationModal={openTransportationModal}
            deleteTransportItem={deleteTransportItem}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case TRANSIT:
        return (
          <ActivityNoImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case FLIGHTTIME:
        return (
          <ActivityNoImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case EAT:
        return (
          <ActivityWithImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            deleteActivity={deleteActivity}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case DAYSTART:
        return (
          <ActivityNoImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case DAYEND:
        return (
          <ActivityNoImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case VIRTUALCHECKIN:
        return null;
      case VIRTUALCHECKOUT:
        return null;
      case VIRTUALRETURNACCOMMODATION:
        return null;
      case VIRTUALLEAVEACCOMMODATION:
        return null;
      case FREETIMELOCKED:
        return (
          <ActivityWithLine
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            openTransportationModal={openTransportationModal}
            deleteTransportItem={deleteTransportItem}
            deleteActivity={deleteActivity}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      case QUEUETIME:
        return (
          <ActivityNoImage
            data={activity}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
            date={dailyData.Date}
          />
        );
      default:
        return null;
    }
  };
  renderAddActivity = () => {
    const {
      activity,
      index,
      movements,
      openModalSetActivity,
      day,
      isStatic,
    } = this.props;
    switch (activity.MovementName) {
      case CHECKIN:
        return [CHECKOUT, DAYEND].indexOf(movements[index + 1].MovementName) !==
          -1 ? (
          <TimeLineAddActivity
            activity={activity}
            key={'move' + index}
            index={index}
            DAYSTART
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            isStatic={isStatic}
          />
        ) : null;
      case CHECKOUT:
        return null;
      case DRIVING:
        return null;
      case RECREATION:
        return (
          <TimeLineAddActivity
            activity={activity}
            key={'move' + index}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            isStatic={isStatic}
          />
        );
      case RETURNACCOMMODATION:
        return null;
      case LEAVEACCOMMODATION:
        return null;
      case DEPARTURE:
        return null;
      case ARRIVAL:
        return null;
      case FREETIME:
        return (
          <TimeLineAddActivity
            activity={activity}
            key={'move' + index}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            isStatic={isStatic}
          />
        );
      case TRANSIT:
        return null;
      case FLIGHTTIME:
        return null;
      case EAT:
        return (
          <TimeLineAddActivity
            activity={activity}
            key={'move' + index}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            isStatic={isStatic}
          />
        );
      case DAYSTART:
        return [CHECKOUT, DAYEND].indexOf(movements[index + 1].MovementName) !==
          -1 ? (
          <TimeLineAddActivity
            activity={activity}
            key={'move' + index}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            isStatic={isStatic}
          />
        ) : null;
      case DAYEND:
        return null;
      case VIRTUALCHECKIN:
        return movements[index + 1].MovementName === VIRTUALCHECKOUT ? (
          <TimeLineAddActivity
            activity={activity}
            key={'move' + index}
            index={index}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            isStatic={isStatic}
          />
        ) : null;
      case VIRTUALCHECKOUT:
        return null;
      case VIRTUALRETURNACCOMMODATION:
        return null;
      case VIRTUALLEAVEACCOMMODATION:
        return null;
      case FREETIMELOCKED:
        return null;
      case QUEUETIME:
        return null;
      default:
        return null;
    }
  };

  render() {
    const {
      activity,
      currentPackage,
      dailyData,
      movements,
      index,
    } = this.props;
    const time = getHourAndMinute(activity.DateTime);
    const isActivityInCurrentDate = isSameDate(
      activity.DateTime,
      dailyData.Date
    );
    const currentEndDate = SumSecond(activity.DateTime, activity.Duration);
    const isEndTimeInCurrentDate = isSameDate(currentEndDate, dailyData.Date);
    const isNoNextMovement = movements[index + 1] ? false : true;
    return (
      <div>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={styles.timeLineIconClock}
          position="right"
          icon={<h5 style={styles.textCenter}>{time}</h5>}
          style={styles.noMargin}
        >
          {this.renderActivity()}
        </VerticalTimelineElement>
        {currentPackage !== FIXED &&
          isActivityInCurrentDate &&
          isEndTimeInCurrentDate &&
          this.renderAddActivity()}
        {isActivityInCurrentDate &&
          !isEndTimeInCurrentDate &&
          !isNoNextMovement && <TimeLineDaySeparator date={currentEndDate} />}
      </div>
    );
  }
}

TimeLineActivity.propTypes = {
  activity: PropTypes.object,
  DateTime: PropTypes.string,
  currentPackage: PropTypes.string,
  openModalSetActivity: PropTypes.func,
  index: PropTypes.number,
  movements: PropTypes.array,
  day: PropTypes.number,
  deleteActivity: PropTypes.func,
  openTransportationModal: PropTypes.func,
  deleteTransportItem: PropTypes.func,
  transportStore: PropTypes.array,
  isStatic: PropTypes.bool,
  dailyData: PropTypes.bool,
};
export default TimeLineActivity;
