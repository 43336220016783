const BACKGROUND_COLOR = '#252525';

const style = {
  header: {
    background: BACKGROUND_COLOR,
    borderRadius: 0,
    borderRight: 0,
    borderTop: 0,
    borderLeft: 0,
    borderBottom: 0,
    marginBottom: 0,
    height: '55px',
  },
  logo: {
    width: '125px',
    paddingBottom: '11px',
    paddingLeft: '5px',
  },
  item: {
    background: BACKGROUND_COLOR,
  },
  containerHeader: {
    width: '800px',
    height: '45px',
    background: 'white',
  },
  logoTouress: {
    marginTop: '37px',
    height: '35px',
  },
  backgroundBlack: {
    background: BACKGROUND_COLOR,
  },
  itemMenu: {
    fontSize: '14px',
    fontWeight: 'bold',
    background: BACKGROUND_COLOR,
  },
  dropdown: {
    fontSize: '14px',
    fontWeight: 'bold',
    height: '100%',
  },
  paddingBottom12Point5: {
    paddingBottom: '12.5px',
  },
  marginLeftFive: {
    marginLeft: '5px',
  },
  popup: {
    fontSize: '14px',
    fontWeight: 'bold',
    maxWidth: '400px',
    padding: '0px',
    minWidth: '300px',
  },
  customIconLogout: {
    paddingBottom: '12.5px',
    cursor: 'pointer',
  },
  paddingTopBotomZero: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export default style;
