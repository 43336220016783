import { Helmet } from 'react-helmet';
import React from 'react';
import PropTypes from 'prop-types';

const DynamicTitle = props => (
  <Helmet>
    <title>{props.title ? props.title + ' - ' : ''}Touress.com</title>
  </Helmet>
);

DynamicTitle.propTypes = {
  title: PropTypes.string,
};

export default DynamicTitle;
