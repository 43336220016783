import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import HeaderWithIcon from '../../../../../components/common/headerWithIcon';
import CollapseButton from '../../../../../components/button/BtnCollapse';
import styles from './styles';
import { Collapse } from 'react-collapse';
import ArrivalDepartureCard from './ArrivalDepartureCard';
import Accommodation from './AccommodationCard';
import { resetStatusGetPlaceByRegion } from '../../../actions/locationAction';
import {
  addSummaryProgram,
  setDateSummaryProgram,
  // setDateDeparture,
  isAccommodationEmpty,
} from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import { setHeadlineProgram } from '../../../actions/customPackageAction';
import ModalDestinationsAndDate from '../../modal/modalDestinationsAndDate/ModalDestinationsAndDate';
import { setReturnsDate } from '../../../../../genericFunction/itineraryBuilder/itineraryBuilder';
import { withTranslation } from 'react-i18next';
class AccommodationAndDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
      openModalAccommodation: false,
      accomDestination: '',
      openModalDestinations: false,
    };
  }
  closeModal = () => {
    this.setState({
      openModalAccommodation: false,
    });
  };
  openModalDestination = () => {
    this.setState({ openModalDestinations: true });
  };
  closeModalDestination = () => {
    this.setState({ openModalDestinations: false });
  };

  handleCollapse = () => {
    let isCollapse = this.state.isCollapse;
    isCollapse = !isCollapse;
    this.setState({ ...this.state, isCollapse: isCollapse });
  };
  addNewAccommodation = () => {
    let headLineProg = { ...this.props.HeadlineProgram };
    if (isAccommodationEmpty(headLineProg.MainPrograms)) {
      alert('please input your accommodation!');
    } else {
      let newAccommodation = addSummaryProgram(
        headLineProg.MainPrograms[headLineProg.MainPrograms.length - 1]
      );
      headLineProg.MainPrograms.push(newAccommodation);
      headLineProg.MainPrograms = setDateSummaryProgram(
        headLineProg.Departures[headLineProg.Departures.length - 1],
        headLineProg.MainPrograms
      );
      // headLineProg.Returns[0] = setDateDeparture(
      //   headLineProg.Returns[0],
      //   headLineProg.MainPrograms
      // );
      headLineProg.Returns = setReturnsDate(
        headLineProg.Returns,
        headLineProg.MainPrograms
      );
      this.props.setHeadlineProgram(headLineProg);
    }
  };
  isNeedChangePlace = (data, main, index, type) => {
    let result = false;
    if (type === 'Arrival') {
      if (index === 0) {
        result = false;
      } else {
        if (main[index - 1] && main[index - 1].TransferType) {
          result = data.Region !== main[index - 1].Region ? true : false;
        } else result = false;
      }
    } else if (type === 'Departure') {
      if (index === 0) {
        result = false;
      } else {
        if (main[index + 1] && main[index + 1].TransferType) {
          result = data.Region !== main[index + 1].Region ? true : false;
        } else result = false;
      }
    }
    return result;
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.selectedDate !== this.props.selectedDate) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      return true;
    }
    return true;
  }

  render() {
    const { t } = this.props;
    const {
      generalInfo,
      errorGeneralInformation,
      setErrorGeneralInformation,
      isVariableDate,
    } = this.props;
    const { Departures, MainPrograms, Returns } = this.props.HeadlineProgram;
    const { openModalDestinations } = this.state;
    const isAccomEmpty = isAccommodationEmpty(MainPrograms);
    return (
      <Segment style={styles.segment}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <HeaderWithIcon
                as="h2"
                title={t('destinationSummary')} //"Destinations Summary"
                icon="clipboard list"
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              <CollapseButton
                handleCollapse={this.handleCollapse}
                style={styles.shadowTransparant}
                isCollapse={this.state.isCollapse}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Collapse isOpened={this.state.isCollapse}>
          {isAccomEmpty || (
            <div style={styles.paddingSideTwenty}>
              {Departures.length > 0 &&
                Departures.map((item, i) =>
                  item.TransferType &&
                  item.TransferType === 'Movement_arrival' ? (
                    <ArrivalDepartureCard
                      type="arrival"
                      data={item}
                      key={i}
                      index={i}
                    />
                  ) : (
                    <ArrivalDepartureCard
                      type="departure"
                      data={item}
                      key={i}
                      index={i}
                    />
                  )
                )}
              {MainPrograms.length > 0 &&
                MainPrograms.map((item, i) =>
                  item.AccommodationSummary ? (
                    <Accommodation
                      data={item}
                      key={i}
                      index={i}
                      selectedDate={this.props.selectedDate}
                    />
                  ) : item.TransferType &&
                    item.TransferType === 'Movement_arrival' ? (
                    <ArrivalDepartureCard
                      type="arrival"
                      data={item}
                      key={i}
                      index={i}
                    />
                  ) : (
                    <ArrivalDepartureCard
                      type="departure"
                      data={item}
                      key={i}
                      index={i}
                    />
                  )
                )}
              {Returns.length > 0 &&
                Returns.map((item, i) =>
                  item.TransferType &&
                  item.TransferType === 'Movement_arrival' ? (
                    <ArrivalDepartureCard
                      type="arrival"
                      data={item}
                      key={i}
                      index={i}
                    />
                  ) : (
                    <ArrivalDepartureCard
                      type="departure"
                      data={item}
                      key={i}
                      index={i}
                    />
                  )
                )}
            </div>
          )}
        </Collapse>
        {!isVariableDate && (
          <ModalDestinationsAndDate
            open={openModalDestinations}
            closeModal={this.closeModalDestination}
            openModal={this.openModalDestination}
            generalInfo={generalInfo}
            errorGeneralInformation={errorGeneralInformation}
            setErrorGeneralInformation={setErrorGeneralInformation}
            guests={this.props.guests}
            accommodations={this.props.accommodations}
          />
        )}
      </Segment>
    );
  }
}
const mapStateToProps = state => {
  return {
    cityList: state.location.cityInCountry,
    accommodationChangeIndex: state.customPackage.accommodationChangeIndex,
    HeadlineProgram: state.customPackage.HeadlineProgram,
    placeByRegion: state.location.placeByRegion,
    getplaceByRegion: state.location.getplaceByRegion,
    accommodations: state.accommodation.accommodations,
  };
};
AccommodationAndDate.propTypes = {
  HeadlineProgram: PropTypes.object,
  // headlineProgram: PropTypes.object,
  placeByRegion: PropTypes.array,
  getplaceByRegion: PropTypes.string,
  resetStatusGetPlaceByRegion: PropTypes.func,
  accommodationChangeIndex: PropTypes.number,
  cityList: PropTypes.array,
  setHeadlineProgram: PropTypes.func,
  accommodations: PropTypes.array,
  generalInfo: PropTypes.object,
  errorGeneralInformation: PropTypes.object,
  setErrorGeneralInformation: PropTypes.func,
  guests: PropTypes.object,
  isVariableDate: PropTypes.bool,
  selectedDate: PropTypes.string,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  { resetStatusGetPlaceByRegion, setHeadlineProgram }
)(withTranslation()(AccommodationAndDate));
