import React, { Component } from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles';
import { postChangePassword } from './../../accountAction';
import { ChangePasswordButton } from './';
import { ModalAlert } from '../../../shared/components/modal';
import InputWithLabel from '../../../../components/input/WithLabel';
import { withTranslation } from 'react-i18next';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      openModal: false,
      message: '',
      oldPasswordIcon: 'eye slash',
      oldPasswordInputType: 'password',
      oldPasswordShow: false,
      newPasswordIcon: 'eye slash',
      newPasswordInputType: 'password',
      newPasswordShow: false,
      confirmPasswordIcon: 'eye slash',
      confirmPasswordInputType: 'password',
      confirmPasswordShow: false,
    };
  }
  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleChangePassword = (e, { contextstate }) => {
    let data = {
      oldPassword: contextstate.oldPassword,
      newPassword: contextstate.newPassword,
      confirmPassword: contextstate.confirmPassword,
    };
    this.props
      .postChangePassword(data)
      .then(() => {
        this.resetForm();
      })
      .catch(() => {
        this.open('Reset password failed'); //ini
      });
  };
  open = message => {
    this.setState({ openModal: true, message: message });
  };

  close = () => this.setState({ openModal: false });

  resetForm = () => {
    this.setState(
      {
        ...this.state,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      () => this.open('Your password has been reset')
    );
  };

  onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    let inputType = `${name}InputType`;
    if (this.state[show]) {
      this.setState({
        [icon]: 'eye slash',
        [show]: false,
        [inputType]: 'password',
      });
    } else {
      this.setState({
        [icon]: 'eye',
        [show]: true,
        [inputType]: 'text',
      });
    }
  };
  render() {
    const { t } = this.props;
    const {
      oldPasswordIcon,
      oldPasswordInputType,
      newPasswordIcon,
      newPasswordInputType,
      confirmPasswordIcon,
      confirmPasswordInputType,
      oldPassword,
      newPassword,
      confirmPassword,
    } = this.state;
    return (
      <div>
        <Grid>
          <Grid.Row centered>
            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <InputWithLabel
                    label={t('currentPassword')} //"Current Password"
                    placeholder={t('currentPassword')} //"Current Password"
                    required
                    labelStyles={styles.displayNoneFontBold}
                    inputStyles={styles.inputTextStyle}
                    handleChange={this.handleChange}
                    value={oldPassword}
                    name="oldPassword"
                    type={oldPasswordInputType}
                    action={
                      <Button
                        name="oldPassword"
                        icon={oldPasswordIcon}
                        onClick={this.onClickPassIcon}
                      />
                    }
                    fluid={true}
                  />
                </Form.Field>
                <Form.Field>
                  <InputWithLabel
                    label={t('newPassword')} //"New Password"
                    placeholder={t('newPassword')} //"New Password"
                    required
                    labelStyles={styles.displayNoneFontBold}
                    inputStyles={styles.inputTextStyle}
                    handleChange={this.handleChange}
                    value={newPassword}
                    name="newPassword"
                    type={newPasswordInputType}
                    action={
                      <Button
                        name="newPassword"
                        icon={newPasswordIcon}
                        onClick={this.onClickPassIcon}
                      />
                    }
                    fluid={true}
                  />
                </Form.Field>
                <Form.Field>
                  <InputWithLabel
                    label={t('confirmPassword')} //"Confirm Password"
                    placeholder={t('confirmPassword')} //"Confirm Password"
                    required
                    labelStyles={styles.displayNoneFontBold}
                    inputStyles={styles.inputTextStyle}
                    handleChange={this.handleChange}
                    value={confirmPassword}
                    name="confirmPassword"
                    type={confirmPasswordInputType}
                    action={
                      <Button
                        name="confirmPassword"
                        icon={confirmPasswordIcon}
                        onClick={this.onClickPassIcon}
                      />
                    }
                    fluid={true}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column textAlign="right" width={8}>
              <ChangePasswordButton
                style={styles.buttonGold}
                onClick={this.handleChangePassword}
                contextstate={this.state}
                text={t('update')} //"Update"
              />
              <ModalAlert
                size="mini"
                openModal={this.state.openModal}
                open={this.open}
                close={this.close}
                message={this.state.message}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
ChangePassword.propTypes = {
  OldPassword: PropTypes.string,
  NewPassword: PropTypes.string,
  ConfirmPassword: PropTypes.string,
  postChangePassword: PropTypes.func,
  t: PropTypes.func,
};
const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    postChangePassword,
  }
)(withTranslation()(ChangePassword));
