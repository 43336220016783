import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Checkbox, Message } from 'semantic-ui-react';
import InputWithLabel from '../../../../../components/input/WithLabel';
// import InputDate from '../../../../../components/input/InputDate';
import InputDate from '../../../../../components/input/DateInput';
import InputTime from '../../../../../components/input/Time';
// import IconButton from '../../button/iconButton';
import {
  getHourAndMinute,
  convertDateFormat,
} from '../../../../../genericFunction/moment';
import {
  // setDateArrival,
  setDateSummaryProgram,
  copyObject,
  // setDateDeparture,
  changeAirport,
  isNeedChangeArrivalDeparture,
  // isOverlapping,
  setDateSummaryProgramByIndex,
  changeTimeArrivalDeparture,
} from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import styles from '../styles';
import { connect } from 'react-redux';
import options from '../../../../../genericFunction/selectOptionsConverter';
import Loader from '../../../../../components/common/loader';
import {
  SumDays,
  SubDays,
  convertToStringDate,
  convertToStringTime,
} from '../../../../../genericFunction/itineraryBuilder/timeHelper';
import {
  isOverlapping,
  setReturnsDate,
  changeLastArrivalDate,
} from '../../../../../genericFunction/itineraryBuilder/itineraryBuilder';
import style from './style';

import CustomSelect from '../../common/customSelect/CustomSelect';
import { initialAirport } from '../../common/customSelect/initialData';
import { withTranslation } from 'react-i18next';

class Arrival extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrivalDate: props.data.Date,
      arrivalTime: getHourAndMinute(props.data.Date),
    };
  }
  componentDidMount() {
    //set place when first time render the component
    if (
      !this.props.data.PlaceId &&
      this.props.places.length > 0 &&
      this.props.data.RequestFrom
    ) {
      let place = this.getPlaceByRegion(this.props.data.RequestFrom);
      if (place.length > 0) this.setFlight(place[0].Id);
    }
  }

  setArrivalTime = (context, value) => {
    context.setState({ ...context.state, arrivalTime: value });
    let { type, headlineProgram, index } = context.props;
    let headLineProg = copyObject(headlineProgram);
    if (type === 'firstArrival') {
      let flight = headLineProg.Departures[index];
      let date = flight.Date;
      headLineProg.Departures[index] = changeTimeArrivalDeparture(
        flight,
        `${convertDateFormat(date, 'YYYY-MM-DD')}T${value}:00`
      );
    } else if (type === 'lastArrival') {
      let flight = headLineProg.Returns[index];
      let date = flight.Date;
      headLineProg.Returns[index] = changeTimeArrivalDeparture(
        flight,
        `${convertDateFormat(date, 'YYYY-MM-DD')}T${value}:00`
      );
    } else {
      let flight = headLineProg.MainPrograms[index];
      let date = flight.Date;
      headLineProg.MainPrograms[index] = changeTimeArrivalDeparture(
        flight,
        `${convertDateFormat(date, 'YYYY-MM-DD')}T${value}:00`
      );
    }
    this.props.setHeadLines(headLineProg);
  };
  setArrivalDate = date => {
    let headLineProg = copyObject(this.props.headlineProgram);
    headLineProg = changeLastArrivalDate(date, headLineProg);
    let newDate = headLineProg.Returns[headLineProg.Returns.length - 1].Date;
    this.props.setHeadLines(headLineProg);
    this.setState({ ...this.state, arrivalDate: newDate });
  };
  //convert place data for dropdown
  setPlaceData = () => {
    let region = this.props.data.RequestFrom;
    if (region) {
      let places = this.props.places.find(item => item.region === region);
      let place = places ? places.data : null;
      if (!place) {
        let allPlaces = this.props.places.reduce((result, current) => {
          result = result.concat(current.data);
          return result;
        }, []);
        place = allPlaces.filter(item => item.Region.Id === region);
      }
      return place.map(item => {
        return options(
          `arrival${item.Id}${this.props.index ? this.props.index : 'first'}`,
          item.Id,
          item.Name
        );
      });
    } else return [];
  };
  setPlaceByReqFrom = () => {
    let region = this.props.data.RequestFrom;
    if (region) {
      let places = this.props.places.find(item => item.region === region);
      let place = places ? places.data : null;
      if (!place) {
        let allPlaces = this.props.places.reduce((result, current) => {
          result = result.concat(current.data);
          return result;
        }, []);
        place = allPlaces.filter(item => item.Region.Id === region);
      }
      return place;
    } else return [];
  };
  //set the place
  handleOnchange = value => {
    //(e, { value }) => {
    this.setFlight(value);
  };
  // set place in summary program
  setFlight = value => {
    let {
      headlineProgram,
      type,
      index,
      data,
      cityList,
      placeByType,
    } = this.props;
    let headLineProg = { ...headlineProgram };
    let place =
      data.RequestFrom && ['firstDeparture', 'lastArrival'].indexOf(type) === -1
        ? this.getPlaceByRegion(data.RequestFrom)
        : placeByType;
    let airport = place.find(item => item.Id === value);
    let city = cityList.find(item => item.Id === airport.CityId);
    if (type === 'firstArrival') {
      headLineProg.Departures[index] = changeAirport(data, airport);
      headLineProg.Departures[index].City = city;
    } else if (type === 'lastArrival') {
      headLineProg.Returns[index] = changeAirport(data, airport);
      headLineProg.Returns[index].City = city;
    } else {
      headLineProg.MainPrograms[index] = changeAirport(data, airport);
      headLineProg.MainPrograms[index].City = city;
    }
    this.props.setHeadLines(headLineProg);
  };
  //filter place array based on region
  getPlaceByRegion = region => {
    let { places } = this.props;
    let data = places.filter(item => item.region === region);
    return data.length > 0 ? data[0].data : [];
  };
  //function to decide the arrival is need change or not
  isNeedChange = () => {
    let { index, data, headlineProgram } = this.props;
    return index
      ? isNeedChangeArrivalDeparture(
          data,
          headlineProgram.MainPrograms,
          index + 1,
          'Arrival'
        )
      : false;
  };
  //function to edit note
  handleEditFlightNumber = (e, { value }) => {
    this.editFlightNumber(value);
  };
  editFlightNumber = value => {
    let { headlineProgram, type, index } = this.props;
    let headLineProg = copyObject(headlineProgram);

    if (type === 'lastArrival') {
      headLineProg.Departures[index].Note = value;
    } else if (type === 'connectionFlight') {
      headLineProg.MainPrograms[index].Note = value;
    }
    this.props.setHeadLines(headLineProg);
  };
  nextDay = () => {
    let { headlineProgram, index, data, type } = this.props;
    let newHeadline = copyObject(headlineProgram);
    let newDate = data.NextDay ? SubDays(data.Date, 1) : SumDays(data.Date, 1);
    if (type === 'connectionFlight') {
      newHeadline.MainPrograms = setDateSummaryProgramByIndex(
        newHeadline.MainPrograms,
        newHeadline.Departures[0],
        index,
        newDate
      );
      newHeadline.Returns = setReturnsDate(
        newHeadline.Returns,
        newHeadline.MainPrograms
      );
    } else if (type === 'lastArrival') {
      let newArrival = { ...newHeadline.Returns[index] };
      newArrival.Date =
        convertToStringDate(newDate) + convertToStringTime(newArrival.Date);
      newArrival.NextDay = !newArrival.NextDay;
      newHeadline.Returns[index] = newArrival;
    } else {
      let firstArrival = { ...newHeadline.Departures[index] };
      firstArrival.Date =
        convertToStringDate(newDate) + convertToStringTime(firstArrival.Date);
      firstArrival.NextDay = !firstArrival.NextDay;
      newHeadline.Departures[index] = firstArrival;

      newHeadline.MainPrograms = setDateSummaryProgram(
        firstArrival,
        newHeadline.MainPrograms
      );
      newHeadline.Returns = setReturnsDate(
        newHeadline.Returns,
        newHeadline.MainPrograms
      );
    }

    this.props.setHeadLines(newHeadline);
  };
  isNextDayDisable = () => {
    const { type, headlineProgram, index } = this.props;
    if (type === 'connectionFlight') {
      return headlineProgram.MainPrograms[index + 1].NextDay;
    } else if (type === 'lastDeparture') {
      return headlineProgram.Returns[index + 1]
        ? headlineProgram.Returns[index + 1].NextDay
        : true;
    } else if (type === 'lastArrival') {
      return headlineProgram.Returns[index - 1]
        ? headlineProgram.Returns[index - 1].NextDay
        : true;
    } else return false;
  };
  render() {
    const { t } = this.props;
    const { arrivalTime } = this.state;
    const {
      type,
      data,
      loading,
      headlineProgram,
      index,
      placeByType,
      places,
    } = this.props;
    const arrivalDate =
      type === 'firstArrival'
        ? headlineProgram.Departures[headlineProgram.Departures.length - 1].Date
        : data.Date;
    // const options =
    //   type === 'lastArrival' ? allPlacesByType : this.setPlaceData();
    const options =
      type === 'lastArrival'
        ? initialAirport(placeByType)
        : initialAirport(this.setPlaceByReqFrom(places));
    const isOverlapp = isOverlapping(headlineProgram, type, index);
    const isNextDayDisable = this.isNextDayDisable();
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Field width={16}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="7">
                        <label style={style.dateTimeLabelStyle}>
                          {/* Arrival to */}
                          {t('arrivalTo')}
                        </label>
                      </Grid.Column>
                      <Grid.Column width="9" textAlign="right">
                        <Checkbox
                          label={t('nextDayArrival')} //"Next Day Arrival"
                          defaultChecked={data.NextDay}
                          onChange={this.nextDay}
                          disabled={isNextDayDisable}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <CustomSelect
                    airport={options}
                    value={data.PlaceId ? data.PlaceId : ''}
                    handleSelect={this.handleOnchange}
                  />
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field width={16}>
                    <InputDate
                      label={t('arrivalDate')} //"Arrival Date"
                      value={arrivalDate}
                      inputStyles={styles.inputDateStyle}
                      setDate={this.setArrivalDate}
                      labelStyle={style.dateTimeLabelStyle}
                      iconStyle={styles.primaryColor}
                      readonly={type === 'lastArrival' ? false : true}
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <InputTime
                      labelText={t('arrivalTime')} //"Arrival Time"
                      labelStyles={style.dateTimeLabelStyle}
                      context={this}
                      value={arrivalTime}
                      setTime={this.setArrivalTime}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field width={16}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="7">
                        <label style={style.dateTimeLabelStyle}>
                          {t('notes')}
                        </label>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Message
                    style={style.noteStyles}
                    content={t('allDateAndTimeAreLocal')} //"All date and time are local for each city"
                  />
                  <div style={style.displayNone}>
                    <InputWithLabel
                      label={t('flightNumber')} //"Flight Number"
                      type="text"
                      value={data.Note ? data.Note : ''}
                      labelStyles={style.dateTimeLabelStyle}
                      handleChange={this.handleEditFlightNumber}
                      fluid={true}
                    />
                  </div>
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={style.noPadding}>
            <Grid.Column>
              {isOverlapp && (
                <Message error>
                  <p>
                    {/* Warning!! your arrival time is overlapping */}
                    <strong>{t('warningArrivalOverlapping')}.</strong>{' '}
                  </p>
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
          <Loader active={loading} inverted={true} />
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    places: state.location.places,
    loading: state.location.loading,
    placeByType: state.location.placeByType,
  };
};
Arrival.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  headlineProgram: PropTypes.object,
  setHeadLines: PropTypes.func,
  places: PropTypes.array,
  index: PropTypes.number,
  loading: PropTypes.bool,
  cityList: PropTypes.array,
  placeByType: PropTypes.array,
  t: PropTypes.func,
};
export default connect(mapStateToProps)(withTranslation()(Arrival));
