import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment } from 'semantic-ui-react';
import { LoaderModal, ButtonCloseModal } from '../../../common';
import CardHeader from './CardHeader';
import CardDescription from './CardDescription';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const ModalDetailExcursion = props => {
  const { t } = useTranslation();
  let {
    open,
    onClose,
    profile,
    loading,
    showButtonChoose,
    handleChoose,
  } = props;

  return (
    <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {/* Excursion Detail */}
        {t('excursionDetail')}
        <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description style={styles.minHeight50px}>
          <Segment.Group style={styles.segmentNoPadding}>
            <LoaderModal loading={loading} />
            {!loading && (
              <CardHeader
                name={profile.Name}
                location={profile.Address}
                images={profile.ObjectImages}
                image={profile.ImageUrl}
              />
            )}
            {!loading && (
              <CardDescription
                category={profile.AttractionCategory}
                type={profile.AttractionType ? profile.AttractionType.Name : ''}
                startTime={profile.OperationStartTime}
                endTime={profile.OperationEndTime}
                id={profile.ServiceItemId}
                showButton={showButtonChoose}
                handleChoose={handleChoose}
                description={profile.Description}
              />
            )}
          </Segment.Group>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
ModalDetailExcursion.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  profile: PropTypes.object,
  showButtonChoose: PropTypes.bool,
  loading: PropTypes.bool,
  handleChoose: PropTypes.func,
  t: PropTypes.func,
};
export default ModalDetailExcursion;
