import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { Grid, Button, Icon, Header, Segment } from 'semantic-ui-react';
import Input from '../../../../components/input/WithLabel';
import styles from './styles';
// import { connect } from 'react-redux';
// import options from '../../../../genericFunction/selectOptionsConverter';
import { useTranslation } from 'react-i18next';

// import i18n from '../../../../i18n';

const FilterTourPackage = props => {
  const { t } = useTranslation();
  const {
    openTourFilter,
    handleChange,
    handleChangeOpenSearch,
    descLocation,
    optionsLocation,
    handleSearch,
    loadingLocation,
    searchPackage,
    listTourPaxTypes,
    loadingTourPaxType,
    tourTypes,
    loadingTourType,
    accommodationTypes,
    loadingAccommodationTypes,
    accommodationRatings,
    loadingAccommodationRating,
    accommodationLocations,
    loadingAccommodationLocations,
  } = props;
  return (
    <Grid>
      <Grid.Row style={styles.paddingTopBottom10px}>
        <Grid.Column width="12">
          {descLocation ? (
            <Header size="medium" style={styles.paddingTopBottom10px}>
              {/* Showing Package For */}
              {t('showingPackageFor')}
              <strong style={styles.goldColor}> {descLocation}</strong>
            </Header>
          ) : null}
        </Grid.Column>
        <Grid.Column width="4">
          <Button
            floated="right"
            onClick={handleChangeOpenSearch}
            style={styles.buttonGold}
            value={!openTourFilter}
            name="openTourFilter"
          >
            {/* CHANGE SEARCH */}
            {t('ChangeSearch')}
            {openTourFilter ? (
              <Icon style={styles.paddingLeftRight15px} name="chevron up" />
            ) : (
              <Icon style={styles.paddingLeftRight15px} name="chevron down" />
            )}
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={styles.paddingTopBottom2px}>
        <Grid.Column width="16">
          <Collapse
            isOpened={openTourFilter}
            style={styles.collapseSearchPackage}
          >
            <Segment style={styles.segmentNoPadding}>
              <Grid>
                <Grid.Row>
                  <Grid.Column style={styles.width298}>
                    <Input
                      name="location"
                      label={t('filterByRegionOrCity')} // "Filter By Region or City"
                      type="select"
                      labelStyles={styles.textBlackBold}
                      inputStyles={styles.selectTransparent}
                      placeholder={t('placeholderRegionCity')} //"Try, 'Indonesia'"
                      icon="map marker alternate"
                      options={optionsLocation}
                      handleChange={handleChange}
                      value={searchPackage.location}
                      loading={loadingLocation}
                    />
                  </Grid.Column>
                  <Grid.Column style={styles.width298}>
                    <Input
                      name="tourType"
                      type="select"
                      inputStyles={styles.selectTransparent}
                      label={t('tourType')} //"Tour Type"
                      labelStyles={styles.textBlackBold}
                      options={tourTypes}
                      handleChange={handleChange}
                      value={searchPackage.tourType}
                      placeholder={t('placeholderTourType')} // "Leisure, Business"
                      loading={loadingTourType}
                    />
                  </Grid.Column>
                  <Grid.Column style={styles.width298}>
                    <Input
                      name="tourCategory"
                      type="select"
                      inputStyles={styles.selectTransparent}
                      label={t('tourCategory')} //"Tour Category"
                      labelStyles={styles.textBlackBold}
                      options={listTourPaxTypes}
                      handleChange={handleChange}
                      value={searchPackage.tourCategory}
                      placeholder={t('placeholderTourPaxType')} //"Honeymoon, Business"
                      loading={loadingTourPaxType}
                    />
                  </Grid.Column>
                  <Grid.Column style={styles.width190}>
                    <Input
                      name="startDate"
                      type="date"
                      inputStyles={styles.selectTransparent}
                      label={t('fromDate')} // "Tour Date From"
                      labelStyles={styles.textBlackBold}
                      handleChange={handleChange}
                      placeholder=""
                      value={searchPackage.startDate}
                    />
                  </Grid.Column>
                  <Grid.Column style={styles.width190}>
                    <Input
                      name="endDate"
                      type="date"
                      inputStyles={styles.selectTransparent}
                      label={t('toDate')} //"Tour Date To"
                      labelStyles={styles.textBlackBold}
                      handleChange={handleChange}
                      placeholder=""
                      value={searchPackage.endDate}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingTop0px}>
                  <Grid.Column style={styles.width298}>
                    <Input
                      name="accommodationType"
                      type="select"
                      inputStyles={styles.selectTransparent}
                      label={t('whereWillStay')} // "Where will you stay"
                      labelStyles={styles.textBlackBold}
                      options={accommodationTypes}
                      handleChange={handleChange}
                      value={searchPackage.accommodationType}
                      placeholder={t('placeholderAccommodationType')} //"Select accommodation"
                      loading={loadingAccommodationTypes}
                    />
                  </Grid.Column>
                  <Grid.Column style={styles.width298}>
                    <Input
                      name="accommodationRating"
                      type="select"
                      inputStyles={styles.selectTransparent}
                      label={t('accommodationRating')} //"Accommodation Rating"
                      labelStyles={styles.textBlackBold}
                      options={accommodationRatings}
                      handleChange={handleChange}
                      value={searchPackage.accommodationRating}
                      placeholder={t('placeholderAccommodationRating')} // "Select rating"
                      loading={loadingAccommodationRating}
                    />
                  </Grid.Column>
                  <Grid.Column style={styles.width298}>
                    <Input
                      name="accommodationLocation"
                      type="select"
                      inputStyles={styles.selectTransparent}
                      label={t('accommodationFacility')} // "Accommodation Facility"
                      labelStyles={styles.textBlackBold}
                      options={accommodationLocations}
                      handleChange={handleChange}
                      value={searchPackage.accommodationLocation}
                      placeholder={t('placeholderAccommodationLocation')} // "Select Facility"
                      loading={loadingAccommodationLocations}
                    />
                  </Grid.Column>
                  <Grid.Column style={styles.width380} verticalAlign="bottom">
                    <Button
                      size="large"
                      style={styles.buttonGold100percen}
                      onClick={handleSearch}
                      name="searchPackage"
                    >
                      {/* SEARCH PACKAGE */}
                      {t('SearchPackage')}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Collapse>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

FilterTourPackage.propTypes = {
  handleChange: PropTypes.func,
  handleChangeOpenSearch: PropTypes.func,
  openTourFilter: PropTypes.bool,
  tourPaxTypes: PropTypes.array,
  descLocation: PropTypes.string,
  optionsLocation: PropTypes.array,
  handleSearch: PropTypes.func,
  loadingLocation: PropTypes.bool,
  searchPackage: PropTypes.object,
  loadingTourPaxType: PropTypes.bool,
  tourTypes: PropTypes.array,
  loadingTourType: PropTypes.bool,
  accommodationTypes: PropTypes.array,
  loadingAccommodationTypes: PropTypes.bool,
  accommodationRatings: PropTypes.array,
  loadingAccommodationRating: PropTypes.bool,
  accommodationLocations: PropTypes.array,
  loadingAccommodationLocations: PropTypes.bool,
  listTourPaxTypes: PropTypes.array,
  t: PropTypes.func,
};

export default FilterTourPackage;
