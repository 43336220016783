const style = {
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
};

export default style;
