const COLOR_WHITE = '#fefefe';
// const COLOR_GOLD = '#e6ca6b';
const COLOR_RED = '#f44336';
// const COLOR_BLUE = '#6b82e6';
// const COLOR_BLACK = '#000000';
const styles = {
  //   whiteColor: {
  //     color: COLOR_WHITE,
  //   },
  //   redColor: {
  //     color: COLOR_RED,
  //   },
  //   blueColor: {
  //     color: COLOR_BLUE,
  //   },
  //   goldColor: {
  //     color: COLOR_GOLD,
  //   },
  //   backgroundGoldTransparent: {
  //     background: 'rgba(230, 202, 107,0.1)',
  //   },
  //   segment: {
  //     border: 'none',
  //     boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
  //     padding: '10px 30px 10px 30px',
  //     margin: '5px 0px 20px 0px',
  //   },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  //   segmentPaddingLeftRight15px: {
  //     border: 'none',
  //     boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
  //     padding: '10px 15px 10px 15px',
  //     margin: '5px 0px 20px 0px',
  //   },
  //   buttonGold: {
  //     padding: '15px',
  //     color: COLOR_BLACK,
  //     background: COLOR_GOLD,
  //   },
  //   buttonDelete: {
  //     padding: '15px',
  //     color: COLOR_RED,
  //     background: COLOR_WHITE,
  //   },
  textAlignCenter: {
    textAlign: 'center',
  },
  borderBottom: {
    borderBottom: '1px solid black',
  },
  withBackground: {
    padding: '10px',
    background: 'rgba(230, 202, 107,0.1)',
  },
  withNoPaddingBackground: {
    background: 'rgba(230, 202, 107,0.1)',
  },
  // labelInputRange: {
  //   borderRight: 'none',
  //   paddingRight: '0px',
  // },
  padding10px: {
    padding: '10px',
  },
  paddingBottom5px: {
    paddingBottom: '5px',
  },
  marginBottom10px: {
    marginBottom: '10px',
  },
  marginTop5px: {
    marginTop: '5px',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  fontWeight700colorRed: {
    color: 'red',
    fontWeight: '700',
  },
  width80percent: {
    width: '80%',
  },
  height50px: {
    height: '50px',
  },
  noBorder: {
    border: 'none',
  },
  buttonDelete: {
    padding: '15px',
    color: COLOR_RED,
    background: COLOR_WHITE,
  },
  marginBottom14px: {
    marginBottom: '14px',
  },
  backGroundWhiteGrey: { background: '#fbfbfb' },
};
export default styles;
