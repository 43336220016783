const BACKGROUND_COLOR = '#252525';

const style = {
  container: {
    background: BACKGROUND_COLOR,
  },
  footerMenu: {
    background: BACKGROUND_COLOR,
  },
  copyright: {
    color: 'white',
    marginBottom: '15px',
    marginTop: '20px',
  },
  columnMenu: {
    color: 'white',
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '15px',
  },
  link: {
    color: 'white',
    marginBottom: '10px',
  },
  marginBottom10px: {
    marginBottom: '10px',
  },
  marginGooglePlay: {
    marginLeft: '-10px',
    marginTop: '-10px',
  },
  marginAppStore: {
    marginLeft: '-10px',
    padding: '0px 10px',
  },
};

export default style;
