import React, { Component } from 'react';
import { Segment, Header, Icon, Loader } from 'semantic-ui-react';
import TransportationCarousel from './TransportationCarousel';
import styles from './../../styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getTransportationUnitById,
  resetStatusTransportationUnitById,
} from '../../transportationSummaryAction';
import { withTranslation } from 'react-i18next';
class SegmentTransportation extends Component {
  state = {
    transportationSum: [],
    selectTransportation: [],
  };
  componentDidUpdate = () => {
    if (
      this.state.transportationSum.length !==
      this.props.transportationSum.length
    ) {
      let { transportationSum } = this.props;
      this.setState({
        ...this.state,
        transportationSum,
        selectTransportation: [],
      });
      this.getTransportationUnit(transportationSum);
    }
    if (this.props.getTransportationUnitStatus === 'success') {
      this.setState({
        ...this.state,
        selectTransportation: this.state.selectTransportation.concat(
          this.props.transportationUnit
        ),
      });
      this.props.resetStatusTransportationUnitById();
    }
  };
  getTransportationUnit = transportationSum => {
    return (
      transportationSum.length > 0 &&
      transportationSum.map(item => {
        return (
          item.data &&
          item.data[0].ServiceItemId &&
          this.props.getTransportationUnitById(item.data[0].ServiceItemId)
        );
      })
    );
  };
  render() {
    const { t } = this.props;
    let { transportationSum, selectTransportation } = this.state;
    let { loading, packageType } = this.props;
    return (
      <div>
        <Segment style={styles.segment}>
          <Header as="h3" className="no-margin">
            <Icon className="fas fa-car fa-2x" style={styles.goldColor} />{' '}
            {/* Transportation */}
            {t('transportation')}
          </Header>
          <Loader active={loading} inline="centered" />
          {selectTransportation.length !== 0 &&
          transportationSum.length !== 0 ? (
            <div>
              <span className="margin-bottom-five" style={styles.greyColor}>
                {/* Note : We will provide you these or similar transport depending
                on availability */}
                {`${t('note')} : ${t('weWillProvideSimilarTransport')}`}
              </span>
              <TransportationCarousel
                selectTransportation={selectTransportation}
                transportationSum={transportationSum}
              />
            </div>
          ) : (
            !loading && (
              <Header as="h4" textAlign="center">
                {packageType === 'fixed'
                  ? `${t('transportationIsProvided')}` // `Transportation is Provided`
                  : `${t('noTransportationOrderedInThisPackage')}`}
                {/* // `No Transportation Ordered in this Package`} */}
              </Header>
            )
          )}
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    transportationUnit: state.transportationSummary.transportationUnit,
    getTransportationUnitStatus:
      state.transportationSummary.getTransportationUnitStatus,
    loading: state.transportationSummary.loading,
  };
};
SegmentTransportation.propTypes = {
  resetStatusTransportationUnitById: PropTypes.func,
  getTransportationUnitById: PropTypes.func,
  transportationSum: PropTypes.array,
  getTransportationUnitStatus: PropTypes.string,
  transportationUnit: PropTypes.object,
  loading: PropTypes.bool,
  packageType: PropTypes.string,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    getTransportationUnitById,
    resetStatusTransportationUnitById,
  }
)(withTranslation()(SegmentTransportation));
