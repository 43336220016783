import React, { Component } from 'react';
import { Segment, Header, Grid, Button, Icon } from 'semantic-ui-react';
import { Collapse } from 'react-collapse';
import PropTypes from 'prop-types';
import FixedDailyProgram from '../../shared/components/dailyProgram/DailyProgram'; // '../../../components/dailyProgram/fixedDailyProgram';
import styles from '../styles';
import { FIXED } from '../../../constant/packageTypes';
import setCurrentPackage from '../../shared/services/helper/setCurrentPackage';
import { withTranslation } from 'react-i18next';
class SegmentItineraryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCollapse: false,
      iconOpenCollapse: 'chevron down',
    };
  }
  openCloseCollapse = (e, { name, value }) => {
    let iconOpenCollapse = !value ? 'chevron up' : 'chevron down';
    return this.setState({ [name]: !value, iconOpenCollapse });
  };
  render() {
    const { t } = this.props;
    let { openCollapse, iconOpenCollapse } = this.state;
    let { dailyProgram, bookingDetail } = this.props;
    let currentPackage = bookingDetail
      ? setCurrentPackage(bookingDetail.PackageType)
      : FIXED;
    return (
      <div>
        <Segment style={styles.segmentNoPadding}>
          <Grid style={styles.paddingRight15px}>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as="h3">
                  <Icon
                    className="fas fa-clipboard-list fa-2x"
                    style={styles.goldColor}
                  />{' '}
                  {/* Detail Itinerary */}
                  {t('detailItinerary')}
                </Header>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  name="openCollapse"
                  value={openCollapse}
                  icon={iconOpenCollapse}
                  size="massive"
                  floated="right"
                  style={styles.transparentGold}
                  onClick={this.openCloseCollapse}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={openCollapse} style={styles.noMargin}>
            <FixedDailyProgram
              currentPackage={currentPackage}
              dailyProgram={dailyProgram}
              isStatic={true}
            />
          </Collapse>
        </Segment>
      </div>
    );
  }
}
SegmentItineraryDetail.propTypes = {
  dailyProgram: PropTypes.array,
  bookingDetail: PropTypes.object,
  t: PropTypes.func,
};
export default withTranslation()(SegmentItineraryDetail);
