import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  Advertisement,
  Benefit,
  HowItWorks,
  IndonesianSitePromotion,
  MobileAppsPromotion,
  QuickLaunch,
  TopDestination,
  TourPackageSection,
  IndonesianSiteModal,
} from './components';
import {
  modalMidEastBali,
  modalMidEastBandung,
  modalMidEastPuncak,
} from '../../assets/image/index';
import ModalLogin from '../shared/components/modal/ModalLogin';
import { ipInfoHttps, ipInfoHttp } from './constant/countryApiConstant';
import hostNameList from './constant/hostNameList';
import Partners from './components/partners/Partners';
import {
  getCarouselImage,
  getCarouselImageAfterLogin,
} from '../shared/actions/imageAction';
import {
  getFeaturedPackageAfterLoginAction,
  getFeaturedPackageBeforeLoginAction,
} from '../shared/actions/fixedPackageAction';
import DynamicTitle from '../../components/common/DynamicTitle';
const Home = () => {
  //=========initial================
  const [countryCode, setCountryCode] = useState('');
  const [openModalDetailIndonesian, setOpenModalDetailIndonesian] = useState(
    false
  );
  const [imageIndonesianDestination, setImageIndonesianDestination] = useState(
    ''
  );
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [filterValue, setFilterValue] = useState('All');
  //store
  const imageCarousel = useSelector(state => state.images.mainCarouselImage);
  const isLogin = useSelector(state => state.authentication.isLogIn);
  const featuredPackageList = useSelector(
    state => state.fixedPackage.featuredPackageList
  );
  const packageLoading = useSelector(state => state.fixedPackage.loading);
  //end of store
  //action
  const dispatch = useDispatch();
  const getCarouselImageAction = data => dispatch(getCarouselImage(data));
  const getCarouselImageAfterLoginAction = data =>
    dispatch(getCarouselImageAfterLogin(data));
  const getPackageAfterLoginAction = () =>
    dispatch(getFeaturedPackageAfterLoginAction());
  const getPackageBeforeLoginAction = country =>
    dispatch(getFeaturedPackageBeforeLoginAction(country));
  //end of action
  //=================lifecycle==================
  //CDM
  useEffect(() => {
    helperDetectCountryCode();
    setAuthToken(localStorage.getItem('token'));
    //eslint-disable-next-line
  }, []);
  //CDU
  useEffect(() => {
    if (isLogin && localStorage.getItem('token')) {
      getCarouselImageAfterLoginAction(1);
      setAuthToken(localStorage.getItem('token'));
      getPackageAfterLoginAction();
    } else if (countryCode) {
      getCarouselImageAction({
        accessForm: 1,
        countryCode: countryCode,
      });
      setAuthToken(null);
      getPackageBeforeLoginAction(countryCode);
    }
    //eslint-disable-next-line
  }, [isLogin]);
  //=================helper=====================
  //fungsi ini digunakan untuk mencari Country ID berdasarkan lokasi dimana user
  const helperDetectCountryCode = () => {
    const hostname = window && window.location && window.location.hostname;
    if (hostNameList.indexOf(hostname) !== -1) {
      axios.get(ipInfoHttps).then(res => {
        helperSetCountryAndGetCarousel(res.data.country);
        helperGetFeaturedPackage(res.data.country);
      });
    } else {
      axios.get(ipInfoHttp).then(res => {
        helperSetCountryAndGetCarousel(res.data.countryCode);
        helperGetFeaturedPackage(res.data.countryCode);
      });
    }
  };
  //fungsi ini diugunakan untuk menyimpan country code ke state dan memanggil api get image berdasarkan token
  const helperSetCountryAndGetCarousel = countryCode => {
    setCountryCode(countryCode);
    if (localStorage.getItem('token')) {
      getCarouselImageAfterLoginAction(1);
    } else {
      getCarouselImageAction({
        accessForm: 1,
        countryCode: countryCode,
      });
    }
  };
  const helperGetFeaturedPackage = countryCode => {
    if (localStorage.getItem('token')) {
      getPackageAfterLoginAction();
    } else {
      getPackageBeforeLoginAction(countryCode);
    }
  };
  //fungsi ini digunakan untuk memilih image
  //yang akan ditampilkan pada modal promotion untuk destinasi indonesia
  const helperSetImageModalDetailIndonesia = name => {
    setOpenModalDetailIndonesian(true);
    if (name === 'bali') {
      setImageIndonesianDestination(modalMidEastBali);
    } else if (name === 'puncak') {
      setImageIndonesianDestination(modalMidEastPuncak);
    } else if (name === 'bandung') {
      setImageIndonesianDestination(modalMidEastBandung);
    }
  };
  const helperFilter = () => {
    return filterValue === 'All'
      ? featuredPackageList
      : featuredPackageList.filter(
          item => item.TourPaxType.Name === filterValue
        );
  };
  //===================handler===================
  const handleOpenModalDetailIndonesia = (e, { name }) => {
    helperSetImageModalDetailIndonesia(name);
  };
  const handleCloseModalIndonesia = () => setOpenModalDetailIndonesian(false);
  const handleOpenModalLogin = () => {
    setOpenModalLogin(true);
  };
  const handleCloseModalLogin = () => setOpenModalLogin(false);
  const handleSetFilter = val => setFilterValue(val);
  //==============================================
  const packageList = helperFilter();
  return (
    <div>
      <DynamicTitle title={null} />
      <Advertisement token={authToken} imageCarousel={imageCarousel} />
      {authToken ? (
        <div>
          <QuickLaunch />
          {countryCode !== 'ID' ? (
            <IndonesianSitePromotion
              openModalIndonesia={handleOpenModalDetailIndonesia}
            />
          ) : (
            <TopDestination />
          )}
        </div>
      ) : null}
      {(!authToken && countryCode === 'ID') || authToken ? (
        <TourPackageSection
          token={authToken}
          openModal={handleOpenModalLogin}
          country={countryCode}
          loading={packageLoading}
          packageList={packageList}
          handleFilter={handleSetFilter}
        />
      ) : null}
      {!authToken && countryCode !== 'ID' ? (
        <IndonesianSitePromotion
          openModalIndonesia={handleOpenModalDetailIndonesia}
        />
      ) : null}
      <Benefit />
      <Partners />
      <HowItWorks token={authToken} />
      <MobileAppsPromotion />
      <IndonesianSiteModal
        image={imageIndonesianDestination}
        openModal={openModalDetailIndonesian}
        close={handleCloseModalIndonesia}
      />
      <ModalLogin
        openModal={openModalLogin}
        close={handleCloseModalLogin}
        size="mini"
      />
    </div>
  );
};

export default Home;
