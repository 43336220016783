import React from 'react';
import PropTypes from 'prop-types';

const ArrowNavigation = props => {
  const { className, onClick, arrowStyle, additionalClassname } = props;
  return (
    <div
      style={arrowStyle ? arrowStyle : {}}
      className={`${className} ${additionalClassname}`}
      onClick={onClick}
    />
  );
};
ArrowNavigation.propTypes = {
  arrowStyle: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  additionalClassname: PropTypes.string,
};

export default ArrowNavigation;
