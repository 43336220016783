import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Form } from 'semantic-ui-react';

const BtnUploadImage = props => {
  let myImageInput = React.createRef();
  return (
    <Form.Field inline>
      <label style={props.labelStyles}>{props.labelTitle}</label>
      <Button
        icon
        labelPosition="left"
        onClick={() => myImageInput.click()}
        disabled={props.disabled}
        style={props.styleButton}
      >
        <Icon name="upload" />
        {props.label}
      </Button>
      <div>
        <input
          hidden
          type="file"
          onChange={props.onImageSelect}
          ref={imageInput => (myImageInput = imageInput)}
          accept="image/gif,image/jpeg,image/jpg,image/png"
        />
      </div>
    </Form.Field>
  );
};

BtnUploadImage.propTypes = {
  onImageSelect: PropTypes.func,
  label: PropTypes.string,
  labelStyles: PropTypes.object,
  labelTitle: PropTypes.string,
  disabled: PropTypes.bool,
  styleButton: PropTypes.object,
};

export default BtnUploadImage;
