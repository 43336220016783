const GET_COUNTRIES_URL = '/countries';
const GET_CURRENCIES_URL = '/currencies/';
const GET_TOTAL_BRANCH_URL = '/CustomEnums/Get/ByCategories/TOTAL_BRANCH';
const GET_TOTAL_EMPLOYEE_URL = '/CustomEnums/Get/ByCategories/TOTAL_EMPLOYEE';
const GET_REVENUE_RANGE_URL = '/CustomEnums/Get/ByCategories/REVENUE_RANGE';
const POST_LOGIN_URL = '/Account/Login';
const GET_USER_INFO_URL = '/Account/UserInfo';
const GET_FIXED_PACKAGE_ALL_URL =
  '/BookingTemplates/GetTemplates/FixedPackage/All';
const GET_FIXED_PACKAGE_WITH_FILTER_URL =
  '/BookingTemplates/GetTemplates/FixedPackage';
const GET_CAROUSEL_IMAGE_URL = '/ApplicationProfiles/CarouselImages';
const GET_TOUR_TYPES_URL = '/TourTransactions/TourTypeList';
const GET_TOUR_PAX_TYPES_URL = '/TourTransactions/TourPaxTypeList';
const GET_ACCOMMODATION_TYPES_URL = '/AccommodationTypes';
const GET_ACCOMMODATION_RATINGS_URL = '/AccommodationRatings';
const GET_ACCOMMODATION_LOCATIONS_URL = '/AccommodationLocations';
const GET_CITY_IN_COUNTRY_URL = '/Cities/CityInCountry';
const GET_READY_PACKAGE_ALL_URL =
  '/BookingTemplates/GetTemplates/ReadyPackage/All';
const GET_READY_PACKAGE_WITH_FILTER_URL =
  '/BookingTemplates/GetTemplates/ReadyPackage';
const GET_ACCOMMODATIONS_URL = '/AccommodationProfiles/v2/Filter/ShowPrice';
const GET_ACCOMMODATION_ROOMS_URL = '/AccommodationItems/byprofile/ShowPrice';
const GET_EXCURSION_TYPES_URL = '/AttractionTypes';
const GET_FIXED_PACKAGE_BY_ID_URL = packageType =>
  `/BookingTemplates/GetTemplates/${packageType}`;
// const GET_FIXED_PACKAGE_BY_ID_URL =
//   '/BookingTemplates/GetTemplates/Admin/FixedDateVariable';
const GET_BROCHURE_URL = '/BookingTemplates/GetBrochure';
const GET_SCHEDULE_URL = '/BookingTemplates/DownloadSchedule';
const GET_EXCURSION_BY_FILTER_URL =
  '/AttractionObjects/ByCityWithFilter/ShowPrice';
const GET_RESTAURANT_BY_FILTER_URL = '/RestaurantProfiles/ByCity/ShowPrice';
const GET_RESTAURANT_RATING_URL = '/RestaurantRatings';
const GET_RESTAURANT_SPECIALIZATIONS_URL = '/RestaurantProfileSpecializations';
const GET_RESTAURANT_MENU_CLASSES_URL = '/RestaurantItems/MenuClasses';
const GET_RESTAURANT_MENU_BY_ID_URL = '/RestaurantItems/ByProfileId';
const GET_ACCOMMODATION_FACILITIES_URL = '/AccommodationFacilities';
const GET_TRANSPORTATION_PROFILE_RATINGS_URL = '/TransportationProfileRatings';
const GET_TRANSPORTATION_PROFILE_CATEGORIES_URL =
  '/TransportationProfileCategories';
const GET_TRANSPORTATION_PROFILES_URL = '/TransportationProfiles';
const GET_TRANSPORTATION_TYPES_URL = '/TransportationTypes';
const GET_TRANSPORTATION_SEAT_TYPES_URL = '/TransportationSeatTypes';
const GET_TRANSPORTATION_UNITS_FILTER_URL =
  '/TransportationUnits/TransportationService/ShowPrice';
const GET_ACCOMMODATION_PROFILES_URL = '/AccommodationProfiles';
const GET_IDENTITY_TYPE_URL = '/TourTransactions/IdentityTypeList';
const GET_GUEST_TITLE_TYPE_URL = '/TourTransactions/GuestTitleList';
const POST_DEMO_JOIN_TOUR_URL = '/TourTransactions/Demo/JoinTour';
const POST_DEMO_PRICE_URL = '/TourTransactions/DemoPrice';
const GET_TOUR_OPERATOR_PROFILE_URL = '/TourOperatorProfiles/ById/';
const GET_TRANSACTION_HISTORY_ALL_URL =
  '/TourTransactions/TransactionHistory/All';
const GET_USER_PROFILE_URL = '/UserProfiles/UserProfile';
const GET_COMPANY_PROFILE_URL = '/Companies/CompanyProfile';
const POST_TOUR_TRANSACTION_CONFIRMATION_URL = '/TourTransactions/Confirmation';
const GET_TOUR_TRANSACTION_SUMMARY_URL = '/TourTransactions/TransactionSummary';
const GET_SCHEDULE_TOUR_TRANSACTION_URL = '/TourTransactions/DownloadSchedule';
const GET_INVOICE_URL = '/TourTransactions/GeneratePDFTransaction';
const GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_URL =
  '/TourTransactions/SupplementItem/ByTransactionId/Payable';
const GET_TOUR_TRANSACTION_TO_BE_PAYED_URL = '/TourTransactions/ToBePayed';
const GET_TRANSPORTATION_UNIT_BY_ID_URL = '/TransportationUnits';
const GET_EXCURSION_BY_ID_URL = '/AttractionObjects/ById';
const GET_MENU_BY_ID_URL = '/RestaurantItems';
const GET_PLACE_BY_REGION_URL = '/Places/Get/ByRegion/';
const GET_MOVEMENT_LIST_URL = '/MovementModes';
const GET_DRIVING_DURATION_URL = '/TourTransactions/Movement';
const PUT_UPDATE_COMPANY_URL = '/Companies/UpdateCompany';
const POST_CHANGE_PASSWORD_URL = '/Account/ChangePassword';
const POST_REGISTER_URL = '/Account/RegisterCompany';
const POST_ACCEPT_QUOTATION_URL = '/TourTransactions/AcceptQuotation';
const POST_EDIT_QUOTATION_URL = '/TourTransactions/EditQuotation';
const POST_CREATE_TOUR_URL = '/TourTransactions/CreateTour';
const POST_JOIN_TOUR_URL = '/TourTransactions/JoinTour';
// const POST_JOIN_TOUR_FIX_PRICE_URL = '/TourTransactions/JoinTour/VariableDate/';
const GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_URL =
  '/RestaurantProfiles/ByServiceItemId';
const GET_USER_COMPANY_LIST_URL = '/UserProfiles/CompanyUsers';
const POST_REGISTER_COMPANY_USER_URL = 'Account/RegisterCompanyUser';
const GET_TOUR_OPERATOR_LIST_URL = '/TourTransactions/TourOperatorList';
const GET_READY_PACKAGE_BY_ID_URL = '/BookingTemplates/GetTemplates/Ready/';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL =
  '/TourTransactions/SupplementItem/ByFixedPackageId';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_URL =
  '/TourTransactions/v2/Confirmation/Demo';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_URL =
  '/TourTransactions/v2/Confirmation';
const POST_TOUR_TRANSACTIONS_TOP_UP_URL = '/TourTransactions/TopUp';
const GET_TOUR_TRANSACTION_BILLED_URL = '/TourTransactions/Billed';
const POST_FORGOT_PASSWORD_URL = '/Account/ForgetPassword/request';
const POST_RESET_PASSWORD_URL = '/Account/ForgetPassword/verify';
const GET_USER_ROLES_URL = '/Account/UserRoles';
const GET_COMPANIES_URL = '/Companies/CompanyProfile/Sales/AdminSimplified';
// const GET_COMPANIES_URL = '/Companies/CompanyProfile/Admin/ByType';
const POST_CREATE_TOUR_ON_BE_HALF_URL = '/TourTransactions/CreateTour/OnBehalf';
const POST_JOIN_TOUR_ON_BE_HALF_URL = '/TourTransactions/JoinTour/OnBehalf';
const POST_REGISTER_SIMPLE_CUSTOMER_URL =
  '/Account/RegisterCompany/SimpleCustomer';
const GET_USER_PROFILE_BY_COMPANY_CODE_URL =
  '/UserProfiles/CompanyUsers/ByCompanyCode';
const POST_RESET_STAFF_PASSWORD_URL = '/Account/ResetStaffPassword';
const PUT_UPDATE_PROFILE_URL = '/UserProfiles/UpdateProfile';
const GET_FIXED_PACKAGE_LANDING_PAGE_URL =
  '/BookingTemplates/GetTemplates/FixedPackage/LandingPage';
const GET_GUEST_LIST_TEMPLATE_URL = '/TourGuests/GetTemplate';
const GET_BROCHURE_OVERLAY_URL = '/BookingTemplates/GetOverlayBrochure/';
const GET_INVOICE_TRAVEL_AGENT_URL =
  '/TourTransactions/DownloadInvoice?tourTransactionId=';
const GET_INVOICE_CUSTOMER_URL =
  '/TourTransactions/DownloadInvoiceForGuest?tourTransactionId=';
const LANGUAGE_URL = '/Languages';
const GET_VOUCHER_CONFIRMATION_URL =
  '/TourTransactions/DownloadVoucherConfirmation?tourTransactionId=';
const GET_USER_PROFILE_FOR_SALES_BY_ID_URL =
  '/UserProfiles/CompanyUsers/Sales/ByCompanyCode?companyCode=';
const GET_ACCOMMODATION_FILTER_PARAMETER_URL =
  '/AccommodationProfiles/AccommodationFilterParameter';
const GET_TOUR_TRANSACTION_BILLS_URL = '/TourTransactions/Bills';
const GET_FEATURED_PACKAGE_BEFORE_LOGIN_URL =
  '/BookingTemplates/GetTemplates/FeaturedPackages?countryId=';
const GET_FEATURED_PACKAGE_AFTER_LOGIN_URL =
  '/BookingTemplates/GetTemplates/FeaturedPackagesLogin';
const GET_PLACE_BY_PLACE_TYPE_ID_URL = '/Places/Get/ByPlaceType/';
const GET_CANCELLATION_REASON_BY_TYPES_URL = type =>
  '/CancellationReasons/ByType/' + type;
const GET_AIRLINE_SCHEDULE_AVAILIBILITY_URL =
  '/AirlineSchedules/AirlineScheduleAvailibility';
const GET_AIRLINE_TICKET_BY_SERVICEITEMID_URL = '/AirlineSchedules/';
const GET_CLASS_AIRLINE_URL = 'AirlineSchedules/GetFlightSeatClassTypeList';
// additional service
const GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL = (
  originalCountry,
  destinationCountry,
  currency
) =>
  `/AdditionalServices/GetList/${originalCountry}/${destinationCountry}/${currency}`;

const GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_URL = (
  bookingTemplateId,
  companyCode
) => `
/BookingTemplates/GetOverlayBrochure/${bookingTemplateId}/${companyCode}`;
const GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL = (
  bookingTemplateId,
  companyCode,
  imageId
) => `
/BookingTemplates/GetOverlayBrochureImage/${bookingTemplateId}/${companyCode}/${imageId}`;

const GET_DOWNLOAD_BROCHURE_USING_CUSTOM_PRICE_URL = (
  bookingTemplateId,
  companyCode,
  imageId,
  currency,
  price
) => `
/BookingTemplates/GetOverlayBrochureImage/${bookingTemplateId}/${companyCode}/${imageId}/${currency}/${price}`;

export {
  GET_CURRENCIES_URL,
  GET_TOTAL_BRANCH_URL,
  GET_COUNTRIES_URL,
  GET_TOTAL_EMPLOYEE_URL,
  GET_REVENUE_RANGE_URL,
  POST_LOGIN_URL,
  GET_USER_INFO_URL,
  GET_FIXED_PACKAGE_ALL_URL,
  GET_FIXED_PACKAGE_WITH_FILTER_URL,
  GET_CAROUSEL_IMAGE_URL,
  GET_TOUR_TYPES_URL,
  GET_TOUR_PAX_TYPES_URL,
  GET_ACCOMMODATION_TYPES_URL,
  GET_ACCOMMODATION_RATINGS_URL,
  GET_ACCOMMODATION_LOCATIONS_URL,
  GET_CITY_IN_COUNTRY_URL,
  GET_READY_PACKAGE_ALL_URL,
  GET_READY_PACKAGE_WITH_FILTER_URL,
  GET_ACCOMMODATIONS_URL,
  GET_ACCOMMODATION_ROOMS_URL,
  GET_EXCURSION_TYPES_URL,
  GET_FIXED_PACKAGE_BY_ID_URL,
  GET_BROCHURE_URL,
  GET_SCHEDULE_URL,
  GET_EXCURSION_BY_FILTER_URL,
  GET_RESTAURANT_BY_FILTER_URL,
  GET_RESTAURANT_RATING_URL,
  GET_RESTAURANT_SPECIALIZATIONS_URL,
  GET_RESTAURANT_MENU_CLASSES_URL,
  GET_RESTAURANT_MENU_BY_ID_URL,
  GET_ACCOMMODATION_FACILITIES_URL,
  GET_TRANSPORTATION_PROFILE_RATINGS_URL,
  GET_TRANSPORTATION_PROFILE_CATEGORIES_URL,
  GET_TRANSPORTATION_PROFILES_URL,
  GET_TRANSPORTATION_TYPES_URL,
  GET_TRANSPORTATION_SEAT_TYPES_URL,
  GET_TRANSPORTATION_UNITS_FILTER_URL,
  GET_ACCOMMODATION_PROFILES_URL,
  GET_IDENTITY_TYPE_URL,
  GET_GUEST_TITLE_TYPE_URL,
  POST_DEMO_JOIN_TOUR_URL,
  POST_DEMO_PRICE_URL,
  GET_TOUR_OPERATOR_PROFILE_URL,
  GET_TRANSACTION_HISTORY_ALL_URL,
  GET_USER_PROFILE_URL,
  GET_COMPANY_PROFILE_URL,
  POST_TOUR_TRANSACTION_CONFIRMATION_URL,
  GET_TOUR_TRANSACTION_SUMMARY_URL,
  GET_SCHEDULE_TOUR_TRANSACTION_URL,
  GET_INVOICE_URL,
  GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_URL,
  GET_TOUR_TRANSACTION_TO_BE_PAYED_URL,
  GET_TRANSPORTATION_UNIT_BY_ID_URL,
  GET_EXCURSION_BY_ID_URL,
  GET_MENU_BY_ID_URL,
  GET_PLACE_BY_REGION_URL,
  GET_MOVEMENT_LIST_URL,
  GET_DRIVING_DURATION_URL,
  PUT_UPDATE_COMPANY_URL,
  POST_CHANGE_PASSWORD_URL,
  POST_REGISTER_URL,
  POST_ACCEPT_QUOTATION_URL,
  POST_EDIT_QUOTATION_URL,
  POST_CREATE_TOUR_URL,
  POST_JOIN_TOUR_URL,
  GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_URL,
  GET_USER_COMPANY_LIST_URL,
  POST_REGISTER_COMPANY_USER_URL,
  GET_TOUR_OPERATOR_LIST_URL,
  GET_READY_PACKAGE_BY_ID_URL,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_URL,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_URL,
  POST_TOUR_TRANSACTIONS_TOP_UP_URL,
  GET_TOUR_TRANSACTION_BILLED_URL,
  POST_FORGOT_PASSWORD_URL,
  POST_RESET_PASSWORD_URL,
  GET_USER_ROLES_URL,
  GET_COMPANIES_URL,
  POST_CREATE_TOUR_ON_BE_HALF_URL,
  POST_JOIN_TOUR_ON_BE_HALF_URL,
  POST_REGISTER_SIMPLE_CUSTOMER_URL,
  GET_USER_PROFILE_BY_COMPANY_CODE_URL,
  POST_RESET_STAFF_PASSWORD_URL,
  PUT_UPDATE_PROFILE_URL,
  GET_FIXED_PACKAGE_LANDING_PAGE_URL,
  GET_GUEST_LIST_TEMPLATE_URL,
  GET_BROCHURE_OVERLAY_URL,
  GET_INVOICE_TRAVEL_AGENT_URL,
  GET_INVOICE_CUSTOMER_URL,
  LANGUAGE_URL,
  GET_VOUCHER_CONFIRMATION_URL,
  GET_USER_PROFILE_FOR_SALES_BY_ID_URL,
  GET_ACCOMMODATION_FILTER_PARAMETER_URL,
  GET_TOUR_TRANSACTION_BILLS_URL,
  GET_FEATURED_PACKAGE_BEFORE_LOGIN_URL,
  GET_FEATURED_PACKAGE_AFTER_LOGIN_URL,
  GET_PLACE_BY_PLACE_TYPE_ID_URL,
  GET_CANCELLATION_REASON_BY_TYPES_URL,
  GET_AIRLINE_SCHEDULE_AVAILIBILITY_URL,
  GET_AIRLINE_TICKET_BY_SERVICEITEMID_URL,
  GET_CLASS_AIRLINE_URL,
  GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL,
  GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_URL,
  GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL,
  GET_DOWNLOAD_BROCHURE_USING_CUSTOM_PRICE_URL,
};
