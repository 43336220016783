import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
const TextMoney = props => {
  let money = props.money ? props.money : 0;
  return (
    <p style={money < 0 ? styles.redColor : {}}>
      {money < 0
        ? `(${props.currencyId} ${Math.abs(money).toLocaleString('id')})`
        : `${props.currencyId} ${money.toLocaleString('id')}`}
    </p>
  );
};
TextMoney.propTypes = {
  money: PropTypes.number,
  currencyId: PropTypes.string,
};
export default TextMoney;
