import {
  GET_COMPANY_PROFILE,
  PUT_UPDATE_COMPANY,
  RESET_UPDATE_COMPANY_STATUS,
  RESET_COMPANY_PROFILE_STATUS,
  GET_COMPANIES,
} from './actionTypes';
import {
  getCompanyProfileApi,
  putUpdateCompanyApi,
  getCompaniesApi,
} from '../../../api/companyApi';
const getCompanyProfile = () => {
  return dispatch => {
    return dispatch({
      type: GET_COMPANY_PROFILE,
      payload: getCompanyProfileApi,
    });
  };
};

const putUpdateCompany = (code, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_UPDATE_COMPANY,
      payload: putUpdateCompanyApi(code, data),
    });
  };
};

const resetUpdateCompanyStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_UPDATE_COMPANY_STATUS });
  };
};
const resetCompanyProfileStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_COMPANY_PROFILE_STATUS });
  };
};

const getCompanies = type => {
  return dispatch => {
    return dispatch({
      type: GET_COMPANIES,
      payload: getCompaniesApi(type),
    });
  };
};
export {
  getCompanyProfile,
  putUpdateCompany,
  resetUpdateCompanyStatus,
  resetCompanyProfileStatus,
  getCompanies,
};
