import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import TourPackages from './TourPackages';
import PackageFilter from './PackageFilter';
import PropTypes from 'prop-types';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const TourPackageSection = props => {
  const { t } = useTranslation();
  const { openModal, token, loading, packageList, handleFilter } = props;

  let authLogin = localStorage.getItem('token');
  return (
    <Container
      className="margin-bottom-fifteen padding-side-twentyfive container-fit"
      style={{ marginTop: '40px' }}
    >
      {authLogin ? (
        <div>
          <h1
            className="margin-bottom-twentyfive margin-bottom-ten"
            style={{
              ...styles.filterHeaderStyle,
              ...styles.marginLeftRight20,
            }}
          >
            {/* Lets us take care of the rest your tour with our series package */}
            {t('letsUsTakeCareTheRest')}
          </h1>
          <PackageFilter
            handleChangeFilter={handleFilter}
            packageLength={packageList.length}
          />
        </div>
      ) : (
        <h1
          className="margin-bottom-twentyfive margin-bottom-ten "
          style={styles.featuredPAckageHeaderStyle}
        >
          {/* Featured Packages */}
          {t('featuredPackages')}
        </h1>
      )}

      {loading ? (
        <Segment style={styles.loadingStyle} basic loading />
      ) : packageList.length > 0 ? (
        <TourPackages
          token={token}
          packages={packageList}
          openModal={openModal}
        />
      ) : (
        // no package found
        <h2 style={styles.textCenter}>{t('noPackageFound')}</h2>
      )}
    </Container>
  );
};

TourPackageSection.propTypes = {
  loading: PropTypes.bool,
  token: PropTypes.string,
  openModal: PropTypes.func,
  packageList: PropTypes.array,
  handleFilter: PropTypes.func,
  t: PropTypes.func,
};
export default TourPackageSection;
