//------library-----
import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Icon, Button, Label } from 'semantic-ui-react';
//-------component------
import ImageCard from '../../card/ImageCard';
//-------style-------
import Styles from './styles';
//-------asset-------
import onholdIcon from '../../../../../assets/icon/globalIcon/spinner.png';
import availableIcon from '../../../../../assets/icon/globalIcon/badge_check.png';
import { useTranslation } from 'react-i18next';

const HeaderRoomCard = props => {
  const { t } = useTranslation();
  let {
    handleChange,
    openDesc,
    name,
    type,
    capacity,
    image,
    images,
    isPromo,
    isInstantConfirmation,
    data,
  } = props;
  return (
    <Segment.Group horizontal>
      <ImageCard images={images} image={image} isPromo={isPromo} size="300px" />
      {/* <Segment style={bgImage(image ? image : noImage)} /> */}
      <Segment style={Styles.BorderZero}>
        <Grid style={Styles.margin3}>
          <Header as="h3">{name}</Header>
          <Grid.Row style={Styles.noPaddingTop}>
            <Grid.Column width={2}>
              <Icon name="bed" size="large" style={Styles.goldColor} />
            </Grid.Column>
            <Grid.Column width={6}>
              <p>{type}</p>
            </Grid.Column>
            <Grid.Column width={2}>
              <Icon name="user" size="large" style={Styles.goldColor} />
            </Grid.Column>
            <Grid.Column width={6}>
              {/* <p>{capacity} Guest / Room</p> */}
              <p>
                {capacity} {t('guest')} / {t('room')}
              </p>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row style={Styles.noPaddingTop}>
            <Grid.Column width="2">
              <Icon name="user" size="large" style={Styles.goldColor} />
            </Grid.Column>
            <Grid.Column width="14">
              <p>{capacity} Guest / Room</p>
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row style={Styles.noPaddingNoMargin}>
            <Grid.Column width={8}>
              {isInstantConfirmation ? (
                <Label basic style={Styles.labelAvailable}>
                  <img alt="" src={availableIcon} style={Styles.marginRight5} />
                  {/* Available */}
                  {t('available')}
                </Label>
              ) : (
                <Label basic style={Styles.labelOnRequest}>
                  <img alt="" src={onholdIcon} style={Styles.marginRight5} />
                  {/* On Request */}
                  {t('onRequest')}
                </Label>
              )}
            </Grid.Column>
            <Grid.Column width={8} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h5" style={{ ...Styles.noMargin, ...Styles.font12 }}>
                {/* Estimated Total Price: */}
                {t('estimatedTotalPrice')}:
              </Header>
              <Header
                as="h4"
                style={{ ...Styles.goldColor, ...Styles.noMargin }}
              >
                {`${
                  data.EstimatedTotalPrice.CurrencyId
                } ${data.EstimatedTotalPrice.Price.toLocaleString('id')}`}
              </Header>
            </Grid.Column>
            <Grid.Column width={8} floated="right">
              <Button
                size="small"
                floated="right"
                style={Styles.buttonGoldHeader}
                onClick={handleChange}
              >
                {/* {openDesc ? 'LESS DETAIL' : 'SEE DETAIL'} */}
                {openDesc ? `${t('LessDetail')}` : `${t('SeeDetail')}`}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};
HeaderRoomCard.propTypes = {
  handleChange: PropTypes.func,
  openDesc: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  capacity: PropTypes.number,
  image: PropTypes.string,
  images: PropTypes.array,
  isPromo: PropTypes.bool,
  isInstantConfirmation: PropTypes.bool,
  t: PropTypes.func,
  data: PropTypes.object,
};
export default HeaderRoomCard;
