import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

const updateCompanyButton = props => {
  const { onClick, contextstate, text, stylesButton, disabled } = props;
  return (
    <Button
      style={stylesButton.updateUserButton}
      contextstate={contextstate}
      onClick={onClick}
      floated="right"
      disabled={disabled}
    >
      <strong>{text}</strong>
      <Icon name="edit" style={stylesButton.marginLeftFive} />
    </Button>
  );
};
updateCompanyButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  contextstate: PropTypes.object,
  stylesButton: PropTypes.object,
  disabled: PropTypes.bool,
};

export default updateCompanyButton;
