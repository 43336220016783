import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Modal, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import CollapseButton from '../../../../../components/button/BtnCollapse';
import styles from '../styles';
import { Collapse } from 'react-collapse';
import Arrival from './ArrivalCompact';
import Departure from './DepartureCompact';
import Accommodation from './Accommodation';
import { resetStatusGetPlaceByRegion } from '../../../actions/locationAction';
import {
  addSummaryProgram,
  setDateSummaryProgram,
  // setDateDeparture,
  isAccommodationEmpty,
  changeAccomodation,
  isHeadLineConflict,
  isValidAirport,
  delConectionFlight,
} from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import {
  setHeadlineProgram,
  setDailyProgram,
  setFirstDestination,
} from '../../../actions/customPackageAction';
// import { convertDateFormat } from '../../../../../genericFunction/moment';
import ModalListAccommodation from '../modalListAccommodation/ModalListAccommodation';
import {
  getAccommodations,
  getAccommodationFilterParameter,
} from '../../../actions/accommodationAction';
import { ModalAlert } from './../';
import { getTourTypes } from '../../../actions/tourTransactionAction';
import {
  validationGeneralInform,
  validationCapacityPackage,
} from '../../../../../genericFunction/validation';
import { setReturnsDate } from '../../../../../genericFunction/itineraryBuilder/itineraryBuilder';
import modalStyle from './style';
import { withTranslation } from 'react-i18next';
import { generateDemoPrice } from '../../../../../genericFunction/itineraryBuilder/demoPrice';

class ModalDestinationsAndDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
      openModalAccommodation: false,
      accomDestination: '',
      accomDestinationName: '',
      accomIndex: null,
      HeadlineProgram: {
        Departures: [],
        MainPrograms: [],
        Returns: [],
      },
      dailyProgram: [],
      errorMessage: '',
      openModalAlert: false,
      dataAccommodation: {},
      filter: {
        ratingId: '',
        areaId: '',
        locationId: '',
        typeId: '',
        facilityId: '',
        promoOnly: false,
      },
      demoPriceObj: {},
    };
  }
  setAccommodationOnChangeIndex = id => {
    this.setState({
      ...this.state,
      accomIndex: id,
    });
  };
  onOpen = () => {
    let {
      generalInfo,
      firstDestination,
      errorGeneralInformation,
      guests,
    } = this.props;
    let newErrors = validationGeneralInform(
      generalInfo,
      firstDestination,
      errorGeneralInformation
    );
    newErrors = validationCapacityPackage(
      generalInfo,
      errorGeneralInformation,
      guests
    );
    if (newErrors.isError) {
      this.props.setErrorGeneralInformation(newErrors.data);
    } else {
      this.props.setErrorGeneralInformation(newErrors.data);
      this.setInitialData();
      this.openModalDestination();
    }
  };
  openModalDestination = () => {
    this.props.openModal();
  };
  setInitialData = () => {
    let { dailyProgram, HeadlineProgram } = this.props;
    this.setState({
      ...this.state,
      dailyProgram: dailyProgram,
      HeadlineProgram: HeadlineProgram,
    });
  };
  setDailyProgram = dailyProgram => {
    let newDP = dailyProgram ? dailyProgram : this.props.dailyProgram;
    let dailyProg = { ...this.state.dailyProgram };
    dailyProg = newDP;
    this.setState({ ...this.state, dailyProgram: dailyProg });
  };
  setHeadLines = headLineProg => {
    let headLine = headLineProg ? headLineProg : this.props.HeadlineProgram;
    this.setState({
      ...this.state,
      HeadlineProgram: headLine,
    });
  };
  setHeadLineAndDaily = (headline, daily) => {
    this.setState({
      ...this.state,
      HeadlineProgram: headline,
      dailyProgram: daily,
    });
  };
  closeModal = () => {
    this.setState({
      openModalAccommodation: false,
    });
  };
  // openModalAccommodation = (data, index) => {
  //   this.openAccommodationModal((data, index));
  // };
  openModalAccommodation = (data, index) => {
    if (data.City && data.City.Id) {
      let dataAccommodation = {
        cityID: data.City.Id,
        ratingID: '',
        areaID: '',
        locationID: '',
        typeID: '',
        facilityID: '',
        promoonly: false,
        requestedDate: data.AccommodationSummary.CheckIn, //convertDateFormat(data.Date, 'MM/DD/YYYY'),
        useExtraBed:
          data.AccommodationSummary.Allocations.ExtraBedPax > 0 ? true : false,
        useChildExtraBed:
          data.AccommodationSummary.Allocations.ChildExtraBedPax > 0
            ? true
            : false,
        useSharingBed:
          data.AccommodationSummary.Allocations.SharingBedPax > 0
            ? true
            : false,
        checkOutDate: data.AccommodationSummary.CheckOut, //convertDateFormat(data.LeavingDate, 'MM/DD/YYYY'),
        sharingRoomPax: data.AccommodationSummary.Allocations.SharingRoomPax,
        singleRoomPax: data.AccommodationSummary.Allocations.SingleRoomPax,
      };
      this.setState(
        {
          dataAccommodation,
        },
        this.prepareAccommodationData(
          data.City.Id,
          data.City.Name,
          dataAccommodation,
          index
        )
      );
    } else {
      this.openModalAlert('Please select your destination');
    }
  };
  prepareAccommodationData = (
    destination,
    destinationName,
    dataAccom,
    index
  ) => {
    let state = { ...this.state };
    state.openModalAccommodation = true;
    state.accomDestination = destination;
    state.accomDestinationName = destinationName;
    state.accomIndex = index;
    let demoPrice = generateDemoPrice(
      null,
      this.props.roomAllocation,
      null,
      null,
      null,
      null,
      null,
      state.HeadlineProgram,
      this.props.dailyProgram,
      [],
      null,
      null,
      [],
      null,
      null,
      null
    );
    // this.props.getAccommodationFilterParameter({
    //   cityId: destination,
    //   requestedDate: dataAccom.requestedDate,
    //   useExtraBed: dataAccom.useExtraBed,
    //   useChildExtraBed: dataAccom.useChildExtraBed,
    //   useSharingBed: dataAccom.useSharingBed,
    // });
    let newDemoPriceObj = {
      RoomAllocation: demoPrice.RoomAllocation,
      Attractions: demoPrice.Attractions,
      Transportations: demoPrice.Transportations,
      Accommodations: demoPrice.Accommodations.filter(
        item => item.ServiceItemId
      ),
      Restaurants: demoPrice.Restaurants,
      TourGuides: [],
      AdditionalServices: [],
      FlightTickets: [],
      Movements: demoPrice.Movements,
    };
    // this.props.getAccommodations(dataAccom, state.filter, newDemoPriceObj);
    this.props.getAccommodations(
      dataAccom,
      this.helperDeleteOldAccommodationInEstimatedPrice(
        dataAccom,
        newDemoPriceObj
      )
    );
    this.props.getTourTypes();
    // this.setAccommodationOnChangeIndex(index);
    this.setState({
      ...this.state,
      openModalAccommodation: state.openModalAccommodation,
      accomDestination: state.accomDestination,
      accomDestinationName: state.accomDestinationName,
      accomIndex: state.accomIndex,
      demoPriceObj: newDemoPriceObj,
    });
  };

  helperDeleteOldAccommodationInEstimatedPrice = (dataAccom, demoPrice) => {
    let newDemoPrice = { ...demoPrice };
    let dataToFilter = newDemoPrice.Accommodations;
    newDemoPrice.Accommodations = dataToFilter.filter(
      item =>
        item.DateTime !== dataAccom.requestedDate &&
        item.EndTime !== dataAccom.checkOutDate
    );
    return newDemoPrice;
  };

  handleCollapse = () => {
    let isCollapse = this.state.isCollapse;
    isCollapse = !isCollapse;
    this.setState({ ...this.state, isCollapse: isCollapse });
  };
  addNewAccommodation = () => {
    let headLineProg = { ...this.state.HeadlineProgram };
    if (isAccommodationEmpty(headLineProg.MainPrograms)) {
      this.openModalAlert('Please input your accommodation!');
    } else {
      headLineProg.MainPrograms = addSummaryProgram(headLineProg.MainPrograms);
      headLineProg.MainPrograms = setDateSummaryProgram(
        headLineProg.Departures[headLineProg.Departures.length - 1],
        headLineProg.MainPrograms
      );
      headLineProg.Returns = setReturnsDate(
        headLineProg.Returns,
        headLineProg.MainPrograms
      );
      this.setHeadLines(headLineProg);
    }
  };
  isNeedChangePlace = (data, main, index, type) => {
    let result = false;
    if (type === 'Arrival') {
      if (index === 0) {
        result = false;
      } else {
        if (main[index - 1] && main[index - 1].TransferType) {
          result = data.Region !== main[index - 1].Region ? true : false;
        } else result = false;
      }
    } else if (type === 'Departure') {
      if (index === 0) {
        result = false;
      } else {
        if (main[index + 1] && main[index + 1].TransferType) {
          result = data.Region !== main[index + 1].Region ? true : false;
        } else result = false;
      }
    }
    return result;
  };
  setDestinationAndDate = () => {
    this.closeModalDestAndDate();
  };
  openModalAlert = message => {
    this.setState({ errorMessage: message, openModalAlert: true });
  };
  closeModalDestAndDate = () => {
    let { HeadlineProgram, dailyProgram } = this.state;
    let isConflict = isHeadLineConflict(HeadlineProgram);
    if (isConflict) {
      this.openModalAlert('Please Fix the conflict');
    } else if (!isValidAirport(HeadlineProgram)) {
      this.openModalAlert('Please choose your place of arrival or departure!');
    } else if (isAccommodationEmpty(HeadlineProgram.MainPrograms)) {
      this.openModalAlert('Please choose your accommodation!');
    } else {
      let city = this.props.cityList.find(
        item => item.Id === HeadlineProgram.MainPrograms[0].City.Id
      );
      this.props.setDailyProgram(dailyProgram);
      this.props.setHeadlineProgram(HeadlineProgram);
      this.props.setFirstDestination(city);
      this.props.closeModal();
    }
  };
  setAccommodationRoom = (accommodations, room, service) => {
    let { HeadlineProgram, accomIndex } = this.state;
    let headLineProg = { ...HeadlineProgram };
    let accommodation = accommodations.find(
      item => item.Id === room.AccommodationProfileId
    );
    headLineProg.MainPrograms = changeAccomodation(
      headLineProg.MainPrograms,
      accomIndex,
      service,
      room,
      accommodation
    );
    this.setHeadLines(headLineProg);
  };
  closeModalAlert = () => {
    this.setState({ ...this.state, openModalAlert: false });
  };
  handleDeleteConnectionFlight = (e, { index }) => {
    let { HeadlineProgram } = this.state;
    let hlProgram = { ...HeadlineProgram };
    hlProgram.MainPrograms = delConectionFlight(
      hlProgram.Departures[hlProgram.Departures.length - 1],
      hlProgram.MainPrograms,
      index
    );
    this.setHeadLines(hlProgram);
  };

  render() {
    const { t } = this.props;
    const {
      openModalAccommodation,
      accomDestination,
      HeadlineProgram,
      accomIndex,
      dailyProgram,
      openModalAlert,
      errorMessage,
      accomDestinationName,
      dataAccommodation,
      demoPriceObj,
    } = this.state;
    const { Departures, MainPrograms, Returns } = HeadlineProgram;
    const { open, accommodations, cityList } = this.props;
    return (
      <div>
        <Modal
          // style={{ width: '980px' }}
          open={open}
          onClose={this.props.closeModal}
          onOpen={this.onOpen}
          trigger={
            <div style={styles.textCenter}>
              <Button style={styles.backgroundGold}>
                {t('setDestination')}
              </Button>
            </div>
          }
        >
          <Modal.Header>{t('destinationsSummary')}</Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column width={14} />
                <Grid.Column width={2} textAlign="right">
                  <CollapseButton
                    handleCollapse={this.handleCollapse}
                    style={modalStyle.boxTransparent}
                    isCollapse={this.state.isCollapse}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Collapse isOpened={this.state.isCollapse}>
              <div style={styles.paddingSideTwenty}>
                {/* {Departures.length > 0 && (
                  <Arrival
                    type="firstArrival"
                    data={Departures[0]}
                    setHeadLines={this.setHeadLines}
                    headlineProgram={HeadlineProgram}
                    cityList={cityList}
                  />
                )} */}
                {Departures.length > 0 && (
                  <Segment style={styles.segmentInside}>
                    <Grid>
                      <Grid.Row columns="2">
                        {Departures.map((airport, i) =>
                          airport.TransferType === 'Movement_arrival' ? (
                            <Grid.Column>
                              <Arrival
                                type="firstArrival"
                                data={airport}
                                key={i}
                                index={i}
                                setHeadLines={this.setHeadLines}
                                headlineProgram={HeadlineProgram}
                                cityList={cityList}
                              />
                            </Grid.Column>
                          ) : (
                            <Grid.Column>
                              <Departure
                                type="firstDeparture"
                                data={airport}
                                key={i}
                                index={i}
                                setHeadLines={this.setHeadLines}
                                headlineProgram={HeadlineProgram}
                                cityList={cityList}
                              />
                            </Grid.Column>
                          )
                        )}
                      </Grid.Row>
                    </Grid>
                  </Segment>
                )}
                {MainPrograms.length > 0 &&
                  MainPrograms.map((item, i) =>
                    item.AccommodationSummary ? (
                      <Accommodation
                        data={item}
                        key={i}
                        index={i}
                        openModal={this.openModalAccommodation}
                        setAccommodationOnChangeIndex={
                          this.setAccommodationOnChangeIndex
                        }
                        headlineProgram={HeadlineProgram}
                        setHeadLines={this.setHeadLines}
                        deleteAccommodation={this.deleteAccommodation}
                        dailyProgram={dailyProgram}
                        setHeadLineAndDaily={this.setHeadLineAndDaily}
                      />
                    ) : (
                      item.TransferType &&
                      item.TransferType === 'Movement_departure' && (
                        <div>
                          <Segment style={styles.segmentInside}>
                            <Grid>
                              <Grid.Row columns="2">
                                <Grid.Column>
                                  <Departure
                                    type="connectionFlight"
                                    data={item}
                                    key={i}
                                    index={i}
                                    setHeadLines={this.setHeadLines}
                                    headlineProgram={HeadlineProgram}
                                    cityList={cityList}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <Arrival
                                    type="connectionFlight"
                                    data={MainPrograms[i + 1]}
                                    key={i + 1}
                                    index={i + 1}
                                    setHeadLines={this.setHeadLines}
                                    headlineProgram={HeadlineProgram}
                                    cityList={cityList}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Segment>
                          <Grid.Row
                            style={{
                              ...modalStyle.noPadding,
                              ...modalStyle.textCenter,
                            }}
                          >
                            <Grid.Column>
                              <Button
                                basic
                                color="red"
                                onClick={this.handleDeleteConnectionFlight}
                                index={i}
                                style={modalStyle.marginBot15}
                              >
                                {t('deleteConnectionFlight')}
                                {/* Delete Connection Flight */}
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </div>
                      )
                    )
                  )}
                {/* {MainPrograms.length > 0 &&
                  MainPrograms.map((item, i) =>
                    item.AccommodationSummary ? (
                      <Accommodation
                        data={item}
                        key={i}
                        index={i}
                        openModal={this.openModalAccommodation}
                        setAccommodationOnChangeIndex={
                          this.setAccommodationOnChangeIndex
                        }
                        headlineProgram={HeadlineProgram}
                        setHeadLines={this.setHeadLines}
                        deleteAccommodation={this.deleteAccommodation}
                        dailyProgram={dailyProgram}
                        setHeadLineAndDaily={this.setHeadLineAndDaily}
                      />
                    ) : item.TransferType &&
                      item.TransferType === 'Movement_arrival' ? (
                      <Arrival
                        type="connectionFlight"
                        data={item}
                        key={i}
                        index={i}
                        setHeadLines={this.setHeadLines}
                        headlineProgram={HeadlineProgram}
                        cityList={cityList}
                      />
                    ) : (
                      <Departure
                        type="connectionFlight"
                        data={item}
                        key={i}
                        index={i}
                        setHeadLines={this.setHeadLines}
                        headlineProgram={HeadlineProgram}
                        cityList={cityList}
                      />
                    )
                  )} */}
                <div style={styles.margin10Center}>
                  <Button
                    style={styles.backgroundGold}
                    onClick={this.addNewAccommodation}
                  >
                    {t('AddDestination')}
                    {/* ADD DESTINATION */}
                  </Button>
                </div>
                {/* {Returns.length > 0 && (
                  <Departure
                    type="lastDeparture"
                    data={Returns[0]}
                    setHeadLines={this.setHeadLines}
                    headlineProgram={HeadlineProgram}
                    cityList={cityList}
                  />
                )} */}
                {Returns.length > 0 && (
                  <Segment style={styles.segmentInside}>
                    <Grid>
                      <Grid.Row columns="2">
                        {Returns.map((airport, i) =>
                          airport.TransferType === 'Movement_arrival' ? (
                            <Grid.Column>
                              <Arrival
                                type="lastArrival"
                                data={airport}
                                key={i}
                                index={i}
                                setHeadLines={this.setHeadLines}
                                headlineProgram={HeadlineProgram}
                                cityList={cityList}
                              />
                            </Grid.Column>
                          ) : (
                            <Grid.Column>
                              <Departure
                                type="lastDeparture"
                                data={airport}
                                key={i}
                                index={i}
                                setHeadLines={this.setHeadLines}
                                headlineProgram={HeadlineProgram}
                                cityList={cityList}
                              />
                            </Grid.Column>
                          )
                        )}
                      </Grid.Row>
                    </Grid>
                  </Segment>
                )}
              </div>
            </Collapse>
            <ModalListAccommodation
              open={openModalAccommodation}
              onClose={this.closeModal}
              name={t('accommodation')} //"Accommodation"
              data={accommodations}
              location={accomDestination}
              locationName={accomDestinationName}
              accomIndex={accomIndex}
              headlineProgram={HeadlineProgram}
              setAccommodationRoom={this.setAccommodationRoom}
              dataAccommodation={dataAccommodation}
              demoPriceObj={demoPriceObj}
            />
            <ModalAlert
              openModal={openModalAlert}
              size="mini"
              close={this.closeModalAlert}
              message={errorMessage}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={styles.backgroundGold}
              onClick={this.setDestinationAndDate}
              content={t('ok')} //"Ok"
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    cityList: state.location.cityInCountry,
    HeadlineProgram: state.customPackage.HeadlineProgram,
    placeByRegion: state.location.placeByRegion,
    getplaceByRegion: state.location.getplaceByRegion,
    accommodations: state.accommodation.accommodations,
    dailyProgram: state.customPackage.dailyProgram,
    places: state.location.places,
    firstDestination: state.customPackage.firstDestination,
    roomAllocation: state.customPackage.roomAllocation,
  };
};
ModalDestinationsAndDate.propTypes = {
  HeadlineProgram: PropTypes.object,
  placeByRegion: PropTypes.array,
  getplaceByRegion: PropTypes.string,
  resetStatusGetPlaceByRegion: PropTypes.func,
  accommodationChangeIndex: PropTypes.number,
  cityList: PropTypes.array,
  setHeadlineProgram: PropTypes.func,
  accommodations: PropTypes.array,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  open: PropTypes.bool,
  getAccommodationFilterParameter: PropTypes.func,
  getAccommodations: PropTypes.func,
  dailyProgram: PropTypes.array,
  setDailyProgram: PropTypes.func,
  places: PropTypes.array,
  setFirstDestination: PropTypes.func,
  getTourTypes: PropTypes.func,
  generalInfo: PropTypes.object,
  firstDestination: PropTypes.object,
  errorGeneralInformation: PropTypes.object,
  setErrorGeneralInformation: PropTypes.func,
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    resetStatusGetPlaceByRegion,
    setHeadlineProgram,
    getAccommodations,
    getAccommodationFilterParameter,
    setDailyProgram,
    setFirstDestination,
    getTourTypes,
  }
)(withTranslation()(ModalDestinationsAndDate));
