import React from 'react';
import { Popup, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const ImagePopup = props => {
  const { content, image, imageStyle } = props;
  return (
    <Popup
      trigger={<Image src={image} style={imageStyle} />}
      content={content}
    />
  );
};
ImagePopup.propTypes = {
  content: PropTypes.string,
  image: PropTypes.string,
  imageStyle: PropTypes.object,
};
export default ImagePopup;
