import * as types from '../actions/actionTypes';

const initialState = {
  acceptQuotationDesc: {},
  postAcceptQuotationStatus: '',
  editQuotationDesc: {},
  postEditQuotationStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // POST_ACCEPT_QUOTATION
    //---------------------------------
    case types.POST_ACCEPT_QUOTATION:
      return { ...state };
    case types.POST_ACCEPT_QUOTATION_PENDING:
      return { ...state, loading: true };
    case types.POST_ACCEPT_QUOTATION_FULFILLED:
      return {
        ...state,
        acceptQuotationDesc: action.payload.data,
        errors: {},
        loading: false,
        postAcceptQuotationStatus: 'success',
      };
    case types.POST_ACCEPT_QUOTATION_REJECTED:
      return {
        ...state,
        acceptQuotationDesc: [],
        errors: action.payload.response.data,
        loading: false,
        postAcceptQuotationStatus: 'failed',
      };
    //---------------------------------
    // POST_EDIT_QUOTATION
    //---------------------------------
    case types.POST_EDIT_QUOTATION:
      return { ...state };
    case types.POST_EDIT_QUOTATION_PENDING:
      return { ...state, loading: true };
    case types.POST_EDIT_QUOTATION_FULFILLED:
      return {
        ...state,
        editQuotationDesc: action.payload.data,
        errors: {},
        loading: false,
        postEditQuotationStatus: 'success',
      };
    case types.POST_EDIT_QUOTATION_REJECTED:
      return {
        ...state,
        editQuotationDesc: [],
        errors: action.payload.response.data,
        loading: false,
        postEditQuotationStatus: 'failed',
      };
    case types.RESET_STATUS_QUOTATION:
      return {
        ...state,
        postAcceptQuotationStatus: '',
        postEditQuotationStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
