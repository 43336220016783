import { GOLD_COLOR } from '../../../../../../constant/colour';
const style = {
  marginLeftRight30: { marginLeft: '30px', marginRight: '30px' },
  labelStyles: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  backgroundGold: { background: GOLD_COLOR },
};

export default style;
