import apiClient from './apiClient';
import { POST_REGISTER_URL, POST_REGISTER_SIMPLE_CUSTOMER_URL } from './apiUrl';

const postRegisterApi = data => {
  return apiClient.post(POST_REGISTER_URL, data);
};

const postRegisterSimpleCustomerApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_REGISTER_SIMPLE_CUSTOMER_URL, data);
};

export { postRegisterApi, postRegisterSimpleCustomerApi };
