const breadCrumb = [
  { key: 'Home', content: 'Home', link: true, active: false, href: '/' },
  {
    key: 'Series Package',
    content: 'Series Package',
    active: true,
    link: false,
  },
];

export default breadCrumb;
