import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { ButtonCloseModal } from '../common';
import Maps from '../map/MapAccommodations';
import styles from './styles';

import { useTranslation } from 'react-i18next';

const ModalAccommodationWithMap = props => {
  const { t } = useTranslation();
  const {
    isOpen,
    onClose,
    data,
    coordinates,
    handleOpenRooms,
    locationName,
  } = props;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="large"
      closeOnDimmerClick
      style={styles.height400}
    >
      <Modal.Header>
        {/* Accommodations in */}
        {t('accommodationsIn')} {locationName}{' '}
        <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.noPadding}>
        <Maps
          mapStyle={styles.height500}
          data={data}
          coordinates={coordinates}
          handleOpenRooms={handleOpenRooms}
        />
      </Modal.Content>
    </Modal>
  );
};

ModalAccommodationWithMap.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  companies: PropTypes.array,
  handleButtonPick: PropTypes.func,
  handleChange: PropTypes.func,
  searchCompany: PropTypes.string,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  data: PropTypes.array,
  coordinates: PropTypes.array,
  handleOpenRooms: PropTypes.func,
  locationName: PropTypes.string,
  t: PropTypes.func,
};

export default ModalAccommodationWithMap;
