import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { Grid, Button, Icon, Segment } from 'semantic-ui-react';
import styles from './styles';
import {
  resetStatusTourTransactionSummary,
  getSuplementItemByTransactionId,
  getTourTransactionToBePayed,
  getTourTransactionSummary,
  getTourTransactionBills,
  postTourTransactionConfirmation,
} from '../../shared/actions/bookingAction';
// import { TextMoney } from '../../shared/components/common';
import createTourPriceObj from '../services/helper/createTourPriceObj';
import setCurrentPackage from '../../shared/services/helper/setCurrentPackage';
import {
  PaymentHistory,
  GuestList,
  NoteTravelAgent,
  CardSummaryItinerary,
  ModalItinerary,
} from './cardPackage';
import { PriceDetail, OrderedItem } from '../../shared/components/summary';
import { getDataForBookingSegment } from '../services/helper/getBookingDetail';
import PopUpButton from './cardPackage/PopUpButton';
import { getTime } from '../../../genericFunction/moment';
import {
  getInvoiceCustomerApi,
  getVoucherConfirmationApi,
  getScheduleTourTransactionApi,
} from '../../../api/bookingApi';
import { ModalAlert, ModalConfirm } from '../../shared/components/modal';
import BookingDetailSegment from './BookingDetailSegment';
import { Link } from 'react-router-dom';
import {
  postAcceptQuotation,
  resetStatusQuotation,
} from '../../shared/actions/quotationAction';
import ModalCancellationReason from '../../shared/components/modal/ModalCancellationReason';
import { useTranslation } from 'react-i18next';

const MyBookingDetail = props => {
  //   const { listPaymentTerm } = props;
  const { t } = useTranslation();
  const { tab } = props.match.params;
  const [isSales, setIsSales] = useState(false);
  const [tourPriceRoom, setTourPriceRoom] = useState([]);
  const [orderedItem, setOrderedItem] = useState([]);
  const [tourTransactionSummaryById, setTourTransactionSummaryById] = useState(
    {}
  );
  const [openModalGuest, setOpenModalGuest] = useState(false);
  const [openModalItinerary, setOpenModalItinerary] = useState(false);

  const [readyFixPrice, setReadyFixPrice] = useState(false);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [similarPage, setSimilarPage] = useState(false);
  const [idAction, setIdAction] = useState('');

  const [modalPayment, setModalPayment] = useState('');
  const [modalBookNow, setModalBookNow] = useState('');
  const [modalEdit, setModalEdit] = useState('');
  const [paymentAction, setPaymentAction] = useState('');
  const [modalAlert, setModalAlert] = useState(false);
  const [modalAlertDescSuccess, setModalAlertDescSuccess] = useState('');
  const [modalAlertDescFailed, setModalAlertDescFailed] = useState('');
  const [modalCancel, setModalCancel] = useState(false);
  // const [modalDelete, setModalDelete] = useState(false);
  const [CancellationReasonId, setCancellationReasonId] = useState(null);
  const [CancellationReasonNote, setCancellationReasonNote] = useState('');
  const [nextPagePay, setNextPagePay] = useState(false); //, setNextPagePay
  const [backToList, setBackToList] = useState(false);

  //=============action==========
  const dispatch = useDispatch();
  const resetStatusTourTransactionSummaryAction = () =>
    dispatch(resetStatusTourTransactionSummary());

  const getSuplementItemByTransactionIdAction = id =>
    dispatch(getSuplementItemByTransactionId(id));
  const getTourTransactionToBePayedAction = id =>
    dispatch(getTourTransactionToBePayed(id));
  const getTourTransactionSummaryAction = id =>
    dispatch(getTourTransactionSummary(id));
  const getTourTransactionBillsAction = id =>
    dispatch(getTourTransactionBills(id));

  const postAcceptQuotationAction = param =>
    dispatch(postAcceptQuotation(param));
  const resetStatusQuotationAction = () => dispatch(resetStatusQuotation());

  const postTourTransactionConfirmationAction = param =>
    dispatch(postTourTransactionConfirmation(param));

  //============store===========
  const loadingSeeDetail = useSelector(state => state.booking.loadingSeeDetail);
  const tourTransactionSummary = useSelector(
    state => state.booking.tourTransactionSummary
  );
  const getTourTransactionSummaryStatus = useSelector(
    state => state.booking.getTourTransactionSummaryStatus
  );
  const countries = useSelector(state => state.countries.countries);
  const tourTransactionBills = useSelector(
    state => state.booking.tourTransactionBills
  );

  const userRoles = useSelector(state => state.user.userRoles);
  const postAcceptQuotationStatus = useSelector(
    state => state.quotation.postAcceptQuotationStatus
  );
  const acceptQuotationDesc = useSelector(
    state => state.quotation.acceptQuotationDesc
  );
  const errors = useSelector(state => state.quotation.errors);

  // const getTourTransactionBillsStatus = useSelector(
  //   state => state.booking.getTourTransactionBillsStatus
  // );

  //============lifecycle============
  useEffect(() => {
    if (getTourTransactionSummaryStatus === 'success') {
      let { id } = props;
      if (tourTransactionSummary.BookingDetailSum.Id === id) {
        convertTourPrice(tourTransactionSummary.TourPriceSum);
        convertOrderedItem(tourTransactionSummary.DailyPrograms);
        setTourTransactionSummaryById(tourTransactionSummary);
        resetStatusTourTransactionSummaryAction();
      }
    }
    // eslint-disable-next-line
    }, [getTourTransactionSummaryStatus]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
    }, [])

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line
    }, [userRoles]);

  useEffect(() => {
    if (postAcceptQuotationStatus === 'success') {
      let { Id, Status } = acceptQuotationDesc.BookingDetailSum;
      let message =
        Status === 'Booking_created'
          ? 'Booking code ' + Id + ' successfully created'
          : 'Your Booking is on hold';
      setModalAlert(true);
      setModalAlertDescSuccess(message);
      resetStatusQuotationAction();
    } else if (postAcceptQuotationStatus === 'failed') {
      setModalAlert(true);
      setModalAlertDescFailed(errors.Message);
      resetStatusQuotationAction();
    }
    // eslint-disable-next-line
    }, [postAcceptQuotationStatus]);

  const loadData = () => {
    getSuplementItemByTransactionIdAction(props.id);
    getTourTransactionToBePayedAction(props.id);
    getTourTransactionSummaryAction(props.id);
    getTourTransactionBillsAction(props.id);
  };

  const checkUser = () => {
    if (userRoles.length !== 0) {
      if (userRoles.Roles && userRoles.Roles.some(e => e === 'Package Sales')) {
        // this.setState({ ...this.state, isSales: true });
        setIsSales(true);
      }
      // if (
      //   this.props.userRoles.Roles.some(
      //     e => ['Customer Admin', 'Package Sales Admin'].indexOf(e) !== -1
      //   )
      // ) {
      //   this.setState({ ...this.state, isAdminCustomer: true });
      // }
    }
  };

  const convertTourPrice = tourPriceSum => {
    let tourPriceRoom = createTourPriceObj(tourPriceSum);
    setTourPriceRoom(tourPriceRoom);
  };
  const convertOrderedItem = dailyPrograms => {
    let orderedItem = dailyPrograms
      .filter(e => e.OrderedItems.length !== 0)
      .map(daily => {
        return { day: daily.Day, date: daily.Date, items: daily.OrderedItems };
      });
    setOrderedItem(orderedItem);
  };

  const handleButtonGuest = () => setOpenModalGuest(!openModalGuest);
  const handleButtonItinerary = () =>
    setOpenModalItinerary(!openModalItinerary);

  const handleDownloadFile = (dataUrl, filename) => {
    let link = document.createElement('a');
    link.download = filename;
    link.href = dataUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const createSimilar = (idAction, packageType, expiredOn) => {
    let isValid = getTime(new Date()) > getTime(expiredOn);
    setIdAction(idAction);
    if (packageType === 'FixedDateVariable') {
      setReadyFixPrice(!isValid);
      setOpenModalAlert(isValid);
      setMessageAlert('Package is already expired');
    } else {
      setSimilarPage(true);
    }
  };

  const handleDownloadVoucherConfirmation = (e, { value }) =>
    getVoucherConfirmationApi(value).then(result => {
      handleDownloadFile(result.data, title);
    });

  const handleDownloadInvoice = (e, { value }) =>
    getVoucherConfirmationApi(value).then(result =>
      handleDownloadFile(result.data, title)
    );

  const handleDownloadInvoiceCustomer = (e, { value }) =>
    getInvoiceCustomerApi(value).then(result =>
      handleDownloadFile(result.data, title)
    );

  const handleDownloadSchedule = (e, { value }) =>
    getScheduleTourTransactionApi(value).then(result =>
      handleDownloadFile(result.data, title)
    );

  const handleCreateSimilar = (e, { value }) =>
    createSimilar(value, packageType, expired);

  const handleCloseModalAlert = () => {
    setOpenModalAlert(false);
    setMessageAlert('');
  };

  const handleCloseAlert = () => {
    setModalAlert(false);
    setModalAlertDescSuccess('');
    setModalAlertDescFailed('');
    loadData();
  };

  const handleBackToList = () => {
    setBackToList(true);
  };

  const handleModal = (e, { name, value, desc }) => {
    name === 'modalPayment'
      ? setModalPayment(!modalPayment)
      : name === 'modalBookNow'
      ? setModalBookNow(!modalBookNow)
      : name === 'modalEdit'
      ? setModalEdit(!modalEdit)
      : setModalEdit(modalEdit);
    setIdAction(value);
    setPaymentAction(desc);
  };

  const handleCloseBookNow = () => setModalBookNow(false);
  const handleClosePayment = () => setModalPayment(false);
  const handleCloseCancel = () => setModalCancel(false);
  const handleCancel = (e, { value }) => {
    setIdAction(value);
    setModalCancel(true);
  };

  const handleSaveCancellationReason = data => {
    setCancellationReasonId(data.CancellationReasonId);
    setCancellationReasonNote(data.CancellationReasonNote);
    confirmDelete();
  };

  const confirmBook = () => {
    postAcceptQuotationAction(idAction);
    // this.setState({ modalBookNow: false });
    setModalBookNow(false);
  };

  const confirmPay = () => {
    setNextPagePay(true);
    setModalPayment(false);
  };

  const confirmDelete = () => {
    let cancelTransaction = {
      Id: idAction,
      Confirmation: 'Cancel',
      CancellationReasonId: CancellationReasonId,
      CancellationReasonNote: CancellationReasonNote,
    };
    postTourTransactionConfirmationAction(cancelTransaction)
      .then(() => {
        loadData();
      })
      .catch(() => {
        setMessageAlert('Cancelled transaction failed');
        setOpenModalAlert(true);
      });
    // .then(() =>
    //   this.setState({
    //     modalSuccessDelete: true,
    //     messageAlert: `Your Booking Code ${
    //       this.state.idAction
    //     } has been Cancelled`,
    //   })
    // )
    // .catch(() =>
    //   this.setState({
    //     loadingPostCancel: false,
    //     modalSuccessDelete: true,
    //     messageAlert: 'Cancelled transaction failed',
    //   })
    // );
    // setModalDelete(false);
    setModalCancel(false);
  };

  //   let { packageType } = props;
  // let {
  //   tourPriceRoom,
  //   tourTransactionSummaryById,
  //   orderedItem,
  //   openModalGuest,
  //   openModalItinerary,
  // } = this.state;
  let currency = tourTransactionSummaryById
    ? tourTransactionSummaryById.BookingDetailSum
      ? tourTransactionSummaryById.BookingDetailSum.CurrencyId
      : ''
    : '';
  let tourTotalPrice = tourTransactionSummaryById
    ? tourTransactionSummaryById.BookingDetailSum
      ? tourTransactionSummaryById.BookingDetailSum.TourTotalPrice
      : 0
    : 0;
  let fixedPackage = tourTransactionSummaryById
    ? tourTransactionSummaryById.BookingDetailSum
      ? tourTransactionSummaryById.BookingDetailSum.FixedPackage
      : {}
    : {};
  let commission = fixedPackage
    ? fixedPackage.BookingCommission
      ? fixedPackage.BookingCommission.ApplicableCommission
      : []
    : [];
  let totalPayable = fixedPackage
    ? fixedPackage.BookingCommission
      ? fixedPackage.BookingCommission.TotalPriceForCustomer
      : 0
    : 0;
  let listTourGuest = tourTransactionSummaryById
    ? tourTransactionSummaryById.TourGuestSum
    : [];
  let departure = tourTransactionSummaryById.HeadlineProgram
    ? tourTransactionSummaryById.HeadlineProgram.Departures
    : [];
  let returns = tourTransactionSummaryById.HeadlineProgram
    ? tourTransactionSummaryById.HeadlineProgram.Returns
    : [];
  let dataSummaryItinerary = tourTransactionSummaryById.HeadlineProgram
    ? tourTransactionSummaryById.HeadlineProgram.MainPrograms
    : [];
  let dailyPrograms = tourTransactionSummaryById.DailyPrograms
    ? tourTransactionSummaryById.DailyPrograms
    : [];
  let FoC = tourTransactionSummaryById.BookingDetailSum
    ? tourTransactionSummaryById.BookingDetailSum.FoC
    : null;
  let currentPackage = tourTransactionSummaryById.BookingDetailSum
    ? setCurrentPackage(tourTransactionSummaryById.BookingDetailSum.PackageType)
    : 'FIXED';
  let tourNote = tourTransactionSummaryById
    ? tourTransactionSummaryById.TourNote
      ? tourTransactionSummaryById.TourNote
      : ''
    : '';

  const isAcceptedQuotation = tourTransactionSummary.BookingDetailSum
    ? tourTransactionSummary.BookingDetailSum.Quotation
      ? tourTransactionSummary.BookingDetailSum.Quotation.IsAccepted
      : false
    : false;

  const dataForBookingSegment = getDataForBookingSegment(
    tourTransactionSummary,
    isAcceptedQuotation
  );
  const {
    title,
    city,
    country,
    expiredOn,
    expired,
    startDate,
    endDate,
    status,
    // currency,
    totalPrice,
    commision,
    paymentTerm,
    // percentagePaymentTerm,
    // totalPayable,
    totalPaid,
    createdBy,
    //
    packageType,
    bookingTemplateRef,
    tourTransactionIdQuotation,
  } = dataForBookingSegment;

  if (nextPagePay) {
    return <Redirect to={'/partial-payment/' + idAction} />;
  } else if (similarPage) {
    return <Redirect to={'/ready/similar/' + idAction} />;
  } else if (readyFixPrice) {
    return <Redirect to={'/ready/ready-package-variable/' + idAction} />;
  } else if (backToList) {
    if (tab === 'booking') {
      return <Redirect to={'/my-profile/booking'} />;
    } else {
      return <Redirect to={'/my-profile/quotation'} />;
    }
  }

  return (
    <>
      <Grid>
        {/* upper button */}
        <Grid.Row columns="3">
          <Grid.Column verticalAlign="middle">
            <Button
              name="listMyBooking"
              onClick={handleBackToList}
              labelPosition="left"
              style={styles.buttonBackgroundWhite}
            >
              <Icon name="arrow left" style={styles.backgroundWhite} />
              {/* Back */} {t('back')}
            </Button>
          </Grid.Column>

          <Grid.Column textAlign="center">
            <Button.Group>
              <PopUpButton
                buttonStyle={styles.bottonDownload}
                name="invoice"
                value={props.id}
                handleClick={handleDownloadInvoice}
                iconClassName="fas fa-file-invoice fa-2x"
                iconStyle={styles.buttonBackgroundWhite}
                content={
                  status.key !== 'Booking_draft'
                    ? t('printAgentInvoice') // 'Print Agent Invoice'
                    : t('printQuotation') // 'Print Quotation'
                }
                popupShowOn="hover"
              />

              {status.key !== 'Booking_draft' && (
                <PopUpButton
                  buttonStyle={styles.bottonDownload}
                  name="invoice"
                  value={props.id}
                  handleClick={handleDownloadInvoiceCustomer}
                  iconClassName="fas fa-receipt fa-2x"
                  iconStyle={styles.buttonBackgroundWhite}
                  content={t('printCustomerInvoice')} //"Print Customer Invoice"
                  popupShowOn="hover"
                />
              )}
              <PopUpButton
                buttonStyle={styles.bottonDownload}
                name="schedule"
                value={props.id}
                handleClick={handleDownloadSchedule}
                iconClassName="fas fa-clipboard-list fa-2x"
                iconStyle={styles.buttonBackgroundWhite}
                content={t('printSchedule')} //"Print Schedule"
                popupShowOn="hover"
              />

              {status.key === 'Booking_Confirmed' ? (
                <PopUpButton
                  buttonStyle={styles.bottonDownload}
                  name="voucherConfirmation"
                  value={props.id}
                  handleClick={handleDownloadVoucherConfirmation}
                  iconClassName="fas fa-file-alt fa-2x"
                  iconStyle={styles.buttonBackgroundWhite}
                  content={t('printVoucherConfirmation')} //"Print Voucher Confirmation"
                  popupShowOn="hover"
                />
              ) : null}
              {((packageType !== 'Fixed' && status.key !== 'Booking_draft') ||
                isAcceptedQuotation) && (
                <PopUpButton
                  buttonStyle={styles.bottonDownload}
                  name="copy"
                  value={
                    packageType !== 'FixedDateVariable'
                      ? isAcceptedQuotation
                        ? tourTransactionIdQuotation
                        : props.id
                      : bookingTemplateRef
                  }
                  handleClick={handleCreateSimilar}
                  iconClassName="fas fa-copy fa-2x"
                  iconStyle={styles.buttonBackgroundWhite}
                  content={t('createSimilar')} //"Create Similar"
                  popupShowOn="hover"
                />
              )}
            </Button.Group>
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="right">
            {status.key !== 'Booking_hold' &&
              status.key !== 'Booking_Confirmed' &&
              status.key !== 'Booking_Completed' &&
              status.key !== 'Booking_draft' &&
              status.key !== 'Booking_Cancelled' &&
              !isSales && (
                <Button
                  name="modalPayment"
                  value={props.id}
                  desc={paymentTerm.Description}
                  style={styles.buttonGold}
                  onClick={handleModal}
                >
                  {t('pay')} {paymentTerm.Description}
                </Button>
              )}

            {status.key !== 'Booking_hold' &&
              status.key !== 'Booking_draft' &&
              status.key !== 'Booking_Cancelled' &&
              status.key !== 'Booking_Completed' && (
                <Button
                  style={styles.buttonNegative}
                  name="modalDelete"
                  value={props.id}
                  onClick={handleCancel} //handleModal
                  desc=""
                >
                  {/* Cancel */} {t('cancel')}
                </Button>
              )}

            {status.key === 'Booking_draft' && !isAcceptedQuotation && (
              <>
                <Button
                  name="modalBookNow"
                  value={props.id}
                  style={styles.buttonGold}
                  onClick={handleModal}
                  floated="right"
                >
                  {/* Book Now */} {t('bookNow')}
                </Button>
                <Button
                  as={Link}
                  name="modalEdit"
                  value={props.id}
                  style={styles.buttonGold}
                  onClick={handleModal}
                  floated="right"
                  to={'/ready/quotation/' + props.id}
                >
                  {/* Edit */} {t('edit')}
                </Button>
              </>
            )}
          </Grid.Column>
        </Grid.Row>

        {/* Booking Detail */}
        <Grid.Row>
          <Grid.Column>
            <Segment style={styles.segmentDetail} loading={loadingSeeDetail}>
              <Grid>
                <BookingDetailSegment
                  id={props.id}
                  title={title}
                  city={city}
                  country={country}
                  startDate={startDate}
                  endDate={endDate}
                  expiredOn={expiredOn}
                  currency={currency}
                  totalPrice={totalPrice}
                  packageType={packageType}
                  commision={commision}
                  paymentTerm={paymentTerm}
                  totalPayable={totalPayable}
                  totalPaid={totalPaid}
                  createdBy={createdBy}
                  status={status}
                />
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        {/* Payment History */}
        <Grid.Row>
          <Grid.Column>
            <Segment style={styles.segmentDetail} loading={loadingSeeDetail}>
              <Grid>
                <PaymentHistory
                  listPaymentTerm={tourTransactionBills}
                  currency={currency}
                />
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        {/* Price Detail */}
        <Grid.Row>
          <Grid.Column>
            <Segment style={styles.segmentDetail} loading={loadingSeeDetail}>
              <Grid>
                {/* <Grid.Row>
                  <Grid.Column>Ini price detail</Grid.Column>
                </Grid.Row> */}
                <PriceDetail
                  tourPriceRoom={tourPriceRoom}
                  additionalItem={
                    tourTransactionSummaryById.TransactionSupplements
                  }
                  currency={currency}
                  tourTotalPrice={tourTotalPrice}
                  commission={commission}
                  totalPayable={totalPayable}
                  packageType={packageType}
                  FoC={FoC}
                />
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        {/* Guest Detail */}
        <Grid.Row>
          <Grid.Column>
            <Segment style={styles.segmentDetail} loading={loadingSeeDetail}>
              <Grid>
                {/* <Grid.Row>
                  <Grid.Column>ini guest detail</Grid.Column>
                </Grid.Row> */}
                <GuestList
                  list={listTourGuest ? listTourGuest : []}
                  handleButton={handleButtonGuest}
                  countries={countries}
                />
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        {/* Order Detail */}
        <Grid.Row>
          <Grid.Column>
            <Segment style={styles.segmentDetail} loading={loadingSeeDetail}>
              <Grid>
                <OrderedItem orderedItem={orderedItem} />
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        {/* Notes */}
        <Grid.Row>
          <Grid.Column>
            <Segment style={styles.segmentDetail} loading={loadingSeeDetail}>
              <Grid>
                <NoteTravelAgent tourNote={tourNote} />
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        {/* Itinerary Summary */}
        <Grid.Row>
          <Grid.Column>
            <Grid>
              <CardSummaryItinerary
                departure={departure}
                returns={returns}
                dataSummaryItinerary={dataSummaryItinerary}
                handleButton={handleButtonItinerary}
                loadingSeeDetail={loadingSeeDetail}
              />
            </Grid>
          </Grid.Column>
        </Grid.Row>

        {/* Modal - modal */}
        <Grid.Row>
          <Grid.Column>
            <ModalAlert
              openModal={openModalAlert}
              size="tiny"
              close={handleCloseModalAlert}
              message={messageAlert}
            />
            <ModalItinerary
              dailyPrograms={dailyPrograms}
              isOpen={openModalItinerary}
              handleButton={handleButtonItinerary}
              currentPackage={currentPackage}
            />
            <ModalConfirm
              openModal={modalBookNow}
              size="tiny"
              message="Are you sure want to book this tour?"
              close={handleCloseBookNow}
              confirm={confirmBook}
            />
            <ModalAlert
              openModal={modalAlert}
              size="tiny"
              close={handleCloseAlert} // handleCloseAlert
              message={
                modalAlertDescSuccess
                  ? modalAlertDescSuccess
                  : modalAlertDescFailed
              }
            />
            <ModalConfirm
              openModal={modalPayment}
              size="tiny"
              message={t('AreYouSureWantToPay', {
                paymentAction: paymentAction,
              })}
              //   message={'Are you sure want to pay ' + paymentAction + '?'}
              close={handleClosePayment}
              confirm={confirmPay}
            />
            <ModalCancellationReason
              openModal={modalCancel}
              handleSave={handleSaveCancellationReason}
              handleClose={handleCloseCancel}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

MyBookingDetail.propTypes = {
  openDetail: PropTypes.bool,
  id: PropTypes.number,
  resetStatusTourTransactionSummary: PropTypes.func,
  loadingSeeDetail: PropTypes.bool,
  tourTransactionSummaryById: PropTypes.object,
  getTourTransactionSummaryStatus: PropTypes.string,
  countries: PropTypes.array,
  packageType: PropTypes.string,
  getTourTransactionBillsStatus: PropTypes.string,
  tourTransactionBills: PropTypes.array,
  handleBackToList: PropTypes.func,
  match: PropTypes.object,
  t: PropTypes.func,
};

export default withRouter(MyBookingDetail);
