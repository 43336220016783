const BACKGROUND_COLOR = '#252525';
const COLOR_WHITE = '#fefefe';
const GOLD_COLOR = '#e6ca6b';
const COLOR_RED = '#f44336';
const COLOR_BLACK = '#000000';

const style = {
  backgroundPrimary: {
    background: BACKGROUND_COLOR,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  headerGoldNoPadding: {
    background: GOLD_COLOR,
    paddingTop: 0,
    paddingBottom: 0,
  },
  paddingBottomZeroAlignRight: {
    paddingBottom: 0,
    textAlign: 'right',
  },
  paddingBottomZeroAlignCenter: {
    paddingBottom: 0,
    textAlign: 'center',
  },
  paddingBottomZero: {
    paddingBottom: 0,
  },
  goldColor: {
    color: '#e6ca6b',
  },
  goldGradient: {
    borderColor: '#fffc9b',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  },
  noMargin: {
    margin: 0,
  },
  paddingBottomZeroNoBorder: {
    paddingBottom: 0,
    border: 'none',
  },
  borderGold: {
    border: '2px solid #e6ca6b',
  },
  buttonGoldPadding15px: {
    padding: '15px',
    color: COLOR_BLACK,
    background: GOLD_COLOR,
  },
  buttonRedPadding15px: {
    padding: '15px',
    color: COLOR_WHITE,
    background: COLOR_RED,
  },
  buttonGold: {
    color: COLOR_BLACK,
    background: GOLD_COLOR,
  },
  borderGoldBackgroundTransparentGold: {
    border: '0.1px solid rgba(230, 202, 107)',
    background: 'rgba(230, 202, 107,0.3)',
    borderRadius: 0,
    margin: 0,
    padding: 5,
  },
  buttonClose: {
    background: COLOR_WHITE,
    color: COLOR_RED,
    padding: '0px',
    margin: '0px',
  },
  noPadding: {
    padding: 0,
  },
  downloadTemplateStyle: { color: '#e6ca6b', cursor: 'pointer' },
  fileLabelStyle: {
    padding: '8px',
    color: 'grey',
    fontWeight: '100',
  },
  buttonChooseFileStyle: {
    color: '#252525',
    backgroundColor: '#e6ca6b',
    padding: '8px',
  },
  buttonUploadStyle: {
    color: '#e6ca6b',
    border: '1px solid #e6ca6b',
    backgroundColor: '#252525',
    padding: '8px',
  },
  displayInlineBlock: { display: 'inline-block' },
};

export default style;
