import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Button } from 'semantic-ui-react';
import styles from './../styles';
import { ButtonCloseModal } from '../../shared/components/common';
import ResetPassword from './ResetPasswordWithUsername';
import {
  handleValidationPassword,
  handleValidationConfirmPassword,
} from '../../../genericFunction/validation';
import { withTranslation } from 'react-i18next';

class ModalResetPassword extends Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    errors: {
      newPassword: { error: false, message: '' },
      confirmPassword: { error: false, message: '' },
    },
  };

  handleChange = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: value }, () => this.validation());
  };

  validation = () => {
    let newErrors = handleValidationPassword(
      this.state.newPassword,
      this.state.errors,
      'newPassword'
    );
    newErrors = handleValidationConfirmPassword(
      this.state.newPassword,
      this.state.confirmPassword,
      newErrors,
      'confirmPassword'
    );
    this.setState({ errors: newErrors });
  };

  handleButton = () => {
    let data = {
      Username: this.props.user.Username,
      NewPassword: this.state.newPassword,
      ConfirmPassword: this.state.confirmPassword,
    };
    this.props.handleResetPasswordUser(data);
  };

  render() {
    const { t } = this.props;
    let { size, openModal, onClose } = this.props;
    let { newPassword, confirmPassword, errors } = this.state;
    let disabled =
      errors.newPassword.error ||
      errors.confirmPassword.error ||
      newPassword.length === 0;
    return (
      <Modal open={openModal} onClose={onClose} size={size} closeOnDimmerClick>
        <Modal.Header style={styles.backgroundModal}>
          {/* Reset Password */}
          {t('resetPassword')} <ButtonCloseModal onClose={onClose} />
        </Modal.Header>
        <Modal.Content style={styles.backgroundModal}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} style={styles.paddingBottom5px}>
                <ResetPassword
                  handleChange={this.handleChange}
                  username={this.props.user.Username}
                  newPassword={newPassword.data}
                  confirmPassword={confirmPassword.data}
                  validate={errors}
                />
              </Grid.Column>
              <Grid.Column
                width={16}
                textAlign="right"
                style={styles.paddingBottom5px}
              >
                <Button
                  onClick={this.handleButton}
                  style={styles.buttonGoldResetPass}
                  disabled={disabled}
                >
                  {/* Reset Password */}
                  {t('resetPassword')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

ModalResetPassword.propTypes = {
  size: PropTypes.string,
  openModal: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  handleResetPasswordUser: PropTypes.func,
  t: PropTypes.func,
};
export default withTranslation()(ModalResetPassword);
