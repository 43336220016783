import {
  GOLD_COLOR,
  WHITE_COLOR,
  RED_COLOR,
} from '../../../../constant/colour';

const styles = {
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  noBorder: {
    border: 'none',
  },
  segmentGoldTransparent: {
    padding: '15px',
    background: 'rgba(230, 202, 107,0.1)',
    border: 'none',
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  noMargin: {
    margin: 0,
  },

  paddingBottomFive: { paddingBottom: '5px' },
  paddingTopFive: { paddingTop: '5px' },
  borderGold: {
    border: `2px solid ${GOLD_COLOR}`,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  buttonRed: {
    margin: '0px 10px',
    color: WHITE_COLOR,
    background: RED_COLOR,
    border: '2px solid' + RED_COLOR,
  },
  label: {
    width: '50%',
    marginRight: '20px',
  },
  input: {
    width: '50%',
  },
  h4: {
    margin: '0',
    fontSize: '18px',
  },
  h3: {
    marginBottom: '5px',
    fontSize: '24px',
  },
  inputWidth100: {
    width: '100%',
  },
};
const bookNowStyle = brochure => {
  return {
    color: '#252525',
    fontSize: brochure && brochure.length > 0 ? '24px' : '14px',
  };
};
export { styles, bookNowStyle };
