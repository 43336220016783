import {
  POST_RESET_STAFF_PASSWORD,
  PUT_UPDATE_PROFILE,
  GET_USER_PROFILE,
  POST_CHANGE_PASSWORD,
} from './constant/actionTypes';
import {
  postResetStaffPasswordApi,
  putUpdateProfileApi,
  getUserProfileApi,
  postChangePasswordApi,
} from '../../api/accountApi';

const postResetStaffPassword = data => {
  return dispatch => {
    return dispatch({
      type: POST_RESET_STAFF_PASSWORD,
      payload: postResetStaffPasswordApi(data),
    });
  };
};

const putUpdateProfile = (id, data) => {
  return dispatch => {
    return dispatch({
      type: PUT_UPDATE_PROFILE,
      payload: putUpdateProfileApi(id, data),
    });
  };
};

const getUserProfile = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE,
      payload: getUserProfileApi(),
    });
  };
};

const postChangePassword = data => {
  return dispatch => {
    return dispatch({
      type: POST_CHANGE_PASSWORD,
      payload: postChangePasswordApi(data),
    });
  };
};
export {
  postResetStaffPassword,
  putUpdateProfile,
  getUserProfile,
  postChangePassword,
};
