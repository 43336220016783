const styles = {
  popupSelectStyle: {
    top: 'auto',
    borderRadius: '2px',
    border: '1px solid rgba(67,67,67,.17)',
    boxShadow: '0 2px 4px 1px rgba(0,0,0,.14)',
    marginTop: '2px',
    marginLeft: '0px',
    position: 'absolute',
    zIndex: '15',
    backgroundColor: 'white',
    width: '350px',
    maxHeight: '300px',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  itemHeaderStyle: {
    margin: '0px',
    padding: '10px 0px 5px 0px',
    background: 'rgb(220, 220, 220)',
  },
  itemnotFoundStyle: {
    borderTop: '1px solid rgba(67,67,67,.17)',
  },
  colorGray: { color: 'gray' },
};
const itemStyle = i => {
  return {
    backgroundColor: 'white',
    borderTop: i !== 0 ? '1px solid rgba(67,67,67,.17)' : 'none',
    width: '100%',
    textAlign: 'left',
    paddingLeft: '10px',
    margin: '0px 5px 0px 5px',
  };
};
export { styles, itemStyle };
