import React, { useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles';
import filter from '../../constant/packageFilter';
import { useTranslation } from 'react-i18next';

const PackageFilter = props => {
  const { t } = useTranslation();
  const { packageLength, handleChangeFilter } = props;
  const [value, setValue] = useState('All');
  const handleChange = (e, { value }) => {
    setValue(value);
    handleChangeFilter(value);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={15}>
          <Form>
            <Form.Group inline>
              {filter.map((item, i) => (
                <Form.Radio
                  key={i}
                  label={item.label}
                  value={item.value}
                  checked={value === item.value}
                  onChange={handleChange}
                />
              ))}
            </Form.Group>
          </Form>
        </Grid.Column>
        <Grid.Column width={1}>
          {packageLength > 6 ? (
            <Link to="/package-list/series/all">
              {/* View all */}
              <strong style={styles.goldColor}>{t('viewAll')}</strong>
            </Link>
          ) : null}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
PackageFilter.propTypes = {
  handleChangeFilter: PropTypes.func,
  packageLength: PropTypes.number,
  t: PropTypes.func,
};
export default PackageFilter;
