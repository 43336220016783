import React from 'react';
import { Grid, Segment, Header, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardSummaryPrice = props => {
  const { t } = useTranslation();
  let {
    id,
    handleModal,
    status,
    currency,
    totalPrice,
    commision,
    paymentTerm,
    percentagePaymentTerm,
    totalPayable,
    totalPaid,
    packageType,
    isSales,
    isAcceptedQuotation,
    createdBy,
    handleCancel,
    handleSeeDetail,
  } = props;
  const firstName = createdBy && createdBy.FirstName && createdBy.FirstName;
  const lastName = createdBy && createdBy.LastName && createdBy.LastName;
  return (
    <Segment style={styles.marginTopBottom15pxRight10px}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="6">
            <Header as="h4">
              {/* Total Price */} {t('totalPrice')}
            </Header>
          </Grid.Column>
          <Grid.Column width="9">
            <Header as="h4">
              : {currency} {totalPrice ? totalPrice.toLocaleString('ID') : 0}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {packageType === 'Fixed' && (
          <Grid.Row style={styles.paddingTopBottom5px}>
            <Grid.Column width="6">
              <Header as="h4">
                {/* Commission */} {t('commission')}
              </Header>
            </Grid.Column>
            <Grid.Column width="9">
              <Header as="h4">
                : {currency} {commision ? commision.toLocaleString('ID') : 0}
              </Header>
            </Grid.Column>
          </Grid.Row>
        )}
        {paymentTerm && (
          <Grid.Row style={styles.paddingTopBottom10px}>
            <Grid.Column width="6">
              <Header as="h4">
                {/* Payment Term */} {t('paymentTerms')}
              </Header>
            </Grid.Column>
            <Grid.Column width="9">
              <Header as="h4">
                : {paymentTerm} {percentagePaymentTerm}%
              </Header>
              {/* pakai (20%) */}
            </Grid.Column>
          </Grid.Row>
        )}
        {packageType === 'Fixed' ? (
          <Grid.Row style={styles.paddingTopBottom10px}>
            <Grid.Column width="6">
              <Header as="h4" style={styles.redColor}>
                {/* Total Payable */} {t('totalPayable')}
              </Header>
            </Grid.Column>
            <Grid.Column width="9">
              <Header as="h4" style={styles.redColor}>
                : {currency}{' '}
                {totalPayable ? totalPayable.toLocaleString('ID') : 0}
              </Header>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row style={styles.paddingTopBottom10px}>
          <Grid.Column width="6">
            <Header as="h4" style={styles.blueColor}>
              {/* Total Paid */} {t('totalPaid')}
            </Header>
          </Grid.Column>
          <Grid.Column width="9">
            <Header as="h4" style={styles.blueColor}>
              : {currency} {totalPaid ? totalPaid.toLocaleString('ID') : 0}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {status === 'Booking_draft' && (
          <Grid.Row style={styles.paddingTopBottom10px}>
            <Grid.Column width="6">
              <Header as="h4">
                {/* Created by */} {t('createdBy')}
              </Header>
            </Grid.Column>
            <Grid.Column width="9">
              <Header as="h4">
                :{' '}
                {createdBy
                  ? !firstName && !lastName
                    ? '-'
                    : firstName + ' ' + lastName
                  : '-'}
              </Header>
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row>
          <Grid.Column
            width={16}
            textAlign="right"
            style={styles.paddingLeftRight12px}
          >
            {(status !== 'Booking_draft' || isAcceptedQuotation) && (
              <Button
                floated="left"
                basic
                color="blue"
                style={styles.buttonPadding10}
                onClick={handleSeeDetail}
                value={id}
              >
                {/* See Detail */} {t('seeDetail')}
              </Button>
            )}
            {status !== 'Booking_hold' &&
              status !== 'Booking_Confirmed' &&
              status !== 'Booking_Completed' &&
              status !== 'Booking_draft' &&
              status !== 'Booking_Cancelled' &&
              !isSales && (
                <Button
                  name="modalPayment"
                  value={id}
                  desc={paymentTerm}
                  style={styles.buttonGold}
                  onClick={handleModal}
                >
                  {/* Pay */}
                  {t('pay')} {paymentTerm}
                </Button>
              )}
            {status !== 'Booking_hold' &&
              status !== 'Booking_draft' &&
              status !== 'Booking_Cancelled' &&
              status !== 'Booking_Completed' && (
                <Button
                  style={styles.buttonNegative}
                  name="modalDelete"
                  value={id}
                  onClick={handleCancel} //handleModal
                  desc=""
                >
                  {/* Cancel */} {t('cancel')}
                </Button>
              )}
          </Grid.Column>

          {status === 'Booking_draft' && !isAcceptedQuotation && (
            <Grid.Column width={16}>
              <Button
                floated="left"
                basic
                color="blue"
                style={styles.buttonPadding10}
                onClick={handleSeeDetail}
                value={id}
              >
                {/* See Detail */} {t('seeDetail')}
              </Button>
              <Button
                name="modalBookNow"
                value={id}
                style={styles.buttonGold}
                onClick={handleModal}
                floated="right"
              >
                {/* Book Now */} {t('bookNow')}
              </Button>
              <Button
                as={Link}
                name="modalEdit"
                value={id}
                style={styles.buttonGold}
                onClick={handleModal}
                floated="right"
                to={'/ready/quotation/' + id}
              >
                {/* Edit */} {t('edit')}
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
CardSummaryPrice.propTypes = {
  id: PropTypes.number,
  status: PropTypes.string,
  currency: PropTypes.string,
  totalPrice: PropTypes.number,
  commision: PropTypes.number,
  paymentTerm: PropTypes.string,
  percentagePaymentTerm: PropTypes.number,
  totalPayable: PropTypes.number,
  totalPaid: PropTypes.number,
  packageType: PropTypes.string,
  handleModal: PropTypes.func,
  isSales: PropTypes.bool,
  isAcceptedQuotation: PropTypes.bool,
  createdBy: PropTypes.object,
  handleCancel: PropTypes.func,
  t: PropTypes.func,
  handleSeeDetail: PropTypes.func,
};
export default CardSummaryPrice;
