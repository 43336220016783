import {
  POST_REGISTER_SIMPLE_CUSTOMER,
  POST_REGISTER_SIMPLE_CUSTOMER_FULFILLED,
  POST_REGISTER_SIMPLE_CUSTOMER_PENDING,
  POST_REGISTER_SIMPLE_CUSTOMER_REJECTED,
  RESET_REGISTER_SIMPLE_CUSTOMER,
} from '../actions/actionTypes';

const initialState = {
  errors: null,
  loading: false,
  registerSimpleCustomer: {},
  postRegisterSimpleCustomerStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // POST_REGISTER_SIMPLE_CUSTOMER
    //---------------------------------
    case POST_REGISTER_SIMPLE_CUSTOMER:
      return { ...state };
    case POST_REGISTER_SIMPLE_CUSTOMER_PENDING:
      return { ...state, loading: true };
    case POST_REGISTER_SIMPLE_CUSTOMER_FULFILLED:
      return {
        ...state,
        registerSimpleCustomer: action.payload.data,
        errors: {},
        loading: false,
        postRegisterSimpleCustomerStatus: 'success',
      };
    case POST_REGISTER_SIMPLE_CUSTOMER_REJECTED:
      return {
        ...state,
        registerSimpleCustomer: {},
        errors: action.payload.response.data,
        loading: false,
        postRegisterSimpleCustomerStatus: 'failed',
      };
    case RESET_REGISTER_SIMPLE_CUSTOMER:
      return {
        ...state,
        postRegisterSimpleCustomerStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
