import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { withTranslation } from 'react-i18next';
class TabMenu extends Component {
  render() {
    const { t } = this.props;
    let { tabActive, handleTab, isAdminCustomer } = this.props;
    return (
      <Menu secondary>
        <Menu.Item
          name="tabActive"
          value="company detail"
          onClick={handleTab}
          style={
            tabActive === 'company detail'
              ? styles.tabActive
              : styles.tabNonActive
          }
        >
          {/* Company Detail */}
          {t('companyDetail')}
        </Menu.Item>
        {isAdminCustomer && (
          <Menu.Item
            name="tabActive"
            value="user info"
            onClick={handleTab}
            style={
              tabActive === 'user info' ? styles.tabActive : styles.tabNonActive
            }
          >
            {/* User Info */}
            {t('userInfo')}
          </Menu.Item>
        )}
      </Menu>
    );
  }
}
TabMenu.propTypes = {
  tabActive: PropTypes.string,
  handleTab: PropTypes.func,
  isAdminCustomer: PropTypes.bool,
  t: PropTypes.func,
};
export default withTranslation()(TabMenu);
