import React from 'react';
import { Grid, Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './../../styles';
import {
  convertDateFormat,
  getHourAndMinute,
} from '../../../../genericFunction/moment';
import { useTranslation } from 'react-i18next';

const SummaryItenary = props => {
  const { t } = useTranslation();
  let { departure, dataSummaryItinerary, returns } = props;
  return (
    <Grid>
      {departure.map((dep, index) => (
        <Grid.Row key={index} style={styles.padding15px}>
          <Grid.Column width={7}>
            <Header as="h5" className="no-margin">
              {/* {dep.TransferType === 'Movement_departure'
                ? 'Departure'
                : 'Arrival'}
              Destination */}
              {dep.TransferType === 'Movement_departure'
                ? t('departureDestination')
                : t('arrivalDestination')}
            </Header>
            <span>{dep.Place}</span>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header as="h5" className="no-margin">
              {/* {dep.TransferType === 'Movement_departure'
                ? 'Departure'
                : 'Arrival'}{' '}
              Date */}
              {dep.TransferType === 'Movement_departure'
                ? t('departureDate')
                : t('arrivalDate')}
            </Header>
            <span>
              <Icon className="fas fa-calendar-alt" style={styles.goldColor} />{' '}
              {convertDateFormat(dep.Date, 'DD MMM YYYY')}
            </span>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header as="h5" className="no-margin">
              {/* {dep.TransferType === 'Movement_departure'
                ? 'Departure'
                : 'Arrival'}{' '}
              Time */}
              {dep.TransferType === 'Movement_departure'
                ? t('departureTime')
                : t('arrivalTime')}
            </Header>
            <span>
              <Icon className="fas fa-clock" style={styles.goldColor} />{' '}
              {getHourAndMinute(dep.Date)}
            </span>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header as="h5" className="no-margin">
              {/* Flight Number */} {t('flightNumber')}
            </Header>
            <span>{dep.Note || ' - '}</span>
          </Grid.Column>
        </Grid.Row>
      ))}
      {dataSummaryItinerary.map((data, index) =>
        data.TransferType ? (
          data.TransferType === 'Movement_arrival' ? (
            <Grid.Row key={index} style={{ padding: '15px' }}>
              <Grid.Column width={7}>
                <Header as="h5" className="no-margin">
                  {/* Arrival Destination */} {t('arrivalDestination')}
                </Header>
                <span>{data.Place}</span>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" className="no-margin">
                  {/* Arrival Date */} {t('arrivalDate')}
                </Header>
                <span>
                  <Icon
                    className="fas fa-calendar-alt"
                    style={styles.goldColor}
                  />{' '}
                  {convertDateFormat(data.Date, 'DD MMM YYYY')}
                </span>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" className="no-margin">
                  {/* Arrival Time */} {t('arrivalTime')}
                </Header>
                <span>
                  <Icon className="fas fa-clock" style={styles.goldColor} />{' '}
                  {getHourAndMinute(data.Date)}
                </span>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" className="no-margin">
                  {/* Flight Number */} {t('flightNumber')}
                </Header>
                <span>{data.Note || ' - '}</span>
              </Grid.Column>
            </Grid.Row>
          ) : data.TransferType === 'Movement_departure' ? (
            <Grid.Row key={index} style={styles.padding15px}>
              <Grid.Column width={7}>
                <Header as="h5" className="no-margin">
                  {/* Departure Destination */} {t('departureDestination')}
                </Header>
                <span>{data.Place}</span>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" className="no-margin">
                  {/* Departure Date */} {t('departureDate')}
                </Header>
                <span>
                  <Icon
                    className="fas fa-calendar-alt"
                    style={styles.goldColor}
                  />{' '}
                  {convertDateFormat(data.Date, 'DD MMM YYYY')}
                </span>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" className="no-margin">
                  {/* Departure Time */} {t('departureTime')}
                </Header>
                <span>
                  <Icon className="fas fa-clock" style={styles.goldColor} />{' '}
                  {getHourAndMinute(data.Date)}
                </span>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h5" className="no-margin">
                  {/* Flight Number */} {t('flightNumber')}
                </Header>
                <span>{data.Note || ' - '}</span>
              </Grid.Column>
            </Grid.Row>
          ) : (
            ''
          )
        ) : (
          <Grid.Row key={index} style={styles.goldCardColor15px}>
            <Grid.Column width={7}>
              <Header as="h5" className="no-margin">
                {/* City Destination */} {t('cityDestination')}
              </Header>
              <span>
                <Icon
                  className="fas fa-map-marker-alt"
                  style={styles.goldColor}
                />{' '}
                {data.City.Name}, {data.Region}
              </span>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header as="h5" className="no-margin">
                {/* Total Days */} {t('totalDays')}
              </Header>
              <span>{data.TotalDays}</span>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header as="h5" className="no-margin">
                {/* Check in Date */}
                {t('checkInDate')}
              </Header>
              <span>
                <Icon
                  className="fas fa-calendar-alt"
                  style={styles.goldColor}
                />{' '}
                {convertDateFormat(data.Date, 'DD MMM YYYY')}
              </span>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header as="h5" className="no-margin">
                {/* Check in Time */}
                {t('checkInTime')}
              </Header>
              <span>
                <Icon className="fas fa-clock" style={styles.goldColor} />{' '}
                {getHourAndMinute(data.Date)}
              </span>
            </Grid.Column>
            <Grid.Column width={10} style={styles.marginTop10px}>
              <Header as="h5" className="no-margin">
                {/* Accomodation */}
                {t('accommodation')}
              </Header>
              <span>
                {data.AccommodationSummary.Name} <br />
                {data.AccommodationSummary.RoomName},{' '}
                {data.AccommodationSummary.RoomService.replace('_', ' ')}
              </span>
            </Grid.Column>
            <Grid.Column width={3} style={styles.marginTop10px}>
              <Header as="h5" className="no-margin">
                {/* Check out Date */} {t('checkOutDate')}
              </Header>
              <span>
                <Icon
                  className="fas fa-calendar-alt"
                  style={styles.goldColor}
                />{' '}
                {convertDateFormat(data.LeavingDate, 'DD MMM YYYY')}
              </span>
            </Grid.Column>
            <Grid.Column width={3} style={styles.marginTop10px}>
              <Header as="h5" className="no-margin">
                {/* Check out Time */} {t('checkOutTime')}
              </Header>
              <span>
                <Icon className="fas fa-clock" style={styles.goldColor} />{' '}
                {getHourAndMinute(data.LeavingDate)}
              </span>
            </Grid.Column>
          </Grid.Row>
        )
      )}
      {returns.map((data, index) => (
        <Grid.Row key={index} style={styles.padding15px}>
          <Grid.Column width={7}>
            <Header as="h5" className="no-margin">
              {/* {data.TransferType === 'Movement_departure'
                ? 'Departure'
                : 'Arrival'}{' '}
              Destination */}
              {data.TransferType === 'Movement_departure'
                ? t('departureDestination')
                : t('arrivalDestination')}
            </Header>
            <span>{data.Place}</span>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header as="h5" className="no-margin">
              {/* {data.TransferType === 'Movement_departure'
                ? 'Departure'
                : 'Arrival'}{' '}
              Date */}
              {data.TransferType === 'Movement_departure'
                ? t('departureDate')
                : t('arrivalDate')}
            </Header>
            <span>
              <Icon className="fas fa-calendar-alt" style={styles.goldColor} />{' '}
              {convertDateFormat(data.Date, 'DD MMM YYYY')}
            </span>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header as="h5" className="no-margin">
              {/* {data.TransferType === 'Movement_departure'
                ? 'Departure'
                : 'Arrival'}{' '}
              Time */}
              {data.TransferType === 'Movement_departure'
                ? t('departureTime')
                : t('arrivalTime')}
            </Header>
            <span>
              <Icon className="fas fa-clock" style={styles.goldColor} />{' '}
              {getHourAndMinute(data.Date)}
            </span>
          </Grid.Column>
          <Grid.Column width={3}>
            <Header as="h5" className="no-margin">
              {/* Flight Number */} {t('flightNumber')}
            </Header>
            <span>{data.Note || ' - '}</span>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

SummaryItenary.propTypes = {
  departure: PropTypes.array,
  returns: PropTypes.array,
  dataSummaryItinerary: PropTypes.array,
  t: PropTypes.func,
};
export default SummaryItenary;
