import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import ButtonClose from '../../../../components/button/BtnClose';
import style from './styles';

const ModalCancelBooking = props => {
  const { close, open } = props;
  const [check, setCheck] = useState(false);

  const handleCheckBox = () => {
    setCheck(!check);
  };

  return (
    <>
      <Modal.Header>
        Do you want to cancel this booking ?
        <ButtonClose onClose={close} />
      </Modal.Header>
      <Modal.Content>
        <Grid.Column>
          Each package has a different cancellation policy. Have you read the
          information regarding cancellation on the invoice.
        </Grid.Column>
        <Grid.Column>
          <Form>
            <Checkbox
              style={{
                ...style.marginModalCancellationReason.checkBoxMargin,
                ...style.marginModalCancellationReason.borderRadius25,
              }}
              name="isCheckBoxChecked"
              value={check}
              label={{
                children: 'Yes, I have read cancellation policy',
              }}
              onChange={handleCheckBox}
              checked={check}
            />
          </Form>
        </Grid.Column>
      </Modal.Content>
      <Modal.Actions style={style.center}>
        <Button
          style={style.buttonConfirm}
          content="Yes, cancel this booking"
          disabled={!check}
          name="isCheckBoxChecked"
          onClick={open}
          className="buttonCancel"
        />
        <Button style={style.buttonCancel} content="No" onClick={close} />
      </Modal.Actions>
    </>
  );
};
ModalCancelBooking.propTypes = {
  close: PropTypes.func,
  open: PropTypes.func,
};
export default ModalCancelBooking;
