import React from 'react';
import { Segment } from 'semantic-ui-react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import styles from './styles';
const SegmentAdditionalInformation = props => {
  return (
    <div>
      {props.descriptions.length > 0 ? (
        <Segment style={styles.segmentNoPadding}>
          {props.descriptions.map(item => (
            <div key={item.Id}>
              <h2>{Parser(item.SubTitle)}</h2>
              <p>{Parser(item.Content)}</p>
            </div>
          ))}
        </Segment>
      ) : null}
    </div>
  );
};

SegmentAdditionalInformation.propTypes = {
  descriptions: PropTypes.array,
};
export default SegmentAdditionalInformation;
