const style = {
  textCenter: {
    textAlign: 'center',
  },
  fontSize: {
    fontSize: '20px',
  },
  videoStyle: { height: '365px' },
  padding50px60px50px140px: { padding: '50px 60px 50px 140px' },
  paddingRight150: { padding: '0px 0px 0px 150px' },
  width350px: { width: '350px' },
  marginTop45: { marginTop: '45px' },
  marginTop12: { marginTop: '12px' },
  marginLeft25: { marginLeft: '25px' },
  navStyle: {
    height: '100%',
    right: '-30px',
    zIndex: 1,
    color: 'grey',
  },
};

export default style;
