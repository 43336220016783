import * as types from '../actions/actionTypes';

const initialState = {
  mainCarouselImage: [],
  loading: false,
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_CAROUSEL_IMAGE
    //---------------------------------
    case types.GET_CAROUSEL_IMAGE:
      return { ...state };
    case types.GET_CAROUSEL_IMAGE_PENDING:
      return { ...state, loading: true };
    case types.GET_CAROUSEL_IMAGE_FULFILLED:
      return {
        ...state,
        mainCarouselImage: action.payload.data,
        errors: {},
        loading: false,
      };
    case types.GET_CAROUSEL_IMAGE_REJECTED:
      return {
        ...state,
        mainCarouselImage: [],
        errors: action.payload.response.data,
        loading: false,
      };
    //---------------------------------
    // GET_CAROUSEL_IMAGE_AFTER_LOGIN
    //---------------------------------
    case types.GET_CAROUSEL_IMAGE_AFTER_LOGIN:
      return { ...state };
    case types.GET_CAROUSEL_IMAGE_AFTER_LOGIN_PENDING:
      return { ...state, loading: true };
    case types.GET_CAROUSEL_IMAGE_AFTER_LOGIN_FULFILLED:
      return {
        ...state,
        mainCarouselImage: action.payload.data,
        errors: {},
        loading: false,
      };
    case types.GET_CAROUSEL_IMAGE_AFTER_LOGIN_REJECTED:
      return {
        ...state,
        mainCarouselImage: [],
        errors: action.payload.response.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
