import React, { useState } from 'react';
import { Segment, Form, Grid, Button } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';
import InputWithLabel from '../../../../components/input/WithLabel';
import { postLogin } from '../../actions/authenticationAction';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import handleValidation from './services/emptyFieldValidation';
import { loginField } from './constant/fieldToValidate';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const Login = props => {
  const { t } = useTranslation();

  const [loginObj, setLoginObj] = useState({
    CompanyCode: '',
    Username: '',
    Password: '',
  });
  const [validate, setValidate] = useState({
    CompanyCode: { error: false, message: '' },
    Username: { error: false, message: '' },
    Password: { error: false, message: '' },
  });
  const [passwordConfig, setPasswordConfig] = useState({
    passwordIcon: 'eye slash',
    passwordInputType: 'password',
    passwordShow: false,
  });
  const dispatch = useDispatch();
  const postLoginAction = data => dispatch(postLogin(data));
  const handleChange = (e, { name, value }) => {
    let newLoginObj = loginObj;
    newLoginObj[name] = value;
    setLoginObj(newLoginObj);
  };

  const handleLogin = () => {
    const validation = handleValidation(loginObj, loginField);
    setValidate(validation);
    const isError = !_.isEmpty(validation);
    if (!isError) {
      props.closePopup && props.closePopup();
      postLoginAction(loginObj);
    }
  };

  const onClickPassIcon = (e, { name }) => {
    let icon = `${name}Icon`;
    let show = `${name}Show`;
    let inputType = `${name}InputType`;
    if (passwordConfig[show]) {
      setPasswordConfig({
        [icon]: 'eye slash',
        [show]: false,
        [inputType]: 'password',
      });
    } else {
      setPasswordConfig({
        [icon]: 'eye',
        [show]: true,
        [inputType]: 'text',
      });
    }
  };
  return (
    <Segment style={styles.loginSegmentStyle}>
      {/* Agent Login */}
      <h3 style={styles.alignCenterMarginBotFive}>{t('agentLogin')}</h3>
      <Form key="mini" onSubmit={handleLogin}>
        <Form.Field>
          <InputWithLabel
            type="number"
            label={`${t('companyId')}`} //"Company Id"
            labelStyles={styles.font14}
            name="CompanyCode"
            value={loginObj.CompanyCode.value}
            handleChange={handleChange}
            placeholder={`${t('companyId')}`} //"Company Id"
            validate={validate}
            required
          />
        </Form.Field>
        <Form.Field>
          <InputWithLabel
            type="text"
            label={`${t('username')}`} //"Username"
            labelStyles={styles.font14}
            name="Username"
            value={loginObj.Username.value}
            handleChange={handleChange}
            placeholder={`${t('username')}`} //"Username"
            validate={validate}
            required
          />
        </Form.Field>
        <Form.Field required>
          <Grid>
            <Grid.Column width={8}>
              <label style={styles.font14}>
                <strong>
                  {/* Password */}
                  {t('password')} <span style={styles.colorRed}>*</span>
                </strong>
              </label>
            </Grid.Column>
            <Grid.Column width={8} style={styles.alignRight}>
              <label style={styles.font14}>
                <Link tabIndex="1" to="/forgot-password">
                  {/* Forgot Password? */}
                  <strong style={styles.goldColor}>
                    {t('forgotPassword?')}
                  </strong>
                </Link>
              </label>
            </Grid.Column>
          </Grid>
          <InputWithLabel
            placeholder={`${t('password')}`} //"Password"
            handleChange={handleChange}
            value={loginObj.Password.value}
            name="Password"
            type={passwordConfig.passwordInputType}
            action={
              <Button
                tabIndex="2"
                name="password"
                icon={passwordConfig.passwordIcon}
                onClick={onClickPassIcon}
                type="button"
              />
            }
            validate={validate}
          />
        </Form.Field>
        <div style={{ textAlign: 'center' }}>
          <Button type="submit" style={styles.backgroundGold}>
            {/* Login */}
            <strong style={styles.loginButton}>{t('logIn')}</strong>
          </Button>
        </div>
        <p style={styles.linkSection}>
          {/* New Travel Agent? Want to Join? */}
          <strong>{t('newTravelAgent')}</strong>
          <Link to="/register">
            {/* Register */}
            <strong style={styles.goldColor}> {t('register')}</strong>
          </Link>
        </p>
      </Form>
    </Segment>
  );
};
Login.propTypes = {
  postLogin: PropTypes.func,
  closePopup: PropTypes.func,
  t: PropTypes.func,
};
export default React.memo(Login);
