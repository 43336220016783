const style = {
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  textBlackBold: {
    color: '#252525',
    fontWeight: 'bold',
  },
  selectTransparent: {
    width: '100%',
    // border: '0px',
    // borderBottom: '2px solid lightgrey',
  },
  paddingBottomTopZero: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  paddingTopBottom10px: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  textSimilarPackage: {
    padding: '50px 0px 10px 0px',
  },
  divPagination: { textAlign: 'center', paddingTop: '20px' },
  nopaddingBottom: { paddingBottom: 0 },
  noMargin: { margin: 0 },
};

export default style;
