const createTourPriceObj = tourPriceSum => {
  let tourPriceRoom = [
    {
      name: 'Sharing Room',
      data: tourPriceSum ? tourPriceSum.SharingRoomSum : {},
    },
    {
      name: 'Child Sharing Room',
      data: tourPriceSum ? tourPriceSum.ChildSharingRoomSum : {},
    },
    {
      name: 'Single Room',
      data: tourPriceSum ? tourPriceSum.SingleRoomSum : {},
    },
    {
      name: 'Child Single Room',
      data: tourPriceSum ? tourPriceSum.ChildSingleRoomSum : {},
    },
    {
      name: 'Extra Bed',
      data: tourPriceSum ? tourPriceSum.ExtraBedSum : {},
    },
    {
      name: 'Child Extra Bed',
      data: tourPriceSum ? tourPriceSum.ChildExtraBedSum : {},
    },
    {
      name: 'Sharing Bed with Parents',
      data: tourPriceSum ? tourPriceSum.SharingBedSum : {},
    },
    {
      name: 'Baby Crib / No Bed',
      data: tourPriceSum ? tourPriceSum.NoBedSum : {},
    },
  ];
  return tourPriceRoom;
};

export default createTourPriceObj;
