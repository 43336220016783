import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Message } from 'semantic-ui-react';
import styles from './styles';
import { connect } from 'react-redux';
import {
  getHourAndMinute,
  convertDateFormat,
} from '../../../../../genericFunction/moment';
import { getPlaceByRegion } from '../../../actions/locationAction';
import ItemWithIcon from '../../../../../components/common/ItemWithIcon';
import { isEarlyCheck } from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import { withTranslation } from 'react-i18next';
class AccommodationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkinDate: props.data.AccommodationSummary.CheckIn,
      checkoutDate: props.data.AccommodationSummary.CheckOut,
      checkinTime: getHourAndMinute(props.data.AccommodationSummary.CheckIn),
      checkoutTime: getHourAndMinute(props.data.AccommodationSummary.CheckOut),
      destination: props.data.City ? props.data.City.Id : null,
    };
  }

  componentDidUpdate(prevProps) {
    const { checkinDate, checkoutDate, checkinTime, checkoutTime } = this.state;
    const { CheckIn, CheckOut } = this.props.data.AccommodationSummary;
    if (
      checkinDate !== CheckIn ||
      checkoutDate !== CheckOut ||
      checkinTime !== getHourAndMinute(CheckIn) ||
      checkoutTime !== getHourAndMinute(CheckOut) ||
      prevProps.selectedDate !== this.props.selectedDate
    )
      this.setState({
        ...this.state,
        checkinDate: CheckIn,
        checkoutDate: CheckOut,
        checkinTime: getHourAndMinute(CheckIn),
        checkoutTime: getHourAndMinute(CheckOut),
      });
  }

  getAccommodationName = data => {
    return data.AccommodationSummary.Name
      ? `${data.AccommodationSummary.Name}, ${
          data.AccommodationSummary.RoomName
        }, ${data.AccommodationSummary.RoomService.replace('_', ' ')}`
      : '-';
  };
  isEven = num => {
    return num % 2 === 0 ? true : false;
  };

  render() {
    const { t } = this.props;
    const { data, index } = this.props;
    const {
      checkinTime,
      checkoutTime, // destination,
      checkinDate,
      checkoutDate,
    } = this.state;
    const accommodation = this.getAccommodationName(data);
    const destination = data.City ? data.City.Name : '-';
    const styleBg =
      index === 0 || this.isEven(index) ? styles.backgroundGoldTransparent : {};
    const earlyCheck = isEarlyCheck(data.AccommodationSummary.CheckIn);
    return (
      <div>
        <Grid style={styleBg}>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText={t('checkInDate')} //"Checkin Date"
                      labelStyle={styles.labelItemStyle}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={convertDateFormat(
                        checkinDate,
                        'DD/MM/YYYY'
                      )}
                      iconName="calendar"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText={t('checkInTime')} //"Checkin Time"
                      labelStyle={styles.labelItemStyle}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={checkinTime}
                      iconName="clock"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText={t('totalDays')} //"Total Days"
                      labelStyle={styles.labelItemStyle}
                      withIcon={false}
                      descriptionText={data.TotalDays}
                      descriptionStyle={styles.textCenter}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText={t('checkOutDate')} //"Checkout Date"
                      labelStyle={styles.labelItemStyle}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={convertDateFormat(
                        checkoutDate,
                        'DD/MM/YYYY'
                      )}
                      iconName="calendar"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText={t('checkOutTime')} //"Checkout Time"
                      labelStyle={styles.labelItemStyle}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={checkoutTime}
                      iconName="clock"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      labelText={t('cityDestination')} //"City Destination"
                      labelStyle={styles.labelItemStyle}
                      withIcon={false}
                      iconStyle={styles.goldColor}
                      descriptionText={destination}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <ItemWithIcon
                    labelText={t('accommodation')} //"Accommodation"
                    labelStyle={styles.labelItemStyle}
                    withIcon={false}
                    iconStyle={styles.goldColor}
                    descriptionText={accommodation}
                  />
                </Form.Field>
              </Form>
              {earlyCheck && (
                <Message warning>
                  <Message.Header>{t('note')}:</Message.Header>
                  <p style={styles.colorRed}>
                    {/* Your check in time is less than 2 PM, there will be extra
                    charge for early checkin. */}
                    {t('warningEarlyCheckIn')}
                  </p>
                </Message>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = () => {
  return {};
};
AccommodationCard.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  getPlaceByRegion: PropTypes.func,
  selectedDate: PropTypes.string,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    getPlaceByRegion,
  }
)(withTranslation()(AccommodationCard));
