import React from 'react';
import PropTypes from 'prop-types';
import HeaderAdditionalServices from './HeaderAdditionalServices';
import FormAdditionalService from './FormAdditionalService';

const TableAdditionalServices = props => {
  const {
    data,
    handleIncrease,
    handleDecrease,
    handleDelete,
    demoPriceLoading,
    handleChangeAmount,
  } = props;
  return (
    <>
      <HeaderAdditionalServices data={data} isShowButton={true} />
      <FormAdditionalService
        data={data}
        handleDelete={handleDelete}
        handleIncrease={handleIncrease}
        handleDecrease={handleDecrease}
        demoPriceLoading={demoPriceLoading}
        handleChangeAmount={handleChangeAmount}
        //       currentPackage
      />
    </>
  );
};

TableAdditionalServices.propTypes = {
  data: PropTypes.array,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleDelete: PropTypes.func,
  demoPriceLoading: PropTypes.bool,
  handleChangeAmount: PropTypes.func,
};

export default React.memo(TableAdditionalServices);
