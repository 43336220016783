import {
  RECREATION,
  EAT,
  FREETIME,
} from '../../../../../../constant/activityTypes';
const getHeaderTitle = activityType => {
  switch (activityType) {
    case RECREATION:
      return 'Set Excursion Activity';
    case EAT:
      return 'Set Meal Activity';
    case FREETIME:
      return 'Set Free Time Activity';
    default:
      return '';
  }
};

export default getHeaderTitle;
