import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Button, Label, Popup } from 'semantic-ui-react';
import ImageCard from '../../../card/ImageCard';
import styles from './styles';

import { useTranslation } from 'react-i18next';

const Card = props => {
  const { t } = useTranslation();
  const handleSetRestaurant = () => {
    const { data, setRestaurant } = props;
    setRestaurant(data);
  };

  let {
    images,
    image,
    header,
    menuClass,
    category,
    typeMenu,
    description,
    id,
  } = props;
  let shortDesc = description ? description.substring(0, 50) : '-';
  let nexDesc = description ? description.substring(51, 52) : '';
  return (
    <Segment.Group horizontal style={styles.segmentNoPadding}>
      <ImageCard images={images} image={image} isPromo={false} size="300px" />
      <Segment style={styles.borderNone}>
        <Grid style={styles.gridMargins}>
          <Header as="h3">{header}</Header>
          <Grid.Row style={styles.noPaddingTop}>
            <Grid.Column width={16}>
              <Label basic style={styles.label}>
                {menuClass}
              </Label>
              <Label basic style={styles.label}>
                {typeMenu}
              </Label>
            </Grid.Column>
            <Grid.Column width={16} style={styles.marginTopFive}>
              <Label basic style={styles.label}>
                {category}
              </Label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.noPaddingTop}>
            <Grid.Column width="16">
              <Header as="h5" style={styles.marginBottomThree}>
                {/* Description */}
                {t('description')}
              </Header>
              {description ? (
                <span>
                  {shortDesc}
                  {nexDesc && (
                    <Popup
                      trigger={<span>...</span>}
                      content={description}
                      size="small"
                    />
                  )}
                </span>
              ) : (
                <p>-</p>
              )}
            </Grid.Column>
            <Grid.Column width="6" floated="right" style={styles.marginTopTen}>
              <Button
                size="large"
                style={styles.buttonGoldCard}
                value={id}
                onClick={handleSetRestaurant}
              >
                {/* CHOOSE */} {t('Choose')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment.Group>
  );
};
Card.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  header: PropTypes.string,
  menuClass: PropTypes.string,
  category: PropTypes.string,
  typeMenu: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  setRestaurant: PropTypes.func,
  data: PropTypes.object,
  t: PropTypes.func,
};
export default Card;
