import React from 'react';
import { Segment, Grid, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import { converNumberToK } from '../../../../genericFunction/numberConverter';
import InputDate from '../../../../components/input/DateInput';
import { useTranslation } from 'react-i18next';

const HeaderInfo = props => {
  const { t } = useTranslation();
  const { prices, availablePax, tourDate } = props;
  return (
    <Segment style={styles.noBorder}>
      <h2>{t('tourForm')}</h2> {/* Tour Form */}
      <Grid>
        <Grid.Column width={10}>
          {/* Starting from */}
          <h4 style={styles.noMargin}>{t('startingFrom')} : </h4>
          <h2 style={styles.noMarginGold}>
            {prices
              ? `${prices.CurrencyId} ${converNumberToK(
                  prices.SharingRoomPrice
                )}`
              : '-'}
          </h2>
          {/* Per Person on Twin Sharing */}
          <h4 style={styles.noMargin}>{t('perPersonOnTwinSharing')}</h4>
        </Grid.Column>
        {props.isVariableDate ? (
          <Grid.Column width={6}>
            <Form>
              <Form.Field>
                <InputDate
                  label="Select Date"
                  value={props.selectedDate}
                  labelStyle={styles.inputStyle}
                  range={true}
                  setDate={props.handleChangeSelectedDate}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        ) : (
          <Grid.Column width={6} textAlign="right">
            <React.Fragment>
              {' '}
              {/* Pax Available */}
              <h4 style={styles.noMargin}>{t('paxAvailable')} : </h4>
              <h2 style={styles.noMarginGold}>
                {availablePax} {t('pax')}
              </h2>
              <h4 style={styles.noMargin}>{tourDate}</h4>
            </React.Fragment>
          </Grid.Column>
        )}
      </Grid>
    </Segment>
  );
};

HeaderInfo.propTypes = {
  availablePax: PropTypes.number,
  tourDate: PropTypes.string,
  prices: PropTypes.object,
  isVariableDate: PropTypes.bool,
  handleChangeSelectedDate: PropTypes.func,
  selectedDate: PropTypes.string,
  t: PropTypes.func,
};
export default HeaderInfo;
