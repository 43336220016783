import React, { Component } from 'react';
import { Button, Modal, Grid } from 'semantic-ui-react';
// import styles from '../../../../components/common/styles';
import styles from './styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ModalConfirm extends Component {
  render() {
    const { t } = this.props;
    const {
      openModal,
      size,
      confirm,
      close,
      message,
      nameConfirm,
      contentOk,
      contentNo,
    } = this.props;
    return (
      <Modal
        size={size ? size : 'mini'}
        open={openModal}
        onClose={close}
        closeOnDimmerClick={false}
      >
        {/* <Modal.Header>Confirmation</Modal.Header> */}
        <Modal.Content>
          <Grid columns="equal" style={styles.textCenter}>
            <Grid.Row>
              <Grid.Column>
                <h2>{message}</h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  style={styles.buttonNegative}
                  content={contentNo ? contentNo : t('no')} //'No'
                  onClick={close}
                />
                <Button
                  name={nameConfirm}
                  style={styles.buttonPositive}
                  content={contentOk ? contentOk : t('yes')} //'Yes'
                  onClick={confirm}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
ModalConfirm.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  confirm: PropTypes.func,
  message: PropTypes.string,
  nameConfirm: PropTypes.string,
  contentOk: PropTypes.string,
  contentNo: PropTypes.string,
  t: PropTypes.func,
};
export default withTranslation()(ModalConfirm);
