import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Segment, Header, Icon, Grid, Button } from 'semantic-ui-react';
import ImageCard from '../../../card/ImageCard';
import ModalDetailListMenu from '../modalDetailListMenu/ModalDetailListMenu';
import { getRestaurantMenuById } from '../../../../actions/restaurantAction';
import styles from './styles';

import { useTranslation } from 'react-i18next';

const Card = props => {
  const { t } = useTranslation();
  let {
    images,
    image,
    isPromo,
    name,
    address,
    id,
    classes,
    typeMenu,
    menus,
    loadingRestaurantMenuById,
    setRestaurant,
    data,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const getRestaurantMenuByIdAction = value =>
    dispatch(getRestaurantMenuById(value));
  const openCloseModal = (e, { value }) => {
    setOpenModal(prevState => !prevState);
    getRestaurantMenuByIdAction(value);
  };

  return (
    <div>
      <Segment.Group horizontal style={styles.segmentNoPadding}>
        <ImageCard
          images={images}
          image={image}
          isPromo={isPromo}
          size="300px"
        />
        <Segment style={styles.borderNone}>
          <Header as="h3" style={styles.goldColor}>
            {name}
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width="1">
                <Icon name="map marker alternate" style={styles.goldColor} />
              </Grid.Column>
              <Grid.Column width="14" style={styles.justify}>
                {address}
              </Grid.Column>
            </Grid.Row>

            {classes && (
              <Grid.Row style={styles.paddingTopZero}>
                <Grid.Column width="1">
                  <Icon className="restorant-speciality" />
                </Grid.Column>
                <Grid.Column width="14">
                  <Header as="h5" style={styles.marginTopLeftTen}>
                    {classes} Dinning - {typeMenu}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column width="9" style={styles.marginTop10px}>
                <Header as="h5" style={styles.noMargin}>
                  {/* Estimated Total Price: */}
                  {t('estimatedTotalPrice')}:
                </Header>
                <Header
                  as="h4"
                  style={{ ...styles.goldColor, ...styles.noMargin }}
                >
                  {`${
                    data.EstimatedTotalPrice.CurrencyId
                  } ${data.EstimatedTotalPrice.Price.toLocaleString('id')}`}
                </Header>
              </Grid.Column>
              <Grid.Column width="7" floated="right">
                <Button
                  size="large"
                  style={styles.buttonGold}
                  onClick={openCloseModal}
                  value={id}
                >
                  {/* SEE MENUS */}
                  {t('SeeMenus')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
      <ModalDetailListMenu
        open={openModal}
        onClose={openCloseModal}
        listDetailData={menus}
        loading={loadingRestaurantMenuById}
        setRestaurant={setRestaurant}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    menus: state.restaurant.restaurantMenusById,
    loadingRestaurantMenuById: state.restaurant.loadingRestaurantMenuById,
  };
};

Card.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  isPromo: PropTypes.bool,
  name: PropTypes.string,
  address: PropTypes.string,
  id: PropTypes.number,
  classes: PropTypes.string,
  typeMenu: PropTypes.string,
  getRestaurantMenuById: PropTypes.func,
  menus: PropTypes.array,
  loadingRestaurantMenuById: PropTypes.bool,
  setRestaurant: PropTypes.func,
  data: PropTypes.object,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    getRestaurantMenuById,
  }
)(Card);
