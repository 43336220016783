import React from 'react';
import { Grid, Segment, Button } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';
import ImageProgressive from '../../../../components/progressiveImage/ImageProgressive'; //'../progressiveImage/ImageProgressive';

import { useTranslation } from 'react-i18next';

const bgImage = imgUrl => {
  return {
    width: '100%',
    height: '280px',
    background: `url(${imgUrl})`,
    backgroundSize: 'cover',
    borderBottom: '0px',
    borderRadius: '5px 5px 0px 0px',
  };
};
const IndoDestCard = props => {
  const { t } = useTranslation();
  let { image, location, description, openModal, type } = props;
  return (
    <Grid.Column>
      <Segment style={styles.noPadding} name={type}>
        <ImageProgressive
          style={bgImage(image)}
          preview={image}
          src={image}
          styles={styles.progressiveImage}
        />
        <div style={styles.paddingFifteen}>
          <h4>{location}</h4>
          <p style={styles.textAlign}>{description}</p>
          <div style={styles.textRight}>
            <Button
              style={styles.buttonSeeDetailIndonesia}
              onClick={openModal}
              value={type}
              name={type}
            >
              {/* SEE DETAIL */}
              {t('SeeDetail')}
            </Button>
          </div>
        </div>
      </Segment>
    </Grid.Column>
  );
};
IndoDestCard.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  type: PropTypes.string,
  openModal: PropTypes.func,
};
export default IndoDestCard;
