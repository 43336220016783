import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Popup, Icon } from 'semantic-ui-react';
import InputAmount from '../../../../../../components/input/Amount';
import { numberRoundUp2Decimal } from '../../../../../../genericFunction/numberConverter';
import styles from '../styles';
import { useTranslation } from 'react-i18next';

const AdditionalServiceForm = props => {
  const { t } = useTranslation();
  const {
    data,
    handleDelete,
    handleIncrease,
    handleDecrease,
    demoPriceLoading,
    handleChangeAmount,
    // currentPackage,
  } = props;
  // let isReadOnly =
  //   currentPackage &&
  //   (currentPackage.status === 'edit' && currentPackage.type !== 'Quotation');
  return (
    <>
      <Grid style={styles.marginForm}>
        {data &&
          data.map((item, idx) => {
            return (
              <>
                <Grid.Row columns={4} key={idx} verticalAlign="middle">
                  <Grid.Column
                    width={6}
                    className={item.IsInfantCount && 'asteriksMarkDouble'}
                  >
                    {item.AdditionalServiceName}
                  </Grid.Column>
                  <Grid.Column
                    width={4}
                    textAlign="center"
                    verticalAlign="middle"
                  >
                    <InputAmount
                      name={item.AdditionalServiceId}
                      value={item.Quantity}
                      handleIncrease={handleIncrease}
                      handleDecrease={handleDecrease}
                      disabled={item.IsMandatory || demoPriceLoading}
                      handleOnChange={handleChangeAmount}
                    />
                  </Grid.Column>
                  <Grid.Column width={5}>
                    {`${item.Currency} ${numberRoundUp2Decimal(
                      item.Amount * item.Quantity
                    )}`}
                  </Grid.Column>
                  <Grid.Column width={1} verticalAlign="middle">
                    {!item.IsMandatory ? (
                      <Popup
                        trigger={
                          <Icon
                            link
                            name="delete"
                            color="red"
                            size="large"
                            onClick={handleDelete}
                            id={item.AdditionalServiceId}
                          />
                        }
                        // Delete additional service
                        content={t('deleteAdditionalService')}
                      />
                    ) : null}
                  </Grid.Column>
                </Grid.Row>
                <Divider style={styles.marginDevider} />
              </>
            );
          })}
      </Grid>
      {data.find(item => item.IsInfantCount) && (
        <Grid textAlign="left" style={styles.marginForm}>
          <Grid.Row>
            <Grid.Column>
              {/* Infant Is Included */}
              <small style={styles.colorRed}>** {t('infantIsIncluded')}</small>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};

AdditionalServiceForm.propTypes = {
  data: PropTypes.array,
  handleDelete: PropTypes.func,
  handleChangeAmount: PropTypes.func,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  currentPackage: PropTypes.object,
  demoPriceLoading: PropTypes.bool,
  t: PropTypes.func,
};

export default React.memo(AdditionalServiceForm);
