import React, { Component } from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import styles from './styles';
import TimeLineDaily from './timeLine/TimeLineDaily';
import PropTypes from 'prop-types';
import ModalListTransportationUnit from './modal/modalListTransportationUnits/ModalListTransportationUnits';
import ModalSetActivity from './modal/modalSetActivity/ModalSetActivity';
import { convertDateFormat } from '../../../../genericFunction/moment';
import { isThereExcursionMeal } from '../../../../genericFunction/dailyProgramValidation';
import { DEPARTURE, ARRIVAL } from '../../../../constant/activityTypes';
class DailyProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      activityType: '',
      activity: null,
      index: 0,
      movements: null,
      day: null,
      openModalTransportationUnits: false,
      dataTransportation: null,
      isThereActivity: false,
    };
  }
  openModalSetActivity = (
    activityType,
    activity,
    movements,
    index,
    day,
    action
  ) => {
    this.setState({
      ...this.state,
      modalOpen: true,
      activityType: activityType,
      activity: activity,
      index: index,
      movements: movements,
      day: day,
      action: action,
    });
  };
  closeModalSetActivity = () => {
    this.setState({
      ...this.state,
      modalOpen: false,
      openModalTransportationUnits: false,
    });
  };
  renderTimeLineDaily = () => {
    return this.props.dailyProgram.map((daily, i) => (
      <TimeLineDaily
        daily={daily}
        key={'daily' + i}
        day={i}
        openModalSetActivity={this.openModalSetActivity}
        deleteActivity={this.props.deleteActivity}
        openTransportationModal={this.openTransportationModal}
        deleteTransportItem={this.props.deleteTransportItem}
        transportStore={this.props.transportStore}
        currentPackage={this.props.currentPackage}
        setDailyProgram={this.props.setDailyProgram}
        dailyProgram={this.props.dailyProgram}
        isStatic={!!this.props.isStatic}
        isSelectDate={this.props.isSelectDate}
        isVariableDate={this.props.isVariableDate}
      />
    ));
  };
  openTransportationModal = (
    from,
    to,
    date,
    index,
    day,
    originName,
    destName
  ) => {
    let dest = this.getDestinations(day, index);
    let dataTransportation = {
      fromCity: from,
      toCities: dest ? dest : to,
      RatingId: '',
      seatTypeId: '',
      typeId: '',
      requestedDate: convertDateFormat(date, 'YYYY-MM-DD'),
      requestedDateTime: date,
      currentDestination: to,
    };
    this.props.getTransportationData(dataTransportation);
    let isThereActivity = this.isThereExcursionMeal(day, index);
    this.setState({
      ...this.state,
      dataTransportation: dataTransportation,
      openModalTransportationUnits: true,
      index: index,
      day: day,
      isThereActivity: isThereActivity,
      from: originName,
      to: destName,
    });
  };
  isThereExcursionMeal = (day, index) => {
    return isThereExcursionMeal(this.props.dailyProgram, day, index);
  };
  setTransportation = (transport, service, hour) => {
    let { index, day } = this.state;
    this.props.setTransportItem(day, index, transport, service, hour);
  };
  getDestinations = (day, moveIdx) => {
    let movements = this.props.dailyProgram[day].Movements;
    let dest = [...movements].reduce((res, item, i, arr) => {
      if (item.MovementName === DEPARTURE && moveIdx <= i) {
        //moveIdx >= i
        res.indexOf(item.OriginDestination) === -1 &&
          res.push(item.OriginDestination);
        arr.splice(1);
      } else if (item.MovementName === ARRIVAL && moveIdx >= i) {
        //&& moveIdx >= i
        res = [item.OriginDestination];
      } else {
        res.indexOf(item.OriginDestination) === -1 &&
          res.push(item.OriginDestination);
      }
      // res.push(item.Destination);
      return res;
    }, []);
    return dest.join();
  };
  render() {
    const {
      modalOpen,
      activityType,
      activity,
      index,
      movements,
      day,
      action,
      dataTransportation,
      openModalTransportationUnits,
      isThereActivity,
      from,
      to,
    } = this.state;
    const { setDailyProgramAfterAdd } = this.props;
    return (
      <div>
        {this.props.dailyProgram.length > 0 ? (
          <VerticalTimeline className="timeLineRootLine" animate={false}>
            {this.renderTimeLineDaily()}
            <VerticalTimelineElement iconStyle={styles.timeLineIcon} />
          </VerticalTimeline>
        ) : null}
        <ModalSetActivity
          open={modalOpen}
          closeModal={this.closeModalSetActivity}
          activityType={activityType}
          activity={activity}
          index={index}
          movements={movements}
          day={day}
          setDailyProgramAfterAdd={setDailyProgramAfterAdd}
          action={action}
        />
        <ModalListTransportationUnit
          open={openModalTransportationUnits}
          onClose={this.closeModalSetActivity}
          name="Transportation"
          from={from}
          to={to}
          date={dataTransportation ? dataTransportation.requestedDate : null}
          setTransportItem={this.setTransportation}
          isThereActivity={isThereActivity}
        />
      </div>
    );
  }
}
DailyProgram.propTypes = {
  dailyProgram: PropTypes.array,
  setDailyProgramAfterAdd: PropTypes.func,
  deleteActivity: PropTypes.func,
  getTransportationData: PropTypes.func,
  setTransportItem: PropTypes.func,
  deleteTransportItem: PropTypes.func,
  transportStore: PropTypes.array,
  currentPackage: PropTypes.string,
  setDailyProgram: PropTypes.func,
  isStatic: PropTypes.bool,
  isSelectDate: PropTypes.string,
  isVariableDate: PropTypes.bool,
};

export default DailyProgram;
