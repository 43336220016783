const GOLD_COLOR = '#e6ca6b';
const style = {
  font36: {
    fontSize: '36px',
  },
  textDownloadApps: {
    color: GOLD_COLOR,
    marginBottom: '0px',
  },
  marginLeftRight20: { marginLeft: '20px', marginRight: '20px' },
  height78: {
    height: '78px',
  },
};

export default style;
