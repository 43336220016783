import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useApiCall from './hooks/useApiCall';
import {
  Container,
  Grid,
  Form,
  Checkbox,
  Image,
  // Modal,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import AgentData from './form/AgentData';
import AgentDetail from './form/AgentDetail';
import UserData from './form/UserData';
import RegisterButton from './components/registerButton/RegisterButton';
import styles from './styles';
import { getCurrenciesApi } from '../../api/currenciesApi';
import { getCountriesApi } from '../../api/countriesApi';
import {
  getTotalBranchApi,
  getRevenueRangeApi,
  getTotalEmployeeApi,
} from '../../api/travelAgentDataApi';
import handleValidation from './services/validation/emptyFieldValidation';
// import { BasicConfirmationModal } from '../shared/components/modal/';
import BasicConfirmationModal from '../shared/components/modal/ModalAlert';
import {
  handleValidationEmail,
  handleValidationPassword,
  handleValidationConfirmPassword,
} from '../../genericFunction/validation';
import imageBase64Converter from './services/helper/imageToBase64Converter';
import passIconClick from '../shared/services/helper/passIconClickHooks';
import { fieldToValidate } from './constant/fieldToValidate';
import registerPostData from './services/data/registerPostData';
import { postRegisterApi } from '../../api/registerApi';
import errorHandling from '../../genericFunction/errorHandling';
import { touressLogoBlack } from '../../assets/logo/index';
import { useTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

const Register = () => {
  const { t } = useTranslation();
  const [registerData, setRegisterData] = useState({
    companyName: '',
    companyCountry: '',
    companyTelephone: '',
    companyAddress: '',
    companyEmail: '',
    companyTradeLicenseNumber: '',
    companyNumberOfEmployees: '',
    companyTotalBranch: '',
    companyCurrency: 'IDR',
    companyLastYearRevenue: '',
    companyIataNumber: '',
    companyAssociationNumber: '',
    userName: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userPhoneNumber: '',
    userPassword: '',
    userConfirmPassword: '',
    imageContentBase64: null,
    imageFile: null,
    fullBase64: null,
  });

  const [passIcon, setPassIcon] = useState({
    passwordIcon: 'eye slash',
    passwordInputType: 'password',
    confirmPasswordIcon: 'eye slash',
    confirmPasswordInputType: 'password',
    passwordShow: false,
    confirmPasswordShow: false,
  });

  const [validate, setValidate] = useState(false);

  const [registerButton, setRegisterButton] = useState(true);

  const [registerTerms, setRegisterTerms] = useState(false);
  const [isArrayContentAlert, setIsArrayContentAlert] = useState(false);
  const [registerModalData, setRegisterModalData] = useState({
    registerModal: false,
    registerOk: null,
    registerMessage: [],
    modalTitle: '',
  });

  const [registerLoading, setRegisterLoading] = useState(false);

  const [backHome, setBackHome] = useState(false);

  const [countryLoading, countryList] = useApiCall(getCountriesApi, 'Name');

  const [currenciesLoading, currenciesList] = useApiCall(
    getCurrenciesApi,
    'Description'
  );

  const [employeeLoading, employeeeList] = useApiCall(
    getTotalEmployeeApi,
    'Value'
  );

  const [revenueLoading, revenueList] = useApiCall(getRevenueRangeApi, 'Value');

  const [totalBranchLoading, totalBranchList] = useApiCall(
    getTotalBranchApi,
    'Value'
  );

  let dataValidation = {};

  if (validate) {
    const listFieldToValidate = fieldToValidate;
    dataValidation = handleValidation(registerData, listFieldToValidate);
    if (registerData.companyEmail) {
      dataValidation = handleValidationEmail(
        registerData.companyEmail,
        dataValidation,
        'companyEmail'
      );
    }
    if (registerData.userEmail) {
      dataValidation = handleValidationEmail(
        registerData.userEmail,
        dataValidation,
        'userEmail'
      );
    }

    if (registerData.userPassword) {
      dataValidation = handleValidationPassword(
        registerData.userPassword,
        dataValidation,
        'userPassword'
      );
    }

    if (registerData.userConfirmPassword) {
      dataValidation = handleValidationConfirmPassword(
        registerData.userConfirmPassword,
        registerData.userPassword,
        dataValidation,
        'userConfirmPassword'
      );
    }
  }

  const handleChange = (e, { name, value, checked }) => {
    if (name === 'registerTerms') {
      setRegisterTerms(checked);
      setRegisterButton(!checked);
    } else {
      setRegisterData({ ...registerData, [name]: value });
      setValidate(true);
    }
  };

  const onClickPassIcon = (e, { name }) => {
    passIconClick(name, passIcon, result => setPassIcon(result));
  };

  const onImageSelect = e => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);

    imageBase64Converter(e.target.files[0], result =>
      setRegisterData({
        ...registerData,
        imageContentBase64: result.content,
        fullBase64: result.fullBase64,
        imageFile: imageUrl,
      })
    );
  };

  const handleSubmitRegister = () => {
    const postData = registerPostData(registerData);

    setRegisterLoading(true);

    postRegisterApi(postData)
      .then(() => {
        setRegisterLoading(false);
        setIsArrayContentAlert(false);
        return setRegisterModalData({
          registerModal: true,
          registerOk: true,
          registerMessage: t('registrationIsSuccessful'),
          // 'Your registration is successful. Please check your email for confirmation.',
          modalTitle: t('agentRegistrationSuccessfull'), //'Agent Registration Successfull',
        });
      })
      .catch(err => {
        setRegisterLoading(false);
        setIsArrayContentAlert(err.response.data.ModelState ? true : false);
        return setRegisterModalData({
          registerModal: true,
          registerOk: false,
          registerMessage: err.response.data.ModelState
            ? errorHandling(err.response.data.ModelState)
            : err.response.data.Message,
          modalTitle: t('agentRegistrationFailed'), //'Agent Registration Failed',
        });
      });
  };

  const onButtonModalClick = () => {
    if (registerModalData.registerOk) {
      setRegisterModalData({ ...registerModalData, registerModal: false });
      setBackHome(true);
    } else {
      setRegisterModalData({ ...registerModalData, registerModal: false });
    }
  };
  const handleBackHome = () => setBackHome(true);
  if (backHome) {
    //eslint-disable-next-line
    return <Redirect to="/" />;
  } else {
    return (
      <div style={styles.page}>
        <DynamicTitle title="Registration" />
        <Container style={styles.container}>
          <Grid style={styles.grid}>
            <Grid.Row style={styles.paddingTop34}>
              <Grid.Column
                width="4"
                style={{ ...styles.imagePadding, ...styles.cursorPointer }}
              >
                <Image src={touressLogoBlack} onClick={handleBackHome} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <div>
                <h3 style={styles.h3}>
                  <strong>
                    {/* Become a touress member and enjoy exclusive benefit */}
                    {t('headerRegister')}
                  </strong>
                </h3>
                <h4 style={styles.h4}>
                  <strong>
                    {/* Let us know more about you and your company */}
                    {t('letUsKnowMore')}
                  </strong>
                </h4>
              </div>
            </Grid.Row>

            <Grid.Row style={styles.row}>
              <AgentData
                countryOptions={countryList}
                loading={countryLoading}
                handleChange={handleChange}
                companyName={registerData.companyName}
                companyCountry={registerData.companyCountry}
                companyTelephone={registerData.companyTelephone}
                companyAddress={registerData.companyAddress}
                companyEmail={registerData.companyEmail}
                validate={dataValidation}
              />
            </Grid.Row>
            <Grid.Row style={styles.row}>
              <AgentDetail
                companyTradeLicenseNumber={
                  registerData.companyTradeLicenseNumber
                }
                companyNumberOfEmployees={registerData.companyNumberOfEmployees}
                companyTotalBranch={registerData.companyTotalBranch}
                companyCurrency={registerData.companyCurrency}
                companyLastYearRevenue={registerData.companyLastYearRevenue}
                companyIataNumber={registerData.companyIataNumber}
                companyAssociationNumber={registerData.companyAssociationNumber}
                currencyOptions={currenciesList}
                currencyLoading={currenciesLoading}
                numberOfEmployeesOptions={employeeeList}
                numberOfEmployeesLoading={employeeLoading}
                totalBranchOptions={totalBranchList}
                totalBranchLoading={totalBranchLoading}
                lastYearRevenueOptions={revenueList}
                lastYearRevenueLoading={revenueLoading}
                handleChange={handleChange}
                onImageSelect={onImageSelect}
                imgSrc={registerData.imageFile}
                validate={dataValidation}
              />
            </Grid.Row>
            <Grid.Row style={styles.row}>
              <UserData
                userName={registerData.userName}
                userFirstName={registerData.userFirstName}
                userLastName={registerData.userLastName}
                userEmail={registerData.userEmail}
                userPhoneNumber={registerData.userPhoneNumber}
                passwordIcon={passIcon.passwordIcon}
                onClickPassIcon={onClickPassIcon}
                confirmPasswordIcon={passIcon.confirmPasswordIcon}
                onClickConfPassIcon={onClickPassIcon}
                passwordInputType={passIcon.passwordInputType}
                confirmPasswordInputType={passIcon.confirmPasswordInputType}
                handleChange={handleChange}
                validate={dataValidation}
              />
            </Grid.Row>
            <Form>
              <Form.Field required>
                <Checkbox
                  checked={registerTerms}
                  name="registerTerms"
                  onChange={handleChange}
                  label={
                    <label>
                      {/* By continuing, you agree to the */}
                      {`${t('byContinuingYouAgreeToThe')} `}
                      <a href="/terms-of-use" target="_blank">
                        {/* Terms of Use */}
                        <u>{t('termsOfUse')}</u>
                      </a>
                      {/* and our */}
                      {` ${t('andOur')} `}
                      <a href="/privacy-policy" target="_blank">
                        {/* Privacy policy */}
                        <u> {t('privacyPolicy')} </u>
                      </a>
                    </label>
                  }
                />
              </Form.Field>
            </Form>
            <Grid.Row>
              <RegisterButton
                onClick={handleSubmitRegister}
                style={styles.buttonRegister}
                disabled={registerButton}
                loading={registerLoading}
                labelStyles={styles.buttonText}
              />
            </Grid.Row>
          </Grid>
          <BasicConfirmationModal
            isArrayContentAlert={isArrayContentAlert}
            openModal={registerModalData.registerModal}
            close={onButtonModalClick}
            message={registerModalData.registerMessage}
            size="small"
          />
        </Container>
      </div>
    );
  }
};

Register.propTypes = {
  t: PropTypes.func,
};

export default Register;
