import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import PropTypes from 'prop-types';
// import { Image } from 'semantic-ui-react';
import ImageProgressive from '../progressiveImage/ImageProgressive';

class ImageLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const images = this.props.images;
    return (
      <div>
        {/* <Image
          style={this.props.imageStyle}
          centered
          src={images[0].src}
          onClick={() =>
            this.setState({
              isOpen: true,
            })
          }
        /> */}
        <div
          onClick={() =>
            this.setState({
              isOpen: true,
            })
          }
        >
          <ImageProgressive
            styles={this.props.imageStyle}
            centered
            preview={images[0].src}
            src={images[0].src}
          />
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].src
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
ImageLightbox.propTypes = {
  images: PropTypes.array,
  imageStyle: PropTypes.object,
};
export default ImageLightbox;
