const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';

const style = {
  backgroundModal: {
    background: '#fbfbfb',
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  boldGoldColor: {
    color: GOLD_COLOR,
    fontWeight: 'bold',
  },
  textTransparent: {
    color: WHITE_COLOR,
    fontWeight: 'bold',
  },
  justify: {
    textAlign: 'justify',
  },
  labelGold: {
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
    color: 'white',
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  buttonGold: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
    // width: '100%',
  },
  inputWithPaddingNine: {
    width: '100%',
    padding: '9px',
  },
  inputStyles: {
    width: '100%',
    // padding: '9px',
    // borderBottom: '2px solid lightgrey',
  },
  label: {
    border: '1px solid #6b82e6',
    color: '#6b82e6',
    borderRadius: '50px',
  },
  labelAvailable: {
    border: '1px solid #00be5a',
    color: '#fff',
    borderRadius: '50px',
    background: '#00be5a',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  labelOnRequest: {
    border: '1px solid #fca000',
    color: '#fff',
    borderRadius: '50px',
    background: '#fca000',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segmentSearch: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '1px 5px',
  },
  borderNone: {
    border: 'none',
  },
  marginTopBottom10px: {
    margin: '10px 0px',
  },
  padding3px10px: {
    padding: '3px 10px',
  },
  paddingTop3px: {
    paddingTop: '3px',
  },
  marginBottom15px: {
    marginBottom: '15px',
  },
  width100percent: {
    width: '100%',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  minHeight50px: {
    minHeight: '50px',
  },
  marginLeftMinus5px: {
    marginLeft: '-5px',
  },
  // labelViewMap: {
  //   backgroundColor: '#e6ca6b',
  //   position: 'absolute',
  //   bottom: '8px',
  //   right: '4px',
  //   color: '#252525',
  //   cursor: 'pointer',
  // },
  labelViewMap: {
    backgroundColor: 'Transparent',
    position: 'absolute',
    bottom: '20px',
    right: '35%',
    color: '#252525',
    cursor: 'pointer',
    fontSize: '14px',
  },
  paddingTop10pxMarginTop10px: {
    margin: '10px 0px 0px 0px',
    padding: '10px 0px 0px 0px',
  },
  noMarginPaddingTop5pxBottom10px: {
    margin: '0px',
    padding: '5px 0px 10px 0px',
  },
  marginRight5: {
    marginRight: '5px',
  },
  paddingTop3left5: { paddingTop: '3px', paddingLeft: '5px' },
  margin03: { margin: '0px 3px' },
  noMargin: { margin: '0px' },
};
export default style;
