import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Segment, Grid, Header, Message, Icon } from 'semantic-ui-react';
//------component------
import RoomService from './RoomService';
//------style--------
import Styles from './styles';

import { useTranslation } from 'react-i18next';

const DescriptionRoomCard = props => {
  const { t } = useTranslation();

  const accommodations = useSelector(
    state => state.accommodation.accommodations
  );
  const handleChange = async (e, { service }) => {
    let { setAccommodationRoom, room, closeModalAccommodation } = props;
    await setAccommodationRoom(accommodations, room, service);
    closeModalAccommodation();
  };

  let { desc, service } = props;
  let bedOnly = service.find(type => type === 'Bed');
  let withBreakfast = service.find(type => type === 'With_breakfast');
  let halfBoard = service.find(type => type === 'Half_board');
  let fullBoard = service.find(type => type === 'Full_board');
  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width="16">
            {/* Room Description */}
            <Header as="h4">{t('roomDescription')}</Header>
            <p style={Styles.noMarginBottom}>{desc}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16" style={Styles.marginBottomfifteen}>
            {/* Room Service  */}
            <Header as="h4">{t('roomService')} </Header>
          </Grid.Column>
          <Grid.Column width="16">
            <Message warning style={Styles.borderMessage}>
              <p>
                <Icon
                  circular
                  name="info"
                  style={Styles.backgroundGoldColorWhite}
                />
                {/* Choose room service what do you want. */}
                {t('chooseRoomService')}
              </p>
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <RoomService
            name="Room Only"
            disable={!bedOnly}
            service={bedOnly}
            handleChange={handleChange}
            text={t('roomOnly')}
          />
          <RoomService
            name="With Breakfast"
            disable={!withBreakfast}
            service={withBreakfast}
            handleChange={handleChange}
            text={t('withBreakfast')}
          />
          <RoomService
            name="Half Board"
            disable={!halfBoard}
            service={halfBoard}
            handleChange={handleChange}
            text={t('halfBoard')}
          />
          <RoomService
            name="Full Board"
            disable={!fullBoard}
            service={fullBoard}
            handleChange={handleChange}
            text={t('fullBoard')}
          />
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

DescriptionRoomCard.propTypes = {
  desc: PropTypes.string,
  amenities: PropTypes.string,
  service: PropTypes.array,
  room: PropTypes.object,
  HeadlineProgram: PropTypes.object,
  accommodations: PropTypes.array,
  onClose: PropTypes.func,
  closeModalAccommodation: PropTypes.func,
  setAccommodationRoom: PropTypes.func,
};

export default DescriptionRoomCard;
