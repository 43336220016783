import React from 'react';
import Slider from 'react-slick';
import ImageSlide from './ImageSlide';
import topDestinationData from '../../constant/topDestinationData';
import ArrowNavigation from '../../../shared/components/arrowNavigation/ArrowNavigation';
class MultipleCarousel extends React.Component {
  render() {
    const settings = {
      dots: false,
      lazyLoad: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2,
      nextArrow: <ArrowNavigation additionalClassname="next" />,
      prevArrow: <ArrowNavigation additionalClassname="prev" />,
    };
    return (
      <div>
        <Slider {...settings}>
          {topDestinationData
            ? topDestinationData.map((item, i) => (
                <div key={i}>
                  <ImageSlide name={item.name} url={item.imageUrl} />
                </div>
              ))
            : null}
        </Slider>
      </div>
    );
  }
}

export default MultipleCarousel;
