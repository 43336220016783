const setAdditionalItem = (supplements, id) => {
  let newSupplement = [];
  supplements.map(item => {
    return newSupplement.push({
      bookingTemplateId: id,
      bookingTemplateSupplementId: item.BookingTemplateSupplementId
        ? item.BookingTemplateSupplementId
        : item.Id,
      qty: item.Qty || 0,
      isMandatory: item.IsMandatory,
      isInfantCount: item.IsInfantCount,
      name: item.Name,
      currencyId: item.CurrencyId,
      unitCost: item.UnitCost,
      minimumPayable: item.MinimumPayable || 0,
      paid: item.Paid || 0,
      payable: item.Payable || 0,
      totalCost: item.TotalCost || 0,
      paymentValue: 0,
    });
  });
  return sortAdditionalItem(newSupplement, 'isMandatory');
};
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const changeQty = (supplements, index, value) => {
  let copySupplements = copyObject(supplements);
  return copySupplements.map(supplement => {
    if (supplement.Id !== index) return supplement;
    else return { ...supplement, qty: value };
  });
};

const setAdditionalItemAmount = (supplements, guests) => {
  const copySupplements = copyObject(supplements);
  const { adults, childs, infants } = guests;
  return copySupplements.map(supplement => {
    if (!supplement.isMandatory) return supplement;
    else {
      if (!supplement.isInfantCount)
        return { ...supplement, qty: adults + childs };
      else return { ...supplement, qty: adults + childs + infants };
    }
  });
};

const getPaymentValue = (supplement, paymentTerms, nowPaymentTerm) => {
  let totalTerms = paymentTerms.reduce((prev, next) => {
    return next.IsPayed ? prev + next.PaymentPercentage : prev;
  }, 0);
  let paymentValue = 0;
  if (supplement.qty !== 0) {
    paymentValue =
      (supplement.qty * supplement.unitCost * totalTerms) / 100 -
      supplement.paid;
    paymentValue =
      paymentValue +
      (supplement.unitCost * supplement.qty * nowPaymentTerm) / 100;
    if (paymentValue < 0) {
      paymentValue = 0;
    }
  } else {
    paymentValue = 0;
  }
  return paymentValue;
};

const setAdditionalPrice = (supplements, paymentTerms, nowPaymentTerm) => {
  const copySupplements = copyObject(supplements);
  copySupplements.map(
    // eslint-disable-next-line
    supplement => {
      let payable = supplement.qty * supplement.unitCost - supplement.paid;
      supplement.paymentValue = getPaymentValue(
        supplement,
        paymentTerms,
        nowPaymentTerm
      );
      supplement.totalCost = supplement.qty * supplement.unitCost;
      supplement.payable = payable > 0 ? payable : 0;
    }
  );
  return copySupplements;
};

// const setAdditionalPaymentValue = (supplement, nowPartialPayment) => {
//   const copySupplements = copyObject(supplements);
//   return copySupplements.map(supplement => {
//     return {
//       ...supplement,
//       totalCost: supplement.unitCost * supplement.qty,
//       paymentValue: supplement.totalCost * nowPartialPayment
//     };
//   });

// }

const sortAdditionalItem = (supplements, sortBy) => {
  return supplements.sort((a, b) => b[sortBy] - a[sortBy]);
};

export {
  setAdditionalItem,
  setAdditionalItemAmount,
  setAdditionalPrice,
  changeQty,
  sortAdditionalItem,
};
