import React from 'react';
import { Grid, Header, Button } from 'semantic-ui-react';
import styles from '../../styles';
import { ucwords } from '../../../../genericFunction/convertWord';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const GuestList = props => {
  const { t } = useTranslation();
  let { countries, list } = props;
  let leader = list.filter(guest => guest.GuestType === 'TOURLEADER');
  let member = list.filter(guest => guest.GuestType === 'TOURMEMBER');
  let totalGuest = list.length;
  return (
    <Grid.Row>
      <Grid.Column width={8} style={styles.marginTop10Bottom5px}>
        <Header as="h2">
          {/* Guest Detail */} {t('guestDetail')}
        </Header>
      </Grid.Column>
      <Grid.Column
        width={8}
        textAlign="right"
        style={styles.paddingTopBottom10pxLeftRight35px}
      >
        <span>
          {/* Total Guest: */}
          {t('totalGuest')}: {totalGuest}
        </span>{' '}
        {totalGuest > 7 && (
          <Button
            style={styles.buttonGold}
            onClick={props.handleButton}
            name="openModalGuest"
          >
            {/* Guest Detail */} {t('guestDetail')}
          </Button>
        )}
      </Grid.Column>
      {leader.length !== 0 && (
        <Grid.Column
          width={16}
          style={styles.paddingTopBottom10pxLeftRight35px}
        >
          <Header as="h5">
            {/* Guest Leader */} {t('guestLeader')}
          </Header>
        </Grid.Column>
      )}
      <Grid.Column width={16}>
        <Grid>
          {leader.map((guest, index) => {
            let country = guest.CountryId
              ? countries.filter(e => e.Id === guest.CountryId)[0]
              : '';
            return (
              <Grid.Row
                key={index}
                style={styles.paddingTopBottom5pxLeftRight35px}
              >
                <Grid.Column width={7}>
                  {ucwords(guest.GuestTitle)}. {ucwords(guest.FirstName)}{' '}
                  {ucwords(guest.LastName)} ({ucwords(guest.GuestCategory)})
                </Grid.Column>
                <Grid.Column width={4}>
                  {ucwords(country ? country.Name : '')}
                </Grid.Column>
                <Grid.Column width={4}>
                  {ucwords(guest.IdentityType)} : {guest.IdentityNbr}
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </Grid.Column>
      {member.length !== 0 && (
        <Grid.Column
          width={16}
          style={styles.paddingTopBottom10pxLeftRight35px}
        >
          <Header as="h5">
            {/* Guest Member */} {t('guestMember')}
          </Header>
        </Grid.Column>
      )}
      <Grid.Column width={16}>
        <Grid>
          {member.slice(0, 5).map((guest, index) => {
            let country = guest.CountryId
              ? countries.filter(e => e.Id === guest.CountryId)[0]
              : '';
            return guest.FirstName ? (
              <Grid.Row
                key={index}
                style={styles.paddingTopBottom5pxLeftRight35px}
              >
                <Grid.Column width={7}>
                  {ucwords(guest.GuestTitle)}, {ucwords(guest.FirstName)}{' '}
                  {ucwords(guest.LastName)} ({ucwords(guest.GuestCategory)})
                </Grid.Column>
                <Grid.Column width={4}>
                  {ucwords(country ? country.Name : '')}
                </Grid.Column>
                <Grid.Column width={4}>
                  {ucwords(guest.IdentityType)} : {guest.IdentityNbr}
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row
                key={index}
                style={styles.paddingTopBottom5pxLeftRight35px}
              >
                <Grid.Column width={7}>-</Grid.Column>
                <Grid.Column width={4}> - </Grid.Column>
                <Grid.Column width={4}> - </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
};

GuestList.propTypes = {
  countries: PropTypes.array,
  list: PropTypes.array,
  handleButton: PropTypes.func,
  t: PropTypes.func,
};
export default GuestList;
