import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid } from 'semantic-ui-react';
import Filter from '../../../formFilter/FormFilter';
import ListCard from './ListCard';
import styles from './styles';
import { ButtonCloseModal } from '../../../common';
import {
  getRestaurantByFilter,
  resetStatusRestaurant,
  // resetStatusRestaurantsByFilter,
} from '../../../../actions/restaurantAction';
const ModalListRestaurant = props => {
  const [formFilter, setFormFilter] = useState([]);
  // const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [activePage, setActivePage] = useState(1);
  //===========store========
  const listRestaurantSpecialization = useSelector(
    state => state.restaurant.restaurantSpecializations
  );
  const restaurantSpecializationsStatus = useSelector(
    state => state.restaurant.getRestaurantSpecializationsStatus
  );
  const listRestaurantMenuClass = useSelector(state =>
    state.restaurant.restaurantMenuClasses.map(e => {
      return { key: e, text: e + ' Dinning', checked: false };
    })
  );
  const restaurantMenuClassesStatus = useSelector(
    state => state.restaurant.getRestaurantMenuClassesStatus
  );
  const listRestaurant = useSelector(state => state.restaurant.restaurants);
  // const restaurantByFilterStatus = useSelector(
  //   state => state.restaurant.getRestaurantByFilterStatus
  // );
  const loading = useSelector(state => state.restaurant.loading);
  const loadingRestaurantByFilter = useSelector(
    state => state.restaurant.loadingRestaurantByFilter
  );
  //========action========
  const dispatch = useDispatch();
  const resetStatusRestaurantAction = () => dispatch(resetStatusRestaurant());
  // const resetStatusRestaurantsByFilterAction = () =>
  //   dispatch(resetStatusRestaurantsByFilter());
  const getRestaurantByFilterAction = data =>
    dispatch(getRestaurantByFilter(data));

  let { open, onClose, name, location, setRestaurant } = props;

  useEffect(() => {
    if (
      restaurantMenuClassesStatus === 'success' &&
      restaurantSpecializationsStatus === 'success'
    ) {
      let data = [
        {
          name: 'Restaurant Classes',
          data: listRestaurantMenuClass,
        },
        {
          name: 'Restaurant Speciality',
          data: showOptions(listRestaurantSpecialization),
        },
      ];
      setFormFilter(data);
      resetStatusRestaurantAction();
    }
    // eslint-disable-next-line
  }, [restaurantMenuClassesStatus, restaurantSpecializationsStatus]);

  const showOptions = data => {
    return data.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  const filteredData = formFilter => {
    let { date, pax, cityId } = props;
    let specializationId, menuClassId, data;
    formFilter.map(filter =>
      filter.name === 'Restaurant Classes'
        ? (menuClassId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
        : (specializationId = filter.data
            .filter(e => e.checked)
            .map(e => e.key)
            .join(','))
    );
    data = {
      cityId,
      ratingIds: '',
      locationIds: '',
      specializationId,
      menuClassId,
      facilityIds: '',
      requestedDate: date,
      pax,
    };
    getRestaurantByFilterAction(data);
  };
  const handleChangeCheckbox = (e, { name, value }) => {
    let dataList = [...formFilter];
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    setFormFilter(dataList);
    filteredData(dataList);
  };
  const handleResetFilter = () => {
    let dataList = [...formFilter];
    dataList = dataList.map(filter =>
      filter.data.some(e => e.checked)
        ? { name: filter.name, data: resetFilter(filter.data) }
        : filter
    );
    setFormFilter(dataList);
    filteredData(dataList);
  };
  const resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  const filterByName = (listRestaurant, searchName) => {
    return listRestaurant.filter(
      data => data.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  const handleChange = (e, { value }) => {
    setSearchName(value);
    setActivePage(1);
  };

  let listRestaurantFiltered = filterByName(listRestaurant, searchName);
  return (
    <Modal open={open} onClose={onClose} size="large" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {name} <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description>
          <Grid>
            <Grid.Column width={5}>
              <Filter
                loading={loading}
                formFilter={formFilter}
                handleChange={handleChangeCheckbox}
                resetFilter={handleResetFilter}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <ListCard
                location={location}
                data={listRestaurantFiltered}
                loading={loadingRestaurantByFilter}
                searchName={searchName}
                activePage={activePage}
                handleChange={handleChange}
                pageChange={pageChange}
                setRestaurant={setRestaurant}
              />
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

ModalListRestaurant.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  pax: PropTypes.number,
  resetStatusRestaurant: PropTypes.func,
  listRestaurantSpecialization: PropTypes.array,
  restaurantSpecializationsStatus: PropTypes.string,
  listRestaurantMenuClass: PropTypes.array,
  restaurantMenuClassesStatus: PropTypes.string,
  resetStatusRestaurantsByFilter: PropTypes.func,
  restaurantByFilterStatus: PropTypes.string,
  listRestaurant: PropTypes.array,
  getRestaurantByFilter: PropTypes.func,
  loading: PropTypes.bool,
  loadingRestaurantByFilter: PropTypes.bool,
  setRestaurant: PropTypes.func,
  cityId: PropTypes.string,
};

export default ModalListRestaurant;
