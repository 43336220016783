import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Header, Button } from 'semantic-ui-react';
import styles from '../styles';
import { CardImage } from './../../common';
import { rangeDate } from '../../../../../genericFunction/moment';
import { withTranslation } from 'react-i18next';
class AccommodationCard extends Component {
  static propTypes = {
    accommodation: PropTypes.object,
    data: PropTypes.object,
    handleButtonInfo: PropTypes.func,
    isVariableDate: PropTypes.bool,
    t: PropTypes.func,
  };
  render() {
    const { t } = this.props;
    let { accommodation, data, handleButtonInfo, isVariableDate } = this.props;
    let startDate = data ? data.Date : '';
    let endDate = data ? data.LeavingDate : '';
    let date = startDate && endDate ? rangeDate(startDate, endDate) : '-';
    let image = accommodation
      ? accommodation.ProfileImages.length !== 0
        ? accommodation.ProfileImages.find(item => item.IsPrimaryImage === true)
          ? accommodation.ProfileImages.find(
              item => item.IsPrimaryImage === true
            ).ImageUrl
          : accommodation.ImageUrl
        : accommodation.ImageUrl
        ? accommodation.ImageUrl
        : ''
      : '';

    return (
      <Grid.Row style={styles.padding0pxMarginRight}>
        <Segment.Group horizontal style={styles.segmentNoPadding}>
          <CardImage image={image} />
          <Segment style={styles.borderNone}>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  width={2}
                  style={styles.paddingTop2pxLeftRight15px}
                >
                  <Button
                    circular
                    icon="info circle"
                    style={styles.buttonIconInfo}
                    size="big"
                    onClick={handleButtonInfo}
                    name="openDetail"
                    value={accommodation}
                  />
                </Grid.Column>
                <Grid.Column width={13} style={styles.padding2px}>
                  <Header
                    as="h3"
                    style={styles.goldColor}
                    className="margin-top-ten margin-bottom-ten no-padding"
                  >
                    {accommodation ? accommodation.Name : '-'}
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {accommodation ? accommodation.AccommodationType.Name : '-'}
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {data && data.TotalDays} {t('day')}{' '}
                    {!isVariableDate && `, ${date}`}
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <span>
                    {/* Room */}
                    <strong>{t('room')} :</strong>{' '}
                    {data ? data.AccommodationSummary.RoomName : '-'}
                  </span>
                </Grid.Column>
                <Grid.Column width={16} style={styles.marginTopBottom5px}>
                  <span>
                    {/* Service Type */}
                    <strong>{t('serviceType')} :</strong>{' '}
                    {data
                      ? data.AccommodationSummary.RoomService.split('_').join(
                          ' '
                        )
                      : ''}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
      </Grid.Row>
    );
  }
}
export default withTranslation()(AccommodationCard);
