import React, { Component } from 'react';
import { Grid, Segment, Loader } from 'semantic-ui-react';
import ButtonBackAndNext from '../../../components/button/BtnBackAndNext';
import { SegmentPackage } from '../../shared/components/summary';
import FormConfirm from './PartialPaymentForm';
import PropTypes from 'prop-types';
import { rangeDate } from '../../../genericFunction/moment';
import { convertTourPrice } from '../../../genericFunction/convertPackage';
import styles from '../styles';
import { withTranslation } from 'react-i18next';

class PartialPaymentComponent extends Component {
  render() {
    const { t } = this.props;
    let {
      tourTransactionSummaryById,
      tourOperatorProfile,
      loadingTourOperator,
      guests,
      tourPriceSum,
      handleIncrease,
      handleDecrease,
      handleOnChange,
      handleButton,
      isBasedPaymentTerms,
      supplements,
      commissions,
      packageType,
      totalPrice,
      isSplitCommission,
      totalPayable,
      disableField,
      additionalServices,
    } = this.props;
    let {
      BookingDetailSum,
      HeadlineProgram,
      TourPriceSum,
      TourNote,
    } = tourTransactionSummaryById;
    let imageTourOperator = tourOperatorProfile
      ? tourOperatorProfile.ImageUrl
      : '';
    let nameTourOperator = tourOperatorProfile ? tourOperatorProfile.Name : '';
    let tourTitle = BookingDetailSum ? BookingDetailSum.Title : '';
    let tourDate = BookingDetailSum
      ? rangeDate(BookingDetailSum.StartDate, BookingDetailSum.EndDate)
      : '-';
    let destinationCity = HeadlineProgram
      ? HeadlineProgram.MainPrograms.reduce((cities, destination) => {
          destination.City && cities.push(destination.City.Name);
          return cities;
        }, [])
      : [];
    let paymentTerms = BookingDetailSum ? BookingDetailSum.PaymentTerms : [];
    let tourPriceSummary = tourPriceSum.SharingRoomSum
      ? convertTourPrice(tourPriceSum)
      : [];
    let currency = BookingDetailSum ? BookingDetailSum.CurrencyId : '';
    let totalGuest = guests
      ? guests.adults + guests.childs + guests.infants
      : 0;
    let FoC = BookingDetailSum ? BookingDetailSum.FoC : null;
    let tourNote = TourNote ? TourNote : '-';
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <FormConfirm
              guests={guests}
              confirmAdult={BookingDetailSum ? BookingDetailSum.AdultQty : 0}
              confirmChild={BookingDetailSum ? BookingDetailSum.ChildQty : 0}
              confirmInfant={BookingDetailSum ? BookingDetailSum.InfantQty : 0}
              tourPriceSum={tourPriceSum}
              confirmTourPriceSum={TourPriceSum}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              isBasedPaymentTerms={isBasedPaymentTerms}
              supplements={supplements}
              packageType={packageType}
            />
          </Grid.Column>
          <Grid.Column width={7}>
            {loadingTourOperator && (
              <Segment style={styles.segmentNoPadding}>
                <Grid>
                  <Grid.Row style={styles.paddingBottom5px}>
                    <Grid.Column width={16} style={styles.height50px}>
                      <Loader active={loadingTourOperator} inline="centered" />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            )}
            <SegmentPackage
              packageType={packageType}
              imageTourOperator={imageTourOperator}
              nameTourOperator={nameTourOperator}
              tourTitle={tourTitle}
              tourDate={tourDate}
              totalGuest={totalGuest}
              destinationCity={destinationCity}
              paymentTerms={paymentTerms}
              currency={currency}
              tourPriceSum={tourPriceSummary}
              additional={supplements || []}
              totalTourPrice={totalPrice}
              splitCommission={isSplitCommission}
              commissions={commissions}
              totalPayable={totalPayable}
              FoC={FoC}
              tourNote={tourNote}
              disableField={disableField}
              message=""
              additionalServices={additionalServices}
            />
          </Grid.Column>
        </Grid.Row>
        <ButtonBackAndNext
          nameBack="activePage"
          nameNext="activePage"
          valueBack="my-booking"
          valueNext="confirm-guest"
          titleButtonBack={t('backToMyBooking')} //"Back to My Booking"
          titleButtonNext={t('next')} //"Next"
          handleButton={handleButton}
        />
      </Grid>
    );
  }
}
PartialPaymentComponent.propTypes = {
  id: PropTypes.string,
  tourTransactionSummaryById: PropTypes.object,
  getTourOperatorProfile: PropTypes.func,
  tourOperatorProfile: PropTypes.object,
  loadingTourOperator: PropTypes.bool,
  guests: PropTypes.string,
  tourPriceSum: PropTypes.object,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleButton: PropTypes.func,
  isBasedPaymentTerms: PropTypes.number,
  supplements: PropTypes.array,
  commissions: PropTypes.array,
  packageType: PropTypes.string,
  totalPrice: PropTypes.number,
  isSplitCommission: PropTypes.bool,
  totalPayable: PropTypes.number,
  disableField: PropTypes.bool,
  // handleChangePaymentSupplement: PropTypes.func,
  t: PropTypes.func,
  additionalServices: PropTypes.array,
};
export default withTranslation()(PartialPaymentComponent);
