import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import PropTypes from 'prop-types';
const ImageLightBox = props => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const { images, handleClose } = props;
  return (
    <Lightbox
      mainSrc={images[photoIndex].src}
      nextSrc={images[(photoIndex + 1) % images.length].src}
      prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
      onCloseRequest={handleClose}
      onMovePrevRequest={() =>
        setPhotoIndex((photoIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
    />
  );
};
ImageLightBox.propTypes = {
  images: PropTypes.array,
  handleClose: PropTypes.func,
};
export default ImageLightBox;
