import React from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Grid,
  Header,
  Message,
  Icon,
  Button,
} from 'semantic-ui-react';
import { convertDateFormat } from '../../../../../../genericFunction/moment';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const CardDescription = props => {
  const { t } = useTranslation();
  let {
    category,
    type,
    startTime,
    endTime,
    id,
    description,
    showButton,
    handleChoose,
  } = props;
  return (
    <Segment style={styles.borderNonePaddingLeftRight}>
      <Grid>
        <Grid.Row style={styles.noPaddingBottom}>
          <Grid.Column width="16">
            <Header as="h4" style={styles.marginBottom3px}>
              {/* Description */}
              {t('description')}
            </Header>
            <p align="justify" style={styles.noMarginBottom}>
              {description}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.noPaddingTopBottom} />
        <Grid.Row style={styles.noPaddingTopBottom}>
          <Grid.Column width="16" style={styles.marginTop10px}>
            <Header as="h4" style={styles.marginBottom3px}>
              {/* Category */}
              {t('description')}
            </Header>
            <p>{category}</p>
          </Grid.Column>
          {category === 'Package' ? (
            <Grid.Column width="16" style={styles.marginTop10px}>
              <Message warning style={styles.border03pxSolid}>
                <p>
                  <Icon circular name="info" style={styles.bgGoldcolorWhite} />
                  {/* The duration of excursion not flexible. */}
                  {t('durationExcursionNotFlexible')}
                </p>
              </Message>
            </Grid.Column>
          ) : null}
          <Grid.Column width="16" style={styles.marginTop10px}>
            <Header as="h4" style={styles.marginBottom3px}>
              {/* Type */} {t('type')}
            </Header>
            <p>{type}</p>
          </Grid.Column>
          <Grid.Column width="16" style={styles.marginTop10px}>
            <Header as="h4" style={styles.marginBottom3px}>
              {/* Working Hour */} {t('workingHour')}
            </Header>
            <p>
              {convertDateFormat(startTime, 'HH:mm')} -
              {convertDateFormat(endTime, 'HH:mm')}
            </p>
          </Grid.Column>
          <Grid.Column
            width="4"
            floated="right"
            style={styles.marginTop10pxMarginBottom10px}
          >
            {showButton && (
              <Button
                size="large"
                style={styles.buttonGoldColorBlack}
                value={id}
                onClick={handleChoose}
              >
                {/* CHOOSE */}
                {t('Choose')}
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
CardDescription.propTypes = {
  category: PropTypes.string,
  type: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  id: PropTypes.number,
  description: PropTypes.string,
  showButton: PropTypes.bool,
  handleChoose: PropTypes.func,
  t: PropTypes.func,
};

export default CardDescription;
