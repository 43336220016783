import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import styles from './styles';

const BtnCollapse = props => (
  <Button
    basic
    icon
    name={props.name}
    onClick={props.handleCollapse}
    style={props.style}
    value={props.value}
  >
    <Icon name={props.isCollapse ? 'angle up' : 'angle down'} />
  </Button>
);

BtnCollapse.propTypes = {
  isCollapse: PropTypes.bool,
  handleCollapse: PropTypes.func,
  style: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
};
export default BtnCollapse;
