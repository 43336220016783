import {
  POST_ACCEPT_QUOTATION,
  RESET_STATUS_QUOTATION,
  POST_EDIT_QUOTATION,
} from './actionTypes';
import {
  postAcceptQuotationApi,
  postEditQuotationApi,
} from '../../../api/quotationApi';

const postAcceptQuotation = id => {
  return dispatch => {
    return dispatch({
      type: POST_ACCEPT_QUOTATION,
      payload: postAcceptQuotationApi(id),
    });
  };
};
const postEditQuotation = (id, data) => {
  return dispatch => {
    return dispatch({
      type: POST_EDIT_QUOTATION,
      payload: postEditQuotationApi(id, data),
    });
  };
};
const resetStatusQuotation = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_QUOTATION,
    });
  };
};
export { postAcceptQuotation, postEditQuotation, resetStatusQuotation };
