import React, { Component } from 'react';
import { Icon, Label, Grid } from 'semantic-ui-react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
// import styles from './markers/styles';
import googleAPIKey from '../../../../config/envApiKey';
import styles from './styles';
const AnyReactComponent = props => {
  const { text, type } = props;
  return (
    <Grid className="max-content">
      {type ? (
        <Grid.Row style={styles.noPadding}>
          <Grid.Column width={16} style={styles.textCenter}>
            <Label
              pointing="below"
              className="labelStyleMap"
              icon={<Icon className="fas fa-hotel" />}
              content={<span style={styles.marginBot4}>{text}</span>}
            />
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row style={styles.noPadding}>
          <Grid.Column width={16} style={styles.textCenter}>
            <Icon name={'map marker alternate'} style={styles.markerStyle} />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

AnyReactComponent.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
};
class Maps extends Component {
  // static defaultProps = {
  //   center: {
  //     lat: 1.28,
  //     lng: 103.85,
  //   },
  //   zoom: 15,
  // };
  state = {
    center: {
      lat: 1.28,
      lng: 103.85,
    },
    zoom: 15,
  };
  shouldComponentUpdate = nextProps => {
    if (nextProps.lat !== this.props.lat || nextProps.lng !== this.props.lng) {
      // this.setState({
      //   ...this.state,
      //   center: { lat: nextProps.lat, lng: nextProps.lng },
      // });
      return true;
    } else return true;
  };
  render() {
    const { mapStyle, lat, lng, text, type } = this.props;
    const { zoom } = this.state;
    const center = { lat: lat, lng: lng };
    return (
      <div style={mapStyle}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleAPIKey }}
          // defaultCenter={center}
          defaultZoom={zoom}
          center={center}
          options={{
            styles: [
              {
                featureType: 'poi.business',
                stylers: [
                  {
                    visibility: 'on',
                  },
                ],
              },
              {
                featureType: 'poi.park',
                elementType: 'labels.text',
                stylers: [
                  {
                    visibility: 'on',
                  },
                ],
              },
            ],
          }}
        >
          <AnyReactComponent lat={lat} lng={lng} text={text} type={type} />
        </GoogleMapReact>
      </div>
    );
  }
}

Maps.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  mapStyle: PropTypes.object,
  lat: PropTypes.number,
  lng: PropTypes.number,
  text: PropTypes.string,
  type: PropTypes.string,
};
export default Maps;
