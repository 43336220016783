const setCompanyState = companyProfile => {
  let country = [
    {
      key: companyProfile.Country.Id,
      text: companyProfile.Country.Name,
      value: companyProfile.Country.Id,
    },
  ];

  let ImageName = companyProfile
    ? companyProfile.CompanyLogo
      ? companyProfile.CompanyLogo.Name
      : ''
    : '';
  let ImageUrl = companyProfile
    ? companyProfile.CompanyLogo
      ? companyProfile.CompanyLogo.ImageUrl
      : ''
    : '';

  let stateCompany = {
    Code: companyProfile.Code,
    CompanyName: companyProfile.Name,
    CountryID: companyProfile.Country.Id,
    Address: companyProfile.Address,
    Email: companyProfile.Email,
    Telephone: companyProfile.Telephone,
    FaxNo: companyProfile.FaxNbr,
    Country: country,
    ImageName,
    ImageUrl,
  };
  return stateCompany;
};

const setUpdateCompanyData = (company, imageUrl, imageName) => {
  let data = {
    Name: company.CompanyName,
    Address: company.Address,
    Telephone: company.Telephone,
    Email: company.Email,
    FaxNbr: company.FaxNo,
    CountryId: company.CountryID,
  };
  if (imageUrl) {
    data = {
      ...data,
      CompanyLogo: {
        ImageContent: imageUrl,
        ImageName: imageName,
      },
    };
  }
  return data;
};
export { setCompanyState, setUpdateCompanyData };
