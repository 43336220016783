import React from 'react';
import { Menu, Header, Icon } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';
const MenuItemWithIcon = props => {
  const { menuRef, handleClick, menuStyle, iconName, text } = props;
  return (
    <Menu.Item myref={menuRef} onClick={handleClick} style={menuStyle}>
      <Header as="h3">
        <Icon name={iconName} style={styles.goldColor} /> {text}
      </Header>
    </Menu.Item>
  );
};
MenuItemWithIcon.propTypes = {
  menuRef: PropTypes.object,
  handleClick: PropTypes.func,
  menuStyle: PropTypes.object,
  iconName: PropTypes.string,
  text: PropTypes.string,
};
export default MenuItemWithIcon;
