const fieldToValidate = [
  'companyName',
  'companyCountry',
  'companyTelephone',
  'companyAddress',
  'companyEmail',
  // 'companyTradeLicenseNumber',
  'companyNumberOfEmployees',
  'companyTotalBranch',
  'companyCurrency',
  'companyLastYearRevenue',
  // 'companyIataNumber',
  'userName',
  'userFirstName',
  'userLastName',
  'userEmail',
  'userPhoneNumber',
  'userPassword',
  'userConfirmPassword',
];

export { fieldToValidate };
