const WHITE_COLOR = '#FFF';
const BLACK_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const style = {
  formStyle: { marginTop: '10px', marginBottom: '25px' },
  firstRowForm: {
    marginTop: '10px',
    marginBottom: '0px',
  },
  labelInput: {
    color: WHITE_COLOR,
    marginTop: '5px',
    marginBottom: '5px',
  },
  selectStyle: {
    // color: GOLD_COLOR,
    // width: '246px',
    // marginLeft: '7px',
  },
  noMarginBottom: {
    marginBottom: '0px',
  },
  buttonRowForm: {
    marginBottom: '0px',
    marginLeft: '140px',
  },
  buttonTransparent: {
    background: BLACK_COLOR,
    paddingLeft: '85px',
    paddingRight: '85px',
    marginTop: '10px',
  },
  button: {
    background: GOLD_COLOR,
    paddingLeft: '45px',
    paddingRight: '45px',
    marginBottom: '10px',
    marginTop: '0px',
  },
  colorBlack: {
    color: BLACK_COLOR,
  },
  colorGold: {
    color: GOLD_COLOR,
  },
  inputStyle: {
    color: GOLD_COLOR,
    // width: '246px',
  },
  noMargin: { margin: 0 },
};

export default style;
