import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';
import styles from '../styles';
import PropTypes from 'prop-types';
import InputAmount from '../../../components/input/Amount';
import { getRoomPax } from '../../../genericFunction/convertPackage';
import { useTranslation } from 'react-i18next';

const TextForm = props => (
  <Header as={props.size} className="no-margin">
    {props.text}
  </Header>
);
const convertPrice = dataPrice => {
  return dataPrice && dataPrice.Currency
    ? dataPrice.Currency + ' ' + dataPrice.TotalPrice.toLocaleString('id')
    : '0';
};

const PartialPaymentForm = props => {
  const { t } = useTranslation();
  let {
    guests,
    confirmAdult,
    confirmChild,
    confirmInfant,
    tourPriceSum,
    handleIncrease,
    handleDecrease,
    handleOnChange,
    confirmTourPriceSum,
    supplements,
    packageType,
  } = props;
  let {
    SharingRoomSum,
    ChildSharingRoomSum,
    SingleRoomSum,
    ChildSingleRoomSum,
    ExtraBedSum,
    ChildExtraBedSum,
    SharingBedSum,
    NoBedSum,
  } = tourPriceSum;
  let priceSharingRoom = convertPrice(SharingRoomSum);
  let priceChildSharingRoom = convertPrice(ChildSharingRoomSum);
  let priceSingleRoom = convertPrice(SingleRoomSum);
  let priceChildSingleRoom = convertPrice(ChildSingleRoomSum);
  let priceExtraBed = convertPrice(ExtraBedSum);
  let priceChildExtraBed = convertPrice(ChildExtraBedSum);
  let priceSharingBed = convertPrice(SharingBedSum);
  let priceNoBed = convertPrice(NoBedSum);
  let confirmSharingRoomSum = getRoomPax(confirmTourPriceSum, 'SharingRoomSum');
  let confirmChildSharingRoomSum = getRoomPax(
    confirmTourPriceSum,
    'ChildSharingRoomSum'
  );
  let confirmSingleRoomSum = getRoomPax(confirmTourPriceSum, 'SingleRoomSum');
  let confirmChildSingleRoomSum = getRoomPax(
    confirmTourPriceSum,
    'ChildSingleRoomSum'
  );
  let confirmExtraBedSum = getRoomPax(confirmTourPriceSum, 'ExtraBedSum');
  let confirmChildExtraBedSum = getRoomPax(
    confirmTourPriceSum,
    'ChildExtraBedSum'
  );
  let confirmSharingBedSum = getRoomPax(confirmTourPriceSum, 'SharingBedSum');
  let confirmNoBedSum = getRoomPax(confirmTourPriceSum, 'NoBedSum');
  return (
    <Segment style={styles.segmentNoPadding}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            {/* Guest Confirmation */}
            <TextForm size="h3" text={t('guestConfirmation')} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.padding10px}>
          <Grid.Column
            width={4}
            verticalAlign="bottom"
            style={styles.marginBottom10px}
          >
            {/* "Current Guest" */}
            <TextForm size="h5" text={t('currentGuest')} />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputAmount
              label={`${t('adults')}(>12 ${t('years')})`} //"Adults(>12 Years)"
              labelStyle={styles.textAlignCenter}
              inputStyle={styles.borderBottom}
              value={confirmAdult}
              disabled={true}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputAmount
              label={`${t('children')}(2-12 ${t('years')})`} //"Children(> 12 Years)"
              labelStyle={styles.textAlignCenter}
              inputStyle={styles.borderBottom}
              value={confirmChild}
              disabled={true}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputAmount
              label={`${t('infant')}(<2 ${t('years')})`} //"Infant(<2 Years)"
              labelStyle={styles.textAlignCenter}
              inputStyle={styles.borderBottom}
              value={confirmInfant}
              disabled={true}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.padding10px}>
          <Grid.Column width={4} style={styles.marginTop5px}>
            {/* Confrim Guest */}
            <TextForm size="h5" text={t('confirmGuest')} />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="adults"
              objectname="guests"
              value={guests.adults}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmAdult === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="childs"
              objectname="guests"
              value={guests.childs}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmChild === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="infants"
              objectname="guests"
              value={guests.infants}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmInfant === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.withNoPaddingBackground}>
          <Grid.Column width={16}>
            {/* Room Allocation Confirmation */}
            <TextForm size="h3" text={t('roomAllocationConfirmation')} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.withBackground}>
          <Grid.Column width={4} />
          <Grid.Column width={5}>
            {/* Confirmed Room Allocation */}
            <TextForm size="h5" text={t('confirmedRoomAllocation')} />
          </Grid.Column>
          <Grid.Column width={7}>
            {/* Total Price */}
            <TextForm size="h5" text={t('totalPrice')} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.withBackground}>
          <Grid.Column width={4} style={styles.marginTop5px}>
            {/* Sharing Room */}
            <TextForm size="h5" text={t('sharingRoom')} />
          </Grid.Column>
          <Grid.Column width={5}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="SharingRoomSum"
              objectname="tourPriceSum"
              value={SharingRoomSum && SharingRoomSum.Pax}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmSharingRoomSum === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
          <Grid.Column width={7} style={styles.marginTop5px}>
            <TextForm size="h5" text={priceSharingRoom} />
          </Grid.Column>
        </Grid.Row>
        {packageType !== 'Fixed' && (
          <Grid.Row style={styles.withBackground}>
            <Grid.Column width={4} style={styles.marginTop5px}>
              {/* Child Sharing Room */}
              <TextForm size="h5" text={t('childSharingRoom')} />
            </Grid.Column>
            <Grid.Column width={5}>
              <InputAmount
                inputStyle={styles.borderBottom}
                name="ChildSharingRoomSum"
                objectname="tourPriceSum"
                value={ChildSharingRoomSum && ChildSharingRoomSum.Pax}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleOnChange}
                disabled={
                  confirmChildSharingRoomSum === 0 || packageType !== 'Fixed'
                }
              />
            </Grid.Column>
            <Grid.Column width={7} style={styles.marginTop5px}>
              <TextForm size="h5" text={priceChildSharingRoom} />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row style={styles.withBackground}>
          <Grid.Column width={4} style={styles.marginTop5px}>
            {/* Single Room */}
            <TextForm size="h5" text={t('singleRoom')} />
          </Grid.Column>
          <Grid.Column width={5}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="SingleRoomSum"
              objectname="tourPriceSum"
              value={SingleRoomSum && SingleRoomSum.Pax}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmSingleRoomSum === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
          <Grid.Column width={7} style={styles.marginTop5px}>
            <TextForm size="h5" text={priceSingleRoom} />
          </Grid.Column>
        </Grid.Row>
        {packageType !== 'Fixed' && (
          <Grid.Row style={styles.withBackground}>
            <Grid.Column width={4} style={styles.marginTop5px}>
              {/* Child Single Room */}
              <TextForm size="h5" text={t('childSingleRoom')} />
            </Grid.Column>
            <Grid.Column width={5}>
              <InputAmount
                inputStyle={styles.borderBottom}
                name="ChildSingleRoomSum"
                objectname="tourPriceSum"
                value={ChildSingleRoomSum && ChildSingleRoomSum.Pax}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleOnChange}
                disabled={
                  confirmChildSingleRoomSum === 0 || packageType !== 'Fixed'
                }
              />
            </Grid.Column>
            <Grid.Column width={7} style={styles.marginTop5px}>
              <TextForm size="h5" text={priceChildSingleRoom} />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row style={styles.withBackground}>
          <Grid.Column width={4} style={styles.marginTop5px}>
            {/* Extra Bed */}
            <TextForm size="h5" text={t('extraBed')} />
          </Grid.Column>
          <Grid.Column width={5}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="ExtraBedSum"
              objectname="tourPriceSum"
              value={ExtraBedSum && ExtraBedSum.Pax}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmExtraBedSum === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
          <Grid.Column width={7} style={styles.marginTop5px}>
            <TextForm size="h5" text={priceExtraBed} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.withBackground}>
          <Grid.Column width={4} style={styles.marginTop5px}>
            {/* Child Extra Bed */}
            <TextForm size="h5" text={t('childExtraBed')} />
          </Grid.Column>
          <Grid.Column width={5}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="ChildExtraBedSum"
              objectname="tourPriceSum"
              value={ChildExtraBedSum && ChildExtraBedSum.Pax}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={
                confirmChildExtraBedSum === 0 || packageType !== 'Fixed'
              }
            />
          </Grid.Column>
          <Grid.Column width={7} style={styles.marginTop5px}>
            <TextForm size="h5" text={priceChildExtraBed} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.withBackground}>
          <Grid.Column width={4} style={styles.marginTop5px}>
            {/* Sharing Bed with Parents */}
            <TextForm size="h5" text={t('sharingBedWithParents')} />
          </Grid.Column>
          <Grid.Column width={5}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="SharingBedSum"
              objectname="tourPriceSum"
              value={SharingBedSum && SharingBedSum.Pax}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmSharingBedSum === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
          <Grid.Column width={7} style={styles.marginTop5px}>
            <TextForm size="h5" text={priceSharingBed} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.withBackground}>
          <Grid.Column width={4} style={styles.marginTop5px}>
            {/* Baby Crib / No Bed */}
            <TextForm size="h5" text={`${t('babyCrib')} / ${t('noBed')}`} />
          </Grid.Column>
          <Grid.Column width={5}>
            <InputAmount
              inputStyle={styles.borderBottom}
              name="NoBedSum"
              objectname="tourPriceSum"
              value={NoBedSum && NoBedSum.Pax}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              handleOnChange={handleOnChange}
              disabled={confirmNoBedSum === 0 || packageType !== 'Fixed'}
            />
          </Grid.Column>
          <Grid.Column width={7} style={styles.marginTop5px}>
            <TextForm size="h5" text={priceNoBed} />
          </Grid.Column>
        </Grid.Row>
        {supplements.length !== 0 && (
          <Grid.Row columns={2}>
            <Grid.Column>
              {/* Additional Item Confirmation */}
              <TextForm size="h3" text={t('additionalItemConfirmation')} />
            </Grid.Column>
            <Grid.Column style={styles.textAlignRight}>
              <span>
                (<span style={styles.fontWeight700colorRed}>*</span>){' '}
                {t('isMandatoryItem')}
              </span>
            </Grid.Column>
          </Grid.Row>
        )}
        {supplements.length !== 0 && (
          <Grid.Row style={styles.padding10px} verticalAlign="middle">
            <Grid.Column width={4} textAlign="left">
              {/* Description Item Description */}
              <TextForm size="h5" text={t('itemDescription')} />
            </Grid.Column>
            <Grid.Column width={4} textAlign="center">
              {/* Quantity */}
              <TextForm size="h5" text={t('quantity')} />
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              {/* Item Price */}
              <TextForm size="h5" text={t('itemPrice')} />
            </Grid.Column>
            <Grid.Column width={4} textAlign="right">
              {/* Total Price */}
              <TextForm size="h5" text={t('totalPrice')} />
            </Grid.Column>
          </Grid.Row>
        )}
        {supplements.length !== 0 &&
          supplements.map((item, index) => {
            return (
              <Grid.Row
                key={index}
                style={styles.padding10px}
                verticalAlign="middle"
              >
                <Grid.Column width={4}>
                  {item.isMandatory && (
                    <span style={styles.fontWeight700colorRed}>*</span>
                  )}
                  {item.name}
                </Grid.Column>
                <Grid.Column width={4}>
                  <InputAmount
                    name={index}
                    objectname="supplements"
                    inputStyle={styles.borderBottom}
                    value={item.qty}
                    handleIncrease={handleIncrease}
                    handleDecrease={handleDecrease}
                    handleOnChange={handleOnChange}
                    disabled={item.isMandatory}
                  />
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  {item.currencyId}{' '}
                  {item.currencyId ? item.unitCost.toLocaleString('id') : 0}
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  {item.currencyId}{' '}
                  {item.currencyId ? item.totalCost.toLocaleString('id') : 0}
                </Grid.Column>
              </Grid.Row>
            );
          })}
      </Grid>
    </Segment>
  );
};
TextForm.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
};
PartialPaymentForm.propTypes = {
  paymentTerms: PropTypes.array,
  guests: PropTypes.object,
  confirmAdult: PropTypes.number,
  confirmChild: PropTypes.number,
  confirmInfant: PropTypes.number,
  tourPriceSum: PropTypes.object,
  confirmTourPriceSum: PropTypes.object,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleOnChange: PropTypes.func,
  supplements: PropTypes.array,
  packageType: PropTypes.string,
};
export default PartialPaymentForm;
