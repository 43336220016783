import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Header, Button, Icon } from 'semantic-ui-react';
import styles from '../../styles';
import { convertDateFormat } from '../../../../genericFunction/moment';
import { ucwords } from '../../../../genericFunction/convertWord';
import { CardImage } from './../../../shared/components/common';

import { withTranslation } from 'react-i18next';
// import noImage from './../../../assets/image/NoImage.png';
// const bgImage = imgUrl => {
//   return {
//     width: '150PX',
//     height: '220px',
//     flex: 'none',
//     background: 'url(' + imgUrl + ')',
//     backgroundSize: 'cover',
//     borderBottom: '0px',
//   };
// };
class RestaurantCard extends Component {
  render() {
    const { t } = this.props;
    let { menu, ticket, day, date, restaurant, handleButtonInfo } = this.props;
    let image = restaurant
      ? restaurant.RestaurantProfileImages.length !== 0
        ? restaurant.RestaurantProfileImages[0].ImageUrl
        : restaurant.ImageUrl
      : '';
    return (
      <Grid.Row style={styles.padding0pxMarginRight}>
        <Segment.Group horizontal style={styles.segmentNoPadding}>
          <CardImage image={image} />
          {/* <Segment style={bgImage(image ? image : noImage)}> */}
          {/* <GoldRibbon size="big" text="Promo Package" /> */}
          {/* </Segment> */}
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  width={2}
                  style={styles.paddingTop2pxLeftRight15px}
                >
                  <Button
                    circular
                    icon="info circle"
                    style={styles.buttonIconInfo}
                    size="big"
                    onClick={handleButtonInfo}
                    name="openDetail"
                    value={restaurant}
                  />
                </Grid.Column>
                <Grid.Column width={14} style={styles.padding2px}>
                  <Header
                    as="h3"
                    style={styles.goldColor}
                    className="margin-top-ten margin-bottom-ten no-padding"
                  >
                    {restaurant ? restaurant.Name : ''}
                  </Header>
                </Grid.Column>
                <Grid.Column
                  width={2}
                  style={styles.paddingTop2pxLeftRight15px}
                >
                  <Icon className="restorant-speciality" />
                </Grid.Column>
                <Grid.Column width={14} style={styles.padding10px}>
                  <Header as="h5">
                    {/* Dining */}
                    {menu ? menu.MenuClass : ''} {t('dining')} -{' '}
                    {menu ? ucwords(menu.MenuType) : ''}
                  </Header>
                </Grid.Column>
                {/* <Grid.Column width={16}>
                  <Header as="h5">
                    <Icon className="restorant-speciality" />
                    {menu.MenuClass} Dining - {ucwords(menu.MenuType)}
                  </Header>
                </Grid.Column> */}
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {'Day ' +
                      day +
                      ', ' +
                      convertDateFormat(date, 'DD MMM YYYY')}
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {/* Menus : */}
                    {t('menus')} :
                  </Header>
                  {ticket.length !== 0 &&
                    ticket.map((e, index) => (
                      <span key={index}>
                        {e.qty + ' ' + e.desc.split('_').join(' ')}
                        <br />
                      </span>
                    ))}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
      </Grid.Row>
    );
  }
}
RestaurantCard.propTypes = {
  menu: PropTypes.object,
  restaurant: PropTypes.object,
  ticket: PropTypes.array,
  day: PropTypes.number,
  date: PropTypes.string,
  handleButtonInfo: PropTypes.func,
  t: PropTypes.func,
};
export default withTranslation()(RestaurantCard);
