import React, { useState, useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
// import { Button, Icon } from 'semantic-ui-react';
import { CardBackgroundImage, CardSummaryPrice, CardDetail } from './';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { Collapse } from 'react-collapse';
import {
  // getSuplementItemByTransactionId,
  // getTourTransactionToBePayed,
  // getTourTransactionSummary,
  // getTourTransactionBills,
  getInvoiceCustomer,
  getInvoiceTravelAgent,
  getScheduleTourTransaction,
  resetStatusGetInvoiceSchedule,
  getVoucherConfirmation,
} from '../../../shared/actions/bookingAction';
// import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
const CardPackage = props => {
  // const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState(false);
  const { handleOpenDetail } = props;
  //===========action===========
  const dispatch = useDispatch();
  // const getSuplementItemByTransactionIdAction = id =>
  //   dispatch(getSuplementItemByTransactionId(id));
  // const getTourTransactionToBePayedAction = id =>
  //   dispatch(getTourTransactionToBePayed(id));
  // const getTourTransactionSummaryAction = id =>
  //   dispatch(getTourTransactionSummary(id));
  // const getTourTransactionBillsAction = id =>
  //   dispatch(getTourTransactionBills(id));
  //=========download file
  const getInvoiceCustomerAction = param => dispatch(getInvoiceCustomer(param));
  const getInvoiceTravelAgentAction = param =>
    dispatch(getInvoiceTravelAgent(param));
  const getScheduleTourTransactionAction = param =>
    dispatch(getScheduleTourTransaction(param));
  const resetStatusGetInvoiceScheduleAction = () =>
    dispatch(resetStatusGetInvoiceSchedule());
  const getVoucherConfirmationAction = param =>
    dispatch(getVoucherConfirmation(param));

  useEffect(() => {
    setOpenDetail(false);
    // eslint-disable-next-line
  }, [props.activePage]);

  useEffect(() => {
    setOpenDetail(false);
    // eslint-disable-next-line
  }, [props.searchTitle]);

  const handleSeeDetail = (e, { value }) => {
    // setOpenDetail(!value);
    // if (!value) {
    //   let { id } = props;
    //   getSuplementItemByTransactionIdAction(id);
    //   getTourTransactionToBePayedAction(id);
    //   getTourTransactionSummaryAction(id);
    //   getTourTransactionBillsAction(id);
    // }
    handleOpenDetail(value);
  };
  const helperGetExpiredDate = listPaymentTerm => {
    let payment = listPaymentTerm.find(item => item.IsPayed === false);
    return payment ? payment.DueDate : null;
  };

  let {
    title,
    id,
    // expiredOn,
    image,
    startDate,
    endDate,
    city,
    country,
    status,
    currency,
    totalPrice,
    commision,
    descPaymentTerm,
    percentagePaymentTerm,
    totalPayable,
    totalPaid,
    packageType,
    handleModal,
    listPaymentTerm,
    isSales,
    handleCreateSimilar,
    isAcceptedQuotation,
    tourTransactionIdQuotation,
    createdBy,
    //ready fix price
    bookingTemplateRef,
    handleCancel,
  } = props;
  // let expiredDate = getExpiredDate();
  return (
    <div>
      <Segment.Group horizontal style={styles.segmentPackage}>
        <CardBackgroundImage
          title={title}
          id={id}
          expiredOn={helperGetExpiredDate(listPaymentTerm)}
          image={image}
          startDate={startDate}
          endDate={endDate}
          city={city}
          country={country}
          status={status}
          createSimilar={handleCreateSimilar}
          isAcceptedQuotation={isAcceptedQuotation}
          tourTransactionIdQuotation={tourTransactionIdQuotation}
          packageType={packageType}
          isSales={isSales}
          bookingTemplateRef={bookingTemplateRef}
          handlegetInvoiceCustomer={getInvoiceCustomerAction}
          handlegetInvoiceTravelAgent={getInvoiceTravelAgentAction}
          handlegetScheduleTourTransaction={getScheduleTourTransactionAction}
          handleresetStatusGetInvoiceSchedule={
            resetStatusGetInvoiceScheduleAction
          }
          handlegetVoucherConfirmation={getVoucherConfirmationAction}
        />
        <CardSummaryPrice
          id={id}
          handleModal={handleModal}
          status={status}
          currency={currency}
          totalPrice={totalPrice}
          commision={commision}
          paymentTerm={descPaymentTerm}
          percentagePaymentTerm={percentagePaymentTerm}
          totalPayable={totalPayable}
          totalPaid={totalPaid}
          packageType={packageType}
          isSales={isSales}
          isAcceptedQuotation={isAcceptedQuotation}
          handleCreateSimilar={handleCreateSimilar}
          createdBy={createdBy}
          handleCancel={handleCancel}
          handleSeeDetail={handleSeeDetail}
        />
      </Segment.Group>
      <Collapse isOpened={openDetail} style={styles.noMargin}>
        <CardDetail
          id={id}
          openDetail={openDetail}
          listPaymentTerm={listPaymentTerm}
          packageType={packageType}
        />
      </Collapse>
      {/* <Segment style={styles.segmentButton} textAlign="center">
        <Button
          name={'openDetail'}
          value={openDetail}
          onClick={handleSeeDetail}
          style={styles.buttonTextBlue}
        >
          {openDetail ? `${t('lessDetail')}` : `${t('seeDetail')}`}
          <Icon
            size="large"
            style={styles.noMargin}
            name={openDetail ? 'angle up' : 'angle down'}
          />
        </Button>
      </Segment> */}
    </div>
  );
};
CardPackage.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  expiredOn: PropTypes.string,
  image: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  status: PropTypes.string,
  currency: PropTypes.string,
  totalPrice: PropTypes.number,
  commision: PropTypes.number,
  descPaymentTerm: PropTypes.string,
  percentagePaymentTerm: PropTypes.number,
  totalPayable: PropTypes.number,
  totalPaid: PropTypes.number,
  packageType: PropTypes.string,
  handleModal: PropTypes.func,
  getSuplementItemByTransactionId: PropTypes.func,
  getTourTransactionToBePayed: PropTypes.func,
  getTourTransactionSummary: PropTypes.func,
  resetStatusTourTransactionSummary: PropTypes.func,
  listPaymentTerm: PropTypes.array,
  activePage: PropTypes.number,
  searchTitle: PropTypes.string,
  isSales: PropTypes.bool,
  isAcceptedQuotation: PropTypes.bool,
  tourTransactionIdQuotation: PropTypes.number,
  handleCreateSimilar: PropTypes.func,
  createdBy: PropTypes.object,
  getTourTransactionBills: PropTypes.func,
  bookingTemplateRef: PropTypes.number,
  handleCancel: PropTypes.func,
  t: PropTypes.func,
  handleOpenDetail: PropTypes.func,
};

export default CardPackage;
