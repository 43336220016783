import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from 'semantic-ui-react';
import styles from '../styles';
import { useTranslation } from 'react-i18next';

const AdditionalServiceHeader = props => {
  const { t } = useTranslation();
  const { data, isShowButton } = props;
  return data.length ? (
    <>
      <Grid style={styles.marginHeader}>
        <Grid.Row columns={4}>
          <Grid.Column width={6}>
            {/* Service */}
            <strong>{t('service')} </strong>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            {/* Quantity */}
            <strong>{t('quantity')} </strong>
          </Grid.Column>
          <Grid.Column width={5}>
            <strong>{t('price')}</strong>
          </Grid.Column>
          <Grid.Column width={1} />
        </Grid.Row>
        <Divider style={styles.marginDeviderHeader} />
      </Grid>
    </>
  ) : isShowButton ? (
    <></>
  ) : null;
};

AdditionalServiceHeader.propTypes = {
  data: PropTypes.array,
  isShowButton: PropTypes.bool,
  t: PropTypes.func,
};

export default React.memo(AdditionalServiceHeader);
