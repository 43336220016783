import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Icon } from 'semantic-ui-react';
import styles from './styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CustomPackageShortcut = () => {
  const { t } = useTranslation();
  return (
    <div style={styles.marginBottom20}>
      <Segment style={styles.goldGradient}>
        <Grid
          style={styles.customPackageShortcutstyle}
          as={Link}
          to="/custom-package"
        >
          <Grid.Row>
            <Grid.Column width={10} verticalAlign="middle">
              <strong className="font20">
                {/* Create your own customized tour and Get quotes */}
                {t('createYourOwnCustomizedAndGetQuotes')}
              </strong>
            </Grid.Column>
            <Grid.Column width={3} verticalAlign="middle">
              <Icon
                className="Custom-Package-Icon-Circle-2x"
                style={styles.margin4}
              />
            </Grid.Column>
            <Grid.Column
              width={3}
              verticalAlign="middle"
              style={styles.textAlignRight}
            >
              <Icon
                name="angle right"
                style={{ ...styles.margin4, ...styles.textAlignRight }}
                size="huge"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};

CustomPackageShortcut.propTypes = {
  t: PropTypes.func,
};

export default CustomPackageShortcut;
