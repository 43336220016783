import React from 'react';
import { Collapse } from 'react-collapse';
import TimeLineActivity from './TimeLineActivity';
import TimeLineAddActivity from './TimeLineAddActivity';
import PropTypes from 'prop-types';
import {
  VIRTUALCHECKIN,
  VIRTUALCHECKOUT,
  VIRTUALRETURNACCOMMODATION,
  VIRTUALLEAVEACCOMMODATION,
  DRIVING,
  FREETIMELOCKED,
  FLIGHTTIME,
} from '../../../../../constant/activityTypes';
// import TimeLineGuideInfo from './TimeLineGuideInfo';
// import { FIXED } from '../../../../../constant/packageTypes';
const TimeLineCollapse = props => {
  const {
    movements,
    day,
    isCollapse,
    openModalSetActivity,
    deleteActivity,
    openTransportationModal,
    deleteTransportItem,
    transportStore,
    currentPackage,
    dailyData,
    // setDailyProgram,
    // dailyProgram,
    isStatic,
  } = props;
  return (
    <Collapse isOpened={isCollapse}>
      {/* {currentPackage !== FIXED ? (
        <TimeLineGuideInfo
          dailyData={dailyData}
          setDailyProgram={setDailyProgram}
          dailyProgram={dailyProgram}
          day={day}
          isStatic={isStatic}
        />
      ) : null} */}
      {movements.map((activity, i) =>
        activity.MovementName === VIRTUALCHECKIN &&
        movements[i + 1].MovementName === VIRTUALCHECKOUT ? (
          <TimeLineAddActivity
            activity={activity}
            key={'move' + i}
            index={i}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            currentPackage={currentPackage}
            isStatic={isStatic}
          />
        ) : [
            VIRTUALCHECKOUT,
            VIRTUALRETURNACCOMMODATION,
            VIRTUALLEAVEACCOMMODATION,
            VIRTUALCHECKIN,
            FLIGHTTIME,
          ].indexOf(activity.MovementName) !== -1 ||
          ([DRIVING, FREETIMELOCKED].indexOf(activity.MovementName) !== -1 &&
            activity.Duration === 0) ? null : (
          <TimeLineActivity
            activity={activity}
            key={'move' + i}
            index={i}
            movements={movements}
            openModalSetActivity={openModalSetActivity}
            day={day}
            deleteActivity={deleteActivity}
            openTransportationModal={openTransportationModal}
            deleteTransportItem={deleteTransportItem}
            transportStore={transportStore}
            currentPackage={currentPackage}
            isStatic={isStatic}
            dailyData={dailyData}
          />
        )
      )}
    </Collapse>
  );
};
TimeLineCollapse.propTypes = {
  movements: PropTypes.array,
  isCollapse: PropTypes.bool,
  openModalSetActivity: PropTypes.func,
  day: PropTypes.number,
  deleteActivity: PropTypes.func,
  openTransportationModal: PropTypes.func,
  deleteTransportItem: PropTypes.func,
  transportStore: PropTypes.array,
  currentPackage: PropTypes.string,
  dailyData: PropTypes.object,
  setDailyProgram: PropTypes.func,
  dailyProgram: PropTypes.array,
  isStatic: PropTypes.bool,
};
export default TimeLineCollapse;
