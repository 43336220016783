import * as types from '../actions/actionTypes';

const initialState = {
  tourOperatorProfile: {},
  getTourOperatorProfileStatus: '',
  errors: {},
  loading: false,
  tourOperatorList: [],
  tourOperatorProfileList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_TOUR_OPERATOR_PROFILE
    //---------------------------------
    case types.GET_TOUR_OPERATOR_PROFILE:
      return { ...state };
    case types.GET_TOUR_OPERATOR_PROFILE_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_OPERATOR_PROFILE_FULFILLED:
      return {
        ...state,
        tourOperatorProfile: action.payload.data,
        tourOperatorProfileList: state.tourOperatorProfileList.concat(
          action.payload.data
        ),
        errors: {},
        loading: false,
        getTourOperatorProfileStatus: 'success',
      };
    case types.GET_TOUR_OPERATOR_PROFILE_REJECTED:
      return {
        ...state,
        tourOperatorProfile: {},
        errors: action.payload.response.data,
        loading: false,
        getTourOperatorProfileStatus: 'failed',
      };
    //---------------------------------
    // GET_TOUR_OPERATOR_LIST
    //---------------------------------

    case types.GET_TOUR_OPERATOR_LIST:
      return { ...state };
    case types.GET_TOUR_OPERATOR_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_OPERATOR_LIST_FULFILLED:
      return {
        ...state,
        tourOperatorList: action.payload.data,
        errors: {},
        loading: false,
        getTourOperatorListStatus: 'success',
      };
    case types.GET_TOUR_OPERATOR_LIST_REJECTED:
      return {
        ...state,
        tourOperatorList: [],
        errors: action.payload.response.data,
        loading: false,
        getTourOperatorListStatus: 'failed',
      };
    default:
      return state;
  }
};

export default reducer;
