import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Menu } from 'semantic-ui-react';
import Input from '../../../../components/input/WithLabel';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const FilterAndSortingTourPackage = props => {
  const { t } = useTranslation();
  const {
    activeTab,
    searchInput,
    handleChange,
    options,
    activeSort,
    handleChangeTab,
  } = props;
  return (
    <Grid.Row style={styles.noPaddingBottom}>
      <Grid.Column width={5}>
        <Card style={styles.segmentPaddingTen}>
          {/* transparent={true} */}
          <Input
            type="input"
            icon="search"
            iconPosition="left"
            inputStyles={styles.inputTransparent}
            label={`${t('packageName')}`} //"Package Name:"
            labelStyles={styles.labelInputStyle}
            placeholder={t('searchPackageName')} //"Search Package Name"
            name="searchName"
            value={searchInput}
            handleChange={handleChange}
          />
        </Card>
      </Grid.Column>
      <Grid.Column width={6} textAlign="center">
        <Card style={styles.segmentTabMenu}>
          <Menu secondary>
            <Menu.Item
              name="tab"
              value="Series"
              active={activeTab === 'Series'}
              position="left"
              style={
                activeTab === 'Series' ? styles.tabActive : styles.tabNonActive
              }
              onClick={handleChangeTab}
            >
              Series Packages
            </Menu.Item>
            <Menu.Item
              name="tab"
              value="Ready"
              active={activeTab === 'Ready'}
              position="right"
              style={
                activeTab === 'Ready' ? styles.tabActive : styles.tabNonActive
              }
              onClick={handleChangeTab}
            >
              Ready Packages
            </Menu.Item>
          </Menu>
        </Card>
      </Grid.Column>
      <Grid.Column width={5}>
        <Card style={styles.segmentPaddingTen}>
          <Input
            name="activeSort"
            label={t('sortBy')} //"Sort by"
            type="select"
            labelStyles={styles.textBlackBold}
            inputStyles={styles.selectTransparent}
            placeholder={t('selectSomething')} //"Select something"
            options={options}
            handleChange={handleChange}
            value={activeSort}
          />
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

FilterAndSortingTourPackage.propTypes = {
  activeTab: PropTypes.string,
  searchInput: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  activeSort: PropTypes.string,
  handleChangeTab: PropTypes.func,
  t: PropTypes.object,
};

export default FilterAndSortingTourPackage;
