import React, { useState } from 'react';
import { Segment, Header, Icon, Grid, Button, Label } from 'semantic-ui-react';
import ImageCard from '../../../card/ImageCard';
import { ModalDetailExcursion } from '../../../modal';
import { convertDateFormat } from '../../../../../../genericFunction/moment';
import styles from './styles';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

const Card = props => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const { data, setExcursion } = props;
  const openCloseModal = () => {
    setOpenModal(prevState => !prevState);
  };
  const chooseExcursion = () => {
    setExcursion(data);
  };

  return (
    <div>
      <Segment.Group horizontal style={styles.segmentNoPadding}>
        <ImageCard
          images={data.ObjectImages}
          image={data.ImageUrl}
          isPromo={data.IsPromo}
          size="300px"
        />
        <Segment style={styles.borderNone}>
          <Header as="h3" style={styles.goldColor}>
            {data.Name}
          </Header>
          <Grid>
            <Grid.Row style={styles.paddingFiveZero}>
              <Grid.Column width="1">
                <Icon name="map marker alternate" style={styles.goldColor} />
              </Grid.Column>
              <Grid.Column width="14" style={styles.justify}>
                {data.Address}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="1" style={styles.marginTopFive}>
                <Icon name="clock" style={styles.goldColor} />
              </Grid.Column>
              <Grid.Column
                width="14"
                style={styles.marginTopFive}
                verticalAlign="middle"
              >
                <Header as="h5">
                  {convertDateFormat(data.OperationStartTime, 'HH:mm')} -
                  {convertDateFormat(data.OperationEndTime, 'HH:mm')}{' '}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={styles.paddingTopThree}>
              <Grid.Column width="16">
                <Label basic style={styles.label}>
                  {/* {data.IsSolidStartTime ? 'Fixed' : 'Flexible'} Timing */}
                  {data.IsSolidStartTime
                    ? `${t('fixedTiming')}`
                    : `${t('flexibleTiming')}`}
                </Label>
                {data.AttractionType && (
                  <Label basic style={styles.label}>
                    {data.AttractionType.Name}
                  </Label>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={styles.noPaddingTop}>
              <Grid.Column width="7" style={styles.marginTop10px}>
                <Header as="h5" style={styles.noMargin}>
                  {/* Estimated Total Price: */}
                  {t('estimatedTotalPrice')}:
                </Header>
                <Header
                  as="h4"
                  style={{ ...styles.goldColor, ...styles.noMargin }}
                >
                  {`${
                    data.EstimatedTotalPrice.CurrencyId
                  } ${data.EstimatedTotalPrice.Price.toLocaleString('id')}`}
                </Header>
              </Grid.Column>

              <Grid.Column floated="right" width="9">
                <Button
                  size="small"
                  style={styles.buttonWhite}
                  onClick={openCloseModal}
                  value={data.Id}
                >
                  {/* DETAIL */}
                  <strong>{t('Detail')}</strong>
                </Button>
                <Button
                  size="small"
                  floated="right"
                  style={styles.buttonGold}
                  onClick={chooseExcursion}
                >
                  {/* CHOOSE */}
                  <strong>{t('Choose')}</strong>
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
      <ModalDetailExcursion
        open={openModal}
        onClose={openCloseModal}
        profile={data}
        showButtonChoose={true}
        loading={false}
        handleChoose={chooseExcursion}
      />
    </div>
  );
};
Card.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  isPromo: PropTypes.bool,
  name: PropTypes.string,
  address: PropTypes.string,
  category: PropTypes.string,
  type: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  id: PropTypes.number,
  description: PropTypes.string,
  setExcursion: PropTypes.func,
  data: PropTypes.object,
  onClose: PropTypes.func,
  t: PropTypes.func,
};

export default Card;
