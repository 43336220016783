import { GOLD_COLOR } from '../../../../constant/colour';
const style = {
  paddingBottomZero: {
    paddingBottom: 0,
  },
  paddingBottomZeroNoBorder: {
    paddingBottom: 0,
    border: 'none',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  headerGoldNoPadding: {
    background: GOLD_COLOR,
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export default style;
