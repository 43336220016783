import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Header, Icon } from 'semantic-ui-react';
import styles from './../../styles';
import { convertDateFormat } from '../../../../genericFunction/moment';
import { noImageTransparant } from '../../../../assets/image';
// import CardImage from '../../../../../components/summary/cardImage';
// import CardImage from '../../../../components/summary/cardImage';
import { withTranslation } from 'react-i18next';

const bgImage = imgUrl => {
  return {
    width: '150PX',
    height: '220px',
    flex: 'none',
    background: 'url(' + imgUrl + ')',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border: '0px',
  };
};
class TransportationCard extends Component {
  render() {
    const { t } = this.props;
    let {
      image,
      name,
      rating,
      seat,
      duration,
      typeService,
      day,
      date,
      overtime,
    } = this.props;
    return (
      <Grid.Row style={styles.padding0pxMarginRight}>
        <Segment.Group horizontal style={styles.segmentNoPadding}>
          <Segment style={bgImage(image ? image : noImageTransparant)}>
            {/* <GoldRibbon size="big" text="Promo Package" /> */}
          </Segment>
          {/* <CardImage image={image ? image : noImageTransparant} /> */}
          <Segment style={styles.borderNone}>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  width={16}
                  style={styles.paddingTop2pxLeftRight15px}
                >
                  <Header
                    as="h3"
                    style={styles.goldColor}
                    className="margin-top-ten margin-bottom-ten no-padding"
                  >
                    {name}
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {rating}
                  </Header>
                </Grid.Column>
                <Grid.Column width={8} style={styles.marginTopBottom5px}>
                  <span>
                    <Icon className="Seater-Icon" />
                    {seat} {t('passenger')} {/* Passanger */}
                  </span>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {'Day ' +
                      day +
                      ', ' +
                      convertDateFormat(date, 'DD MMM YYYY')}
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header
                    floated="left"
                    as="h5"
                    style={styles.marginTopBottom5px}
                  >
                    {t('typeOfService')}
                    {/* Type of service */}
                  </Header>
                  <Header
                    floated="right"
                    as="h5"
                    style={styles.marginTopBottom5px}
                  >
                    :
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {typeService}
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header
                    floated="left"
                    as="h5"
                    style={styles.marginTopBottom5px}
                  >
                    {t('duration')}
                    {/* Duration */}
                  </Header>
                  <Header
                    floated="right"
                    as="h5"
                    style={styles.marginTopBottom5px}
                  >
                    :
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {duration}
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header
                    floated="left"
                    as="h5"
                    style={styles.marginTopBottom5pxWidth130px}
                  >
                    {t('estimatedOvertimeCharge')}
                    {/* Estimated overtime charge */}
                  </Header>
                  <Header
                    floated="right"
                    as="h5"
                    style={styles.marginTopBottom5px}
                  >
                    :
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    <span style={styles.colorRed}>
                      {overtime !== 0 ? overtime + ' x 1 Hour' : '-'}
                    </span>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
      </Grid.Row>
    );
  }
}
TransportationCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.string,
  seat: PropTypes.string,
  duration: PropTypes.string,
  typeService: PropTypes.string,
  day: PropTypes.number,
  date: PropTypes.string,
  overtime: PropTypes.number,
  t: PropTypes.func,
};
export default withTranslation()(TransportationCard);
