import {
  GET_TOUR_OPERATOR_PROFILE,
  GET_TOUR_OPERATOR_LIST,
} from './actionTypes';
import {
  getTourOperatorProfileApi,
  getTourOperatorListApi,
} from '../../../api/tourOperatorApi';
const getTourOperatorProfile = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_PROFILE,
      payload: getTourOperatorProfileApi(id),
    });
  };
};

const getTourOperatorList = (data, isReadyFixedPrice) => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_OPERATOR_LIST,
      payload: getTourOperatorListApi(data, isReadyFixedPrice),
    });
  };
};
export { getTourOperatorProfile, getTourOperatorList };
