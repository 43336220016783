import {
  uzbekistanAirways,
  accorHotels,
  //royalBrunei,
} from '../../../assets/image';
const partnersImagesData = [
  {
    id: 1,
    name: 'Uzbekistan Airways',
    imageUrl: uzbekistanAirways,
  },
  {
    id: 2,
    name: 'Accor Hotels',
    imageUrl: accorHotels,
  },
  // {
  //   id: 3,
  //   name: 'Royal Bruney',
  //   imageUrl: royalBrunei,
  // },
];

export default partnersImagesData;
