import React from 'react';
import { Segment, Grid, Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { useTranslation } from 'react-i18next';

const DetailBooking = props => {
  const { t } = useTranslation();
  let {
    id,
    title,
    tourDate,
    totalGuest,
    totalTourPrice,
    // totalAdditionalPrice,
    // totalPackagePrice,
    currency,
    descNowPaymentTerm,
    priceNowPaymentTerm,
    totalPaid,
  } = props;
  return (
    <Segment style={styles.segment}>
      <Grid style={styles.paddingTopBottom20px}>
        <Grid.Row style={styles.paddingTop10pxBottom5px}>
          <Grid.Column width={7}>
            {/* BOOKING CODE */}
            <Header as="h2">{t('BookingCode')}</Header>
          </Grid.Column>
          <Grid.Column width={9}>
            <Header as="h2">{id}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10pxBottom5px}>
          <Grid.Column width={16}>
            {/* Booking Detail */}
            <Header as="h4">{t('bookingDetail')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10pxBottom5px}>
          <Grid.Column width={16}>
            <Header as="h4">{title}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingBottom5px}>
          <Grid.Column width={7}>
            <Icon style={styles.goldColor} name="calendar alternate" />
            {/* tour date */}
            <span>
              {t('tourDate')} : {tourDate}
            </span>
          </Grid.Column>
          <Grid.Column width={9}>
            <Icon style={styles.goldColor} name="users" />
            {/* total guest */}
            <span>
              {t('totalGuest')} : {totalGuest}{' '}
              {t('guest', { count: totalGuest })}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10pxBottom5px}>
          <Grid.Column width={9}>
            {/* Total Tour Price */}
            <Header as="h3">{t('totalTourPrice')} </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as="h3">
              : {currency}{' '}
              {totalTourPrice ? totalTourPrice.toLocaleString('ID') : 0}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {/* <Grid.Row style={styles.paddingTop10pxBottom5px}>
            <Grid.Column width={9}>
              <Header as="h3">Total Additional Price</Header>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as="h3">
                : {currency}{' '}
                {totalAdditionalPrice
                  ? totalAdditionalPrice.toLocaleString('ID')
                  : 0}
              </Header>
            </Grid.Column>
          </Grid.Row> */}
        {/* <Grid.Row style={styles.paddingTop10pxBottom5px}>
            <Grid.Column width={9}>
              <Header as="h3">Total Package Price</Header>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as="h3">
                : {currency}{' '}
                {totalPackagePrice ? totalPackagePrice.toLocaleString('ID') : 0}
              </Header>
            </Grid.Column>
          </Grid.Row> */}
        <Grid.Row style={styles.paddingTop10pxBottom5px}>
          <Grid.Column width={9}>
            {/* price */}
            <Header as="h3">
              {t('descNowPaymentTerm', {
                descNowPaymentTerm: descNowPaymentTerm,
              })}
            </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as="h3">
              : {currency}{' '}
              {priceNowPaymentTerm
                ? priceNowPaymentTerm.toLocaleString('ID')
                : 0}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10pxBottom5px}>
          <Grid.Column width={9}>
            {/* Total paid */}
            <Header as="h3">{t('totalPaid')} </Header>
          </Grid.Column>
          <Grid.Column width={7}>
            <Header as="h3">
              : {currency} {totalPaid ? totalPaid.toLocaleString('ID') : 0}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
DetailBooking.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  tourDate: PropTypes.string,
  totalGuest: PropTypes.number,
  totalTourPrice: PropTypes.number,
  // totalAdditionalPrice: PropTypes.number,
  // totalPackagePrice: PropTypes.number,
  currency: PropTypes.string,
  descNowPaymentTerm: PropTypes.string,
  priceNowPaymentTerm: PropTypes.number,
  totalPaid: PropTypes.number,
  t: PropTypes.func,
};
export default DetailBooking;
