import { GOLD_COLOR } from '../../../../../constant/colour';
const style = {
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  shadowTransparant: { boxShadow: '0 0 0 transparent' },
  paddingSideTwenty: {
    padding: '10px 20px',
  },
  borderGold: {
    border: `2px solid ${GOLD_COLOR}`,
  },
  paddingBottomZeroNoBorder: {
    paddingBottom: 0,
    border: 'none',
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  marginForm: {
    marginLeft: '30px',
    marginRight: '30px',
    marginTop: '0px',
  },
  marginHeader: { marginLeft: '30px', marginRight: '30px' },
  marginDeviderHeader: { marginTop: '0px' },
  marginDevider: { marginTop: '0px', marginBottom: '0px' },
  colorRed: {
    color: 'red',
  },
  messageError: {
    border: '1px solid rgb(244, 67, 54)',
    background: 'rgba(244, 67, 54, 0.4)',
  },
};

export default style;
