const BACKGROUND_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';

const style = {
  backgroundPrimary: {
    background: BACKGROUND_COLOR,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  headerGoldNoPadding: {
    background: GOLD_COLOR,
    paddingTop: 0,
    paddingBottom: 0,
  },
  paddingBottomZeroAlignRight: {
    paddingBottom: 0,
    textAlign: 'right',
  },
  paddingBottomZeroAlignCenter: {
    paddingBottom: 0,
    textAlign: 'center',
  },
  paddingBottomZero: {
    paddingBottom: 0,
  },
  goldColor: {
    color: '#e6ca6b',
  },
  goldGradient: {
    borderColor: '#fffc9b',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  },
  noMargin: {
    margin: 0,
  },
  paddingBottomZeroNoBorder: {
    paddingBottom: 0,
    border: 'none',
  },
  borderGold: {
    border: '2px solid #e6ca6b',
  },
  inputSelectionStyle: {
    border: 'none',
    // borderBottom: '1px solid grey',
    // borderRadius: '0px',
  },
  inputTextStyle: {
    padding: '.638500em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  inputDateStyle: {
    border: 'none',
    // borderBottom: '1px solid grey',
    // borderRadius: '0px',
    paddingLeft: '20px !important',
  },
  inputTimeStyle: {
    padding: '.67857143em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  buttonGold: {
    padding: '10px',
    color: BACKGROUND_COLOR,
    background: GOLD_COLOR,
  },
  displayNoneFontBold: {
    display: 'block',
    fontWeight: 'bold',
  },
};

export default style;
