import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import PartialPayment from './components/PartialPaymentComponent';
import SegmentFormGuest from './components/GuestsFormWithButtonDelete';
import { BreadCrumb } from '../shared/components/common';
import ButtonBackAndNext from '../../components/button/BtnBackAndNext';
import {
  getTourTransactionSummary,
  getSuplementItemByTransactionId,
} from '../shared/actions/bookingAction';
import { getTourOperatorProfile } from '../shared/actions/tourOperatorAction';
import { postTourTransactionsConfirmationDemoV2 } from '../shared/actions/tourTransactionAction';
import { setTempTourTransactionsConfirmationDemoV2 } from '../shared/actions/tempAction';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../components/common/loader';
import {
  copyObject,
  changeGuest,
  fillFewGuests,
} from '../../genericFunction/itineraryBuilder/guest';
import {
  setAdditionalItem,
  setAdditionalPrice,
  setAdditionalItemAmount,
} from '../../genericFunction/additionalItem';
import {
  setCommissionAmount,
  getTotalPrice,
  getTotalPayablePartialPayment,
  getUpdateGuestPayment,
  getSupplementPayment,
} from '../../genericFunction/convertPackage';
import {
  isRoomValid,
  isGuestsValid,
  validationTourPax,
  isRoomAllocationValid,
  isErrorFormPackage,
  validationFillAllGuests,
  isAllGuestsError,
  validationTotalGuests,
  validationMinPax,
} from '../../genericFunction/validation';
import { ModalAlert } from './../shared/components/modal';
import { Redirect } from 'react-router-dom';
import { breadCrumb } from './constant/breadCrumb';
import styles from './styles';
import { withTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

class PartialPaymentPage extends Component {
  state = {
    activePage: 'partial-payment',
    guests: {
      adults: 0,
      childs: 0,
      infants: 0,
    },
    tourPriceSum: {},
    supplements: [],
    // isBasedPaymentTerms: 1,
    commissions: {},
    packageType: null,
    errorFormPackage: {
      tourPax: { error: false, message: [] },
      roomAllocation: { error: false, message: [] },
      quota: { error: false, message: [] },
      commission: { error: false, message: [] },
      guests: { error: false, message: [] },
      minimumPax: { error: false, message: [] },
    },
    modalAlert: false,
    totalPrice: 0,
    detailGuests: [],
    nowPayment: {},
    MinPax: '',
    tourNote: '',
    disableField: true,
    additionalServices: [],
  };
  handleButton = (e, { name, value }) => {
    if (name === 'activePage' && this.state.packageType === 'Fixed') {
      this.validationData(value);
    } else if (
      // name !== 'isBasedPaymentTerms' &&
      this.state.packageType !== 'Fixed'
    ) {
      value === 'payment'
        ? this.validationGuests(this.state.errorFormPackage)
        : this.setState({ ...this.state, [name]: value });
      // disini
    } else {
      // const { supplements, nowPayment } = this.state;
      // let newSupplements = [];
      // if (name === 'isBasedPaymentTerms') {
      //   newSupplements =
      //     value === 1 && nowPayment
      //       ? setAdditionalPrice(
      //           supplements,
      //           this.props.tourTransactionSummaryById.BookingDetailSum
      //             .PaymentTerms,
      //           nowPayment.PaymentPercentage
      //         )
      //       : supplements;
      // }
      // console.log(newSupplements);
      this.setState({
        ...this.state,
        [name]: value,
        // supplements: newSupplements,
      });
    }
  };
  handleCloseModal = () => this.setState({ ...this.state, modalAlert: false });

  validationData = value => {
    let {
      guests,
      errorFormPackage,
      tourPriceSum,
      MinPax,
      packageType,
    } = this.state;
    // let newErrorFormPackage = {};
    // let validationGuests = [];
    let newErrorFormPackage = validationTourPax(guests, errorFormPackage);
    newErrorFormPackage =
      packageType === 'Fixed'
        ? validationMinPax(guests, errorFormPackage, MinPax)
        : newErrorFormPackage;
    newErrorFormPackage = isRoomAllocationValid(
      newErrorFormPackage,
      guests,
      tourPriceSum
    );
    if (value === 'confirm-guest') {
      if (isErrorFormPackage(newErrorFormPackage)) {
        this.setState({
          ...this.state,
          errorFormPackage: newErrorFormPackage,
          modalAlert: true,
        });
      } else {
        this.setState({ ...this.state, activePage: value });
      }
    } else if (value === 'payment') {
      this.validationGuests(newErrorFormPackage);
    } else {
      this.setState({ ...this.state, activePage: value });
    }
  };
  validationGuests = errorFormPackage => {
    let { guests, detailGuests, tourPriceSum, supplements } = this.state;
    let validationGuests = validationFillAllGuests(detailGuests);
    let newErrorFormPackage = validationTotalGuests(
      detailGuests,
      guests,
      errorFormPackage
    );
    if (newErrorFormPackage.guests.error) {
      // console.log(newErrorFormPackage);
      this.setState({
        ...this.state,
        errorFormPackage: newErrorFormPackage,
        modalAlert: true,
      });
    } else if (isAllGuestsError(validationGuests)) {
      this.setState({
        ...this.state,
        detailGuests: validationGuests,
      });
    } else {
      let data = {
        Id: this.props.match.params.id,
        Confirmation: 'Confirm',
        UpdateGuest: getUpdateGuestPayment(guests, tourPriceSum, detailGuests),
        SupplementPayment: getSupplementPayment(supplements),
      };
      this.props.postTourTransactionsConfirmationDemoV2(data);
      this.props.setTempTourTransactionsConfirmationDemoV2(data);
      this.setState({ ...this.state, activePage: 'payment' });
    }
  };
  componentDidMount = () => {
    let { id } = this.props.match.params;
    this.props.getTourTransactionSummary(id).then(async () => {
      let {
        BookingDetailSum,
        TourPriceSum,
        TourGuestSum,
        AdditionalServices,
      } = copyObject(this.props.tourTransactionSummaryById);
      let commissions = BookingDetailSum.FixedPackage
        ? BookingDetailSum.FixedPackage.BookingCommission.ApplicableCommission
        : [];
      let packageType = BookingDetailSum.PackageType;
      // disini
      let nowPayment = await this.getNowPaymentTerm(
        this.props.tourTransactionSummaryById
      );

      this.props.getTourOperatorProfile(BookingDetailSum.TourOperatorProfileId);
      this.props
        .getSuplementItemByTransactionId(id)
        .then(() =>
          this.setSupplementState(
            this.props.suplementItemByTransactionId,
            id,
            nowPayment,
            BookingDetailSum.PaymentTerms
          )
        );
      let guests = {
        adults: BookingDetailSum.AdultQty,
        childs: BookingDetailSum.ChildQty,
        infants: BookingDetailSum.InfantQty,
      };
      let detailGuests = TourGuestSum.map(guest => {
        return {
          ...guest,
          IsDelete: false,
          errors: {
            firstName: { error: false, message: '' },
            lastName: { error: false, message: '' },
            identityNbr: { error: false, message: '' },
            identityType: { error: false, message: '' },
            guestTitle: { error: false, message: '' },
            countryId: { error: false, message: '' },
          },
        };
      });
      this.setState({
        ...this.state,
        guests,
        tourPriceSum: TourPriceSum,
        commissions,
        packageType,
        detailGuests,
        nowPayment,
        MinPax: BookingDetailSum.FixedPackage
          ? BookingDetailSum.FixedPackage.MinPax
          : null,
        additionalServices: AdditionalServices,
      });
    });
  };
  handleIncrease = (e, { name, value, objectname }) => {
    const state = { ...this.state };
    const objectInState = state[objectname];
    let newValue = 0;
    if (objectname === 'tourPriceSum') {
      newValue = isRoomValid(
        name,
        value,
        this.state.guests,
        this.state.packageType
      )
        ? value + 1
        : value;
      objectInState[name]['Pax'] = newValue;
      objectInState[name]['TotalPrice'] =
        newValue * objectInState[name]['PricePerPax'];
    } else if (objectname === 'supplements') {
      objectInState[name].qty = value + 1;
    } else if (objectname === 'guests') {
      newValue = isGuestsValid(
        name,
        value,
        this.props.tourTransactionSummaryById.BookingDetailSum
      )
        ? value + 1
        : value;
      objectInState[name] = newValue;
    } else {
      objectInState[name] = value + 1;
    }
    this.setState({ ...this.state, [objectname]: objectInState }, async () => {
      if (objectname === 'supplements') {
        this.setSupplementPrice();
      } else if (objectname === 'guests') {
        await this.setCommissionsState(objectInState);
        await this.setSupplementQty(objectInState);
        this.setSupplementPrice();
      }
    });
  };

  // () =>
  // objectname === 'supplements'
  //   ? this.setSupplementPrice()
  //   : objectname === 'guests' && this.setCommissionsState(objectInState)
  handleDecrease = (e, { name, value, objectname }) => {
    const state = copyObject(this.state);
    const objectInState = state[objectname];
    let newValue = value - 1 < 0 ? 0 : value - 1;
    if (objectname === 'supplements') {
      objectInState[name].qty = newValue;
    } else if (objectname === 'tourPriceSum') {
      objectInState[name]['Pax'] = newValue;
      objectInState[name]['TotalPrice'] =
        newValue * objectInState[name]['PricePerPax'];
    } else {
      newValue =
        objectname === 'guests' ? (newValue < 1 ? 1 : newValue) : newValue;
      objectInState[name] = newValue;
    }
    this.setState({ ...this.state, [objectname]: objectInState }, async () => {
      if (objectname === 'supplements') {
        this.setSupplementPrice();
      } else if (objectname === 'guests') {
        await this.setCommissionsState(objectInState);
        await this.setSupplementQty(objectInState);
        this.setSupplementPrice();
      }
    });
  };

  handleOnChange = (e, { name, value, objectname }) => {
    const state = { ...this.state };
    const objectInState = state[objectname];
    const newValue = value === '' ? 0 : parseInt(value, 10);
    if (objectname === 'supplements') {
      objectInState[name].qty = newValue;
    } else if (objectname === 'tourPriceSum') {
      objectInState[name]['Pax'] = newValue;
      objectInState[name]['TotalPrice'] =
        newValue * objectInState[name]['PricePerPax'];
    } else {
      objectInState[name] = newValue;
    }
    this.setState({ ...this.state, [objectname]: objectInState }, async () => {
      if (objectname === 'supplements') {
        this.setSupplementPrice();
      } else if (objectname === 'guests') {
        await this.setCommissionsState(objectInState);
        await this.setSupplementQty(objectInState);
        this.setSupplementPrice();
      }
    });
  };

  // handleChangePaymentSupplement = (e, { name, objectname, value }) => {
  //   const state = { ...this.state };
  //   const objectInState = state.supplements;
  //   let newValue = 0;
  //   if (value) {
  //     newValue = parseInt(value.replace(/\D/g, ''), 10);
  //   }
  //   newValue =
  //     newValue > objectInState[name].payable
  //       ? objectInState[name].payable
  //       : newValue < objectInState[name].minimumPayable
  //       ? objectInState[name].minimumPayable
  //       : newValue;
  //   objectInState[name][objectname] = newValue;
  //   this.setState({ ...this.state, supplements: objectInState });
  // };
  //  =========== handle guest form ====================
  handleChangeGuest = (e, { name, value, objectname }) => {
    let { detailGuests } = this.state;
    let newListGuest = changeGuest(name, detailGuests, objectname, value);
    this.setState({
      ...this.state,
      detailGuests: newListGuest,
    });
  };
  uploadTemplate = guests => {
    let detailGuests = fillFewGuests(
      guests.rows,
      this.state.detailGuests,
      'edit'
    );
    this.setState({
      ...this.state,
      detailGuests,
    });
  };
  //  =========== end handle guest form ====================
  setSupplementState = (supplements, id, paymentTerm, paymentTerms) => {
    let newSupplements = setAdditionalItem(supplements, id);
    newSupplements = paymentTerm
      ? setAdditionalPrice(
          newSupplements,
          paymentTerms,
          paymentTerm.PaymentPercentage
        )
      : newSupplements;
    this.setState({
      ...this.state,
      supplements: newSupplements,
    });
  };
  setSupplementPrice = () => {
    let { supplements, nowPayment } = this.state;
    let newSupplements = nowPayment
      ? setAdditionalPrice(
          supplements,
          this.props.tourTransactionSummaryById.BookingDetailSum.PaymentTerms,
          nowPayment.PaymentPercentage
        )
      : supplements;
    this.setState({ ...this.state, supplements: newSupplements });
  };
  setSupplementQty = guests => {
    let newSupplements = setAdditionalItemAmount(
      this.state.supplements,
      guests
    );
    this.setState({ ...this.state, supplements: newSupplements });
  };
  setCommissionsState = guests => {
    let { commissions } = this.state;
    let newCommissions = setCommissionAmount(commissions, guests);
    this.setState({ ...this.state, commissions: newCommissions });
  };
  getNowPaymentTerm = tourTransactionSummaryById => {
    let { BookingDetailSum } = tourTransactionSummaryById;
    return BookingDetailSum
      ? BookingDetailSum.FixedPackage
        ? BookingDetailSum.FixedPackage.PaymentTerms.length !== 0
          ? BookingDetailSum.FixedPackage.PaymentTerms.filter(
              paid => !paid.IsPayed
            )[0]
          : {}
        : {}
      : {};
  };
  getMessage = errorFormPackage => {
    return errorFormPackage.tourPax.error
      ? errorFormPackage.tourPax.message[0]
      : errorFormPackage.roomAllocation.error
      ? errorFormPackage.roomAllocation.message.length > 1
        ? this.showMessage(errorFormPackage.roomAllocation.message)
        : errorFormPackage.roomAllocation.message
      : errorFormPackage.guests.error
      ? errorFormPackage.guests.message.length > 1
        ? this.showMessage(errorFormPackage.guests.message)
        : errorFormPackage.guests.message
      : errorFormPackage.minimumPax.error
      ? errorFormPackage.minimumPax.message[0]
      : '';
  };
  showMessage = messages => {
    return messages.reduce((prev, next, idx) =>
      idx + 1 === messages.length ? prev + ' and ' + next : prev + ', ' + next
    );
  };
  render() {
    const { t } = this.props;
    let {
      activePage,
      tourPriceSum,
      guests,
      // isBasedPaymentTerms,
      supplements,
      commissions,
      packageType,
      modalAlert,
      detailGuests,
      errorFormPackage,
      tourNote,
      disableField,
      additionalServices,
    } = this.state;
    let {
      loading,
      tourTransactionSummaryById,
      tourOperatorProfile,
      loadingTourOperator,
    } = this.props;
    // let nowPayment = this.getNowPaymentTerm(tourTransactionSummaryById);
    let messageError = this.getMessage(errorFormPackage);
    let totalPrice = getTotalPrice(
      tourPriceSum,
      supplements,
      additionalServices
    );
    let isSplitCommission =
      tourTransactionSummaryById.BookingDetailSum &&
      tourTransactionSummaryById.BookingDetailSum.IsSplitStaffCommission;
    let totalPayable =
      packageType === 'Fixed' &&
      getTotalPayablePartialPayment(commissions, totalPrice);
    let { id } = this.props.match.params;
    if (activePage === 'my-booking') {
      return <Redirect to={'/my-profile/booking/'} />;
    }
    if (activePage === 'payment') {
      return <Redirect to={'/payment/payment-method/' + id} />;
    }
    return (
      <div style={styles.backGroundWhiteGrey}>
        <div style={styles.backGroundWhiteGrey}>
          <DynamicTitle title="Payment" />
          <BreadCrumb sections={breadCrumb} />
          <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
            {activePage === 'partial-payment' ? (
              <PartialPayment
                tourOperatorProfile={tourOperatorProfile}
                loadingTourOperator={loadingTourOperator}
                guests={guests}
                tourPriceSum={tourPriceSum}
                tourTransactionSummaryById={tourTransactionSummaryById}
                // isBasedPaymentTerms={isBasedPaymentTerms}
                supplements={supplements}
                commissions={commissions}
                packageType={packageType}
                totalPrice={totalPrice}
                isSplitCommission={isSplitCommission}
                totalPayable={totalPayable}
                handleButton={this.handleButton}
                handleIncrease={this.handleIncrease}
                handleDecrease={this.handleDecrease}
                handleOnChange={this.handleOnChange}
                tourNote={tourNote}
                disableField={disableField}
                // handleChangePaymentSupplement={
                //   this.handleChangePaymentSupplement
                // }
                additionalServices={additionalServices}
              />
            ) : (
              activePage === 'confirm-guest' && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <SegmentFormGuest
                        guests={detailGuests}
                        handleChange={this.handleChangeGuest}
                        isCustomOrReadyPackage={
                          packageType !== 'Fixed' ? true : false
                        }
                        uploadTemplate={this.uploadTemplate}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <ButtonBackAndNext
                    nameBack="activePage"
                    nameNext="activePage"
                    valueBack="partial-payment"
                    valueNext="payment"
                    titleButtonBack={t('back')} //"Back"
                    titleButtonNext={t('next')} //"Next"
                    handleButton={this.handleButton}
                  />
                </Grid>
              )
            )}
            <ModalAlert
              openModal={modalAlert}
              size="tiny"
              close={this.handleCloseModal}
              message={messageError}
            />
            <Loader active={loading} description="Loading" page={true} />
          </Container>
        </div>
      </div>
    );
  }
}
PartialPaymentPage.propTypes = {
  match: PropTypes.object,
  getTourTransactionSummary: PropTypes.func,
  getTourOperatorProfile: PropTypes.func,
  postTourTransactionsConfirmationDemoV2: PropTypes.func,
  setTempTourTransactionsConfirmationDemoV2: PropTypes.func,
  loading: PropTypes.bool,
  tourTransactionSummaryById: PropTypes.object,
  tourOperatorProfile: PropTypes.object,
  loadingTourOperator: PropTypes.bool,
  getSuplementItemByTransactionId: PropTypes.func,
  suplementItemByTransactionId: PropTypes.array,
  loadingSeeDetail: PropTypes.array,
  loadingTourTransaction: PropTypes.bool,
  tourTransactionBilled: PropTypes.object,
  t: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    loading: state.booking.loadingSeeDetail,
    loadingTourTransaction: state.tourTransaction.loading,
    tourTransactionSummaryById: state.booking.tourTransactionSummary,
    tourOperatorProfile: state.tourOperator.tourOperatorProfile,
    suplementItemByTransactionId: state.booking.suplementItemByTransactionId,
    tourTransactionBilled: state.tourTransaction.tourTransactionBilled,
  };
};
export default connect(
  mapStateToProps,
  {
    getTourOperatorProfile,
    getTourTransactionSummary,
    getSuplementItemByTransactionId,
    postTourTransactionsConfirmationDemoV2,
    setTempTourTransactionsConfirmationDemoV2,
  }
)(withTranslation()(PartialPaymentPage));
