import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment, Grid, Header } from 'semantic-ui-react';
import { CardHeaderExcursion } from '../../../shared/components/modal';
import { ButtonCloseModal } from '../../../shared/components/common';
// import CardDescription from './cardDescription';
import styles from '../../styles';

const modalDetailRestaurant = props => {
  let { open, onClose, image, images, name, address, description } = props;
  return (
    <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        Restaurant Detail <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description>
          <Segment.Group style={styles.segment}>
            <CardHeaderExcursion
              name={name}
              location={address}
              images={images}
              image={image}
            />
            <Segment style={styles.segmentNoBorderPaddingLeftRight20px}>
              <Grid>
                <Grid.Row style={styles.noPaddingBottom}>
                  <Grid.Column width="16">
                    <Header as="h4" style={styles.marginBottom3px}>
                      Description
                    </Header>
                    <p style={styles.noMarginBottom}>{description}</p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment.Group>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
modalDetailRestaurant.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  image: PropTypes.string,
  images: PropTypes.array,
  name: PropTypes.string,
  address: PropTypes.string,
  description: PropTypes.string,
};
export default modalDetailRestaurant;
