const passIconClick = (name, data, cb) => {
  let icon = `${name}Icon`;
  let show = `${name}Show`;
  let inputType = `${name}InputType`;
  if (data[show]) {
    cb({
      ...data,
      [icon]: 'eye slash',
      [show]: false,
      [inputType]: 'password',
    });
  } else {
    cb({
      ...data,
      [icon]: 'eye',
      [show]: true,
      [inputType]: 'text',
    });
  }
};

export default passIconClick;
