import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup, Icon, Label } from 'semantic-ui-react';
import AccommodationPanel from './AccommodationPanel';

class AccommodationMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  handleOpen = () => {
    this.props.handleMarkerOnclick(this.props.index);
  };
  handleClose = () => {
    this.props.handleMarkerOnClose();
  };
  render() {
    const { id, data, handleOpenRooms, isOpen } = this.props;
    const accommodation =
      data.length > 0 ? data.find(item => item.Id === id) : null;
    return (
      <Popup
        trigger={
          // <Icon name={'map marker alternate'} className="hotel-map-marker" />
          <Label
            pointing="below"
            className="labelStyleMap"
            icon={<Icon className="fas fa-hotel" />}
            content={<span>{accommodation ? accommodation.Name : null}</span>}
          />
        }
        content={
          <AccommodationPanel
            data={accommodation}
            closePopup={this.handleClose}
            handleOpenRooms={handleOpenRooms}
          />
        }
        open={isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        on="click"
        hideOnScroll
      />
    );
  }
}
AccommodationMarker.propTypes = {
  id: PropTypes.number,
  data: PropTypes.array,
  handleOpenRooms: PropTypes.func,
  isOpen: PropTypes.bool,
  index: PropTypes.number,
  handleMarkerOnclick: PropTypes.func,
  handleMarkerOnClose: PropTypes.func,
};
export default AccommodationMarker;
