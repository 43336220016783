import {
  GET_CAROUSEL_IMAGE,
  GET_CAROUSEL_IMAGE_AFTER_LOGIN,
} from './actionTypes';
import {
  getCarouselImageApi,
  getCarouselImageAfterLoginApi,
} from '../../../api/imageApi';

const getCarouselImage = data => {
  return dispatch => {
    return dispatch({
      type: GET_CAROUSEL_IMAGE,
      payload: getCarouselImageApi(data),
    });
  };
};

const getCarouselImageAfterLogin = data => {
  return dispatch => {
    return dispatch({
      type: GET_CAROUSEL_IMAGE_AFTER_LOGIN,
      payload: getCarouselImageAfterLoginApi(data),
    });
  };
};

export { getCarouselImage, getCarouselImageAfterLogin };
