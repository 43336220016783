import apiClient from './apiClient';
import { GET_BROCHURE_URL, GET_BROCHURE_OVERLAY_URL } from './apiUrl';

const getBrochureApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_BROCHURE_URL}?id=${id}`);
};

const getBrochureOverlayApi = (id, companyCode) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_BROCHURE_OVERLAY_URL}/${id}/${companyCode}`);
};
export { getBrochureApi, getBrochureOverlayApi };
