import * as types from '../actions/actionTypes';

const initialState = {
  accommodationTypes: [],
  getAccommodationTypeStatus: '',
  loadingGetAccommodationTypes: false,
  accommodationRatings: [],
  getAccommodationRatingStatus: '',
  loadingGetAccommodationRating: false,
  accommodationLocations: [],
  getAccommodationLocationStatus: '',
  accommodations: null, //{}
  getAccommodationStatus: '',
  accommodationRooms: [],
  getAccommodationRoomStatus: '',
  accommodationFacilities: [],
  getAccommodationFacilitiesStatus: '',
  accommodationProfile: {},
  accommodationProfileList: [],
  getAccommodationProfileByIdStatus: '',
  errors: {},
  loading: false,
  loadingGetAccommodation: false,
  accommodationAreas: [],
  loadingFilterParameter: false,
  getAccommodationFilterParameterStatus: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_ACCOMMODATION_TYPES
    //---------------------------------
    case types.GET_ACCOMMODATION_TYPES:
      return { ...state };
    case types.GET_ACCOMMODATION_TYPES_PENDING:
      return { ...state, loading: true, loadingGetAccommodationTypes: true };
    case types.GET_ACCOMMODATION_TYPES_FULFILLED:
      return {
        ...state,
        accommodationTypes: action.payload.data,
        errors: {},
        loading: false,
        getAccommodationTypeStatus: 'success',
        loadingGetAccommodationTypes: false,
      };
    case types.GET_ACCOMMODATION_TYPES_REJECTED:
      return {
        ...state,
        accommodationTypes: [],
        errors: action.payload.response.data,
        loading: false,
        getAccommodationTypeStatus: 'failed',
        loadingGetAccommodationTypes: false,
      };
    //---------------------------------
    // GET_ACCOMMODATION_RATINGS
    //---------------------------------
    case types.GET_ACCOMMODATION_RATINGS:
      return { ...state };
    case types.GET_ACCOMMODATION_RATINGS_PENDING:
      return { ...state, loading: true, loadingGetAccommodationRating: true };
    case types.GET_ACCOMMODATION_RATINGS_FULFILLED:
      return {
        ...state,
        accommodationRatings: action.payload.data,
        errors: {},
        loading: false,
        loadingGetAccommodationRating: false,
        getAccommodationRatingStatus: 'success',
      };
    case types.GET_ACCOMMODATION_RATINGS_REJECTED:
      return {
        ...state,
        accommodationRatings: [],
        errors: action.payload.response.data,
        loading: false,
        getAccommodationRatingStatus: 'failed',
        loadingGetAccommodationRating: false,
      };
    //---------------------------------
    // GET_ACCOMMODATION_LOCATIONS
    //---------------------------------
    case types.GET_ACCOMMODATION_LOCATIONS:
      return { ...state };
    case types.GET_ACCOMMODATION_LOCATIONS_PENDING:
      return {
        ...state,
        loading: true,
        loadingGetAccommodationLocations: true,
      };
    case types.GET_ACCOMMODATION_LOCATIONS_FULFILLED:
      return {
        ...state,
        accommodationLocations: action.payload.data,
        errors: {},
        loading: false,
        getAccommodationLocationStatus: 'success',
        loadingGetAccommodationLocations: false,
      };
    case types.GET_ACCOMMODATION_LOCATIONS_REJECTED:
      return {
        ...state,
        accommodationLocations: [],
        errors: action.payload.response.data,
        loading: false,
        getAccommodationLocationStatus: 'failed',
        loadingGetAccommodationLocations: false,
      };
    //---------------------------------
    // GET_ACCOMMODATIONS
    //---------------------------------
    case types.GET_ACCOMMODATIONS:
      return { ...state };
    case types.GET_ACCOMMODATIONS_PENDING:
      return { ...state, loadingGetAccommodation: true };
    case types.GET_ACCOMMODATIONS_FULFILLED:
      return {
        ...state,
        // accommodations: action.payload.data,
        accommodations: action.payload.data.AccommodationResults,
        accommodationRatings:
          action.payload.data.FilterParameters.AccommodationRatings,
        accommodationTypes:
          action.payload.data.FilterParameters.AccommodationTypes,
        accommodationFacilities:
          action.payload.data.FilterParameters.AccommodationProfileFacilities,
        accommodationLocations: action.payload.data.FilterParameters.Areas,
        errors: {},
        loadingGetAccommodation: false,
        getAccommodationStatus: 'success',
      };
    case types.GET_ACCOMMODATIONS_REJECTED:
      return {
        ...state,
        accommodations: null, //{}
        errors: action.payload.response.data,
        loadingGetAccommodation: false,
        getAccommodationStatus: 'failed',
      };
    //---------------------------------
    // GET_ACCOMMODATION_ROOMS
    //---------------------------------
    case types.GET_ACCOMMODATION_ROOMS:
      return { ...state };
    case types.GET_ACCOMMODATION_ROOMS_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMMODATION_ROOMS_FULFILLED:
      return {
        ...state,
        accommodationRooms: action.payload.data,
        errors: {},
        loading: false,
        getAccommodationRoomStatus: 'success',
      };
    case types.GET_ACCOMMODATION_ROOMS_REJECTED:
      return {
        ...state,
        accommodationRooms: [],
        errors: action.payload.response.data,
        loading: false,
        getAccommodationRoomStatus: 'failed',
      };
    //---------------------------------
    // GET_ACCOMMODATION_FACILITIES
    //---------------------------------
    case types.GET_ACCOMMODATION_FACILITIES:
      return { ...state };
    case types.GET_ACCOMMODATION_FACILITIES_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMMODATION_FACILITIES_FULFILLED:
      return {
        ...state,
        accommodationFacilities: action.payload.data,
        errors: {},
        loading: false,
        getAccommodationFacilitiesStatus: 'success',
      };
    case types.GET_ACCOMMODATION_FACILITIES_REJECTED:
      return {
        ...state,
        accommodationFacilities: [],
        errors: action.payload.response.data,
        loading: false,
        getAccommodationFacilitiesStatus: 'failed',
      };
    case types.RESET_STATUS_ACCOMMODATION:
      return {
        ...state,
        getAccommodationTypeStatus: '',
        getAccommodationRatingStatus: '',
        getAccommodationLocationStatus: '',
        getAccommodationStatus: '',
        getAccommodationRoomStatus: '',
      };
    //---------------------------------
    // GET_ACCOMMODATION_PROFILE_BY_ID
    //---------------------------------
    case types.GET_ACCOMMODATION_PROFILE_BY_ID:
      return { ...state };
    case types.GET_ACCOMMODATION_PROFILE_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_ACCOMMODATION_PROFILE_BY_ID_FULFILLED:
      return {
        ...state,
        accommodationProfile: action.payload.data,
        errors: {},
        loading: false,
        getAccommodationProfileByIdStatus: 'success',
      };
    case types.GET_ACCOMMODATION_PROFILE_BY_ID_REJECTED:
      return {
        ...state,
        accommodationProfile: {},
        errors: action.payload.response.data,
        loading: false,
        getAccommodationProfileByIdStatus: 'failed',
      };
    case types.RESET_GET_ACCOMMODATION_PROFILE_BY_ID:
      return {
        ...state,
        accommodationProfile: {},
        getAccommodationProfileByIdStatus: '',
      };
    case types.SELECT_ACCOMMODATION_PROFILE:
      return {
        ...state,
        accommodationProfileList: state.accommodationProfileList.concat(
          action.payload
        ),
      };
    case types.RESET_SELECT_ACCOMMODATION_PROFILE:
      return {
        ...state,
        accommodationProfileList: [],
      };
    //======================================
    //---------------------------------
    // GET_ACCOMMODATION_FILTER_PARAMETER
    //---------------------------------
    case types.GET_ACCOMMODATION_FILTER_PARAMETER:
      return { ...state };
    case types.GET_ACCOMMODATION_FILTER_PARAMETER_PENDING:
      return { ...state, loadingFilterParameter: true };
    case types.GET_ACCOMMODATION_FILTER_PARAMETER_FULFILLED: {
      let data = action.payload.data;
      return {
        ...state,
        accommodationRatings: data ? data.AccommodationRatings : [],
        accommodationLocations: data ? data.AccommodationLocations : [],
        accommodationTypes: data ? data.AccommodationTypes : [],
        accommodationFacilities: data
          ? data.AccommodationProfileFacilities
          : [],
        accommodationAreas: data ? data.Areas : [],
        errors: {},
        loadingFilterParameter: false,
        getAccommodationFilterParameterStatus: true,
      };
    }
    case types.GET_ACCOMMODATION_FILTER_PARAMETER_REJECTED:
      return {
        ...state,
        accommodationRatings: [],
        accommodationLocations: [],
        accommodationTypes: [],
        accommodationFacilities: [],
        accommodationAreas: [],
        errors: action.payload.response.data,
        loadingFilterParameter: false,
        getAccommodationFilterParameterStatus: false,
      };
    default:
      return state;
  }
};

export default reducer;
