import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { BreadCrumb } from '../../../shared/components/common';
import FormPaymentMethod from './PaymentMethodForm';
import BookingDetail from './DetailBooking';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { breadCrumbPaymentMethod } from '../../constant/breadCrumb';

const PayMethod = props => {
  let {
    id,
    title,
    tourDate,
    totalGuest,
    totalTourPrice,
    // paymentTerm,
    // totalAdditionalPrice,
    // totalPackagePrice,
    // totalPackagePriceWithAdditional,
    currency,
    expiredDate,
    balance,
    outstandingBill,
    handleButton,
    descNowPaymentTerm,
    priceNowPaymentTerm,
    totalPaid,
    handleChange,
    amountToPay,
    currencyCompany,
  } = props;
  return (
    <div style={styles.backgroundWhite}>
      <BreadCrumb sections={breadCrumbPaymentMethod} />
      <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
        <Grid>
          <Grid.Row>
            <Grid.Column width={9}>
              <BookingDetail
                id={id}
                title={title}
                tourDate={tourDate}
                totalGuest={totalGuest}
                totalTourPrice={totalTourPrice}
                // totalAdditionalPrice={totalAdditionalPrice}
                // totalPackagePrice={totalPackagePrice}
                currency={currency}
                descNowPaymentTerm={descNowPaymentTerm}
                priceNowPaymentTerm={priceNowPaymentTerm}
                totalPaid={totalPaid}
              />
            </Grid.Column>
            <Grid.Column width={7}>
              <FormPaymentMethod
                expiredDate={expiredDate}
                currency={currency}
                currencyCompany={currencyCompany}
                balance={balance}
                outstandingBill={outstandingBill}
                handleButton={handleButton}
                amountToPay={amountToPay}
                handleChange={handleChange}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};
PayMethod.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  tourDate: PropTypes.string,
  totalGuest: PropTypes.number,
  totalTourPrice: PropTypes.number,
  // totalAdditionalPrice: PropTypes.number,
  // totalPackagePrice: PropTypes.number,
  currency: PropTypes.string,
  expiredDate: PropTypes.string,
  balance: PropTypes.number,
  outstandingBill: PropTypes.number,
  handleButton: PropTypes.func,
  descNowPaymentTerm: PropTypes.string,
  priceNowPaymentTerm: PropTypes.number,
  totalPaid: PropTypes.number,
  amountToPay: PropTypes.number,
  handleChange: PropTypes.func,
  currencyCompany: PropTypes.string,
};
export default PayMethod;
