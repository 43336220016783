import React, { Component } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import TourOperatorPanel from '../TourOperatorPanel';
import { withTranslation } from 'react-i18next';

class TourOperatorSegment extends Component {
  handleClick = () => {
    let { data, setTourOperator, closeModal } = this.props;
    setTourOperator(data.TourProfileId);
    closeModal();
  };
  render() {
    const { t } = this.props;
    let { data, isFixPrice } = this.props;
    let profile = data.TourOperatorProfile;
    return (
      <Segment style={styles.segment}>
        <TourOperatorPanel
          data={data}
          profile={profile}
          isFixPrice={isFixPrice}
        />
        <div style={styles.textFight}>
          <Button style={styles.backgroundGold} onClick={this.handleClick}>
            {/* Pick */}
            {t('pick')}
          </Button>
        </div>
      </Segment>
    );
  }
}

TourOperatorSegment.propTypes = {
  ImageUrl: PropTypes.string,
  data: PropTypes.object,
  setTourOperator: PropTypes.func,
  closeModal: PropTypes.func,
  isFixPrice: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(TourOperatorSegment);
