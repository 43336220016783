import {
  POST_REGISTER_COMPANY_USER,
  RESET_REGISTER_COMPANY_USER_STATUS,
  GET_USER_COMPANY_LIST,
} from './constant/actionTypes';
import {
  postRegisterCompanyUserApi,
  getUserCompanyListApi,
} from '../../api/companyApi';

const postRegisterCompanyUser = data => {
  return dispatch => {
    return dispatch({
      type: POST_REGISTER_COMPANY_USER,
      payload: postRegisterCompanyUserApi(data),
    });
  };
};
const resetRegisterCompanyUserStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_REGISTER_COMPANY_USER_STATUS });
  };
};
const getUserCompanyList = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_COMPANY_LIST,
      payload: getUserCompanyListApi(),
    });
  };
};
export {
  postRegisterCompanyUser,
  resetRegisterCompanyUserStatus,
  getUserCompanyList,
};
