import { GOLD_COLOR } from '../../../../../constant/colour';

const style = {
  textCenter: { textAlign: 'center' },
  timeLineIconClock: {
    width: '70px',
    height: '20px',
    left: '6%',
    marginLeft: '-10px',
    marginTop: '27px', //'35px',
    background: GOLD_COLOR,
    borderRadius: '10px',
    boxShadow: '0 0 0 transparent',
  },
  timeLineAddActivityIcon: {
    width: '40px',
    height: '40px',
    left: '8.5%',
    marginLeft: '-10px',
    marginTop: '7px', //'20px',
    background: GOLD_COLOR,
    boxShadow: '0 0 0 transparent',
  },
  iconPlusStyle: { marginTop: '10px', marginLeft: '11px' },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  timeLineIcon: {
    width: '20px',
    height: '20px',
    left: '10%',
    marginLeft: '-10px',
    marginTop: '20px',
    background: GOLD_COLOR,
  },
  noMarginBot: { marginBottom: 0 },
  boxTransparent: { boxShadow: '0 0 0 transparent', padding: '5px' },
  marginTopMinFive: { marginTop: '-5px' },
  noPadding: { padding: 0 },
  noMargin: { margin: 0 },
  tourGuideStyle: { margin: 0, paddingTop: 0, paddingBottom: 0 },
  noDisplay: { display: 'none' },
  addActivitiesStyle: {
    background: GOLD_COLOR,
    zIndex: 1,
  },
  boxShadowNone: { boxShadow: 'none' },
  timeLineSeparator: {
    width: '100px',
    height: '30px',
    left: '8.5%',
    marginLeft: '-40px',
    marginTop: '17px',
    background: '#e0e5f9',
    boxShadow: '0 0 0 transparent',
    borderRadius: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    textAlign: 'center',
  },
  separatorStyle: {
    marginBottom: '17px',
    marginTop: '20px',
    marginLeft: '14px',
    borderBottom: '2px dashed #6b82e6',
  },
};

export default style;
