import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  getHourAndMinute,
  convertDateFormat,
} from '../../../../../genericFunction/moment';
import styles from './styles';
import Loader from '../../../../../components/common/loader';
import ItemWithIcon from '../../../../../components/common/ItemWithIcon';
import { withTranslation } from 'react-i18next';
class ArrivalDepartureCard extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.selectedDate !== this.props.selectedDate) {
      return true;
    } else return true;
  }

  render() {
    const { t } = this.props;
    const { data, loading, type } = this.props;
    const arrivalDepartureDate = data.Date;
    const arrivalDepartureTime = getHourAndMinute(data.Date);
    const label = type === 'arrival' ? 'Arrival' : 'Departure';
    // t('arrival') : t('departure'); //
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field width={16}>
                    <ItemWithIcon
                      // labelText={`${label} Date`}
                      labelText={`${
                        label === 'Arrival'
                          ? t('arrivalDate')
                          : t('departureDate')
                      }`}
                      labelStyle={styles.labelItemStyle}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={convertDateFormat(
                        arrivalDepartureDate,
                        'DD/MM/YYYY'
                      )}
                      iconName="calendar"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    <ItemWithIcon
                      // labelText={`${label} Time`}
                      labelText={`${
                        label === 'Arrival'
                          ? t('arrivalTime')
                          : t('departureTime')
                      }`}
                      labelStyle={styles.labelItemStyle}
                      withIcon={true}
                      iconStyle={styles.goldColor}
                      descriptionText={arrivalDepartureTime}
                      iconName="clock"
                    />
                  </Form.Field>
                  <Form.Field width={16}>
                    {type === 'arrival' || (
                      <ItemWithIcon
                        labelText={t('flightNumber')} //"Flight Number"
                        labelStyle={styles.labelItemStyle}
                        withIcon={false}
                        descriptionText={data.Note ? data.Note : '-'}
                      />
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Field width={16}>
                  <ItemWithIcon
                    // labelText={`${label} Destination`}
                    labelText={`${
                      label === 'Arrival'
                        ? t('arrivalDestination')
                        : t('departureDestination')
                    }`}
                    labelStyle={styles.labelItemStyle}
                    withIcon={false}
                    descriptionText={data.Place ? data.Place : '-'}
                  />
                </Form.Field>
                <Form.Field width={16}>
                  {/* {type === 'arrival' || ( */}
                  {/* <ItemWithIcon
                    labelText="Flight Number"
                    labelStyle={styles.labelItemStyle}
                    withIcon={false}
                    descriptionText={data.Note ? data.Note : '-'}
                  /> */}
                  {/* )} */}
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Loader active={loading} inverted={true} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    HeadlineProgram: state.customPackage.HeadlineProgram,
    loading: state.location.loading,
    t: PropTypes.func,
  };
};
ArrivalDepartureCard.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string,
  data: PropTypes.object,
  HeadlineProgram: PropTypes.object,
  loading: PropTypes.bool,
  selectedDate: PropTypes.string,
  t: PropTypes.func,
};
export default connect(mapStateToProps)(
  withTranslation()(ArrivalDepartureCard)
);
