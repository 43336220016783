const styles = {
  segmentHeader: {
    flex: 'none',
    borderColor: '#f7f9fb00',
    paddingBottom: '0',
    paddingLeft: '0',
  },
  bgTransparant: {
    background: '#f7f9fb00',
    borderColor: '#f7f9fb00',
    border: '0px',
    boxShadow: '0 1px 2px 0 rgba(34, 36, 38, 0)',
  },
  lineHeightHeader: {
    lineHeight: 1.6,
  },
};
const bannerBackground = image => {
  return {
    background: 'url(' + image + ')',
    backgroundSize: 'cover',
  };
};
export { styles, bannerBackground };
