const setCurrentPackage = type => {
  switch (type) {
    case 'Fixed':
      return 'FIXED';
    case 'Ready':
      return 'READY';
    case 'Custom':
      return 'CUSTOM';
    default:
      return 'CUSTOM';
  }
};

export default setCurrentPackage;
