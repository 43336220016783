import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, Icon, Form } from 'semantic-ui-react';
import { timeDuration } from '../../genericFunction/moment';
import styles from './styles';

class Duration extends React.PureComponent {
  handleDecrease = () => {
    let { min, onChange, value } = this.props;
    let limit = min ? min : 0;
    value = value - 900;
    let newValue = value < limit ? limit : value;
    onChange && onChange(newValue);
  };
  handleIncrease = () => {
    let { onChange, value } = this.props;
    value = value + 900;
    onChange && onChange(value);
  };
  render() {
    let { label, labelStyle } = this.props;
    let value = this.props.value ? this.props.value : 0;
    return (
      <Form.Field>
        <label style={labelStyle}>{label}</label>
        <Input
          labelPosition="right"
          fluid
          style={styles.lableDurationPlus}
          type="text"
        >
          <Label basic style={styles.labelAmount} onClick={this.handleDecrease}>
            <Icon style={styles.noMargin} name="minus" color="red" />
          </Label>
          <input
            style={styles.textCenter}
            readOnly
            value={timeDuration(value)}
          />
          <Label
            basic
            style={styles.labelAmountPlus}
            onClick={this.handleIncrease}
          >
            <Icon style={styles.noMargin} name="plus" color="green" />
          </Label>
        </Input>
      </Form.Field>
    );
  }
}
Duration.propTypes = {
  min: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  labelStyle: PropTypes.object,
};
export default Duration;
