const style = {
  pointer: {
    pointer: 'cursor',
  },
  imageSize: {
    width: '280px',
    height: '350px',
  },
  headerStyle: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '900',
  },
  marginLeftRight20: { marginLeft: '20px', marginRight: '20px' },
};

export default style;
