import apiClient from './apiClient';
import {
  GET_USER_INFO_URL,
  GET_USER_ROLES_URL,
  GET_USER_PROFILE_BY_COMPANY_CODE_URL,
  GET_USER_PROFILE_FOR_SALES_BY_ID_URL,
} from './apiUrl';

const getUserInfoApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_USER_INFO_URL);
};

const getUserRolesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_USER_ROLES_URL);
};

const getUserProfileByCompanyCodeApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    `${GET_USER_PROFILE_BY_COMPANY_CODE_URL}?companyCode=${id}`
  );
};

const getUserProfileByCompanyCodeForSalesApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_USER_PROFILE_FOR_SALES_BY_ID_URL}${id}`);
};

export {
  getUserInfoApi,
  getUserRolesApi,
  getUserProfileByCompanyCodeApi,
  getUserProfileByCompanyCodeForSalesApi,
};
