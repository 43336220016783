import React, { Component } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { TabMenu, CompanyDetail, UserInfo } from './myCompany/';
import PropTypes from 'prop-types';
import styles from './../styles';
class MyCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 'company detail',
    };
  }

  handleTab = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  render() {
    let { tabActive } = this.state;
    let {
      handleModalResetPasswordUser,
      isAdminCustomer,
      handleChangeCompany,
      handleChangeImage,
      handleUpdateCompany,
      optionCountries,
      handleUpdateImage,
    } = this.props;
    return (
      <div>
        <Segment style={styles.segment}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <TabMenu
                  tabActive={tabActive}
                  handleTab={this.handleTab}
                  isAdminCustomer={isAdminCustomer}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {tabActive === 'company detail' ? (
                  <CompanyDetail
                    companyProfile={this.props.companyProfile}
                    isAdminCustomer={isAdminCustomer}
                    handleChangeCompany={handleChangeCompany}
                    handleChangeImage={handleChangeImage}
                    handleUpdateCompany={handleUpdateCompany}
                    optionCountries={optionCountries}
                    handleUpdateImage={handleUpdateImage}
                  />
                ) : (
                  <UserInfo
                    userList={this.props.userList}
                    handleCreateNewUser={this.props.handleCreateNewUser}
                    handleChange={this.props.handleChange}
                    companyUserState={this.props.companyUserState}
                    handleModalResetPasswordUser={handleModalResetPasswordUser}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}
MyCompany.propTypes = {
  companyProfile: PropTypes.object,
  userList: PropTypes.array,
  handleCreateNewUser: PropTypes.func,
  handleChange: PropTypes.func,
  companyUserState: PropTypes.object,
  handleModalResetPasswordUser: PropTypes.func,
  isAdminCustomer: PropTypes.bool,
  handleChangeCompany: PropTypes.func,
  handleChangeImage: PropTypes.func,
  handleUpdateCompany: PropTypes.func,
  optionCountries: PropTypes.array,
  handleUpdateImage: PropTypes.func,
};
export default MyCompany;
