import {
  // GET_TRANSACTION_HISTORY_ALL,
  POST_TOUR_TRANSACTION_CONFIRMATION,
  GET_TOUR_TRANSACTION_SUMMARY,
  RESET_STATUS_TOUR_TRANSACTION_CONFIRMATION,
  RESET_STATUS_TRANSACTION_HISTORY_ALL,
  RESET_STATUS_TOUR_TRANSACTION_SUMMARY,
  SELECT_TOUR_TRANSACTION_SUMMARY,
  RESET_SELECT_TOUR_TRANSACTION_SUMMARY,
  GET_SCHEDULE_TOUR_TRANSACTION,
  GET_INVOICE,
  RESET_STATUS_INVOICE_AND_SCHEDULE,
  GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID,
  GET_TOUR_TRANSACTION_TO_BE_PAYED,
  GET_INVOICE_TRAVEL_AGENT,
  GET_INVOICE_CUSTOMER,
  GET_VOUCHER_CONFIRMATION,
  GET_TOUR_TRANSACTION_BILLS,
  //========================
  GET_TRANSACTION_HISTORY_CONFIRMED,
  GET_TRANSACTION_HISTORY_INPAYMENT,
  GET_TRANSACTION_HISTORY_CREATED,
  GET_TRANSACTION_HISTORY_COMPLETED,
  GET_TRANSACTION_HISTORY_CANCELLED,
  GET_TRANSACTION_HISTORY_DRAFT,
} from './actionTypes';
import {
  getTransactionHistoryAllApi,
  postTourTransactionConfirmationApi,
  getTourTransactionSummaryApi,
  getScheduleTourTransactionApi,
  getInvoiceApi,
  getSuplementItemByTransactionIdApi,
  getTourTransactionToBePayedApi,
  getInvoiceTravelAgentApi,
  getInvoiceCustomerApi,
  getVoucherConfirmationApi,
  getTourTransactionBillsApi,
} from '../../../api/bookingApi';

//=============helper function============
const getTourTransactionActionType = status => {
  switch (status) {
    case 'Booking_created':
      return GET_TRANSACTION_HISTORY_CREATED;
    case 'settle':
      return GET_TRANSACTION_HISTORY_INPAYMENT;
    case 'Done':
      return GET_TRANSACTION_HISTORY_CONFIRMED;
    case 'Booking_cancelled':
      return GET_TRANSACTION_HISTORY_CANCELLED;
    case 'Booking_completed':
      return GET_TRANSACTION_HISTORY_COMPLETED;
    case 'Booking_draft':
      return GET_TRANSACTION_HISTORY_DRAFT;
    default:
      return '';
  }
};

const getTransactionHistoryAll = status => {
  return dispatch => {
    return dispatch({
      type: getTourTransactionActionType(status), //GET_TRANSACTION_HISTORY_ALL,
      payload: getTransactionHistoryAllApi(status),
      status: status,
    });
  };
};
const postTourTransactionConfirmation = data => {
  return dispatch => {
    return dispatch({
      type: POST_TOUR_TRANSACTION_CONFIRMATION,
      payload: postTourTransactionConfirmationApi(data),
    });
  };
};
const getTourTransactionSummary = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TRANSACTION_SUMMARY,
      payload: getTourTransactionSummaryApi(id),
    });
  };
};
const getScheduleTourTransaction = id => {
  return dispatch => {
    return dispatch({
      type: GET_SCHEDULE_TOUR_TRANSACTION,
      payload: getScheduleTourTransactionApi(id),
    });
  };
};
const getInvoice = id => {
  return dispatch => {
    return dispatch({
      type: GET_INVOICE,
      payload: getInvoiceApi(id),
    });
  };
};
const resetStatusTourTransactionConfirm = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TOUR_TRANSACTION_CONFIRMATION,
    });
  };
};
const resetStatusTransactionHistoryAll = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TRANSACTION_HISTORY_ALL,
    });
  };
};
const selectTourTransactionSummary = data => {
  return dispatch => {
    return dispatch({ type: SELECT_TOUR_TRANSACTION_SUMMARY, payload: data });
  };
};
const getSuplementItemByTransactionId = id => {
  return dispatch => {
    return dispatch({
      type: GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID,
      payload: getSuplementItemByTransactionIdApi(id),
    });
  };
};
const getTourTransactionToBePayed = id => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TRANSACTION_TO_BE_PAYED,
      payload: getTourTransactionToBePayedApi(id),
    });
  };
};
const resetStatusTourTransactionSummary = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TOUR_TRANSACTION_SUMMARY,
    });
  };
};
const resetSelectTourTransactionSummary = () => {
  return dispatch => {
    return dispatch({
      type: RESET_SELECT_TOUR_TRANSACTION_SUMMARY,
    });
  };
};
const resetStatusGetInvoiceSchedule = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_INVOICE_AND_SCHEDULE,
    });
  };
};

const getInvoiceTravelAgent = id => {
  return dispatch => {
    return dispatch({
      type: GET_INVOICE_TRAVEL_AGENT,
      payload: getInvoiceTravelAgentApi(id),
    });
  };
};
const getInvoiceCustomer = id => {
  return dispatch => {
    return dispatch({
      type: GET_INVOICE_CUSTOMER,
      payload: getInvoiceCustomerApi(id),
    });
  };
};
const getVoucherConfirmation = id => {
  return dispatch => {
    return dispatch({
      type: GET_VOUCHER_CONFIRMATION,
      payload: getVoucherConfirmationApi(id),
    });
  };
};

const getTourTransactionBills = transactionId => {
  return dispatch => {
    return dispatch({
      type: GET_TOUR_TRANSACTION_BILLS,
      payload: getTourTransactionBillsApi(transactionId),
    });
  };
};

export {
  getTransactionHistoryAll,
  postTourTransactionConfirmation,
  getTourTransactionSummary,
  resetStatusTourTransactionConfirm,
  resetStatusTransactionHistoryAll,
  selectTourTransactionSummary,
  resetStatusTourTransactionSummary,
  resetSelectTourTransactionSummary,
  getScheduleTourTransaction,
  getInvoice,
  resetStatusGetInvoiceSchedule,
  getSuplementItemByTransactionId,
  getTourTransactionToBePayed,
  getInvoiceTravelAgent,
  getInvoiceCustomer,
  getVoucherConfirmation,
  getTourTransactionBills,
};
