import React from 'react';
import { Grid, Header, Segment, Icon, Button } from 'semantic-ui-react';
import styles from './../../styles';
import ShowSummaryItinenary from './SummaryItinerary';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CardSummaryItinerary = props => {
  const { t } = useTranslation();
  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Segment style={styles.segment} loading={props.loadingSeeDetail}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} style={styles.margin10px0px5px0px}>
                <Header as="h2" className="no-margin">
                  <Icon
                    className="fas fa-clipboard-list fa-2x"
                    style={styles.goldColor}
                  />{' '}
                  {/* Itinerary Summary */}
                  {t('itinerarySummary')}
                </Header>
              </Grid.Column>
              <Grid.Column width={4} floated="right">
                <Button
                  style={styles.buttonGoldMargin10px}
                  floated="right"
                  name="openModalItinerary"
                  onClick={props.handleButton}
                >
                  {/* Itinerary Detail */}
                  {t('itineraryDetail')}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <ShowSummaryItinenary
                departure={props.departure}
                returns={props.returns}
                dataSummaryItinerary={props.dataSummaryItinerary}
              />
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

CardSummaryItinerary.propTypes = {
  departure: PropTypes.array,
  returns: PropTypes.array,
  dataSummaryItinerary: PropTypes.array,
  handleButton: PropTypes.func,
  loadingSeeDetail: PropTypes.bool,
};
export default CardSummaryItinerary;
