import { copyObject } from './dailyProgram';
import { convertDateFormat, SumSecond, getNumberOfSecond } from './timeHelper';
import { getCities } from '../tourGuide';
const object = {
  Title: null,
  AdultPaxQty: null,
  ChildPaxQty: null,
  InfantPaxQty: null,
  RoomAllocation: {
    SharingRoomQty: null,
    SingleRoomQty: null,
    ExtraBedQty: null,
    ChildExtraBedQty: null,
    ChildSharingRoomQty: null,
    ChildSingleRoomQty: null,
    SharingBedQty: null,
    BabyCrib: null,
    NoBed: null,
  },
  FoC: {
    Qty: null,
    Description: '',
  },
  StartDate: null,
  EndDate: null,
  CityDestinationId: null,
  RegionDestinationId: null,
  TourCategoryId: null,
  TourPaxTypeId: null,
  GroupType: null,
  Attractions: null,
  Transportations: null,
  Accommodations: null,
  Movements: null,
  Guests: null,
  Restaurants: null,
  TourNote: null,
  // TourGuides: null,
  AdditionalServices: null,
};
const accommodation = {
  AccommodationItemServiceType: null,
  DateTime: null,
  EndTime: null,
  ServiceItemId: null,
  SharingRoomQty: null,
  SingleRoomQty: null,
  ExtraBedQty: null,
  ChildExtraBedQty: null,
  ChildSharingRoomQty: null,
  ChildSingleRoomQty: null,
  SharingBedQty: null,
  BabyCrib: null,
  NoBed: null,
};
const objMovement = {
  DateTime: null,
  ServiceItemId: null,
  CityId: null,
  Duration: null,
  SeqNumber: null,
  ServiceItemRefId: null,
  PlaceId: null,
  Note: '',
  PartOfAnotherDate: null,
};
const setPax = (obj, guests) => {
  obj.AdultPaxQty = guests && guests.adults;
  obj.ChildPaxQty = guests && guests.childs;
  obj.InfantPaxQty = guests && guests.infants;
  return obj;
};
const setRoomAllocation = (obj, rooms) => {
  obj.RoomAllocation = rooms;
  return obj;
};
const setGeneralInfo = (
  obj,
  tourName,
  groupCapacity,
  firstDestination,
  tourType,
  tourCategory,
  tourNote
) => {
  obj.Title = tourName;
  obj.CityDestinationId = firstDestination;
  obj.TourCategoryId = tourType;
  obj.TourPaxTypeId = tourCategory;
  obj.GroupType = groupCapacity;
  obj.TourNote = tourNote;
  return obj;
};
const setTourNote = (obj, tourNote) => {
  obj.TourNote = tourNote;
  return obj;
};
const setStartEndTime = (obj, start, end) => {
  obj.StartDate = start;
  obj.EndDate = end;
  return obj;
};
const setAccommodation = (obj, summaryProgram) => {
  let array = summaryProgram.reduce((result, current) => {
    if (current.AccommodationSummary) {
      let room = current.AccommodationSummary.Allocations;
      let newObj = copyObject(accommodation);
      newObj.AccommodationItemServiceType =
        current.AccommodationSummary.RoomService;
      newObj.DateTime = current.AccommodationSummary.CheckIn;
      newObj.EndTime = current.AccommodationSummary.CheckOut;
      newObj.ServiceItemId = current.AccommodationSummary.ServiceItemId;
      newObj.SharingRoomQty = room.SharingRoomPax;
      newObj.SingleRoomQty = room.SingleRoomPax;
      newObj.ExtraBedQty = room.ExtraBedPax;
      newObj.ChildExtraBedQty = room.ChildExtraBedPax;
      newObj.ChildSharingRoomQty = room.ChildSharingRoomPax;
      newObj.ChildSingleRoomQty = room.ChildSingleRoomPax;
      newObj.SharingBedQty = room.SharingBedPax;
      newObj.BabyCrib = room.BabyCrib;
      newObj.NoBed = room.NoBedPax;
      result = result.concat(newObj);
    }
    return result;
  }, []);
  obj.RegionDestinationId = summaryProgram[0].Region;
  obj.Accommodations = array;
  return obj;
};
const setAttraction = (obj, dailyProgram) => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove) => {
      if (currentMove.MovementName === 'RECREATION') {
        const objAttr = {
          ServiceItemId: currentMove.Item.ServiceItemId,
          DateTime: currentMove.DateTime,
          Duration: currentMove.Duration,
          CityId: currentMove.Destination,
        };
        move = move.concat(objAttr);
      }
      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  obj.Attractions = array;
  return obj;
};
const setMovements = (obj, dailyProgram) => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove) => {
      let item = copyObject(objMovement);
      item.DateTime = currentMove.DateTime;
      item.ServiceItemId = currentMove.ServiceItemId;
      item.CityId = currentMove.Destination;
      item.Duration = currentMove.Duration;
      item.SeqNumber = currentMove.SeqNumber;
      item.ServiceItemRefId = currentMove.Item.ServiceItemId;
      item.PlaceId = currentMove.Item.PlaceId;
      item.Note = currentMove.Note;
      item.PartOfAnotherDate =
        convertDateFormat(current.Date, 'YYYY-MM-DD') !==
        convertDateFormat(currentMove.DateTime, 'YYYY-MM-DD')
          ? current.Date
          : null;
      move = move.concat(item);

      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  obj.Movements = array;
  return obj;
};
const setRestaurant = (obj, dailyProgram) => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove) => {
      if (currentMove.MovementName === 'EAT') {
        const item = {
          DateTime: currentMove.DateTime,
          ServiceItemId: currentMove.Item.ServiceItemId,
          CityId: currentMove.Destination,
        };
        move = move.concat(item);
      }
      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  obj.Restaurants = array;
  return obj;
};
const setGuestList = (obj, guestList) => {
  let arrays = guestList.reduce((result, current) => {
    if (
      current.CountryId !== '' &&
      current.FirstName !== '' &&
      current.IdentityNbr !== '' &&
      current.IdentityType !== '' &&
      current.LastName !== '' &&
      current.GuestTitle !== ''
    ) {
      result = result.concat(current);
    }
    return result;
  }, []);
  obj.Guests = arrays;
  return obj;
};
const setFoc = (obj, foc) => {
  obj.FoC = foc && foc.Qty ? foc : null;
  return obj;
};
const getDriving = dailyProgram => {
  let array = dailyProgram.reduce((result, current) => {
    let items = current.Movements.reduce((move, currentMove) => {
      if (currentMove.MovementName === 'DRIVING') {
        move = move.concat(currentMove);
      }
      return move;
    }, []);
    result = result.concat(items);
    return result;
  }, []);
  return array;
};
const setTransport = dailyProgram => {
  let drivings = getDriving(dailyProgram);
  let result = drivings.reduce((trans, car) => {
    if (car.Item.ServiceItemId) {
      let idx = trans.findIndex(
        itm =>
          itm.ServiceItemId === car.Item.ServiceItemId &&
          itm.CityId === car.Destination &&
          convertDateFormat(itm.DateTime, 'YYYY-MM-DD') ===
            convertDateFormat(car.DateTime, 'YYYY-MM-DD')
      );
      if (trans.length === 0 || idx === -1) {
        let objT = {
          CityId: car.Destination,
          DateTime: car.DateTime,
          EndTime: SumSecond(car.DateTime, car.Duration),
          Hours: car.Item.Hours,
          SeqNumber: car.SeqNumber,
          ServiceItemId: car.Item.ServiceItemId,
          TransportationItemServiceType: car.Item.ServiceType,
        };
        trans = trans.concat(objT);
      } else {
        trans[idx].SeqNumber = car.SeqNumber;
        trans[idx].EndTime = SumSecond(car.DateTime, car.Duration);
      }
    }
    return trans;
  }, []);
  return result;
};
const getOvertimeTransport = transport => {
  let maxDuration = transport.Hours * 3600;
  let currentDuration = getNumberOfSecond(
    transport.DateTime,
    transport.EndTime
  );
  let overtime = 0;
  if (maxDuration < currentDuration) {
    overtime = Math.ceil((currentDuration - maxDuration) / 3600);
  }
  return overtime;
};
const setTourGuide = (obj, dailyProgram) => {
  let tourGuides = dailyProgram.reduce((result, current) => {
    if (current.TourGuides) {
      let cities = getCities(current);
      current.TourGuides = current.TourGuides.map(itm => {
        let city = cities.find(item => item.CityId === itm.CityId);
        if (city) {
          itm.DateTime = city.DateTime;
          itm.EndTime = city.EndTime;
        }
        itm.LanguageId = itm.Language.Id;
        return itm;
      });
      result = result.concat(current.TourGuides);
    }
    return result;
  }, []);
  obj.TourGuides = tourGuides;
  return obj;
};
const setAdditionalServices = (obj, additionalServices) => {
  let newAdditionalService = additionalServices.reduce((res, cur) => {
    let obj = {
      Amount: cur.Amount,
      CurrencyId: cur.Currency,
      AdditionalServiceCountryId: cur.OriginalCountryId,
      IsMandatory: cur.IsMandatory,
      Quantity: cur.Quantity,
      IsInfantCount: cur.IsInfantCount,
    };
    res = res.concat(obj);
    return res;
  }, []);
  obj.AdditionalServices = newAdditionalService;
  return obj;
};
const generateDemoPrice = (
  guests,
  rooms,
  tourName,
  groupCapacity,
  firstDestination,
  tourType,
  tourCategory,
  headLine,
  dailyProgram,
  guestList,
  foc,
  tourNote,
  additionalServices,
  readyPackageId,
  packageType,
  tourTransactionSummary
) => {
  let demo = copyObject(object);
  demo = setPax(demo, guests);
  demo = setRoomAllocation(demo, rooms);
  demo = setFoc(demo, foc);
  demo = setGeneralInfo(
    demo,
    tourName,
    groupCapacity,
    firstDestination,
    tourType,
    tourCategory
  );
  demo = setTourNote(demo, tourNote);
  let start = headLine.Departures[0].Date;
  let end = headLine.Returns[headLine.Returns.length - 1].Date;
  let summaryProgram = headLine.MainPrograms;
  demo = setStartEndTime(demo, start, end);
  demo = setAccommodation(demo, summaryProgram);
  demo = setAttraction(demo, dailyProgram);
  demo = setMovements(demo, dailyProgram);
  demo = setRestaurant(demo, dailyProgram);
  demo = setGuestList(demo, guestList);
  demo = setTourGuide(demo, dailyProgram);
  demo.Transportations = setTransport(dailyProgram);
  demo = setAdditionalServices(demo, additionalServices);
  demo.ReadyPackageId =
    packageType === 'ready'
      ? readyPackageId.BookingDetailSum.Id
      : packageType === 'similar' || packageType === 'quotation'
      ? tourTransactionSummary.BookingDetailSum.BookingTemplateRef
      : 0;
  demo.IsReadyPackage =
    packageType === 'ready'
      ? true
      : packageType === 'similar'
      ? true
      : packageType === 'quotation'
      ? true
      : false;
  if (packageType === 'quotation') {
    demo.CompanyCode =
      tourTransactionSummary.BookingDetailSum.CreatedBy.CompanyCode;
  }
  return demo;
};
export { generateDemoPrice, getOvertimeTransport, setTransport };
