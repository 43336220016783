import React from 'react';
import { Grid, Header, Table } from 'semantic-ui-react';
import styles from '../styles';
import { convertDateFormat } from '../../../../../genericFunction/moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TablePaymentTermsSummary = props => {
  const { t } = useTranslation();
  return (
    <Grid.Column width={16} style={styles.backgroundWhiteWithPadding15px}>
      <Header as="h5" className="no-margin">
        {/* Payment Term */}
        {t('paymentTerms')}
      </Header>
      <Table style={styles.paddingRight10Left20} basic="very">
        <Table.Header>
          <Table.Row style={styles.noPadding}>
            <Table.HeaderCell style={styles.tableHeader}>
              {/* Description */}
              {t('description')}
            </Table.HeaderCell>
            <Table.HeaderCell style={styles.tableHeader}>
              {/* Percentage */}
              {t('percentage')}
            </Table.HeaderCell>
            <Table.HeaderCell style={styles.tableHeader}>
              {/* Expiry Date */}
              {t('expiryDate')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.paymentTerms.length !== 0 ? (
            props.paymentTerms.map(payment => (
              <Table.Row key={payment.Id}>
                <Table.Cell style={styles.tableBody}>
                  <strong>{payment.Description}</strong>
                </Table.Cell>
                <Table.Cell style={styles.tableBody}>
                  <strong>{payment.PaymentPercentage}%</strong>
                </Table.Cell>
                <Table.Cell style={styles.tableBody}>
                  <strong>
                    {convertDateFormat(payment.DueDate, 'DD MMM YYYY')}
                  </strong>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell style={styles.tableBody}>
                <strong>-</strong>
              </Table.Cell>
              <Table.Cell style={styles.tableBody}>
                <strong>-</strong>
              </Table.Cell>
              <Table.Cell style={styles.tableBody}>
                <strong>-</strong>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  );
};

TablePaymentTermsSummary.propTypes = {
  paymentTerms: PropTypes.array,
  t: PropTypes.func,
};
export default TablePaymentTermsSummary;
