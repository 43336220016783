import React from 'react';
import TimeLineDay from './TimeLineDay';
import PropTypes from 'prop-types';
import TimeLineCollapse from './TimeLineCollapse';
class TimeLineDaily extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
    };
  }
  handleCollapse = () => {
    let isCollapse = this.state.isCollapse;
    isCollapse = !isCollapse;
    this.setState({ ...this.state, isCollapse: isCollapse });
  };
  render() {
    const { isCollapse } = this.state;
    const {
      daily,
      day,
      openModalSetActivity,
      deleteActivity,
      openTransportationModal,
      deleteTransportItem,
      transportStore,
      currentPackage,
      setDailyProgram,
      dailyProgram,
      isStatic,
      isSelectDate,
      isVariableDate,
    } = this.props;
    return (
      <div>
        <TimeLineDay
          handleCollapse={this.handleCollapse}
          day={daily.Day}
          date={daily.Date}
          isCollapse={isCollapse}
          isSelectDate={isSelectDate}
          isVariableDate={isVariableDate}
        />
        <TimeLineCollapse
          day={day}
          movements={daily.Movements}
          isCollapse={isCollapse}
          openModalSetActivity={openModalSetActivity}
          deleteActivity={deleteActivity}
          openTransportationModal={openTransportationModal}
          deleteTransportItem={deleteTransportItem}
          transportStore={transportStore}
          currentPackage={currentPackage}
          dailyData={daily}
          setDailyProgram={setDailyProgram}
          dailyProgram={dailyProgram}
          isStatic={isStatic}
        />
      </div>
    );
  }
}
TimeLineDaily.propTypes = {
  daily: PropTypes.object,
  openModalSetActivity: PropTypes.func,
  day: PropTypes.number,
  deleteActivity: PropTypes.func,
  openTransportationModal: PropTypes.func,
  deleteTransportItem: PropTypes.func,
  transportStore: PropTypes.array,
  currentPackage: PropTypes.string,
  setDailyProgram: PropTypes.func,
  dailyProgram: PropTypes.array,
  isStatic: PropTypes.bool,
  isSelectDate: PropTypes.string,
  isVariableDate: PropTypes.bool,
};
export default TimeLineDaily;
