import React from 'react';
import { Visibility, Menu, Button, Header, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import { converNumberToK } from '../../../../genericFunction/numberConverter';
import { useTranslation } from 'react-i18next';

const StickyHeader = props => {
  const { t } = useTranslation();
  const {
    menuFixed,
    disable,
    detail,
    handleSummary,
    generalInfoRef,
    destinationsRef,
    itineraryRef,
    operatorRef,
    guestsRef,
    additionalInfoRef,
    handleScrollToElement,
    stickTopMenu,
    unStickTopMenu,
    confirmQuotation,
    packageType,
    roomPrices,
    handleItemClick,
    activeItem,
    isFixedPrice,
    // additionalInformationRef,
    additionalServiceRef,
  } = props;
  return (
    <Visibility
      onBottomPassed={stickTopMenu}
      onBottomVisible={unStickTopMenu}
      once={false}
      style={styles.zIndex1}
    >
      <Menu
        // borderless
        className={menuFixed ? 'fixed' : ''}
        //untuk sekarang karena belum ada additional services dan additional information
        //styles top menu akan dirubah seperti styles.readyMenuStyle. sebelum nya adalah styles.readyFixPriceMenuStyle
        // style={
        //   menuFixed
        //     ? isFixedPrice
        //       ? styles.readyFixPriceMenuStyle
        //       : styles.readyMenuStyle
        //     : styles.noDisplay // fixed={menuFixed && 'top'}
        // }
        style={
          menuFixed ? styles.readyMenuStyle : styles.noDisplay // fixed={menuFixed && 'top'}
        }
        // pointing
        secondary
      >
        <Menu.Item
          myref={generalInfoRef}
          onClick={handleScrollToElement}
          style={styles.marginleft2em}
          active={activeItem === 'generalInfo'}
        >
          <Button
            onClick={handleItemClick}
            style={
              activeItem === 'generalInfo'
                ? styles.navbarTopWhite
                : styles.navbarTopGold
            }
            name="generalInfo"
            content={t('generalInformation')} //"General Information"
          />
        </Menu.Item>
        <Menu.Item
          myref={destinationsRef}
          onClick={handleScrollToElement}
          active={activeItem === 'destinations'}
        >
          <Button
            onClick={handleItemClick}
            style={
              activeItem === 'destinations'
                ? styles.navbarTopWhite
                : styles.navbarTopGold
            }
            name="destinations"
            content={t('destinations')} //"Destinations"
          />
        </Menu.Item>
        <Menu.Item
          myref={itineraryRef}
          onClick={handleScrollToElement}
          active={activeItem === 'itinerary'}
        >
          <Button
            onClick={handleItemClick}
            style={
              activeItem === 'itinerary'
                ? styles.navbarTopWhite
                : styles.navbarTopGold
            }
            name="itinerary"
            content={t('tourItinerary')} //"Tour Itinerary"
          />
        </Menu.Item>
        <Menu.Item
          myref={additionalServiceRef}
          onClick={handleScrollToElement}
          active={activeItem === 'additionalService'}
        >
          <Button
            onClick={handleItemClick}
            style={
              activeItem === 'additionalService'
                ? styles.navbarTopWhite
                : styles.navbarTopGold
            }
            name="additionalService"
            content={t('additionalServices')} //"Additional Services"
          />
        </Menu.Item>
        <Menu.Item
          myref={operatorRef}
          onClick={handleScrollToElement}
          active={activeItem === 'operator'}
        >
          <Button
            onClick={handleItemClick}
            style={
              activeItem === 'operator'
                ? styles.navbarTopWhite
                : styles.navbarTopGold
            }
            name="operator"
            content={t('tourOperator')} //"Tour Operator"
          />
          {/* <strong style={styles.navbarTopGold}>Tour Operator</strong> */}
        </Menu.Item>
        <Menu.Item
          myref={guestsRef}
          onClick={handleScrollToElement}
          active={activeItem === 'guests'}
        >
          <Button
            onClick={handleItemClick}
            style={
              activeItem === 'guests'
                ? styles.navbarTopWhite
                : styles.navbarTopGold
            }
            name="guests"
            content={t('guestList')} //"Guest List"
          />
        </Menu.Item>

        <Menu.Item
          myref={additionalInfoRef}
          onClick={handleScrollToElement}
          active={activeItem === 'additionalInfo'}
        >
          <Button
            onClick={handleItemClick}
            style={
              activeItem === 'additionalInfo'
                ? styles.navbarTopWhite
                : styles.navbarTopGold
            }
            name="additionalInfo"
            content={t('additionalInformation')} //"Additional Information"
          />
        </Menu.Item>
        {disable ? null : packageType === 'quotation' ? (
          <Menu.Item
            onClick={() => confirmQuotation('save')}
            style={styles.paddingFive}
          >
            <Button style={styles.buttonBookNowStyle}>
              {/* Save Quotation */}
              {t('saveQuotation')}
            </Button>
          </Menu.Item>
        ) : null}
        {disable ? null : packageType === 'quotation' ? (
          <Menu.Item
            onClick={() => confirmQuotation('deal')}
            style={styles.paddingFive}
          >
            <Button style={styles.buttonBookNowStyle}>
              {/* Book Now */}
              {t('bookNow')}
            </Button>
          </Menu.Item>
        ) : null}
        {disable ? null : packageType !== 'quotation' ? (
          <Menu.Item onClick={handleSummary} style={styles.paddingFive}>
            <Button style={styles.buttonBookNowStyle}>
              {/* Book Now */}
              {t('bookNow')}
            </Button>
          </Menu.Item>
        ) : null}
        {isFixedPrice && (
          <Menu.Item style={styles.menuPrice}>
            <Grid>
              <Grid.Row style={styles.noPaddingBottom}>
                <Grid.Column>
                  <Header as="h5">
                    {/* {!disable ? 'Total Price :' : 'Starting From : '} */}
                    {!disable
                      ? `${t('totalPrice')} :`
                      : `${t('startingFrom')} :`}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={styles.noPaddingTop}>
                <Grid.Column>
                  <Header as="h5">
                    {!disable
                      ? detail
                        ? `${detail.CurrencyId} ${converNumberToK(
                            detail.TourTotalPrice
                          )}/${t('pax')}` //Pax
                        : '-'
                      : `${roomPrices ? roomPrices[0].Currency : ''} ${
                          roomPrices[0].Price
                            ? converNumberToK(roomPrices[0].Price)
                              ? converNumberToK(roomPrices[0].Price)
                              : '-'
                            : 0
                        }/${t('pax')}`}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        )}
      </Menu>
    </Visibility>
  );
};
StickyHeader.propTypes = {
  menuFixed: PropTypes.bool,
  disable: PropTypes.bool,
  detail: PropTypes.object,
  handleSummary: PropTypes.func,
  generalInfoRef: PropTypes.object,
  destinationsRef: PropTypes.object,
  itineraryRef: PropTypes.object,
  operatorRef: PropTypes.object,
  guestsRef: PropTypes.object,
  additionalInfoRef: PropTypes.object,
  handleScrollToElement: PropTypes.func,
  stickTopMenu: PropTypes.func,
  unStickTopMenu: PropTypes.func,
  confirmQuotation: PropTypes.func,
  packageType: PropTypes.string,
  roomPrices: PropTypes.object,
  activeItem: PropTypes.string,
  handleItemClick: PropTypes.func,
  isFixedPrice: PropTypes.bool,
  additionalInformationRef: PropTypes.object,
  additionalServiceRef: PropTypes.object,
  t: PropTypes.func,
};
export default StickyHeader;
