import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/WithLabel';
import LineDivider from '../../../../components/common/lineDivider';
import styles from './../../styles';
import { useTranslation } from 'react-i18next';

const ShortRegisterCompany = props => {
  const { t } = useTranslation();
  const {
    handleChange,
    companyName,
    companyCountry,
    companyTelephone,
    companyAddress,
    companyCurrency,
    countryOptions,
    currencyOptions,
    loading,
    validate,
  } = props;

  return (
    <div>
      <div>
        <h4 style={styles.h4}>
          <strong>
            {/* Travel Agent Data */} {t('travelAgentData')}
          </strong>
        </h4>
      </div>
      <LineDivider />
      <Form>
        <InputWithLabel
          label={t('companyName')} //"Company Name"
          placeholder={t('companyName')} //"Company Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyName.data}
          name="companyName"
          validate={validate}
        />
        <InputWithLabel
          label={t('country')} //"Country"
          placeholder={t('selectCountry')} //"Select Country"
          inline
          required
          options={countryOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyCountry.data}
          name="companyCountry"
          type="select"
          loading={loading}
          validate={validate}
        />
        <InputWithLabel
          label={t('companyTelephone')} //"Company Telephone"
          placeholder={t('companyTelephone')} //"Company Telephone"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyTelephone.data}
          name="companyTelephone"
          validate={validate}
          type="number"
        />
        <InputWithLabel
          label={t('companyAddress')} //"Company Address"
          placeholder={t('companyAddress')} //"Company Address"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyAddress.data}
          name="companyAddress"
          type="textArea"
          validate={validate}
        />
        <InputWithLabel
          label={t('basedCurrency')} //"Based Currency"
          placeholder={t('placeholderIdentityType')} //"Select Type"
          inline
          required
          options={currencyOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyCurrency.data}
          name="companyCurrency"
          type="select"
          loading={loading}
          validate={validate}
        />
      </Form>
    </div>
  );
};

ShortRegisterCompany.propTypes = {
  handleChange: PropTypes.func,
  companyName: PropTypes.string,
  companyCountry: PropTypes.string,
  companyTelephone: PropTypes.string,
  companyAddress: PropTypes.string,
  companyCurrency: PropTypes.string,
  countryOptions: PropTypes.array,
  currencyOptions: PropTypes.array,
  validate: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.object,
};

export default ShortRegisterCompany;
