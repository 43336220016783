import {
  GET_ACCOMMODATION_TYPES,
  GET_ACCOMMODATION_RATINGS,
  GET_ACCOMMODATION_LOCATIONS,
  GET_ACCOMMODATIONS,
  GET_ACCOMMODATION_ROOMS,
  GET_ACCOMMODATION_FACILITIES,
  RESET_STATUS_ACCOMMODATION,
  GET_ACCOMMODATION_PROFILE_BY_ID,
  RESET_GET_ACCOMMODATION_PROFILE_BY_ID,
  SELECT_ACCOMMODATION_PROFILE,
  RESET_SELECT_ACCOMMODATION_PROFILE,
  GET_ACCOMMODATION_FILTER_PARAMETER,
} from './actionTypes';
import {
  getAccommodationTypesApi,
  getAccommodationRatingsApi,
  getAccommodationLocationsApi,
  getAccommodationsApi,
  getAccommodationRoomsApi,
  getAccommodationFacilitiesApi,
  getAccommodationProfileByIdApi,
  getAccommodationFilterParameterApi,
} from '../../../api/accommodationApi';

const getAccommodationTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_TYPES,
      payload: getAccommodationTypesApi(),
    });
  };
};

const getAccommodationRatings = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_RATINGS,
      payload: getAccommodationRatingsApi(),
    });
  };
};

const getAccommodationLocations = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_LOCATIONS,
      payload: getAccommodationLocationsApi(),
    });
  };
};

const getAccommodations = (data, bodyReq) => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATIONS,
      payload: getAccommodationsApi(data, bodyReq),
    });
  };
};
const getAccommodationRooms = (data, bodyReq) => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_ROOMS,
      payload: getAccommodationRoomsApi(data, bodyReq),
    });
  };
};
const getAccommodationFacilities = () => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_FACILITIES,
      payload: getAccommodationFacilitiesApi(),
    });
  };
};
const resetStatusAccommodation = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_ACCOMMODATION,
    });
  };
};
const getAccommodationProfileById = id => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_PROFILE_BY_ID,
      payload: getAccommodationProfileByIdApi(id),
    });
  };
};
const resetGetAccommodationProfileById = () => {
  return dispatch => {
    return dispatch({ type: RESET_GET_ACCOMMODATION_PROFILE_BY_ID });
  };
};
const selectAccommodationProfile = data => {
  return dispatch => {
    return dispatch({ type: SELECT_ACCOMMODATION_PROFILE, payload: data });
  };
};

const resetSelectAccommodation = () => {
  return dispatch => {
    return dispatch({ type: RESET_SELECT_ACCOMMODATION_PROFILE });
  };
};

const getAccommodationFilterParameter = data => {
  return dispatch => {
    return dispatch({
      type: GET_ACCOMMODATION_FILTER_PARAMETER,
      payload: getAccommodationFilterParameterApi(data),
    });
  };
};
export {
  getAccommodationTypes,
  getAccommodationRatings,
  getAccommodationLocations,
  getAccommodations,
  getAccommodationRooms,
  getAccommodationFacilities,
  resetStatusAccommodation,
  getAccommodationProfileById,
  resetGetAccommodationProfileById,
  selectAccommodationProfile,
  resetSelectAccommodation,
  getAccommodationFilterParameter,
};
