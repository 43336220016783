import React, { Component } from 'react';
import { Button, Modal, Grid } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ModalAlert extends Component {
  render() {
    const { t } = this.props;
    const { openModal, size, close, message, isArrayContentAlert } = this.props;
    return (
      <Modal
        size={size ? size : 'mini'}
        open={openModal}
        onClose={close}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Grid columns="equal" style={styles.textCenter}>
            <Grid.Row>
              <Grid.Column>
                {isArrayContentAlert ? (
                  <ul>
                    {message.map((e, idx) => (
                      <li key={idx}>
                        <h3>{e}</h3>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <h2>{message}</h2>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  style={styles.backgroundGold}
                  content={t('ok')} //"Ok"
                  onClick={close}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
ModalAlert.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  message: PropTypes.string,
  isArrayContentAlert: PropTypes.bool,
  t: PropTypes.func,
};
export default withTranslation()(ModalAlert);
