const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  var newDate = new Date(result);
  return newDate;
};
const subtractDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  var newDate = new Date(result);
  return newDate;
};
export { addDays, subtractDays };
