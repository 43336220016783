import React, { Component } from 'react';
import { Grid, Button, Message, Header } from 'semantic-ui-react';
// import readXlsxFile from 'read-excel-file/node';
import readXlsxFile from 'read-excel-file';
import { templateGuest } from '../../genericFunction/readTemplate';
import styles from './styles';
import PropTypes from 'prop-types';
import { ModalAlert } from '../../pages/shared/components/modal';
import _ from 'lodash';
import ButtonUpload from './BtnUploadFile';
import { API_ROOT } from '../../config/envUrl';
import { GET_GUEST_LIST_TEMPLATE_URL } from '../../api/apiUrl';
import { withTranslation } from 'react-i18next';

class BtnUploadTemplate extends Component {
  state = {
    data: {},
    modalAlert: false,
    message: '',
  };

  convertExceltoJson = e => {
    const schema = templateGuest;
    this.setState({ name: e.target.files[0].name });
    readXlsxFile(e.target.files[0], { schema }).then(rows => {
      return this.setState({
        ...this.state,
        data: rows,
      });
    });
  };

  handleDownloadTemplate = () => {
    this.handleDownload();
  };
  handleDownload = () => {
    var link = document.createElement('a');
    link.href = `${API_ROOT}${GET_GUEST_LIST_TEMPLATE_URL}`;
    link.download = 'Guest Template.xlsx';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  handleUploadTemplate = () => {
    if (_.isEmpty(this.state.data)) {
      this.setState({
        ...this.state,
        modalAlert: true,
        message: 'Please choose file to upload!',
      });
    } else {
      this.props.uploadTemplate(this.state.data);
    }
  };
  closeModal = () => this.setState({ ...this.state, modalAlert: false });
  render() {
    const { t } = this.props;
    let { message, modalAlert, name } = this.state;
    return (
      <Grid.Column width={8} floated="right" textAlign="right">
        <Grid>
          <Grid.Row style={styles.noPadding}>
            <Grid.Column width={4} />
            <Grid.Column width={12}>
              {/* You can input guest manually or upload using file */}
              <span>{t('labelInputGuestManually')}: </span>
              <span
                style={styles.downloadTemplateStyle}
                onClick={this.handleDownloadTemplate}
              >
                {` ${t('downloadTemplate')}`}
                {/* Download Template */}
              </span>
              <Message style={styles.borderGoldBackgroundTransparentGold}>
                <Grid>
                  <Grid.Row style={styles.noPadding}>
                    <Grid.Column width={8}>
                      <Header as="h5" style={styles.fileLabelStyle}>
                        {name ? name : `${t('pleaseChooseFile')}`}
                        {/* Please Choose File */}
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <ButtonUpload
                        convertExceltoJson={this.convertExceltoJson}
                        label={`${t('chooseFile')}`} //"Choose File"
                        buttonStyle={styles.buttonChooseFileStyle}
                      />
                      <Button
                        onClick={this.handleUploadTemplate}
                        style={styles.buttonUploadStyle}
                      >
                        {/* Upload List */}
                        {t('uploadList')}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <Input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          onChange={this.convertExceltoJson}
        /> */}

        <ModalAlert
          openModal={modalAlert}
          size="mini"
          close={this.closeModal}
          message={message}
        />
      </Grid.Column>
    );
  }
}

BtnUploadTemplate.propTypes = {
  uploadTemplate: PropTypes.func,
  downloadTemplate: PropTypes.func,
  getGuestListTemplate: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(BtnUploadTemplate);
