import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/WithLabel';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const ForgotPasswordForm = props => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h4 style={styles.h4}>
          {/* Forgot Password */}
          <strong>{t('forgotPassword')}</strong>
        </h4>
        <span>
          {/* Please enter your email address, We will send you link to reset your
        password. */}
          {t('labelResetPassword')}
        </span>
      </div>
      <div>
        <Form style={styles.form}>
          <InputWithLabel
            label={props.label}
            name="email"
            handleChange={props.handleChange}
            value={props.value}
            inputStyles={styles.input}
          />
        </Form>
      </div>
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  label: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
};

export default ForgotPasswordForm;
