import React from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Label, Form } from 'semantic-ui-react';
import styles from './styles';

class Amount extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: 0 };
  }

  render() {
    const {
      value,
      labelStyle,
      label,
      name,
      objectname,
      handleOnChange,
      disabled,
      handleDecrease,
      handleIncrease,
      size,
    } = this.props;

    return (
      <Form.Field>
        <label style={labelStyle}>{label}</label>
        <Input
          labelPosition="right"
          placeholder="Amount"
          fluid
          style={styles.inputStyleAmount}
          type="number"
          min={0}
          value={value}
          name={name}
          objectname={objectname}
          onChange={disabled ? null : handleOnChange}
          disabled={disabled}
          size={size ? size : ''}
        >
          <Label
            basic
            style={styles.labelAmount}
            value={value}
            name={name}
            objectname={objectname}
            onClick={disabled ? null : handleDecrease}
            disabled={disabled}
          >
            <Icon style={styles.noMargin} name="minus" color="red" />
          </Label>
          <input style={styles.inputAmount} />
          <Label
            basic
            style={styles.labelAmountPlus}
            value={value}
            name={name}
            objectname={objectname}
            onClick={disabled ? null : handleIncrease}
            disabled={disabled}
          >
            <Icon style={styles.noMargin} name="plus" color="green" />
          </Label>
        </Input>
      </Form.Field>
    );
  }
}
Amount.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  handleIncrease: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleDecrease: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectname: PropTypes.string,
  disabled: PropTypes.bool,
  labelStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  size: PropTypes.string,
};
export default Amount;
