import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';
import {
  Panel,
  MyBooking,
  MyAccount,
  MyCompany,
  MyQuotation,
  ModalUserCompanyList,
  ModalResetPassword,
} from './components';
import { BreadCrumb } from '../shared/components/common';
import Loader from '../../components/common/loader';
import {
  postRegisterCompanyUser,
  resetRegisterCompanyUserStatus,
  getUserCompanyList,
} from './companyUserAction';
import { ModalAlert } from './../shared/components/modal';
import { getUserRoles } from '../shared/actions/userAction';
import {
  getCompanyProfile,
  resetUpdateCompanyStatus,
  resetCompanyProfileStatus,
  putUpdateCompany,
} from '../shared/actions/companyAction';
import { getCountries } from '../shared/actions/countriesAction';
import { connect } from 'react-redux';
import {
  postResetStaffPassword,
  putUpdateProfile,
  getUserProfile,
} from './accountAction';
import options from '../../genericFunction/selectOptionsConverter';
import breadCrumb from './constant/breadCrumb';
import styles from './styles';
import {
  setCompanyState,
  setUpdateCompanyData,
} from './services/data/setCompanyData';
import userRegisterField from './constant/fieldToValidate';
import handleValidation from './services/validation/fieldEmptyValidation';
import _ from 'lodash';
import { isHideQuotation } from '../../constant/hideFeature';

import { withTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

class Profile extends Component {
  constructor(props) {
    super(props);
    let { companyProfile } = this.props;
    this.state = {
      OpenModal: false,
      OpenModalUser: false,
      Message: '',
      companyProfileData: {
        Code: 0,
        CompanyName: '',
        CountryID: '',
        Country: [],
        Address: '',
        Email: '',
        Telephone: '',
        FaxNo: '',
        ImageUrl: '',
        ImageName: '',
        ImageUrlUpdate: '',
      },
      companyUserObject: {
        Address: '',
        CompanyCode: companyProfile.Code,
        ConfirmPassword: '',
        Email: '',
        Firstname: '',
        Lastname: '',
        Password: '',
        Role: 'Customer',
        Telephone: '',
        Username: '',
      },
      openModalResetPassUser: false,
      selectedUserResetPass: {},
      isSales: false,
      isAdminCustomer: false,
      modalfieldError: {},
    };
  }
  componentDidUpdate = () => {
    if (this.props.getCompanyProfileStatus === 'success') {
      this.setCompanyState();
      this.props.getUserCompanyList(this.props.companyProfile.Code);
      this.props.resetCompanyProfileStatus();
    }

    if (this.props.createCompanyUserStatus === 'success') {
      this.closeUserModal();
      this.props.resetRegisterCompanyUserStatus();
      this.open(this.props.t('newUserHasBeenCreated')); //'New user has been created'
      this.props.getUserCompanyList();
    } else if (this.props.createCompanyUserStatus === 'failed') {
      this.closeUserModal();
      this.props.resetRegisterCompanyUserStatus();
      this.open(this.props.errorCompanyUser.Message);
      this.props.getUserCompanyList();
    }
  };
  open = message => {
    this.setState({ OpenModal: true, Message: message });
  };
  close = () => this.setState({ OpenModal: false });
  openUserModal = () => {
    const { companyProfile } = this.props;
    this.setState({
      OpenModalUser: true,
      companyUserObject: {
        Address: '',
        CompanyCode: companyProfile.Code,
        ConfirmPassword: '',
        Email: '',
        Firstname: '',
        Lastname: '',
        Password: '',
        Role: 'Customer',
        Telephone: '',
        Username: '',
      },
    });
  };

  handleChangeImageCompany = e =>
    this.handleChangeImage('companyProfileData', e.target.files);
  // accountImageContent, accountImageName =>  untuk di update
  // accountImageFile => untuk di tampilkan
  handleChangeImage = (nameState, files) => {
    let newData = this.state[nameState];
    let ImageUrl = URL.createObjectURL(files[0]);
    let ImageName = files[0].name;
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      let imageInfo = {
        base64: reader.result,
      };
      newData = {
        ...newData,
        ImageUrl,
        ImageName,
        ImageUrlUpdate: imageInfo.base64,
      };
      this.setState({
        [nameState]: newData,
      });
    };
  };

  closeUserModal = () => this.setState({ OpenModalUser: false });

  setCompanyState = () => {
    let { companyProfile } = this.props;
    let stateCompany = setCompanyState(companyProfile);
    this.setState({
      companyProfileData: stateCompany,
    });
  };

  componentDidMount = () => {
    this.props.getUserProfile();
    this.props.getCompanyProfile();
    this.props.getCountries();
    this.props.getUserRoles().then(() => this.checkUser());
  };

  checkUser = () => {
    if (this.props.userRoles.length !== 0) {
      if (this.props.userRoles.Roles.some(e => e === 'Package Sales')) {
        this.setState({ ...this.state, isSales: true });
      }
      if (
        this.props.userRoles.Roles.some(
          e => ['Customer Admin', 'Package Sales Admin'].indexOf(e) !== -1
        )
      ) {
        this.setState({ ...this.state, isAdminCustomer: true });
      }
    }
  };

  static propTypes = {
    match: PropTypes.object,
    getUserProfile: PropTypes.func,
    getCompanyProfile: PropTypes.func,
    getCountries: PropTypes.func,
    userProfile: PropTypes.object,
    companyProfile: PropTypes.object,
    loadingUser: PropTypes.bool,
    loadingCompany: PropTypes.bool,
    loadingConfirmDelete: PropTypes.bool,
    updateCompanyStatus: PropTypes.string,
    resetUpdateCompanyStatus: PropTypes.func,
    getCompanyProfileStatus: PropTypes.string,
    resetCompanyProfileStatus: PropTypes.func,
    getUserCompanyList: PropTypes.func,
    postRegisterCompanyUser: PropTypes.func,
    createCompanyUserStatus: PropTypes.string,
    getUserRoles: PropTypes.func,
    userRoles: PropTypes.object,
    errorCompany: PropTypes.object,
    putUpdateCompany: PropTypes.func,
    putUpdateProfile: PropTypes.func,
    userCompanyList: PropTypes.array,
    resetRegisterCompanyUserStatus: PropTypes.func,
    postResetStaffPassword: PropTypes.func,
    loadingAccount: PropTypes.bool,
    optionCountries: PropTypes.array,
    loadingCompanyUser: PropTypes.bool,
    errorCompanyUser: PropTypes.object,
    t: PropTypes.func,
  };

  handleChangeCompanyUserData = (e, { name, value }) => {
    let company = this.state.companyUserObject;
    company = { ...company, [name]: value };

    const error = handleValidation(company, userRegisterField);
    this.setState({
      companyUserObject: company,
      modalfieldError: error,
    });
  };
  openCreateUserModal = () => {
    this.openUserModal();
  };
  handleCreateCompanyUser = () => {
    let company = this.state.companyUserObject;
    let { companyProfile } = this.props;
    company.CompanyCode = companyProfile.Code;
    const error = handleValidation(company, userRegisterField);
    const isError = !_.isEmpty(error);
    if (isError) {
      this.setState({ ...this.setState, modalfieldError: error });
    } else {
      this.props.postRegisterCompanyUser(company);
    }
  };
  handleModalResetPasswordUser = user => {
    this.setState({
      ...this.state,
      openModalResetPassUser: !this.state.openModalResetPassUser,
      selectedUserResetPass: user ? user : {},
    });
  };

  handleResetPasswordUser = data => {
    this.setState(
      {
        ...this.state,
        openModalResetPassUser: false,
      },
      () => {
        this.props
          .postResetStaffPassword(data)
          .then(() => this.open(this.props.t('resetPasswordSuccessful'))) //'Reset password is successful'
          .catch(() => this.open(this.props.t('failedResetPassword'))); //'Failed reset password'
      }
    );
  };

  handleOnChange = (e, { name, value, objectname }) => {
    let newData = this.state[objectname];
    newData = { ...newData, [name]: value };
    this.setState({
      [objectname]: newData,
    });
  };

  handleUpdateCompany = () => {
    const { companyProfileData } = this.state;
    this.updateCompany(companyProfileData, null, null);
  };
  updateCompany = (company, imageUrl, imageName) => {
    let data = setUpdateCompanyData(company, imageUrl, imageName);
    this.props
      .putUpdateCompany(company.Code, data)
      .then(async () => {
        await this.props.getCompanyProfile();
        this.open(this.props.t('companySuccessfullyUpdated')); //'Company successfully updated'
      })
      .catch(() => this.open(this.props.t('failedUpdateCompany'))); //'Failed update company'
  };

  render() {
    const { t } = this.props;
    let { tab } = this.props.match.params;
    let {
      userProfile,
      companyProfile,
      loadingUser,
      loadingCompany,
      loadingConfirmDelete,
      userCompanyList,
      loadingAccount,
      optionCountries,
      loadingCompanyUser,
    } = this.props;
    let {
      isSales,
      companyProfileData,
      isAdminCustomer,
      modalfieldError,
      companyUserObject,
    } = this.state;
    let loading =
      loadingUser ||
      loadingCompany ||
      loadingConfirmDelete ||
      loadingAccount ||
      loadingCompanyUser;
    return (
      <div style={styles.backgroundWhite}>
        <DynamicTitle
          title={
            tab === 'account'
              ? 'My Account'
              : tab === 'company'
              ? 'My Company'
              : tab === 'booking' && 'My Booking'
          }
        />
        <BreadCrumb sections={breadCrumb} />
        <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <Panel
                  firstName={userProfile.FirstName}
                  lastName={userProfile.LastName}
                  companyId={companyProfile.Code}
                  currency={
                    companyProfile.Currency ? companyProfile.Currency : ''
                  }
                  balance={companyProfile.Balance ? companyProfile.Balance : 0}
                  tabActive={tab}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                {tab === 'account' ? (
                  <MyAccount openModal={this.open} />
                ) : tab === 'company' ? (
                  <MyCompany
                    companyProfile={companyProfileData}
                    userList={userCompanyList}
                    handleCreateNewUser={this.openCreateUserModal}
                    handleCreateCompanyUser={this.handleCreateCompanyUser}
                    handleChange={this.handleChangeCompanyUserData}
                    handleModalResetPasswordUser={
                      this.handleModalResetPasswordUser
                    }
                    isAdminCustomer={isAdminCustomer}
                    handleChangeCompany={this.handleOnChange}
                    handleChangeImage={this.handleChangeImageCompany}
                    handleUpdateCompany={this.handleUpdateCompany}
                    optionCountries={optionCountries}
                    handleUpdateImage={this.updateCompany}
                  />
                ) : tab === 'booking' ? (
                  <MyBooking isSales={isSales} />
                ) : (
                  !isHideQuotation && <MyQuotation />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ModalUserCompanyList
            size="mini"
            name={t('addUser')} //"Add User"
            openModal={this.state.OpenModalUser}
            open={this.openUserModal}
            onClose={this.closeUserModal}
            companyProfile={companyProfileData}
            handleChange={this.handleChangeCompanyUserData}
            companyUserState={companyUserObject}
            handleCreateCompanyUser={this.handleCreateCompanyUser}
            validate={modalfieldError}
          />
          <ModalResetPassword
            size="mini"
            openModal={this.state.openModalResetPassUser}
            onClose={this.handleModalResetPasswordUser}
            user={this.state.selectedUserResetPass}
            handleResetPasswordUser={this.handleResetPasswordUser}
          />
        </Container>
        <Loader description="loading" active={loading} page={true} />
        <ModalAlert
          size="mini"
          openModal={this.state.OpenModal}
          open={this.open}
          close={this.close}
          message={this.state.Message}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  let optionCountries = state.countries.countries.map(country =>
    options(country.Id, country.Id, country.Name)
  );
  return {
    userProfile: state.account.userProfile,
    companyProfile: state.company.companyProfile,
    loadingUser: state.user.loading,
    loadingCompany: state.company.loading,
    loadingConfirmDelete: state.booking.loadingConfirm,
    getUserProfileStatus: state.account.getUserProfileStatus,
    updateCompanyStatus: state.company.updateCompanyStatus,
    getCompanyProfileStatus: state.company.getCompanyProfileStatus,
    userCompanyList: state.companyUser.userCompanyList,
    createCompanyUserStatus: state.companyUser.createCompanyUserStatus,
    userRoles: state.user.userRoles,
    loadingAccount: state.account.loading,
    errorCompany: state.company.errors,
    errorCompanyUser: state.companyUser.errors,
    loadingCompanyUser: state.companyUser.loading,
    optionCountries,
  };
};
export default connect(
  mapStateToProps,
  {
    getCountries,
    getUserProfile,
    getCompanyProfile,
    putUpdateProfile,
    resetUpdateCompanyStatus,
    resetCompanyProfileStatus,
    getUserCompanyList,
    postRegisterCompanyUser,
    resetRegisterCompanyUserStatus,
    getUserRoles,
    postResetStaffPassword,
    putUpdateCompany,
  }
)(withTranslation()(Profile));
