import React, { Component } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { TabMenu, AccountDetail, ChangePassword } from './myAccount/';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './../styles';
// import { getUserProfile } from '../../shared/actions/userAction';
import { getUserProfile } from './../accountAction';
import { putUpdateProfile } from '../accountAction';
import { userNoImage } from '../../../assets/image';
import { withTranslation } from 'react-i18next';
class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 'account detail',
      modalAlertOpen: false,
      modalAlertMessage: '',
      modalAlertHeader: '',
      profiles: {
        Username: '',
        Firstname: '',
        Lastname: '',
        Address: '',
        CountryID: '',
        Country: [],
        Gender: '',
        PhoneNbr: '',
        Email: '',
        ImageUrl: [],
        AccountManager: [],
      },
      accountImageFile: '',
    };
  }
  componentDidMount() {
    this.getProfile();
  }

  getProfile = () =>
    this.props
      .getUserProfile()
      .then(() => this.setAccountState())
      .catch(() => this.openModal(this.props.t('failedGetDataProfile'))); //'Failed Get Data Profile'

  setAccountState = () => {
    let { userProfile } = this.props;
    let country = [
      {
        key: userProfile.Country.Id,
        text: userProfile.Country.Name,
        value: userProfile.Country.Id,
      },
    ];
    let accountManager = {
      FirstName: userProfile.AccountManager.FirstName,
      LastName: userProfile.AccountManager.LastName,
      PhoneNbr: userProfile.AccountManager.PhoneNbr,
      Email: userProfile.AccountManager.Email,
      ImageUrl: userProfile.AccountManager.ImageUrl
        ? userProfile.AccountManager.ImageUrl
        : userNoImage,
    };

    let srcImage = userProfile.ImageUrl ? userProfile.ImageUrl : userNoImage;
    let stateProf = {
      Id: userProfile.Id,
      Username: userProfile.UserName,
      Firstname: userProfile.FirstName,
      Lastname: userProfile.LastName,
      PhoneNbr: userProfile.PhoneNbr,
      CountryID: userProfile.Country.Id,
      Country: country,
      Email: userProfile.Email,
      Gender: userProfile.Gender,
      ImageUrl: userProfile.ImageUrl ? userProfile.ImageUrl : userNoImage,
      Address: userProfile.Address,
      AccountManager: accountManager,
    };
    this.setState({
      profiles: stateProf,
      accountImageFile: srcImage,
    });
  };

  handleTab = (e, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleChange = (e, { name, value }) => {
    let profiles = this.state.profiles;
    profiles = { ...profiles, [name]: value };
    this.setState({
      profiles: profiles,
    });
  };
  handleUpdateProfile = () => {
    let { profiles, accountImageContent } = this.state;
    this.postUserProfile(profiles, '', accountImageContent);
  };
  postUserProfile = (profileData, imageContent, ImageName) => {
    let data = {
      Id: profileData.Id,
      FirstName: profileData.Firstname,
      LastName: profileData.Lastname,
      Address: profileData.Address,
      Email: profileData.Email,
      PhoneNbr: profileData.PhoneNbr,
      CountryId: profileData.CountryID,
      Gender: profileData.Gender,
      ImageName: ImageName,
      ImageContent: imageContent,
    };
    this.props
      .putUpdateProfile(profileData.Id, data)
      .then(async () => {
        // this.openModalAlert('Success', 'Success update profile');
        await this.getProfile();
        this.props.openModal(this.props.t('successUpdateProfile')); //'Success update profile'
      })
      .catch(() => this.props.openModal(this.props.t('failedUpdateProfile'))); //'Failed update profile'
  };
  openModalAlert = (header, message) =>
    this.setState({
      ...this.state,
      modalAlertOpen: true,
      modalAlertMessage: message,
      modalAlertHeader: header,
    });
  render() {
    let { tabActive, profiles, accountImageFile } = this.state;
    return (
      <div>
        <Segment style={styles.segment}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <TabMenu tabActive={tabActive} handleTab={this.handleTab} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {tabActive === 'account detail' ? (
                  <AccountDetail
                    profiles={profiles}
                    handleChange={this.handleChange}
                    handleUpdateProfile={this.handleUpdateProfile}
                    // onImageSelect={this.props.onImageSelect}
                    imgSrc={accountImageFile}
                    handleUpdateImage={this.postUserProfile}
                  />
                ) : (
                  <ChangePassword />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}
MyAccount.propTypes = {
  getUserProfile: PropTypes.func,
  userProfile: PropTypes.object,
  putUpdateProfile: PropTypes.func,
  openModal: PropTypes.func,
  t: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    userProfile: state.account.userProfile,
  };
};
export default connect(
  mapStateToProps,
  {
    getUserProfile,
    putUpdateProfile,
  }
)(withTranslation()(MyAccount));
