import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'semantic-ui-react';
import LineDivider from '../../../components/common/lineDivider';
import InputWithLabel from '../../../components/input/WithLabel';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const UserData = props => {
  const { t } = useTranslation();
  const {
    handleChange,
    userName,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
    userPassword,
    userConfirmPassword,
    onClickPassIcon,
    onClickConfPassIcon,
    passwordIcon,
    confirmPasswordIcon,
    passwordInputType,
    confirmPasswordInputType,
    validate,
  } = props;

  return (
    <div>
      <h4 style={styles.h4}>
        <strong>
          {/* User Data */} {t('userData')}
        </strong>
      </h4>
      <LineDivider />
      <Form>
        <InputWithLabel
          label={t('userName')} //"User Name"
          placeholder={t('userName')} //"User Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userName}
          name="userName"
          validate={validate}
        />
        <InputWithLabel
          label={t('firstName')} //"First Name"
          placeholder={t('firstName')} //"First Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userFirstName}
          name="userFirstName"
          validate={validate}
        />
        <InputWithLabel
          label={t('lastName')} //"Last Name"
          placeholder={t('lastName')} //"Last Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userLastName}
          name="userLastName"
          validate={validate}
        />
        <InputWithLabel
          label={t('email')} //"Email"
          placeholder={t('email')} //"Email"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userEmail}
          name="userEmail"
          validate={validate}
        />
        <InputWithLabel
          label={t('phoneNumber')} //"Phone Number"
          placeholder={t('phoneNumber')} //"Phone Number"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userPhoneNumber}
          name="userPhoneNumber"
          validate={validate}
          type="number"
        />
        <InputWithLabel
          label={t('password')} //"Password"
          placeholder={t('password')} //"Password"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userPassword}
          name="userPassword"
          type={passwordInputType}
          action={
            <Button
              name="password"
              icon={passwordIcon}
              onClick={onClickPassIcon}
            />
          }
          validate={validate}
        />
        <InputWithLabel
          label={t('confirmPassword')} //"Confirm Password"
          placeholder={t('confirmPassword')} //"confirm Password"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userConfirmPassword}
          name="userConfirmPassword"
          type={confirmPasswordInputType}
          action={
            <Button
              name="confirmPassword"
              icon={confirmPasswordIcon}
              onClick={onClickConfPassIcon}
            />
          }
          validate={validate}
        />
      </Form>
    </div>
  );
};

UserData.propTypes = {
  handleChange: PropTypes.func,
  userName: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userPhoneNumber: PropTypes.string,
  userEmail: PropTypes.string,
  userPassword: PropTypes.string,
  userConfirmPassword: PropTypes.string,
  confirmPasswordIcon: PropTypes.string,
  onClickPassIcon: PropTypes.func,
  onClickConfPassIcon: PropTypes.func,
  passwordIcon: PropTypes.string,
  passwordInputType: PropTypes.string,
  confirmPasswordInputType: PropTypes.string,
  validate: PropTypes.object,
};
export default UserData;
