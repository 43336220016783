import React from 'react';
import { Menu, Container, Button, Visibility } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import { converNumberToK } from '../../../../../genericFunction/numberConverter';
import { useTranslation } from 'react-i18next';

const StickyHeader = props => {
  const { t } = useTranslation();
  const {
    menuFixed,
    disable,
    detail,
    handleSummary,
    generalInfoRef,
    destinationsRef,
    itineraryRef,
    operatorRef,
    guestsRef,
    handleScrollToElement,
    stickTopMenu,
    unStickTopMenu,
    additionalServiceRef,
  } = props;
  return (
    <Visibility
      onBottomPassed={stickTopMenu}
      onBottomVisible={unStickTopMenu}
      once={false}
      style={styles.zIndex1}
    >
      <Menu
        borderless
        className={menuFixed ? 'fixed' : ''}
        style={
          menuFixed ? styles.fixedMenuStyle : styles.noDisplay // fixed={menuFixed && 'top'}
        }
      >
        <Container style={styles.menuContainer}>
          <Menu.Item myref={generalInfoRef} onClick={handleScrollToElement}>
            {/* General Information */}
            <strong style={styles.goldColor}>{t('generalInformation')} </strong>
          </Menu.Item>
          <Menu.Item myref={destinationsRef} onClick={handleScrollToElement}>
            {/* Destinations */}
            <strong style={styles.goldColor}>{t('destinations')}</strong>
          </Menu.Item>
          <Menu.Item myref={itineraryRef} onClick={handleScrollToElement}>
            {/* Tour Itinerary */}
            <strong style={styles.goldColor}>{t('tourItinerary')}</strong>
          </Menu.Item>
          <Menu.Item
            myref={additionalServiceRef}
            onClick={handleScrollToElement}
          >
            {/* Additional Service */}
            <strong style={styles.goldColor}>{t('additionalServices')}</strong>
          </Menu.Item>
          <Menu.Item myref={operatorRef} onClick={handleScrollToElement}>
            {/* Tour Operator */}
            <strong style={styles.goldColor}>{t('tourOperator')}</strong>
          </Menu.Item>
          <Menu.Item myref={guestsRef} onClick={handleScrollToElement}>
            {/* Guest List */}
            <strong style={styles.goldColor}>{t('guestList')}</strong>
          </Menu.Item>
          {disable || (
            <Menu.Item style={styles.menuPrice}>
              <strong>
                {detail
                  ? `${detail.CurrencyId +
                      ' ' +
                      converNumberToK(detail.TourTotalPrice)}`
                  : '-'}
              </strong>
            </Menu.Item>
          )}
          {disable || (
            <Menu.Item onClick={handleSummary} style={styles.paddingFive}>
              {/* Book Now */}
              <Button style={styles.buttonBookNowStyle}>{t('bookNow')} </Button>
            </Menu.Item>
          )}
        </Container>
      </Menu>
    </Visibility>
  );
};

StickyHeader.propTypes = {
  menuFixed: PropTypes.bool,
  disable: PropTypes.bool,
  detail: PropTypes.object,
  handleSummary: PropTypes.func,
  generalInfoRef: PropTypes.object,
  destinationsRef: PropTypes.object,
  itineraryRef: PropTypes.object,
  operatorRef: PropTypes.object,
  guestsRef: PropTypes.object,
  handleScrollToElement: PropTypes.func,
  stickTopMenu: PropTypes.func,
  unStickTopMenu: PropTypes.func,
  additionalServiceRef: PropTypes.object,
  t: PropTypes.func,
};
export default StickyHeader;
