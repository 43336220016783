import React, { Component } from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withTranslation } from 'react-i18next';

class SegmentItemPriceInformation extends Component {
  renderRoomPrices = () => {
    const { t } = this.props;
    return (
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={styles.paddingBottomZero}>
              {/* Room Type */}
              {t('roomType')}
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={styles.paddingBottomZero}
            >
              {/* Price */}
              {t('price')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.roomPrices.length > 0
            ? this.props.roomPrices.map(item => (
                <Table.Row key={item.Id}>
                  <Table.Cell style={styles.paddingBottomZero}>
                    {/* {item.Room} */}
                    {t(item.Room)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    style={styles.paddingBottomZero}
                  >
                    {item.Currency + ' ' + item.Price.toLocaleString('id')}
                  </Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div style={styles.segmentNoPadding}>
        <Header style={styles.headerGoldNoPadding} as="h5" attached="top">
          {/* Room Allocation Price */}
          {t('roomAllocationPrice')}
        </Header>
        <Segment attached>{this.renderRoomPrices()}</Segment>
      </div>
    );
  }
}
SegmentItemPriceInformation.propTypes = {
  paymentTerms: PropTypes.array,
  roomPrices: PropTypes.array,
  tourOperator: PropTypes.object,
  isVariableDate: PropTypes.bool,
  t: PropTypes.func,
};
export default withTranslation()(SegmentItemPriceInformation);
