import * as types from '../actions/actionTypes';

const initialState = {
  companyProfile: {},
  getCompanyProfileStatus: '',
  updateCompanyStatus: '',
  companies: [],
  getCompaniesStatus: '',
  errors: {},
  loading: false,
  loadingGetCompany: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_COMPANY_PROFILE
    //---------------------------------
    case types.GET_COMPANY_PROFILE:
      return { ...state };
    case types.GET_COMPANY_PROFILE_PENDING:
      return { ...state, loading: true, loadingGetCompany: true };
    case types.GET_COMPANY_PROFILE_FULFILLED:
      return {
        ...state,
        companyProfile: action.payload.data,
        errors: {},
        loading: false,
        loadingGetCompany: false,
        getCompanyProfileStatus: 'success',
      };
    case types.GET_COMPANY_PROFILE_REJECTED:
      return {
        ...state,
        companyProfile: {},
        errors: action.payload.response.data,
        loading: false,
        loadingGetCompany: false,
        getCompanyProfileStatus: 'failed',
      };
    //---------------------------------
    // PUT_UPDATE_COMPANY
    //---------------------------------
    case types.PUT_UPDATE_COMPANY:
      return { ...state };
    case types.PUT_UPDATE_COMPANY_PENDING:
      return { ...state, loading: true };
    case types.PUT_UPDATE_COMPANY_FULFILLED:
      return {
        ...state,
        errors: {},
        loading: false,
        updateCompanyStatus: 'success',
      };
    case types.PUT_UPDATE_COMPANY_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loading: false,
        updateCompanyStatus: 'failed',
      };

    case types.RESET_UPDATE_COMPANY_STATUS:
      return {
        ...state,
        updateCompanyStatus: '',
      };
    case types.RESET_COMPANY_PROFILE_STATUS:
      return {
        ...state,
        getCompanyProfileStatus: '',
      };
    //---------------------------------
    // GET_COMPANIES
    //---------------------------------
    case types.GET_COMPANIES:
      return { ...state };
    case types.GET_COMPANIES_PENDING:
      return { ...state, loading: true };
    case types.GET_COMPANIES_FULFILLED:
      return {
        ...state,
        errors: {},
        loading: false,
        companies: action.payload.data,
        getCompaniesStatus: 'success',
      };
    case types.GET_COMPANIES_REJECTED:
      return {
        ...state,
        companies: [],
        errors: action.payload.response.data,
        loading: false,
        getCompaniesStatus: 'failed',
      };
    default:
      return state;
  }
};

export default reducer;
