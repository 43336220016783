import apiClient from './apiClient';
import {
  GET_COMPANY_PROFILE_URL,
  PUT_UPDATE_COMPANY_URL,
  GET_USER_COMPANY_LIST_URL,
  POST_REGISTER_COMPANY_USER_URL,
  GET_COMPANIES_URL,
} from './apiUrl';

const getCompanyProfileApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_COMPANY_PROFILE_URL);
};

const putUpdateCompanyApi = (code, data) => {
  return apiClient.put(`${PUT_UPDATE_COMPANY_URL}?companyCode=${code}`, data);
};

const getUserCompanyListApi = () => {
  return apiClient.get(GET_USER_COMPANY_LIST_URL);
};
const postRegisterCompanyUserApi = data => {
  return apiClient.post(POST_REGISTER_COMPANY_USER_URL, data);
};
const getCompaniesApi = type => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_COMPANIES_URL}?type=${type}`);
};
export {
  getCompanyProfileApi,
  putUpdateCompanyApi,
  getUserCompanyListApi,
  postRegisterCompanyUserApi,
  getCompaniesApi,
};
