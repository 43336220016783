import * as types from '../actions/actionTypes';

const initialState = {
  totalBranch: [],
  totalEmployee: [],
  revenueRange: [],
  totalBranchLoading: false,
  totalEmployeeLoading: false,
  revenueRangeLoading: false,
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_TOTAL_BRANCH
    //---------------------------------
    case types.GET_TOTAL_BRANCH:
      return { ...state };
    case types.GET_TOTAL_BRANCH_PENDING:
      return { ...state, totalBranchLoading: true };
    case types.GET_TOTAL_BRANCH_FULFILLED:
      return {
        ...state,
        totalBranch: action.payload.data,
        totalBranchLoading: false,
      };
    //---------------------------------
    // GET_TOTAL_EMPLOYEE
    //---------------------------------
    case types.GET_TOTAL_EMPLOYEE:
      return { ...state };
    case types.GET_TOTAL_EMPLOYEE_PENDING:
      return { ...state, totalEmployeeLoading: true };
    case types.GET_TOTAL_EMPLOYEE_FULFILLED:
      return {
        ...state,
        totalEmployee: action.payload.data,
        totalEmployeeLoading: false,
      };
    //---------------------------------
    // GET_REVENUE_RANGE
    //---------------------------------
    case types.GET_REVENUE_RANGE:
      return { ...state };
    case types.GET_REVENUE_RANGE_PENDING:
      return { ...state, revenueRangeLoading: true };
    case types.GET_REVENUE_RANGE_FULFILLED:
      return {
        ...state,
        revenueRange: action.payload.data,
        revenueRangeLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
