import React from 'react';
import { Popup, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
const PopUpButton = props => {
  const {
    buttonStyle,
    name,
    value,
    handleClick,
    iconClassName,
    iconStyle,
    content,
    popupShowOn,
  } = props;
  return (
    <Popup
      trigger={
        <Button
          style={buttonStyle}
          name={name}
          value={value}
          onClick={handleClick}
          size="huge"
        >
          <Icon className={iconClassName} style={iconStyle} />
        </Button>
      }
      content={content}
      on={popupShowOn}
    />
  );
};
PopUpButton.propTypes = {
  buttonStyle: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  handleClick: PropTypes.func,
  iconClassName: PropTypes.string,
  iconStyle: PropTypes.object,
  content: PropTypes.string,
  popupShowOn: PropTypes.string,
};

export default PopUpButton;
