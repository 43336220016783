const setRoomAllocation = tourPriceSum => {
  const {
    ChildExtraBedSum,
    ChildSharingRoomSum,
    ChildSingleRoomSum,
    ExtraBedSum,
    NoBedSum,
    SharingBedSum,
    SharingRoomSum,
    SingleRoomSum,
  } = tourPriceSum;
  let rooms = {
    ChildExtraBedQty: ChildExtraBedSum.Pax + ChildExtraBedSum.FoCPax,
    ChildSharingRoomQty: ChildSharingRoomSum.Pax + ChildSharingRoomSum.FoCPax,
    ChildSingleRoomQty: ChildSingleRoomSum.Pax + ChildSingleRoomSum.FoCPax,
    ExtraBedQty: ExtraBedSum.Pax + ExtraBedSum.FoCPax,
    BabyCrib: 0,
    NoBed: NoBedSum.Pax + NoBedSum.FoCPax,
    SharingBedQty: SharingBedSum.Pax + SharingBedSum.FoCPax,
    SharingRoomQty: SharingRoomSum.Pax + SharingRoomSum.FoCPax,
    SingleRoomQty: SingleRoomSum.Pax + SingleRoomSum.FoCPax,
  };
  return rooms;
};

export default setRoomAllocation;
