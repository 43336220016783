//---library---
import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Icon, Grid, Button, Label } from 'semantic-ui-react';
//---component---
import ImageCard from '../../card/ImageCard';
import Facilities from '../../../../../components/common/facilities';
//---asset--
import onholdIcon from '../../../../../assets/icon/globalIcon/spinner.png';
import availableIcon from '../../../../../assets/icon/globalIcon/badge_check.png';
//---style--
import styles from './styles';
import { useTranslation } from 'react-i18next';

const Card = props => {
  const { t } = useTranslation();
  const openCloseModal = (e, { value }) => {
    props.handleOpenRooms(value);
  };

  let {
    images,
    image,
    isPromo,
    name,
    type,
    address,
    id,
    facilities,
    rating,
    data,
  } = props;
  let stars = new Array(parseInt(rating, 10)).fill(0);
  return (
    <div>
      <Segment.Group horizontal style={styles.segmentNoPadding}>
        <ImageCard
          images={images}
          image={image}
          isPromo={isPromo}
          size="300px"
        />
        <Segment style={styles.borderNone}>
          <Header as="h3" style={styles.goldColor}>
            {name}
          </Header>
          <Label basic style={styles.label}>
            {type + ' '}
          </Label>{' '}
          {stars.map((item, index) => (
            <Icon key={item + index} name="star" color="yellow" />
          ))}
          <Grid>
            <Grid.Row style={styles.paddingTop10pxMarginTop10px}>
              <Grid.Column width="1">
                <Icon name="map marker alternate" style={styles.goldColor} />
              </Grid.Column>
              <Grid.Column width="14" style={styles.justify}>
                {address}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={styles.noMarginPaddingTop5pxBottom10px}>
              <Grid.Column width={8}>
                {data.IsInstantConfirmation ? (
                  <Label style={styles.labelAvailable}>
                    <img
                      alt=""
                      src={availableIcon}
                      style={styles.marginRight5}
                    />
                    {/* Available */}
                    {t('available')}
                  </Label>
                ) : (
                  <Label style={styles.labelOnRequest}>
                    <img alt="" src={onholdIcon} style={styles.marginRight5} />
                    {/* On Request */}
                    {t('onRequest')}
                  </Label>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={styles.padding3px10px}>
              <Facilities
                facilities={facilities}
                header={t('mainService')} //"Main Service"
                isIconWidthTrue={true}
              />
            </Grid.Row>
            <Grid.Row style={styles.paddingTop3px}>
              <Grid.Column width="7" floated="right">
                <Button
                  size="small"
                  style={styles.buttonGold}
                  onClick={openCloseModal}
                  value={id}
                  floated="right"
                >
                  {/* SEE ROOM */}
                  {t('SeeRoom')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
    </div>
  );
};
Card.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  isPromo: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  address: PropTypes.string,
  id: PropTypes.number,
  facilities: PropTypes.array,
  rating: PropTypes.string,
  getAccommodationRooms: PropTypes.func,
  listAccommodationRoomById: PropTypes.array,
  closeModal: PropTypes.func,
  loading: PropTypes.bool,
  data: PropTypes.object,
  handleOpenRooms: PropTypes.func,
  t: PropTypes.func,
};
export default Card;
