import * as types from './constant/actionTypes';

const initialState = {
  brochure: {},
  getBrochureStatus: '',
  errors: {},
  loading: false,
  brochureOverlay: null,
  getBrochureOverlayStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // --------------------------------------------------------------------------------------------
    // Print Brocure
    // --------------------------------------------------------------------------------------------
    case types.GET_BROCHURE_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_BROCHURE_FULFILLED: {
      return {
        ...state,
        brochure: action.payload.data,
        loading: false,
        getBrochureStatus: 'success',
      };
    }
    case types.GET_BROCHURE_REJECTED: {
      return { ...state, loading: false, getBrochureStatus: 'failed' };
    }
    case types.RESET_GET_BROCHURE: {
      return { ...state, brochure: {}, getBrochureStatus: '' };
    }
    // --------------------------------------------------------------------------------------------
    // Print Brocure Overlay
    // --------------------------------------------------------------------------------------------
    case types.GET_BROCHURE_OVERLAY_PENDING: {
      return { ...state, loading: true };
    }
    case types.GET_BROCHURE_OVERLAY_FULFILLED: {
      return {
        ...state,
        brochureOverlay: action.payload.data,
        loading: false,
        getBrochureOverlayStatus: 'success',
      };
    }
    case types.GET_BROCHURE_OVERLAY_REJECTED: {
      return { ...state, loading: false, getBrochureOverlayStatus: 'failed' };
    }
    default:
      return state;
  }
}
