const GET_COUNTRIES = 'GET_COUNTRIES';
const GET_COUNTRIES_PENDING = 'GET_COUNTRIES_PENDING';
const GET_COUNTRIES_FULFILLED = 'GET_COUNTRIES_FULFILLED';
const GET_COUNTRIES_REJECTED = 'GET_COUNTRIES_REJECTED';
const RESET_STATUS_GET_COUNTRIES = 'RESET_STATUS_GET_COUNTRIES';
const GET_CURRENCIES = 'GET_CURRENCIES';
const GET_CURRENCIES_FULFILLED = 'GET_CURRENCIES_FULFILLED';
const GET_CURRENCIES_PENDING = 'GET_CURRENCIES_PENDING';
const GET_CURRENCIES_REJECTED = 'GET_CURRENCIES_REJECTED';
const GET_TOTAL_BRANCH = 'GET_TOTAL_BRANCH';
const GET_TOTAL_BRANCH_FULFILLED = 'GET_TOTAL_BRANCH_FULFILLED';
const GET_TOTAL_BRANCH_PENDING = 'GET_TOTAL_BRANCH_PENDING';
const GET_TOTAL_BRANCH_REJECTED = 'GET_TOTAL_BRANCH_REJECTED';
const GET_TOTAL_EMPLOYEE = 'GET_TOTAL_EMPLOYEE';
const GET_TOTAL_EMPLOYEE_FULFILLED = 'GET_TOTAL_EMPLOYEE_FULFILLED';
const GET_TOTAL_EMPLOYEE_PENDING = 'GET_TOTAL_EMPLOYEE_PENDING';
const GET_TOTAL_EMPLOYEE_REJECTED = 'GET_TOTAL_EMPLOYEE_REJECTED';
const GET_REVENUE_RANGE = 'GET_REVENUE_RANGE';
const GET_REVENUE_RANGE_FULFILLED = 'GET_REVENUE_RANGE_FULFILLED';
const GET_REVENUE_RANGE_PENDING = 'GET_REVENUE_RANGE_PENDING';
const GET_REVENUE_RANGE_REJECTED = 'GET_REVENUE_RANGE_REJECTED';
const POST_LOGIN = 'POST_LOGIN';
const POST_LOGIN_PENDING = 'POST_LOGIN_PENDING';
const POST_LOGIN_FULFILLED = 'POST_LOGIN_FULFILLED';
const POST_LOGIN_REJECTED = 'POST_LOGIN_REJECTED';
const RESET_LOGIN_STATUS = 'RESET_LOGIN_STATUS';
const LOGOUT = 'LOGOUT';
const RESET_LOGOUT_STATUS = 'RESET_LOGOUT_STATUS';
// const GET_USER_INFO = 'GET_USER_INFO';
// const GET_USER_INFO_PENDING = 'GET_USER_INFO_PENDING';
// const GET_USER_INFO_FULFILLED = 'GET_USER_INFO_FULFILLED';
// const GET_USER_INFO_REJECTED = 'GET_USER_INFO_REJECTED';
const GET_FIXED_PACKAGE_ALL = 'GET_FIXED_PACKAGE_ALL';
const GET_FIXED_PACKAGE_ALL_PENDING = 'GET_FIXED_PACKAGE_ALL_PENDING';
const GET_FIXED_PACKAGE_ALL_FULFILLED = 'GET_FIXED_PACKAGE_ALL_FULFILLED';
const GET_FIXED_PACKAGE_ALL_REJECTED = 'GET_FIXED_PACKAGE_ALL_REJECTED';
const RESET_GET_FIXED_PACKAGE_ALL_STATUS = 'RESET_GET_FIXED_PACKAGE_ALL_STATUS';
const GET_CAROUSEL_IMAGE = 'GET_CAROUSEL_IMAGE';
const GET_CAROUSEL_IMAGE_PENDING = 'GET_CAROUSEL_IMAGE_PENDING';
const GET_CAROUSEL_IMAGE_FULFILLED = 'GET_CAROUSEL_IMAGE_FULFILLED';
const GET_CAROUSEL_IMAGE_REJECTED = 'GET_CAROUSEL_IMAGE_REJECTED';
const GET_TOUR_TYPES = 'GET_TOUR_TYPES';
const GET_TOUR_TYPES_PENDING = 'GET_TOUR_TYPES_PENDING';
const GET_TOUR_TYPES_FULFILLED = 'GET_TOUR_TYPES_FULFILLED';
const GET_TOUR_TYPES_REJECTED = 'GET_TOUR_TYPES_REJECTED';
const GET_TOUR_PAX_TYPES = 'GET_TOUR_PAX_TYPES';
const GET_TOUR_PAX_TYPES_PENDING = 'GET_TOUR_PAX_TYPES_PENDING';
const GET_TOUR_PAX_TYPES_FULFILLED = 'GET_TOUR_PAX_TYPES_FULFILLED';
const GET_TOUR_PAX_TYPES_REJECTED = 'GET_TOUR_PAX_TYPES_REJECTED';
const GET_ACCOMMODATION_TYPES = 'GET_ACCOMMODATION_TYPES';
const GET_ACCOMMODATION_TYPES_PENDING = 'GET_ACCOMMODATION_TYPES_PENDING';
const GET_ACCOMMODATION_TYPES_FULFILLED = 'GET_ACCOMMODATION_TYPES_FULFILLED';
const GET_ACCOMMODATION_TYPES_REJECTED = 'GET_ACCOMMODATION_TYPES_REJECTED';
const GET_ACCOMMODATION_RATINGS = 'GET_ACCOMMODATION_RATINGS';
const GET_ACCOMMODATION_RATINGS_PENDING = 'GET_ACCOMMODATION_RATINGS_PENDING';
const GET_ACCOMMODATION_RATINGS_FULFILLED =
  'GET_ACCOMMODATION_RATINGS_FULFILLED';
const GET_ACCOMMODATION_RATINGS_REJECTED = 'GET_ACCOMMODATION_RATINGS_REJECTED';
const GET_ACCOMMODATION_LOCATIONS = 'GET_ACCOMMODATION_LOCATIONS';
const GET_ACCOMMODATION_LOCATIONS_PENDING =
  'GET_ACCOMMODATION_LOCATIONS_PENDING';
const GET_ACCOMMODATION_LOCATIONS_FULFILLED =
  'GET_ACCOMMODATION_LOCATIONS_FULFILLED';
const GET_ACCOMMODATION_LOCATIONS_REJECTED = 'GET_ACCOMMODATION_LOCATIONS';
const GET_CITY_IN_COUNTRY = 'GET_CITY_IN_COUNTRY';
const GET_CITY_IN_COUNTRY_PENDING = 'GET_CITY_IN_COUNTRY_PENDING';
const GET_CITY_IN_COUNTRY_FULFILLED = 'GET_CITY_IN_COUNTRY_FULFILLED';
const GET_CITY_IN_COUNTRY_REJECTED = 'GET_CITY_IN_COUNTRY_REJECTED';
const GET_READY_PACKAGE_ALL = 'GET_READY_PACKAGE_ALL';
const GET_READY_PACKAGE_ALL_PENDING = 'GET_READY_PACKAGE_ALL_PENDING';
const GET_READY_PACKAGE_ALL_FULFILLED = 'GET_READY_PACKAGE_ALL_FULFILLED';
const GET_READY_PACKAGE_ALL_REJECTED = 'GET_READY_PACKAGE_ALL_REJECTED';
const RESET_GET_READY_PACKAGE_ALL_STATUS = 'RESET_GET_READY_PACKAGE_ALL_STATUS';
const GET_ACCOMMODATIONS = 'GET_ACCOMMODATION';
const GET_ACCOMMODATIONS_PENDING = 'GET_ACCOMMODATION_PENDING';
const GET_ACCOMMODATIONS_FULFILLED = 'GET_ACCOMMODATION_FULFILLED';
const GET_ACCOMMODATIONS_REJECTED = 'GET_ACCOMMODATION_REJECTED';
const GET_ACCOMMODATION_ROOMS = 'GET_ACCOMMODATION_ROOMS';
const GET_ACCOMMODATION_ROOMS_PENDING = 'GET_ACCOMMODATION_ROOMS_PENDING';
const GET_ACCOMMODATION_ROOMS_FULFILLED = 'GET_ACCOMMODATION_ROOMS_FULFILLED';
const GET_ACCOMMODATION_ROOMS_REJECTED = 'GET_ACCOMMODATION_ROOMS_REJECTED';
const GET_EXCURSION_TYPES = 'GET_EXCURSION_TYPES';
const GET_EXCURSION_TYPES_PENDING = 'GET_EXCURSION_TYPES_PENDING';
const GET_EXCURSION_TYPES_FULFILLED = 'GET_EXCURSION_TYPES_FULFILLED';
const GET_EXCURSION_TYPES_REJECTED = 'GET_EXCURSION_TYPES_REJECTED';
const GET_EXCURSION_BY_FILTER = 'GET_EXCURSION_BY_FILTER';
const GET_EXCURSION_BY_FILTER_PENDING = 'GET_EXCURSION_BY_FILTER_PENDING';
const GET_EXCURSION_BY_FILTER_FULFILLED = 'GET_EXCURSION_BY_FILTER_FULFILLED';
const GET_EXCURSION_BY_FILTER_REJECTED = 'GET_EXCURSION_BY_FILTER_REJECTED';
const GET_RESTAURANT_BY_FILTER = 'GET_RESTAURANT_BY_FILTER';
const GET_RESTAURANT_BY_FILTER_PENDING = 'GET_RESTAURANT_BY_FILTER_PENDING';
const GET_RESTAURANT_BY_FILTER_FULFILLED = 'GET_RESTAURANT_BY_FILTER_FULFILLED';
const GET_RESTAURANT_BY_FILTER_REJECTED = 'GET_RESTAURANT_BY_FILTER_REJECTED';
const GET_RESTAURANT_RATING = 'GET_RESTAURANT_RATING';
const GET_RESTAURANT_RATING_PENDING = 'GET_RESTAURANT_RATING_PENDING';
const GET_RESTAURANT_RATING_FULFILLED = 'GET_RESTAURANT_RATING_FULFILLED';
const GET_RESTAURANT_RATING_REJECTED = 'GET_RESTAURANT_RATING_REJECTED';
const GET_RESTAURANT_SPECIALIZATIONS = 'GET_RESTAURANT_SPECIALIZATIONS';
const GET_RESTAURANT_SPECIALIZATIONS_PENDING =
  'GET_RESTAURANT_SPECIALIZATIONS_PENDING';
const GET_RESTAURANT_SPECIALIZATIONS_FULFILLED =
  'GET_RESTAURANT_SPECIALIZATIONS_FULFILLED';
const GET_RESTAURANT_SPECIALIZATIONS_REJECTED =
  'GET_RESTAURANT_SPECIALIZATIONS_REJECTED';
const GET_RESTAURANT_MENU_CLASSES = 'GET_RESTAURANT_MENU_CLASSES';
const GET_RESTAURANT_MENU_CLASSES_PENDING =
  'GET_RESTAURANT_MENU_CLASSES_PENDING';
const GET_RESTAURANT_MENU_CLASSES_FULFILLED =
  'GET_RESTAURANT_MENU_CLASSES_FULFILLED';
const GET_RESTAURANT_MENU_CLASSES_REJECTED =
  'GET_RESTAURANT_MENU_CLASSES_REJECTED';
const GET_RESTAURANT_MENU_BY_ID = 'GET_RESTAURANT_MENU_BY_ID';
const GET_RESTAURANT_MENU_BY_ID_PENDING = 'GET_RESTAURANT_MENU_BY_ID_PENDING';
const GET_RESTAURANT_MENU_BY_ID_FULFILLED =
  'GET_RESTAURANT_MENU_BY_ID_FULFILLED';
const GET_RESTAURANT_MENU_BY_ID_REJECTED = 'GET_RESTAURANT_MENU_BY_ID_REJECTED';
const GET_ACCOMMODATION_FACILITIES = 'GET_ACCOMMODATION_FACILITIES';
const GET_ACCOMMODATION_FACILITIES_PENDING =
  'GET_ACCOMMODATION_FACILITIES_PENDING';
const GET_ACCOMMODATION_FACILITIES_FULFILLED =
  'GET_ACCOMMODATION_FACILITIES_FULFILLED';
const GET_ACCOMMODATION_FACILITIES_REJECTED =
  'GET_ACCOMMODATION_FACILITIES_REJECTED';
const GET_TRANSPORTATION_PROFILE_RATINGS = 'GET_TRANSPORTATION_PROFILE_RATINGS';
const GET_TRANSPORTATION_PROFILE_RATINGS_PENDING =
  'GET_TRANSPORTATION_PROFILE_RATINGS_PENDING';
const GET_TRANSPORTATION_PROFILE_RATINGS_FULFILLED =
  'GET_TRANSPORTATION_PROFILE_RATINGS_FULFILLED';
const GET_TRANSPORTATION_PROFILE_RATINGS_REJECTED =
  'GET_TRANSPORTATION_PROFILE_RATINGS_REJECTED';
const GET_TRANSPORTATION_PROFILE_CATEGORIES =
  'GET_TRANSPORTATION_PROFILE_CATEGORIES';
const GET_TRANSPORTATION_PROFILE_CATEGORIES_PENDING =
  'GET_TRANSPORTATION_PROFILE_CATEGORIES_PENDING';
const GET_TRANSPORTATION_PROFILE_CATEGORIES_FULFILLED =
  'GET_TRANSPORTATION_PROFILE_CATEGORIES_FULFILLED';
const GET_TRANSPORTATION_PROFILE_CATEGORIES_REJECTED =
  'GET_TRANSPORTATION_PROFILE_CATEGORIES_REJECTED';
const GET_TRANSPORTATION_PROFILES = 'GET_TRANSPORTATION_PROFILES';
const GET_TRANSPORTATION_PROFILES_PENDING =
  'GET_TRANSPORTATION_PROFILES_PENDING';
const GET_TRANSPORTATION_PROFILES_FULFILLED =
  'GET_TRANSPORTATION_PROFILES_FULFILLED';
const GET_TRANSPORTATION_PROFILES_REJECTED =
  'GET_TRANSPORTATION_PROFILES_REJECTED';
const GET_TRANSPORTATION_TYPES = 'GET_TRANSPORTATION_TYPES';
const GET_TRANSPORTATION_TYPES_PENDING = 'GET_TRANSPORTATION_TYPES_PENDING';
const GET_TRANSPORTATION_TYPES_FULFILLED = 'GET_TRANSPORTATION_TYPES_FULFILLED';
const GET_TRANSPORTATION_TYPES_REJECTED = 'GET_TRANSPORTATION_TYPES_REJECTED';
const GET_TRANSPORTATION_SEAT_TYPES = 'GET_TRANSPORTATION_SEAT_TYPES';
const GET_TRANSPORTATION_SEAT_TYPES_PENDING =
  'GET_TRANSPORTATION_SEAT_TYPES_PENDING';
const GET_TRANSPORTATION_SEAT_TYPES_FULFILLED =
  'GET_TRANSPORTATION_SEAT_TYPES_FULFILLED';
const GET_TRANSPORTATION_SEAT_TYPES_REJECTED =
  'GET_TRANSPORTATION_SEAT_TYPES_REJECTED';
const GET_TRANSPORTATION_UNITS_FILTER = 'GET_TRANSPORTATION_UNITS_FILTER';
const GET_TRANSPORTATION_UNITS_FILTER_PENDING =
  'GET_TRANSPORTATION_UNITS_FILTER_PENDING';
const GET_TRANSPORTATION_UNITS_FILTER_FULFILLED =
  'GET_TRANSPORTATION_UNITS_FILTER_FULFILLED';
const GET_TRANSPORTATION_UNITS_FILTER_REJECTED =
  'GET_TRANSPORTATION_UNITS_FILTER_REJECTED';
const SET_TEMP_FILTER_QUICK_LAUNCH = 'SET_TEMP_FILTER_QUICK_LAUNCH';
const RESET_STATUS_TRANSACTION = 'RESET_STATUS_TRANSACTION';
const RESET_STATUS_ACCOMMODATION = 'RESET_STATUS_ACCOMMODATION';
const RESET_STATUS_EXCURSION = 'RESET_STATUS_EXCURSION';
const RESET_STATUS_GET_CITY_IN_COUNTRY = 'RESET_STATUS_GET_CITY_IN_COUNTRY';
const GET_FIXED_PACKAGE_BY_ID = 'GET_FIXED_PACKAGE_BY_ID';
const GET_FIXED_PACKAGE_BY_ID_PENDING = 'GET_FIXED_PACKAGE_BY_ID_PENDING';
const GET_FIXED_PACKAGE_BY_ID_FULFILLED = 'GET_FIXED_PACKAGE_BY_ID_FULFILLED';
const GET_FIXED_PACKAGE_BY_ID_REJECTED = 'GET_FIXED_PACKAGE_BY_ID_REJECTED';
const RESET_GET_FIXED_PACKAGE_BY_ID_STATUS =
  'RESET_GET_FIXED_PACKAGE_BY_ID_STATUS';
const RESET_STATUS_RESTAURANT = 'RESET_STATUS_RESTAURANT';
const RESET_STATUS_TRANSPORTATION = 'RESET_STATUS_TRANSPORTATION';
const RESET_STATUS_TRANSPORTATION_UNITS_FILTER =
  'RESET_STATUS_TRANSPORTATION_UNITS_FILTER';
const RESET_STATUS_RESTAURANTS_BY_FILTER = 'RESET_STATUS_RESTAURANTS_BY_FILTER';
const GET_ACCOMMODATION_PROFILE_BY_ID = 'GET_ACCOMMODATION_PROFILE_BY_ID';
const GET_ACCOMMODATION_PROFILE_BY_ID_PENDING =
  'GET_ACCOMMODATION_PROFILE_BY_ID_PENDING';
const GET_ACCOMMODATION_PROFILE_BY_ID_REJECTED =
  'GET_ACCOMMODATION_PROFILE_BY_ID_REJECTED';
const GET_ACCOMMODATION_PROFILE_BY_ID_FULFILLED =
  'GET_ACCOMMODATION_PROFILE_BY_ID_FULFILLED';
const RESET_GET_ACCOMMODATION_PROFILE_BY_ID =
  'RESET_GET_ACCOMMODATION_PROFILE_BY_ID';
const GET_GUEST_TITLE_TYPE = 'GET_GUEST_TITLE_TYPE';
const GET_GUEST_TITLE_TYPE_PENDING = 'GET_GUEST_TITLE_TYPE_PENDING';
const GET_GUEST_TITLE_TYPE_REJECTED = 'GET_GUEST_TITLE_TYPE_REJECTED';
const GET_GUEST_TITLE_TYPE_FULFILLED = 'GET_GUEST_TITLE_TYPE_FULFILLED';
const GET_IDENTITY_TYPE = 'GET_IDENTITY_TYPE';
const GET_IDENTITY_TYPE_PENDING = 'GET_IDENTITY_TYPE_PENDING';
const GET_IDENTITY_TYPE_REJECTED = 'GET_IDENTITY_TYPE_REJECTED';
const GET_IDENTITY_TYPE_FULFILLED = 'GET_IDENTITY_TYPE_FULFILLED';
const SELECT_ACCOMMODATION_PROFILE = 'SELECT_ACCOMMODATION_PROFILE';
const POST_DEMO_JOIN_TOUR = 'POST_DEMO_JOIN_TOUR';
const POST_DEMO_JOIN_TOUR_PENDING = 'POST_DEMO_JOIN_TOUR_PENDING';
const POST_DEMO_JOIN_TOUR_REJECTED = 'POST_DEMO_JOIN_TOUR_REJECTED';
const POST_DEMO_JOIN_TOUR_FULFILLED = 'POST_DEMO_JOIN_TOUR_FULFILLED';
const POST_DEMO_PRICE = 'POST_DEMO_PRICE';
const POST_DEMO_PRICE_PENDING = 'POST_DEMO_PRICE_PENDING';
const POST_DEMO_PRICE_REJECTED = 'POST_DEMO_PRICE_REJECTED';
const POST_DEMO_PRICE_FULFILLED = 'POST_DEMO_PRICE_FULFILLED';
const GET_TOUR_OPERATOR_PROFILE = 'GET_TOUR_OPERATOR_PROFILE';
const GET_TOUR_OPERATOR_PROFILE_PENDING = 'GET_TOUR_OPERATOR_PROFILE_PENDING';
const GET_TOUR_OPERATOR_PROFILE_REJECTED = 'GET_TOUR_OPERATOR_PROFILE_REJECTED';
const GET_TOUR_OPERATOR_PROFILE_FULFILLED =
  'GET_TOUR_OPERATOR_PROFILE_FULFILLED';
const SET_TEMP_DEMO_JOIN_TOUR = 'SET_TEMP_DEMO_JOIN_TOUR';
const GET_TRANSACTION_HISTORY_ALL = 'GET_TRANSACTION_HISTORY_ALL';
const GET_TRANSACTION_HISTORY_ALL_PENDING =
  'GET_TRANSACTION_HISTORY_ALL_PENDING';
const GET_TRANSACTION_HISTORY_ALL_REJECTED =
  'GET_TRANSACTION_HISTORY_ALL_REJECTED';
const GET_TRANSACTION_HISTORY_ALL_FULFILLED =
  'GET_TRANSACTION_HISTORY_ALL_FULFILLED';
const GET_COMPANY_PROFILE = 'GET_COMPANY_PROFILE';
const GET_COMPANY_PROFILE_PENDING = 'GET_COMPANY_PROFILE_PENDING';
const GET_COMPANY_PROFILE_REJECTED = 'GET_COMPANY_PROFILE_REJECTED';
const GET_COMPANY_PROFILE_FULFILLED = 'GET_COMPANY_PROFILE_FULFILLED';
const POST_TOUR_TRANSACTION_CONFIRMATION = 'POST_TOUR_TRANSACTION_CONFIRMATION';
const POST_TOUR_TRANSACTION_CONFIRMATION_PENDING =
  'POST_TOUR_TRANSACTION_CONFIRMATION_PENDING';
const POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED =
  'POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED';
const POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED =
  'POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED';
const RESET_STATUS_TOUR_TRANSACTION_CONFIRMATION =
  'RESET_STATUS_TOUR_TRANSACTION_CONFIRMATION';
const RESET_STATUS_TRANSACTION_HISTORY_ALL =
  'RESET_STATUS_TRANSACTION_HISTORY_ALL';
const GET_TOUR_TRANSACTION_SUMMARY = 'GET_TOUR_TRANSACTION_SUMMARY';
const GET_TOUR_TRANSACTION_SUMMARY_PENDING =
  'GET_TOUR_TRANSACTION_SUMMARY_PENDING';
const GET_TOUR_TRANSACTION_SUMMARY_REJECTED =
  'GET_TOUR_TRANSACTION_SUMMARY_REJECTED';
const GET_TOUR_TRANSACTION_SUMMARY_FULFILLED =
  'GET_TOUR_TRANSACTION_SUMMARY_FULFILLED';
const RESET_STATUS_TOUR_TRANSACTION_SUMMARY =
  'RESET_STATUS_TOUR_TRANSACTION_SUMMARY';
const SELECT_TOUR_TRANSACTION_SUMMARY = 'SELECT_TOUR_TRANSACTION_SUMMARY';
const RESET_SELECT_TOUR_TRANSACTION_SUMMARY =
  'RESET_SELECT_TOUR_TRANSACTION_SUMMARY';
const GET_SCHEDULE_TOUR_TRANSACTION = 'GET_SCHEDULE_TOUR_TRANSACTION';
const GET_SCHEDULE_TOUR_TRANSACTION_PENDING =
  'GET_SCHEDULE_TOUR_TRANSACTION_PENDING';
const GET_SCHEDULE_TOUR_TRANSACTION_REJECTED =
  'GET_SCHEDULE_TOUR_TRANSACTION_REJECTED';
const GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED =
  'GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED';
const GET_INVOICE = 'GET_INVOICE';
const GET_INVOICE_PENDING = 'GET_INVOICE_PENDING';
const GET_INVOICE_REJECTED = 'GET_INVOICE_REJECTED';
const GET_INVOICE_FULFILLED = 'GET_INVOICE_FULFILLED';
const RESET_STATUS_INVOICE_AND_SCHEDULE = 'RESET_STATUS_INVOICE_AND_SCHEDULE';
const GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID =
  'GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID';
const GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_PENDING =
  'GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_PENDING';
const GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_REJECTED =
  'GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_REJECTED';
const GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_FULFILLED =
  'GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_FULFILLED';
const GET_TOUR_TRANSACTION_TO_BE_PAYED = 'GET_TOUR_TRANSACTION_TO_BE_PAYED';
const GET_TOUR_TRANSACTION_TO_BE_PAYED_PENDING =
  'GET_TOUR_TRANSACTION_TO_BE_PAYED';
const GET_TOUR_TRANSACTION_TO_BE_PAYED_REJECTED =
  'GET_TOUR_TRANSACTION_TO_BE_PAYED';
const GET_TOUR_TRANSACTION_TO_BE_PAYED_FULFILLED =
  'GET_TOUR_TRANSACTION_TO_BE_PAYED';
const RESET_SELECT_ACCOMMODATION_PROFILE = 'RESET_SELECT_ACCOMMODATION_PROFILE';
// const GET_TRANSPORTATION_UNIT_BY_ID = 'GET_TRANSPORTATION_UNIT_BY_ID';
// const GET_TRANSPORTATION_UNIT_BY_ID_PENDING =
//   'GET_TRANSPORTATION_UNIT_BY_ID_PENDING';
// const GET_TRANSPORTATION_UNIT_BY_ID_REJECTED =
//   'GET_TRANSPORTATION_UNIT_BY_ID_REJECTED';
// const GET_TRANSPORTATION_UNIT_BY_ID_FULFILLED =
//   'GET_TRANSPORTATION_UNIT_BY_ID_FULFILLED';
// const RESET_STATUS_TRANSPORTATION_UNIT_BY_ID =
//   'RESET_STATUS_TRANSPORTATION_UNIT_BY_ID';
const GET_EXCURSION_BY_ID = 'GET_EXCURSION_BY_ID';
const GET_EXCURSION_BY_ID_PENDING = 'GET_EXCURSION_BY_ID_PENDING';
const GET_EXCURSION_BY_ID_REJECTED = 'GET_EXCURSION_BY_ID_REJECTED';
const GET_EXCURSION_BY_ID_FULFILLED = 'GET_EXCURSION_BY_ID_FULFILLED';
const GET_MENU_BY_ID = 'GET_MENU_BY_ID';
const GET_MENU_BY_ID_PENDING = 'GET_MENU_BY_ID_PENDING';
const GET_MENU_BY_ID_REJECTED = 'GET_MENU_BY_ID_REJECTED';
const GET_MENU_BY_ID_FULFILLED = 'GET_MENU_BY_ID_FULFILLED';
const SET_GUESTS_AMOUNT = 'SET_GUESTS_AMOUNT';
const SET_ROOMALLOCATION = 'SET_ROOMALLOCATION';
const SET_TOUR_NAME = 'SET_TOUR_NAME';
const SET_HEADLINE_PROGRAM = 'SET_HEADLINE_PROGRAM';
const SET_FIRST_DESTINATION = 'SET_FIRST_DESTINATION';
const GET_PLACE_BY_REGION = 'GET_PLACE_BY_REGION';
const GET_PLACE_BY_REGION_FULFILLED = 'GET_PLACE_BY_REGION_FULFILLED';
const GET_PLACE_BY_REGION_PENDING = 'GET_PLACE_BY_REGION_PENDING';
const GET_PLACE_BY_REGION_REJECTED = 'GET_PLACE_BY_REGION_REJECTED';
const RESET_GET_PLACE_BY_REGION = 'RESET_GET_PLACE_BY_REGION';
const SET_ACCOMMODATION_ONCHANGE_INDEX = 'SET_ACCOMMODATION_ONCHANGE_INDEX';
const RESET_ACCOMMODATION_ONCHANGE_INDEX = 'RESET_ACCOMMODATION_ONCHANGE_INDEX';
const SET_DAILYPROGRAM = 'SET_DAILYPROGRAM';
const RESET_SET_HEADLINE_STATUS = 'RESET_SET_HEADLINE_STATUS';
const GET_MOVEMENT_LIST = 'GET_MOVEMENT_LIST';
const GET_MOVEMENT_LIST_PENDING = 'GET_MOVEMENT_LIST_PENDING';
const GET_MOVEMENT_LIST_FULFILLED = 'GET_MOVEMENT_LIST_FULFILLED';
const GET_MOVEMENT_LIST_REJECTED = 'GET_MOVEMENT_LIST_REJECTED';
const GET_DURATION_ID_TO_ID = 'GET_DURATION_ID_TO_ID';
const GET_DURATION_ID_TO_ID_PENDING = 'GET_DURATION_ID_TO_ID_PENDING';
const GET_DURATION_ID_TO_ID_FULFILLED = 'GET_DURATION_ID_TO_ID_FULFILLED';
const GET_DURATION_ID_TO_ID_REJECTED = 'GET_DURATION_ID_TO_ID_REJECTED';
const GET_DURATION_ID_TO_ADDRESS = 'GET_DURATION_ID_TO_ADDRESS';
const GET_DURATION_ID_TO_ADDRESS_PENDING = 'GET_DURATION_ID_TO_ADDRESS_PENDING';
const GET_DURATION_ID_TO_ADDRESS_FULFILLED =
  'GET_DURATION_ID_TO_ADDRESS_FULFILLED';
const GET_DURATION_ID_TO_ADDRESS_REJECTED =
  'GET_DURATION_ID_TO_ADDRESS_REJECTED';
const GET_DURATION_ADDRESS_TO_ID = 'GET_DURATION_ADDRESS_TO_ID';
const GET_DURATION_ADDRESS_TO_ID_PENDING = 'GET_DURATION_ADDRESS_TO_ID_PENDING';
const GET_DURATION_ADDRESS_TO_ID_FULFILLED =
  'GET_DURATION_ADDRESS_TO_ID_FULFILLED';
const GET_DURATION_ADDRESS_TO_ID_REJECTED =
  'GET_DURATION_ADDRESS_TO_ID_REJECTED';
const RESET_GET_DURATION_STATUS = 'RESET_GET_DURATION_STATUS';
const RESET_MAPPING_DURATION = 'RESET_MAPPING_DURATION';
const SET_DRIVING_DATA = 'SET_DRIVING_DATA';
const RESET_DRIVING_DATA = 'RESET_DRIVING_DATA';
const RESET_COUNT_GET_DURATION = 'RESET_COUNT_GET_DURATION';
const SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE';
const PUT_UPDATE_COMPANY = 'PUT_UPDATE_COMPANY';
const PUT_UPDATE_COMPANY_PENDING = 'PUT_UPDATE_COMPANY_PENDING';
const PUT_UPDATE_COMPANY_REJECTED = 'PUT_UPDATE_COMPANY_REJECTED';
const PUT_UPDATE_COMPANY_FULFILLED = 'PUT_UPDATE_COMPANY_FULFILLED';
const RESET_UPDATE_COMPANY_STATUS = 'RESET_UPDATE_COMPANY_STATUS';
const POST_REGISTER = 'POST_REGISTER';
const POST_REGISTER_PENDING = 'POST_REGISTER_PENDING';
const POST_REGISTER_FULFILLED = 'POST_REGISTER_FULFILLED';
const POST_REGISTER_REJECTED = 'POST_REGISTER_REJECTED';
const POST_ACCEPT_QUOTATION = 'POST_ACCEPT_QUOTATION';
const POST_ACCEPT_QUOTATION_PENDING = 'POST_ACCEPT_QUOTATION_PENDING';
const POST_ACCEPT_QUOTATION_REJECTED = 'POST_ACCEPT_QUOTATION_REJECTED';
const POST_ACCEPT_QUOTATION_FULFILLED = 'POST_ACCEPT_QUOTATION_FULFILLED';
const RESET_STATUS_QUOTATION = 'RESET_STATUS_QUOTATION';
const POST_EDIT_QUOTATION = 'POST_EDIT_QUOTATION';
const POST_EDIT_QUOTATION_PENDING = 'POST_EDIT_QUOTATION_PENDING';
const POST_EDIT_QUOTATION_REJECTED = 'POST_EDIT_QUOTATION_REJECTED';
const POST_EDIT_QUOTATION_FULFILLED = 'POST_EDIT_QUOTATION_FULFILLED';
const POST_CREATE_TOUR = 'POST_CREATE_TOUR';
const POST_CREATE_TOUR_PENDING = 'POST_CREATE_TOUR_PENDING';
const POST_CREATE_TOUR_REJECTED = 'POST_CREATE_TOUR_REJECTED';
const POST_CREATE_TOUR_FULFILLED = 'POST_CREATE_TOUR_FULFILLED';
const POST_JOIN_TOUR = 'POST_JOIN_TOUR';
const POST_JOIN_TOUR_PENDING = 'POST_JOIN_TOUR_PENDING';
const POST_JOIN_TOUR_REJECTED = 'POST_JOIN_TOUR_REJECTED';
const POST_JOIN_TOUR_FULFILLED = 'POST_JOIN_TOUR_FULFILLED';
const SET_TEMP_ID_TOUR = 'SET_TEMP_ID_TOUR';
const GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM =
  'GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM';
const GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_PENDING =
  'GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_PENDING';
const GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_REJECTED =
  'GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_REJECTED';
const GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_FULFILLED =
  'GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_FULFILLED';
const RESET_COMPANY_PROFILE_STATUS = 'RESET_COMPANY_PROFILE_STATUS';

const SET_GUEST_LIST = 'SET_GUEST_LIST';
const SET_COUNT_GET_DURATION = 'SET_COUNT_GET_DURATION';
const SET_MAPPING_OBJ = 'SET_MAPPING_OBJ';
const GET_TOUR_OPERATOR_LIST = 'GET_TOUR_OPERATOR_LIST';
const GET_TOUR_OPERATOR_LIST_PENDING = 'GET_TOUR_OPERATOR_LIST_PENDING';
const GET_TOUR_OPERATOR_LIST_REJECTED = 'GET_TOUR_OPERATOR_LIST_REJECTED';
const GET_TOUR_OPERATOR_LIST_FULFILLED = 'GET_TOUR_OPERATOR_LIST_FULFILLED';
const GET_READY_PACKAGE_BY_ID = 'GET_READY_PACKAGE_BY_ID';
const GET_READY_PACKAGE_BY_ID_PENDING = 'GET_READY_PACKAGE_BY_ID_PENDING';
const GET_READY_PACKAGE_BY_ID_REJECTED = 'GET_READY_PACKAGE_BY_ID_REJECTED';
const GET_READY_PACKAGE_BY_ID_FULFILLED = 'GET_READY_PACKAGE_BY_ID_FULFILLED';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED';
const GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED =
  'GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED';
const RESET_TEMP_DEMO_JOIN_TOUR = 'RESET_TEMP_DEMO_JOIN_TOUR';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2 =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_PENDING =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_PENDING';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_REJECTED =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_REJECTED';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_FULFILLED =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_FULFILLED';
const SET_TEMP_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2 =
  'SET_TEMP_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2';
const SET_TEMP_DEMO_PRICE = 'SET_TEMP_DEMO_PRICE';
const RESET_TEMP_DEMO_PRICE = 'RESET_TEMP_DEMO_PRICE';
const POST_TOUR_TRANSACTIONS_CONFIRMATION =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_PENDING =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION_PENDING';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_REJECTED =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION_REJECTED';
const POST_TOUR_TRANSACTIONS_CONFIRMATION_FULFILLED =
  'POST_TOUR_TRANSACTIONS_CONFIRMATION_FULFILLED';
const POST_TOUR_TRANSACTIONS_TOP_UP = 'POST_TOUR_TRANSACTIONS_TOP_UP';
const POST_TOUR_TRANSACTIONS_TOP_UP_PENDING =
  'POST_TOUR_TRANSACTIONS_TOP_UP_PENDING';
const POST_TOUR_TRANSACTIONS_TOP_UP_REJECTED =
  'POST_TOUR_TRANSACTIONS_TOP_UP_REJECTED';
const POST_TOUR_TRANSACTIONS_TOP_UP_FULFILLED =
  'POST_TOUR_TRANSACTIONS_TOP_UP_FULFILLED';
const RESET_SET_DAILY_PROGRAM_STATUS = 'RESET_SET_DAILY_PROGRAM_STATUS';
const RESET_GET_READY_PACKAGE_BY_ID_STATUS =
  'RESET_GET_READY_PACKAGE_BY_ID_STATUS';
const RESET_CUSTOM_PACKAGE = 'RESET_CUSTOM_PACKAGE';
const RESET_DEMO_PRICE = 'RESET_DEMO_PRICE';
const GET_TOUR_TRANSACTION_BILLED = 'GET_TOUR_TRANSACTION_BILLED';
const GET_TOUR_TRANSACTION_BILLED_PENDING =
  'GET_TOUR_TRANSACTION_BILLED_PENDING';
const GET_TOUR_TRANSACTION_BILLED_REJECTED =
  'GET_TOUR_TRANSACTION_BILLED_REJECTED';
const GET_TOUR_TRANSACTION_BILLED_FULFILLED =
  'GET_TOUR_TRANSACTION_BILLED_FULFILLED';
const POST_FORGOT_PASSWORD = 'POST_FORGOT_PASSWORD';
const POST_FORGOT_PASSWORD_PENDING = 'POST_FORGOT_PASSWORD_PENDING';
const POST_FORGOT_PASSWORD_FULFILLED = 'POST_FORGOT_PASSWORD_FULFILLED';
const POST_FORGOT_PASSWORD_REJECTED = 'POST_FORGOT_PASSWORD_REJECTED';
const POST_RESET_PASSWORD = 'POST_RESET_PASSWORD';
const POST_RESET_PASSWORD_PENDING = 'POST_RESET_PASSWORD_PENDING';
const POST_RESET_PASSWORD_FULFILLED = 'POST_RESET_PASSWORD_FULFILLED';
const POST_RESET_PASSWORD_REJECTED = 'POST_RESET_PASSWORD_REJECTED';
const GET_USER_ROLES = 'GET_USER_ROLES';
const GET_USER_ROLES_PENDING = 'GET_USER_ROLES_PENDING';
const GET_USER_ROLES_REJECTED = 'GET_USER_ROLES_REJECTED';
const GET_USER_ROLES_FULFILLED = 'GET_USER_ROLES_FULFILLED';
const GET_COMPANIES = 'GET_COMPANIES';
const GET_COMPANIES_PENDING = 'GET_COMPANIES_PENDING';
const GET_COMPANIES_REJECTED = 'GET_COMPANIES_REJECTED';
const GET_COMPANIES_FULFILLED = 'GET_COMPANIES_FULFILLED';
const POST_CREATE_TOUR_ON_BE_HALF = 'POST_CREATE_TOUR_ON_BE_HALF';
const POST_CREATE_TOUR_ON_BE_HALF_PENDING =
  'POST_CREATE_TOUR_ON_BE_HALF_PENDING';
const POST_CREATE_TOUR_ON_BE_HALF_REJECTED =
  'POST_CREATE_TOUR_ON_BE_HALF_REJECTED';
const POST_CREATE_TOUR_ON_BE_HALF_FULFILLED =
  'POST_CREATE_TOUR_ON_BE_HALF_FULFILLED';
const POST_JOIN_TOUR_ON_BE_HALF = 'POST_JOIN_TOUR_ON_BE_HALF';
const POST_JOIN_TOUR_ON_BE_HALF_PENDING = 'POST_JOIN_TOUR_ON_BE_HALF_PENDING';
const POST_JOIN_TOUR_ON_BE_HALF_REJECTED = 'POST_JOIN_TOUR_ON_BE_HALF_REJECTED';
const POST_JOIN_TOUR_ON_BE_HALF_FULFILLED =
  'POST_JOIN_TOUR_ON_BE_HALF_FULFILLED';
const POST_REGISTER_SIMPLE_CUSTOMER = 'POST_REGISTER_SIMPLE_CUSTOMER';
const POST_REGISTER_SIMPLE_CUSTOMER_PENDING =
  'POST_REGISTER_SIMPLE_CUSTOMER_PENDING';
const POST_REGISTER_SIMPLE_CUSTOMER_REJECTED =
  'POST_REGISTER_SIMPLE_CUSTOMER_REJECTED';
const POST_REGISTER_SIMPLE_CUSTOMER_FULFILLED =
  'POST_REGISTER_SIMPLE_CUSTOMER_FULFILLED';
const RESET_REGISTER_SIMPLE_CUSTOMER = 'RESET_REGISTER_SIMPLE_CUSTOMER';
const SET_TEMP_BACK_CUSTOM_READY = 'SET_TEMP_BACK_CUSTOM_READY';
const RESET_TEMP_BACK_CUSTOM_READY = 'RESET_TEMP_BACK_CUSTOM_READY';
const SET_TEMP_TOUR_CUSTOM_READY = 'SET_TEMP_TOUR_CUSTOM_READY';
const RESET_FIRST_DESTINATION = 'RESET_FIRST_DESTINATION';
const RESET_GUESTS = 'RESET_GUESTS';
const RESET_ROOM_ALLOCATION = 'RESET_ROOM_ALLOCATION';

const GET_FIXED_PACKAGE_LANDING_PAGE = 'GET_FIXED_PACKAGE_LANDING_PAGE';
const GET_FIXED_PACKAGE_LANDING_PAGE_PENDING =
  'GET_FIXED_PACKAGE_LANDING_PAGE_PENDING';
const GET_FIXED_PACKAGE_LANDING_PAGE_REJECTED =
  'GET_FIXED_PACKAGE_LANDING_PAGE_REJECTED';
const GET_FIXED_PACKAGE_LANDING_PAGE_FULFILLED =
  'GET_FIXED_PACKAGE_LANDING_PAGE_FULFILLED';
const RESET_FIXED_PACKAGE_LANDING_PAGE_STATUS =
  'RESET_FIXED_PACKAGE_LANDING_PAGE_STATUS';

const GET_CAROUSEL_IMAGE_AFTER_LOGIN = 'GET_CAROUSEL_IMAGE_AFTER_LOGIN';
const GET_CAROUSEL_IMAGE_AFTER_LOGIN_PENDING =
  'GET_CAROUSEL_IMAGE_AFTER_LOGIN_PENDING';
const GET_CAROUSEL_IMAGE_AFTER_LOGIN_FULFILLED =
  'GET_CAROUSEL_IMAGE_AFTER_LOGIN_FULFILLED';
const GET_CAROUSEL_IMAGE_AFTER_LOGIN_REJECTED =
  'GET_CAROUSEL_IMAGE_AFTER_LOGIN_REJECTED';
const GET_GUEST_LIST_TEMPLATE = 'GET_GUEST_LIST_TEMPLATE';
const GET_GUEST_LIST_TEMPLATE_PENDING = 'GET_GUEST_LIST_TEMPLATE_PENDING';
const GET_GUEST_LIST_TEMPLATE_FULFILLED = 'GET_GUEST_LIST_TEMPLATE_FULFILLED';
const GET_GUEST_LIST_TEMPLATE_REJECTED = 'GET_GUEST_LIST_TEMPLATE_REJECTED';
const GET_INVOICE_TRAVEL_AGENT = 'GET_INVOICE_TRAVEL_AGENT';
const GET_INVOICE_TRAVEL_AGENT_PENDING = 'GET_INVOICE_TRAVEL_AGENT_PENDING';
const GET_INVOICE_TRAVEL_AGENT_FULFILLED = 'GET_INVOICE_TRAVEL_AGENT_FULFILLED';
const GET_INVOICE_TRAVEL_AGENT_REJECTED = 'GET_INVOICE_TRAVEL_AGENT_REJECTED';
const GET_INVOICE_CUSTOMER = 'GET_INVOICE_CUSTOMER';
const GET_INVOICE_CUSTOMER_PENDING = 'GET_INVOICE_CUSTOMER_PENDING';
const GET_INVOICE_CUSTOMER_FULFILLED = 'GET_INVOICE_CUSTOMER_FULFILLED';
const GET_INVOICE_CUSTOMER_REJECTED = 'GET_INVOICE_CUSTOMER_REJECTED';
const SET_COORDINATE_ADDRESS = 'SET_COORDINATE_ADDRESS';
const RESET_TEMP_FILTER_QUICK_LAUNCH = 'RESET_TEMP_FILTER_QUICK_LAUNCH';
const UPDATE_COORDINATE_ADDRESS = 'UPDATE_COORDINATE_ADDRESS';
const SET_EXPIRED_TOKEN = 'SET_EXPIRED_TOKEN';
const GET_LANGUAGE_ALL = 'GET_LANGUAGE_ALL';
const GET_LANGUAGE_ALL_PENDING = 'GET_LANGUAGE_ALL_PENDING';
const GET_LANGUAGE_ALL_FULFILLED = 'GET_LANGUAGE_ALL_FULFILLED';
const GET_LANGUAGE_ALL_REJECTED = 'GET_LANGUAGE_ALL_REJECTED';
const GET_VOUCHER_CONFIRMATION = 'GET_VOUCHER_CONFIRMATION';
const GET_VOUCHER_CONFIRMATION_PENDING = 'GET_VOUCHER_CONFIRMATION_PENDING';
const GET_VOUCHER_CONFIRMATION_FULFILLED = 'GET_VOUCHER_CONFIRMATION_FULFILLED';
const GET_VOUCHER_CONFIRMATION_REJECTED = 'GET_VOUCHER_CONFIRMATION_REJECTED';
const GET_ACCOMMODATION_FILTER_PARAMETER = 'GET_ACCOMMODATION_FILTER_PARAMETER';
const GET_ACCOMMODATION_FILTER_PARAMETER_PENDING =
  'GET_ACCOMMODATION_FILTER_PARAMETER_PENDING';
const GET_ACCOMMODATION_FILTER_PARAMETER_FULFILLED =
  'GET_ACCOMMODATION_FILTER_PARAMETER_FULFILLED';
const GET_ACCOMMODATION_FILTER_PARAMETER_REJECTED =
  'GET_ACCOMMODATION_FILTER_PARAMETER_REJECTED';

const UPDATE_COORDINATE_CENTER = 'UPDATE_COORDINATE_CENTER';
const GET_TOUR_TRANSACTION_BILLS = 'GET_TOUR_TRANSACTION_BILLS';
const GET_TOUR_TRANSACTION_BILLS_PENDING = 'GET_TOUR_TRANSACTION_BILLS_PENDING';
const GET_TOUR_TRANSACTION_BILLS_FULFILLED =
  'GET_TOUR_TRANSACTION_BILLS_FULFILLED';
const GET_TOUR_TRANSACTION_BILLS_REJECTED =
  'GET_TOUR_TRANSACTION_BILLS_REJECTED';

const RESET_DEMO_JOIN_TOUR = 'RESET_DEMO_JOIN_TOUR';
const GET_TRANSACTION_HISTORY_CREATED = 'GET_TRANSACTION_HISTORY_CREATED';
const GET_TRANSACTION_HISTORY_CREATED_PENDING =
  'GET_TRANSACTION_HISTORY_CREATED_PENDING';
const GET_TRANSACTION_HISTORY_CREATED_FULFILLED =
  'GET_TRANSACTION_HISTORY_CREATED_FULFILLED';
const GET_TRANSACTION_HISTORY_CREATED_REJECTED =
  'GET_TRANSACTION_HISTORY_CREATED_REJECTED';
const GET_TRANSACTION_HISTORY_INPAYMENT = 'GET_TRANSACTION_HISTORY_INPAYMENT';
const GET_TRANSACTION_HISTORY_INPAYMENT_PENDING =
  'GET_TRANSACTION_HISTORY_INPAYMENT_PENDING';
const GET_TRANSACTION_HISTORY_INPAYMENT_FULFILLED =
  'GET_TRANSACTION_HISTORY_INPAYMENT_FULFILLED';
const GET_TRANSACTION_HISTORY_INPAYMENT_REJECTED =
  'GET_TRANSACTION_HISTORY_INPAYMENT_REJECTED';
const GET_TRANSACTION_HISTORY_CONFIRMED = 'GET_TRANSACTION_HISTORY_CONFIRMED';
const GET_TRANSACTION_HISTORY_CONFIRMED_PENDING =
  'GET_TRANSACTION_HISTORY_CONFIRMED_PENDING';
const GET_TRANSACTION_HISTORY_CONFIRMED_FULFILLED =
  'GET_TRANSACTION_HISTORY_CONFIRMED_FULFILLED';
const GET_TRANSACTION_HISTORY_CONFIRMED_REJECTED =
  'GET_TRANSACTION_HISTORY_CONFIRMED_REJECTED';
const GET_TRANSACTION_HISTORY_COMPLETED = 'GET_TRANSACTION_HISTORY_COMPLETED';
const GET_TRANSACTION_HISTORY_COMPLETED_PENDING =
  'GET_TRANSACTION_HISTORY_COMPLETED_PENDING';
const GET_TRANSACTION_HISTORY_COMPLETED_FULFILLED =
  'GET_TRANSACTION_HISTORY_COMPLETED_FULFILLED';
const GET_TRANSACTION_HISTORY_COMPLETED_REJECTED =
  'GET_TRANSACTION_HISTORY_COMPLETED_REJECTED';
const GET_TRANSACTION_HISTORY_CANCELLED = 'GET_TRANSACTION_HISTORY_CANCELLED';
const GET_TRANSACTION_HISTORY_CANCELLED_PENDING =
  'GET_TRANSACTION_HISTORY_CANCELLED_PENDING';
const GET_TRANSACTION_HISTORY_CANCELLED_FULFILLED =
  'GET_TRANSACTION_HISTORY_CANCELLED_FULFILLED';
const GET_TRANSACTION_HISTORY_CANCELLED_REJECTED =
  'GET_TRANSACTION_HISTORY_CANCELLED_REJECTED';
const GET_TRANSACTION_HISTORY_DRAFT = 'GET_TRANSACTION_HISTORY_DRAFT';
const GET_TRANSACTION_HISTORY_DRAFT_PENDING =
  'GET_TRANSACTION_HISTORY_DRAFT_PENDING';
const GET_TRANSACTION_HISTORY_DRAFT_FULFILLED =
  'GET_TRANSACTION_HISTORY_DRAFT_FULFILLED';
const GET_TRANSACTION_HISTORY_DRAFT_REJECTED =
  'GET_TRANSACTION_HISTORY_DRAFT_REJECTED';
const GET_FEATURED_PACKAGE_BEFORE_LOGIN = 'GET_FEATURED_PACKAGE_BEFORE_LOGIN';
const GET_FEATURED_PACKAGE_BEFORE_LOGIN_PENDING =
  'GET_FEATURED_PACKAGE_BEFORE_LOGIN_PENDING';
const GET_FEATURED_PACKAGE_BEFORE_LOGIN_FULFILLED =
  'GET_FEATURED_PACKAGE_BEFORE_LOGIN_FULFILLED';
const GET_FEATURED_PACKAGE_BEFORE_LOGIN_REJECTED =
  'GET_FEATURED_PACKAGE_BEFORE_LOGIN_REJECTED';
const GET_FEATURED_PACKAGE_AFTER_LOGIN = 'GET_FEATURED_PACKAGE_AFTER_LOGIN';
const GET_FEATURED_PACKAGE_AFTER_LOGIN_PENDING =
  'GET_FEATURED_PACKAGE_AFTER_LOGIN_PENDING';
const GET_FEATURED_PACKAGE_AFTER_LOGIN_FULFILLED =
  'GET_FEATURED_PACKAGE_AFTER_LOGIN_FULFILLED';
const GET_FEATURED_PACKAGE_AFTER_LOGIN_REJECTED =
  'GET_FEATURED_PACKAGE_AFTER_LOGIN_REJECTED';

const GET_PLACE_BY_PLACE_TYPE_ID = 'GET_PLACE_BY_PLACE_TYPE_ID';
const GET_PLACE_BY_PLACE_TYPE_ID_PENDING = 'GET_PLACE_BY_PLACE_TYPE_ID_PENDING';
const GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED =
  'GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED';
const GET_PLACE_BY_PLACE_TYPE_ID_REJECTED =
  'GET_PLACE_BY_PLACE_TYPE_ID_REJECTED';

const CHANGE_LOGIN_FLAG = 'CHANGE_LOGIN_FLAG';
export {
  GET_COUNTRIES,
  GET_COUNTRIES_PENDING,
  GET_COUNTRIES_FULFILLED,
  GET_COUNTRIES_REJECTED,
  RESET_STATUS_GET_COUNTRIES,
  GET_CURRENCIES,
  GET_CURRENCIES_FULFILLED,
  GET_CURRENCIES_PENDING,
  GET_CURRENCIES_REJECTED,
  GET_REVENUE_RANGE,
  GET_REVENUE_RANGE_FULFILLED,
  GET_REVENUE_RANGE_PENDING,
  GET_REVENUE_RANGE_REJECTED,
  GET_TOTAL_BRANCH,
  GET_TOTAL_BRANCH_FULFILLED,
  GET_TOTAL_BRANCH_PENDING,
  GET_TOTAL_BRANCH_REJECTED,
  GET_TOTAL_EMPLOYEE,
  GET_TOTAL_EMPLOYEE_FULFILLED,
  GET_TOTAL_EMPLOYEE_PENDING,
  GET_TOTAL_EMPLOYEE_REJECTED,
  POST_LOGIN,
  POST_LOGIN_PENDING,
  POST_LOGIN_FULFILLED,
  POST_LOGIN_REJECTED,
  RESET_LOGIN_STATUS,
  LOGOUT,
  RESET_LOGOUT_STATUS,
  // GET_USER_INFO,
  // GET_USER_INFO_PENDING,
  // GET_USER_INFO_FULFILLED,
  // GET_USER_INFO_REJECTED,
  GET_FIXED_PACKAGE_ALL,
  GET_FIXED_PACKAGE_ALL_PENDING,
  GET_FIXED_PACKAGE_ALL_FULFILLED,
  GET_FIXED_PACKAGE_ALL_REJECTED,
  RESET_GET_FIXED_PACKAGE_ALL_STATUS,
  GET_CAROUSEL_IMAGE,
  GET_CAROUSEL_IMAGE_PENDING,
  GET_CAROUSEL_IMAGE_FULFILLED,
  GET_CAROUSEL_IMAGE_REJECTED,
  GET_TOUR_TYPES,
  GET_TOUR_TYPES_PENDING,
  GET_TOUR_TYPES_FULFILLED,
  GET_TOUR_TYPES_REJECTED,
  GET_TOUR_PAX_TYPES,
  GET_TOUR_PAX_TYPES_PENDING,
  GET_TOUR_PAX_TYPES_FULFILLED,
  GET_TOUR_PAX_TYPES_REJECTED,
  GET_ACCOMMODATION_TYPES,
  GET_ACCOMMODATION_TYPES_PENDING,
  GET_ACCOMMODATION_TYPES_FULFILLED,
  GET_ACCOMMODATION_TYPES_REJECTED,
  GET_ACCOMMODATION_RATINGS,
  GET_ACCOMMODATION_RATINGS_PENDING,
  GET_ACCOMMODATION_RATINGS_FULFILLED,
  GET_ACCOMMODATION_RATINGS_REJECTED,
  GET_ACCOMMODATION_LOCATIONS,
  GET_ACCOMMODATION_LOCATIONS_PENDING,
  GET_ACCOMMODATION_LOCATIONS_FULFILLED,
  GET_ACCOMMODATION_LOCATIONS_REJECTED,
  GET_CITY_IN_COUNTRY,
  GET_CITY_IN_COUNTRY_PENDING,
  GET_CITY_IN_COUNTRY_FULFILLED,
  GET_CITY_IN_COUNTRY_REJECTED,
  GET_READY_PACKAGE_ALL,
  GET_READY_PACKAGE_ALL_PENDING,
  GET_READY_PACKAGE_ALL_FULFILLED,
  GET_READY_PACKAGE_ALL_REJECTED,
  RESET_GET_READY_PACKAGE_ALL_STATUS,
  GET_ACCOMMODATIONS,
  GET_ACCOMMODATIONS_PENDING,
  GET_ACCOMMODATIONS_FULFILLED,
  GET_ACCOMMODATIONS_REJECTED,
  GET_ACCOMMODATION_ROOMS,
  GET_ACCOMMODATION_ROOMS_PENDING,
  GET_ACCOMMODATION_ROOMS_FULFILLED,
  GET_ACCOMMODATION_ROOMS_REJECTED,
  GET_EXCURSION_TYPES,
  GET_EXCURSION_TYPES_PENDING,
  GET_EXCURSION_TYPES_FULFILLED,
  GET_EXCURSION_TYPES_REJECTED,
  GET_EXCURSION_BY_FILTER,
  GET_EXCURSION_BY_FILTER_PENDING,
  GET_EXCURSION_BY_FILTER_FULFILLED,
  GET_EXCURSION_BY_FILTER_REJECTED,
  GET_RESTAURANT_BY_FILTER,
  GET_RESTAURANT_BY_FILTER_PENDING,
  GET_RESTAURANT_BY_FILTER_FULFILLED,
  GET_RESTAURANT_BY_FILTER_REJECTED,
  GET_RESTAURANT_RATING,
  GET_RESTAURANT_RATING_PENDING,
  GET_RESTAURANT_RATING_FULFILLED,
  GET_RESTAURANT_RATING_REJECTED,
  GET_RESTAURANT_SPECIALIZATIONS,
  GET_RESTAURANT_SPECIALIZATIONS_PENDING,
  GET_RESTAURANT_SPECIALIZATIONS_FULFILLED,
  GET_RESTAURANT_SPECIALIZATIONS_REJECTED,
  GET_RESTAURANT_MENU_CLASSES,
  GET_RESTAURANT_MENU_CLASSES_PENDING,
  GET_RESTAURANT_MENU_CLASSES_FULFILLED,
  GET_RESTAURANT_MENU_CLASSES_REJECTED,
  GET_RESTAURANT_MENU_BY_ID,
  GET_RESTAURANT_MENU_BY_ID_PENDING,
  GET_RESTAURANT_MENU_BY_ID_FULFILLED,
  GET_RESTAURANT_MENU_BY_ID_REJECTED,
  GET_ACCOMMODATION_FACILITIES,
  GET_ACCOMMODATION_FACILITIES_PENDING,
  GET_ACCOMMODATION_FACILITIES_FULFILLED,
  GET_ACCOMMODATION_FACILITIES_REJECTED,
  GET_TRANSPORTATION_PROFILE_RATINGS,
  GET_TRANSPORTATION_PROFILE_RATINGS_PENDING,
  GET_TRANSPORTATION_PROFILE_RATINGS_FULFILLED,
  GET_TRANSPORTATION_PROFILE_RATINGS_REJECTED,
  GET_TRANSPORTATION_PROFILE_CATEGORIES,
  GET_TRANSPORTATION_PROFILE_CATEGORIES_PENDING,
  GET_TRANSPORTATION_PROFILE_CATEGORIES_FULFILLED,
  GET_TRANSPORTATION_PROFILE_CATEGORIES_REJECTED,
  GET_TRANSPORTATION_PROFILES,
  GET_TRANSPORTATION_PROFILES_PENDING,
  GET_TRANSPORTATION_PROFILES_FULFILLED,
  GET_TRANSPORTATION_PROFILES_REJECTED,
  GET_TRANSPORTATION_TYPES,
  GET_TRANSPORTATION_TYPES_PENDING,
  GET_TRANSPORTATION_TYPES_FULFILLED,
  GET_TRANSPORTATION_TYPES_REJECTED,
  GET_TRANSPORTATION_SEAT_TYPES,
  GET_TRANSPORTATION_SEAT_TYPES_PENDING,
  GET_TRANSPORTATION_SEAT_TYPES_FULFILLED,
  GET_TRANSPORTATION_SEAT_TYPES_REJECTED,
  GET_TRANSPORTATION_UNITS_FILTER,
  GET_TRANSPORTATION_UNITS_FILTER_PENDING,
  GET_TRANSPORTATION_UNITS_FILTER_FULFILLED,
  GET_TRANSPORTATION_UNITS_FILTER_REJECTED,
  SET_TEMP_FILTER_QUICK_LAUNCH,
  RESET_STATUS_TRANSACTION,
  RESET_STATUS_ACCOMMODATION,
  RESET_STATUS_EXCURSION,
  RESET_STATUS_GET_CITY_IN_COUNTRY,
  GET_FIXED_PACKAGE_BY_ID,
  GET_FIXED_PACKAGE_BY_ID_PENDING,
  GET_FIXED_PACKAGE_BY_ID_FULFILLED,
  GET_FIXED_PACKAGE_BY_ID_REJECTED,
  RESET_GET_FIXED_PACKAGE_BY_ID_STATUS,
  RESET_STATUS_RESTAURANT,
  RESET_STATUS_TRANSPORTATION,
  RESET_STATUS_TRANSPORTATION_UNITS_FILTER,
  RESET_STATUS_RESTAURANTS_BY_FILTER,
  GET_ACCOMMODATION_PROFILE_BY_ID,
  GET_ACCOMMODATION_PROFILE_BY_ID_PENDING,
  GET_ACCOMMODATION_PROFILE_BY_ID_REJECTED,
  GET_ACCOMMODATION_PROFILE_BY_ID_FULFILLED,
  RESET_GET_ACCOMMODATION_PROFILE_BY_ID,
  GET_GUEST_TITLE_TYPE,
  GET_GUEST_TITLE_TYPE_PENDING,
  GET_GUEST_TITLE_TYPE_REJECTED,
  GET_GUEST_TITLE_TYPE_FULFILLED,
  GET_IDENTITY_TYPE,
  GET_IDENTITY_TYPE_PENDING,
  GET_IDENTITY_TYPE_REJECTED,
  GET_IDENTITY_TYPE_FULFILLED,
  SELECT_ACCOMMODATION_PROFILE,
  POST_DEMO_JOIN_TOUR,
  POST_DEMO_JOIN_TOUR_PENDING,
  POST_DEMO_JOIN_TOUR_REJECTED,
  POST_DEMO_JOIN_TOUR_FULFILLED,
  POST_DEMO_PRICE,
  POST_DEMO_PRICE_PENDING,
  POST_DEMO_PRICE_REJECTED,
  POST_DEMO_PRICE_FULFILLED,
  GET_TOUR_OPERATOR_PROFILE,
  GET_TOUR_OPERATOR_PROFILE_PENDING,
  GET_TOUR_OPERATOR_PROFILE_FULFILLED,
  GET_TOUR_OPERATOR_PROFILE_REJECTED,
  SET_TEMP_DEMO_JOIN_TOUR,
  GET_TRANSACTION_HISTORY_ALL,
  GET_TRANSACTION_HISTORY_ALL_PENDING,
  GET_TRANSACTION_HISTORY_ALL_FULFILLED,
  GET_TRANSACTION_HISTORY_ALL_REJECTED,
  GET_COMPANY_PROFILE,
  GET_COMPANY_PROFILE_PENDING,
  GET_COMPANY_PROFILE_FULFILLED,
  GET_COMPANY_PROFILE_REJECTED,
  POST_TOUR_TRANSACTION_CONFIRMATION,
  POST_TOUR_TRANSACTION_CONFIRMATION_PENDING,
  POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED,
  POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED,
  RESET_STATUS_TOUR_TRANSACTION_CONFIRMATION,
  RESET_STATUS_TRANSACTION_HISTORY_ALL,
  GET_TOUR_TRANSACTION_SUMMARY,
  GET_TOUR_TRANSACTION_SUMMARY_PENDING,
  GET_TOUR_TRANSACTION_SUMMARY_FULFILLED,
  GET_TOUR_TRANSACTION_SUMMARY_REJECTED,
  RESET_STATUS_TOUR_TRANSACTION_SUMMARY,
  SELECT_TOUR_TRANSACTION_SUMMARY,
  RESET_SELECT_TOUR_TRANSACTION_SUMMARY,
  GET_SCHEDULE_TOUR_TRANSACTION,
  GET_SCHEDULE_TOUR_TRANSACTION_PENDING,
  GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED,
  GET_SCHEDULE_TOUR_TRANSACTION_REJECTED,
  GET_INVOICE,
  GET_INVOICE_PENDING,
  GET_INVOICE_FULFILLED,
  GET_INVOICE_REJECTED,
  RESET_STATUS_INVOICE_AND_SCHEDULE,
  GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID,
  GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_PENDING,
  GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_FULFILLED,
  GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_REJECTED,
  GET_TOUR_TRANSACTION_TO_BE_PAYED,
  GET_TOUR_TRANSACTION_TO_BE_PAYED_PENDING,
  GET_TOUR_TRANSACTION_TO_BE_PAYED_FULFILLED,
  GET_TOUR_TRANSACTION_TO_BE_PAYED_REJECTED,
  RESET_SELECT_ACCOMMODATION_PROFILE,
  // GET_TRANSPORTATION_UNIT_BY_ID,
  // GET_TRANSPORTATION_UNIT_BY_ID_PENDING,
  // GET_TRANSPORTATION_UNIT_BY_ID_FULFILLED,
  // GET_TRANSPORTATION_UNIT_BY_ID_REJECTED,
  // RESET_STATUS_TRANSPORTATION_UNIT_BY_ID,
  GET_EXCURSION_BY_ID,
  GET_EXCURSION_BY_ID_PENDING,
  GET_EXCURSION_BY_ID_FULFILLED,
  GET_EXCURSION_BY_ID_REJECTED,
  GET_MENU_BY_ID,
  GET_MENU_BY_ID_PENDING,
  GET_MENU_BY_ID_FULFILLED,
  GET_MENU_BY_ID_REJECTED,
  SET_GUESTS_AMOUNT,
  SET_ROOMALLOCATION,
  SET_TOUR_NAME,
  SET_HEADLINE_PROGRAM,
  SET_FIRST_DESTINATION,
  GET_PLACE_BY_REGION,
  GET_PLACE_BY_REGION_FULFILLED,
  GET_PLACE_BY_REGION_PENDING,
  GET_PLACE_BY_REGION_REJECTED,
  RESET_GET_PLACE_BY_REGION,
  SET_ACCOMMODATION_ONCHANGE_INDEX,
  RESET_ACCOMMODATION_ONCHANGE_INDEX,
  SET_DAILYPROGRAM,
  RESET_SET_HEADLINE_STATUS,
  GET_MOVEMENT_LIST,
  GET_MOVEMENT_LIST_PENDING,
  GET_MOVEMENT_LIST_FULFILLED,
  GET_MOVEMENT_LIST_REJECTED,
  GET_DURATION_ID_TO_ID,
  GET_DURATION_ID_TO_ID_PENDING,
  GET_DURATION_ID_TO_ID_FULFILLED,
  GET_DURATION_ID_TO_ID_REJECTED,
  GET_DURATION_ID_TO_ADDRESS,
  GET_DURATION_ID_TO_ADDRESS_PENDING,
  GET_DURATION_ID_TO_ADDRESS_FULFILLED,
  GET_DURATION_ID_TO_ADDRESS_REJECTED,
  GET_DURATION_ADDRESS_TO_ID,
  GET_DURATION_ADDRESS_TO_ID_PENDING,
  GET_DURATION_ADDRESS_TO_ID_FULFILLED,
  GET_DURATION_ADDRESS_TO_ID_REJECTED,
  RESET_GET_DURATION_STATUS,
  RESET_MAPPING_DURATION,
  SET_DRIVING_DATA,
  RESET_DRIVING_DATA,
  RESET_COUNT_GET_DURATION,
  SET_CURRENT_PACKAGE,
  PUT_UPDATE_COMPANY,
  PUT_UPDATE_COMPANY_PENDING,
  PUT_UPDATE_COMPANY_FULFILLED,
  PUT_UPDATE_COMPANY_REJECTED,
  RESET_UPDATE_COMPANY_STATUS,
  POST_REGISTER,
  POST_REGISTER_PENDING,
  POST_REGISTER_FULFILLED,
  POST_REGISTER_REJECTED,
  POST_ACCEPT_QUOTATION,
  POST_ACCEPT_QUOTATION_PENDING,
  POST_ACCEPT_QUOTATION_FULFILLED,
  POST_ACCEPT_QUOTATION_REJECTED,
  RESET_STATUS_QUOTATION,
  POST_EDIT_QUOTATION,
  POST_EDIT_QUOTATION_PENDING,
  POST_EDIT_QUOTATION_FULFILLED,
  POST_EDIT_QUOTATION_REJECTED,
  POST_CREATE_TOUR,
  POST_CREATE_TOUR_PENDING,
  POST_CREATE_TOUR_FULFILLED,
  POST_CREATE_TOUR_REJECTED,
  POST_JOIN_TOUR,
  POST_JOIN_TOUR_PENDING,
  POST_JOIN_TOUR_FULFILLED,
  POST_JOIN_TOUR_REJECTED,
  SET_TEMP_ID_TOUR,
  GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM,
  GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_PENDING,
  GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_FULFILLED,
  GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_REJECTED,
  RESET_COMPANY_PROFILE_STATUS,
  SET_GUEST_LIST,
  SET_COUNT_GET_DURATION,
  SET_MAPPING_OBJ,
  GET_TOUR_OPERATOR_LIST,
  GET_TOUR_OPERATOR_LIST_PENDING,
  GET_TOUR_OPERATOR_LIST_REJECTED,
  GET_TOUR_OPERATOR_LIST_FULFILLED,
  GET_READY_PACKAGE_BY_ID,
  GET_READY_PACKAGE_BY_ID_PENDING,
  GET_READY_PACKAGE_BY_ID_REJECTED,
  GET_READY_PACKAGE_BY_ID_FULFILLED,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED,
  RESET_TEMP_DEMO_JOIN_TOUR,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_PENDING,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_FULFILLED,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_REJECTED,
  SET_TEMP_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2,
  SET_TEMP_DEMO_PRICE,
  RESET_TEMP_DEMO_PRICE,
  POST_TOUR_TRANSACTIONS_CONFIRMATION,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_PENDING,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_FULFILLED,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_REJECTED,
  POST_TOUR_TRANSACTIONS_TOP_UP,
  POST_TOUR_TRANSACTIONS_TOP_UP_PENDING,
  POST_TOUR_TRANSACTIONS_TOP_UP_FULFILLED,
  POST_TOUR_TRANSACTIONS_TOP_UP_REJECTED,
  RESET_SET_DAILY_PROGRAM_STATUS,
  RESET_GET_READY_PACKAGE_BY_ID_STATUS,
  RESET_CUSTOM_PACKAGE,
  RESET_DEMO_PRICE,
  GET_TOUR_TRANSACTION_BILLED,
  GET_TOUR_TRANSACTION_BILLED_PENDING,
  GET_TOUR_TRANSACTION_BILLED_FULFILLED,
  GET_TOUR_TRANSACTION_BILLED_REJECTED,
  POST_FORGOT_PASSWORD,
  POST_FORGOT_PASSWORD_PENDING,
  POST_FORGOT_PASSWORD_FULFILLED,
  POST_FORGOT_PASSWORD_REJECTED,
  POST_RESET_PASSWORD,
  POST_RESET_PASSWORD_FULFILLED,
  POST_RESET_PASSWORD_PENDING,
  POST_RESET_PASSWORD_REJECTED,
  GET_USER_ROLES,
  GET_USER_ROLES_PENDING,
  GET_USER_ROLES_FULFILLED,
  GET_USER_ROLES_REJECTED,
  GET_COMPANIES,
  GET_COMPANIES_PENDING,
  GET_COMPANIES_FULFILLED,
  GET_COMPANIES_REJECTED,
  POST_CREATE_TOUR_ON_BE_HALF,
  POST_CREATE_TOUR_ON_BE_HALF_PENDING,
  POST_CREATE_TOUR_ON_BE_HALF_FULFILLED,
  POST_CREATE_TOUR_ON_BE_HALF_REJECTED,
  POST_JOIN_TOUR_ON_BE_HALF,
  POST_JOIN_TOUR_ON_BE_HALF_PENDING,
  POST_JOIN_TOUR_ON_BE_HALF_FULFILLED,
  POST_JOIN_TOUR_ON_BE_HALF_REJECTED,
  POST_REGISTER_SIMPLE_CUSTOMER,
  POST_REGISTER_SIMPLE_CUSTOMER_PENDING,
  POST_REGISTER_SIMPLE_CUSTOMER_FULFILLED,
  POST_REGISTER_SIMPLE_CUSTOMER_REJECTED,
  RESET_REGISTER_SIMPLE_CUSTOMER,
  SET_TEMP_BACK_CUSTOM_READY,
  RESET_TEMP_BACK_CUSTOM_READY,
  SET_TEMP_TOUR_CUSTOM_READY,
  RESET_FIRST_DESTINATION,
  RESET_GUESTS,
  RESET_ROOM_ALLOCATION,
  GET_FIXED_PACKAGE_LANDING_PAGE,
  GET_FIXED_PACKAGE_LANDING_PAGE_PENDING,
  GET_FIXED_PACKAGE_LANDING_PAGE_FULFILLED,
  GET_FIXED_PACKAGE_LANDING_PAGE_REJECTED,
  RESET_FIXED_PACKAGE_LANDING_PAGE_STATUS,
  GET_CAROUSEL_IMAGE_AFTER_LOGIN,
  GET_CAROUSEL_IMAGE_AFTER_LOGIN_PENDING,
  GET_CAROUSEL_IMAGE_AFTER_LOGIN_FULFILLED,
  GET_CAROUSEL_IMAGE_AFTER_LOGIN_REJECTED,
  GET_GUEST_LIST_TEMPLATE,
  GET_GUEST_LIST_TEMPLATE_PENDING,
  GET_GUEST_LIST_TEMPLATE_FULFILLED,
  GET_GUEST_LIST_TEMPLATE_REJECTED,
  GET_INVOICE_TRAVEL_AGENT,
  GET_INVOICE_TRAVEL_AGENT_PENDING,
  GET_INVOICE_TRAVEL_AGENT_FULFILLED,
  GET_INVOICE_TRAVEL_AGENT_REJECTED,
  GET_INVOICE_CUSTOMER,
  GET_INVOICE_CUSTOMER_PENDING,
  GET_INVOICE_CUSTOMER_FULFILLED,
  GET_INVOICE_CUSTOMER_REJECTED,
  SET_COORDINATE_ADDRESS,
  RESET_TEMP_FILTER_QUICK_LAUNCH,
  UPDATE_COORDINATE_ADDRESS,
  SET_EXPIRED_TOKEN,
  GET_LANGUAGE_ALL,
  GET_LANGUAGE_ALL_PENDING,
  GET_LANGUAGE_ALL_FULFILLED,
  GET_LANGUAGE_ALL_REJECTED,
  GET_VOUCHER_CONFIRMATION,
  GET_VOUCHER_CONFIRMATION_PENDING,
  GET_VOUCHER_CONFIRMATION_FULFILLED,
  GET_VOUCHER_CONFIRMATION_REJECTED,
  GET_ACCOMMODATION_FILTER_PARAMETER,
  GET_ACCOMMODATION_FILTER_PARAMETER_PENDING,
  GET_ACCOMMODATION_FILTER_PARAMETER_FULFILLED,
  GET_ACCOMMODATION_FILTER_PARAMETER_REJECTED,
  UPDATE_COORDINATE_CENTER,
  GET_TOUR_TRANSACTION_BILLS,
  GET_TOUR_TRANSACTION_BILLS_PENDING,
  GET_TOUR_TRANSACTION_BILLS_FULFILLED,
  GET_TOUR_TRANSACTION_BILLS_REJECTED,
  RESET_DEMO_JOIN_TOUR,
  //===========GET_TRANSACTION_HISTORY==========
  GET_TRANSACTION_HISTORY_CREATED,
  GET_TRANSACTION_HISTORY_CREATED_PENDING,
  GET_TRANSACTION_HISTORY_CREATED_FULFILLED,
  GET_TRANSACTION_HISTORY_CREATED_REJECTED,
  GET_TRANSACTION_HISTORY_INPAYMENT,
  GET_TRANSACTION_HISTORY_INPAYMENT_PENDING,
  GET_TRANSACTION_HISTORY_INPAYMENT_FULFILLED,
  GET_TRANSACTION_HISTORY_INPAYMENT_REJECTED,
  GET_TRANSACTION_HISTORY_CONFIRMED,
  GET_TRANSACTION_HISTORY_CONFIRMED_PENDING,
  GET_TRANSACTION_HISTORY_CONFIRMED_FULFILLED,
  GET_TRANSACTION_HISTORY_CONFIRMED_REJECTED,
  GET_TRANSACTION_HISTORY_COMPLETED,
  GET_TRANSACTION_HISTORY_COMPLETED_PENDING,
  GET_TRANSACTION_HISTORY_COMPLETED_FULFILLED,
  GET_TRANSACTION_HISTORY_COMPLETED_REJECTED,
  GET_TRANSACTION_HISTORY_CANCELLED,
  GET_TRANSACTION_HISTORY_CANCELLED_PENDING,
  GET_TRANSACTION_HISTORY_CANCELLED_FULFILLED,
  GET_TRANSACTION_HISTORY_CANCELLED_REJECTED,
  GET_TRANSACTION_HISTORY_DRAFT,
  GET_TRANSACTION_HISTORY_DRAFT_PENDING,
  GET_TRANSACTION_HISTORY_DRAFT_FULFILLED,
  GET_TRANSACTION_HISTORY_DRAFT_REJECTED,
  GET_FEATURED_PACKAGE_BEFORE_LOGIN,
  GET_FEATURED_PACKAGE_BEFORE_LOGIN_PENDING,
  GET_FEATURED_PACKAGE_BEFORE_LOGIN_FULFILLED,
  GET_FEATURED_PACKAGE_BEFORE_LOGIN_REJECTED,
  GET_FEATURED_PACKAGE_AFTER_LOGIN,
  GET_FEATURED_PACKAGE_AFTER_LOGIN_PENDING,
  GET_FEATURED_PACKAGE_AFTER_LOGIN_FULFILLED,
  GET_FEATURED_PACKAGE_AFTER_LOGIN_REJECTED,
  GET_PLACE_BY_PLACE_TYPE_ID,
  GET_PLACE_BY_PLACE_TYPE_ID_PENDING,
  GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED,
  GET_PLACE_BY_PLACE_TYPE_ID_REJECTED,
  CHANGE_LOGIN_FLAG,
};
