import React from 'react';
import { Card, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FilterContent from './FilterContent';
import FilterHeader from './FilterHeader';
import Loader from '../../../../components/common/loader';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const FormFilter = props => {
  const { t } = useTranslation();
  let { formFilter, handleChange, resetFilter, loading } = props;
  return (
    <Card style={styles.segmentNoPadding}>
      {/* "Filter" */}
      <FilterHeader header={t('filter')} resetFilter={resetFilter} />
      {loading && (
        <Segment style={styles.segmentLoader}>
          <Loader active={loading} inverted={true} />
        </Segment>
      )}
      {!loading &&
        formFilter.map((item, index) =>
          item.data.length > 0 ? (
            <FilterContent
              key={index}
              columns={1}
              header={item.name}
              data={item.data}
              gridStyles={styles.marginTopBottom15}
              columnStyles={styles.paddingTopBottom5}
              handleChange={handleChange}
              name={item.name}
            />
          ) : null
        )}
    </Card>
  );
};
FormFilter.propTypes = {
  loading: PropTypes.bool,
  formFilter: PropTypes.array,
  handleChange: PropTypes.func,
  resetFilter: PropTypes.func,
  t: PropTypes.func,
};

export default FormFilter;
