import React from 'react';
import { Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const TotalDataTable = props => {
  const { t } = useTranslation();
  return (
    <div style={styles.marginAuto}>
      {props.pagedData && props.pagedData.length > 1 && (
        <Header as="h5">
          {/* Showing {props.numbersTo} to {props.numbersFrom} of */}
          {/* {props.data.length} */}
          {`${t('showing')} ${props.numbersTo} ${t('to_2')} ${
            props.numbersFrom
          } ${t('of')} ${props.data.length}`}
        </Header>
      )}
    </div>
  );
};
TotalDataTable.propTypes = {
  pagedData: PropTypes.array,
  numbersTo: PropTypes.number,
  numbersFrom: PropTypes.number,
  data: PropTypes.array,
  t: PropTypes.func,
};

export default TotalDataTable;
