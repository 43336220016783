import * as types from '../actions/actionTypes';

const initialState = {
  cityInCountry: [],
  cityInCountryStatus: '',
  placeByRegion: [],
  getplaceByRegion: '',
  errors: {},
  loading: false,
  places: [],
  coordinates: [],
  coordinate: null,
  placeByType: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_CITY_IN_COUNTRY
    //---------------------------------
    case types.GET_CITY_IN_COUNTRY:
      return { ...state };
    case types.GET_CITY_IN_COUNTRY_PENDING:
      return { ...state, loading: true };
    case types.GET_CITY_IN_COUNTRY_FULFILLED:
      return {
        ...state,
        cityInCountry: action.payload.data,
        errors: {},
        loading: false,
        cityInCountryStatus: 'success',
      };
    case types.GET_CITY_IN_COUNTRY_REJECTED:
      return {
        ...state,
        cityInCountry: [],
        errors: action.payload.response.data,
        loading: false,
        cityInCountryStatus: 'failed',
      };
    case types.RESET_STATUS_GET_CITY_IN_COUNTRY:
      return {
        ...state,
        cityInCountryStatus: '',
      };
    //---------------------------------
    // GET_PLACE_BY_REGION
    //---------------------------------
    case types.GET_PLACE_BY_REGION_PENDING:
      return { ...state, loading: true };
    case types.GET_PLACE_BY_REGION_FULFILLED: {
      let newPlaces = [...state.places];
      if (
        action.payload.data.length > 0 &&
        newPlaces.filter(
          item => item.region === action.payload.data[0].RequestedFromId
        ).length === 0
      ) {
        newPlaces = newPlaces.concat({
          region: action.payload.data[0].RequestedFromId,
          // region: action.payload.data[0].City.Id,
          data: action.payload.data,
        });
      }
      return {
        ...state,
        placeByRegion: action.payload.data,
        places: newPlaces,
        errors: {},
        loading: false,
        getplaceByRegion: 'success',
      };
    }

    case types.GET_PLACE_BY_REGION_REJECTED:
      return {
        ...state,
        placeByRegion: [],
        errors: action.payload.response.data,
        loading: false,
        getplaceByRegion: 'failed',
      };
    case types.RESET_GET_PLACE_BY_REGION:
      return {
        ...state,
        // placeByRegion: [],
        errors: {},
        loading: false,
        getplaceByRegion: '',
      };
    case types.SET_COORDINATE_ADDRESS: {
      let oldCoordinates = [...state.coordinates];
      let findPlace = oldCoordinates.find(item => item.id === action.id);
      return {
        ...state,
        coordinates: findPlace
          ? oldCoordinates
          : oldCoordinates.concat({
              coordinate: action.payload,
              id: action.id,
              placeType: action.placeType,
              isOpen: false,
            }),
        coordinate: action.payload,
        errors: {},
        loading: false,
      };
    }
    case types.UPDATE_COORDINATE_ADDRESS:
      return {
        ...state,
        coordinates: action.payload,
        errors: {},
        loading: false,
      };
    case types.UPDATE_COORDINATE_CENTER:
      return {
        ...state,
        coordinate: action.payload,
      };
    //---------------------------------
    // GET_PLACE_BY_PLACE_TYPE_ID
    //---------------------------------
    case types.GET_PLACE_BY_PLACE_TYPE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_PLACE_BY_PLACE_TYPE_ID_FULFILLED: {
      return {
        ...state,
        placeByType: action.payload.data,
        errors: {},
        loading: false,
      };
    }
    case types.GET_PLACE_BY_PLACE_TYPE_ID_REJECTED:
      return {
        ...state,
        placeByType: [],
        errors: action.payload.response.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
