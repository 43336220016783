import React from 'react';
import { Label } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';

const GoldRibbon = props => (
  <Label
    as="a"
    ribbon
    size={props.size}
    style={
      props.style
        ? props.style.color
          ? props.style
          : styles.goldRibbon
        : styles.goldRibbon
    }
  >
    {props.text}
  </Label>
);

GoldRibbon.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
};
export default GoldRibbon;
