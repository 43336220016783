import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Icon, Grid, Label } from 'semantic-ui-react';
import ImageCard from '../../card/ImageCard';
// import { convertDateFormat } from '../../../../../genericFunction/moment';
import styles from './styles';
import Maps from '../../map/Maps';
import ImagePopup from '../../../../../components/common/ImagePopup';
import { getLocationIcon } from '../../../../../genericFunction/locationsIcon';
import { getServiceIcon } from '../../../../../genericFunction/servicesIcon';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AccommodationDetailCard = props => {
  const { t } = useTranslation();
  // const [openModal, setOpenModal] = useState(false);

  //------store----------
  const coordinate = useSelector(state => state.location.coordinate);

  // const openCloseModal = (e, { value }) => {
  //   openRoomModal(value);
  // };
  // const openRoomModal = value => {
  //   const { headlineProgram, getAccommodationRooms, accomIndex } = props;
  //   let mainProg = headlineProgram.MainPrograms;
  //   // setOpenModal(prevState => !prevState);
  //   let data = {
  //     profileid: value,
  //     requestedDate: convertDateFormat(mainProg[accomIndex].Date, 'MM/DD/YYYY'),
  //     endDate: convertDateFormat(
  //       mainProg[accomIndex].LeavingDate,
  //       'MM/DD/YYYY'
  //     ),
  //   };
  //   getAccommodationRooms(data);
  // };
  // const closeModal = () => setOpenModal(false);

  const renderLocation = AccommodationLocations => {
    return AccommodationLocations
      ? AccommodationLocations.map((item, i) => {
          return (
            <Grid.Column key={`locations${i}`}>
              <ImagePopup
                content={item.Name}
                image={getLocationIcon(item.Id)}
              />
            </Grid.Column>
          );
        })
      : '-';
  };
  const renderFacilities = facilities => {
    return facilities
      ? facilities.map((item, i) => {
          return (
            <Grid.Column key={`facilities${i}`}>
              <ImagePopup content={item.Name} image={getServiceIcon(item.Id)} />
            </Grid.Column>
          );
        })
      : '-';
  };

  let {
    ProfileImages,
    ImageUrl,
    AccommodationType,
    AccommodationRating,
    Address,
    Name,
    OverView,
    ProfileFacilities,
    AccommodationLocations,
  } = props.data;

  let stars = new Array(parseInt(AccommodationRating.Id, 10)).fill(0);
  return (
    <div style={styles.width100percen}>
      <Segment.Group style={styles.segmentNoPadding}>
        <Segment.Group horizontal style={styles.noPaddingNoMargin}>
          <ImageCard images={ProfileImages} image={ImageUrl} size="300px" />
          <Segment style={styles.borderNone}>
            <Header as="h3">{Name}</Header>
            <Label basic style={styles.label}>
              {AccommodationType.Name + ' '}
            </Label>{' '}
            {stars.map((item, index) => (
              <Icon key={item + index} name="star" color="yellow" />
            ))}
            <Grid>
              <Grid.Row>
                <Grid.Column width="1">
                  <Icon name="map marker alternate" style={styles.goldColor} />
                </Grid.Column>
                <Grid.Column width="14" style={styles.justify}>
                  {Address}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
        <Segment style={styles.segmentDescription}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {/* Description */}
                <Header as="h3">{t('description')}</Header>
                <span style={styles.justify}>{OverView}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment style={styles.BorderZero}>
          <Maps
            mapStyle={styles.maps}
            lat={coordinate ? coordinate.lat : null}
            lng={coordinate ? coordinate.lng : null}
            text={Name}
            type="hotel"
          />
        </Segment>
        <Segment style={styles.segmentDescription}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {/* Main Service */}
                      <Header as="h3">{t('mainService')}</Header>
                      <Grid>
                        <Grid.Row columns={12}>
                          {renderFacilities(ProfileFacilities)}
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {/* Nearby */}
                      <Header as="h3">{t('nearby')}</Header>
                      <Grid>
                        <Grid.Row columns={12}>
                          {renderLocation(AccommodationLocations)}
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
    </div>
  );
};

AccommodationDetailCard.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  isPromo: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  address: PropTypes.string,
  id: PropTypes.number,
  facilities: PropTypes.array,
  rating: PropTypes.string,
  getAccommodationRooms: PropTypes.func,
  listAccommodationRoomById: PropTypes.array,
  headlineProgram: PropTypes.object,
  accomIndex: PropTypes.number,
  closeModal: PropTypes.func,
  loading: PropTypes.bool,
  setAccommodationRoom: PropTypes.func,
  data: PropTypes.object,
  coordinate: PropTypes.object,
  t: PropTypes.func,
};

export default AccommodationDetailCard;
