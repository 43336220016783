import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Segment, Image, Grid } from 'semantic-ui-react';
import ForgotPasswordForm from './form/ForgotPasswordForm';
import { postForgotPasswordApi } from '../../api/forgotPasswordApi';
import { BasicConfirmationModal } from '../shared/components/modal/';
import styles from './styles';
import { touressLogoBlack } from '../../assets/logo/index';
import { Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  const [postOk, setPostOk] = useState(null);

  const [message, setMessage] = useState('');

  const [modalTitle, setModalTitle] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [backHome, setBackHome] = useState(false);

  const handleChange = (e, { value }) => {
    setEmail(value);
  };

  const handleSubmit = () => {
    setLoading(true);
    postForgotPasswordApi(email)
      .then(() => {
        setPostOk(true);
        setOpenModal(true);
        // `We've send a link to reset your password to ${email}, please check your email!!`
        setMessage(`${t('sendLinkToResetPassword', { email: email })}`);
        // Reset password link send
        setModalTitle(`${t('resetPasswordLinkSend')}`);
        setLoading(false);
      })
      .catch(() => {
        setPostOk(false);
        setOpenModal(true);
        // `No email with the address ${email} found, please make sure to enter correct email!!'`
        setMessage(`${t('emailNotFound', { email: email })}`);
        // 'ReReset password failed'
        setModalTitle(`${t('reResetPasswordFailed')}`);
        setLoading(false);
      });
  };

  const handleButtonModal = () => {
    setOpenModal(false);
  };

  const handleBackHome = () => setBackHome(true);
  if (backHome) {
    // eslint-disable-next-line
    return <Redirect to="/" />;
  } else {
    return (
      <Container style={styles.width80}>
        <DynamicTitle title="Forgot Password" />
        <Grid style={styles.marginTop20}>
          <Grid.Row>
            <Grid.Column width="4" style={styles.cursorPointer}>
              <Image src={touressLogoBlack} onClick={handleBackHome} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment textAlign="center" style={styles.segment}>
          <ForgotPasswordForm
            label={t('emailAddress')} // Email Address
            handleChange={handleChange}
            value={email}
          />
          <Button
            onClick={handleSubmit}
            style={styles.buttonSubmit}
            loading={loading}
          >
            {/* Submit */}
            {t('submit')}
          </Button>
          <BasicConfirmationModal
            modalOpen={openModal}
            title={modalTitle}
            content={message}
            status={postOk}
            onClick={handleButtonModal}
          />
        </Segment>
      </Container>
    );
  }
};

ForgotPassword.propTypes = {
  t: PropTypes.func,
};

export default ForgotPassword;
