import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Segment,
  Grid,
  Container,
  Image,
} from 'semantic-ui-react';
import UploadButton from '../../../../components/button/BtnUploadImage';
import { noImage } from '../../../../assets/image';
import { ButtonCloseModal } from '../common';
import styles from './styles';
import { withTranslation } from 'react-i18next';

class ModalUploadImage extends Component {
  state = {
    image: null,
    nameImage: null,
    imageUrl: null, // for update
  };

  componentDidUpdate(prevProps) {
    if (prevProps.openModal !== this.props.openModal) {
      this.setState((state, props) => ({ ...state, image: props.image }));
    }
  }
  onChangeImage = e => {
    //   di tampilkan
    this.setState({
      image: URL.createObjectURL(e.target.files[0]),
      nameImage: e.target.files[0].name,
    });
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = async () => {
      let imageInfo = {
        base64: reader.result,
      };
      this.setState({ imageUrl: imageInfo.base64 });
    };
  };
  handleSaveImage = async () => {
    const { nameImage, imageUrl } = this.state;
    const { data } = this.props;
    let newImageUrl = imageUrl ? imageUrl.split(',')[1] : imageUrl;
    await this.props.handleUpdateImage(data, newImageUrl, nameImage);
    this.props.handleClose();
  };
  render() {
    const { t } = this.props;
    const { openModal, handleClose, header } = this.props;
    const { image } = this.state;
    return (
      <Modal inverted open={openModal} onClose={handleClose} size="mini">
        <Modal.Header>
          {header}
          <ButtonCloseModal onClose={handleClose} />
        </Modal.Header>
        <Modal.Content>
          <Segment>
            {/* <InputBase64
            multiple={true}
            accept="image/jpg, image/jpeg, image/png"
            onDone={props.handleChange}
          /> */}
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} style={styles.marginTopBottom2em}>
                  <Image
                    size="medium"
                    src={image ? image : noImage}
                    style={styles.maxHeight250px}
                  />
                </Grid.Column>
                <Grid.Column width={16} textAlign="center">
                  <UploadButton
                    onImageSelect={this.onChangeImage}
                    label={t('chooseImage')} //"Choose Image"
                    styleButton={styles.buttonGold}
                  />
                  <Container
                    content="*maximum image size 5MB"
                    style={styles.colorGreyFontItalic}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('save')} //"Save"
            color="blue"
            onClick={this.handleSaveImage}
            style={styles.buttonGold}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalUploadImage.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  image: PropTypes.string,
  handleUpdateImage: PropTypes.func,
  data: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(ModalUploadImage);
