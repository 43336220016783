import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getFixedPackageById } from '../shared/actions/fixedPackageAction';
import { getTourOperatorProfile } from '../shared/actions/tourOperatorAction';
import { setCurrentPackage } from '../shared/actions/generalAction';
import Loader from '../../components/common/loader';
import { BreadCrumb } from '../shared/components/common';
import { ModalAlert } from '../shared/components/modal';
// import FixedPackageDetail from './components/fixedPackageDetail';
import breadCrumb from './constant/breadCrumb';
import { withTranslation } from 'react-i18next';

//====
import {
  Container,
  Grid,
  Segment,
  Visibility,
  Menu,
  Sticky,
  Button,
} from 'semantic-ui-react';
import TitleAndImageGallery from './components/titleImageGallery/TitleAndImageGallery';
import CustomPackageShortcut from '../../components/common/customPackageShortcut';
import SegmentOperatorAndItemPriceInformation from './components/segmentOperatorAndItemPriceInformation/SegmentOperatorAndItemPriceInformation';
import SegmentDownloadsAndBookNow from './components/segmentDownloadsAndBookNow/SegmentDownloadsAndBookNow';
import SegmentTourForm from './components/segmentTourFrom/SegmentTourFrom';
// import SegmentAccommodation from '../../components/summary/segmentAccommodation';
import { SegmentAccommodation } from '../shared/components/summary';
import SegmentTourDescription from './components/segmentTourDescription/SegmentTourDescription';
import SegmentAdditionalInformation from './components/segmentAdditionalInformation/SegmentAdditionalInformation';
import SegmentFormGuest from '../shared/components/guest/SegmentFormGuest';
import styles from './styles';
import FixedDailyProgram from '../shared/components/dailyProgram/DailyProgram';
import { shortFormatDate, getYear } from '../../genericFunction/moment';
import { getTotalDays, rangeDate } from '../../genericFunction/moment';
import {
  generateGuest,
  changeGuest,
  fillFewGuests,
} from '../../genericFunction/itineraryBuilder/guest';
import {
  validationFillGuest,
  validationTourPax,
  isGuestError,
  isErrorFormPackage,
  isRoomAllocationValid,
  isQuotaValid,
  isCommissionValid,
  validationMinPax,
} from '../../genericFunction/validation';
import {
  setAdditionalItem,
  setAdditionalItemAmount,
} from '../../genericFunction/additionalItem';
import { convertDataBookNowFixed } from '../../genericFunction/convertPackage';
import {
  getSupplementItemByFixedPackageId,
  postDemoJoinTour,
  getIdentityTypes,
  getGuestTitleTypes,
} from '../shared/actions/tourTransactionAction';
import {
  setTempDemoJoinTour,
  setTempIdTour,
  resetTempDemoJoinTour,
} from '../shared/actions/tempAction';
import { Redirect } from 'react-router-dom';
import { getCountries } from '../shared/actions/countriesAction';
import { converNumberToK } from '../../genericFunction/numberConverter';
import { FIXED } from '../../constant/packageTypes';
import getRoomPrices from './service/data/getRoomPrices';
import DynamicTitle from '../../components/common/DynamicTitle';
//====
class SeriesPackage extends Component {
  constructor(props) {
    super(props);
    this.tourFormRef = React.createRef('tourForm');
    this.itineraryRef = React.createRef('itinerary');
    this.accommodationRef = React.createRef('accommodation');
    this.informationRef = React.createRef('information');
    this.guestFormRef = React.createRef('guestForm');
  }
  state = {
    id: null,
    menuFixed: false,
    overlayFixed: false,
    calculations: {
      topPassed: false,
      bottomPassed: false,
      topVisible: false,
      bottomVisible: false,
    },
    bookSegmentCalculations: {
      bottomPassed: false,
    },
    bookNow: false,
    guests: { adults: 0, childs: 0, infants: 0 },
    roomAllocation: {
      sharingRoom: 0,
      singleRoom: 0,
      adultExtraBed: 0,
      childExtraBed: 0,
      sharingBed: 0,
      noBed: 0,
    },
    supplementObject: [],
    splitCommision: null,
    printCommision: 0,
    detailGuest: [],
    errors: {
      firstName: { error: false, message: '' },
      lastName: { error: false, message: '' },
      identityNbr: { error: false, message: '' },
      identityType: { error: false, message: '' },
      guestTitle: { error: false, message: '' },
      countryId: { error: false, message: '' },
    },
    errorFormPackage: {
      tourPax: { error: false, message: [] },
      roomAllocation: { error: false, message: [] },
      quota: { error: false, message: [] },
      commission: { error: false, message: [] },
      minimumPax: { error: false, message: [] },
    },
    isFailed: false,
    openModalAlert: false,
    messageModalAlert: '',
    goToList: false,
    tourNote: null,
  };
  componentDidMount() {
    const props = this.props;
    const { id } = props.match.params;
    // this.getInitialData(id);
    this.setState({ ...this.state, id: id });
    this.props.setCurrentPackage('FIXED');

    this.props.getCountries();
    this.props.getIdentityTypes();
    this.props.getGuestTitleTypes();
    if (
      this.props.dataDemoJoinTour.supplementObject &&
      this.props.idTour === id
    ) {
      this.setDataDemoJoinTour(this.props.dataDemoJoinTour);
    } else {
      this.props.resetTempDemoJoinTour();
      this.props
        .getSupplementItemByFixedPackageId(id)
        .then(() => this.setSupplementState(this.props.supplements, id));
    }
  }
  componentDidUpdate() {
    //the function below is to get package data when user first login in series package page
    this.getPackageAfterLogin();
  }
  getInitialData = id => {
    const context = this;
    this.props
      .getFixedPackageById('FIXED', id)
      .then(res => {
        context.props.getTourOperatorProfile(res.value.data.TourOperator.Id);
      })
      .catch(() => {
        this.setState({
          ...this.state,
          isFailed: true,
          openModalAlert: true,
          messageModalAlert: `${this.props.t('failedGetPackage')}`, // 'Failed get package',
          // messageModalAlert: 'Failed get package',
          headerModalAlert: 'Failed',
        });
      });
  };
  getPackageAfterLogin = () => {
    const { id } = this.props.match.params;
    if (
      this.props.expiredToken &&
      new Date(this.props.expiredToken).getTime() > new Date().getTime() &&
      (_.isEmpty(this.props.fixedPackageById)
        ? true
        : this.props.fixedPackageById.BookingDetailSum.Id !== id) &
        !this.props.loadingFixedPackage &
        !this.state.isFailed
    ) {
      const { id } = this.props.match.params;
      this.getInitialData(id);
      this.props.setCurrentPackage('FIXED');
    }
  };
  handleCloseAlert = () => {
    this.setState({ ...this.state, openModalAlert: false, goToList: true });
  };
  //=====
  setDataDemoJoinTour = dataDemoJoinTour => {
    let {
      splitCommision,
      printCommision,
      guests,
      roomAllocation,
      supplementObject,
      detailGuest,
      tourNote,
    } = dataDemoJoinTour;
    detailGuest &&
      this.setState(
        {
          ...this.state,
          splitCommision,
          printCommision,
          guests,
          roomAllocation,
          supplementObject,
          detailGuest,
          tourNote,
        },
        () => this.setSupplementAmount()
      );
  };
  //===handle function sticky section===
  handleContextRef = contextRef => this.setState({ contextRef });
  handleChangeVisibilityBookSegment = (e, { calculations }) =>
    this.setState({ ...this.state, bookSegmentCalculations: calculations });
  stickTopMenu = () => this.setState({ menuFixed: true });
  unStickTopMenu = () => {
    this.setState({ menuFixed: false });
  };
  //====================================
  handleScrollToElement(e, { myref }) {
    window.scroll({ top: myref.current.offsetTop, behavior: 'smooth' });
  }
  scrollToElement = (errorFormGuest, errorFormPackage) => {
    if (isErrorFormPackage(errorFormPackage)) {
      window.scroll({
        top: this.tourFormRef.current.offsetTop,
        behavior: 'smooth',
      });
    } else if (isGuestError(errorFormGuest)) {
      window.scroll({
        top: this.guestFormRef.current.offsetTop,
        behavior: 'smooth',
      });
    } else {
      let {
        splitCommision,
        printCommision,
        guests,
        roomAllocation,
        supplementObject,
        detailGuest,
        tourNote,
      } = this.state;
      let dataBookNow = convertDataBookNowFixed(
        splitCommision,
        printCommision,
        guests,
        roomAllocation,
        supplementObject,
        detailGuest,
        tourNote
      );
      let { id } = this.props.match.params;
      this.props
        .postDemoJoinTour(id, dataBookNow)
        .then(() => {
          this.props.setTempIdTour(id);
          this.props.setTempDemoJoinTour({
            splitCommision,
            printCommision,
            guests,
            roomAllocation,
            supplementObject,
            detailGuest,
            tourNote,
          });
          this.setState({ ...this.state, bookNow: true });
        })
        .catch(() => {
          this.setState({
            openModalAlert: true,
            messageModalAlert: `${this.props.t('failedGetDemoPricePackage')}`, // 'Failed get demo price package',
            // messageModalAlert: 'Failed get demo price package',
            headerModalAlert: 'Failed',
          });
        });
    }
  };

  handleBookNow = () => {
    let {
      detailGuest,
      guests,
      errors,
      errorFormPackage,
      roomAllocation,
      splitCommision,
    } = this.state;
    let {
      MinimumGuest,
      ConfirmedGuest,
    } = this.props.fixedPackageById.BookingDetailSum.FixedPackage;

    let { MinPax } = this.props.fixedPackageById;
    let newErrors = validationFillGuest(detailGuest, errors);
    let newErrorFormPackage = validationTourPax(guests, errorFormPackage);
    newErrorFormPackage = validationMinPax(guests, errorFormPackage, MinPax);
    newErrorFormPackage = isRoomAllocationValid(
      newErrorFormPackage,
      guests,
      roomAllocation
    );
    newErrorFormPackage = isQuotaValid(
      newErrorFormPackage,
      MinimumGuest,
      ConfirmedGuest,
      guests
    );
    newErrorFormPackage = isCommissionValid(
      newErrorFormPackage,
      splitCommision
    );
    this.setState(
      {
        ...this.state,
        errors: newErrors,
        errorFormPackage: newErrorFormPackage,
      },
      () => this.scrollToElement(this.state.errors, this.state.errorFormPackage)
    );
  };

  getPaymentTerms() {
    let { BookingDetailSum } = this.props.fixedPackageById;
    let paymentTerms = BookingDetailSum
      ? BookingDetailSum.FixedPackage
        ? BookingDetailSum.FixedPackage.PaymentTerms
        : null
      : null;
    const newPaymentTerms = [];
    // eslint-disable-next-line
    paymentTerms && paymentTerms.length > 0 // eslint-disable-next-line
      ? paymentTerms.map(item => {
          newPaymentTerms.push({
            Description: item.Description,
            PaymentPercentage: `${item.PaymentPercentage} %`,
            PaidOn: `${shortFormatDate(item.DueDate)} ${getYear(item.DueDate)}`,
          });
        })
      : null;
    return newPaymentTerms;
  }
  getCities = headlineProgram => {
    let city = [];
    headlineProgram &&
      headlineProgram.MainPrograms.map(
        e =>
          e.City &&
          city.push(
            e.City.Name + ' (' + (e.TotalDays ? e.TotalDays : 0) + 'D) '
          )
      );
    return city;
  };
  //  =========== handle tour form ====================
  setDetailGuest = (name, listGuest, guests) => {
    if (name === 'guests') {
      let newListGuest = generateGuest(listGuest, guests);
      this.setState({ detailGuest: newListGuest });
    }
  };
  handleIncrease = (e, { name, value, objectname }) => {
    const state = { ...this.state };
    let objectInState = state[objectname];
    if (objectname === 'supplementObject') {
      objectInState[name].qty = value + 1;
    } else {
      objectInState[name] = value + 1;
    }
    this.setState({ ...this.state, [objectname]: objectInState }, () =>
      this.setDetailGuest(objectname, this.state.detailGuest, this.state.guests)
    );
    this.setSupplementAmount();
  };
  handleDecrease = (e, { name, value, objectname }) => {
    const state = { ...this.state };
    const objectInState = state[objectname];
    const newValue = value - 1 < 0 ? 0 : value - 1;
    if (objectname === 'supplementObject') {
      objectInState[name].qty = newValue;
    } else {
      objectInState[name] = newValue;
    }
    this.setState({ ...this.state, [objectname]: objectInState }, () =>
      this.setDetailGuest(objectname, this.state.detailGuest, this.state.guests)
    );
    this.setSupplementAmount();
  };
  handleChangeGuestAmount = (e, { name, value, objectname }) => {
    const state = { ...this.state };
    const objectInState = state[objectname];
    const newValue = value === '' ? 0 : parseInt(value, 10);
    if (objectname === 'supplementObject') {
      objectInState[name].qty = newValue;
    } else {
      objectInState[name] = newValue;
    }
    this.setState({ ...this.state, [objectname]: objectInState }, () =>
      this.setDetailGuest(objectname, this.state.detailGuest, this.state.guests)
    );
    this.setSupplementAmount();
  };
  handleChangeRadio = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: value });
  };
  setSupplementState = (supplements, id) => {
    this.setState({
      ...this.state,
      supplementObject: setAdditionalItem(supplements, id),
    });
  };
  setSupplementAmount = () => {
    let { supplementObject, guests } = this.state;
    let newSupplements = setAdditionalItemAmount(supplementObject, guests);
    this.setState({ ...this.state, supplementObject: newSupplements });
  };
  //  =========== end handle tour form =================
  //  =========== handle guest form ====================
  handleChangeGuest = async (e, { name, value, objectname }) => {
    let { detailGuest, errors } = this.state;
    let newListGuest = await changeGuest(name, detailGuest, objectname, value);
    let newErrorsGuests = (await isGuestError(errors))
      ? await validationFillGuest(newListGuest, errors)
      : errors;
    this.setState({
      ...this.state,
      detailGuest: newListGuest,
      errors: newErrorsGuests,
    });
  };

  uploadTemplate = guests => {
    let detailGuest = fillFewGuests(guests.rows, this.state.detailGuest, 'new');
    this.setState({
      ...this.state,
      detailGuest,
    });
  };

  render() {
    const { t } = this.props;
    const {
      menuFixed,
      bookNow,
      guests,
      roomAllocation,
      supplementObject,
      splitCommision,
      printCommision,
      detailGuest,
      errors,
      errorFormPackage,
      contextRef,
      openModalAlert,
      messageModalAlert,
      goToList,
    } = this.state;

    let { fixedPackageById } = this.props;
    fixedPackageById = fixedPackageById ? fixedPackageById : {};
    const {
      BookingDetailSum,
      DailyPrograms,
      Descriptions,
      TourOperator,
      Prices,
      Commissions,
      HeadlineProgram,
      Brochures,
      MinPax,
    } = fixedPackageById;
    const roomPrices = getRoomPrices(Prices);
    const paymentTerms = BookingDetailSum ? this.getPaymentTerms() : [];
    const tourDate = BookingDetailSum
      ? rangeDate(BookingDetailSum.StartDate, BookingDetailSum.EndDate)
      : '-';
    const availablePax = BookingDetailSum
      ? BookingDetailSum.FixedPackage.PaxLeft
      : 0;
    const totalDays = BookingDetailSum
      ? getTotalDays(BookingDetailSum.StartDate, BookingDetailSum.EndDate)
      : 0;
    const cities = HeadlineProgram
      ? this.getCities(HeadlineProgram).join(',')
      : '-';
    const mainAccommodation = HeadlineProgram
      ? HeadlineProgram.MainPrograms.filter(e => e.AccommodationSummary)
      : [];
    const description = BookingDetailSum
      ? BookingDetailSum.Description
      : 'No description';
    if (bookNow) {
      return <Redirect to="/summary/series" />;
    }
    if (goToList) {
      return <Redirect to="/package-list/series/all" />;
    }
    const operator = TourOperator
      ? this.props.tourOperatorList.find(item => item.Id === TourOperator.Id)
      : null;
    return (
      <Container
        style={styles.marginBotFifteenRelative}
        className="padding-side-twentyfive container-fit"
      >
        <DynamicTitle
          title={
            fixedPackageById &&
            fixedPackageById.BookingDetailSum &&
            fixedPackageById.BookingDetailSum.Title
          }
        />
        <BreadCrumb sections={breadCrumb} />
        <Visibility
          onBottomPassed={this.stickTopMenu}
          onBottomVisible={this.unStickTopMenu}
          once={false}
          style={styles.zIndex1}
        >
          <Menu
            borderless
            className={menuFixed ? 'fixed' : ''}
            style={
              menuFixed ? styles.fixedMenuStyle : styles.displayNone // fixed={menuFixed && 'top'}
            }
          >
            <Container style={styles.menuContainerStyle}>
              <Menu.Item
                myref={
                  this.tourFormRef // as="a"
                }
                onClick={this.handleScrollToElement}
              >
                {/* Tour Form */}
                <strong style={styles.goldColor}>{t('tourForm')}</strong>
              </Menu.Item>
              <Menu.Item
                myref={
                  this.itineraryRef // as="a"
                }
                onClick={this.handleScrollToElement}
              >
                {/* Itinerary */}
                <strong style={styles.goldColor}>{t('itinerary')}</strong>
              </Menu.Item>
              <Menu.Item
                myref={
                  this.accommodationRef // as="a"
                }
                onClick={this.handleScrollToElement}
              >
                {/* Accommodations */}
                <strong style={styles.goldColor}>{t('accommodations')}</strong>
              </Menu.Item>
              <Menu.Item
                myref={
                  this.informationRef // as="a"
                }
                onClick={this.handleScrollToElement}
              >
                {/* Information */}
                <strong style={styles.goldColor}>{t('information')}</strong>
              </Menu.Item>
              <Menu.Item
                myref={
                  this.guestFormRef // as="a"
                }
                onClick={this.handleScrollToElement}
              >
                <strong style={styles.goldColor}>{t('guest')}</strong>
              </Menu.Item>
              <Menu.Item style={styles.menuPriceStyle}>
                <strong>
                  {!_.isEmpty(fixedPackageById)
                    ? `${fixedPackageById.Prices.CurrencyId} ${converNumberToK(
                        fixedPackageById.Prices.SharingRoomPrice
                      )}/pax`
                    : '-'}
                </strong>
              </Menu.Item>
              {this.state.bookSegmentCalculations.bottomPassed && (
                <Menu.Item
                  onClick={this.handleBookNow}
                  style={styles.paddingFive}
                >
                  <Button style={styles.backgroundPrimaryWithBorder}>
                    {/* Book Now */}
                    {t('bookNow')}
                  </Button>
                </Menu.Item>
              )}
            </Container>
          </Menu>
        </Visibility>
        <div ref={this.handleContextRef}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={9}>
                <TitleAndImageGallery package={fixedPackageById} />
                <SegmentTourDescription description={description} />
                <SegmentOperatorAndItemPriceInformation
                  roomPrices={roomPrices}
                  paymentTerms={paymentTerms}
                  tourOperator={operator}
                />
                <div ref={this.itineraryRef} style={styles.marginTopFifteen}>
                  <Segment style={styles.segmentNoPadding}>
                    {/* <h2>Tour Itinerary</h2> */}
                    <h2>{t('tourItinerary')}</h2>
                    <FixedDailyProgram
                      currentPackage={FIXED}
                      dailyProgram={DailyPrograms ? DailyPrograms : []}
                    />
                  </Segment>
                </div>
                <div
                  ref={this.accommodationRef}
                  style={styles.marginTopFifteen}
                >
                  <SegmentAccommodation mainPrograms={mainAccommodation} />
                </div>
                <div ref={this.informationRef} style={styles.marginTopFifteen}>
                  <SegmentAdditionalInformation
                    descriptions={Descriptions ? Descriptions : []}
                  />
                </div>
                <div ref={this.guestFormRef} />
              </Grid.Column>
              <Grid.Column width={7} style={styles.zeroZIndex}>
                <div ref={this.tourFormRef}>
                  <SegmentTourForm
                    prices={Prices}
                    tourDate={tourDate}
                    availablePax={availablePax}
                    guests={guests}
                    roomAllocation={roomAllocation}
                    supplements={supplementObject}
                    splitCommision={splitCommision}
                    printCommision={printCommision}
                    commissions={Commissions}
                    handleIncrease={this.handleIncrease}
                    handleDecrease={this.handleDecrease}
                    handleChangeRadio={this.handleChangeRadio}
                    handleChangeGuestAmount={this.handleChangeGuestAmount}
                    errors={errorFormPackage}
                    minPax={MinPax}
                  />
                </div>
                <Sticky context={contextRef} offset={50}>
                  <Visibility onUpdate={this.handleChangeVisibilityBookSegment}>
                    <SegmentDownloadsAndBookNow
                      tourDate={tourDate}
                      availablePax={availablePax}
                      transactionId={
                        BookingDetailSum ? BookingDetailSum.Id : null
                      }
                      title={BookingDetailSum ? BookingDetailSum.Title : '-'}
                      totalDays={totalDays}
                      handleBookNow={this.handleBookNow}
                      cities={cities}
                      brochures={Brochures}
                      fixedPackageById={fixedPackageById}
                      id={this.props.match.params.id}
                      currency={fixedPackageById.Prices?.CurrencyId}
                    />
                  </Visibility>
                  <CustomPackageShortcut />
                </Sticky>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Grid>
          <Grid.Column width={16} style={styles.paddingZero}>
            <SegmentFormGuest
              guests={detailGuest}
              handleChange={this.handleChangeGuest}
              errors={errors}
              uploadTemplate={this.uploadTemplate}
            />
          </Grid.Column>
        </Grid>
        <Loader
          description="loading"
          active={this.props.loadingFixedPackage}
          page={true}
        />
        <ModalAlert
          openModal={openModalAlert}
          close={this.handleCloseAlert}
          size="tiny"
          message={messageModalAlert}
        />
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    fixedPackageById: state.fixedPackage.fixedPackageById,
    loadingFixedPackage: state.fixedPackage.loading,
    login_status: state.authentication.login_status,
    expiredToken: state.authentication.expiredToken,
    //
    supplements: state.tourTransaction.supplementItemByFixedPackageId,
    dataDemoJoinTour: state.temp.dataDemoJoinTour,
    idTour: state.temp.idTour,
    tourOperatorList: state.tourOperator.tourOperatorProfileList,
  };
};
SeriesPackage.propTypes = {
  fixedPackageById: PropTypes.object,
  match: PropTypes.object,
  getFixedPackageById: PropTypes.func,
  loadingFixedPackage: PropTypes.bool,
  loadingAccommodation: PropTypes.bool,
  setCurrentPackage: PropTypes.func,
  login_status: PropTypes.string,
  expiredToken: PropTypes.string,

  package: PropTypes.object,
  id: PropTypes.string,
  getSupplementItemByFixedPackageId: PropTypes.func,
  supplements: PropTypes.array,
  setTempIdTour: PropTypes.func,
  setTempDemoJoinTour: PropTypes.func,
  postDemoJoinTour: PropTypes.func,
  dataDemoJoinTour: PropTypes.object,
  idTour: PropTypes.number,
  resetTempDemoJoinTour: PropTypes.func,
  getCountries: PropTypes.func,
  getIdentityTypes: PropTypes.func,
  getGuestTitleTypes: PropTypes.func,
  tourOperatorList: PropTypes.array,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    getFixedPackageById,
    setCurrentPackage,
    getTourOperatorProfile,

    getSupplementItemByFixedPackageId,
    postDemoJoinTour,
    setTempDemoJoinTour,
    setTempIdTour,
    resetTempDemoJoinTour,
    getCountries,
    getIdentityTypes,
    getGuestTitleTypes,
  }
)(withTranslation()(SeriesPackage));
