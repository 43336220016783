import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ImageGalery from './ImageGallerys';
import ImageLightBox from '../../../../components/common/imageLightBox';
import options from '../../../../genericFunction/imageListConverter';
import styles from './styles';
import ImageProgressive from '../../../../components/progressiveImage/ImageProgressive';
import { noImage } from '../../../../assets/image/index';

class TitleAndImageGallery extends Component {
  getImageArray(imageUrl) {
    return [options('images', imageUrl)];
  }
  render() {
    const { Images, BookingDetailSum } = this.props.package;
    return (
      <Segment style={styles.segmentNoPadding}>
        <h2>{BookingDetailSum ? BookingDetailSum.Title : '-'}</h2>
        {Images && Images.length > 0 ? (
          <div>
            <ImageGalery images={Images} id={BookingDetailSum.Id} />
          </div>
        ) : BookingDetailSum && BookingDetailSum.City.ImageUrl ? (
          <ImageLightBox
            images={this.getImageArray(BookingDetailSum.City.ImageUrl)}
            imageStyle={styles.lightBoxImageStyle}
          />
        ) : (
          <ImageProgressive
            src={noImage}
            preview={noImage}
            styles={styles.imageProgresivePadding}
          />
        )}
      </Segment>
    );
  }
}

TitleAndImageGallery.propTypes = {
  package: PropTypes.object,
  Images: PropTypes.array,
};
export default TitleAndImageGallery;
