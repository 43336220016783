import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
const PrivateRoute = ({ component: Component, ...rest }) => {
  const local = localStorage.getItem('token');
  const expiredtoken = localStorage.getItem('expiredToken');
  return (
    <Route
      {...rest}
      render={props =>
        !local ||
        !expiredtoken ||
        (expiredtoken &&
          new Date(expiredtoken).getTime() < new Date().getTime()) ? (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.string,
  location: PropTypes.string,
};
export default PrivateRoute;
