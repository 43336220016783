import React from 'react';
import {
  Segment,
  Grid,
  Form,
  Checkbox,
  Message,
  Icon,
} from 'semantic-ui-react';
import styles from './styles';
import InputAmount from '../../../../components/input/Amount';
import LineDivider from '../../../../components/common/lineDivider';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { isErrorFormPackage } from '../../../../genericFunction/validation';
import HeaderInfo from './HeaderInfo';
import { TextMoney } from '../../../shared/components/common';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const getTotalGuest = guests => {
  return guests.adults + guests.childs + guests.infants;
};
const getCommission = (commissions, splitCommision, printCommision) => {
  const newCommissions = [];
  // eslint-disable-next-line
  commissions &&
  commissions.length > 0 &&
  (splitCommision === 0 || splitCommision === 1) // eslint-disable-next-line
    ? commissions.map(item => {
        let category = item.Category.replace('_', '');
        category = category.charAt(0).toLowerCase() + category.slice(1);
        return splitCommision === 1
          ? item.Category === 'Agent_Commission' &&
              newCommissions.push({
                name: i18next.t(category), //item.Category.replace('_', ' '),
                value: item.Value,
              })
          : splitCommision === 0 && printCommision === 0
          ? newCommissions.length === 0
            ? newCommissions.push({
                name: i18next.t('totalCommission'), // 'Total Commission',
                value: item.Value,
              })
            : (newCommissions[0].value = newCommissions[0].value + item.Value)
          : newCommissions.push({
              name: i18next.t(category), //item.Category.replace('_', ' '),
              value: item.Value,
            });
      })
    : null;
  return newCommissions;
};
const getTotalAllocatedGuest = roomAllocation => {
  return (
    roomAllocation.sharingRoom +
    roomAllocation.singleRoom +
    roomAllocation.adultExtraBed +
    roomAllocation.childExtraBed +
    roomAllocation.sharingBed +
    roomAllocation.noBed
  );
};
const getError = errors => {
  return errors.tourPax.error
    ? errors.tourPax.message
    : errors.roomAllocation.error
    ? errors.roomAllocation.message
    : errors.quota.error
    ? errors.quota.message
    : errors.minimumPax.error
    ? errors.minimumPax.message
    : errors.commission.error && errors.commission.message;
};
const SegmentTourForm = props => {
  const { t } = useTranslation();
  let {
    commissions,
    prices,
    tourDate,
    availablePax,
    guests,
    roomAllocation,
    supplements,
    splitCommision,
    printCommision,
    handleIncrease,
    handleDecrease,
    handleChangeGuestAmount,
    handleChangeRadio,
    errors,
    minPax,
    //additional for variable date
    isVariableDate,
    handleChangeSelectedDate,
    selectedDate,
    isNotValidDate,
  } = props;
  const isErrors = isErrorFormPackage(errors);
  const totalAllocatedGuest = getTotalAllocatedGuest(roomAllocation);
  const totalGuest = getTotalGuest(guests);
  let selectCommission = getCommission(
    commissions,
    splitCommision,
    printCommision
  );
  const selectErrors = getError(errors);
  let isNullGuestRomm = totalAllocatedGuest === 0 || totalGuest === 0;
  return (
    <div style={styles.marginBotFourteen}>
      <Segment.Group style={styles.segmentNoPadding}>
        <HeaderInfo
          availablePax={availablePax}
          tourDate={tourDate}
          prices={prices}
          isVariableDate={isVariableDate}
          handleChangeSelectedDate={handleChangeSelectedDate}
          selectedDate={selectedDate}
        />

        <Segment style={styles.segmentGoldTransparent}>
          <Collapse isOpened={isVariableDate && isNotValidDate}>
            <Message style={styles.borderRedBackgroundRed}>
              {selectedDate
                ? t('dateOutsiedPeriode') //'The date you selected is outside the specified period'
                : t('pleaseSelectDate') //'Please select date'
              }
            </Message>
          </Collapse>
          <Collapse isOpened={isNullGuestRomm && !isErrors}>
            <Message style={styles.borderGoldBackgroundTransparentGold}>
              {/* Please select the number on guest(s) & room(s) Allocation */}
              {t('warningSelectAllocation')}
            </Message>
          </Collapse>
          <Collapse isOpened={isErrors}>
            <Message
              error
              style={styles.borderRedBackgroundRed}
              list={selectErrors === false ? [] : selectErrors}
            />
          </Collapse>
          <h4>{t('guest')}</h4> {/* Guest */}
          {!isVariableDate && (
            <React.Fragment>
              {minPax ? (
                <Message color="yellow">
                  <Icon name="warning circle" color="yellow" />
                  {/* Minimum pax for this package: */}
                  {t('warningMinimumPax')}:
                  <strong>
                    {' '}
                    {minPax} {t('pax').toLowerCase()}
                  </strong>
                </Message>
              ) : null}
            </React.Fragment>
          )}
          <Grid columns={3}>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={`${t('adults')}(>12 ${t('years')})`} //"Adults(>12 Years)"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="adults"
                objectname="guests"
                value={guests.adults}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleChangeGuestAmount}
              />
            </Grid.Column>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={`${t('children')}(2-12 ${t('years')})`} // "Children(2-12 Years)"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="childs"
                objectname="guests"
                value={guests.childs}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleChangeGuestAmount}
              />
            </Grid.Column>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={`${t('infants')}(<2 ${t('years')})`} //"Infants(<2 Years)"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="infants"
                objectname="guests"
                value={guests.infants}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChangeAmount={handleChangeGuestAmount}
              />
            </Grid.Column>
          </Grid>
          {/* Room Allocation per Pax */}
          <h4>{t('roomAllocationPerPax')}</h4>
          <Grid columns={3}>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={t('sharingRoom')} //"Sharing Room"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="sharingRoom"
                objectname="roomAllocation"
                value={roomAllocation.sharingRoom}
                handleIncrease={props.handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={props.handleChangeGuestAmount}
                disabled={
                  prices && prices.SharingRoomPrice !== 0 ? false : true
                }
              />
            </Grid.Column>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={t('singleRoom')} //"Single Room"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="singleRoom"
                objectname="roomAllocation"
                value={roomAllocation.singleRoom}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleChangeGuestAmount}
                disabled={prices && prices.SingleRoomPrice !== 0 ? false : true}
              />
            </Grid.Column>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={`${t('extraBed')}(${t('adult')})`} //"Extra Bed(Adult)"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="adultExtraBed"
                objectname="roomAllocation"
                value={roomAllocation.adultExtraBed}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleChangeGuestAmount}
                disabled={
                  prices && prices.AdultExtraBedPrice !== 0 ? false : true
                }
              />
            </Grid.Column>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={`${t('extraBed')}(${t('child')})`} //"Extra Bed(Child)"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="childExtraBed"
                objectname="roomAllocation"
                value={roomAllocation.childExtraBed}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleChangeGuestAmount}
                disabled={
                  prices && prices.ChildExtraBedPrice !== 0 ? false : true
                }
              />
            </Grid.Column>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={t('sharingWithParents')} //"Sharing with parents"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="sharingBed"
                objectname="roomAllocation"
                value={roomAllocation.sharingBed}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleChangeGuestAmount}
                disabled={prices && prices.SharingBedPrice !== 0 ? false : true}
              />
            </Grid.Column>
            <Grid.Column style={{ alignSelf: 'flex-end' }}>
              <InputAmount
                label={`${t('babyCrib')}/${t('noBed')}`} //"Baby Crib/No Bed"
                labelStyle={styles.textAlignCenter}
                inputStyle={styles.borderBottom}
                name="noBed"
                objectname="roomAllocation"
                value={roomAllocation.noBed}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
                handleOnChange={handleChangeGuestAmount}
                disabled={prices && prices.NoBedPrice !== 0 ? false : true}
              />
            </Grid.Column>
          </Grid>
          <h4>
            {/* You have allocated */}
            {t('youHaveAllocated')}{' '}
            <span style={styles.goldColor}>{totalAllocatedGuest}</span>{' '}
            {t('guestOutOf')} <span style={styles.goldColor}>{totalGuest}</span>
          </h4>
        </Segment>
        {!isVariableDate && (
          <React.Fragment>
            <Segment style={styles.noBorder}>
              {/* Commision Confirmation */}
              <h4>{t('commisionConfirmation')}</h4>
              <Form>
                <Form.Group>
                  {/* Do you want to split staff commision */}
                  <Form.Field>
                    {t('doYouWantToSplitStaffCommision')}?
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      radio
                      label={t('yes')} //"Yes"
                      name="splitCommision"
                      value={1}
                      checked={splitCommision === 1}
                      onChange={handleChangeRadio}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      radio
                      label={t('no')} //"No"
                      name="splitCommision"
                      value={0}
                      checked={splitCommision === 0}
                      onChange={handleChangeRadio}
                    />
                  </Form.Field>
                </Form.Group>
                <Collapse isOpened={splitCommision === 0}>
                  <Form.Group>
                    <Form.Field>
                      {/* Print Commission as Agent and Staff */}
                      {t('printCommissionAsAgentAndStaff')}?
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        radio
                        label={t('yes')} //"Yes"
                        name="printCommision"
                        value={1}
                        checked={printCommision === 1}
                        onChange={handleChangeRadio}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        radio
                        label={t('no')} //"No"
                        name="printCommision"
                        value={0}
                        checked={printCommision === 0}
                        onChange={handleChangeRadio}
                      />
                    </Form.Field>
                  </Form.Group>
                </Collapse>
              </Form>
              <Collapse isOpened={selectCommission.length !== 0}>
                <Message style={styles.backgroundWhiteBorderGold}>
                  <Message.Header
                    style={styles.backgroundGoldTransparentPadding7px20px}
                  >
                    <Icon
                      name="info"
                      size="small"
                      circular
                      style={styles.colorBorderGold}
                    />
                    {/* Commission will be printed in invoice as */}
                    {t('commissionWillBePrintedInInvoiceAs')}
                  </Message.Header>
                  <Message.Content style={styles.paddingTenAndTwenty}>
                    {selectCommission.map((commission, index) => (
                      <Grid key={index}>
                        <Grid.Column width={8}>{commission.name}</Grid.Column>
                        <Grid.Column width={8}>
                          {commission.value.toLocaleString('id')}/{t('pax')}
                        </Grid.Column>
                      </Grid>
                    ))}
                  </Message.Content>
                </Message>
              </Collapse>
            </Segment>
            <Segment style={styles.segmentGoldTransparent}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    {/* Additional Item */}
                    <h4>{t('additionalItem')}</h4>
                  </Grid.Column>
                  <Grid.Column style={styles.textRight}>
                    <span>
                      (<span style={styles.mandatoryField}>*</span>)
                      {/* is mandatory item */}
                      {t('isMandatoryItem')}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <LineDivider />
              {supplements.length > 0 ? (
                <div>
                  <Grid>
                    <Grid.Column width={4}>
                      <h4>{t('quantity')}</h4> {/* Quantity */}
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <h4>{t('item')}</h4> {/* Item */}
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <h4>{`${t('price')} / ${t('pax')}`}</h4>{' '}
                      {/* Price / Pax */}
                    </Grid.Column>
                  </Grid>
                  <LineDivider />
                  <Grid>
                    {supplements.map((item, i) => (
                      <Grid.Row key={i}>
                        <Grid.Column width={4}>
                          <InputAmount
                            name={i}
                            objectname="supplementObject"
                            inputStyle={styles.borderBottom}
                            value={item.qty}
                            handleIncrease={handleIncrease}
                            handleDecrease={handleDecrease}
                            handleOnChange={handleChangeGuestAmount}
                            disabled={item.isMandatory}
                          />
                        </Grid.Column>
                        <Grid.Column width={6}>
                          {item.isMandatory && (
                            <span style={styles.mandatoryField}>*</span>
                          )}
                          {item.name}
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <TextMoney
                            currencyId={item.currencyId}
                            money={item.unitCost ? item.unitCost : 0}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    ))}
                  </Grid>
                </div>
              ) : (
                <Message info>
                  {/* No Additional Item Available */}
                  <p>{t('noAdditionalItemAvailable')}</p>
                </Message>
              )}
            </Segment>
          </React.Fragment>
        )}
      </Segment.Group>
    </div>
  );
};
SegmentTourForm.propTypes = {
  prices: PropTypes.object,
  tourDate: PropTypes.string,
  availablePax: PropTypes.number,
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  supplements: PropTypes.array,
  splitCommision: PropTypes.number,
  printCommision: PropTypes.number,
  commissions: PropTypes.array,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  handleChangeGuestAmount: PropTypes.func,
  handleChangeRadio: PropTypes.func,
  errors: PropTypes.object,
  minPax: PropTypes.number,
  isVariableDate: PropTypes.bool,
  handleChangeSelectedDate: PropTypes.func,
  selectedDate: PropTypes.string,
  isNotValidDate: PropTypes.bool,
};
export default SegmentTourForm;
