const POST_RESET_STAFF_PASSWORD = 'POST_RESET_STAFF_PASSWORD';
const POST_RESET_STAFF_PASSWORD_PENDING = 'POST_RESET_STAFF_PASSWORD_PENDING';
const POST_RESET_STAFF_PASSWORD_REJECTED = 'POST_RESET_STAFF_PASSWORD_REJECTED';
const POST_RESET_STAFF_PASSWORD_FULFILLED =
  'POST_RESET_STAFF_PASSWORD_FULFILLED';
const PUT_UPDATE_PROFILE = 'PUT_UPDATE_PROFILE';
const PUT_UPDATE_PROFILE_PENDING = 'PUT_UPDATE_PROFILE_PENDING';
const PUT_UPDATE_PROFILE_REJECTED = 'PUT_UPDATE_PROFILE_REJECTED';
const PUT_UPDATE_PROFILE_FULFILLED = 'PUT_UPDATE_PROFILE_FULFILLED';
const POST_REGISTER_COMPANY_USER = 'POST_REGISTER_COMPANY_USER';
const POST_REGISTER_COMPANY_USER_PENDING = 'POST_REGISTER_COMPANY_USER_PENDING';
const POST_REGISTER_COMPANY_USER_FULFILLED =
  'POST_REGISTER_COMPANY_USER_FULFILLED';
const POST_REGISTER_COMPANY_USER_REJECTED =
  'POST_REGISTER_COMPANY_USER_REJECTED';
const RESET_REGISTER_COMPANY_USER_STATUS = 'RESET_REGISTER_COMPANY_USER_STATUS';
const GET_USER_COMPANY_LIST = 'GET_USER_COMPANY_LIST';
const GET_USER_COMPANY_LIST_PENDING = 'GET_USER_COMPANY_LIST_PENDING';
const GET_USER_COMPANY_LIST_FULFILLED = 'GET_USER_COMPANY_LIST_FULFILLED';
const GET_USER_COMPANY_LIST_REJECTED = 'GET_USER_COMPANY_LIST_REJECTED';
const GET_USER_PROFILE = 'GET_USER_PROFILE';
const GET_USER_PROFILE_PENDING = 'GET_USER_PROFILE_PENDING';
const GET_USER_PROFILE_REJECTED = 'GET_USER_PROFILE_REJECTED';
const GET_USER_PROFILE_FULFILLED = 'GET_USER_PROFILE_FULFILLED';
const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
const POST_CHANGE_PASSWORD_PENDING = 'POST_CHANGE_PASSWORD_PENDING';
const POST_CHANGE_PASSWORD_FULFILLED = 'POST_CHANGE_PASSWORD_FULFILLED';
const POST_CHANGE_PASSWORD_REJECTED = 'POST_CHANGE_PASSWORD_REJECTED';
export {
  POST_RESET_STAFF_PASSWORD,
  POST_RESET_STAFF_PASSWORD_PENDING,
  POST_RESET_STAFF_PASSWORD_FULFILLED,
  POST_RESET_STAFF_PASSWORD_REJECTED,
  PUT_UPDATE_PROFILE,
  PUT_UPDATE_PROFILE_PENDING,
  PUT_UPDATE_PROFILE_FULFILLED,
  PUT_UPDATE_PROFILE_REJECTED,
  POST_REGISTER_COMPANY_USER,
  POST_REGISTER_COMPANY_USER_PENDING,
  POST_REGISTER_COMPANY_USER_FULFILLED,
  POST_REGISTER_COMPANY_USER_REJECTED,
  RESET_REGISTER_COMPANY_USER_STATUS,
  GET_USER_COMPANY_LIST,
  GET_USER_COMPANY_LIST_PENDING,
  GET_USER_COMPANY_LIST_FULFILLED,
  GET_USER_COMPANY_LIST_REJECTED,
  GET_USER_PROFILE,
  GET_USER_PROFILE_PENDING,
  GET_USER_PROFILE_FULFILLED,
  GET_USER_PROFILE_REJECTED,
  POST_CHANGE_PASSWORD,
  POST_CHANGE_PASSWORD_PENDING,
  POST_CHANGE_PASSWORD_FULFILLED,
  POST_CHANGE_PASSWORD_REJECTED,
};
