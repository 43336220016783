const GET_USER_INFO = 'GET_USER_INFO';
const GET_USER_INFO_PENDING = 'GET_USER_INFO_PENDING';
const GET_USER_INFO_FULFILLED = 'GET_USER_INFO_FULFILLED';
const GET_USER_INFO_REJECTED = 'GET_USER_INFO_REJECTED';
const RESET_GET_USER_INFO_STATUS = 'RESET_GET_USER_INFO_STATUS';

export {
  GET_USER_INFO,
  GET_USER_INFO_PENDING,
  GET_USER_INFO_FULFILLED,
  GET_USER_INFO_REJECTED,
  RESET_GET_USER_INFO_STATUS,
};
