const ColorContent = '#6b82e6';
const COLOR_GOLD = '#e6ca6b';
const COLOR_WHITE = '#fefefe';
const COLOR_RED = '#f44336';

const styles = {
  label: {
    width: '50%',
    marginRight: '0',
  },
  input: {
    width: '50%',
  },
  h4: {
    margin: '0',
    fontSize: '18px',
  },
  h3: {
    marginBottom: '5px',
    fontSize: '24px',
  },
  inputWidth100: {
    width: '100%',
  },
  //
  buttonBackgroundWhite: {
    color: ColorContent,
    backgroundColor: 'white',
  },
  backgroundWhite: {
    backgroundColor: 'white',
  },
  segmentDetail: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    // padding: '10px 30px 10px 30px',
    // margin: '5px 0px 20px 0px',
  },
  cardMark: { borderLeft: '3px solid rgb(230, 202, 107)' },
  collapseButtonStyle: {
    color: '#6b82e6',
    background: 'none',
    padding: '14px 0px',
  },
  borderTopGrey: { borderTop: '1px solid #dadada' },
  greyColor: { color: '#8F8F8F' },
  paddingHeaderTopSegment: {
    paddingTop: '24px',
    paddingBottom: '0px',
  },
  noPaddingTopBot: { paddingTop: 0, paddingBottom: 0 },
  noPaddingBot: { paddingBottom: 0 },
  padding7: {
    padding: '7px 0px',
  },
  greyBackground: { background: '#f7f9fa' },
  paddingTopBot5: { paddingTop: 5, paddingBottom: 5 },
  bottonDownload: {
    margin: '5px 3px 5px 3px',
    padding: '5px',
    color: COLOR_GOLD,
    // border: '2px solid #e6ca6b',
    backgroundColor: 'rgba(255,255,255,0)',
    textAlign: 'center',
  },
  iconButton: {
    color: COLOR_GOLD,
    margin: '0px',
  },
  buttonGold: {
    padding: '10px',
    color: '#000000',
    background: COLOR_GOLD,
  },
  buttonNegative: {
    padding: '10px',
    color: COLOR_WHITE,
    background: COLOR_RED,
  },
};

export default styles;
