import apiClient from './apiClient';
import {
  GET_TOUR_OPERATOR_PROFILE_URL,
  GET_TOUR_OPERATOR_LIST_URL,
} from './apiUrl';

const getTourOperatorProfileApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_TOUR_OPERATOR_PROFILE_URL}/${id}`);
};
const getTourOperatorListApi = (data, isReadyFixedPrice) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(
    isReadyFixedPrice
      ? `${GET_TOUR_OPERATOR_LIST_URL}/Simple`
      : GET_TOUR_OPERATOR_LIST_URL,
    data
  );
};
export { getTourOperatorProfileApi, getTourOperatorListApi };
