import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Icon, Button, Label, Message } from 'semantic-ui-react';
import Input from '../../../components/input/WithLabel';
import { ButtonCloseModal } from '../../shared/components/common';
import { Collapse } from 'react-collapse';
import { accountNoMandiri, accountNoBca } from '../constant/accountNumber';
import styles from '../styles';
import { useTranslation } from 'react-i18next';

const HeaderListPaymentMethod = props => {
  let { desc, isSelectedMethod, valueButton, handleButton } = props;
  return (
    <Grid.Row style={styles.backgroundGoldNoPadding}>
      <Grid.Column
        width={13}
        verticalAlign="middle"
        style={styles.paddingLeft25px}
      >
        <h5>{desc}</h5>
      </Grid.Column>
      <Grid.Column width={3}>
        <Button
          name="isSelectedMethod"
          value={valueButton}
          floated="right"
          style={styles.buttonGoldNoPadding}
          onClick={handleButton}
        >
          <Icon
            name={isSelectedMethod ? 'angle up' : 'angle down'}
            size="big"
          />
          {/* angle down */}
        </Button>
      </Grid.Column>
    </Grid.Row>
  );
};

const ManualPayment = props => {
  const { t } = useTranslation();
  let {
    title,
    handleButton,
    selectManualPayment,
    accountNo,
    swiftCode,
  } = props;
  return (
    <Grid.Row style={styles.paddingTopBottom10pxNoMargin}>
      <Grid.Column width={16} style={styles.paddingRight25BottomLeft10}>
        <h5 style={styles.noMargin}>
          {`${title}`}
          <Button
            name="selectManualPayment"
            value={
              selectManualPayment === title.toLowerCase()
                ? null
                : title.toLowerCase()
            }
            floated="right"
            style={styles.buttonTransparentBlack}
            onClick={handleButton}
          >
            <Icon
              name={
                selectManualPayment === title.toLowerCase()
                  ? 'angle up'
                  : 'angle down'
              }
              size="big"
            />
          </Button>
        </h5>
      </Grid.Column>
      <Grid.Column width={16}>
        <Collapse isOpened={selectManualPayment === title.toLowerCase()}>
          <Grid.Column width={16}>
            <Grid style={styles.width100PercenPaddingTopBottom5LeftRight10}>
              {accountNo.map((e, index) => (
                <Grid.Row key={index} style={styles.noPadding}>
                  <Grid.Column width={7}>{e.desc}</Grid.Column>
                  <Grid.Column width={9}>: {e.accountNo}</Grid.Column>
                </Grid.Row>
              ))}
              <Grid.Row style={styles.paddingTopBottom5px}>
                {/* On Behalf Of */}
                <Grid.Column width={7}>{t('onBehalfOf')}</Grid.Column>
                <Grid.Column width={9}>: PT. INDONESIA TUR ARABIA</Grid.Column>
              </Grid.Row>
              {swiftCode && (
                <Grid.Row style={styles.paddingTopBottom5px}>
                  {/* Swift Code */}
                  <Grid.Column width={7}>{t('swiftCode')}</Grid.Column>
                  <Grid.Column width={9}>: {swiftCode}</Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row style={styles.paddingTopBottom5px}>
                <Grid.Column width={16}>
                  <Message style={styles.messageGold}>
                    {/* Make sure your payment is SUCCESSFUL. Contact our Finance
                    Division and send evidence to speed up the verification
                    process */}
                    {t('makeSurePaymentSuccessfull')}
                  </Message>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={styles.paddingTopBottom5px}>
                <Grid.Column width={16}>
                  {/* Our Finance Division Contact : */}
                  {t('ourFinanceDivisionContact')}
                </Grid.Column>
                <Grid.Column width={8}>
                  <Icon name="mail" style={styles.goldColor} /> cs@touress.com
                </Grid.Column>
                <Grid.Column width={8}>
                  <Icon name="phone" style={styles.goldColor} /> &#40;+62&#41;
                  2130053666
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Collapse>
      </Grid.Column>
    </Grid.Row>
  );
};
const ModalPaymentChoice = props => {
  const { t } = useTranslation();
  let {
    isOpen,
    onClose,
    handleButton,
    isSelectedMethod,
    amountFunds,
    handleChange,
    handleAddFunds,
    // amountToPay,
    paymentGateAwayChoices,
    currency,
    balance,
    currencyCompany,
    selectManualPayment,
    shortfall,
  } = props;
  // let minimumFund = amountToPay - balance;
  return (
    <Modal open={isOpen} onClose={onClose} size="tiny" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundWhite}>
        {/* Top Up Touress Wallet  */}
        {t('topUpTouressWallet')} <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={(styles.backgroundWhite, styles.noPadding)}>
        <Modal.Description>
          {paymentGateAwayChoices &&
            paymentGateAwayChoices.map((paymentGateAway, index) => (
              <Grid style={styles.noMargin} key={index}>
                <HeaderListPaymentMethod
                  desc={
                    paymentGateAway.PaymentGateAwayChoice === 'Deposit'
                      ? t('manualPayment') //'Manual Payment'
                      : paymentGateAway.PaymentGateAwayChoice
                  }
                  isSelectedMethod={
                    isSelectedMethod &&
                    isSelectedMethod === paymentGateAway.PaymentGateAwayChoice
                  }
                  valueButton={paymentGateAway.PaymentGateAwayChoice}
                  handleButton={handleButton}
                />
                <Collapse isOpened={true} style={styles.width100Percen}>
                  {isSelectedMethod === paymentGateAway.PaymentGateAwayChoice &&
                  isSelectedMethod !== 'Deposit' ? (
                    <Grid.Row style={styles.paddingTopBottom10pxNoMargin}>
                      <Grid.Column width={16} style={styles.padding10px}>
                        <h5 style={styles.noMargin}>
                          {/* Add Funds to your touress wallet */}{' '}
                          {t('addFundsToTouressWallet')}
                        </h5>
                        <p>
                          {/* Funds in your touress wallet may be used for purchase
                          of any package on touress.{' '} */}
                          {t('touressWalletFundDesc')}
                        </p>
                        <p>
                          {/* Current Wallet Balance:{' '} */}
                          {t('currentWalletBalance')}:
                          <strong>
                            {currencyCompany} {balance.toLocaleString('ID')}
                          </strong>
                        </p>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <Grid>
                          <Grid.Row style={styles.marginTopBottom20pxNoPadding}>
                            <Grid.Column
                              width={10}
                              style={styles.paddingLeft25px}
                            >
                              <strong>
                                {/* Add  */}
                                {t('add')} {currency}{' '}
                                {shortfall < 0
                                  ? Math.abs(shortfall).toLocaleString('ID')
                                  : 0}
                              </strong>
                              {/* <p>Minimum fund for transaction</p> */}
                              <p>{t('minimumFundForTransaction')}</p>
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <Button
                                style={styles.buttonGoldBorderSolid}
                                floated="right"
                                disabled={shortfall > 0 ? false : true}
                                onClick={handleAddFunds}
                                value={Math.abs(shortfall)}
                                method={paymentGateAway.PaymentGateAwayChoice}
                                name="goto"
                              >
                                {/* ADD FUNDS */}
                                {t('AddFunds')}
                              </Button>
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row
                            style={styles.marginLeftBottomRight10pxNoPadding}
                          >
                            <Grid.Column
                              width={10}
                              style={styles.paddingLeft25px}
                            >
                              <Input
                                inputStyles={styles.width100Percen}
                                placeholder={t('enterYourAmountFunds')} //"Enter Your Amount Funds"
                                handleChange={handleChange}
                                value={amountFunds.toLocaleString('ID')}
                                type="text"
                                labelInput={<Label basic>{currency}</Label>}
                                labelPosition="left"
                                name="amountFunds"
                              />
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <Button
                                style={styles.buttonGoldBorderSolid}
                                floated="right"
                                onClick={handleAddFunds}
                                name="goto"
                                value={amountFunds}
                                method={paymentGateAway.PaymentGateAwayChoice}
                                disabled={amountFunds > 0 ? false : true}
                              >
                                {/* ADD FUNDS */} {t('AddFunds')}
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  ) : (
                    isSelectedMethod ===
                      paymentGateAway.PaymentGateAwayChoice &&
                    isSelectedMethod === 'Deposit' && (
                      <div style={styles.width100Percen}>
                        <ManualPayment
                          title="Mandiri"
                          accountNo={accountNoMandiri}
                          swiftCode="BMRIIDJA"
                          handleButton={handleButton}
                          selectManualPayment={selectManualPayment}
                        />

                        <ManualPayment
                          title="BCA"
                          accountNo={accountNoBca}
                          handleButton={handleButton}
                          selectManualPayment={selectManualPayment}
                        />
                      </div>
                    )
                  )}
                </Collapse>

                <Grid.Row style={styles.minHeight5pxNoMarginNoPadding}>
                  <Grid.Column width={16} />
                </Grid.Row>
              </Grid>
            ))}
          {/* <Grid.Row style={styles.backgroundGoldNoPadding}>
              <Grid.Column
                width={13}
                verticalAlign="middle"
                style={styles.paddingLeft25px}
              >
                <h5>Manual Transfer</h5>
              </Grid.Column>
              <Grid.Column width={3}>
                <Button
                  name="isMethodXendit"
                  value={!isMethodXendit}
                  floated="right"
                  style={styles.buttonGold}
                  onClick={handleButton}
                >
                  <Icon
                    name={isMethodXendit ? 'angle down' : 'angle right'}
                    size="big"
                  />
                  {/* angle down */}
          {/* </Button>
              </Grid.Column>
            </Grid.Row> */}{' '}
          {/* */}
          {/* </Grid> */}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

ModalPaymentChoice.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  handleButton: PropTypes.func,
  isSelectedMethod: PropTypes.string,
  amountFunds: PropTypes.number,
  // amountToPay: PropTypes.number,
  paymentGateAwayChoices: PropTypes.array,
  currency: PropTypes.string,
  balance: PropTypes.number,
  currencyCompany: PropTypes.string,
  selectManualPayment: PropTypes.string,
  handleChange: PropTypes.func,
  handleAddFunds: PropTypes.func,
  shortfall: PropTypes.number,
  t: PropTypes.func,
};

HeaderListPaymentMethod.propTypes = {
  desc: PropTypes.string,
  isSelectedMethod: PropTypes.number,
  valueButton: PropTypes.string,
  handleButton: PropTypes.func,
};

ManualPayment.propTypes = {
  handleButton: PropTypes.func,
  selectManualPayment: PropTypes.string,
  accountNo: PropTypes.array,
  swiftCode: PropTypes.string,
  title: PropTypes.string,
  t: PropTypes.func,
};

export default ModalPaymentChoice;
