import { GET_COUNTRIES, RESET_STATUS_GET_COUNTRIES } from './actionTypes';
import { getCountriesApi } from '../../../api/countriesApi';

const getCountries = () => {
  return dispatch => {
    return dispatch({
      type: GET_COUNTRIES,
      payload: getCountriesApi(),
    });
  };
};

const resetStatusGetCountries = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_GET_COUNTRIES,
    });
  };
};

export { getCountries, resetStatusGetCountries };
