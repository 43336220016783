import {
  beachFront,
  cityCenter,
  cliff,
  jungle,
  mountain,
  nearBeach,
  paddyField,
  river,
  tropicalGarden,
  village,
} from '../assets/icon/accommodation location/index';

const getLocationIcon = type => {
  switch (type) {
    case 'BEACHFRONT':
      return beachFront;
    case 'CITYCENTER':
      return cityCenter;
    case 'CLIFF':
      return cliff;
    case 'JUNGLE':
      return jungle;
    case 'NEARMOUNTAIN':
      return mountain;
    case 'NEARBEACH':
      return nearBeach;
    case 'PADDYFIELD':
      return paddyField;
    case 'RIVER':
      return river;
    case 'TROPICALGARDEN':
      return tropicalGarden;
    case 'VILLAGE':
      return village;
    default:
      return beachFront;
  }
};

export { getLocationIcon };
