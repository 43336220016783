import apiClient from './apiClient';
import { GET_CAROUSEL_IMAGE_URL } from './apiUrl';

const getCarouselImageApi = data => {
  return apiClient.get(
    `${GET_CAROUSEL_IMAGE_URL}?accessFrom=${data.accessForm}&countryId=${
      data.countryCode
    }`
  );
};
const getCarouselImageAfterLoginApi = data => {
  return apiClient.get(`${GET_CAROUSEL_IMAGE_URL}?accessFrom=${data}`);
};
export { getCarouselImageApi, getCarouselImageAfterLoginApi };
