import React from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/WithLabel';
import LineDivider from '../../../components/common/lineDivider';
import UploadButton from '../../../components/button/BtnUploadImage';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const AgentDetail = props => {
  const { t } = useTranslation();
  const {
    handleChange,
    companyTradeLicenseNumber,
    companyNumberOfEmployees,
    numberOfEmployeesOptions,
    companyTotalBranch,
    totalBranchOptions,
    companyCurrency,
    currencyOptions,
    companyLastYearRevenue,
    lastYearRevenueOptions,
    companyIataNumber,
    companyAssociationNumber,
    validate,
    currencyLoading,
    numberOfEmployeesLoading,
    totalBranchLoading,
    lastYearRevenueLoading,
    onImageSelect,
    imgSrc,
  } = props;

  return (
    <div>
      <h4 style={styles.h4}>
        <strong>
          {/* Travel Agent Detail */}
          {t('travelAgentDetail')}
        </strong>
      </h4>
      <LineDivider />
      <Form>
        <InputWithLabel
          label={t('tradeLicenseNumber')} //"Trade License Number"
          placeholder={t('tradeLicenseNumber')} //"Trade License Number"
          inline
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyTradeLicenseNumber}
          name="companyTradeLicenseNumber"
          validate={validate}
        />
        <InputWithLabel
          label={t('numberOfEmployees')} //"Number of Employees"
          placeholder={t('selectNumberOfEmployees')} //"Select Number of Employees"
          inline
          required
          options={numberOfEmployeesOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyNumberOfEmployees}
          name="companyNumberOfEmployees"
          type="select"
          loading={numberOfEmployeesLoading}
          validate={validate}
        />
        <InputWithLabel
          label={t('totalSubAgentsBranch')} //"Total Sub Agents / Branch"
          placeholder={t('selectTotalSubAgentsBranch')} //"Select Total Sub Agents / Branch"
          inline
          required
          options={totalBranchOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyTotalBranch}
          name="companyTotalBranch"
          type="select"
          loading={totalBranchLoading}
          validate={validate}
        />
        <InputWithLabel
          label={t('basedCurrency')} //"Based Currency"
          placeholder={t('basedCurrency')} //"Based Currency"
          inline
          required
          options={currencyOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyCurrency}
          name="companyCurrency"
          type="select"
          loading={currencyLoading}
          validate={validate}
        />
        <InputWithLabel
          label={t('lastYearRevenue')} //"Last Year Revenue"
          placeholder={t('lastYearRevenue')} //"Last Year Revenue"
          inline
          required
          options={lastYearRevenueOptions}
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyLastYearRevenue}
          name="companyLastYearRevenue"
          type="select"
          loading={lastYearRevenueLoading}
          validate={validate}
        />
        <InputWithLabel
          label={t('IATAnumber')} //"IATA Number"
          placeholder={t('IATAnumber')} //"IATA Number"
          inline
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyIataNumber}
          name="companyIataNumber"
          validate={validate}
        />
        <InputWithLabel
          label={t('associationNumber')} //"Association Number"
          placeholder={t('associationNumber')} //"Association Number"
          inline
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={companyAssociationNumber}
          name="companyAssociationNumber"
          validate={validate}
        />
        <UploadButton
          labelStyles={styles.label}
          onImageSelect={onImageSelect}
          label={t('selectImage')} //"Select Image"
          labelTitle={t('tradeLicenseImage')} //"Trade License Image"
        />
        <Form.Field inline>
          <label style={styles.label} />
          <Image src={imgSrc} size="medium" />
        </Form.Field>
      </Form>
    </div>
  );
};

AgentDetail.propTypes = {
  handleChange: PropTypes.func,
  companyTradeLicenseNumber: PropTypes.string,
  companyNumberOfEmployees: PropTypes.string,
  numberOfEmployeesOptions: PropTypes.array,
  companyTotalBranch: PropTypes.string,
  totalBranchOptions: PropTypes.array,
  companyCurrency: PropTypes.string,
  currencyOptions: PropTypes.array,
  companyLastYearRevenue: PropTypes.string,
  lastYearRevenueOptions: PropTypes.array,
  companyIataNumber: PropTypes.string,
  companyAssociationNumber: PropTypes.string,
  validate: PropTypes.object,
  numberOfEmployeesLoading: PropTypes.bool,
  totalBranchLoading: PropTypes.bool,
  currencyLoading: PropTypes.bool,
  lastYearRevenueLoading: PropTypes.bool,
  onImageSelect: PropTypes.func,
  imgSrc: PropTypes.object,
  t: PropTypes.func,
};

export default AgentDetail;
