const registerPostData = state => {
  const data = {
    User: {
      Email: state.userEmail,
      Username: state.userName,
      Firstname: state.userFirstName,
      Lastname: state.userLastName,
      Telephone: state.userPhoneNumber,
      Password: state.userPassword,
      ConfirmPassword: state.userConfirmPassword,
    },
    Name: state.companyName,
    Address: state.companyAddress,
    Email: state.companyEmail,
    Telephone: state.companyTelephone,
    CountryId: state.companyCountry,
    EmployeeRangeId: state.companyNumberOfEmployees,
    BranchRangeId: state.companyTotalBranch,
    RevenueRangeId: state.companyLastYearRevenue,
    TradelicenseNbr: state.companyTradeLicenseNumber,
    ImageContent: state.imageContentBase64,
    IATANbr: state.companyIataNumber,
    AssociationNumber: state.companyAssociationNumber,
    CurrencyId: state.companyCurrency,
    CompanyBusiness: 'CUSTOMER',
    ImageName: `${state.companyName} Trade License Image`,
    FaxNbr: 0,
  };

  return data;
};

export default registerPostData;
