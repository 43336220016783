const bgImage = imgUrl => {
  return {
    width: '500PX',
    minHeight: '180px',
    flex: 'none',
    backgroundImage: `url("${imgUrl}"
    )`,
    backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundBlendMode: 'color-burn',
    backgroundSize: 'cover',
    borderBottom: '0px',
    border: 'none',
  };
};
export default bgImage;
