import { CHECKIN } from '../constant/activityTypes';
import { isSameDate } from './moment';

const isThereExcursionMeal = (dailyProgram, dayIndex) => {
  let movements = dailyProgram[dayIndex].Movements;
  // let activity = movements[moveIndex];
  let result = movements.find(
    item =>
      // item.Destination === activity.Destination &&
      item.MovementName === 'RECREATION' || item.MovementName === 'EAT'
  );
  return result ? true : false;
};
const isCheckInDifferentDate = dailyProgram => {
  let result = dailyProgram.reduce((res, daily) => {
    res = daily.Movements.reduce((moveRes, item) => {
      if (item.MovementName === CHECKIN) {
        let samedate = isSameDate(daily.Date, item.DateTime);
        if (!samedate) {
          moveRes = true;
        }
      }
      return moveRes;
    }, res);
    return res;
  }, false);
  return result;
};
export { isThereExcursionMeal, isCheckInDifferentDate };
