import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import LineDivider from '../../../../components/common/lineDivider';
import InputWithLabel from '../../../../components/input/WithLabel';
import styles from '../../styles';
import { useTranslation } from 'react-i18next';

const ShortRegisterUser = props => {
  const { t } = useTranslation();
  const {
    handleChange,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
    validate,
  } = props;

  return (
    <div>
      <h4 style={styles.h4}>
        <strong>
          {/* User Data */}
          {t('userData')}
        </strong>
      </h4>
      <LineDivider />
      <Form>
        <InputWithLabel
          label={t('firstName')} //"First Name"
          placeholder={t('firstName')} //"First Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userFirstName.data}
          name="userFirstName"
          validate={validate}
        />
        <InputWithLabel
          label={t('lastName')} //"Last Name"
          placeholder={t('lastName')} //"Last Name"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userLastName.data}
          name="userLastName"
          validate={validate}
        />
        <InputWithLabel
          label={t('email')} //"Email"
          placeholder={t('email')} //"Email"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userEmail.data}
          name="userEmail"
          validate={validate}
        />
        <InputWithLabel
          label={t('phoneNumber')} //"Phone Number"
          placeholder={t('phoneNumber')} //"Phone Number"
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={handleChange}
          value={userPhoneNumber.data}
          name="userPhoneNumber"
          validate={validate}
        />
      </Form>
    </div>
  );
};

ShortRegisterUser.propTypes = {
  handleChange: PropTypes.func,
  userName: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userPhoneNumber: PropTypes.string,
  userEmail: PropTypes.string,
  validate: PropTypes.object,
  t: PropTypes.object,
};
export default ShortRegisterUser;
