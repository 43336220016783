import {
  dubaiImage,
  jordaniaImage,
  turkeyImage,
  finlandImage,
} from '../../../assets/image';
const topDestinationData = [
  {
    id: 1,
    name: 'Jordan',
    imageUrl: jordaniaImage,
  },
  {
    id: 2,
    name: 'Turkey',
    imageUrl: turkeyImage,
  },
  {
    id: 3,
    name: 'Dubai',
    imageUrl: dubaiImage,
  },
  {
    id: 4,
    name: 'Finland',
    imageUrl: finlandImage,
  },
];

export default topDestinationData;
