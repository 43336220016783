import { GET_USER_ROLES } from './actionTypes';
import { getUserRolesApi } from '../../../api/userApi';

const getUserRoles = () => {
  return dispatch => {
    return dispatch({
      type: GET_USER_ROLES,
      payload: getUserRolesApi(),
    });
  };
};

export { getUserRoles };
