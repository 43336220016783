import {
  GET_EXCURSION_TYPES,
  GET_EXCURSION_BY_FILTER,
  RESET_STATUS_EXCURSION,
  GET_EXCURSION_BY_ID,
} from './actionTypes';
import {
  getExcursionTypesApi,
  getExcursionByFilterApi,
  getExcursionByIdApi,
} from '../../../api/excursionApi';

const getExcursionTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_TYPES,
      payload: getExcursionTypesApi(),
    });
  };
};
const getExcursionByFilter = (data, bodyReq) => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_BY_FILTER,
      payload: getExcursionByFilterApi(data, bodyReq),
    });
  };
};
const resetStatusExcursion = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_EXCURSION,
    });
  };
};
const getExcursionById = id => {
  return dispatch => {
    return dispatch({
      type: GET_EXCURSION_BY_ID,
      payload: getExcursionByIdApi(id),
    });
  };
};
export {
  getExcursionTypes,
  getExcursionByFilter,
  resetStatusExcursion,
  getExcursionById,
};
