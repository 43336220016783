import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Segment,
  Pagination,
  Header,
} from 'semantic-ui-react';
import Input from '../../../../../../components/input/WithLabel';
import { LoaderModal } from '../../../common';
// import { ucwords } from '../../../../../../genericFunction/convertWord';
import Card from './Card';
import styles from './styles';

import { useTranslation } from 'react-i18next';

const ListCard = props => {
  const { t } = useTranslation();
  const pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };

  let {
    data,
    location,
    loading,
    searchName,
    handleChange,
    activePage,
    pageChange,
    setRestaurant,
  } = props;
  // let { activePage } = this.state;
  // location = ucwords(location);
  let pagedData = pagination(data, 3);
  let listData = pagedData[activePage - 1];
  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={9}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                type="input"
                icon="map marker alternate"
                iconPosition="left"
                inputStyles={styles.inputStyles}
                // label={`${data.length} Restaurant Found`}
                label={t('restaurantFound', { count: data.length })}
                labelStyles={styles.boldGoldColor}
                value={location}
                disabled={true}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={7}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                name="searchName"
                type="input"
                icon="search"
                iconPosition="left"
                inputStyles={styles.inputStyles}
                label={t('searchByName')} //"Search By Name"
                labelStyles={styles.textBold}
                value={searchName}
                handleChange={handleChange}
                placeholder={t('searchByRestaurantName')} // "Search by restaurant name"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={16}>
            <LoaderModal loading={loading} />
            {listData && !loading
              ? listData.map((data, index) => {
                  let classes =
                    data.Menu && data.Menu.length !== 0
                      ? data.Menu[0].MenuClass
                      : '';
                  let typeMenu =
                    data.Menu && data.Menu.length !== 0
                      ? data.Menu[0].TypeOfMenu
                      : '';
                  return (
                    <Card
                      key={index}
                      images={data.RestaurantProfileImages}
                      image={data.ImageUrl}
                      isPromo={data.IsPromo}
                      name={data.Name}
                      address={data.Address}
                      classes={classes}
                      typeMenu={typeMenu}
                      id={data.Id}
                      setRestaurant={setRestaurant}
                      data={data}
                    />
                  );
                })
              : !loading && (
                  <Header textAlign="center" as="h4">
                    {/* There is no result of restaurant for your destination */}
                    {t('noResultRestaurantInDestination')}
                  </Header>
                )}
          </Grid.Column>
          <Grid.Column textAlign="center" width={16}>
            {pagedData && !loading && pagedData.length > 1 && (
              <Pagination
                totalPages={pagedData.length}
                onPageChange={pageChange}
                activePage={activePage}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
ListCard.propTypes = {
  data: PropTypes.array,
  location: PropTypes.string,
  loading: PropTypes.bool,
  searchName: PropTypes.string,
  activePage: PropTypes.number,
  handleChange: PropTypes.func,
  pageChange: PropTypes.func,
  setRestaurant: PropTypes.func,
};
export default ListCard;
