const GOLD_COLOR = '#e6ca6b';

const style = {
  label: {
    border: '1px solid #6b82e6',
    color: '#6b82e6',
    borderRadius: '50px',
  },
  goldColor: { color: GOLD_COLOR },
  noMarginTop: {
    marginTop: '0px',
  },
  noMargin: {
    margin: '0px',
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  buttonGold: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
    // width: '100%',
  },
  noPadding: { padding: 0 },
  textCenter: { textAlign: 'center' },
  marginBot4: { marginBottom: '4px' },
};
export default style;
