import React, { Component } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import styles from './styles';
import ProgressiveImage from 'react-progressive-image-loading';

class ImageGallerys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      thumbnailPosition: 'bottom',
      showVideo: {},
    };

    this.images = [].concat(this._getStaticImages());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.slideInterval !== prevState.slideInterval ||
      this.state.slideDuration !== prevState.slideDuration
    ) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
    if (prevProps.id !== this.props.id) {
      this.images = [].concat(this._getStaticImages());
    }
  }

  _onImageClick(event) {
    // eslint-disable-next-line
    console.debug(
      'clicked on image',
      event.target,
      'at index',
      this._imageGallery.getCurrentIndex()
    );
  }

  _onImageLoad(event) {
    // eslint-disable-next-line
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    // eslint-disable-next-line
    console.debug('slid to index', index);
  }

  _onPause(index) {
    // eslint-disable-next-line
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    // eslint-disable-next-line
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    // eslint-disable-next-line
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({ [state]: event.target.value });
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  _getStaticImages() {
    let images = [];
    let imgs = this.props.images;
    // eslint-disable-next-line
    imgs.map(item => {
      images.push({
        original: `${item.ImageUrl}`,
        thumbnail: `${item.ImageUrl}`,
        height: '300px',
      });
    });
    return images;
  }

  _resetVideo() {
    this.setState({ showVideo: {} });
    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }
    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    let state = this.state;
    state.showVideo[url] = !this.state.showVideo[url];
    this.setState({
      showVideo: this.state.showVideo,
    });
    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }
      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            {/* eslint-disable-next-line */}
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            >
              <div />
            </a>
            <iframe
              title="This is a unique title"
              width="675"
              height="315"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        ) : (
          //  eslint-disable-next-line
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button" />
            <img alt="a" src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={styles.rightZeroInitial}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }
  render() {
    return (
      <section className="app">
        <ProgressiveImage
          preview={this.images}
          src={this.images}
          render={() => (
            <ImageGallery
              ref={i => (this._imageGallery = i)}
              items={this.images}
              lazyLoad={false}
              onClick={this._onImageClick.bind(this)}
              onImageLoad={this._onImageLoad}
              onSlide={this._onSlide.bind(this)}
              onPause={this._onPause.bind(this)}
              onScreenChange={this._onScreenChange.bind(this)}
              onPlay={this._onPlay.bind(this)}
              infinite={this.state.infinite}
              showBullets={this.state.showBullets}
              showFullscreenButton={
                this.state.showFullscreenButton &&
                this.state.showGalleryFullscreenButton
              }
              showPlayButton={
                this.state.showPlayButton && this.state.showGalleryPlayButton
              }
              showThumbnails={this.state.showThumbnails}
              showIndex={this.state.showIndex}
              showNav={this.state.showNav}
              isRTL={this.state.isRTL}
              thumbnailPosition={this.state.thumbnailPosition}
              slideDuration={parseInt(this.state.slideDuration, 10)}
              slideInterval={parseInt(this.state.slideInterval, 10)}
              additionalClass="app-image-gallery"
            />
          )}
        />
      </section>
    );
  }
}
ImageGallerys.propTypes = {
  images: PropTypes.array,
  id: PropTypes.number,
};
export default ImageGallerys;
