import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { Segment, Header, Grid, Button, Icon } from 'semantic-ui-react';
import ImageCard from '../../../card/ImageCard';
import Input from '../../../../../../components/input/WithLabel';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const Card = props => {
  const { t } = useTranslation();
  const initialValidation = {
    typeService: { error: false, message: '' },
    duration: { error: false, message: '' },
  };

  const [openCollapse, setOpenCollapse] = useState(false);
  const [optionDuration, setOptionDuration] = useState([]);
  const [typeService, setTypeService] = useState('');
  const [duration, setDuration] = useState('');
  const [errors, setErrors] = useState(initialValidation);

  const handleClick = (e, { name }) => {
    if (name === 'openCollapse') {
      setOpenCollapse(prevState => !prevState);
      setTypeService('');
      setDuration('');
    }
    // this.setState({
    //   [name]: !this.state[name],
    //   typeService: state.typeService,
    //   duration: state.duration,
    // });
  };
  const handleChange = (e, { name, value }) => {
    let { options } = props;
    let index = options.findIndex(e => e.key === value);
    let newOptionDuration =
      index !== -1 ? options[index].options : optionDuration;
    let newErrors = { ...errors };
    newErrors[name].error = false;
    newErrors[name].message = '';
    if (name === 'typeService') {
      setTypeService(value);
    } else if (name === 'duration') {
      setDuration(value);
    }
    setOptionDuration(newOptionDuration);
    setErrors(errors);

    // this.setState({
    //   [name]: value,
    //   optionDuration,
    //   errors,
    // });
  };
  const handleTransport = () => {
    setTransport(typeService, duration, errors);
  };
  const setTransport = (typeService, duration, errors) => {
    let { data, setTransportItem, onClose } = props;
    if (typeService && duration) {
      let transportServicetype = data.TransportationItemServiceTypes.find(
        item => item.ServiceType === typeService
      );
      let objHour = transportServicetype.Hours.find(
        item => item.Id === duration
      );
      setTransportItem(data, typeService, objHour);
      onClose();
    } else {
      let newErrors = { ...errors };
      newErrors.typeService.error = !typeService;
      newErrors.typeService.message = typeService
        ? ''
        : `${t('pleaseChooseType')}`; // 'Please choose type';
      newErrors.duration.error = !duration;
      newErrors.duration.message = duration
        ? ''
        : `${t('pleaseChooseDuration')}`; // 'Please choose duration';
      setErrors(newErrors);
    }
  };

  let { image, name, id, type, rating, seat, options, data } = props;
  return (
    <Segment.Group style={styles.segmentNoPadding}>
      <Segment.Group horizontal>
        <ImageCard images={[]} image={image} isPromo={false} size="340px  " />
        <Segment style={styles.borderNone}>
          <Header as="h3" style={styles.goldColor}>
            {name.replace('_', ' ')}
          </Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width="16" style={styles.justify}>
                <p>{type}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={styles.paddingTopThree}>
              <Grid.Column width="1">
                <Icon className="star" style={styles.goldColor} />
              </Grid.Column>
              <Grid.Column width="6">{rating}</Grid.Column>
              <Grid.Column width="1">
                <Icon className="Seater-Icon" />
              </Grid.Column>
              {/* Passengers */}
              <Grid.Column width="6">
                {seat} {t('passengers')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="9" style={styles.marginTop10px}>
                <Header as="h5" style={styles.noMargin}>
                  {/* Estimated Total Price: */}
                  {t('estimatedTotalPrice')}:
                </Header>
                <Header
                  as="h4"
                  style={{ ...styles.goldColor, ...styles.noMargin }}
                >
                  {`${
                    data.EstimatedTotalPrice.CurrencyId
                  } ${data.EstimatedTotalPrice.Price.toLocaleString('id')}`}
                </Header>
              </Grid.Column>
              <Grid.Column width="7" floated="right">
                <Button
                  size="small"
                  onClick={handleClick}
                  name="openCollapse"
                  style={styles.buttonGold}
                  floated="right"
                >
                  {/* SET SERVICE */}
                  {t('SetService')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Segment.Group>
      <Collapse isOpened={openCollapse} style={styles.noMargin}>
        <Segment style={styles.borderNone}>
          <Grid>
            <Grid.Row>
              <Grid.Column width="7" floated="left">
                <Input
                  name="typeService"
                  type="select"
                  inputStyles={styles.inputStyleGold}
                  transparent={true}
                  label={t('service')} //"Service"
                  labelStyles={styles.fontWeightBold}
                  options={options}
                  handleChange={handleChange}
                  value={typeService}
                  placeholder={t('chooseType')} //"Choose Type"
                  validate={errors}
                />
              </Grid.Column>
              <Grid.Column width="7" floated="right">
                <Input
                  name="duration"
                  type="select"
                  inputStyles={styles.inputStyleGold}
                  transparent={true}
                  label={t('duration')} //"Duration"
                  labelStyles={styles.fontWeightBold}
                  options={optionDuration}
                  handleChange={handleChange}
                  value={duration}
                  placeholder={t('chooseDuration')} //"Choose Duration"
                  validate={errors}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="5" floated="right">
                <Button
                  size="small"
                  floated="right"
                  style={styles.buttonGold}
                  value={id}
                  onClick={handleTransport}
                >
                  {/* CHOOSE */} {t('Choose')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Collapse>
    </Segment.Group>
  );
};
Card.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  rating: PropTypes.string,
  seat: PropTypes.string,
  options: PropTypes.array,
  id: PropTypes.number,
  setTransportItem: PropTypes.func,
  data: PropTypes.object,
  onClose: PropTypes.func,
};
export default Card;
