const style = {
  goldColor: {
    color: '#e6ca6b',
  },
  noPaddingRightCenter: {
    paddingRight: '0px',
    textAlign: 'center',
  },
  headerStyle: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '900',
  },
  noBorder: { border: 'none' },
  gridStyles: { marginLeft: '25px', marginRight: '25px', marginBottom: '5px' },
};

export default style;
