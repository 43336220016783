import React, { Component } from 'react';
import { Button, Modal, Grid, Header, Form } from 'semantic-ui-react';
import Input from '../../../../components/input/WithLabel';
import styles from './styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ModalConfirmWithNote extends Component {
  // handleChange = async (e, { name, value }) => {
  //   await this.props.handleTourNote(name, value);
  // };
  render() {
    const { t } = this.props;
    const {
      openModal,
      size,
      confirm,
      close,
      message,
      nameConfirm,
      contentOk,
      contentNo,
      tourNote,
      disableField,
      packageType,
      handleTourNote,
    } = this.props;
    return (
      <Modal
        size={size}
        open={openModal}
        onClose={close}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <Grid columns="equal" style={styles.textCenter}>
            <Grid.Row>
              <Grid.Column>
                <h2>{message}</h2>
              </Grid.Column>
            </Grid.Row>
            {packageType === 'quotation' ? (
              <Grid.Row>
                <Grid.Column width={16}>
                  {/* Notes (Optional) */}
                  <Header as="h4">{`${t('notes')} (${t('optional')})`}</Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Form>
                    <Input
                      placeholder={t('haveInstructionsOrRequest?')} //"Have instructions or requests about this tour for us?"
                      type="textArea"
                      value={tourNote}
                      inputStyles={styles.borderGrey}
                      handleChange={handleTourNote}
                      disabled={disableField}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Column>
                <Button
                  style={styles.buttonNegative}
                  content={contentNo ? contentNo : t('no')} //'No'
                  onClick={close}
                />
                <Button
                  name={nameConfirm}
                  style={styles.buttonPositive}
                  content={contentOk ? contentOk : t('yes')} //'Yes'
                  onClick={confirm}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}
ModalConfirmWithNote.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  confirm: PropTypes.func,
  message: PropTypes.string,
  nameConfirm: PropTypes.string,
  contentOk: PropTypes.string,
  contentNo: PropTypes.string,
  tourNote: PropTypes.string,
  disableField: PropTypes.bool,
  handleTourNote: PropTypes.func,
  packageType: PropTypes.string,
  t: PropTypes.object,
};
export default withTranslation()(ModalConfirmWithNote);
