import imageBase64CutContent from './imageBase64CutContent';

const imageToBase64Converter = (image, callback) => {
  let reader = new FileReader();
  // Convert the file to base64 text
  reader.readAsDataURL(image);

  reader.onload = () => {
    let imageInfo = {
      base64: reader.result,
      content: imageBase64CutContent(reader.result),
    };
    callback(imageInfo);
  };
};

export default imageToBase64Converter;
