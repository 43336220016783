const isTimeOverlap = (dateTime, startTime, endTime) => {
  let isOverlap =
    new Date(startTime).getTime() < new Date(dateTime).getTime() &&
    new Date(dateTime).getTime() < new Date(endTime).getTime()
      ? true
      : false;
  return isOverlap;
};

export default isTimeOverlap;
