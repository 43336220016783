import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/WithLabel';
import PanelThumbnailImage from '../panel/PanelThumbnailImage';
import { styles } from '../../../seriesPackage/components/segmentDownloadsAndBookNow/styles';
// import StepImage from '../common/step';
// import PanelThumbnailImage from '../panel/PanelThumbnailImage';
import { useTranslation } from 'react-i18next';

const ModalDownloadImage = props => {
  const { t } = useTranslation();
  return (
    <Modal
      //dimmer="blurring"
      // closeIcon
      inverted
      open={props.openModal}
      onClose={props.handleClose}
      // size="medium"
      size="small"
      style={{ width: '60em' }}
    >
      <Modal.Header>{props.header}</Modal.Header>
      <Modal.Content>
        <h5>{props.content}</h5>
        <PanelThumbnailImage
          image={props.image}
          handleChange={props.handleChangePrimaryImage}
        />
        <InputWithLabel
          label={'Custom Price'}
          placeholder={'Custom Price'}
          inline
          required
          labelStyles={styles.label}
          inputStyles={styles.input}
          handleChange={props.handleCustomPrice}
          value={props.customPrice}
          name="customPrice"
          //validate={validate}
        />
      </Modal.Content>
      <Modal.Actions>
        {/* {props.indexActive === 0 || (
        <Button
          content="Back"
          basic
          color="blue"
          onClick={props.handleBackStep}
        />
      )} */}
        <Button
          content={t('close')} //"Close"
          style={styles.buttonRed}
          onClick={props.handleClose}
        />
        <Button
          content={t('downloadImage')} //"Download Image"
          // primary
          style={styles.backgroundGold}
          onClick={props.handleDownloadImage}
        />
      </Modal.Actions>
    </Modal>
  );
};

ModalDownloadImage.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  content: PropTypes.string,
  handleChange: PropTypes.func,
  imagePreview: PropTypes.func,
  handleNext: PropTypes.func,
  handleBackStep: PropTypes.func,
  image: PropTypes.array,
  listStepImage: PropTypes.array,
  indexActive: PropTypes.number,
  handleChangePrimaryImage: PropTypes.func,
  // imageRatio: PropTypes.object,
  imageRatio: PropTypes.array,
  handleDownloadImage: PropTypes.func,
  t: PropTypes.func,
  handleCustomPrice: PropTypes.func,
  customPrice: PropTypes.string,
};

export default ModalDownloadImage;
