const style = {
  dateTimeLabelStyle: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '4px',
    fontSize: '.92857143em',
  },
  placeStyle: { boxShadow: '0px 4px 6px rgba(0,0,0,0.2)', padding: '15px' },
  boxShadow: { border: '1px solid' }, //{ boxShadow: '0px 4px 6px rgba(0,0,0,0.2)' },
  segmentInside: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  btnCloseStyle: { color: 'red', cursor: 'pointer' },
  noPadding: { padding: 0 },
  marginBot15: { marginBottom: '15px' },
  textCenter: { textAlign: 'center' },
  noteStyles: {
    color: '#573a08',
    background: '#fffaf3',
    margin: 0,
    paddingTop: '9px',
    paddingBottom: '9px',
  },
  displayNone: { display: 'none' },
  boxTransparent: { boxShadow: '0 0 0 transparent' },
};

export default style;
