import { GOLD_COLOR } from '../../../../../constant/colour';
const style = {
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  buttonBlue: {
    color: '#6b82e6',
    background: 'white',
    border: '2px solid #6b82e6',
    fontSize: '16px',
  },
  buttonGold: {
    background: GOLD_COLOR,
    border: `2px solid ${GOLD_COLOR}`,
    fontSize: '16px',
  },
  menuActive: {
    background: 'rgba(230, 202, 107, 0.1)',
    borderRight: `4px solid ${GOLD_COLOR}`,
    padding: '10px',
    margin: '0px',
  },
  menuNonActive: {
    padding: '10px',
    margin: '0px',
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  bigFontGold: {
    fontSize: '36px',
    color: GOLD_COLOR,
    margin: '0px',
  },
  noMargin: {
    margin: '0px',
  },
  mediumFontGold: {
    fontSize: '30px',
    color: GOLD_COLOR,
    margin: '0px',
    marginTop: '4px',
  },
  paddingTop5em: { paddingTop: '5em' },
  nomarginTop: { marginTop: '0px' },
  height8em: { height: '8em' },
  marginLeftMinus: { marginLeft: '-3.8em' },
  noPadding: { padding: '0px' },
  menuStyle: { padding: '0px', margin: '0px 15px 20px 15px' },
  width100: { width: '100%' },
  textCenter: { textAlign: 'center' },
  textRight: { textAlign: 'right' },
};

export default style;
