import React from 'react';
import { Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import ProgressiveImage from 'react-progressive-image-loading';

const handlerOnClick = () => {};
const ImageSlide = props => (
  <div
    onClick={handlerOnClick}
    style={{ ...styles.pointer, ...styles.marginLeftRight20 }}
  >
    <ProgressiveImage
      preview={props.url}
      src={props.url}
      transitionTime={500}
      transitionFunction="ease"
      render={(src, style) => <Image src={src} style={style} alt="img" />}
    />
  </div>
);

ImageSlide.propTypes = {
  url: PropTypes.string,
  handlerOnClick: PropTypes.func,
};
export default ImageSlide;
