const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';

const style = {
  backgroundModal: {
    background: '#fbfbfb',
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  textTransparent: {
    color: WHITE_COLOR,
    fontWeight: 'bold',
  },
  justify: {
    textAlign: 'justify',
  },
  buttonGold: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
  },
  inputWithPaddingNine: {
    width: '100%',
    padding: '9px',
  },
  inputStyles: {
    width: '100%',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segmentSearch: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '1px 5px',
  },
  borderNone: { border: 'none' },
  paddingTopThree: { paddingTop: '3px' },
  noMargin: { margin: '0px' },
  inputStyleGold: {
    width: '100%',
    border: '0px',
    borderBottom: '2px solid #e6ca6b',
  },
  fontWeightBold: { fontWeight: 'bold' },
  marginBottomFifteen: { marginBottom: '15px' },
  columnStyle: {
    marginTop: '10px',
    marginBottom: '10px',
    minHeight: '50px',
  },
};
export default style;
