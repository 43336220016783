import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import styles from './styles';

const languageIcon = value => (
  <div style={styles.paddingBottom12Point5}>
    <Icon className="Language-Icon" />
    <strong>{value}</strong>
  </div>
);

const languageMenu = props => (
  <Menu.Item style={styles.paddingTopBotomZero}>
    <Dropdown
      item
      style={styles.dropdown}
      options={props.options}
      defaultValue={props.defaultValue}
      trigger={languageIcon(props.value)}
      onChange={props.handleChange}
      name="language"
    />
  </Menu.Item>
);

languageMenu.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
};
export default languageMenu;
