import React from 'react';
import { Modal, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ImageProgressive from '../../../../components/progressiveImage/ImageProgressive';
import styles from './styles';
const IndonesianSiteModal = props => {
  return (
    <Modal
      closeIcon
      size={props.size}
      open={props.openModal}
      onClose={props.close}
    >
      <Modal.Content>
        <Grid columns="equal" style={styles.textCenter}>
          <Grid.Row>
            <Grid.Column>
              <ImageProgressive
                preview={props.image}
                src={props.image}
                styles={styles.textCenter}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};
IndonesianSiteModal.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
  message: PropTypes.string,
  image: PropTypes.string,
};
export default IndonesianSiteModal;
