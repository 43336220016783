const styles = {
  label: {
    width: '50%',
    marginRight: '0',
  },
  input: {
    width: '50%',
  },
  h4: {
    margin: '0',
    fontSize: '18px',
  },
  h3: {
    marginBottom: '5px',
    fontSize: '24px',
  },
  inputWidth100: {
    width: '100%',
  },
};

export default styles;
