import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, Button, Container } from 'semantic-ui-react';
import { Collapse } from 'react-collapse';
import Input from '../../../components/input/WithLabel';
import styles from './styles';
import {
  getTourTypes,
  getTourPaxTypes,
} from '../../shared/actions/tourTransactionAction';
import {
  getAccommodationTypes,
  getAccommodationRatings,
  getAccommodationLocations,
} from '../../shared/actions/accommodationAction';
import { getCityInCountry } from '../../shared/actions/locationAction';
import { setTempFilterQuickLaunch } from '../../shared/actions/tempAction';

import { useTranslation } from 'react-i18next';

const FormFilterPackage = () => {
  const { t } = useTranslation();
  const [openMoreForm, setOpenMoreForm] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [formFilter, setFormFilter] = useState({
    tourType: null,
    tourPaxType: null,
    accommodationType: null,
    accommodationRating: null,
    accommodationLocation: null,
    startingDate: '',
    // desireDay: 0,
    endingDate: '',
    location: '',
  });
  //============action===========
  const dispatch = useDispatch();
  const getTourTypesAction = () => dispatch(getTourTypes());
  const getTourPaxTypesAction = () => dispatch(getTourPaxTypes());
  const getAccommodationTypesAction = () => dispatch(getAccommodationTypes());
  const getAccommodationRatingsAction = () =>
    dispatch(getAccommodationRatings());
  const getAccommodationLocationsAction = () =>
    dispatch(getAccommodationLocations());
  const getCityInCountryAction = () => dispatch(getCityInCountry());
  const setTempFilterQuickLaunchAction = param =>
    dispatch(setTempFilterQuickLaunch(param));
  //==========store==============
  const listTourType = useSelector(state =>
    state.tourTransaction.tourTypes.map(type => {
      return { text: type.Name, value: type.Id };
    })
  );
  const listTourPaxType = useSelector(state =>
    state.tourTransaction.tourPaxTypes.map(type => {
      return { text: type.Name, value: type.Id };
    })
  );
  const listAccommodationType = useSelector(state =>
    state.accommodation.accommodationTypes.map(type => {
      return { text: type.Name, value: type.Id };
    })
  );
  const listAccommodationRating = useSelector(state =>
    state.accommodation.accommodationRatings.map(type => {
      return { text: type.Name, value: type.Id };
    })
  );
  const listAccommodationLocation = useSelector(state =>
    state.accommodation.accommodationLocations.map(type => {
      return { text: type.Name, value: type.Id };
    })
  );
  const listCityInCountry = useSelector(state =>
    state.location.cityInCountry.map((type, index) => {
      return {
        key: type.Name + type.Region.Name + index,
        text: type.Name,
        content: (
          <p>
            <strong>{type.Name}</strong>
            <br />
            {type.Region.Name}, {type.Country.Name}
          </p>
        ),
        value: type.Name,
      };
    })
  );
  //==========lifecycle==========
  useEffect(() => {
    getTourTypesAction();
    getTourPaxTypesAction();
    getAccommodationTypesAction();
    getAccommodationRatingsAction();
    getAccommodationLocationsAction();
    getCityInCountryAction();
    // eslint-disable-next-line
  }, []);

  const handleMoreFilter = () => {
    setOpenMoreForm(!openMoreForm);
  };
  const handleChange = (e, { name, value }) => {
    let newFormFilter = { ...formFilter };
    newFormFilter[name] = value;
    setFormFilter(newFormFilter);
  };
  // setDesireDay = async () => {
  //   let formFilter = { ...this.state.formFilter };
  //   const { startingDate, endingDate } = formFilter;
  //   let desireDay = await getTotalDays(startingDate, endingDate, true);
  //   formFilter = { ...formFilter, desireDay };
  //   this.setState({ ...this.state, formFilter });
  // };
  const handleFilter = () => {
    // await this.setDesireDay();
    setTempFilterQuickLaunchAction(formFilter);
    setNextPage(!nextPage);
    // .then(() => setNextPage(!nextPage))
    // .catch(() => setNextPage(!nextPage));
  };

  const {
    tourType,
    tourPaxType,
    endingDate,
    accommodationType,
    accommodationRating,
    accommodationLocation,
    startingDate,
    location,
  } = formFilter;

  if (nextPage) {
    return <Redirect from="/" to="/package-list/tour/all" />;
  }
  return (
    <Form style={styles.formStyle}>
      <Form.Group widths="equal" style={styles.firstRowForm}>
        <Input
          name="tourType"
          label={`${t('chooseYourType')}`} // Choose your tour type
          labelStyles={styles.labelInput}
          type="select"
          placeholder={`${t('placeholderTourType')}`} // "Leisure, Business"
          inputStyles={styles.selectStyle}
          options={listTourType}
          handleChange={handleChange}
          value={tourType}
        />
        <Input
          name="tourPaxType"
          label={`${t('chooseTourCategory')}`} // Choose tour category
          labelStyles={styles.labelInput}
          type="select"
          inputStyles={styles.selectStyle}
          placeholder={`${t('placeholderTourPaxType')}`} //"Honeymoon, Business"
          options={listTourPaxType}
          handleChange={handleChange}
          value={tourPaxType}
        />
      </Form.Group>
      <Form.Group widths="equal" style={styles.noMarginBottom}>
        <Input
          name="location"
          label={`${t('whereAreYouGoing')}`} // Where are you going to?
          labelStyles={styles.labelInput}
          type="select"
          inputStyles={styles.selectStyle}
          placeholder={`${t('placeholderLocation')}`} // "Try, 'Bali'"
          icon="map marker alternate"
          options={listCityInCountry}
          handleChange={handleChange}
          value={location}
        />
        <Input
          name="accommodationType"
          label={`${t('whereWillStay')}`} //"Where will you stay?"
          labelStyles={styles.labelInput}
          type="select"
          inputStyles={styles.selectStyle}
          placeholder={`${t('placeholderAccommodationType')}`} //"Select accommodation"
          options={listAccommodationType}
          handleChange={handleChange}
          value={accommodationType}
        />
      </Form.Group>
      <Collapse isOpened={openMoreForm} style={styles.noMargin}>
        <Form.Group widths="equal" style={styles.noMarginBottom}>
          <Input
            name="startingDate"
            label={`${t('tourDateFrom')}`} // "Tour Date From"
            labelStyles={styles.labelInput}
            type="date"
            inputStyles={styles.inputStyle}
            icon="calendar"
            iconPosition="left"
            handleChange={handleChange}
            value={startingDate}
          />
          <Input
            name="endingDate"
            label={`${t('tourDateTo')}`} //"Tour Date To"
            labelStyles={styles.labelInput}
            type="date"
            inputStyles={styles.inputStyle}
            icon="calendar"
            iconPosition="left"
            handleChange={handleChange}
            value={endingDate}
          />
        </Form.Group>
        <Form.Group widths="equal" style={styles.noMarginBottom}>
          <Input
            name="accommodationRating"
            label={`${t('accommodationRating')}`} //"Accommodation rating"
            labelStyles={styles.labelInput}
            type="select"
            inputStyles={styles.selectStyle}
            placeholder={`${t('placeholderAccommodationRating')}`} // "Select rating"
            options={listAccommodationRating}
            handleChange={handleChange}
            value={accommodationRating}
          />
          <Input
            name="accommodationLocation"
            label={`${t('accommodationFacility')}`} // "Accommodation facility"
            labelStyles={styles.labelInput}
            type="select"
            inputStyles={styles.selectStyle}
            placeholder={`${t('placeholderAccommodationLocation')}`} // "Select facilities"
            options={listAccommodationLocation}
            handleChange={handleChange}
            value={accommodationLocation}
          />
        </Form.Group>
      </Collapse>
      <Form.Group>
        <Container textAlign="center">
          <div>
            <Button
              size="big"
              onClick={handleMoreFilter}
              style={styles.buttonTransparent}
            >
              <strong style={styles.colorGold}>
                {/* 'Less Filter' : 'More Filter' */}
                {openMoreForm ? `${t('lessFilter')}` : `${t('moreFilter')}`}
              </strong>
            </Button>
          </div>
        </Container>
      </Form.Group>
      <Form.Group>
        <Container textAlign="center">
          <Button size="big" style={styles.button} onClick={handleFilter}>
            {/* LET&apos;S GET STARTED! */}
            <strong style={styles.colorBlack}>{t('letsGetStarted')}</strong>
          </Button>
        </Container>
      </Form.Group>
    </Form>
  );
};

FormFilterPackage.propTypes = {
  getTourTypes: PropTypes.func,
  getTourPaxTypes: PropTypes.func,
  getAccommodationTypes: PropTypes.func,
  getAccommodationRatings: PropTypes.func,
  getAccommodationLocations: PropTypes.func,
  getCityInCountry: PropTypes.func,
  setTempFilterQuickLaunch: PropTypes.func,
  listTourType: PropTypes.array,
  listTourPaxType: PropTypes.array,
  listAccommodationType: PropTypes.array,
  listAccommodationRating: PropTypes.array,
  listAccommodationLocation: PropTypes.array,
  listCityInCountry: PropTypes.array,
  t: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    listTourType: state.tourTransaction.tourTypes.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    listTourPaxType: state.tourTransaction.tourPaxTypes.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    listAccommodationType: state.accommodation.accommodationTypes.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    listAccommodationRating: state.accommodation.accommodationRatings.map(
      type => {
        return { text: type.Name, value: type.Id };
      }
    ),
    listAccommodationLocation: state.accommodation.accommodationLocations.map(
      type => {
        return { text: type.Name, value: type.Id };
      }
    ),
    listCityInCountry: state.location.cityInCountry.map((type, index) => {
      return {
        key: type.Name + type.Region.Name + index,
        text: type.Name,
        content: (
          <p>
            <strong>{type.Name}</strong>
            <br />
            {type.Region.Name}, {type.Country.Name}
          </p>
        ),
        value: type.Name,
      };
    }),
  };
}
export default connect(
  mapStateToProps,
  {
    getTourTypes,
    getTourPaxTypes,
    getAccommodationTypes,
    getAccommodationRatings,
    getAccommodationLocations,
    getCityInCountry,
    setTempFilterQuickLaunch,
  }
)(FormFilterPackage);
