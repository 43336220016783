import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Icon } from 'semantic-ui-react';
import { addDays } from '../../genericFunction/dateTimeHelper';
class DateInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChange(date) {
    this.context.props.setDate(date);
  }

  render() {
    const {
      label,
      inputStyles,
      labelStyle,
      iconStyle,
      inputClassName,
      value,
      readonly,
      range,
      date,
      setOtherRange,
      expiredOn,
      includeDates,
    } = this.props;
    let startDate = value ? new Date(value) : new Date();
    return (
      <>
        <label style={labelStyle}>{label}</label>
        <DatePicker
          className="widthFluid"
          selected={startDate}
          onChange={this.handleChange}
          context={this}
          dateFormat="dd/MM/yyyy"
          style={
            { border: '2px solid black' } // withPortal
          }
          includeDates={includeDates}
          minDate={
            range ? new Date(date) : setOtherRange ? new Date(date) : null
          }
          maxDate={
            range
              ? addDays(date, 1)
              : setOtherRange
              ? new Date(expiredOn)
              : null
          }
          readOnly={readonly}
          customInput={
            <Input fluid iconPosition="left" label={label}>
              <Icon name="calendar" style={iconStyle} />
              <input style={inputStyles} className={inputClassName} />
            </Input>
          }
        />
      </>
    );
  }
}

DateInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  labelStyle: PropTypes.object,
  inputStyles: PropTypes.object,
  iconStyle: PropTypes.object,
  inputClassName: PropTypes.string,
  setDate: PropTypes.func,
  readonly: PropTypes.bool,
  date: PropTypes.string,
  range: PropTypes.bool,
  setOtherRange: PropTypes.bool,
  expiredOn: PropTypes.string,
  includeDates: PropTypes.array,
};
export default DateInput;
