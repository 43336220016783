import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Popup } from 'semantic-ui-react';
import styles from './styles';
import { LanguageMenu } from './';
import Login from '../login/Login';
import { useTranslation } from 'react-i18next';

const CustomIcons = props => {
  const { t } = useTranslation();
  return (
    <div style={styles.customIconLogout} onClick={props.onOpen}>
      <Icon className="User-Custom-Icon" />
      {/* Log In */}
      <strong>{t('logIn')} </strong>
      <Icon className="dropdown" style={styles.marginLeftFive} />
    </div>
  );
};

const LogoutHeaderMenu = props => (
  <Menu.Menu position="right" style={styles.backgroundBlack}>
    <Menu.Item>
      <Popup
        style={styles.popup}
        trigger={CustomIcons(props)}
        on="click"
        position="bottom center"
        open={props.open}
        onClose={props.handleClose}
      >
        <Login closePopup={props.handleClose} />
      </Popup>
    </Menu.Item>
    <LanguageMenu
      options={props.options}
      defaultValue={props.defaultValue}
      value={props.value}
      handleChange={props.handleChange}
    />
  </Menu.Menu>
);
CustomIcons.propTypes = {
  onOpen: PropTypes.func,
  t: PropTypes.func,
};
LogoutHeaderMenu.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  handleClose: PropTypes.func,
};

export default LogoutHeaderMenu;
