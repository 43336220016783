import React, { Component } from 'react';
import styles from './styles';
import { Segment, Grid, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { convertTourPrice } from '../../../../genericFunction/convertPackage';
import { PriceDetail, OrderedItem } from './';
import Loader from '../../../../components/common/loader';
import { initialAdditionalServices } from '../../services/additionalServices/processData';
import { withTranslation } from 'react-i18next';
class SummaryQuotation extends Component {
  state = {
    tourPriceRoom: [],
    currency: '',
    tourTotalPrice: 0,
    orderedItem: [],
  };

  componentDidUpdate = prevProps => {
    if (
      prevProps.tourTransactionSummary !== this.props.tourTransactionSummary
    ) {
      this.setSummary(this.props.tourTransactionSummary);
    } else if (prevProps.demoPrice !== this.props.demoPrice) {
      this.setSummary(this.props.demoPrice);
    }
  };

  setSummary = dataSummary => {
    let tourPriceRoom = convertTourPrice(dataSummary.TourPriceSum);
    let currency = dataSummary.BookingDetailSum
      ? dataSummary.BookingDetailSum.CurrencyId
      : '';
    let tourTotalPrice = dataSummary.BookingDetailSum
      ? dataSummary.BookingDetailSum.TourTotalPrice
      : 0;
    let orderedItem = dataSummary.DailyPrograms
      ? this.convertOrderedItem(dataSummary.DailyPrograms)
      : [];
    this.setState({
      ...this.state,
      tourPriceRoom,
      currency,
      tourTotalPrice,
      orderedItem,
    });
  };
  convertOrderedItem = dailyPrograms => {
    let orderedItem = dailyPrograms
      .filter(e => e.OrderedItems.length !== 0)
      .map(daily => {
        return { day: daily.Day, date: daily.Date, items: daily.OrderedItems };
      });
    return orderedItem;
  };

  handleQuotation = (e, { name }) => {
    this.props.confirmQuotation(name);
  };

  render() {
    const { t } = this.props;
    const { demoPriceLoading, demoPrice } = this.props;
    const { tourPriceRoom, currency, tourTotalPrice, orderedItem } = this.state;
    // console.log(this.props.demoPrice);
    // let disable = Object.keys(demoPrice).length > 0 ? false : true;
    // let { tourTransactionSummary } = this.props;
    // let tourPriceRoom = tourTransactionSummary.TourPriceSum
    //   ? convertTourPrice(tourTransactionSummary.TourPriceSum)
    //   : [];
    // let currency = tourTransactionSummary
    //   ? tourTransactionSummary.BookingDetailSum
    //     ? tourTransactionSummary.BookingDetailSum.CurrencyId
    //     : ''
    //   : '';
    // let tourTotalPrice = tourTransactionSummary
    //   ? tourTransactionSummary.BookingDetailSum
    //     ? tourTransactionSummary.BookingDetailSum.TourTotalPrice
    //     : 0
    //   : 0;
    // let orderedItem = tourTransactionSummary.DailyPrograms
    //   ? this.convertOrderedItem(tourTransactionSummary.DailyPrograms)
    //   : [];
    let additionalServiceList = initialAdditionalServices(
      demoPrice.AdditionalServices || []
    );
    return (
      <div style={styles.marginBottom14px}>
        <Segment style={styles.segmentWithShadowBox}>
          <Loader active={demoPriceLoading} inverted={true} />
          <Grid>
            <OrderedItem orderedItem={orderedItem} />
            <PriceDetail
              tourPriceRoom={tourPriceRoom}
              currency={currency}
              tourTotalPrice={tourTotalPrice}
              commission={{}}
              packageType="Quotation"
              additionalServices={additionalServiceList}
            />

            <Grid.Row>
              <Grid.Column textAlign="center">
                <div>
                  <Button
                    name="save"
                    style={styles.buttonBlue}
                    onClick={
                      this.handleQuotation // disabled={disable}
                    }
                  >
                    {/* Save Quotation */}
                    {t('saveQuotation')}
                  </Button>
                  <Button
                    name="deal"
                    style={styles.buttonGold}
                    onClick={
                      this.handleQuotation // disabled={disable}
                    }
                  >
                    {/* Book Now */}
                    {t('bookNow')}
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

SummaryQuotation.propTypes = {
  tourTransactionSummary: PropTypes.object,
  demoPrice: PropTypes.object,
  confirmQuotation: PropTypes.func,
  demoPriceLoading: PropTypes.bool,
  t: PropTypes.func,
};

export default withTranslation()(SummaryQuotation);
