import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Table,
  Button,
  Grid,
  Pagination,
  Segment,
} from 'semantic-ui-react';
import styles from '../styles';
import Input from '../../../components/input/WithLabel';
import { LoaderModal, ButtonCloseModal } from '../../shared/components/common';
import { useTranslation } from 'react-i18next';

const pagination = (data, perPage) => {
  let sets = [],
    chunks = data.length / perPage;
  for (let i = 0, j = 0; i < chunks; i++, j += perPage)
    sets[i] = data.slice(j, j + perPage);
  return sets;
};

const ModalListCompanies = props => {
  const { t } = useTranslation();
  let {
    isOpen,
    onClose,
    activePage,
    pageChange,
    userProfileByCompany,
    handleButtonPick,
    searchUser,
    handleChange,
    loading,
  } = props;

  let pagedData = pagination(userProfileByCompany, 10);
  let listUserProfileByCompany = pagedData[activePage - 1];
  return (
    <Modal open={isOpen} onClose={onClose} size="small" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {/* Showing List User */}
        {t('showingListUser')} <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Input
                type="text"
                placeholder={t('searchUserByUsername')} //"Search user by username"
                icon="search"
                iconPosition="left"
                inputStyles={styles.width100percent}
                handleChange={handleChange}
                value={searchUser}
                name="searchUser"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table basic="very" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('numb')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('username')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('address')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('telephone')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('email')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('action')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {!loading &&
                    listUserProfileByCompany &&
                    listUserProfileByCompany.map((user, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{index + 1}</Table.Cell>
                        <Table.Cell>{user.Username}</Table.Cell>
                        <Table.Cell>{user.Address}</Table.Cell>
                        <Table.Cell>{user.Telephone}</Table.Cell>
                        <Table.Cell>{user.Email}</Table.Cell>
                        <Table.Cell>
                          <Button
                            style={styles.buttonGoldUserList}
                            value={{ code: user.Id, name: user.Username }}
                            onClick={handleButtonPick}
                            name="selectedUser"
                          >
                            {t('pick')}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={16}>
              {loading && (
                <Segment style={styles.segmentNoBorderTransparentMinHeight50px}>
                  <LoaderModal loading={loading} />
                </Segment>
              )}
            </Grid.Column>
            <Grid.Column
              textAlign="center"
              width={16}
              style={styles.paddingTop10px}
            >
              {!loading && pagedData && pagedData.length > 1 && (
                <Pagination
                  totalPages={pagedData.length}
                  onPageChange={pageChange}
                  activePage={activePage}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

ModalListCompanies.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  userProfileByCompany: PropTypes.array,
  loading: PropTypes.bool,
  handleButtonPick: PropTypes.func,
  searchUser: PropTypes.string,
  handleChange: PropTypes.func,
  t: PropTypes.func,
};

export default ModalListCompanies;
