import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Segment, Header, Grid } from 'semantic-ui-react';
import Card from './Card';
import Input from '../../../../../../components/input/WithLabel';
import { LoaderModal, ButtonCloseModal } from '../../../common';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const ModalDetailListMenu = props => {
  const { t } = useTranslation();
  const [searchName, setSearchName] = useState('');
  const handleChange = (e, { value }) => {
    setSearchName(value);
  };
  const filterData = (listmenu, searchName) => {
    return listmenu.filter(
      menu => menu.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };

  let { open, onClose, listDetailData, loading, setRestaurant } = props;
  listDetailData = filterData(listDetailData, searchName);
  return (
    <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {/* Restaurant&#39;s Menus  */}
        {t('restaurantMenu')} <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} verticalAlign="middle">
                <Header as="h5">
                  {/* Showing {listDetailData.length} Restaurant&#39;s Menus */}
                  {t('showingRestaurantMenu', { count: listDetailData.length })}
                </Header>
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment style={styles.segmentSearch}>
                  <Input
                    name="searchName"
                    type="input"
                    icon="search"
                    iconPosition="left"
                    transparent={true}
                    inputStyles={styles.inputWithPaddingNine}
                    labelStyles={styles.textTransparent}
                    value={searchName}
                    handleChange={handleChange}
                    placeholder={t('searchByMenuName')} //"Search by menu name"
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {loading && (
                  <Segment style={styles.segmentTransparent}>
                    <LoaderModal loading={loading} />
                  </Segment>
                )}
                {listDetailData && !loading
                  ? listDetailData.map((data, index) => (
                      <Card
                        key={index}
                        id={data.Id}
                        images={data.RestaurantImages}
                        image={data.ImageUrl}
                        header={data.Name}
                        menuClass={data.MenuClass}
                        category={data.Category}
                        typeMenu={data.TypeOfMenu}
                        description={data.Description}
                        data={data}
                        setRestaurant={setRestaurant}
                      />
                    ))
                  : !loading && (
                      <Header textAlign="center" as="h4">
                        {/* There is no result of menu for your restaurant */}
                        {t('noResultMenu')}
                      </Header>
                    )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* </Segment.Group> */}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
ModalDetailListMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  listDetailData: PropTypes.array,
  loading: PropTypes.bool,
  setRestaurant: PropTypes.func,
  t: PropTypes.func,
};
export default ModalDetailListMenu;
