import * as types from '../actions/actionTypes';

const initialState = {
  tourTypes: [],
  loadingGetTourType: false,
  getTourTypeStatus: '',
  tourPaxTypes: [],
  loadingGetTourPaxType: false,
  getTourPaxTypeStatus: '',
  identityTypes: [],
  getIdentityTypesStatus: '',
  guestTitleTypes: [],
  getGuestTitleTypesStatus: '',
  demoJoinTour: {},
  postDemoJoinTourStatus: '',
  demoPrice: {},
  postDemoPriceStatus: '',
  createTour: {},
  postCreateTourStatus: '',
  joinTour: {},
  postJoinTourStatus: '',
  supplementItemByFixedPackageId: [],
  getSupplementItemByFixedPackageIdStatus: '',
  tourTransactionsConfirmationDemoV2: {},
  postTourTransactionsConfirmationDemoV2Status: '',
  tourTransactionsTopUp: {},
  postTourTransactionsTopUpStatus: '',
  tourTransactionsConfirmation: {},
  postTourTransactionsConfirmationStatus: '',
  tourTransactionBilled: {},
  getTourTransactionBilledStatus: '',
  createTourOnBeHalf: {},
  postCreateTourOnBeHalfStatus: '',
  joinTourOnBeHalf: {},
  postJoinTourOnBeHalfStatus: '',
  errors: {},
  loading: false,
  loadingDownload: false,
  guestTemplate: {},
  getGuestTemplateStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_TOUR_TYPES
    //---------------------------------
    case types.GET_TOUR_TYPES:
      return { ...state };
    case types.GET_TOUR_TYPES_PENDING:
      return { ...state, loading: true, loadingGetTourType: true };
    case types.GET_TOUR_TYPES_FULFILLED:
      return {
        ...state,
        tourTypes: action.payload.data,
        errors: {},
        loading: false,
        getTourTypeStatus: 'success',
        loadingGetTourType: false,
      };
    case types.GET_TOUR_TYPES_REJECTED:
      return {
        ...state,
        tourTypes: [],
        errors: action.payload.response.data,
        loading: false,
        getTourTypeStatus: 'failed',
        loadingGetTourType: false,
      };
    //---------------------------------
    // GET_TOUR_PAX_TYPES
    //---------------------------------
    case types.GET_TOUR_PAX_TYPES:
      return { ...state };
    case types.GET_TOUR_PAX_TYPES_PENDING:
      return { ...state, loading: true, loadingGetTourPaxType: true };
    case types.GET_TOUR_PAX_TYPES_FULFILLED:
      return {
        ...state,
        tourPaxTypes: action.payload.data,
        errors: {},
        loading: false,
        loadingGetTourPaxType: false,
        getTourPaxTypeStatus: 'success',
      };
    case types.GET_TOUR_PAX_TYPES_REJECTED:
      return {
        ...state,
        tourPaxTypes: [],
        errors: action.payload.response.data,
        loading: false,
        loadingGetTourPaxType: false,
        getTourPaxTypeStatus: 'failed',
      };
    case types.RESET_STATUS_TRANSACTION:
      return {
        ...state,
        getTourTypeStatus: '',
        getTourPaxTypeStatus: '',
        postCreateTourStatus: '',
        postJoinTourStatus: '',
        postCreateTourOnBeHalfStatus: '',
        postJoinTourOnBeHalfStatus: '',
        postTourTransactionsConfirmationDemoV2Status: '',
      };
    //---------------------------------
    // GET_GUEST_TITLE_TYPE
    //---------------------------------
    case types.GET_GUEST_TITLE_TYPE:
      return { ...state };
    case types.GET_GUEST_TITLE_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_GUEST_TITLE_TYPE_FULFILLED:
      return {
        ...state,
        guestTitleTypes: action.payload.data,
        errors: {},
        loading: false,
        getGuestTitleTypesStatus: 'success',
      };
    case types.GET_GUEST_TITLE_TYPE_REJECTED:
      return {
        ...state,
        guestTitleTypes: [],
        errors: action.payload.response.data,
        loading: false,
        getGuestTitleTypesStatus: 'failed',
      };
    //---------------------------------
    // GET_IDENTITY_TYPE
    //---------------------------------
    case types.GET_IDENTITY_TYPE:
      return { ...state };
    case types.GET_IDENTITY_TYPE_PENDING:
      return { ...state, loading: true };
    case types.GET_IDENTITY_TYPE_FULFILLED:
      return {
        ...state,
        identityTypes: action.payload.data,
        errors: {},
        loading: false,
        getIdentityTypesStatus: 'success',
      };
    case types.GET_IDENTITY_TYPE_REJECTED:
      return {
        ...state,
        identityTypes: [],
        errors: action.payload.response.data,
        loading: false,
        getIdentityTypesStatus: 'failed',
      };
    //---------------------------------
    // POST_DEMO_JOIN_TOUR
    //---------------------------------
    case types.POST_DEMO_JOIN_TOUR:
      return { ...state };
    case types.POST_DEMO_JOIN_TOUR_PENDING:
      return { ...state, loading: true };
    case types.POST_DEMO_JOIN_TOUR_FULFILLED:
      return {
        ...state,
        demoJoinTour: action.payload.data,
        errors: {},
        loading: false,
        postDemoJoinTourStatus: 'success',
      };
    case types.POST_DEMO_JOIN_TOUR_REJECTED:
      return {
        ...state,
        demoJoinTour: {},
        errors: action.payload.response.data,
        loading: false,
        postDemoJoinTourStatus: 'failed',
      };
    case types.RESET_DEMO_JOIN_TOUR:
      return { ...state, demoJoinTour: {} };
    //---------------------------------
    // POST_DEMO_PRICE
    //---------------------------------
    case types.POST_DEMO_PRICE:
      return { ...state };
    case types.POST_DEMO_PRICE_PENDING:
      return { ...state, loading: true };
    case types.POST_DEMO_PRICE_FULFILLED:
      return {
        ...state,
        demoPrice: action.payload.data,
        errors: {},
        loading: false,
        postDemoPriceStatus: 'success',
      };
    case types.POST_DEMO_PRICE_REJECTED:
      return {
        ...state,
        demoPrice: {},
        errors: action.payload.response.data,
        loading: false,
        postDemoPriceStatus: 'failed',
      };
    case types.RESET_DEMO_PRICE:
      return { ...state, demoPrice: {} };
    //---------------------------------
    // POST_CREATE_TOUR
    //---------------------------------
    case types.POST_CREATE_TOUR:
      return { ...state };
    case types.POST_CREATE_TOUR_PENDING:
      return { ...state, loading: true };
    case types.POST_CREATE_TOUR_FULFILLED:
      return {
        ...state,
        createTour: action.payload.data,
        errors: {},
        loading: false,
        postCreateTourStatus: 'success',
      };
    case types.POST_CREATE_TOUR_REJECTED:
      return {
        ...state,
        createTour: {},
        errors: action.payload.response.data,
        loading: false,
        postCreateTourStatus: 'failed',
      };
    //---------------------------------
    // POST_JOIN_TOUR
    //---------------------------------
    case types.POST_JOIN_TOUR:
      return { ...state };
    case types.POST_JOIN_TOUR_PENDING:
      return { ...state, loading: true };
    case types.POST_JOIN_TOUR_FULFILLED:
      return {
        ...state,
        joinTour: action.payload.data,
        errors: {},
        loading: false,
        postJoinTourStatus: 'success',
      };
    case types.POST_JOIN_TOUR_REJECTED:
      return {
        ...state,
        joinTour: {},
        errors: action.payload.response.data,
        loading: false,
        postJoinTourStatus: 'failed',
      };

    //---------------------------------
    // GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID
    //---------------------------------
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID:
      return { ...state };
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_FULFILLED:
      return {
        ...state,
        supplementItemByFixedPackageId: action.payload.data,
        errors: {},
        loading: false,
        getSupplementItemByFixedPackageIdStatus: 'success',
      };
    case types.GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_REJECTED:
      return {
        ...state,
        supplementItemByFixedPackageId: {},
        errors: action.payload.response.data,
        loading: false,
        getSupplementItemByFixedPackageIdStatus: 'failed',
      };
    //---------------------------------
    // POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2
    //---------------------------------
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2:
      return {
        ...state,
        postTourTransactionsConfirmationDemoV2Status: 'start',
      };
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_PENDING:
      return {
        ...state,
        loading: true,
        postTourTransactionsConfirmationDemoV2Status: 'pending',
      };
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_FULFILLED:
      return {
        ...state,
        tourTransactionsConfirmationDemoV2: action.payload.data,
        errors: {},
        loading: false,
        postTourTransactionsConfirmationDemoV2Status: 'success',
      };
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_REJECTED:
      return {
        ...state,
        tourTransactionsConfirmationDemoV2: {},
        errors: action.payload.response.data,
        loading: false,
        postTourTransactionsConfirmationDemoV2Status: 'failed',
      };
    //---------------------------------
    // POST_TOUR_TRANSACTIONS_TOP_UP
    //---------------------------------
    case types.POST_TOUR_TRANSACTIONS_TOP_UP:
      return { ...state };
    case types.POST_TOUR_TRANSACTIONS_TOP_UP_PENDING:
      return { ...state, loading: true };
    case types.POST_TOUR_TRANSACTIONS_TOP_UP_FULFILLED:
      return {
        ...state,
        tourTransactionsTopUp: action.payload.data,
        errors: {},
        loading: false,
        postTourTransactionsTopUpStatus: 'success',
      };
    case types.POST_TOUR_TRANSACTIONS_TOP_UP_REJECTED:
      return {
        ...state,
        tourTransactionsTopUp: {},
        errors: action.payload.response.data,
        loading: false,
        postTourTransactionsTopUpStatus: 'failed',
      };
    //---------------------------------
    // POST_TOUR_TRANSACTIONS_CONFIRMATION
    //---------------------------------
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION:
      return { ...state };
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION_PENDING:
      return { ...state, loading: true };
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION_FULFILLED:
      return {
        ...state,
        tourTransactionsConfirmation: action.payload.data,
        errors: {},
        loading: false,
        postTourTransactionsConfirmationStatus: 'success',
      };
    case types.POST_TOUR_TRANSACTIONS_CONFIRMATION_REJECTED:
      return {
        ...state,
        tourTransactionsConfirmation: {},
        errors: action.payload.response.data,
        loading: false,
        postTourTransactionsConfirmationStatus: 'failed',
      };
    //---------------------------------
    // GET_TOUR_TRANSACTION_BILLED
    //---------------------------------
    case types.GET_TOUR_TRANSACTION_BILLED:
      return { ...state };
    case types.GET_TOUR_TRANSACTION_BILLED_PENDING:
      return { ...state, loading: true };
    case types.GET_TOUR_TRANSACTION_BILLED_FULFILLED:
      return {
        ...state,
        tourTransactionBilled: action.payload.data,
        errors: {},
        loading: false,
        getTourTransactionBilledStatus: 'success',
      };
    case types.GET_TOUR_TRANSACTION_BILLED_REJECTED:
      return {
        ...state,
        tourTransactionBilled: {},
        errors: action.payload.response.data,
        loading: false,
        getTourTransactionBilledStatus: 'failed',
      };
    //---------------------------------
    // POST_CREATE_TOUR_ON_BE_HALF
    //---------------------------------
    case types.POST_CREATE_TOUR_ON_BE_HALF:
      return { ...state };
    case types.POST_CREATE_TOUR_ON_BE_HALF_PENDING:
      return { ...state, loading: true };
    case types.POST_CREATE_TOUR_ON_BE_HALF_FULFILLED:
      return {
        ...state,
        createTourOnBeHalf: action.payload.data,
        errors: {},
        loading: false,
        postCreateTourOnBeHalfStatus: 'success',
      };
    case types.POST_CREATE_TOUR_ON_BE_HALF_REJECTED:
      return {
        ...state,
        createTourOnBeHalf: {},
        errors: action.payload.response.data,
        loading: false,
        postCreateTourOnBeHalfStatus: 'failed',
      };
    //---------------------------------
    // POST_JOIN_TOUR_ON_BE_HALF
    //---------------------------------
    case types.POST_JOIN_TOUR_ON_BE_HALF:
      return { ...state };
    case types.POST_JOIN_TOUR_ON_BE_HALF_PENDING:
      return { ...state, loading: true };
    case types.POST_JOIN_TOUR_ON_BE_HALF_FULFILLED:
      return {
        ...state,
        joinTourOnBeHalf: action.payload.data,
        errors: {},
        loading: false,
        postJoinTourOnBeHalfStatus: 'success',
      };
    case types.POST_JOIN_TOUR_ON_BE_HALF_REJECTED:
      return {
        ...state,
        joinTourOnBeHalf: {},
        errors: action.payload.response.data,
        loading: false,
        postJoinTourOnBeHalfStatus: 'failed',
      };
    //---------------------------------
    // GET_GUEST_LIST_TEMPLATE
    //---------------------------------
    case types.GET_GUEST_LIST_TEMPLATE:
      return { ...state };
    case types.GET_GUEST_LIST_TEMPLATE_PENDING:
      return { ...state, loadingDownload: true };
    case types.GET_GUEST_LIST_TEMPLATE_FULFILLED:
      return {
        ...state,
        guestTemplate: action.payload.data,
        errors: {},
        loadingDownload: false,
        getGuestTemplateStatus: 'success',
      };
    case types.GET_GUEST_LIST_TEMPLATE_REJECTED:
      return {
        ...state,
        guestTemplate: {},
        errors: action.payload.response.data,
        loadingDownload: false,
        getGuestTemplateStatus: 'failed',
      };
    default:
      return state;
  }
};

export default reducer;
