import React from 'react';
import { Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'react';
import styles from './styles';
const FeatureItem = props => {
  let { iconUrl, text, number, urlLink } = props;
  return (
    <Segment.Group
      horizontal
      style={
        number === '1'
          ? { ...styles.noMarginTop, ...styles.height85 }
          : styles.height85
      }
      as={Link}
      to={urlLink}
    >
      <Segment style={styles.backgroundGold}>
        <Image src={iconUrl} style={styles.maxHeightWidth55} />
      </Segment>
      <Segment style={{ ...styles.backgroundPrimary, ...styles.maxWidth283 }}>
        <strong style={styles.featureItemText}>{text}</strong>
      </Segment>
    </Segment.Group>
  );
};
FeatureItem.propTypes = {
  iconUrl: PropTypes.string,
  text: PropTypes.string,
  number: PropTypes.string,
  urlLink: PropTypes.string,
};
export default FeatureItem;
