const BLACK_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';
// const GREY_COLOR = '#f2f2f2';

const style = {
  backgroundModal: {
    backgroundColor: '#fbfbfb',
  },
  backgroundCard: {
    backgroundColor: WHITE_COLOR,
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  blackColor: {
    color: BLACK_COLOR,
  },
  greyColor: {
    color: '#d3d3d3',
  },
  message: {
    border: '0.3px solid #e6ca6b',
    backgroundColor: 'white',
    color: 'black',
  },
  messageDisable: {
    border: '0.5px solid #d3d3d3',
    backgroundColor: 'white',
    color: 'black',
  },
  //   boldGoldColor: {
  //     color: GOLD_COLOR,
  //     fontWeight: 'bold',
  //   },
  //   justify: {
  //     textAlign: 'justify',
  //   },
  //   labelGold: {
  //     borderColor: '#b2993d',
  //     background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  //     color: 'white',
  //     paddingBottom: '5px',
  //     paddingTop: '5px',
  //   },
  buttonGold: {
    background: GOLD_COLOR,
    color: WHITE_COLOR,
  },
  transparentButton: {
    backgroundColor: WHITE_COLOR,
    color: GOLD_COLOR,
    padding: '0px',
  },
  buttonChooseRoom: {
    backgroundColor: GOLD_COLOR,
    color: BLACK_COLOR,
    padding: '10px',
  },
  buttonChooseRoomDisable: {
    backgroundColor: '#d3d3d3',
    color: '#252525',
    padding: '10px',
  },
  //   imageAttachment: {
  //     backgroundColor: BLACK_COLOR,
  //     opacity: 0.5,
  //   },
  //   inputStyles: {
  //     width: '100%',
  //     padding: '9px',
  //     borderBottom: '2px solid lightgrey',
  //   },
  // noMarginTop: {
  //   marginTop: '0px',
  // },
  noMarginBottom: {
    marginBottom: '0px',
  },
  marginBottomfifteen: { marginBottom: '15px' },
  noPaddingTop: { paddingTop: '0px' },
  paddingthree: {
    padding: '3px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  label: {
    border: '1px solid #6b82e6',
    color: '#6b82e6',
    borderRadius: '50px',
  },
  justify: {
    textAlign: 'justify',
  },
  width100percen: {
    width: '100%',
  },
  noPaddingNoMargin: {
    padding: 0,
    margin: 0,
  },
  borderNone: {
    border: 'none',
  },
  segmentDescription: {
    backgroundColor: 'rgba(230, 202, 107, 0.1)',
    border: 0,
  },
  BorderZero: {
    border: 0,
  },
  maps: {
    width: '100%',
    height: '200px',
  },
  borderMessage: {
    border: '0.3px solid #e6ca6b',
  },
  backgroundGoldColorWhite: {
    backgroundColor: '#e6ca6b',
    color: 'white',
  },
  buttonGoldHeader: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
    // width: '100%',
  },
  segmentSearch: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '1px 5px',
  },
  textTransparent: {
    color: WHITE_COLOR,
    fontWeight: 'bold',
  },
  margin10px: {
    margin: '10px',
  },
  padding9pxWidth100percent: {
    width: '100%',
    padding: '9px',
  },
  segmentLoader: {
    border: 'none',
    background: 'transparent',
    minHeight: '50px',
  },
  noMargin: {
    margin: '0px',
  },
  columnRoomService: {
    textAlign: 'center',
    padding: '10px 3px 3px 3px',
  },
  textCenterPadding3px: {
    textAlign: 'center',
    padding: '3px',
  },
  labelAvailable: {
    border: '1px solid #00be5a',
    color: '#fff',
    borderRadius: '50px',
    background: '#00be5a',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  labelOnRequest: {
    border: '1px solid #fca000',
    color: '#fff',
    borderRadius: '50px',
    background: '#fca000',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  marginRight5: {
    marginRight: '5px',
  },
  margin3: { margin: '3px 3px 0px 3px' },
  font12: { fontSize: '12px' },
};
export default style;
