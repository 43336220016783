import React from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from '../styles';
import { useTranslation } from 'react-i18next';

const TabMenu = props => {
  const { t } = useTranslation();
  let { tabActive, handleTab } = props;
  return (
    <Menu secondary>
      <Menu.Item
        name="tabActive"
        value="created"
        onClick={handleTab}
        style={tabActive === 'created' ? styles.tabActive : styles.tabNonActive}
      >
        {/* Created */}
        {t('created')}
      </Menu.Item>
      <Menu.Item
        name="tabActive"
        value="inPayment"
        onClick={handleTab}
        style={
          tabActive === 'in payment' ? styles.tabActive : styles.tabNonActive
        }
      >
        {/* In Payment */}
        {t('inPayment')}
      </Menu.Item>
      <Menu.Item
        name="tabActive"
        value="confirmed"
        onClick={handleTab}
        style={
          tabActive === 'confirmed' ? styles.tabActive : styles.tabNonActive
        }
      >
        {/* Confirmed */}
        {t('confirmed')}
      </Menu.Item>
      <Menu.Item
        name="tabActive"
        value="completed"
        onClick={handleTab}
        style={
          tabActive === 'completed' ? styles.tabActive : styles.tabNonActive
        }
      >
        {/* Completed */}
        {t('completed')}
      </Menu.Item>
      <Menu.Item
        name="tabActive"
        value="cancelled"
        onClick={handleTab}
        style={
          tabActive === 'cancelled' ? styles.tabActive : styles.tabNonActive
        }
      >
        {/* Cancelled */}
        {t('cancelled')}
      </Menu.Item>
    </Menu>
  );
};
TabMenu.propTypes = {
  tabActive: PropTypes.string,
  handleTab: PropTypes.func,
  t: PropTypes.func,
};
export default TabMenu;
