const GOLD_COLOR = '#e6ca6b';
const styles = {
  bgGradTrans: {
    background:
      'linear-gradient(to bottom, #252525 0 %, rgba(255, 255, 255, 0) 20 %, rgba(255, 255, 255, 0) 80 %, #252525 100 %) !important',
  },

  bgGradDark: {
    maxWidth: '290px',
    width: '100%',
    color: 'white',
    background:
      'linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(90,90,90,1) 16%,rgba(90,90,90,1) 70%,rgba(2,0,0,1) 100%)',
  },
  progressiveImage: {
    maxWidth: '290px',
    width: '100%',
    height: '250px',
    backgroundImage:
      'linear-gradient(to bottom, rgba(41,137,216,0) 0%,rgba(32,124,202,0) 74%,rgba(31,119,194,0) 75%,rgba(0,0,0,1) 100%),',
    backgroundSize: 'cover',
    borderBottom: '0px',
    borderRadius: '5px 5px 0px 0px',
    padding: '0px 0px 0px 0px',
    position: 'absolute',
    bottom: '0',
    right: '0',
  },
  goldRibbon: {
    color: 'white',
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  absoluteRow: { bottom: '0', position: 'absolute' },
  noPaddingBottom: { paddingBottom: '0px' },
  whiteColor: { color: 'white' },
  priceStyle: {
    color: GOLD_COLOR,
    display: 'block',
    fontSize: '20px',
    margin: '3px 0px',
  },
  labelRoomStyle: { color: 'white', fontSize: '12px' },
  labelPaxAvailableStyle: {
    color: 'white',
    margin: '10px 0px',
    fontSize: '11px',
  },
  labelAvailableStyle: {
    color: 'red',
    display: 'block',
    fontSize: '25px',
    position: 'absolute',
  },
  labelLeftStyle: {
    color: 'white',
    fontSize: '14px',
    margin: '10px 0px',
  },
  paddingTopBottomFive: { padding: '5px 0px' },
  fontSize12: { fontSize: '12px' },
  bookNowStyle: { background: GOLD_COLOR, padding: '5px' },
  blackColor: { color: 'black' },
  paddingTopBotFiveSideThirty: { padding: '5px 30px' },
  marginLeftFive: { marginLeft: '5px' },
  ribbonTypePackage: {
    color: 'black',
    borderColor: '#e6ca6b',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  },
  bookNowStyleFixPrice: {
    background: GOLD_COLOR,
    padding: '5px',
    marginTop: '1em',
  },
  colorFFF: { color: '#fff' },
};
const bgImage = imgUrl => {
  return {
    maxWidth: '290px',
    width: '100%',
    height: '250px',
    backgroundImage: `linear-gradient(to bottom, rgba(41,137,216,0) 0%,rgba(32,124,202,0) 74%,rgba(31,119,194,0) 75%,rgba(0,0,0,1) 100%), url("${imgUrl}"
      )`,
    backgroundSize: 'cover',
    borderBottom: '0px',
  };
};
export { styles, bgImage };
