import React from 'react';
import { useState, useEffect } from 'react';
import { Grid, Header, Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/WithLabel';
import styles from '../../styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const NoteTravelAgent = props => {
  const { t } = useTranslation();
  let { tourNote } = props;
  //=======================actions=======================
  const [pageDisable, setPageDisable] = useState(true);
  //=======================lifecycle=======================
  useEffect(() => {
    setPageDisable(true);
    // eslint-disable-next-line
  }, []);
  return (
    <Grid.Row>
      <Grid.Column width={16} style={styles.marginTop10Bottom5px}>
        <Header as="h3">{t('notes')}:</Header>
      </Grid.Column>
      <Grid.Column width={16}>
        <Form>
          <InputWithLabel
            placeholder="-"
            type="textArea"
            value={tourNote}
            name="tourNote"
            inputStyles={styles.notewidth100percent}
            disabled={pageDisable}
            // handleChange={}
          />
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};
NoteTravelAgent.propTypes = {
  tourNote: PropTypes.string,
  pageDisable: PropTypes.bool,
  t: PropTypes.func,
};
export default NoteTravelAgent;
