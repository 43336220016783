import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FeatureMini from './FeatureMini';
import CarouselSection from './CarouselSection';
const Advertisement = props => {
  let { imageCarousel, token } = props;
  return (
    <Container
      className={
        token
          ? 'margin-bottom-fifteen margin-top-ten padding-side-twentyfive container-fit'
          : 'container-fit'
      }
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={token ? 11 : 16}>
            <CarouselSection
              dataImage={imageCarousel}
              token={token ? token : null}
            />
          </Grid.Column>
          {token && (
            <Grid.Column width={5}>
              <FeatureMini />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

Advertisement.propTypes = {
  token: PropTypes.string,
  imageCarousel: PropTypes.array,
};
export default Advertisement;
