import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import styles from './styles';
const BtnUploadFile = props => {
  let myfileInput = React.createRef();
  return (
    <div style={styles.displayInlineBlock}>
      <label style={props.labelStyles}>{props.labelTitle}</label>
      <Button style={props.buttonStyle} onClick={() => myfileInput.click()}>
        {props.label}
      </Button>
      <div>
        <input
          hidden
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={props.convertExceltoJson}
          ref={fileInput => (myfileInput = fileInput)}
        />
      </div>
    </div>
  );
};

BtnUploadFile.propTypes = {
  convertExceltoJson: PropTypes.func,
  label: PropTypes.string,
  labelStyles: PropTypes.object,
  labelTitle: PropTypes.string,
  buttonStyle: PropTypes.object,
};

export default BtnUploadFile;
