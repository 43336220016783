import { combineReducers } from 'redux';
// import { reducer as formReducer } from 'redux-form';
import currencies from '../pages/shared/reducers/currenciesReducer';
import countries from '../pages/shared/reducers/countriesReducer';
import authentication from '../pages/shared/reducers/authenticationReducer';
import user from '../pages/shared/reducers/userReducer';
import fixedPackage from '../pages/shared/reducers/fixedPackageReducer';
import images from '../pages/shared/reducers/imageReducer';
import tourTransaction from '../pages/shared/reducers/tourTransactionReducer';
import accommodation from '../pages/shared/reducers/accommodationReducer';
import location from '../pages/shared/reducers/locationReducer';
import readyPackage from '../pages/shared/reducers/readyPackageReducer';
import travelAgentData from '../pages/shared/reducers/travelAgentDataReducer';
import excursion from '../pages/shared/reducers/excursionReducer';
import restaurant from '../pages/shared/reducers/restaurantReducer';
import transportation from '../pages/shared/reducers/transportationReducer';
import temp from '../pages/shared/reducers/tempReducer';
import general from '../pages/shared/reducers/generalReducer';
import tourOperator from '../pages/shared/reducers/tourOperatorReducer';
import booking from '../pages/shared/reducers/bookingReducer';
import company from '../pages/shared/reducers/companyReducer';
import customPackage from '../pages/shared/reducers/customPackageReducer';
import createCustomer from '../pages/shared/reducers/createCustomerReducer';
import quotation from '../pages/shared/reducers/quotationReducer';
import account from '../pages/myProfile/accountReducer';
import language from '../pages/shared/reducers/languageReducer';
import brochure from '../pages/seriesPackage/brochureReducer';
import schedule from '../pages/seriesPackage/scheduleReducer';
import companyUser from '../pages/myProfile/companyUserReducer';
import userHeader from '../pages/shared/components/header/userHeaderReducer';
import userSummary from './../pages/summary/userSummaryReducer';
import transportationSummary from './../pages/summary/transportationSummaryReducer';

const reducers = {
  // form: formReducer,
  currencies,
  countries,
  authentication,
  user,
  fixedPackage,
  images,
  tourTransaction,
  accommodation,
  location,
  readyPackage,
  travelAgentData,
  excursion,
  restaurant,
  transportation,
  temp,
  general,
  tourOperator,
  booking,
  company,
  customPackage,
  //register,
  quotation,
  //  forgotPassword,
  account,
  createCustomer,
  language,
  brochure,
  schedule,
  companyUser,
  userHeader,
  userSummary,
  transportationSummary,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
