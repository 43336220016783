import React, { Component } from 'react';
import { Segment, Header, Icon, Loader } from 'semantic-ui-react';
import ExcursionCarousel from './ExcursionCarousel';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { connect } from 'react-redux';
import {
  getExcursionById,
  resetStatusExcursion,
} from '../../../shared/actions/excursionAction';
import { withTranslation } from 'react-i18next';
class SegmentExcursion extends Component {
  state = {
    excursionSum: [],
    selectExcursion: [],
  };
  componentDidUpdate = () => {
    if (this.state.excursionSum.length !== this.props.excursionSum.length) {
      let { excursionSum } = this.props;
      this.setState({
        ...this.state,
        excursionSum,
        selectExcursion: [],
      });
      this.getExcursionProfile(excursionSum);
    }
    if (this.props.getExcursionByIdStatus === 'success') {
      this.setState({
        ...this.state,
        selectExcursion: this.state.selectExcursion.concat(
          this.props.excursionById
        ),
      });
      this.props.resetStatusExcursion();
    }
  };
  getExcursionProfile = excursionSum => {
    let serviceItemId = [];
    excursionSum.length > 0 &&
      excursionSum.map(item => {
        return (
          item.data &&
          item.data.map(e => {
            return (
              serviceItemId.some(id => id === e.ServiceItemId) ||
              (serviceItemId = serviceItemId.concat(e.ServiceItemId))
            );
          })
        );
      });
    return (
      serviceItemId && serviceItemId.map(id => this.props.getExcursionById(id))
    );
  };
  render() {
    const { t } = this.props;
    let { excursionSum, selectExcursion } = this.state;
    let { loading } = this.props;
    return (
      <div>
        <Segment style={styles.segment}>
          <Header as="h3" className="no-margin">
            <Icon
              // className="fas fa-ticket-alt fa-2x"
              className="Excursion-28px"
              style={styles.goldColor}
            />{' '}
            {/* Excursion */}
            {t('excursion')}
          </Header>
          <Loader active={loading} inline="centered" />
          {selectExcursion.length !== 0 && excursionSum.length !== 0 ? (
            <div>
              <span className="margin-bottom-five" style={styles.greyColor}>
                {/* Note : We will provide you these or similar excursion depending
                on availability */}
                {`${t('note')} : ${t('weWillProvideSimilarExcursion')}`}
              </span>
              <ExcursionCarousel
                excursionSum={excursionSum}
                selectExcursion={selectExcursion}
              />
            </div>
          ) : (
            !loading && (
              <Header as="h4" textAlign="center">
                {/* No Excursion Ordered in this Package */}
                {t('noExcursionOrderediInThisPackage')}
              </Header>
            )
          )}
          {/* <Loader
            active={this.props.loadingAccommodation}
            description="getting accommodations"
          /> */}
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    loading: state.excursion.loading,
    getExcursionByIdStatus: state.excursion.getExcursionByIdStatus,
    excursionById: state.excursion.excursion,
  };
};

SegmentExcursion.propTypes = {
  loading: PropTypes.bool,
  excursionSum: PropTypes.array,
  getExcursionByIdStatus: PropTypes.string,
  excursionById: PropTypes.object,
  resetStatusExcursion: PropTypes.func,
  getExcursionById: PropTypes.func,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    getExcursionById,
    resetStatusExcursion,
  }
)(withTranslation()(SegmentExcursion));
