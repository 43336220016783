import * as types from '../actions/actionTypes';

const initialState = {
  fixedPackageAll: [],
  getFixedPackageAllStatus: '',
  errors: {},
  loading: false,
  fixedPackageById: {},
  getFixedPackageByIdStatus: '',
  fixedPackageLandingPage: [],
  getFixedPackageLandingPageStatus: '',
  featuredPackageList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_FIXED_PACKAGE_ALL
    //---------------------------------
    case types.GET_FIXED_PACKAGE_ALL:
      return { ...state };
    case types.GET_FIXED_PACKAGE_ALL_PENDING:
      return { ...state, loading: true };
    case types.GET_FIXED_PACKAGE_ALL_FULFILLED:
      return {
        ...state,
        fixedPackageAll: action.payload.data,
        errors: {},
        loading: false,
        getFixedPackageAllStatus: 'success',
      };
    case types.GET_FIXED_PACKAGE_ALL_REJECTED:
      return {
        ...state,
        fixedPackageAll: [],
        errors: action.payload.response.data,
        loading: false,
        getFixedPackageAllStatus: 'failed',
      };
    case types.RESET_GET_FIXED_PACKAGE_ALL_STATUS:
      return {
        ...state,
        errors: {},
        loading: false,
        getFixedPackageAllStatus: '',
      };
    //---------------------------------
    // GET_FIXED_PACKAGE_BY_ID_PENDING
    //---------------------------------
    case types.GET_FIXED_PACKAGE_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_FIXED_PACKAGE_BY_ID_FULFILLED:
      return {
        ...state,
        fixedPackageById: action.payload.data,
        errors: {},
        loading: false,
        getFixedPackageByIdStatus: 'success',
      };
    case types.GET_FIXED_PACKAGE_BY_ID_REJECTED:
      return {
        ...state,
        fixedPackageById: {},
        errors: action.payload.response.data,
        loading: false,
        getFixedPackageByIdStatus: 'failed',
      };
    //---------------------------------
    // GET_FIXED_PACKAGE_LANDING_PAGE
    //---------------------------------
    case types.GET_FIXED_PACKAGE_LANDING_PAGE:
      return { ...state };
    case types.GET_FIXED_PACKAGE_LANDING_PAGE_PENDING:
      return { ...state, loading: true };
    case types.GET_FIXED_PACKAGE_LANDING_PAGE_FULFILLED:
      return {
        ...state,
        fixedPackageLandingPage: action.payload.data,
        errors: {},
        loading: false,
        getFixedPackageLandingPageStatus: 'success',
      };
    case types.GET_FIXED_PACKAGE_LANDING_PAGE_REJECTED:
      return {
        ...state,
        fixedPackageLandingPage: [],
        errors: action.payload.response.data,
        loading: false,
        getFixedPackageLandingPageStatus: 'failed',
      };
    case types.RESET_FIXED_PACKAGE_LANDING_PAGE_STATUS:
      return {
        getFixedPackageLandingPageStatus: '',
      };
    case types.RESET_GET_FIXED_PACKAGE_BY_ID_STATUS:
      return {
        ...state,
        errors: {},
        loading: false,
        getFixedPackageByIdStatus: '',
      };

    //---------------------------------------------------
    //----Featured package
    //---------------------------------------------------
    case types.GET_FEATURED_PACKAGE_BEFORE_LOGIN:
      return { ...state };
    case types.GET_FEATURED_PACKAGE_BEFORE_LOGIN_PENDING:
      return { ...state, loading: true };
    case types.GET_FEATURED_PACKAGE_BEFORE_LOGIN_FULFILLED:
      return {
        ...state,
        featuredPackageList: action.payload.data,
        errors: {},
        loading: false,
      };
    case types.GET_FEATURED_PACKAGE_BEFORE_LOGIN_REJECTED:
      return {
        ...state,
        featuredPackageList: [],
        errors: action.payload.response.data,
        loading: false,
      };
    //-------------------------------------------------
    case types.GET_FEATURED_PACKAGE_AFTER_LOGIN:
      return { ...state };
    case types.GET_FEATURED_PACKAGE_AFTER_LOGIN_PENDING:
      return { ...state, loading: true };
    case types.GET_FEATURED_PACKAGE_AFTER_LOGIN_FULFILLED: {
      let newState = { ...state };
      newState.featuredPackageList = action.payload.data;
      return {
        ...state,
        featuredPackageList: newState.featuredPackageList,
        errors: {},
        loading: false,
      };
    }
    case types.GET_FEATURED_PACKAGE_AFTER_LOGIN_REJECTED:
      return {
        ...state,
        featuredPackageList: [],
        errors: action.payload.response.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
