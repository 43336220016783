import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Label, Segment, Header } from 'semantic-ui-react';
import noImage from '../../../../assets/image/NoImagetrans.png';
import GoldRibbon from '../common/GoldRibbon';
import LightBox from './ImageLightBox';
import options from '../../../../genericFunction/imageListConverter';
// import HeaderRoomCard from './headerRoomCard';
import styles from '../modal/styles';
import ProgressiveImage from 'react-progressive-image-loading';
import bgImage from './styles';
// const imageCard = props => {

import { useTranslation } from 'react-i18next';

const ImageCard = props => {
  const { t } = useTranslation();

  const [openImage, setOpenImage] = useState(false);
  const handleOpenImage = () => {
    setOpenImage(prevState => !prevState);
  };
  const getImageArray = (images, image) => {
    let showImage;
    if (images.length > 0) {
      showImage = images.map(image => {
        return options('images', image.ImageUrl);
      });
    } else if (image) {
      showImage = [options('images', image)];
    } else {
      showImage = [options('images', noImage)];
    }
    return showImage;
  };

  let { images, image, isPromo, size } = props;

  const imagePath =
    images.length !== 0
      ? images.find(item => item.IsPrimaryImage === true)
        ? images.find(item => item.IsPrimaryImage === true).ImageUrl
        : image
        ? image
        : noImage
      : image
      ? image
      : noImage;

  return (
    <ProgressiveImage
      preview={imagePath}
      src={imagePath}
      render={(src, style) => (
        <Segment
          style={Object.assign(
            style,
            bgImage(
              images.length > 0
                ? images.find(item => item.IsPrimaryImage === true)
                  ? images.find(item => item.IsPrimaryImage === true).ImageUrl
                  : images[0].ImageUrl
                : image,
              size,
              noImage
            )
          )}
        >
          {isPromo ? <GoldRibbon size="big" text="Promo" /> : null}
          {images.length > 1 && image ? (
            <Label
              size="big"
              onClick={handleOpenImage}
              attached="bottom"
              style={styles.imageAttachment}
            >
              <Header as="h3" textAlign="center" style={styles.goldColor}>
                {/* SEE MORE PHOTO */}
                {t('SeeMorePhoto')}
              </Header>
            </Label>
          ) : null}
          {openImage && (
            <LightBox
              images={getImageArray(images, image)}
              handleClose={handleOpenImage}
            />
          )}
        </Segment>
      )}
    />
  );
};
ImageCard.propTypes = {
  images: PropTypes.array,
  image: PropTypes.string,
  isPromo: PropTypes.bool,
  size: PropTypes.string,
  t: PropTypes.func,
};
export default ImageCard;
