import {
  GET_BROCHURE,
  RESET_GET_BROCHURE,
  GET_BROCHURE_OVERLAY,
} from './constant/actionTypes';
import { getBrochureApi, getBrochureOverlayApi } from '../../api/brochureApi';

const getBrochure = id => {
  return dispatch => {
    return dispatch({
      type: GET_BROCHURE,
      payload: getBrochureApi(id),
    });
  };
};

const resetGetBrochure = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_BROCHURE });
  };
};

const getBrochureOverlay = (id, companyCode) => {
  return dispatch => {
    return dispatch({
      type: GET_BROCHURE_OVERLAY,
      payload: getBrochureOverlayApi(id, companyCode),
    });
  };
};
export { getBrochure, resetGetBrochure, getBrochureOverlay };
