import React from 'react';
import {
  Segment,
  Grid,
  Header,
  Button,
  Message,
  Label,
} from 'semantic-ui-react';
import Input from '../../../../components/input/WithLabel';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { useTranslation } from 'react-i18next';

const PaymentMethodForm = props => {
  const { t } = useTranslation();
  let {
    handleButton,
    currency,
    expiredDate,
    balance,
    amountToPay,
    handleChange,
    currencyCompany,
  } = props;
  return (
    <Segment style={styles.segmentPaddingLeftRight15px}>
      <Grid style={styles.paddingTopBottom20px}>
        <Grid.Row style={styles.paddingTop10RightLeft15Bottom5}>
          <Grid.Column width={16}>
            {/* Touress Wallet */}
            <Header as="h2">{t('touressWallet')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10pxBottom5px}>
          <Grid.Column
            width={16}
            textAlign="center"
            style={styles.backgroundGoldTransparent}
          >
            <Header as="h4" style={styles.margin10pxColorRed}>
              {/* Complete payment before */}
              {t('completePaymentBefore')} {expiredDate}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10RightLeft15Bottom5}>
          <Grid.Column width={16} verticalAlign="middle">
            <Header as="h4">
              {/* Your Balance */}
              {t('yourBalance')}: {currencyCompany}{' '}
              {balance ? balance.toLocaleString('ID') : 0}
            </Header>
          </Grid.Column>
          {/* <Grid.Column width={7}>
              <Button
                name="isTopUp"
                value={true}
                style={styles.buttonGold}
                floated="right"
                onClick={handleButton}
              >
                Top Up
              </Button>
              <Button
                name="isProcessPay"
                value={true}
                style={styles.buttonGold}
                floated="right"
                onClick={handleButton}
              >
                Process Pay
              </Button>
            </Grid.Column> */}
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10RightLeft15Bottom5}>
          <Grid.Column width={16}>
            <Message style={styles.borderGoldBackgroundTransparentGold}>
              {/* You can pay more than your bill, the changes you pay will be used
              in the next payment. */}
              {t('canPayMoreThanBill')}
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10RightLeft15Bottom5}>
          <Grid.Column width={9} verticalAlign="middle">
            <Input
              label={t('amountToPay')} //"Amount to Pay"
              required
              name="amountToPay"
              inputStyles={styles.width80Percen}
              placeholder="0"
              handleChange={handleChange}
              value={amountToPay ? amountToPay.toLocaleString('ID') : 0}
              type="text"
              labelInput={<Label basic>{currency}</Label>}
              labelPosition="left"
            />
          </Grid.Column>
          <Grid.Column width={7} verticalAlign="bottom">
            <Button
              name="isTopUp"
              value={true}
              style={styles.buttonGold}
              floated="right"
              onClick={handleButton}
            >
              {/* Top Up */}
              {t('topUp')}
            </Button>
            <Button
              name="isProcessPay"
              value={true}
              style={styles.buttonGold}
              floated="right"
              onClick={handleButton}
              disabled={amountToPay === 0}
            >
              {/* Pay */}
              {t('pay')}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop10RightLeft15Bottom5} />
      </Grid>
    </Segment>
  );
};
PaymentMethodForm.propTypes = {
  id: PropTypes.number,
  expiredDate: PropTypes.string,
  balance: PropTypes.number,
  outstandingBill: PropTypes.number,
  currency: PropTypes.string,
  handleButton: PropTypes.func,
  amountToPay: PropTypes.number,
  handleChange: PropTypes.func,
  currencyCompany: PropTypes.string,
};
export default PaymentMethodForm;
