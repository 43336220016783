import { GOLD_COLOR, WHITE_COLOR } from '../../constant/colour';

const styles = {
  page: {
    background: WHITE_COLOR,
    marginBottom: '100px',
  },
  container: {
    width: '800px',
  },
  lineDivider: {
    marginTop: '20px',
    marginBottom: '20px',
    color: 'white',
    border: '0',
    borderTop: '1px solid #cfcfe0',
  },
  grid: {
    marginTop: '0',
    marginBottom: '0',
    marginRight: '0',
    marginLeft: '0',
  },
  row: {
    flexDirection: 'column',
  },
  h3: {
    marginBottom: '5px',
    fontSize: '24px',
  },
  h4: {
    margin: '0',
    fontSize: '18px',
  },
  image: {
    marginTop: '15px',
    marginLeft: '-10px',
    height: '45px',
  },
  label: {
    width: '50%',
    marginRight: '0',
  },
  input: {
    width: '50%',
  },
  buttonRegister: {
    background: GOLD_COLOR,
    display: 'block',
    margin: '0 auto',
  },
  buttonText: {
    color: WHITE_COLOR,
    fontSize: '14px',
  },
  imagePadding: {
    paddingLeft: 0,
    paddingRight: '21px',
  },
  paddingTop34: {
    paddingTop: '34px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
};

export default styles;
