import apiClient from './apiClient';
import { GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL } from './apiUrl';

const getAdditionalServicesListTransactionApi = (
  origin,
  destination,
  currency
) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    GET_ADDITIONAL_SERVICES_LIST_IN_TRANSACTION_URL(
      origin,
      destination,
      currency
    )
  );
};

export { getAdditionalServicesListTransactionApi };
