import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import styles from './styles';
import FeatureItem from './FeatureItem';
import {
  fixPackageIcon,
  readyPackageIcon,
  promoPackageIcon,
} from '../../../../assets/icon/features';

import { useTranslation } from 'react-i18next';

const FeatureMini = () => {
  const { t } = useTranslation();
  return (
    <Grid.Column style={styles.marginLeft16}>
      <FeatureItem
        number="1"
        style={styles.featureMiniIcon}
        iconUrl={readyPackageIcon}
        text={`${t('findReadyPackage')}`} // Find Ready Packages we have prepared for you.
        urlLink="/package-list/ready/all"
      />
      <FeatureItem
        number="2"
        style={styles.featureMiniIcon}
        iconUrl={fixPackageIcon}
        text={`${t('findSeriesPackage')}`} // Find Series Packages that we made. All included.
        urlLink="/package-list/series/all"
      />
      <FeatureItem
        number="3"
        style={styles.featureMiniIcon}
        iconUrl={promoPackageIcon}
        text={`${t('findOffering')}`} // Find our offering best Promo Packages for you.
        urlLink="/package-list/promo/all"
      />
    </Grid.Column>
  );
};

FeatureMini.propTypes = {
  t: PropTypes.func,
};

export default FeatureMini;
