import React, { useState, useEffect } from 'react';
import { Grid, Segment, Loader, Pagination } from 'semantic-ui-react';
import TabMenu from './TabMenu';
import { CardPackage } from './cardPackage';
import { ModalConfirm, ModalAlert } from '../../shared/components/modal';
import ModalCancellationReason from '../../shared/components/modal/ModalCancellationReason';
import Input from '../../../components/input/WithLabel';
import {
  getTransactionHistoryAll,
  postTourTransactionConfirmation,
  resetStatusTourTransactionConfirm,
} from '../../shared/actions/bookingAction';
//import { postTourTransactionsConfirmation } from '../../../pages/shared/actions/tourTransactionAction';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './../styles';
import { Redirect } from 'react-router-dom';
import { getTime } from '../../../genericFunction/itineraryBuilder/timeHelper';
import { useTranslation } from 'react-i18next';
import MyBookingDetail from './MyBookingDetail';
import { withRouter } from 'react-router-dom';

const MyBooking = props => {
  const { t } = useTranslation();
  const { transactionId } = props.match.params;
  //==========initial state=========
  const [tabActive, setTabActive] = useState('created');
  const [modalDelete, setModalDelete] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalBookNow, setModalBookNow] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalSuccessDelete, setModalSuccessDelete] = useState(false);
  const [nextPagePay, setNextPagePay] = useState(false);
  const [idAction, setIdAction] = useState('');
  const [paymentAction, setPaymentAction] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [similarPage, setSimilarPage] = useState(false);
  const [readyFixPrice, setReadyFixPrice] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [modalCancel, setModalCancel] = useState(false);
  // const [CancellationReasonId, setCancellationReasonId] = useState(null);
  // const [CancellationReasonNote, setCancellationReasonNote] = useState('');
  const [dataDetail, setDataDetail] = useState({});
  //===========action===========
  const dispatch = useDispatch();
  const getTransactionHistoryAllAction = param =>
    dispatch(getTransactionHistoryAll(param));
  const postTourTransactionConfirmationAction = param =>
    dispatch(postTourTransactionConfirmation(param));
  const resetStatusTourTransactionConfirmAction = () =>
    dispatch(resetStatusTourTransactionConfirm());
  //============store==============
  const transactionHistoryAll = useSelector(
    state => state.booking.transactionHistoryAll
  );
  const statusTransactionConfirm = useSelector(
    state => state.booking.postTourTransactionConfirmStatus
  );
  const loadingHistory = useSelector(state => state.booking.loadingHistory);
  const listCancelledTransaction = useSelector(
    state => state.booking.listCancelledTransaction
  );
  const listCreatedTransaction = useSelector(
    state => state.booking.listCreatedTransaction
  );
  const listInPaymentTransaction = useSelector(
    state => state.booking.listInPaymentTransaction
  );
  const listConfirmedTransaction = useSelector(
    state => state.booking.listConfirmedTransaction
  );
  const listCompletedTransaction = useSelector(
    state => state.booking.listCompletedTransaction
  );
  useEffect(() => {
    getTransactionHistoryAllAction('Booking_created');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (statusTransactionConfirm === 'success') {
      getListBooking(tabActive);
      setModalSuccessDelete(true);
      setMessageAlert(`Your Booking Code ${idAction} has been Cancelled`);
      resetStatusTourTransactionConfirmAction();
    }
    // eslint-disable-next-line
  }, [statusTransactionConfirm]);

  const handleTab = (e, { value }) => {
    setTabActive(value);
    getListBooking(value);
  };
  const handleModal = (e, { name, value, desc }) => {
    name === 'modalPayment'
      ? setModalPayment(!modalPayment)
      : name === 'modalBookNow'
      ? setModalBookNow(!modalBookNow)
      : name === 'modalEdit'
      ? setModalEdit(!modalEdit)
      : setModalEdit(modalEdit);
    setIdAction(value);
    setPaymentAction(desc);
  };
  const handleClosePayment = () => setModalPayment(false);
  const handleCloseDelete = () => setModalDelete(false);
  const handleCloseCancel = () => setModalCancel(false);
  const handleCloseSuccessDelete = () => setModalSuccessDelete(false);
  const handleChange = (e, { value }) => {
    setSearchTitle(value);
    setActivePage(1);
  };
  const handleClickCancel = (e, { value }) => {
    setIdAction(value);
    setModalCancel(true);
  };
  const confirmDelete = data => {
    let cancelTransaction = {
      Id: idAction,
      Confirmation: 'Cancel',
      CancellationReasonId: data.CancellationReasonId,
      CancellationReasonNote: data.CancellationReasonNote,
    };
    postTourTransactionConfirmationAction(cancelTransaction);
    // .then(() =>
    //   this.setState({
    //     modalSuccessDelete: true,
    //     messageAlert: `Your Booking Code ${
    //       this.state.idAction
    //     } has been Cancelled`,
    //   })
    // )
    // .catch(() =>
    //   this.setState({
    //     loadingPostCancel: false,
    //     modalSuccessDelete: true,
    //     messageAlert: 'Cancelled transaction failed',
    //   })
    // );
    setModalDelete(false);
    setModalCancel(false);
  };
  const handleSaveCancellationReason = data => {
    let newData = {
      CancellationReasonId: data.CancellationReasonId,
      CancellationReasonNote: data.CancellationReasonNote,
    };
    confirmDelete(newData);
  };
  const confirmPay = () => {
    setNextPagePay(true);
    setModalPayment(false);
  };
  const getListBooking = tabActive => {
    transactionHistoryAll.length = 0;
    tabActive === 'created'
      ? getTransactionHistoryAllAction('Booking_created')
      : tabActive === 'inPayment'
      ? getTransactionHistoryAllAction('settle')
      : tabActive === 'confirmed'
      ? getTransactionHistoryAllAction('Done')
      : tabActive === 'cancelled'
      ? getTransactionHistoryAllAction('Booking_cancelled')
      : getTransactionHistoryAllAction('Booking_completed');
  };
  const filtered = listData => {
    return listData.filter(
      data =>
        data.Title.toLowerCase().indexOf(searchTitle.toLowerCase()) !== -1 ||
        data.Id.toLowerCase().indexOf(searchTitle.toLowerCase()) !== -1
    );
  };
  const pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;

    for (var i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);

    return sets;
  };
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const handleCreateSimilar = (idAction, packageType, expiredOn) => {
    let isValid = getTime(new Date()) > getTime(expiredOn);
    setIdAction(idAction);
    if (packageType === 'FixedDateVariable') {
      setReadyFixPrice(!isValid);
      setModalSuccessDelete(isValid);
      setMessageAlert('Package is already expired');
    } else {
      setSimilarPage(true);
    }
  };

  const handleOpenDetail = value => {
    // setDataDetail(value);
    // setOpenDetail(true);
    // let { id } = value;
    // getSuplementItemByTransactionIdAction(id);
    // getTourTransactionToBePayedAction(id);
    // getTourTransactionSummaryAction(id);
    // getTourTransactionBillsAction(id);
    let newPageUrl = `/my-profile/booking/${value}`;
    window.open(newPageUrl, '_blank');
  };

  const handleBackToList = () => {
    setDataDetail({});
    // setOpenDetail(false);
  };

  //=============helper==============
  const getListData = () => {
    switch (tabActive) {
      case 'created':
        return listCreatedTransaction;
      case 'inPayment':
        return listInPaymentTransaction;
      case 'confirmed':
        return listConfirmedTransaction;
      case 'cancelled':
        return listCancelledTransaction;
      case 'completed':
        return listCompletedTransaction;
      default:
        return '';
    }
  };

  let { isSales } = props;
  let transactionList = getListData();
  let filterData = filtered(transactionList);
  let pagedData = pagination(filterData, 3);
  let listData = pagedData[activePage - 1];
  let showCard = transactionList && !loadingHistory;
  if (nextPagePay) {
    return <Redirect to={'/partial-payment/' + idAction} />;
  } else if (similarPage) {
    return <Redirect to={'/ready/similar/' + idAction} />;
  } else if (readyFixPrice) {
    return <Redirect to={'/ready/ready-package-variable/' + idAction} />;
  }

  return (
    <div>
      <Segment style={styles.segment}>
        <Grid>
          {transactionId ? (
            <Grid.Row>
              <Grid.Column>
                <MyBookingDetail
                  id={dataDetail.id ? dataDetail.id : transactionId}
                  listPaymentTerm={dataDetail.listPaymentTerm}
                  packageType={dataDetail.packageType}
                  handleBackToList={handleBackToList}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TabMenu tabActive={tabActive} handleTab={handleTab} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={6}>
                  <Input
                    inputStyles={styles.searchInput}
                    transparent
                    icon="search"
                    iconPosition="left"
                    placeholder={t('searchTourNameOrBookingCode')} //"Search Tour Name or Booking Code"
                    name="searchTitle"
                    value={searchTitle}
                    handleChange={handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Loader active={loadingHistory} inline="centered" />
                <Grid.Column width={16}>
                  {showCard && listData
                    ? listData.map((data, index) => {
                        let commission =
                          data.PackageType === 'Fixed'
                            ? data.FixedPackage.BookingCommission.ApplicableCommission.reduce(
                                (acc, commission) => {
                                  return acc + commission.TotalPrice;
                                },
                                0
                              )
                            : 0;
                        let totalPayable =
                          data.PackageType === 'Fixed'
                            ? data.FixedPackage.BookingCommission
                                .TotalPriceForCustomer
                            : 0;
                        let paymentTerm = data.PaymentTerms
                          ? data.PaymentTerms.length > 0
                            ? data.PaymentTerms.filter(paid => !paid.IsPayed)[0]
                            : ''
                          : '';
                        let descPaymentTerm = paymentTerm
                          ? paymentTerm.Description
                          : '';
                        let percentagePaymentTerm = paymentTerm
                          ? paymentTerm.PaymentPercentage
                          : 0;
                        return (
                          <div key={3 * (activePage - 1) + index}>
                            <CardPackage
                              title={data.Title}
                              id={data.Id}
                              expiredOn={data.ExpiredOn}
                              image={data.City.ImageUrl}
                              startDate={data.StartDate}
                              endDate={data.EndDate}
                              city={data.City.Name}
                              country={data.Country.Name}
                              handleModal={handleModal}
                              status={data.Status}
                              currency={data.CurrencyId}
                              totalPrice={data.TourTotalPrice}
                              commision={commission}
                              descPaymentTerm={descPaymentTerm}
                              percentagePaymentTerm={percentagePaymentTerm}
                              totalPayable={totalPayable}
                              totalPaid={data.TotalPayed}
                              packageType={data.PackageType}
                              listPaymentTerm={data.PaymentTerms}
                              activePage={activePage}
                              searchTitle={searchTitle}
                              isSales={isSales}
                              handleCreateSimilar={handleCreateSimilar}
                              //ready fix price
                              bookingTemplateRef={
                                data.PackageType === 'FixedDateVariable' &&
                                data.BookingTemplateRef
                              }
                              handleCancel={handleClickCancel}
                              handleOpenDetail={handleOpenDetail}
                            />
                          </div>
                        );
                      })
                    : !loadingHistory && (
                        <h3 style={styles.textAlignCenter}>
                          {/* There are no {tabActive} transactions */}
                          {t('thereAreNoTransactions', {
                            status: t(tabActive),
                          })}
                        </h3>
                      )}
                  <ModalConfirm
                    openModal={modalDelete}
                    size="tiny"
                    message={t('cancelBooking?')} //"Cancel Booking ?"
                    close={handleCloseDelete}
                    confirm={confirmDelete}
                  />
                  <ModalCancellationReason
                    openModal={modalCancel}
                    handleSave={handleSaveCancellationReason}
                    handleClose={handleCloseCancel}
                  />
                  <ModalConfirm
                    openModal={modalPayment}
                    size="tiny"
                    message={t('AreYouSureWantToPay', {
                      paymentAction: paymentAction,
                    })} //{'Are you sure want to pay ' + paymentAction + '?'}
                    close={handleClosePayment}
                    confirm={confirmPay}
                  />
                  <ModalAlert
                    openModal={modalSuccessDelete}
                    size="tiny"
                    close={handleCloseSuccessDelete}
                    message={messageAlert}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="center">
                <Grid.Column width={16}>
                  {pagedData && pagedData.length > 1 && !loadingHistory && (
                    <Pagination
                      totalPages={pagedData.length}
                      onPageChange={pageChange}
                      activePage={activePage}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Segment>
    </div>
  );
};
MyBooking.propTypes = {
  getTransactionHistoryAll: PropTypes.func,
  postTourTransactionConfirmation: PropTypes.func,
  resetStatusTourTransactionConfirm: PropTypes.func,
  resetStatusTransactionHistoryAll: PropTypes.func,
  transactionHistoryAll: PropTypes.array,
  statusTransactionConfirm: PropTypes.string,
  loadingHistory: PropTypes.bool,
  isSales: PropTypes.bool,
  listCancelledTransaction: PropTypes.array,
  listCreatedTransaction: PropTypes.array,
  listInPaymentTransaction: PropTypes.array,
  listConfirmedTransaction: PropTypes.array,
  listCompletedTransaction: PropTypes.array,
  t: PropTypes.func,
  // postTourTransactionsConfirmation: PropTypes.func,
  // statusTransactionsConfirm: PropTypes.string,
  match: PropTypes.object,
};

export default withRouter(MyBooking);
