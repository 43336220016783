import apiClient from './apiClient';
import {
  GET_EXCURSION_TYPES_URL,
  GET_EXCURSION_BY_FILTER_URL,
  GET_EXCURSION_BY_ID_URL,
} from './apiUrl';

const getExcursionTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_EXCURSION_TYPES_URL);
};
const getExcursionByFilterApi = (data, bodyReq) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(
    `${GET_EXCURSION_BY_FILTER_URL}?cityid=${data.cityid}&attractionTypeId=${
      data.attractionTypeId
    }&requestedDate=${data.requestedDate}&pax=${data.pax}`,
    bodyReq
  );
};
const getExcursionByIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_EXCURSION_BY_ID_URL}/${id}`);
};
export { getExcursionTypesApi, getExcursionByFilterApi, getExcursionByIdApi };
