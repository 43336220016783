import apiClient from './apiClient';
import {
  GET_RESTAURANT_BY_FILTER_URL,
  GET_RESTAURANT_RATING_URL,
  GET_RESTAURANT_SPECIALIZATIONS_URL,
  GET_RESTAURANT_MENU_CLASSES_URL,
  GET_RESTAURANT_MENU_BY_ID_URL,
  GET_MENU_BY_ID_URL,
  GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_URL,
} from './apiUrl';

const getRestaurantByFilterApi = (data, bodyReq) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(
    `${GET_RESTAURANT_BY_FILTER_URL}?cityId=${data.cityId}&ratingIds=${
      data.ratingIds
    }&locationIds=${data.locationIds}&specializationId=${
      data.specializationId
    }&menuClassId=${data.menuClassId}&facilityIds=${
      data.facilityIds
    }&requestedDate=${data.requestedDate}&pax=${data.pax}`,
    bodyReq
  );
};
// &requestedDate=${data.requestedDate}&pax=${1}
const getRestaurantRatingApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_RESTAURANT_RATING_URL);
};
const getRestaurantSpecializationsApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_RESTAURANT_SPECIALIZATIONS_URL);
};
const getRestaurantMenuClassesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_RESTAURANT_MENU_CLASSES_URL);
};
const getRestaurantMenuByIdApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_RESTAURANT_MENU_BY_ID_URL}?profileId=${data}`);
};
const getMenuByIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_MENU_BY_ID_URL}/${id}`);
};
const getRestaurantProfileByServiceItemApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_RESTAURANT_PROFILE_BY_SERVICE_ITEM_URL}/${id}`);
};
export {
  getRestaurantByFilterApi,
  getRestaurantRatingApi,
  getRestaurantSpecializationsApi,
  getRestaurantMenuClassesApi,
  getRestaurantMenuByIdApi,
  getMenuByIdApi,
  getRestaurantProfileByServiceItemApi,
};
