// import { GOLD_COLOR } from '../../../../../constant/colour';
const GOLD_COLOR = '#e6ca6b';

const style = {
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  paddingSideTwenty: {
    padding: '10px 20px',
  },
  displayBlockBold: { display: 'block', fontWeight: 'bold' },
  shadowTransparant: { boxShadow: '0 0 0 transparent' },
  fontBold: { fontWeight: 'bold' },
  textCenter: {
    textAlign: 'center',
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  paddingRight20: {
    paddingRight: '20px',
  },
  borderBottom: {
    borderBottom: '1px solid grey',
  },
  paddingLeft20: {
    paddingLeft: '20px',
  },
  marginLeftRight30px: {
    marginLeft: '30px',
    marginRight: '30px',
  },
  paddingLeft8px: {
    paddingLeft: '8px',
  },
  paddingLeft4pxWidth100percent: {
    width: '100%',
    paddingLeft: '4px',
  },
  marginTop10px: {
    marginTop: '10px',
  },
  paddingBottom3: { paddingBottom: '3px' },
};

export default style;
