import { GET_SCHEDULE, RESET_GET_SCHEDULE } from './constant/actionTypes';
import { getScheduleApi } from '../../api/scheduleApi';

const getSchedule = id => {
  return dispatch => {
    return dispatch({
      type: GET_SCHEDULE,
      payload: getScheduleApi(id),
    });
  };
};

const resetGetSchedule = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_SCHEDULE });
  };
};

export { getSchedule, resetGetSchedule };
