import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
//import Loadable from 'react-loadable';
import Home from './pages/home/Home';
import { Header } from './pages/shared/components/header/';
import Footer from './pages/shared/components/footer/Footer';
import AboutUs from './pages/disclaimer/aboutUs';
import PrivacyPolicy from './pages/disclaimer/privacyPolicy';
import ComingSoon from './components/common/commingSoon';
import TermsOfUse from './pages/disclaimer/termsOfUse';
import ContactUs from './pages/disclaimer/contactUs';
import Register from './pages/register/Register';
import PackageList from './pages/packageList/PackageList'; //'./pages/packageList';
import FixedPackage from './pages/seriesPackage/SeriesPackage';
// import CustomPackage from './pages/customPackage';
import Summary from './pages/summary/Summary';
import MyProfile from './pages/myProfile/MyProfile';
import Payment from './pages/payment/PaymentPage';
// import CustomPackage from './pages/customPackage';
import CustomPackage from './pages/customPackage/CustomPackage';
import ReadyPackage from './pages/readyPackage/ReadyPackage';
import ForgotPassword from './pages/forgotResetPassword/ForgotPassword';
import ResetPassword from './pages/forgotResetPassword/ResetPassword';
// import PartialPayment from './pages/partialPayment';
import PartialPayment from './pages/partialPayment/PartialPaymentPage';
import ScrollToTop from './components/scrollToTop/ScrollToTop';

// import testpage from './pages/testPage/TestPage';
/*const Home = Loadable({
  loader: () => import('./pages/home'),
  loading: () => <div>Loading....</div>,
});*/

const HeaderWithRouter = withRouter(Header);
const App = () => (
  <div
    style={{ display: 'flex', flexDirection: 'column', background: 'white' }}
  >
    <ScrollToTop>
      <HeaderWithRouter />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/register" component={Register} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/coming-soon" component={ComingSoon} />
        {/* private route */}
        <PrivateRoute
          path="/package-list/:packageType/:tourPaxType"
          component={PackageList}
        />
        <PrivateRoute path="/series-package/:id" component={FixedPackage} />
        <PrivateRoute
          path="/ready-package-variable/:id"
          component={FixedPackage}
        />
        <PrivateRoute path="/summary/:packageType" component={Summary} />
        <PrivateRoute
          path="/my-profile/:tab/:transactionId?"
          component={MyProfile}
        />
        <PrivateRoute path="/payment/:step/:id" component={Payment} />
        <PrivateRoute path="/partial-payment/:id" component={PartialPayment} />
        <PrivateRoute path="/custom-package" component={CustomPackage} />
        <PrivateRoute path="/ready/:packageType/:id" component={ReadyPackage} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:token/:email" component={ResetPassword} />
        {/* <Route path="/testpage" component={testpage} /> */}
        <Redirect to="/coming-soon" />
      </Switch>
      <Footer />
    </ScrollToTop>
  </div>
);

export default App;
