import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
// import styles from './styles';

const BtnIcon = props => (
  <Icon
    name={props.name}
    style={props.iconStyle}
    onClick={props.handleOnClick}
    size={props.size}
  />
);

BtnIcon.propTypes = {
  handleOnClick: PropTypes.func,
  style: PropTypes.object,
  name: PropTypes.string,
  iconStyle: PropTypes.object,
  size: PropTypes.string,
};
export default BtnIcon;
