//-----library-----
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid, Icon } from 'semantic-ui-react';
//-----component-----
import Filter from '../../formFilter/FormFilter';
import ListCard from './ListCard';
import { ButtonCloseModal } from '../../common';
import ModalAccommodationWithMap from '../ModalAccommodationWithMap';
import ModalDetailListRoom from '../modalDetailListRoom/ModalDetailListRoom';
//-----action-----
import { resetStatusAccommodation } from '../../../actions/accommodationAction';
import { resetStatusTransaction } from '../../../actions/tourTransactionAction';
import { setCoordinateAddress } from '../../../actions/locationAction';
import { getAccommodationRooms } from '../../../actions/accommodationAction';
//-----style------
import styles from './styles';
//-----constants-----
import { rating, availability } from './constants/constants';
// import { useTranslation } from 'react-i18next';

const ModalListAccommodation = props => {
  // const { t } = useTranslation();
  //----- initial -----
  const [formFilter, setFormFilter] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [activeSort, setActiveSort] = useState('');
  const [openMaps, setOpenMaps] = useState(false);
  const [openRooms, setOpenRooms] = useState(false);
  const [accommodationData, setAccommodationData] = useState(null);
  //----- store -----
  const listAccommodationRatings = useSelector(
    state => state.accommodation.accommodationRatings
  );
  const listAccommodationType = useSelector(
    state => state.accommodation.accommodationTypes
  );
  const listAccommodationLocation = useSelector(
    state => state.accommodation.accommodationLocations
  );
  const listAccommodationFacilities = useSelector(
    state => state.accommodation.accommodationFacilities
  );
  // const getAccommodationFilterParameterStatus = useSelector(
  //   state => state.accommodation.getAccommodationFilterParameterStatus
  // );
  const listTourTypes = useSelector(state => state.tourTransaction.tourTypes);
  const tourTypeStatus = useSelector(
    state => state.tourTransaction.getTourTypeStatus
  );
  const loadingAccommodation = useSelector(
    state => state.accommodation.loading
  );
  const loadingGetRoom = useSelector(state => state.accommodation.loading);
  const loadingTourTransaction = useSelector(
    state => state.tourTransaction.loading
  );
  const loadingGetAccommodation = useSelector(
    state => state.accommodation.loadingGetAccommodation
  );
  const coordinates = useSelector(state => state.location.coordinates);
  const listAccommodationRoomById = useSelector(
    state => state.accommodation.accommodationRooms
  );
  const getAccommodationStatus = useSelector(
    state => state.accommodation.getAccommodationStatus
  );
  //-----action------
  const dispatch = useDispatch();
  const resetStatusAccommodationAction = () =>
    dispatch(resetStatusAccommodation());
  const resetStatusTransactionAction = () => dispatch(resetStatusTransaction());
  const setCoordinateAddressAction = (coordinate, id, type) =>
    dispatch(setCoordinateAddress(coordinate, id, type));
  const getAccommodationRoomsAction = data =>
    dispatch(getAccommodationRooms(data));

  //------lifecycle------
  useEffect(() => {
    // if (tourTypeStatus === 'success' && getAccommodationFilterParameterStatus) {
    if (tourTypeStatus === 'success' && getAccommodationStatus === 'success') {
      let data = getFormFilter(
        availability,
        listAccommodationRatings,
        listAccommodationType,
        listAccommodationFacilities,
        listAccommodationLocation,
        listTourTypes
      );
      setFormFilter(data);
      resetStatusAccommodationAction();
      resetStatusTransactionAction();
    }
    // eslint-disable-next-line
  }, [tourTypeStatus, getAccommodationStatus]);
  //-----helper------
  const getFormFilter = (
    availabilityList,
    listAccommodationRatings,
    listAccommodationType,
    listAccommodationFacilities,
    listAccommodationLocation,
    listTourTypes
  ) => {
    let data = [
      {
        name: 'Accommodation Status',
        data: availabilityList,
      },
      {
        name: 'Accommodation Star Rating',
        data: showStar(listAccommodationRatings),
      },
      {
        name: 'Accommodation Type',
        data: showOptions(listAccommodationType),
      },
      {
        name: 'Accommodation Facilities',
        data: showOptions(listAccommodationFacilities),
      },
      {
        name: 'Accommodation Location',
        data: showOptions(listAccommodationLocation),
      },
      {
        name: 'Accommodation Segment',
        data: showOptions(listTourTypes),
      },
    ];
    return data;
  };

  const showOptions = data => {
    return data.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  const showStar = listRating => {
    listRating = listRating.sort((a, b) => {
      return parseInt(b.Id, 10) - parseInt(a.Id, 10);
    });
    return listRating.map(data => {
      let stars = new Array(parseInt(data.Id, 10)).fill(0);
      let text = (
        <label key={data.Id}>
          {stars.length !== 0
            ? stars.map((star, index) => (
                <Icon key={star + index} name="star" color="yellow" />
              ))
            : 'Unrated'}
        </label>
      );
      return {
        key: data.Id,
        text,
        checked: false,
      };
    });
  };
  const findIndexByName = (list, name) => {
    return list.findIndex(e => e.name === name);
  };
  const listChecked = (formFilter, index) => {
    return formFilter[index].data.filter(e => {
      return e.checked === true;
    });
  };
  const filterbyExistingIndex = (filters, list) => {
    return filters.every(filter => {
      return list.findIndex(e => e.Name === filter.text) !== -1;
    });
  };
  const filterByKeyId = (formFilter, index, id) => {
    return (
      formFilter[index].data.findIndex(e => e.key === id && e.checked) !== -1
    );
  };
  const filterAccommodation = (listData, formFilter, searchName) => {
    let dataFilter, indexForm, filters;
    return listData.filter(data => {
      let {
        AccommodationRating,
        AccommodationType,
        AccommodationLocations,
        AccommodationSegment,
        ProfileFacilities,
        IsInstantConfirmation,
      } = data;
      dataFilter = true;
      if (searchName) {
        dataFilter =
          dataFilter &&
          data.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1;
      }
      //-----Accommodation Status-----
      indexForm = findIndexByName(formFilter, 'Accommodation Status');
      if (formFilter[indexForm].data.some(e => e.checked)) {
        dataFilter =
          dataFilter &&
          formFilter[indexForm].data.findIndex(
            e => (e.key === 'AVAILABLE') === IsInstantConfirmation && e.checked
          ) !== -1;
      }
      //-----Accommodation Star Rating-----
      indexForm = findIndexByName(formFilter, 'Accommodation Star Rating');
      if (formFilter[indexForm].data.some(e => e.checked)) {
        dataFilter =
          dataFilter &&
          filterByKeyId(formFilter, indexForm, AccommodationRating.Id);
      }
      //-----Accommodation Type-----
      indexForm = findIndexByName(formFilter, 'Accommodation Type');
      if (formFilter[indexForm].data.some(e => e.checked)) {
        dataFilter =
          dataFilter &&
          filterByKeyId(formFilter, indexForm, AccommodationType.Id);
      }
      //-----Accommodation Location-----
      indexForm = findIndexByName(formFilter, 'Accommodation Location');
      filters = listChecked(formFilter, indexForm);
      dataFilter =
        dataFilter && filterbyExistingIndex(filters, AccommodationLocations);
      //-----Accommodation Segment-----
      indexForm = findIndexByName(formFilter, 'Accommodation Segment');
      filters = listChecked(formFilter, indexForm);
      dataFilter =
        dataFilter && filterbyExistingIndex(filters, AccommodationSegment);
      //-----Accommodation Facilities-----
      indexForm = findIndexByName(formFilter, 'Accommodation Facilities');
      filters = listChecked(formFilter, indexForm);
      dataFilter =
        dataFilter && filterbyExistingIndex(filters, ProfileFacilities);

      return dataFilter;
    });
  };

  const handleChangeCheckbox = (e, { name, value }) => {
    let dataList = [...formFilter];
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    setFormFilter(dataList);
  };
  const handleResetFilter = () => {
    let newformFilter = [...formFilter];
    newformFilter = newformFilter.map(filter =>
      filter.data.some(e => e.checked)
        ? { name: filter.name, data: resetFilter(filter.data) }
        : filter
    );
    setFormFilter(newformFilter);
  };
  const resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  const handleChange = (e, { name, value }) => {
    if (name === 'searchName') {
      setSearchName(value);
    } else {
      setActiveSort(value);
    }
    setActivePage(1);
  };

  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const compare = (a, b) => {
    let comparison = 0;
    if (a.AccommodationRating.Id > b.AccommodationRating.Id) {
      comparison = 1;
    } else if (b.AccommodationRating.Id > a.AccommodationRating.Id) {
      comparison = -1;
    }
    return comparison;
  };
  const sortByRating = (listAccommodation, type) => {
    return type === 'lowest rating'
      ? listAccommodation.sort(compare)
      : type === 'highest rating' && listAccommodation.sort(compare).reverse();
  };
  const handleOnClose = () => {
    setActivePage(1);
    props.onClose();
    handleCloseMap();
  };
  const handleOpenMap = () => {
    handleShouldGetCoordinate();
    setOpenMaps(true);
  };
  const handleCloseMap = () => setOpenMaps(false);
  const handleOpenRooms = value => handleGetRooms(value);
  const handleCloseRooms = () => setOpenRooms(false);
  const handleGetRooms = value => {
    let accommodation = data.find(item => item.Id === value);
    setOpenRooms(true);
    setAccommodationData(accommodation);
    let newDataAccommodation = { ...props.dataAccommodation };
    newDataAccommodation.profileId = value;
    getAccommodationRoomsAction(newDataAccommodation);
    getAccommodationRoomsAction(newDataAccommodation, props.demoPriceObj);

    let coordinate = coordinates.find(item => item.id === value);
    if (coordinate) {
      setCoordinateAddressAction(
        coordinate.coordinate,
        coordinate.id,
        'accommodation'
      );
    } else {
      saveCoordinate(accommodation);
    }
  };

  const handleShouldGetCoordinate = () => {
    let data = filterAccommodationByCoordinate();
    let length = data.length;
    if (length > 0) {
      for (var i = 0; i < length; i++) {
        saveCoordinate(data[i]);
      }
    }
  };
  const saveCoordinate = accommodation => {
    let { Coordinate } = accommodation.AddressObject;
    let newCoordinate = {
      lat: Coordinate ? Number(Coordinate.Lat) : null,
      lng: Coordinate ? Number(Coordinate.Lng) : null,
    };
    setCoordinateAddressAction(
      newCoordinate,
      accommodation.Id,
      'accommodation'
    );
  };
  const handleSetRooms = (accommodations, room, service) => {
    setOpenRooms(false);
    props.setAccommodationRoom(accommodations, room, service);
  };
  const filterAccommodationByCoordinate = () => {
    let { data } = props;
    return data.filter(obj => {
      return !coordinates.some(obj2 => {
        return obj.Id === obj2.id;
      });
    });
  };

  let { open, name, data, location, locationName } = props;
  data =
    formFilter.length > 0 && data
      ? filterAccommodation(data, formFilter, searchName)
      : data;
  data = activeSort ? sortByRating(data, activeSort) : data;
  let loading = loadingAccommodation || loadingTourTransaction;

  data = !loadingGetAccommodation ? data : [];
  let loadingRoom = loadingGetRoom;
  return (
    <Modal open={open} onClose={handleOnClose} size="large" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {name}
        <ButtonCloseModal onClose={handleOnClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description>
          <Grid>
            <Grid.Column width={5}>
              <Filter
                formFilter={formFilter}
                handleChange={handleChangeCheckbox}
                resetFilter={handleResetFilter}
                loading={loading}
                loadingRoom={loadingRoom}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <ListCard
                location={location}
                locationName={locationName}
                data={data}
                loading={loadingGetAccommodation}
                handleChange={handleChange}
                searchName={searchName}
                activePage={activePage}
                pageChange={pageChange}
                rating={rating}
                activeSort={activeSort}
                handleOpenRooms={handleOpenRooms}
                dataGetAccommodation={props.dataAccommodation}
                handleOpenMap={handleOpenMap}
              />
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <ModalAccommodationWithMap
        isOpen={openMaps}
        onClose={handleCloseMap}
        data={data}
        coordinates={coordinates}
        handleOpenRooms={handleOpenRooms}
        locationName={locationName}
      />
      <ModalDetailListRoom
        open={openRooms}
        onClose={handleCloseRooms}
        data={listAccommodationRoomById}
        header={accommodationData ? accommodationData.Name : null}
        desc={accommodationData ? accommodationData.Name : null}
        amenities={
          accommodationData ? accommodationData.ProfileFacilities : null
        }
        closeModalAccommodation={handleOnClose}
        loadingRoom={loadingGetRoom}
        setAccommodationRoom={handleSetRooms}
        accommodationProfile={accommodationData}
      />
    </Modal>
  );
};

ModalListAccommodation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  data: PropTypes.array,
  location: PropTypes.string,
  listAccommodationRatings: PropTypes.array,
  resetStatusAccommodation: PropTypes.func,
  listAccommodationType: PropTypes.array,
  listAccommodationLocation: PropTypes.array,
  listAccommodationFacilities: PropTypes.array,
  getAccommodationFilterParameterStatus: PropTypes.bool,
  getAccommodationStatus: PropTypes.string,
  listTourTypes: PropTypes.array,
  tourTypeStatus: PropTypes.string,
  resetStatusTransaction: PropTypes.func,
  loadingAccommodation: PropTypes.bool,
  loadingTourTransaction: PropTypes.bool,
  loadingGetAccommodation: PropTypes.bool,
  loadingGetRoom: PropTypes.bool,
  loadingRoom: PropTypes.bool,
  headlineProgram: PropTypes.object,
  setAccommodationRoom: PropTypes.func,
  accomIndex: PropTypes.number,
  locationName: PropTypes.string,
  setCoordinateAddress: PropTypes.func,
  coordinates: PropTypes.array,
  listAccommodationRoomById: PropTypes.array,
  getAccommodationRooms: PropTypes.func,
  dataAccommodation: PropTypes.object,
  t: PropTypes.func,
  demoPriceObj: PropTypes.object,
};
export default ModalListAccommodation;
