import React from 'react';
import { Segment, Grid, Icon, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  DEPARTURE,
  DRIVING,
  ARRIVAL,
  QUEUETIME,
  DAYEND,
  DAYSTART,
  TRANSIT,
  FLIGHTTIME,
} from '../../../../../constant/activityTypes';
import { timeDuration } from '../../../../../genericFunction/moment';
import { CUSTOM, READY, FIXED } from '../../../../../constant/packageTypes';
import { MessageOverload } from './';
import { withTranslation } from 'react-i18next';

class ActivityNoImage extends React.Component {
  iconRender() {
    const { MovementName, Item } = this.props.data;
    const { currentPackage } = this.props;
    switch (MovementName) {
      case DRIVING:
        return 'fas fa-car fa-2x';
      case DEPARTURE: {
        if (currentPackage === FIXED) {
          return Item.Name === 'Station'
            ? 'fas fa-subway fa-2x'
            : 'fas fa-plane-departure fa-2x';
        } else return 'fas fa-plane-departure fa-2x';
      }
      case ARRIVAL: {
        if (currentPackage === FIXED) {
          return Item.Name === 'Station'
            ? 'fas fa-subway fa-2x'
            : 'fas fa-plane-arrival fa-2x';
        } else return 'fas fa-plane-arrival fa-2x';
      }
      case QUEUETIME:
        return 'fas fa-passport fa-2x';
      case DAYEND:
        return 'fas fa-moon fa-2x';
      case DAYSTART:
        return 'fas fa-sun fa-2x';
      case TRANSIT:
        return 'fas fa-plane fa-2x';
      case FLIGHTTIME:
        return 'fas fa-plane fa-2x';
      default:
        return null;
    }
  }
  getDescription = () => {
    const { data, currentPackage } = this.props;
    if (currentPackage === FIXED) {
      if ([DAYEND, DAYSTART].indexOf(data.MovementName) !== -1) {
        return data.Item.Name ? data.Item.Name : data.DestinationName;
      } else if ([DEPARTURE, ARRIVAL].indexOf(data.MovementName) !== -1) {
        return data.Item.Desc;
      } else return data.DestinationName;
    } else {
      if (
        [DAYEND, DAYSTART, DEPARTURE, ARRIVAL].indexOf(data.MovementName) !== -1
      ) {
        return data.Item.Name;
      } else return data.DestinationName;
    }
  };
  getTicketDesc = () => {
    const { t } = this.props;
    let { isStatic, data } = this.props;
    let type = isStatic ? data.Item.Name : data.Item.Desc;
    switch (type) {
      case 'Airport':
        return `${t('airFares')}`; // Air fares
      case 'Port':
        return `${t('shipTickets')}`; //'Ship tickets';
      default:
        return `${t('transportationTckets')}`; //'Transportation tickets';
    }
  };
  renderNote = () => {
    const { t } = this.props;
    const { MovementName, Note, Item } = this.props.data;
    const { currentPackage } = this.props;
    let note = '';
    if (currentPackage === FIXED) {
      if (
        [DEPARTURE, ARRIVAL].indexOf(MovementName) !== -1 &&
        Item.Name === 'Airport'
      ) {
        note = `${t('flightNumber')}`; // 'Flight Number';
      } else note = `${t('note')}`; // 'Note';
    } else {
      note =
        [DEPARTURE, ARRIVAL].indexOf(MovementName) !== -1
          ? `${t('flightNumber')}` // 'Flight Number'
          : `${t('note')}`; //'Note';
    }
    if (Note) {
      return (
        <span>
          <span style={styles.font700}>{note}</span>: {Note}
        </span>
      );
    }
  };
  render() {
    const { t } = this.props;
    const { date } = this.props;
    const {
      MovementName,
      MovementDescription,
      DurationText,
      Duration,
      DateTime,
    } = this.props.data;
    let duration = Duration ? timeDuration(Duration) : DurationText;
    let description = this.getDescription();
    let transportTicketDesc = this.getTicketDesc();
    return (
      <Segment basic style={styles.noMargin}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}>
              <Icon style={styles.goldColor} className={this.iconRender()} />
            </Grid.Column>
            <Grid.Column width={14}>
              <Grid>
                <Grid.Row style={styles.noPaddingBot}>
                  <Grid.Column width={16}>
                    <strong>{MovementDescription}</strong>, {t('at')}{' '}
                    {description}{' '}
                    {[DRIVING, QUEUETIME, FLIGHTTIME].indexOf(MovementName) !==
                      -1 && ` - ${t('duration')} ` + duration}
                    {/* Duration */}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingFive}>
                  <Grid.Column width={16}>{this.renderNote()}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {[CUSTOM, READY].indexOf(this.props.currentPackage) !== -1 &&
          [DEPARTURE, ARRIVAL].indexOf(MovementName) !== -1 && (
            <Message warning>
              {/* are not included */}
              {`${transportTicketDesc} ${t('notIncluded')}`}
            </Message>
          )}
        <MessageOverload
          dateDay={date}
          dateActivity={DateTime}
          movementName={MovementName}
        />
      </Segment>
    );
  }
}
ActivityNoImage.propTypes = {
  data: PropTypes.object,
  MovementName: PropTypes.string,
  MovementDescription: PropTypes.string,
  DestinationName: PropTypes.string,
  DurationText: PropTypes.string,
  Item: PropTypes.object,
  currentPackage: PropTypes.string,
  isStatic: PropTypes.bool,
  date: PropTypes.string,
  t: PropTypes.func,
};
export default withTranslation()(ActivityNoImage);
