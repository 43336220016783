import apiClient from './apiClient';
import {
  GET_READY_PACKAGE_ALL_URL,
  GET_READY_PACKAGE_BY_ID_URL,
  GET_READY_PACKAGE_WITH_FILTER_URL,
} from './apiUrl';

const getReadyPackageAllApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return data
    ? apiClient.get(
        `${GET_READY_PACKAGE_WITH_FILTER_URL}?cityId=${data.location ||
          ''}&desiredDays=&tourCategoryId=${data.tourType ||
          ''}&tourPaxTypeId=${data.tourPaxType ||
          ''}&accommodationType=${data.accommodationType ||
          ''}&accommodationRating=${data.accommodationRating ||
          ''}&accommodationLocation=${data.accommodationLocation ||
          ''}&startingDate=${data.startingDate ||
          ''}&endingDate=${data.endingDate || ''}`
      )
    : apiClient.get(GET_READY_PACKAGE_ALL_URL);
};

const getReadyPackageByIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_READY_PACKAGE_BY_ID_URL}${id}`);
};

export { getReadyPackageAllApi, getReadyPackageByIdApi };
