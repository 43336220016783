import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BreadCrumb } from '../shared/components/common';
import { Container, Grid } from 'semantic-ui-react';
import Banner from './components/banner/Banner';
// import ContainerList from './components/containerList/ContainerList';
import PropTypes from 'prop-types';
import getBreadCrumb from './service/helper/setBreadCrumb';
import FilterTourPackage from './components/filter/FilterTourPackage';
import Filter from './components/filter/Filter';
import CardList from './components/containerList/CardList';
import {
  getTourTypes,
  getTourPaxTypes,
  resetStatusTransaction,
} from '../shared/actions/tourTransactionAction';
import { getCountries } from '../shared/actions/countriesAction';
import {
  getCityInCountry,
  resetStatusGetCityInCountry,
} from '../shared/actions/locationAction';
import {
  getFixedPackageAll,
  resetGetFixedPackageAllStatus,
} from '../shared/actions/fixedPackageAction';
import {
  getReadyPackageAll,
  resetGetReadyPackageAllStatus,
} from '../shared/actions/readyPackageAction';
import { resetTempFilterQuickLaunch } from '../shared/actions/tempAction';
import {
  getAccommodationTypes,
  getAccommodationRatings,
  getAccommodationLocations,
} from '../shared/actions/accommodationAction';
import listTotalDays from './constant/listDuration';
import { useTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

const PackageList = props => {
  const { t } = useTranslation();
  // const listTotalDays = t('listDuration', { returnObjects: true });
  // console.log(listTotalDays);
  //========initial state======
  const [currentPackageType, setCurrentPackageType] = useState('');
  // eslint-disable-next-line
  const [tourPaxType, setTourPaxType] = useState('');
  const [title, setTitle] = useState('');
  const [icon, setIcon] = useState('');
  const [breadCrumb, setBreadCrumbState] = useState([]);
  //=====
  const [openTourFilter, setOpenTourFilter] = useState(false);
  const [cityInCountry, setCityInCountry] = useState('');
  const [filterData, setFilterData] = useState({
    listTourType: [],
    listDuration: [],
  });
  const [fixPackageList, setFixPackageList] = useState([]);
  const [readyPackageList, setReadyPackageList] = useState([]);
  const [rangePrice, setRangePrice] = useState([0, 0]);
  const [maxPrice, setMaxPrice] = useState(0);
  const [tab, setTab] = useState('Series');
  const [searchPackage, setSearchPackage] = useState({
    location: '',
    tourType: '',
    tourCategory: '',
    startDate: '',
    endDate: '',
    accommodationType: '',
    accommodationRating: '',
    accommodationFacility: '',
    desireDay: '',
  });
  const [similarFixPackageList, setSimilarFixPackageList] = useState([]);
  const [similarReadyPackageList, setSimilarReadyPackageList] = useState([]);
  //==============store================
  const fixPkgResult = useSelector(
    state => state.fixedPackage.fixedPackageAll.results
  );
  const fixPkgSimilar = useSelector(
    state => state.fixedPackage.fixedPackageAll.similar_results
  );
  const readyPkgResult = useSelector(
    state => state.readyPackage.readyPackageAll.results
  );
  const readyPkgSimilar = useSelector(
    state => state.readyPackage.readyPackageAll.similar_results
  );

  const getFixedPackageAllStatus = useSelector(
    state => state.fixedPackage.getFixedPackageAllStatus
  );
  const getReadyPackageAllStatus = useSelector(
    state => state.readyPackage.getReadyPackageAllStatus
  );
  const dataFilter = useSelector(state => state.temp.dataFilterQuickLaunch);
  const tourTypelist = useSelector(state => state.tourTransaction.tourTypes);
  const tourTypeStatus = useSelector(
    state => state.tourTransaction.getTourTypeStatus
  );
  const cityInCountryStatus = useSelector(
    state => state.location.cityInCountryStatus
  );
  const loadingTourTransaction = useSelector(
    state => state.tourTransaction.loading
  );
  const countryList = useSelector(state => state.countries.countries);
  const listCityInCountry = useSelector(state =>
    state.location.cityInCountry.map((type, index) => {
      return {
        key: type.Name + type.Region.Name + index,
        text: type.Name,
        content: (
          <p>
            <strong>{type.Name}</strong>
            <br />
            {type.Region.Name}, {type.Country.Name}
          </p>
        ),
        value: type.Name,
        region: type.Region.Name,
        country: type.Country.Name,
      };
    })
  );
  //=============action===============
  const dispatch = useDispatch();
  const getTourTypesAction = () => dispatch(getTourTypes());
  const getTourPaxTypesAction = () => dispatch(getTourPaxTypes());
  const resetStatusTransactionAction = () => dispatch(resetStatusTransaction());
  const getCountriesAction = () => dispatch(getCountries());
  const getCityInCountryAction = () => dispatch(getCityInCountry());
  const resetStatusGetCityInCountryAction = () =>
    dispatch(resetStatusGetCityInCountry());
  const getFixedPackageAllAction = data => dispatch(getFixedPackageAll(data));
  const resetGetFixedPackageAllStatusAction = () =>
    dispatch(resetGetFixedPackageAllStatus());
  const getReadyPackageAllAction = data => dispatch(getReadyPackageAll(data));
  const resetGetReadyPackageAllStatusAction = () =>
    dispatch(resetGetReadyPackageAllStatus());
  const resetTempFilterQuickLaunchAction = () =>
    dispatch(resetTempFilterQuickLaunch());
  const getAccommodationTypesAction = () => dispatch(getAccommodationTypes());
  const getAccommodationRatingsAction = () =>
    dispatch(getAccommodationRatings());
  const getAccommodationLocationsAction = () =>
    dispatch(getAccommodationLocations());
  //=============lifecycle============
  useEffect(() => {
    initial();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { packageType } = props.match.params;
    if (currentPackageType !== packageType) {
      getPackages(packageType);
    }
    // eslint-disable-next-line
  });
  useEffect(() => {
    if (tourTypeStatus === 'success') {
      checkedForm(tourTypelist, '', 'listTourType');
      resetStatusTransactionAction();
    }
    // eslint-disable-next-line
  }, [tourTypeStatus]);

  useEffect(() => {
    if (cityInCountryStatus === 'success') {
      let cityInCountry = filterLocation(
        listCityInCountry,
        searchPackage.location
      );
      setCityInCountry(cityInCountry);
      resetStatusGetCityInCountryAction();
    }
    // eslint-disable-next-line
  }, [cityInCountryStatus]);

  useEffect(() => {
    if (
      getFixedPackageAllStatus === 'success' &&
      getReadyPackageAllStatus === 'success'
    ) {
      initialSetDataPackage();
    }
    // eslint-disable-next-line
  }, [getFixedPackageAllStatus, getReadyPackageAllStatus]);
  //==========helper=========
  const initial = () => {
    const { packageType, tourPaxType } = props.match.params;
    //==============
    getPackages(packageType);
    setTourPaxType(tourPaxType);
    //==============
    getFixedPackageAllAction(dataFilter);
    getReadyPackageAllAction(dataFilter);
    getTourTypesAction();
    getTourPaxTypesAction();
    getAccommodationTypesAction();
    getAccommodationRatingsAction();
    getAccommodationLocationsAction();
    if (packageType) getCountriesAction();
    getCityInCountryAction();
    checkedForm(listTotalDays, 0, 'listDuration');
    initialSearchPackage();
  };
  const getPackages = pkgType => {
    let brCrumb = getBreadCrumb(pkgType);
    setCurrentPackageType(brCrumb.packageType);
    setTitle(brCrumb.title);
    setIcon(brCrumb.icon);
    setBreadCrumbState(brCrumb.breadCrumb);
  };
  const initialSearchPackage = () => {
    const {
      tourType,
      tourPaxType,
      accommodationType,
      accommodationRating,
      accommodationLocation,
      startingDate,
      desireDay,
      endDate,
      location,
    } = dataFilter;
    let newSearchPackage = {
      ...searchPackage,
      location,
      tourType,
      tourCategory: tourPaxType,
      startDate: startingDate,
      endDate: endDate,
      accommodationType,
      accommodationRating,
      accommodationLocation,
      desireDay,
    };
    setSearchPackage(newSearchPackage);
  };

  const initialSetDataPackage = () => {
    let maxPrice = fixPkgResult
      ? searchMax(fixPkgResult, 'SharingRoomPrice')
      : 0;
    let maxRange =
      maxPrice > rangePrice[1] && rangePrice[1] !== 0
        ? rangePrice[1]
        : maxPrice;
    setFixPackageList(fixPkgResult);
    setReadyPackageList(readyPkgResult);
    setSimilarFixPackageList(fixPkgSimilar);
    setSimilarReadyPackageList(readyPkgSimilar);
    setCityInCountry(filterLocation(listCityInCountry, searchPackage.location));
    setRangePrice([0, maxRange]);
    setMaxPrice(maxPrice);
    //=============================
    resetGetFixedPackageAllStatusAction();
    resetGetReadyPackageAllStatusAction();
    resetTempFilterQuickLaunchAction();
  };

  const searchMax = (data, name) => {
    return Math.max.apply(
      Math,
      data.map(e => {
        return e[name];
      })
    );
  };

  const checkedForm = (listData, dataChecked, name) => {
    listData = listData.map(type => {
      let desc = '';
      let text = type.Name;
      let checked = type.Id === dataChecked;
      if (name === 'listDuration') {
        desc = type.Days;
        type.Days.length === 1
          ? (checked = dataChecked >= 13)
          : type.Days.indexOf(parseInt(dataChecked, 10)) !== -1
          ? (checked = true)
          : (checked = false);
      }
      return {
        key: type.Id,
        text,
        checked,
        desc,
      };
    });
    let newFilterData = filterData;
    newFilterData[name] = listData;
    setFilterData(newFilterData);
  };
  const filterLocation = (listCityInCountry, location) => {
    listCityInCountry = location
      ? listCityInCountry.filter(data => {
          return data.value.toLowerCase() === location.toLowerCase();
        })
      : [];
    let cityInCountry =
      listCityInCountry.length !== 0
        ? `${listCityInCountry[0].country}, ${
            listCityInCountry[0].region
          }, ${location}`
        : '';
    return cityInCountry;
  };
  const handleResetFilter = () => {
    let { listTourType, listDuration } = filterData;
    if (listTourType.some(e => e.checked))
      listTourType = resetFilter(listTourType);
    if (listDuration.some(e => e.checked))
      listDuration = resetFilter(listDuration);
    let maxPrice = fixPkgResult ? searchMax(fixPkgResult, 'LowestPrice') : 0;
    setRangePrice([0, maxPrice]);
    setFilterData({
      listTourType,
      listDuration,
    });
  };

  const resetFilter = listData => {
    return listData.map(data => {
      return {
        key: data.key,
        text: data.text,
        checked: false,
        desc: data.desc,
      };
    });
  };
  const handleChangeCheckbox = (e, { name, value }) => {
    let dataList = filterData[name];
    let index = dataList.findIndex(x => x.key === value);
    dataList[index].checked = !dataList[index].checked;
    setFilterData({
      ...filterData,
      [name]: dataList,
    });
  };

  const handleChangeInput = (e, { name, value }) => {
    let newSearchPackage = { ...searchPackage, [name]: value };
    setSearchPackage(newSearchPackage);
  };
  const handleChangeOpenSearch = (e, { value }) => {
    setOpenTourFilter(value);
  };

  const handleSearch = () => {
    const {
      location,
      tourType,
      tourCategory,
      startDate,
      endDate,
      accommodationType,
      accommodationRating,
      accommodationLocation,
    } = searchPackage;
    let data = {
      tourType,
      tourPaxType: tourCategory,
      accommodationType,
      accommodationRating,
      accommodationLocation,
      startingDate: startDate,
      endingDate: endDate,
      location,
    };
    getFixedPackageAllAction(data);
    getReadyPackageAllAction(data);
  };
  const onLowerBoundChange = e => {
    let value = e.target.value
      ? parseInt(e.target.value.replace(/\D/g, ''), 10)
      : 0;
    setRangePrice([value, rangePrice[1]]);
  };
  const onUpperBoundChange = e => {
    let value = e.target.value
      ? parseInt(e.target.value.replace(/\D/g, ''), 10)
      : 0;
    value = maxPrice < value ? maxPrice : value;
    setRangePrice([rangePrice[0], value]);
  };
  const onSliderChange = value => setRangePrice(value);

  const convertLocation = countries => {
    let optionsLocation = countries.map(type => {
      return {
        key: type.Id,
        text: type.Name,
        value: type.Name,
      };
    });
    optionsLocation.unshift({
      key: '',
      text: `${t('selectAllCountries')}`, //Select all countries
      value: '',
    });
    return optionsLocation;
  };
  const handleChangeTab = value => {
    setTab(value);
  };

  let countries = convertLocation(countryList);
  return (
    <div>
      <BreadCrumb sections={breadCrumb} />
      <Banner title={title} icon={icon} />
      <DynamicTitle title={title} />
      {/* <ContainerList packageType={currentPackageType} /> */}
      <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
        <Grid>
          <Grid.Column width={16}>
            <FilterTourPackage
              openTourFilter={openTourFilter}
              handleChange={handleChangeInput}
              descLocation={cityInCountry}
              optionsLocation={listCityInCountry}
              handleSearch={handleSearch}
              searchPackage={searchPackage}
              handleChangeOpenSearch={handleChangeOpenSearch}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Filter
              dataFilter={filterData}
              handleChange={handleChangeCheckbox}
              resetFilter={handleResetFilter}
              maxPrice={maxPrice}
              rangePrice={rangePrice}
              onLowerBoundChange={onLowerBoundChange}
              onUpperBoundChange={onUpperBoundChange}
              onSliderChange={onSliderChange}
              packageType={currentPackageType}
              loadingTourTransaction={loadingTourTransaction}
              tab={tab}
            />
          </Grid.Column>
          <Grid.Column width={11} floated="right">
            <CardList
              packageType={currentPackageType}
              listFormFilter={filterData}
              fixPackageList={fixPackageList}
              readyPackageList={readyPackageList}
              countries={countries}
              rangePrice={rangePrice}
              handleChangeTab={handleChangeTab}
              tab={tab}
              similarFixPackageList={similarFixPackageList}
              similarReadyPackageList={similarReadyPackageList}
            />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

PackageList.propTypes = {
  packageType: PropTypes.string,
  match: PropTypes.object,
  t: PropTypes.func,
};
export default PackageList;
