import {
  POST_REGISTER_SIMPLE_CUSTOMER,
  RESET_REGISTER_SIMPLE_CUSTOMER,
} from './actionTypes';
import { postRegisterSimpleCustomerApi } from '../../../api/registerApi';

const postRegisterSimpleCustomer = data => {
  return dispatch => {
    return dispatch({
      type: POST_REGISTER_SIMPLE_CUSTOMER,
      payload: postRegisterSimpleCustomerApi(data),
    });
  };
};

const resetRegisterSimpleCustomerStatus = () => {
  return dispatch => {
    return dispatch({
      type: RESET_REGISTER_SIMPLE_CUSTOMER,
    });
  };
};
export { postRegisterSimpleCustomer, resetRegisterSimpleCustomerStatus };
