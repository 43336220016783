import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
const headerWithIcon = props => (
  <Header as={props.as}>
    <Icon
      style={styles.goldColor}
      name={props.icon}
      className={props.iconClassName}
    />
    <Header.Content>{props.title}</Header.Content>
  </Header>
);

headerWithIcon.propTypes = {
  title: PropTypes.string,
  as: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
};
export default headerWithIcon;
