import React from 'react';
import { Icon, Label, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
const MarkerWithLabel = props => {
  const { text, icon, className, style } = props;
  return (
    <Grid className="max-content">
      <Grid.Row style={styles.noPadding}>
        <Grid.Column width={16} style={styles.textCenter}>
          <Label
            pointing="below"
            className={className}
            style={style}
            icon={<Icon className={icon} />}
            content={<span style={styles.marginBot4}>{text}</span>}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
MarkerWithLabel.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};
export default MarkerWithLabel;
