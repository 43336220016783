import styles from '../../styles';
const convertStatus = (status, isAcceptedQuotation) => {
  let textStatus = [
    { key: 'Booking_created', value: 'Created', style: styles.ribbonCreated },
    {
      key: 'Booking_hold',
      value: 'Created (On Hold)',
      style: styles.ribbonOnHold,
    },
    {
      key: 'Booking_DP_Confirmed',
      value: 'DP confirm',
      style: styles.ribbonPayment,
    },
    {
      key: 'Booking_SP_Confirmed',
      value: 'SP confirm',
      style: styles.ribbonPayment,
    },
    {
      key: 'Booking_In_Payment',
      value: 'In Payment',
      style: styles.ribbonPayment,
    },
    { key: 'Booking_Confirmed', value: 'Confirmed', style: {} },
    {
      key: 'Booking_Completed',
      value: 'Completed',
      style: styles.ribbonComplete,
    },
    {
      key: 'Booking_Cancelled',
      value: 'Cancelled',
      style: styles.ribbonCancelled,
    },
    {
      key: 'Booking_draft',
      value: 'Quotation',
      style: styles.ribbonQuotation,
    },
  ];
  let newStatus = textStatus.filter(e => e.key === status)[0];
  isAcceptedQuotation && (newStatus.value = 'Quotation Accepted');
  return newStatus;
};

export default convertStatus;
