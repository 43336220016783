import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';
import { convertDateFormat } from '../../../../genericFunction/moment';
import { useTranslation, Translation } from 'react-i18next';

const HeaderItem = props => (
  <Grid.Row style={styles.paddingTop15px}>
    <Grid.Column width={16} style={styles.padding5px35pxMarginTop15px}>
      <Header as="h4">
        {/* DAY  */}
        <Translation>{t => <>{t('day')}</>}</Translation> {props.day} -{' '}
        {convertDateFormat(props.date, 'DD MMM YYYY')}
      </Header>
    </Grid.Column>
  </Grid.Row>
);
const Item = props => {
  let style = props.desc.indexOf('overtime') !== -1 ? styles.redColor : {};
  return (
    <Grid.Row style={props.style}>
      <Grid.Column width={7} style={styles.noPaddingNoMargin}>
        {props.title}
      </Grid.Column>
      <Grid.Column width={4} style={styles.noPaddingNoMargin}>
        <span style={style}>{props.qty}</span>
      </Grid.Column>
      <Grid.Column width={4} style={styles.noPaddingNoMargin}>
        <span style={style}>{props.desc}</span>
      </Grid.Column>
    </Grid.Row>
  );
};
const setIndex = (item, index, overtimeTransport) => {
  return item.ItemType === 'Accommodation'
    ? index + 1
    : item.ItemType === 'Attraction'
    ? index + 1
    : item.ItemType === 'Restaurant'
    ? index + 1
    : item.ItemType === 'Transportation' &&
      item.Description.indexOf('overtime') === -1 &&
      overtimeTransport === 0
    ? index + 1
    : item.ItemType === 'Transportation' &&
      item.Description.indexOf('overtime') !== -1 &&
      overtimeTransport !== 0 &&
      index + 2;
};
const OrderedItem = props => {
  const { t } = useTranslation();
  let { orderedItem } = props;
  return (
    <Grid.Row>
      <Grid.Column width={16} style={styles.marginTop10Bottom5px}>
        <Header as="h2">
          {/* Order Detail */} {t('orderDetail')}
        </Header>
      </Grid.Column>
      {orderedItem &&
        orderedItem.map(day => {
          let overtimeTransport = day.items
            ? day.items.filter(e => e.Description.indexOf('overtime') !== -1)
                .length
            : 0;
          let index = 0;
          return (
            <Grid.Column key={day.day} width={16}>
              <Grid>
                <HeaderItem day={day.day} date={day.date} />
                {day.items.map(item => {
                  index = setIndex(item, index, overtimeTransport);
                  let style =
                    index % 2 === 0
                      ? styles.orderedItemEven
                      : styles.orderedItemOdd;
                  return item.ItemType === 'Accommodation' ? (
                    <Item
                      key={index}
                      style={style}
                      title={item.ItemName.split('-')[0]}
                      // qty={`${item.Qty} ${item.Qty > 1 ? 'Rooms' : 'Room'}`}
                      qty={`${item.Qty} ${t('room', { count: item.Qty })}`}
                      desc={
                        item.ItemName.split('-')[1] + ', ' + item.Description
                      }
                    />
                  ) : item.ItemType === 'Attraction' ? (
                    <Item
                      key={index}
                      style={style}
                      title={item.ItemName}
                      // qty={`For ${item.Qty} ${
                      //   item.Qty > 1 ? 'Tickets' : 'Ticket'
                      // }`}
                      qty={`${t('for')} ${item.Qty} ${t('ticket', {
                        count: item.Qty,
                      })}`}
                      desc={item.Description.split('_')[1]}
                    />
                  ) : item.ItemType === 'Restaurant' ? (
                    <Item
                      key={index}
                      style={style}
                      title={item.ItemName.replace('_', ' ')}
                      // qty={`For ${item.Qty} ${
                      //   item.Qty > 1 ? 'Guests' : 'Guest'
                      // }`}
                      qty={`${t('for')} ${item.Qty} ${t('guest', {
                        count: item.Qty,
                      })}`}
                      desc={item.Description.split('_')[1]}
                    />
                  ) : item.ItemType === 'Transportation' &&
                    item.Description.toLowerCase().indexOf('overtime') ===
                      -1 ? (
                    <Grid
                      key={index}
                      style={styles.noMarginPadding10px0pxWidth100percent}
                    >
                      <Item
                        style={style}
                        title={item.ItemName.split('-')[0]}
                        // qty={`For ${item.Qty} ${
                        //   item.Qty > 1 ? 'Unit' : 'Units'
                        // }`}
                        qty={`${t('for')} ${item.Qty} ${t('unit', {
                          count: item.Qty,
                        })}`}
                        desc={
                          item.Description + ', ' + item.ItemName.split('-')[1]
                        }
                      />
                      {overtimeTransport !== 0 && (
                        <Item
                          style={style}
                          title={item.ItemName.split('-')[0]}
                          // qty={overtimeTransport + ' x 1 Hour'}
                          qty={overtimeTransport + ` x 1 ${t('hour')}`}
                          desc={'Transportation overtime'}
                        />
                      )}
                    </Grid>
                  ) : (
                    item.ItemType === 'TourGuide' && (
                      <Item
                        style={style}
                        title={item.Description}
                        qty={`${item.Qty} ${t('personFor')} ${item.CityName}`}
                        desc={`${t('serviceLanguageIn')} ${item.Language.Text}`}
                      />
                    )
                  );
                })}
              </Grid>
            </Grid.Column>
          );
        })}
    </Grid.Row>
  );
};
OrderedItem.propTypes = {
  orderedItem: PropTypes.array,
  t: PropTypes.func,
};
Item.propTypes = {
  style: PropTypes.object,
  qty: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};
HeaderItem.propTypes = {
  day: PropTypes.number,
  date: PropTypes.string,
};
export default OrderedItem;
