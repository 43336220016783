import * as types from '../actions/actionTypes';

const initialState = {
  transactionHistoryAll: [],
  getTransactionHistoryAllStatus: '',
  tourTransactionConfirmDesc: {},
  postTourTransactionConfirmStatus: '',
  tourTransactionSummary: {},
  getTourTransactionSummaryStatus: '',
  tourTransactionSummaryList: [],
  invoice: {},
  getInvoiceStatus: '',
  schedule: {},
  getScheduleStatus: '',
  errors: {},
  voucherConfirmation: {},
  getVoucherConfirmationStatus: '',
  loadingConfirm: false,
  loadingDownload: false,
  loadingHistory: false,
  loadingSeeDetail: false,
  tourTransactionBills: [],
  getTourTransactionBillsStatus: '',
  //===list tour transaction===
  listCancelledTransaction: [],
  listCreatedTransaction: [],
  listInPaymentTransaction: [],
  listConfirmedTransaction: [],
  listCompletedTransaction: [],
  listQuotationTransaction: [],
  //
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_TRANSACTION_HISTORY_ALL
    //---------------------------------
    case types.GET_TRANSACTION_HISTORY_ALL:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_ALL_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_HISTORY_ALL_FULFILLED:
      return {
        ...state,
        transactionHistoryAll: action.payload.data,
        errors: {},
        loadingHistory: false,
        getTransactionHistoryAllStatus: 'success',
      };
    case types.GET_TRANSACTION_HISTORY_ALL_REJECTED:
      return {
        ...state,
        transactionHistoryAll: [],
        errors: action.payload.response.data,
        loadingHistory: false,
        getTransactionHistoryAllStatus: 'failed',
      };
    //---------------------------------
    // POST_TOUR_TRANSACTION_CONFIRMATION
    //---------------------------------
    case types.POST_TOUR_TRANSACTION_CONFIRMATION:
      return { ...state };
    case types.POST_TOUR_TRANSACTION_CONFIRMATION_PENDING:
      return { ...state, loadingConfirm: true };
    case types.POST_TOUR_TRANSACTION_CONFIRMATION_FULFILLED:
      return {
        ...state,
        tourTransactionConfirmDesc: action.payload.data,
        errors: {},
        loadingConfirm: false,
        postTourTransactionConfirmStatus: 'success',
      };
    case types.POST_TOUR_TRANSACTION_CONFIRMATION_REJECTED:
      return {
        ...state,
        tourTransactionConfirmDesc: [],
        errors: action.payload.response.data,
        loadingConfirm: false,
        postTourTransactionConfirmStatus: 'failed',
      };
    //---------------------------------
    // GET_TOUR_TRANSACTION_SUMMARY
    //---------------------------------
    case types.GET_TOUR_TRANSACTION_SUMMARY:
      return { ...state };
    case types.GET_TOUR_TRANSACTION_SUMMARY_PENDING:
      return { ...state, loadingSeeDetail: true };
    case types.GET_TOUR_TRANSACTION_SUMMARY_FULFILLED:
      return {
        ...state,
        tourTransactionSummary: action.payload.data,
        errors: {},
        loadingSeeDetail: false,
        getTourTransactionSummaryStatus: 'success',
      };
    case types.GET_TOUR_TRANSACTION_SUMMARY_REJECTED:
      return {
        ...state,
        tourTransactionSummary: {},
        errors: action.payload.response.data,
        loadingSeeDetail: false,
        getTourTransactionSummaryStatus: 'failed',
      };
    //---------------------------------
    // GET_TOUR_TRANSACTION_BILLS
    //---------------------------------
    case types.GET_TOUR_TRANSACTION_BILLS:
      return { ...state };
    case types.GET_TOUR_TRANSACTION_BILLS_PENDING:
      return { ...state, loadingSeeDetail: true };
    case types.GET_TOUR_TRANSACTION_BILLS_FULFILLED:
      return {
        ...state,
        tourTransactionBills: action.payload.data,
        errors: {},
        loadingSeeDetail: false,
        getTourTransactionBillsStatus: 'success',
      };
    case types.GET_TOUR_TRANSACTION_BILLS_REJECTED:
      return {
        ...state,
        tourTransactionBills: [],
        errors: action.payload.response.data,
        loadingSeeDetail: false,
        getTourTransactionBillsStatus: 'failed',
      };
    //---------------------------------
    // GET_INVOICE
    //---------------------------------
    case types.GET_INVOICE:
      return { ...state };
    case types.GET_INVOICE_PENDING:
      return { ...state, loadingDownload: true };
    case types.GET_INVOICE_FULFILLED:
      return {
        ...state,
        invoice: action.payload.data,
        errors: {},
        loadingDownload: false,
        getInvoiceStatus: 'success',
      };
    case types.GET_INVOICE_REJECTED:
      return {
        ...state,
        invoice: {},
        errors: action.payload.response.data,
        loadingDownload: false,
        getInvoiceStatus: 'failed',
      };
    //---------------------------------
    // GET_SCHEDULE_TOUR_TRANSACTION
    //---------------------------------
    case types.GET_SCHEDULE_TOUR_TRANSACTION:
      return { ...state };
    case types.GET_SCHEDULE_TOUR_TRANSACTION_PENDING:
      return { ...state, loadingDownload: true };
    case types.GET_SCHEDULE_TOUR_TRANSACTION_FULFILLED:
      return {
        ...state,
        schedule: action.payload.data,
        errors: {},
        loadingDownload: false,
        getScheduleStatus: 'success',
      };
    case types.GET_SCHEDULE_TOUR_TRANSACTION_REJECTED:
      return {
        ...state,
        schedule: {},
        errors: action.payload.response.data,
        loadingDownload: false,
        getScheduleStatus: 'failed',
      };
    //---------------------------------
    // GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID
    //---------------------------------
    case types.GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID:
      return { ...state };
    case types.GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_PENDING:
      return { ...state, loadingSeeDetail: true };
    case types.GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_FULFILLED:
      return {
        ...state,
        suplementItemByTransactionId: action.payload.data,
        errors: {},
        loadingSeeDetail: false,
        getSuplementItemByTransactionIdStatus: 'success',
      };
    case types.GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_REJECTED:
      return {
        ...state,
        suplementItemByTransactionId: [],
        errors: action.payload.response.data,
        loadingSeeDetail: false,
        getSuplementItemByTransactionIdStatus: 'failed',
      };
    //---------------------------------
    // GET_TOUR_TRANSACTION_TO_BE_PAYED
    //---------------------------------
    case types.GET_TOUR_TRANSACTION_TO_BE_PAYED:
      return { ...state };
    case types.GET_TOUR_TRANSACTION_TO_BE_PAYED_PENDING:
      return { ...state, loadingSeeDetail: true };
    case types.GET_TOUR_TRANSACTION_TO_BE_PAYED_FULFILLED:
      return {
        ...state,
        tourTransactionToBePayed: action.payload.data,
        errors: {},
        loadingSeeDetail: false,
        getTourTransactionToBePayedStatus: 'success',
      };
    case types.GET_TOUR_TRANSACTION_TO_BE_PAYED_REJECTED:
      return {
        ...state,
        tourTransactionToBePayed: {},
        errors: action.payload.response.data,
        loadingSeeDetail: false,
        getTourTransactionToBePayedStatus: 'failed',
      };
    //---------------------------------
    // SELECT_TOUR_TRANSACTION_SUMMARY
    //---------------------------------
    case types.SELECT_TOUR_TRANSACTION_SUMMARY:
      return {
        ...state,
        tourTransactionSummaryList: state.tourTransactionSummaryList.concat(
          action.payload
        ),
      };
    case types.RESET_SELECT_TOUR_TRANSACTION_SUMMARY:
      return {
        ...state,
        tourTransactionSummaryList: [],
      };
    case types.RESET_STATUS_TOUR_TRANSACTION_SUMMARY:
      return {
        ...state,
        getTourTransactionSummaryStatus: '',
        getTourTransactionBillsStatus: '',
      };
    case types.RESET_STATUS_TOUR_TRANSACTION_CONFIRMATION:
      return {
        ...state,
        postTourTransactionConfirmStatus: '',
      };
    case types.RESET_STATUS_TRANSACTION_HISTORY_ALL:
      return {
        ...state,
        getTransactionHistoryAllStatus: '',
      };
    case types.RESET_STATUS_INVOICE_AND_SCHEDULE:
      return {
        ...state,
        getInvoiceStatus: '',
        getScheduleStatus: '',
        getVoucherConfirmationStatus: '',
      };
    //---------------------------------
    // Download Invoice for travel agent and customer
    //---------------------------------
    case types.GET_INVOICE_TRAVEL_AGENT:
      return { ...state };
    case types.GET_INVOICE_TRAVEL_AGENT_PENDING:
      return { ...state, loadingDownload: true };
    case types.GET_INVOICE_TRAVEL_AGENT_FULFILLED:
      return {
        ...state,
        invoice: action.payload.data,
        errors: {},
        loadingDownload: false,
        getInvoiceStatus: 'success',
      };
    case types.GET_INVOICE_TRAVEL_AGENT_REJECTED:
      return {
        ...state,
        invoice: {},
        errors: action.payload.response.data,
        loadingDownload: false,
        getInvoiceStatus: 'failed',
      };
    case types.GET_INVOICE_CUSTOMER:
      return { ...state };
    case types.GET_INVOICE_CUSTOMER_PENDING:
      return { ...state, loadingDownload: true };
    case types.GET_INVOICE_CUSTOMER_FULFILLED:
      return {
        ...state,
        invoice: action.payload.data,
        errors: {},
        loadingDownload: false,
        getInvoiceStatus: 'success',
      };
    case types.GET_INVOICE_CUSTOMER_REJECTED:
      return {
        ...state,
        invoice: {},
        errors: action.payload.response.data,
        loadingDownload: false,
        getInvoiceStatus: 'failed',
      };
    //---------------------------------
    // GET_VOUCHER_CONFIRMATION
    //---------------------------------
    case types.GET_VOUCHER_CONFIRMATION:
      return { ...state };
    case types.GET_VOUCHER_CONFIRMATION_PENDING:
      return { ...state, loadingDownload: true };
    case types.GET_VOUCHER_CONFIRMATION_FULFILLED:
      return {
        ...state,
        voucherConfirmation: action.payload.data,
        errors: {},
        loadingDownload: false,
        getVoucherConfirmationStatus: 'success',
      };
    case types.GET_VOUCHER_CONFIRMATION_REJECTED:
      return {
        ...state,
        voucherConfirmation: {},
        errors: action.payload.response.data,
        loadingDownload: false,
        getVoucherConfirmationStatus: 'failed',
      };

    //---------------------------------
    // GET_TRANSACTION_HISTORY CREATED
    //---------------------------------
    case types.GET_TRANSACTION_HISTORY_CREATED:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_CREATED_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_HISTORY_CREATED_FULFILLED:
      return {
        ...state,
        listCreatedTransaction: action.payload.data,
        errors: {},
        loadingHistory: false,
      };
    case types.GET_TRANSACTION_HISTORY_CREATED_REJECTED:
      return {
        ...state,
        listCreatedTransaction: [],
        errors: action.payload.response.data,
        loadingHistory: false,
      };
    //---------------------------------
    // GET_TRANSACTION_HISTORY INPAYMENT
    //---------------------------------
    case types.GET_TRANSACTION_HISTORY_INPAYMENT:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_INPAYMENT_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_HISTORY_INPAYMENT_FULFILLED:
      return {
        ...state,
        listInPaymentTransaction: action.payload.data,
        errors: {},
        loadingHistory: false,
      };
    case types.GET_TRANSACTION_HISTORY_INPAYMENT_REJECTED:
      return {
        ...state,
        listInPaymentTransaction: [],
        errors: action.payload.response.data,
        loadingHistory: false,
      };
    //---------------------------------
    // GET_TRANSACTION_HISTORY CONFIRMED
    //---------------------------------
    case types.GET_TRANSACTION_HISTORY_CONFIRMED:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_CONFIRMED_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_HISTORY_CONFIRMED_FULFILLED:
      return {
        ...state,
        listConfirmedTransaction: action.payload.data,
        errors: {},
        loadingHistory: false,
      };
    case types.GET_TRANSACTION_HISTORY_CONFIRMED_REJECTED:
      return {
        ...state,
        listConfirmedTransaction: [],
        errors: action.payload.response.data,
        loadingHistory: false,
      };
    //---------------------------------
    // GET_TRANSACTION_HISTORY COMPLETED
    //---------------------------------
    case types.GET_TRANSACTION_HISTORY_COMPLETED:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_COMPLETED_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_HISTORY_COMPLETED_FULFILLED:
      return {
        ...state,
        listCompletedTransaction: action.payload.data,
        errors: {},
        loadingHistory: false,
      };
    case types.GET_TRANSACTION_HISTORY_COMPLETED_REJECTED:
      return {
        ...state,
        listCompletedTransaction: [],
        errors: action.payload.response.data,
        loadingHistory: false,
      };
    //---------------------------------
    // GET_TRANSACTION_HISTORY CANCELLED
    //---------------------------------
    case types.GET_TRANSACTION_HISTORY_CANCELLED:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_CANCELLED_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_HISTORY_CANCELLED_FULFILLED:
      return {
        ...state,
        listCancelledTransaction: action.payload.data,
        errors: {},
        loadingHistory: false,
      };
    case types.GET_TRANSACTION_HISTORY_CANCELLED_REJECTED:
      return {
        ...state,
        listCancelledTransaction: [],
        errors: action.payload.response.data,
        loadingHistory: false,
      };
    //---------------------------------
    // GET_TRANSACTION_HISTORY DRAFT
    //---------------------------------
    case types.GET_TRANSACTION_HISTORY_DRAFT:
      return { ...state };
    case types.GET_TRANSACTION_HISTORY_DRAFT_PENDING:
      return { ...state, loadingHistory: true };
    case types.GET_TRANSACTION_HISTORY_DRAFT_FULFILLED:
      return {
        ...state,
        listQuotationTransaction: action.payload.data,
        errors: {},
        loadingHistory: false,
      };
    case types.GET_TRANSACTION_HISTORY_DRAFT_REJECTED:
      return {
        ...state,
        listQuotationTransaction: [],
        errors: action.payload.response.data,
        loadingHistory: false,
      };

    default:
      return state;
  }
};
export default reducer;
