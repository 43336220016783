const indonesianSitePromotionData = [
  {
    name: 'bali',
    type: 'bali',
    location: 'Bali, Indonesia',
    description:
      'Visit the artisan villages around Ubud on a full-day tour of Balinese culture. ',
  },
  {
    name: 'puncak',
    type: 'puncak',
    location: 'Puncak, Indonesia',
    description:
      'Venture out of the city with trips to JungleLand Adventure Theme Park (in Sentul), ',
  },
  {
    name: 'bandung',
    type: 'bandung',
    location: 'Bandung, Indonesia',
    description:
      'The capital of West Java, Bandung offers a blend of leisure activities, entertainment, and sightseeing.',
  },
];

export default indonesianSitePromotionData;
