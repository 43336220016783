const style = {
  noPadding: { padding: 0 },
  progressiveImage: {
    width: '100%',
    height: '280px',
    background: 'url',
    backgroundSize: 'cover',
    borderBottom: '0px',
    borderRadius: '5px 5px 0px 0px',
    padding: '0px 0px 0px 0px ',
  },
  textRight: { textAlign: 'right' },
  buttonSeeDetailIndonesia: {
    backgroundColor: 'transparent',
    color: 'blue',
  },
  textCenter: { textAlign: 'center' },
  paddingFifteen: { padding: '15px' },
};

export default style;
