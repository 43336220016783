import * as types from '../actions/actionTypes';

const initialState = {
  readyPackageAll: [],
  getReadyPackageAllStatus: '',
  // readyPackageById: null,
  readyPackageById: {},
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_READY_PACKAGE_ALL
    //---------------------------------
    case types.GET_READY_PACKAGE_ALL:
      return { ...state };
    case types.GET_READY_PACKAGE_ALL_PENDING:
      return { ...state, loading: true };
    case types.GET_READY_PACKAGE_ALL_FULFILLED:
      return {
        ...state,
        readyPackageAll: action.payload.data,
        errors: {},
        loading: false,
        getReadyPackageAllStatus: 'success',
      };
    case types.GET_READY_PACKAGE_ALL_REJECTED:
      return {
        ...state,
        readyPackageAll: [],
        errors: action.payload.response.data,
        loading: false,
        getReadyPackageAllStatus: 'failed',
      };
    case types.RESET_GET_READY_PACKAGE_ALL_STATUS:
      return {
        ...state,
        errors: {},
        loading: false,
        getReadyPackageAllStatus: '',
      };
    //---------------------------------
    // GET_READY_PACKAGE_BY_ID
    //---------------------------------
    case types.GET_READY_PACKAGE_BY_ID:
      return { ...state };
    case types.GET_READY_PACKAGE_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_READY_PACKAGE_BY_ID_FULFILLED:
      return {
        ...state,
        readyPackageById: action.payload.data,
        errors: {},
        loading: false,
        getReadyPackageByIdStatus: 'success',
      };
    case types.GET_READY_PACKAGE_BY_ID_REJECTED:
      return {
        ...state,
        readyPackageById: {},
        errors: action.payload.response.data,
        loading: false,
        getReadyPackageByIdStatus: 'failed',
      };
    case types.RESET_GET_READY_PACKAGE_BY_ID_STATUS:
      return {
        ...state,
        errors: {},
        loading: false,
        getReadyPackageByIdStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
