import React from 'react';
import { Container } from 'semantic-ui-react';
import styles from './styles';
import DynamicTitle from '../../components/common/DynamicTitle';

const aboutUs = () => (
  <div>
    <DynamicTitle title="About Us" />
    <Container textAlign="center" style={styles.title}>
      <h2>ABOUT US</h2>
    </Container>
    <Container text textAlign="justified">
      <p style={styles.aboutUsComponent}>
        <strong>touress </strong> is a new state of the art, advance, and first
        of its kind &quot;easy & fun to use&quot; online tour reservation and
        booking system. Our platforms which are available in Web, Android, and
        iOS empower travel consultants all around the world to book the packages
        your customers love quickly with instant confirmation.
      </p>
    </Container>
  </div>
);

export default aboutUs;
