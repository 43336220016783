import React, { Component } from 'react';
import {
  Segment,
  Header,
  Grid,
  Menu,
  Message,
  Button,
  Divider,
  Popup,
  Icon,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import Loader from '../../../../../components/common/loader';
import EmptyImg from '../../../../../assets/svg/Calculator.svg';
import MenuItemWithIcon from './MenuItemWithIcon';
import SegmentItemPriceInformation from '../../../../readyPackage/components/segmentItemPriceInformation/SegmentItemPriceInformation';
import { convertDateFormat } from '../../../../../genericFunction/itineraryBuilder/timeHelper';
import { converNumberToK } from '../../../../../genericFunction/numberConverter';
import { withTranslation } from 'react-i18next';
class SideMenu extends Component {
  state = {
    tabAccount: false,
    tabCompany: false,
    tabBooking: false,
    tabQuotation: false,
  };
  handleQuotation = (e, { name }) => {
    this.props.confirmQuotation(name);
  };
  renderButton = disable => {
    let { packageType, handleSummary } = this.props;
    return packageType === 'quotation' ? (
      <div>
        <Button
          name="save"
          style={styles.buttonBlue}
          disabled={disable}
          onClick={this.handleQuotation}
        >
          {/* Save Quotation */}
          {this.props.t('saveQuotation')}
        </Button>
        <Button
          name="deal"
          style={styles.buttonGold}
          disabled={disable}
          onClick={this.handleQuotation}
        >
          {/* Book Now */} {this.props.t('bookNow')}
        </Button>
      </div>
    ) : (
      <Button
        style={styles.buttonGold}
        disabled={disable}
        onClick={handleSummary}
      >
        {/* Book Now */} {this.props.t('bookNow')}
      </Button>
    );
  };
  render() {
    const { t } = this.props;
    let {
      tabActive,
      generalInfoRef,
      destinationsRef,
      itineraryRef,
      operatorRef,
      guestsRef,
      handleScroll,
      demoPrice,
      demoPriceLoading,
      roomPrices,
      expiredDate,
      isVariableDate,
      menuFixed,
      demoJoinTour,
      additionalInfoRef,
      additionalServiceRef,
      isAdditionalThereInfo,
    } = this.props;
    let disable =
      Object.keys(!isVariableDate ? demoPrice : demoJoinTour).length > 0
        ? false
        : true;
    let detail = !isVariableDate
      ? demoPrice.BookingDetailSum
      : demoJoinTour.BookingDetailSum;

    return (
      <div style={menuFixed ? styles.paddingTop5em : styles.nomarginTop}>
        {isVariableDate ? (
          <React.Fragment>
            <Segment style={styles.height8em}>
              <Grid columns={3}>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Grid columns={2}>
                      <Grid.Row>
                        <Grid.Column>
                          <Header as="h4" style={styles.noMargin}>
                            {/* Starting From */}
                            {t('startingFrom')}
                          </Header>
                        </Grid.Column>
                        <Grid.Column>
                          <Popup
                            position="bottom center"
                            trigger={
                              <Icon
                                circular
                                name="info"
                                size="small"
                                color="blue"
                                style={styles.marginLeftMinus}
                              />
                            }
                            on="hover"
                          >
                            <Header as={'h4'} content="Fix Price" />
                            <Divider style={styles.noPadding} />
                            {/* All activity and room allocation price has been set
                            as a package */}
                            {t('fixPricePopUp')}
                          </Popup>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                    <Header style={styles.bigFontGold}>
                      {`${roomPrices ? roomPrices[0].Currency : ''} ${
                        roomPrices[0].Price
                          ? converNumberToK(roomPrices[0].Price)
                            ? converNumberToK(roomPrices[0].Price)
                            : '-'
                          : 0
                      }`}
                    </Header>
                    <strong>
                      {/* Per Person on twin sharing */}
                      {t('perPersonOnTwinSharing')}
                    </strong>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    <Header as="h4" style={styles.noMargin} floated="right">
                      {t('expiredUntill')} :
                    </Header>
                    <Header style={styles.mediumFontGold} floated="right">
                      {convertDateFormat(expiredDate, 'DD MMM YYYY')}{' '}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            <SegmentItemPriceInformation roomPrices={roomPrices} />
          </React.Fragment>
        ) : (
          <Segment style={styles.segment}>
            <Grid>
              <Grid.Row style={styles.menuStyle}>
                <Menu vertical text style={styles.width100}>
                  <MenuItemWithIcon
                    menuRef={generalInfoRef}
                    handleClick={handleScroll}
                    menuStyle={
                      tabActive === 'account'
                        ? styles.menuActive
                        : styles.menuNonActive
                    }
                    iconName="clipboard list"
                    text={t('generalInformation')} //"General Information"
                  />
                  <MenuItemWithIcon
                    menuRef={destinationsRef}
                    handleClick={handleScroll}
                    menuStyle={
                      tabActive === 'company'
                        ? styles.menuActive
                        : styles.menuNonActive
                    }
                    iconName="clipboard list"
                    text={t('destinations')} //"Destinations"
                  />
                  <MenuItemWithIcon
                    menuRef={itineraryRef}
                    handleClick={handleScroll}
                    menuStyle={
                      tabActive === 'booking'
                        ? styles.menuActive
                        : styles.menuNonActive
                    }
                    iconName="clipboard list"
                    text={t('tourItinerary')} //"Tour Itinerary"
                  />
                  <MenuItemWithIcon
                    menuRef={additionalServiceRef}
                    handleClick={handleScroll}
                    menuStyle={
                      tabActive === 'quotation'
                        ? styles.menuActive
                        : styles.menuNonActive
                    }
                    iconName="clipboard list"
                    text={t('additionalServices')} //"Additional Services"
                  />
                  <MenuItemWithIcon
                    menuRef={operatorRef}
                    handleClick={handleScroll}
                    menuStyle={
                      tabActive === 'quotation'
                        ? styles.menuActive
                        : styles.menuNonActive
                    }
                    iconName="user"
                    text={t('tourOperator')} //"Tour Operator"
                  />
                  <MenuItemWithIcon
                    menuRef={guestsRef}
                    handleClick={handleScroll}
                    menuStyle={
                      tabActive === 'quotation'
                        ? styles.menuActive
                        : styles.menuNonActive
                    }
                    iconName="users"
                    text={t('guestList')} //"Guest List"
                  />
                  {isAdditionalThereInfo && (
                    <MenuItemWithIcon
                      menuRef={additionalInfoRef}
                      handleClick={handleScroll}
                      menuStyle={
                        tabActive === 'quotation'
                          ? styles.menuActive
                          : styles.menuNonActive
                      }
                      iconName="info circle"
                      text={t('additionalInformation')} //"Additional Information"
                    />
                  )}
                </Menu>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
        <Segment style={styles.segment}>
          <Header as="h3">
            {/* Price Estimation */}
            {t('priceEstimation')}
          </Header>
          {disable || (
            <p>
              <strong>{detail ? detail.TourOperatorProfile.Name : ''}</strong>{' '}
              {/* Offering you for this package : */}
              {t('priceOffering')} :
            </p>
          )}
          {disable ? (
            <div>
              <div style={styles.textCenter}>
                <img src={EmptyImg} width="210" alt="calculator" />
              </div>
              <p style={styles.textCenter}>
                {/* We will offer a price to you, after you complete the arrangement
                beside and choose tour operators */}
                {t('weWillOfferPriceToYou')}
              </p>
            </div>
          ) : (
            <div>
              <Message color="yellow" style={styles.textCenter}>
                <Header as="h3">
                  {detail
                    ? detail.CurrencyId +
                      ' ' +
                      detail.TourTotalPrice.toLocaleString('id')
                    : '-'}
                </Header>
              </Message>
              <div style={styles.textRight}>{this.renderButton(disable)}</div>
            </div>
          )}
          <Loader active={demoPriceLoading} inverted={true} />
        </Segment>
      </div>
    );
  }
}
SideMenu.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyId: PropTypes.string,
  currency: PropTypes.string,
  balance: PropTypes.number,
  tabActive: PropTypes.string,
  generalInfoRef: PropTypes.object,
  destinationsRef: PropTypes.object,
  itineraryRef: PropTypes.object,
  operatorRef: PropTypes.object,
  guestsRef: PropTypes.object,
  handleScroll: PropTypes.func,
  handleSummary: PropTypes.func,
  demoPriceLoading: PropTypes.bool,
  demoPrice: PropTypes.object,
  packageType: PropTypes.string,
  confirmQuotation: PropTypes.func,
  roomPrices: PropTypes.array,
  expiredDate: PropTypes.string,
  isVariableDate: PropTypes.bool,
  menuFixed: PropTypes.bool,
  demoJoinTour: PropTypes.object,
  additionalInfoRef: PropTypes.object,
  isAdditionalThereInfo: PropTypes.bool,
  t: PropTypes.func,
  additionalServiceRef: PropTypes.object,
};
export default withTranslation()(SideMenu);
