import apiClient from './apiClient';
import { GET_MOVEMENT_LIST_URL, GET_DRIVING_DURATION_URL } from './apiUrl';

const getMovementListApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_MOVEMENT_LIST_URL);
};

const getDurationIdtoAddressApi = (from, to) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    `${GET_DRIVING_DURATION_URL}?originServiceItemId=${from}&destinationAddress=${to}&mode=DRIVING`
  );
};

const getDurationAddresstoIdApi = (from, to) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    `${GET_DRIVING_DURATION_URL}?originAddress=${from}&destinationServiceItemId=${to}&mode=DRIVING`
  );
};

const getDurationIdtoIdApi = (from, to) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    `${GET_DRIVING_DURATION_URL}?originServiceItemId=${from}&destinationServiceItemId=${to}&mode=DRIVING`
  );
};
export {
  getMovementListApi,
  getDurationIdtoAddressApi,
  getDurationAddresstoIdApi,
  getDurationIdtoIdApi,
};
