import React from 'react';
import { Segment, Grid, Icon, Image, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import ImageLightBox from '../../../../../components/common/imageLightBox';
import noImage from '../../../../../assets/image/NoImage.png';
import { connect } from 'react-redux';
import {
  CHECKIN,
  CHECKOUT,
  RETURNACCOMMODATION,
  LEAVEACCOMMODATION,
  EAT,
  RECREATION,
} from '../../../../../constant/activityTypes';
import options from '../../../../../genericFunction/imageListConverter';
import ModalAccommodationDetail from '../../modal/modalDetailAccommodation/ModalDetailAccommodation';
import IconButton from '../../../../../components/button/BtnIcon';
import {
  timeDuration,
  isSameDate,
} from '../../../../../genericFunction/moment';
import { isActivityTimeOnNextDay } from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import { CUSTOM, READY } from '../../../../../constant/packageTypes';
import { MessageOverload } from './';
import { withTranslation, Translation } from 'react-i18next';
class ActivityWithImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      accommodation: {},
    };
  }

  getAccommodationProfile(serviceItemId) {
    const { MainPrograms } = this.props.fixedPackageById.HeadlineProgram;
    const profile = MainPrograms.filter(
      item =>
        item.AccommodationSummary &&
        item.AccommodationSummary.ServiceItemId === serviceItemId
    );
    return profile.length > 0
      ? profile[0].AccommodationSummary.AccommodationProfileId
      : null;
  }
  iconRender() {
    const { MovementName } = this.props.data;
    switch (MovementName) {
      case CHECKIN:
        return 'Check-In-28px';
      case CHECKOUT:
        return 'Check-Out-28px';
      case RETURNACCOMMODATION:
        return 'Return-Accommodation-28px';
      case LEAVEACCOMMODATION:
        return 'Leave-Accommodation-28px';
      case EAT:
        return 'fas fa-utensils fa-2x';
      case RECREATION:
        return 'Excursion-28px';
      default:
        return null;
    }
  }
  getImageArray(imageUrl) {
    const array = imageUrl ? [options('image1', imageUrl)] : [];
    return array;
  }
  onCloseModal = () => this.setState({ openModal: false });
  handleEdit = () => {
    this.props.openModalSetActivity(
      this.props.data.MovementName,
      this.props.data,
      this.props.movements,
      this.props.index,
      this.props.day,
      'EDIT'
    );
  };
  renderNote = () => {
    const { Note } = this.props.data;
    if (Note) {
      return (
        <span>
          {/* Note */}
          <span style={styles.font700}>
            <Translation>{t => <>{t('note')}</>}</Translation>
          </span>
          : {Note}
        </span>
      );
    }
  };
  handleDelete = () => {
    this.props.deleteActivity(this.props.index, this.props.day);
  };
  render() {
    const { t } = this.props;
    const { date } = this.props;
    const {
      Item,
      MovementDescription,
      MovementName,
      DurationText,
      Duration,
      DestinationName,
      DateTime,
    } = this.props.data;
    const {
      ImageUrl,
      Name,
      Address,
      ProfileFacilities,
      ProfileImages,
      AccommodationRating,
      Desc,
      AccommodationLocations,
    } = this.state.accommodation;
    let duration = DurationText ? DurationText : timeDuration(Duration);
    const images = this.getImageArray(Item.ImageUrl);
    const destination = DestinationName ? DestinationName : Item.CityId;
    const isTimeOver =
      MovementName === RECREATION
        ? this.props.data.Item.OperationStartTime
          ? isActivityTimeOnNextDay(this.props.data)
          : false
        : false;
    let isActivityInCurrentDate = isSameDate(date, DateTime);
    return (
      <Segment basic style={styles.noMargin}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              {images.length > 0 ? (
                <ImageLightBox
                  images={images}
                  imageStyle={styles.pointerStyle}
                />
              ) : (
                <Image src={noImage} />
              )}
            </Grid.Column>
            <Grid.Column width={2}>
              <Icon style={styles.goldColor} className={this.iconRender()} />
            </Grid.Column>
            <Grid.Column width={9}>
              <Grid>
                <Grid.Row
                  style={
                    [EAT, RECREATION].indexOf(MovementName) !== -1
                      ? styles.borderBottomGrey
                      : null
                  }
                >
                  <Grid.Column width={10}>
                    <h5>{MovementDescription}</h5>
                  </Grid.Column>
                  {[READY, CUSTOM].indexOf(this.props.currentPackage) !== -1 &&
                  !this.props.isStatic &&
                  [EAT, RECREATION].indexOf(MovementName) !== -1 ? (
                    <Grid.Column width={6} textAlign="right">
                      {isActivityInCurrentDate && (
                        <IconButton
                          name="edit"
                          iconStyle={styles.buttonIconEdit}
                          handleOnClick={this.handleEdit}
                          size="large"
                        />
                      )}
                      <IconButton
                        name="times"
                        iconStyle={styles.buttonIconDelete}
                        handleOnClick={this.handleDelete}
                        size="large"
                      />
                    </Grid.Column>
                  ) : null}
                </Grid.Row>
                <Grid.Row style={styles.noPaddingBot}>
                  <Grid.Column width={16}>
                    {Item.Name}
                    {destination ? ` ${t('at')} ` + destination + ',' : ''}
                    {MovementName === CHECKIN
                      ? Item.ServiceType
                        ? Item.ServiceType === 'Bed'
                          ? `${t('roomOnly')}` // 'Room Only'
                          : Item.ServiceType.replace(/_/g, ' ')
                        : null
                      : null}
                    {[EAT, RECREATION].indexOf(MovementName) !== -1
                      ? ` - ${t('duration')} ` + duration //- Duration
                      : ''}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingFive}>
                  <Grid.Column width={16}>{this.renderNote()}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <ModalAccommodationDetail
          open={this.state.openModal}
          onClose={this.onCloseModal}
          images={ProfileImages ? ProfileImages : []}
          image={ImageUrl ? ImageUrl : ''}
          name={Name ? Name : ''}
          address={Address ? Address : ''}
          rating={AccommodationRating ? AccommodationRating.Id : '0'}
          description={Desc ? Desc : ''}
          facilities={ProfileFacilities ? ProfileFacilities : []}
          location={AccommodationLocations ? AccommodationLocations : []}
        />
        {[READY, CUSTOM].indexOf(this.props.currentPackage) !== -1 &&
        isTimeOver ? (
          <Message error>
            {/* The excursion time is outside the operating time. Please change your
            excursion time. */}
            {t('warningExcursionOutsideTime')}
          </Message>
        ) : null}
        <MessageOverload
          dateDay={date}
          dateActivity={DateTime}
          movementName={MovementName}
        />
      </Segment>
    );
  }
}
ActivityWithImage.propTypes = {
  Item: PropTypes.object,
  data: PropTypes.object,
  MovementName: PropTypes.string,
  MovementDescription: PropTypes.string,
  DurationText: PropTypes.string,
  handleDelete: PropTypes.func,
  accommodationProfile: PropTypes.object,
  fixedPackageById: PropTypes.object,
  accommodationProfileList: PropTypes.array,
  currentPackage: PropTypes.string,
  activity: PropTypes.object,
  openModalSetActivity: PropTypes.func,
  movements: PropTypes.array,
  index: PropTypes.number,
  day: PropTypes.number,
  deleteActivity: PropTypes.func,
  isStatic: PropTypes.bool,
  date: PropTypes.string,
  t: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    accommodationProfileList: state.accommodation.accommodationProfileList,
    fixedPackageById: state.fixedPackage.fixedPackageById,
  };
};
export default connect(mapStateToProps)(withTranslation()(ActivityWithImage));
