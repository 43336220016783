import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Header, Label } from 'semantic-ui-react';
import InputCustom from '../../../../components/input/WithLabel';
import styles from './styles';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';

const InputRange = props => {
  const { t } = useTranslation();
  let {
    maxPrice,
    rangePrice,
    onLowerBoundChange,
    onUpperBoundChange,
    onSliderChange,
  } = props;
  let minFilterRange = rangePrice[0].toLocaleString();
  let maxFilterRange = rangePrice[1].toLocaleString();
  return (
    <Card.Content>
      {/* Price Range */}
      <Header as="h3">{t('priceRange')}</Header>
      <Grid style={styles.paddingTopBottom10px}>
        <Grid.Column width={8} style={styles.paddingTopBottom2px}>
          <InputCustom
            inputStyles={styles.width80percent}
            placeholder="0"
            handleChange={onLowerBoundChange}
            value={minFilterRange}
            type="text"
            labelInput={
              <Label basic style={styles.labelInputRange}>
                IDR
              </Label>
            }
            labelPosition="left"
          />
        </Grid.Column>
        <Grid.Column width={8} style={styles.paddingTopBottom2px}>
          <InputCustom
            inputStyles={styles.width80percent}
            placeholder="0"
            handleChange={onUpperBoundChange}
            value={maxFilterRange}
            type="text"
            labelInput={
              <Label basic style={styles.labelInputRange}>
                IDR
              </Label>
            }
            labelPosition="left"
          />
        </Grid.Column>
        <Grid.Column width={16} style={styles.paddingTopBottom2px}>
          <Range
            allowCross={false}
            value={rangePrice}
            onChange={onSliderChange}
            min={0}
            max={maxPrice}
            trackStyle={[styles.backgroundGold]}
            railStyle={styles.greyColor}
            handleStyle={[styles.borderGold, styles.borderGold]}
            activeDotStyle={styles.borderGold}
            step={10000}
          />
        </Grid.Column>
      </Grid>
    </Card.Content>
  );
};
InputRange.propTypes = {
  maxPrice: PropTypes.number,
  rangePrice: PropTypes.array,
  onLowerBoundChange: PropTypes.func,
  onUpperBoundChange: PropTypes.func,
  onSliderChange: PropTypes.func,
  t: PropTypes.func,
};
export default InputRange;
