import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Header } from 'semantic-ui-react';
import CreateCustomPackageSection from './CreateCustomPackageSection';
import FormFilterPackage from '../../form/FormFilterPackage';
import styles from './styles';

import { useTranslation } from 'react-i18next';

const QuickLaunch = () => {
  const { t } = useTranslation();
  return (
    <Container
      style={styles.quickLaunchContainerStyle}
      className="padding-side-twentyfive container-fit"
    >
      <Grid stretched style={styles.noMarginTop}>
        <Grid.Row style={styles.segment}>
          <Grid.Column width="4" style={styles.backgroundPrimary}>
            <Header as="h3" style={styles.centerAlignGold}>
              {/* Find travel packages */}
              {t('findTravelPackage')}
              <br />
              {/* that fit with your customer */}
              {t('thatFitYourCustomer')}
            </Header>
          </Grid.Column>
          <Grid.Column
            style={{ ...styles.backgroundPrimary, ...styles.formFilterStyle }}
          >
            <FormFilterPackage />
          </Grid.Column>
          <Grid.Column style={styles.shortCutStyle}>
            <CreateCustomPackageSection />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

QuickLaunch.propTypes = {
  t: PropTypes.func,
};

export default QuickLaunch;
