import {
  SET_GUESTS_AMOUNT,
  SET_TOUR_NAME,
  SET_ROOMALLOCATION,
  SET_HEADLINE_PROGRAM,
  SET_FIRST_DESTINATION,
  SET_ACCOMMODATION_ONCHANGE_INDEX,
  RESET_ACCOMMODATION_ONCHANGE_INDEX,
  SET_DAILYPROGRAM,
  RESET_SET_HEADLINE_STATUS,
  GET_MOVEMENT_LIST,
  GET_DURATION_ADDRESS_TO_ID,
  GET_DURATION_ID_TO_ADDRESS,
  GET_DURATION_ID_TO_ID,
  RESET_GET_DURATION_STATUS,
  RESET_MAPPING_DURATION,
  SET_DRIVING_DATA,
  RESET_DRIVING_DATA,
  GET_DURATION_ADDRESS_TO_ID_FULFILLED,
  GET_DURATION_ADDRESS_TO_ID_REJECTED,
  GET_DURATION_ID_TO_ID_FULFILLED,
  GET_DURATION_ID_TO_ID_REJECTED,
  GET_DURATION_ID_TO_ADDRESS_FULFILLED,
  GET_DURATION_ID_TO_ADDRESS_REJECTED,
  RESET_COUNT_GET_DURATION,
  SET_GUEST_LIST,
  SET_COUNT_GET_DURATION,
  SET_MAPPING_OBJ,
  RESET_SET_DAILY_PROGRAM_STATUS,
  RESET_CUSTOM_PACKAGE,
  RESET_FIRST_DESTINATION,
  RESET_GUESTS,
  RESET_ROOM_ALLOCATION,
} from './actionTypes';
import {
  getMovementListApi,
  getDurationAddresstoIdApi,
  getDurationIdtoIdApi,
  getDurationIdtoAddressApi,
} from '../../../api/customPackageApi';

const setGuestsAmount = guests => {
  return dispatch => {
    return dispatch({
      type: SET_GUESTS_AMOUNT,
      payload: guests,
    });
  };
};

const setTourName = tourName => {
  return dispatch => {
    return dispatch({
      type: SET_TOUR_NAME,
      payload: tourName,
    });
  };
};
const setRoomAllocation = roomAllocation => {
  return dispatch => {
    return dispatch({
      type: SET_ROOMALLOCATION,
      payload: roomAllocation,
    });
  };
};
const setHeadlineProgram = headLineProgram => {
  return dispatch => {
    return dispatch({
      type: SET_HEADLINE_PROGRAM,
      payload: headLineProgram,
    });
  };
};
const resetHeadLineStatus = () => {
  return dispatch => {
    return dispatch({
      type: RESET_SET_HEADLINE_STATUS,
    });
  };
};

const setFirstDestination = firstDesination => {
  return dispatch => {
    return dispatch({
      type: SET_FIRST_DESTINATION,
      payload: firstDesination,
    });
  };
};
const setAccommodationOnChangeIndex = index => {
  return dispatch => {
    return dispatch({
      type: SET_ACCOMMODATION_ONCHANGE_INDEX,
      payload: index,
    });
  };
};
const resetAccommodationOnChangeIndex = () => {
  return dispatch => {
    return dispatch({
      type: RESET_ACCOMMODATION_ONCHANGE_INDEX,
    });
  };
};
const setDailyProgram = dailyProgram => {
  return dispatch => {
    return dispatch({ type: SET_DAILYPROGRAM, payload: dailyProgram });
  };
};

const getMovementList = () => {
  return dispatch => {
    return dispatch({
      type: GET_MOVEMENT_LIST,
      payload: getMovementListApi(),
    });
  };
};

const getDurationAddresstoId = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: GET_DURATION_ADDRESS_TO_ID,
      payload: getDurationAddresstoIdApi(from, to),
    })
      .then(response =>
        dispatch({
          type: GET_DURATION_ADDRESS_TO_ID_FULFILLED,
          payload: response.value.data,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: GET_DURATION_ADDRESS_TO_ID_REJECTED,
          payload: error,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        });
      });
  };
};

const getDurationIdtoAddress = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: GET_DURATION_ID_TO_ADDRESS,
      payload: getDurationIdtoAddressApi(from, to),
    })
      .then(response =>
        dispatch({
          type: GET_DURATION_ID_TO_ADDRESS_FULFILLED,
          payload: response.value.data,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: GET_DURATION_ID_TO_ADDRESS_REJECTED,
          payload: error,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        });
      });
  };
};

const getDurationIdtoId = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: GET_DURATION_ID_TO_ID,
      payload: getDurationIdtoIdApi(from, to),
    })
      .then(response =>
        dispatch({
          type: GET_DURATION_ID_TO_ID_FULFILLED,
          payload: response.value.data,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        })
      )
      .catch(error => {
        dispatch({
          type: GET_DURATION_ID_TO_ID_REJECTED,
          payload: error,
          data: {
            from: from,
            to: to,
            day: day,
            index: index,
          },
        });
      });
  };
};

const setDrivingData = (from, to, day, index) => {
  return dispatch => {
    return dispatch({
      type: SET_DRIVING_DATA,
      payload: {
        from: from,
        to: to,
        day: day,
        index: index,
      },
    });
  };
};

const resetDrivingData = () => {
  return dispatch => {
    return dispatch({ type: RESET_DRIVING_DATA });
  };
};

const resetGetDurationStatus = () => {
  return dispatch => {
    return dispatch({ type: RESET_GET_DURATION_STATUS });
  };
};

const resetMappingDuration = () => {
  return dispatch => {
    return dispatch({ type: RESET_MAPPING_DURATION });
  };
};

const resetGetDurationCount = () => {
  return dispatch => {
    return dispatch({ type: RESET_COUNT_GET_DURATION });
  };
};

const setGuestList = guestList => {
  return dispatch => {
    return dispatch({ type: SET_GUEST_LIST, payload: guestList });
  };
};

const setGetDurationCount = count => {
  return dispatch => {
    return dispatch({ type: SET_COUNT_GET_DURATION, payload: count });
  };
};
const setMappingObj = obj => {
  return dispatch => {
    return dispatch({ type: SET_MAPPING_OBJ, payload: obj });
  };
};

const resetSetDailyProgramStatus = () => {
  return dispatch => {
    return dispatch({ type: RESET_SET_DAILY_PROGRAM_STATUS });
  };
};

const resetCustomPackage = () => {
  return dispatch => {
    return dispatch({ type: RESET_CUSTOM_PACKAGE });
  };
};

const resetFirstDestination = () => {
  return dispatch => {
    return dispatch({ type: RESET_FIRST_DESTINATION });
  };
};

const resetGuests = () => {
  return dispatch => {
    return dispatch({ type: RESET_GUESTS });
  };
};

const resetRoomAllocation = () => {
  return dispatch => {
    return dispatch({ type: RESET_ROOM_ALLOCATION });
  };
};

export {
  setGuestsAmount,
  setTourName,
  setRoomAllocation,
  setHeadlineProgram,
  setFirstDestination,
  setAccommodationOnChangeIndex,
  resetAccommodationOnChangeIndex,
  setDailyProgram,
  getMovementList,
  resetHeadLineStatus,
  getDurationAddresstoId,
  getDurationIdtoAddress,
  getDurationIdtoId,
  resetGetDurationStatus,
  resetMappingDuration,
  setDrivingData,
  resetDrivingData,
  resetGetDurationCount,
  setGuestList,
  setGetDurationCount,
  setMappingObj,
  resetSetDailyProgramStatus,
  resetCustomPackage,
  resetFirstDestination,
  resetGuests,
  resetRoomAllocation,
};
