import apiClient from './apiClient';
import {
  POST_RESET_STAFF_PASSWORD_URL,
  PUT_UPDATE_PROFILE_URL,
  GET_USER_PROFILE_URL,
  POST_CHANGE_PASSWORD_URL,
} from './apiUrl';

const postResetStaffPasswordApi = data => {
  return apiClient.post(POST_RESET_STAFF_PASSWORD_URL, data);
};

const putUpdateProfileApi = (id, data) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.put(`${PUT_UPDATE_PROFILE_URL}/${id}`, data);
};

const getUserProfileApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_USER_PROFILE_URL);
};

const postChangePasswordApi = data => {
  return apiClient.post(POST_CHANGE_PASSWORD_URL, data);
};

export {
  postResetStaffPasswordApi,
  putUpdateProfileApi,
  getUserProfileApi,
  postChangePasswordApi,
};
