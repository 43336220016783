import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';

const downloadButton = props => (
  <div>
    <Button
      fluid
      basic
      style={styles.borderGold}
      propscontext={props.propscontext}
      onClick={props.handleOnClick}
    >
      <Icon name="download" style={styles.goldColor} />
      <strong style={styles.goldColor}>{props.buttonText}</strong>
    </Button>
  </div>
);

downloadButton.propTypes = {
  buttonText: PropTypes.string,
  handleOnClick: PropTypes.func,
  propscontext: PropTypes.object,
};
export default downloadButton;
