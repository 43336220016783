const iconFacilitiesAccommodation = [
  { key: 'AC', icon: 'fas fa-snowflake fa-2x', text: 'AC', width: 2 },
  { key: 'WIFI', icon: 'fas fa-wifi fa-2x', text: 'WiFi', width: 2 },
  {
    key: 'SWIMMINGPOOL',
    icon: 'fas fa-swimming-pool fa-2x',
    text: 'Swimming Pool',
    width: 2,
  },
  {
    key: 'PARKING',
    icon: 'fas fa-parking fa-2x',
    text: 'Parking',
    width: 2,
  },
  {
    key: 'RESTAURANT',
    icon: 'fas fa-utensils fa-2x',
    text: 'Restaurant',
    width: 2,
  },
];
const rating = [
  { key: 'HS', value: 'highest rating', text: 'Highest Star' },
  { key: 'LS', value: 'lowest rating', text: 'Lowest Star' },
];
const availability = [
  { key: 'ON_REQUEST', text: 'On Request', checked: false },
  { key: 'AVAILABLE', text: 'Available', checked: false },
];
export { iconFacilitiesAccommodation, rating, availability };
