import { validationPassword } from '../../../../genericFunction/validation';
const handleValidation = (state, fields) => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (state.Username.includes(' ')) {
      validate['Username'] = {
        error: true,
        message: `Username is not allowed to use spaces`,
      };
    } else if (field === 'Password') {
      let password = validationPassword(state[field]);
      if (!password) {
        validate[field] = {
          error: true,
          message: `Min. 8 characters ( number and letter )`,
        };
      }
    } else if (field === 'ConfirmPassword') {
      if (state[field] !== state['Password']) {
        validate[field] = {
          error: true,
          message: `Password doesn't match`,
        };
      }
    } else if (field === 'Email' && state.Email.indexOf('@' && '.') === -1) {
      validate[field] = {
        error: true,
        message: `Email format not valid!!`,
      };
    } else {
      delete validate[field];
    }
    return validate;
  });

  return validate;
};

export default handleValidation;
