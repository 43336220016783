import { GOLD_COLOR, RED_COLOR } from '../../../../constant/colour';
const style = {
  marginBotFourteen: { marginBottom: '14px' },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  noBorder: {
    border: 'none',
  },
  noMarginGold: { margin: 0, color: GOLD_COLOR },
  noMargin: { margin: 0 },
  segmentGoldTransparent: {
    padding: '15px',
    background: 'rgba(230, 202, 107,0.1)',
    border: 'none',
  },
  borderGoldBackgroundTransparentGold: {
    border: `1px solid ${GOLD_COLOR}`,
    background: 'rgba(230, 202, 107,0.3)',
  },
  borderRedBackgroundRed: {
    border: `1px solid ${RED_COLOR}`,
    background: 'rgba(244, 67, 54, 0.4)',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  borderBottom: {
    borderBottom: '1px solid black',
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  backgroundWhiteBorderGold: {
    padding: '0px',
    border: `1px solid ${GOLD_COLOR}`,
    background: 'white',
  },
  backgroundGoldTransparentPadding7px20px: {
    padding: '7px 20px',
    background: 'rgba(230, 202, 107,0.1)',
  },
  paddingTenAndTwenty: {
    padding: '10px 20px',
  },
  textRight: { textAlign: 'right' },

  mandatoryField: { color: 'red', fontWeight: '700' },
  inputStyle: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  backgroundGold: { backgroundColor: GOLD_COLOR },
};
export default style;
