import apiClient from './apiClient';
import {
  GET_TRANSACTION_HISTORY_ALL_URL,
  POST_TOUR_TRANSACTION_CONFIRMATION_URL,
  GET_TOUR_TRANSACTION_SUMMARY_URL,
  GET_SCHEDULE_TOUR_TRANSACTION_URL,
  GET_INVOICE_URL,
  GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_URL,
  GET_TOUR_TRANSACTION_TO_BE_PAYED_URL,
  GET_INVOICE_TRAVEL_AGENT_URL,
  GET_INVOICE_CUSTOMER_URL,
  GET_VOUCHER_CONFIRMATION_URL,
  GET_TOUR_TRANSACTION_BILLS_URL,
} from './apiUrl';

const getTransactionHistoryAllApi = status => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_TRANSACTION_HISTORY_ALL_URL}?&status=${status}`);
};
const postTourTransactionConfirmationApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_TOUR_TRANSACTION_CONFIRMATION_URL, data);
};
const getTourTransactionSummaryApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_TOUR_TRANSACTION_SUMMARY_URL}?id=${id}`);
};
const getScheduleTourTransactionApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    `${GET_SCHEDULE_TOUR_TRANSACTION_URL}?tourTransactionId=${id}`
  );
};
const getInvoiceApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_INVOICE_URL}?id=${id}`);
};
const getSuplementItemByTransactionIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(
    `${GET_SUPLEMENT_ITEM_BY_TRANSACTION_ID_URL}?tourTransactionId=${id}`
  );
};
const getTourTransactionToBePayedApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_TOUR_TRANSACTION_TO_BE_PAYED_URL}/${id}`);
};

const getInvoiceTravelAgentApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_INVOICE_TRAVEL_AGENT_URL}${id}`);
};
const getInvoiceCustomerApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_INVOICE_CUSTOMER_URL}${id}`);
};
const getVoucherConfirmationApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_VOUCHER_CONFIRMATION_URL}${id}`);
};

const getTourTransactionBillsApi = transactionId => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_TOUR_TRANSACTION_BILLS_URL}/${transactionId}`);
};

export {
  getTransactionHistoryAllApi,
  postTourTransactionConfirmationApi,
  getTourTransactionSummaryApi,
  getScheduleTourTransactionApi,
  getInvoiceApi,
  getSuplementItemByTransactionIdApi,
  getTourTransactionToBePayedApi,
  getInvoiceTravelAgentApi,
  getInvoiceCustomerApi,
  getVoucherConfirmationApi,
  getTourTransactionBillsApi,
};
