import React, { useState, useEffect } from 'react';
import { Grid, Segment, Loader, Pagination } from 'semantic-ui-react';
import LoaderGlobal from '../../../components/common/loader';
import { CardPackage } from './cardPackage/';
import { ModalConfirm, ModalAlert } from '../../shared/components/modal';
import Input from '../../../components/input/WithLabel';
import {
  getTransactionHistoryAll,
  // getTourTransactionSummary,
} from '../../shared/actions/bookingAction';
import {
  postAcceptQuotation,
  resetStatusQuotation,
} from '../../shared/actions/quotationAction';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './../styles';
import { Redirect } from 'react-router-dom';
import MyBookingDetail from './MyBookingDetail';
import { withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// import { disabilityAccess } from '../../../assets/icon/accommodation service';
const MyQuotation = props => {
  const { transactionId } = props.match.params;
  const { t } = useTranslation();
  const [modalBookNow, setModalBookNow] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [modalAlertDescSuccess, setModalAlertDescSuccess] = useState('');
  const [modalAlertDescFailed, setModalAlertDescFailed] = useState('');
  const [gotoMyBooking, setGotoMyBooking] = useState(false);
  const [idAction, setIdAction] = useState(0);
  const [searchTitle, setSearchTitle] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [similarPage, setSimilarPage] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  //========action=========
  const dispatch = useDispatch();
  const getTransactionHistoryAllAction = param =>
    dispatch(getTransactionHistoryAll(param));
  const postAcceptQuotationAction = param =>
    dispatch(postAcceptQuotation(param));
  const resetStatusQuotationAction = () => dispatch(resetStatusQuotation());
  // const getTourTransactionSummaryAction = () =>
  //   dispatch(getTourTransactionSummary());
  //==========store===========
  const listQuotationTransaction = useSelector(
    state => state.booking.listQuotationTransaction
  );
  const loadingHistory = useSelector(state => state.booking.loadingHistory);
  const loadingAcceptQuotation = useSelector(state => state.quotation.loading);
  const postAcceptQuotationStatus = useSelector(
    state => state.quotation.postAcceptQuotationStatus
  );
  const errors = useSelector(state => state.quotation.errors);
  const acceptQuotationDesc = useSelector(
    state => state.quotation.acceptQuotationDesc
  );
  useEffect(() => {
    getTransactionHistoryAllAction('Booking_draft');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (postAcceptQuotationStatus === 'success') {
      let { Id, Status } = acceptQuotationDesc.BookingDetailSum;
      let message =
        Status === 'Booking_created'
          ? t('bookingSuccessfullyCreated', { id: Id }) //'Booking code ' + Id + ' successfully created'
          : t('yourBookingIsOnHold'); //'Your Booking is on hold';
      setModalAlert(true);
      setModalAlertDescSuccess(message);
      resetStatusQuotationAction();
    } else if (postAcceptQuotationStatus === 'failed') {
      setModalAlert(true);
      setModalAlertDescFailed(errors.Message);
      resetStatusQuotationAction();
    }
    // eslint-disable-next-line
  }, [postAcceptQuotationStatus]);
  // componentDidUpdate = () => {
  //   // Please allow 24 hours to confirm availability.
  //   if (this.props.postAcceptQuotationStatus === 'success') {
  //     let { Id, Status } = this.props.acceptQuotationDesc.BookingDetailSum;
  //     let message =
  //       Status === 'Booking_created'
  //         ? 'Booking code ' + Id + ' successfully created'
  //         : 'Your Booking is on hold';
  //     this.setState({
  //       ...this.state,
  //       modalAlert: true,
  //       modalAlertDescSuccess: message,
  //     });
  //     resetStatusQuotationAction();
  //   } else if (this.props.postAcceptQuotationStatus === 'failed') {
  //     this.setState({
  //       ...this.state,
  //       modalAlert: true,
  //       modalAlertDescFailed: this.props.errors.Message,
  //     });
  //     resetStatusQuotationAction();
  //   }
  // };

  const handleModal = (e, { name, value }) => {
    name === 'modalBookNow'
      ? setModalBookNow(!modalBookNow)
      : name === 'modalEdit'
      ? setModalEdit(!modalEdit)
      : setModalEdit(modalEdit);
    setIdAction(value);
    name === 'modalEdit' && getTransaction(value);

    // this.setState(
    //   {
    //     [name]: !this.state[name],
    //     idAction: value,
    //   },
    //   () => {
    //     name === 'modalEdit' && getTransaction(value);
    //   }
    // );
  };
  const handleCloseBookNow = () => setModalBookNow(false);
  const getTransaction = id => {
    return <Redirect to={'/quotation/' + id} />;
  };
  const confirmBook = () => {
    postAcceptQuotationAction(idAction);
    // this.setState({ modalBookNow: false });
    setModalBookNow(false);
  };
  const handleCloseAlert = () => {
    modalAlertDescSuccess && setGotoMyBooking(true);
    setModalAlert(false);
    // ? this.setState({ ...this.state, modalAlert: false, gotoMyBooking: true })
    // : this.setState({ ...this.state, modalAlert: false });
  };
  const handleChange = (e, { value }) => {
    setSearchTitle(value);
    setActivePage(1);
  };
  const filtered = listData => {
    return listData.filter(
      data =>
        data.Title.toLowerCase().indexOf(searchTitle.toLowerCase()) !== -1 ||
        data.Id.toLowerCase().indexOf(searchTitle.toLowerCase()) !== -1
    );
  };
  const pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;

    for (var i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);

    return sets;
  };
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  const handleCreateSimilar = idAction => {
    setIdAction(idAction);
    setSimilarPage(true);
  };

  const handleOpenDetail = value => {
    // setDataDetail(value);
    // setOpenDetail(true);
    // let { id } = value;
    // getSuplementItemByTransactionIdAction(id);
    // getTourTransactionToBePayedAction(id);
    // getTourTransactionSummaryAction(id);
    // getTourTransactionBillsAction(id);
    let newPageUrl = `/my-profile/quotation/${value}`;
    window.open(newPageUrl, '_blank');
  };

  const handleBackToList = () => {
    setDataDetail({});
    // setOpenDetail(false);
  };

  let filterData = filtered(listQuotationTransaction);
  let pagedData = pagination(filterData, 3);
  let listData = pagedData[activePage - 1];
  let showCard = listQuotationTransaction && !loadingHistory;
  if (gotoMyBooking) {
    return <Redirect to={'/my-profile/booking'} />;
  } else if (similarPage) {
    return <Redirect to={'/ready/similar/' + idAction} />;
  }
  return (
    <div>
      <Segment style={styles.segment}>
        <Grid>
          {transactionId ? (
            <Grid.Row>
              <Grid.Column>
                <MyBookingDetail
                  id={dataDetail.id ? dataDetail.id : transactionId}
                  listPaymentTerm={dataDetail.listPaymentTerm}
                  packageType={dataDetail.packageType}
                  handleBackToList={handleBackToList}
                  // isAcceptedQuotation=
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Input
                    inputStyles={styles.searchInput}
                    transparent
                    icon="search"
                    iconPosition="left"
                    placeholder={t('searchTourNameOrQuotationCode')} //"Search Tour Name or Quotation Number"
                    name="searchTitle"
                    value={searchTitle}
                    handleChange={handleChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Loader active={loadingHistory} inline="centered" />
                <Grid.Column width={16}>
                  {showCard && listData
                    ? listData.map((data, index) => {
                        let commission =
                          data.PackageType === 'Fixed'
                            ? data.FixedPackage.BookingCommission
                                .ApplicableCommission.TotalPrice
                            : 0;
                        let totalPayable =
                          data.PackageType === 'Fixed'
                            ? data.FixedPackage.BookingCommission
                                .TotalPriceForCustomer
                            : 0;
                        let paymentTerm = data.PaymentTerms
                          ? data.PaymentTerms.length > 0
                            ? data.PaymentTerms.filter(paid => !paid.IsPayed)[0]
                            : ''
                          : '';
                        let descPaymentTerm = paymentTerm
                          ? paymentTerm.Description
                          : '';
                        let percentagePaymentTerm = paymentTerm
                          ? paymentTerm.PaymentPercentage
                          : 0;
                        let isAcceptedQuotation = data.Quotation
                          ? data.Quotation.IsAccepted
                          : false;
                        let tourTransactionIdQuotation = data.Quotation
                          ? data.Quotation.TourTransactionId
                          : 0;
                        return (
                          <div key={index}>
                            <CardPackage
                              title={data.Title}
                              id={data.Id}
                              expiredOn={data.ExpiredOn}
                              image={data.City.ImageUrl}
                              startDate={data.StartDate}
                              endDate={data.EndDate}
                              city={data.City.Name}
                              country={data.Country.Name}
                              handleModal={handleModal}
                              status={data.Status}
                              currency={data.CurrencyId}
                              totalPrice={data.TourTotalPrice}
                              commision={commission}
                              descPaymentTerm={descPaymentTerm}
                              percentagePaymentTerm={percentagePaymentTerm}
                              totalPayable={totalPayable}
                              totalPaid={data.TotalPayed}
                              packageType={data.PackageType}
                              listPaymentTerm={data.PaymentTerms}
                              activePage={activePage}
                              searchTitle={searchTitle}
                              isAcceptedQuotation={isAcceptedQuotation}
                              tourTransactionIdQuotation={
                                tourTransactionIdQuotation
                              }
                              handleCreateSimilar={handleCreateSimilar}
                              createdBy={data.CreatedBy}
                              handleOpenDetail={handleOpenDetail}
                            />
                          </div>
                        );
                      })
                    : !loadingHistory && (
                        <h3 style={styles.textAlignCenter}>
                          There are no quotation
                        </h3>
                      )}
                  <ModalConfirm
                    openModal={modalBookNow}
                    size="tiny"
                    message="Are you sure want to book this tour?"
                    close={handleCloseBookNow}
                    confirm={confirmBook}
                  />
                  <ModalAlert
                    openModal={modalAlert}
                    size="tiny"
                    close={handleCloseAlert}
                    message={
                      modalAlertDescSuccess
                        ? modalAlertDescSuccess
                        : modalAlertDescFailed
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="center">
                <Grid.Column width={16}>
                  {pagedData && pagedData.length > 1 && (
                    <Pagination
                      totalPages={pagedData.length}
                      onPageChange={pageChange}
                      activePage={activePage}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Segment>
      <LoaderGlobal
        active={loadingAcceptQuotation}
        description="loading"
        page={loadingAcceptQuotation}
      />
    </div>
  );
};

MyQuotation.propTypes = {
  getTransactionHistoryAll: PropTypes.func,
  listQuotationTransaction: PropTypes.array,
  loadingHistory: PropTypes.bool,
  postAcceptQuotation: PropTypes.func,
  loadingAcceptQuotation: PropTypes.bool,
  postAcceptQuotationStatus: PropTypes.string,
  resetStatusQuotation: PropTypes.func,
  errors: PropTypes.object,
  getTourTransactionSummary: PropTypes.func,
  acceptQuotationDesc: PropTypes.object,
  t: PropTypes.object,
  match: PropTypes.object,
};
export default withRouter(MyQuotation);
