const GET_USER_PROFILE_BY_COMPANY_CODE = 'GET_USER_PROFILE_BY_COMPANY_CODE';
const GET_USER_PROFILE_BY_COMPANY_CODE_PENDING =
  'GET_USER_PROFILE_BY_COMPANY_CODE_PENDING';
const GET_USER_PROFILE_BY_COMPANY_CODE_REJECTED =
  'GET_USER_PROFILE_BY_COMPANY_CODE_REJECTED';
const GET_USER_PROFILE_BY_COMPANY_CODE_FULFILLED =
  'GET_USER_PROFILE_BY_COMPANY_CODE_FULFILLED';
const GET_TRANSPORTATION_UNIT_BY_ID = 'GET_TRANSPORTATION_UNIT_BY_ID';
const GET_TRANSPORTATION_UNIT_BY_ID_PENDING =
  'GET_TRANSPORTATION_UNIT_BY_ID_PENDING';
const GET_TRANSPORTATION_UNIT_BY_ID_REJECTED =
  'GET_TRANSPORTATION_UNIT_BY_ID_REJECTED';
const GET_TRANSPORTATION_UNIT_BY_ID_FULFILLED =
  'GET_TRANSPORTATION_UNIT_BY_ID_FULFILLED';
const RESET_STATUS_TRANSPORTATION_UNIT_BY_ID =
  'RESET_STATUS_TRANSPORTATION_UNIT_BY_ID';
const GET_USER_PROFILE_BY_CODE_FOR_SALES = 'GET_USER_PROFILE_BY_CODE_FOR_SALES';
const GET_USER_PROFILE_BY_CODE_FOR_SALES_PENDING =
  'GET_USER_PROFILE_BY_CODE_FOR_SALES_PENDING';
const GET_USER_PROFILE_BY_CODE_FOR_SALES_REJECTED =
  'GET_USER_PROFILE_BY_CODE_FOR_SALES_REJECTED';
const GET_USER_PROFILE_BY_CODE_FOR_SALES_FULFILLED =
  'GET_USER_PROFILE_BY_CODE_FOR_SALES_FULFILLED';
export {
  GET_USER_PROFILE_BY_COMPANY_CODE,
  GET_USER_PROFILE_BY_COMPANY_CODE_PENDING,
  GET_USER_PROFILE_BY_COMPANY_CODE_REJECTED,
  GET_USER_PROFILE_BY_COMPANY_CODE_FULFILLED,
  GET_TRANSPORTATION_UNIT_BY_ID,
  GET_TRANSPORTATION_UNIT_BY_ID_PENDING,
  GET_TRANSPORTATION_UNIT_BY_ID_FULFILLED,
  GET_TRANSPORTATION_UNIT_BY_ID_REJECTED,
  RESET_STATUS_TRANSPORTATION_UNIT_BY_ID,
  GET_USER_PROFILE_BY_CODE_FOR_SALES,
  GET_USER_PROFILE_BY_CODE_FOR_SALES_PENDING,
  GET_USER_PROFILE_BY_CODE_FOR_SALES_REJECTED,
  GET_USER_PROFILE_BY_CODE_FOR_SALES_FULFILLED,
};
