const breadCrumb = label => {
  return [
    { key: 'Home', content: 'Home', link: true, href: '/' },
    { key: label, content: label, active: true },
  ];
};
const breadCrumbFixPrice = [
  { key: 'Home', content: 'Home', link: true, href: '/' },
  {
    key: 'Ready Package Fix Price ',
    content: 'Ready Package Fix Price',
    active: true,
  },
];

export { breadCrumb, breadCrumbFixPrice };
