import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
// import CardHeader from './cardHeader';
// import CardDescription from './cardDescription';
import { ButtonCloseModal } from '../../common';
import styles from '../styles';
import AccommodationDetailCard from '../modalDetailListRoom/AccommodationDetailCard';
import { useTranslation } from 'react-i18next';

const ModalDetailAccommodation = props => {
  const { t } = useTranslation();
  let { open, onClose, profile } = props;
  return (
    <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {/* Accommodation Detail */}
        {t('accommodationDetail')} <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description>
          <AccommodationDetailCard data={profile} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
ModalDetailAccommodation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  images: PropTypes.array,
  image: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  rating: PropTypes.string,
  description: PropTypes.string,
  facilities: PropTypes.array,
  location: PropTypes.array,
  type: PropTypes.string,
  profile: PropTypes.object,
};
export default ModalDetailAccommodation;
