import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Segment,
  Pagination,
  Header,
} from 'semantic-ui-react';
import { LoaderModal } from '../../../common';
import Input from '../../../../../../components/input/WithLabel';
import Card from './Card';
import { ucwords } from '../../../../../../genericFunction/convertWord';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const ListCard = props => {
  const { t } = useTranslation();
  const parseOption = data => {
    let { isThereActivity } = props;
    data = isThereActivity
      ? data.filter(item => item.ServiceType.search('Transfer') === -1)
      : data;
    return data.map(item => {
      let options = item.Hours.map(hour => {
        return {
          key: hour.Id,
          value: hour.Id,
          text: hour.Name,
        };
      });

      return {
        key: item.ServiceType,
        value: item.ServiceType,
        text: item.ServiceType.split('_').join(' '),
        options,
      };
    });
  };

  const pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;
    for (let i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);
    return sets;
  };

  let {
    data,
    from,
    to,
    loading,
    activePage,
    pageChange,
    activeSort,
    options,
    searchName,
    handleChange,
    setTransportItem,
    onClose,
  } = props;
  let pagedData = pagination(data, 10);
  let listData = pagedData[activePage - 1];
  from = ucwords(from);
  to = ucwords(to);
  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={styles.marginBottomFifteen}>
            <Segment style={styles.segmentSearch}>
              <Input
                name="searchName"
                type="input"
                icon="search"
                iconPosition="left"
                inputStyles={styles.inputWithPaddingNine}
                transparent={true}
                labelStyles={styles.textTransparent}
                value={searchName}
                handleChange={handleChange}
                placeholder={t('searchByTransportationName')} //"Search by transportation name"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                type="input"
                icon="map marker alternate"
                iconPosition="left"
                inputStyles={styles.inputStyles}
                label={t('from')} //"From"
                labelStyles={styles.fontWeightBold}
                value={from}
                disabled={true}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={5}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                type="input"
                icon="map marker alternate"
                iconPosition="left"
                inputStyles={styles.inputStyles}
                label={t('to')} //"To"
                labelStyles={styles.fontWeightBold}
                value={to}
                disabled={true}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                name="activeSort"
                type="select"
                inputStyles={styles.inputStyles}
                label={`${t('sortBy')}:`} //"Sort by:"
                labelStyles={styles.fontWeightBold}
                options={options}
                value={activeSort}
                placeholder={t('sortTransportation')} //"Sort transportation"
                handleChange={handleChange}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={16} style={styles.columnStyle}>
            <LoaderModal loading={loading} />
            {listData && !loading
              ? listData.map((data, index) => {
                  let options = parseOption(
                    data.TransportationItemServiceTypes
                  );
                  return (
                    <Card
                      key={index}
                      image={data.ImageUrl}
                      name={data.Name}
                      type={data.TransportationTypeName}
                      rating={data.TransportationRatingName}
                      seat={data.TransportationSeatTypeName}
                      options={options}
                      id={data.ServiceItemId}
                      setTransportItem={setTransportItem}
                      data={data}
                      onClose={onClose}
                    />
                  );
                })
              : !loading && (
                  <Header textAlign="center" as="h4">
                    {/* There is no result of transportation for your destination */}
                    {t('noResultTransportationInDestination')}
                  </Header>
                )}
          </Grid.Column>
          <Grid.Column textAlign="center" width={16}>
            {pagedData && !loading && pagedData.length > 1 && (
              <Pagination
                totalPages={pagedData.length}
                onPageChange={pageChange}
                activePage={activePage}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
ListCard.propTypes = {
  data: PropTypes.array,
  from: PropTypes.string,
  to: PropTypes.string,
  loading: PropTypes.bool,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  activeSort: PropTypes.string,
  options: PropTypes.array,
  searchName: PropTypes.string,
  handleChange: PropTypes.func,
  setTransportItem: PropTypes.func,
  onClose: PropTypes.func,
  isThereActivity: PropTypes.bool,

  t: PropTypes.func,
};
export default ListCard;
