import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BreadCrumb } from '../shared/components/common';
import SummaryPackage from './components/Summary';
import Loader from '../../components/common/loader';
import {
  getCompanyProfile,
  getCompanies,
} from '../shared/actions/companyAction';
import { getUserRoles } from '../shared/actions/userAction';
import {
  postDemoPrice,
  postDemoJoinTourVariableDate,
} from '../shared/actions/tourTransactionAction';
import {
  postRegisterSimpleCustomer,
  resetRegisterSimpleCustomerStatus,
} from '../shared/actions/createCustomerAction';
import { getUserProfileByCompanyCodeForSales } from './userSummaryAction';
import styles from './styles';
import { convertDataBookNowFixed } from '../../genericFunction/convertPackage';
import DynamicTitle from '../../components/common/DynamicTitle';
class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadCrumb: [],
      demo: {},
      isSales: false,
      modalCompanies: false,
      selectedCompany: { code: null, name: null },
      selectedUser: { code: null, name: null },
      searchCompany: '',
      activePage: 1,
      modalRegisCompany: false,
      modalListUser: false,
      modalAlert: false,
      modalAlertMessage: '',
      searchUser: '',
      dataSimpleCompany: {},
      packageType: '',
      tourNote: '',
    };
  }

  // ======== handle modal list companies =========
  handleModalCompanies = () =>
    this.setState({
      ...this.state,
      modalCompanies: !this.state.modalCompanies,
    });

  handlePickCompany = (e, { name, value }) => {
    let selectedUser = { code: null, name: null };
    this.setState(
      {
        ...this.state,
        [name]: value,
        modalCompanies: false,
        selectedUser,
      },
      () => this.handleModalListUser()
    );
  };

  getDemoPrice = () => {
    let { dataDemoPrice, demoJoinTour } = this.props;
    let {
      selectedCompany,
      selectedUser,
      modalListUser,
      packageType,
      tourNote,
    } = this.state;
    if (packageType !== 'fixed' && !modalListUser) {
      dataDemoPrice.CompanyCode = selectedCompany.code;
      dataDemoPrice.UserProfileId = selectedUser.code;
      dataDemoPrice.TourNote = tourNote;
      packageType !== 'ready-package-variable'
        ? this.props.postDemoPrice(dataDemoPrice)
        : this.getDemoJoinTour();

      // packageType !== 'ready-package-variable'
      //   ? (dataDemoPrice.CompanyCode = selectedCompany.code)
      //   : (dataDemoJoinTour.CompanyCode = selectedCompany.code);
      // selectedUser.code &&
      //   (packageType !== 'ready-package-variable'
      //     ? (dataDemoPrice.UserProfileId = selectedUser.code)
      //     : (dataDemoJoinTour.UserProfileId = selectedUser.code));
      // packageType !== 'ready-package-variable'
      //   ? this.props.postDemoPrice(dataDemoPrice)
      //   : this.props.postDemoJoinTourVariableDate(idTour, dataDemoJoinTour);
      selectedUser.code && (dataDemoPrice.UserProfileId = selectedUser.code);
      this.props.postDemoPrice(dataDemoPrice).then(() =>
        this.setState({
          ...this.state,
          tourNote: demoJoinTour.BookingDetailSum
            ? demoJoinTour.TourNote
            : dataDemoPrice.TourNote,
        })
      );
    }
  };
  handleChange = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: value, activePage: 1 });
  };
  pageChange = (e, { activePage }) => {
    this.setState({ ...this.state, activePage });
  };
  // ======== handle modal user list ==============

  handleModalListUser = () => {
    !this.state.modalListUser &&
      this.props.getUserProfileByCompanyCodeForSales(
        this.state.selectedCompany.code
      );
    this.setState(
      {
        ...this.state,
        modalListUser: !this.state.modalListUser,
        activePage: 1,
      },
      () =>
        this.state.packageType === 'ready-package-variable'
          ? this.getDemoJoinTour()
          : this.getDemoPrice()
    );
  };
  handlePickUser = (e, { name, value }) =>
    this.setState({ ...this.state, [name]: value, modalListUser: false }, () =>
      this.state.packageType === 'ready-package-variable'
        ? this.getDemoJoinTour()
        : this.getDemoPrice()
    );

  // ======== handle modal regis company ==========
  handleModalRegisCompany = () =>
    this.setState({
      ...this.state,
      modalRegisCompany: !this.state.modalRegisCompany,
    });

  componentDidUpdate = () => {
    if (this.props.postRegisterSimpleCustomerStatus === 'success') {
      let selectedCompany = { ...this.state.selectedCompany };
      let selectedUser = { ...this.state.selectedUser };
      selectedCompany.code = this.props.registerSimpleCustomer.Code;
      selectedCompany.name = this.state.dataSimpleCompany.CompanyName;
      this.setState({ ...this.state, selectedCompany, selectedUser }, () =>
        this.getDemoPrice()
      );
      this.props.resetRegisterSimpleCustomerStatus();
    } else if (this.props.postRegisterSimpleCustomerStatus === 'failed') {
      this.props.resetRegisterSimpleCustomerStatus();
    }
  };

  simpleRegisterCompany = dataSimpleCompany => {
    this.setState(
      {
        ...this.state,
        modalRegisCompany: false,
        dataSimpleCompany,
      },
      () =>
        this.props
          .postRegisterSimpleCustomer(dataSimpleCompany)
          .then(() => this.props.getCompanies('Customer'))
          .catch(err => {
            this.setState({
              ...this.state,
              modalAlert: true,
              modalAlertMessage: err.response.data.ModelState
                ? err.response.data.ModelState.message0[0]
                : err.response.data.Message,
            });
          })
    );
  };

  // -------- handle modal alert =================
  handleModalAlert = () =>
    this.setState({ ...this.state, modalAlert: !this.state.modalAlert });

  componentDidMount = () => {
    this.getPackageType();
    this.props.getCompanyProfile();
    this.props.getUserRoles().then(() => this.checkSales());
    // this.props.getUserRoles();
  };

  checkSales = () => {
    if (this.props.userRoles.Roles.some(e => e === 'Package Sales')) {
      this.setState({ ...this.state, isSales: true }, () =>
        this.props.getCompanies('Customer')
      );
    }
  };

  static propTypes = {
    match: PropTypes.object,
    loading: PropTypes.bool,
    demoJoinTour: PropTypes.object,
    fixedPackageById: PropTypes.object,
    // dataGuest: PropTypes.object,
    demoPrice: PropTypes.object,
    tourOperatorProfile: PropTypes.object,
    getCompanyProfile: PropTypes.func,
    companyProfile: PropTypes.object,
    loadingCompany: PropTypes.bool,
    idTour: PropTypes.number,
    guestList: PropTypes.array,
    getUserRoles: PropTypes.func,
    userRoles: PropTypes.object,
    loadingUser: PropTypes.bool,
    getCompanies: PropTypes.func,
    companies: PropTypes.array,
    postDemoPrice: PropTypes.func,
    dataDemoPrice: PropTypes.object,
    postRegisterSimpleCustomer: PropTypes.func,
    getUserProfileByCompanyCodeForSales: PropTypes.func,
    loadingRegister: PropTypes.bool,
    loadingGetUserProfileByCompany: PropTypes.bool,
    userProfileByCompany: PropTypes.array,
    registerSimpleCustomer: PropTypes.object,
    postRegisterSimpleCustomerStatus: PropTypes.string,
    resetRegisterSimpleCustomerStatus: PropTypes.func,
    loadingGetCompany: PropTypes.bool,
    dataDemoJoinTour: PropTypes.object,
    postDemoJoinTourVariableDate: PropTypes.func,
    dataTempTourCustomReady: PropTypes.object,
  };
  getPackageType() {
    let { packageType } = this.props.match.params;
    packageType = packageType === 'series' ? 'fixed' : packageType;
    let breadCrumb = [];
    let home = {
      key: 'Home',
      content: 'Home',
      link: true,
      active: false,
      href: '/',
    };
    let summary = {
      key: 'Summary Package',
      content: 'Summary Package',
      link: false,
      active: true,
    };
    switch (packageType) {
      case 'fixed':
        breadCrumb = [
          home,
          {
            key: 'Series Package',
            content: 'Series Package',
            active: false,
            link: true,
            href: '/series-package/' + this.props.idTour,
          },
          summary,
        ];
        break;
      case 'ready-package-variable':
        breadCrumb = [
          home,
          {
            key: 'Ready Package',
            content: 'Ready Package',
            active: false,
            link: true,
            href: '/ready/ready-package-variable/' + this.props.idTour,
          },
          summary,
        ];
        break;
      case 'ready':
        breadCrumb = [
          home,
          {
            key: 'Ready Package',
            content: 'Ready Package',
            active: false,
            link: true,
            href: '/ready/ready-package/',
          },
          summary,
        ];
        break;
      case 'similar':
        breadCrumb = [
          home,
          {
            key: 'Similar Package',
            content: 'Similar Package',
            active: false,
            link: true,
            href: '/ready/similar/',
          },
          summary,
        ];
        break;
      default:
        breadCrumb = [
          home,
          {
            key: 'Custom Package',
            content: 'Custom Package',
            active: false,
            link: true,
            href: '/custom-package/',
          },
          summary,
        ];
    }
    this.setState({
      breadCrumb,
      packageType,
      tourNote:
        packageType === 'fixed'
          ? this.props.dataDemoJoinTour
            ? this.props.dataDemoJoinTour.tourNote
            : ''
          : this.props.dataTempTourCustomReady
          ? this.props.dataTempTourCustomReady.TourNote
          : '',
    });
  }

  getDemoJoinTour = () => {
    let { dataDemoJoinTour, idTour } = this.props;
    let {
      selectedCompany,
      selectedUser,
      modalListUser,
      packageType,
      tourNote,
    } = this.state;
    if (packageType !== 'fixed' && !modalListUser) {
      let dataBookNow = convertDataBookNowFixed(
        false,
        false,
        dataDemoJoinTour.guests,
        dataDemoJoinTour.roomAllocation,
        [],
        dataDemoJoinTour.detailGuest,
        dataDemoJoinTour.TourNote
      );
      dataBookNow.CompanyCode = selectedCompany.code;
      selectedUser.code && (dataBookNow.UserProfileId = selectedUser.code);
      dataBookNow.StartTour = dataDemoJoinTour.startTour;
      dataBookNow.EndTour = dataDemoJoinTour.endTour;
      dataBookNow.TourOperatorProfileId = dataDemoJoinTour.tourOperatorId;
      dataBookNow.Title = dataDemoJoinTour.title;
      dataBookNow.TourNote = tourNote;
      this.props.postDemoJoinTourVariableDate(idTour, dataBookNow);
    }
  };
  render() {
    let {
      breadCrumb,
      isSales,
      modalCompanies,
      selectedCompany,
      searchCompany,
      activePage,
      modalRegisCompany,
      modalListUser,
      modalAlert,
      modalAlertMessage,
      searchUser,
      selectedUser,
      packageType,
      tourNote,
    } = this.state;
    let {
      loading,
      demoJoinTour,
      fixedPackageById, // dataGuest,
      tourOperatorProfile,
      companyProfile,
      loadingCompany,
      demoPrice,
      guestList,
      loadingUser,
      companies,
      loadingRegister,
      userProfileByCompany,
      loadingGetCompany,
    } = this.props;
    let demo =
      packageType === 'fixed' || packageType === 'ready-package-variable'
        ? demoJoinTour
        : demoPrice; //dataDummy;
    let filterCompanies = companies.filter(e =>
      searchCompany
        ? e.Name.toLowerCase().indexOf(searchCompany.toLowerCase()) !== -1
        : true
    );
    let filterUsers = userProfileByCompany.filter(e =>
      searchUser
        ? e.Username.toLowerCase().indexOf(searchUser.toLowerCase()) !== -1
        : true
    );
    let loadingAll = loading || loadingCompany || loadingRegister;
    return (
      <div style={styles.whiteCardColor}>
        <DynamicTitle title="Summary Package" />
        <BreadCrumb sections={breadCrumb} />
        <SummaryPackage
          packageType={packageType}
          demo={demo}
          packageById={packageType === 'fixed' ? fixedPackageById : {}}
          tourOperatorProfile={tourOperatorProfile}
          companyProfile={companyProfile}
          guestList={guestList}
          isSales={isSales}
          companies={filterCompanies}
          modalCompanies={modalCompanies}
          selectedCompany={selectedCompany}
          searchCompany={searchCompany}
          handleModalCompanies={this.handleModalCompanies}
          handlePickCompany={this.handlePickCompany}
          handlePickUser={this.handlePickUser}
          handleChange={this.handleChange}
          activePage={activePage}
          pageChange={this.pageChange}
          handleModalRegisCompany={this.handleModalRegisCompany}
          modalRegisCompany={modalRegisCompany}
          simpleRegisterCompany={this.simpleRegisterCompany}
          modalAlert={modalAlert}
          handleModalAlert={this.handleModalAlert}
          modalAlertMessage={modalAlertMessage}
          modalListUser={modalListUser}
          loadingGetUserProfileByCompany={loadingUser}
          userProfileByCompany={filterUsers}
          handleModalListUser={this.handleModalListUser}
          searchUser={searchUser}
          selectedUser={selectedUser}
          loadingGetCompany={loadingGetCompany}
          tourNote={tourNote}
        />
        <Loader description="Loading" active={loadingAll} page={true} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    loading: state.tourTransaction.loading,
    demoJoinTour: state.tourTransaction.demoJoinTour,
    fixedPackageById: state.fixedPackage.fixedPackageById,
    // dataGuest: state.temp.dataGuest,
    demoPrice: state.tourTransaction.demoPrice,
    tourOperatorProfile: state.tourOperator.tourOperatorProfile,
    companyProfile: state.company.companyProfile,
    loadingCompany: state.company.loading,
    idTour: state.temp.idTour,
    guestList: state.customPackage.guestList,
    userRoles: state.user.userRoles,
    loadingUser: state.userSummary.loadingGetUserProfileByCompany,
    companies: state.company.companies,
    dataDemoPrice: state.temp.dataDemoPrice,
    loadingRegister: state.createCustomer.loading,
    loadingGetUserProfileByCompany:
      state.userSummary.loadingGetUserProfileByCompany,
    userProfileByCompany: state.userSummary.userProfileByCompany,
    registerSimpleCustomer: state.createCustomer.registerSimpleCustomer,
    postRegisterSimpleCustomerStatus:
      state.createCustomer.postRegisterSimpleCustomerStatus,
    loadingGetCompany: state.company.loadingGetCompany,
    // ready fixed price
    dataDemoJoinTour: state.temp.dataDemoJoinTour,
    dataTempTourCustomReady: state.temp.dataTourCustomReady,
  };
};
export default connect(
  mapStateToProps,
  {
    getCompanyProfile,
    getUserRoles,
    getCompanies,
    postDemoPrice,
    postRegisterSimpleCustomer,
    getUserProfileByCompanyCodeForSales,
    resetRegisterSimpleCustomerStatus,
    postDemoJoinTourVariableDate,
  }
)(Summary);
