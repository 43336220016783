const handleValidation = (state, fields) => {
  const validate = {};
  fields.map(field => {
    if (state[field] === '') {
      validate[field] = {
        error: true,
        message: `Field can't be empty!!`,
      };
    } else if (state.userName.includes(' ')) {
      validate[field] = {
        error: true,
        message: `Username is not allowed to use spaces`,
      };
    } else {
      delete validate[field];
    }
    return validate;
  });
  return validate;
};

export default handleValidation;
