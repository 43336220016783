import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import styles from './styles';

class changePasswordButton extends Component {
  render() {
    const { onClick, contextstate, text } = this.props;
    return (
      <Button
        style={styles.backgroundGold}
        contextstate={contextstate}
        onClick={onClick}
      >
        <strong>{text}</strong>
      </Button>
    );
  }
}
changePasswordButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  contextstate: PropTypes.object,
};

export default changePasswordButton;
