const breadCrumb = [
  {
    key: 'Home',
    content: 'Home',
    active: false,
    link: true,
    href: '/',
  },
  {
    key: 'My booking',
    content: 'My booking',
    active: false,
    link: true,
    href: '/my-profile/booking',
  },
  {
    key: 'Confirmation Detail',
    content: 'Confirmation Detail',
    link: false,
    active: true,
  },
];

export { breadCrumb };
