import { GOLD_COLOR, RED_COLOR } from '../../../../../constant/colour';
const style = {
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  paddingSideTwenty: {
    padding: '10px 20px',
  },
  shadowTransparant: { boxShadow: '0 0 0 transparent' },
  backgroundGoldTransparent: {
    background: 'rgba(230, 202, 107, 0.1)',
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  textCenter: { textAlign: 'center' },
  labelItemStyle: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  colorRed: { color: RED_COLOR },
};

export default style;
