import apiClient from './apiClient';
import {
  GET_TOUR_TYPES_URL,
  GET_TOUR_PAX_TYPES_URL,
  GET_IDENTITY_TYPE_URL,
  GET_GUEST_TITLE_TYPE_URL,
  POST_DEMO_JOIN_TOUR_URL,
  POST_DEMO_PRICE_URL,
  POST_CREATE_TOUR_URL,
  POST_JOIN_TOUR_URL,
  GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_URL,
  POST_TOUR_TRANSACTIONS_CONFIRMATION_URL,
  POST_TOUR_TRANSACTIONS_TOP_UP_URL,
  GET_TOUR_TRANSACTION_BILLED_URL,
  POST_CREATE_TOUR_ON_BE_HALF_URL,
  POST_JOIN_TOUR_ON_BE_HALF_URL,
  GET_GUEST_LIST_TEMPLATE_URL,
} from './apiUrl';

const getTourTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_TOUR_TYPES_URL);
};

const getTourPaxTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_TOUR_PAX_TYPES_URL);
};

const getIdentityTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_IDENTITY_TYPE_URL);
};
const getGuestTitleTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_GUEST_TITLE_TYPE_URL);
};

const postDemoJoinTourApi = (id, data) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(`${POST_DEMO_JOIN_TOUR_URL}/${id}`, data);
};

const postDemoPriceApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_DEMO_PRICE_URL, data);
};

const postCreateTourApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_CREATE_TOUR_URL, data);
};

const postJoinTourApi = (id, data) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(`${POST_JOIN_TOUR_URL}/${id}`, data);
};

const postCreateTourOnBehalfApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_CREATE_TOUR_ON_BE_HALF_URL, data);
};

const postJoinTourOnBehalfApi = (id, data) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(`${POST_JOIN_TOUR_ON_BE_HALF_URL}/${id}`, data);
};

const getSupplementItemByFixedPackageIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_SUPPLEMENT_ITEM_BY_FIXED_PACKAGE_ID_URL}/${id}`);
};

const postTourTransactionsConfirmationDemoV2Api = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2_URL, data);
};

const postTourTransactionsConfirmationApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_TOUR_TRANSACTIONS_CONFIRMATION_URL, data);
};

const postTourTransactionsTopUpApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(POST_TOUR_TRANSACTIONS_TOP_UP_URL, data);
};

const getTourTransactionBilledApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_TOUR_TRANSACTION_BILLED_URL}/${id}`);
};

const getGuestListTemplateApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_GUEST_LIST_TEMPLATE_URL);
};

const postJoinTourVariableDateApi = (id, data) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(`${POST_JOIN_TOUR_URL}/VariableDate/${id}`, data);
};

const postDemoJoinTourVariableDateApi = (id, data) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(`${POST_DEMO_JOIN_TOUR_URL}/VariableDate/${id}`, data);
};

export {
  getTourTypesApi,
  getTourPaxTypesApi,
  getIdentityTypesApi,
  getGuestTitleTypesApi,
  postDemoJoinTourApi,
  postDemoPriceApi,
  postCreateTourApi,
  postJoinTourApi,
  getSupplementItemByFixedPackageIdApi,
  postTourTransactionsConfirmationDemoV2Api,
  postTourTransactionsConfirmationApi,
  postTourTransactionsTopUpApi,
  getTourTransactionBilledApi,
  postCreateTourOnBehalfApi,
  postJoinTourOnBehalfApi,
  getGuestListTemplateApi,
  postJoinTourVariableDateApi,
  postDemoJoinTourVariableDateApi,
};
