import * as types from '../actions/actionTypes';

const initialState = {
  userRoles: {},
  getUserRolesStatus: '',
  errors: {},
  loading: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_USER_ROLES
    //---------------------------------
    case types.GET_USER_ROLES:
      return { ...state };
    case types.GET_USER_ROLES_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_ROLES_FULFILLED:
      return {
        ...state,
        userRoles: action.payload.data,
        errors: {},
        getUserRolesStatus: 'success',
        loading: false,
      };
    case types.GET_USER_ROLES_REJECTED:
      return {
        ...state,
        userRoles: {},
        errors: action.payload.response.data,
        getUserRolesStatus: 'failed',
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
