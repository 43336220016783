import { GET_USER_PROFILE_BY_COMPANY_CODE } from './constant/actionTypes';
import {
  getUserProfileByCompanyCodeApi,
  getUserProfileByCompanyCodeForSalesApi,
} from './../../api/userApi';

const getUserProfileByCompanyCode = id => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE_BY_COMPANY_CODE,
      payload: getUserProfileByCompanyCodeApi(id),
    });
  };
};
const getUserProfileByCompanyCodeForSales = id => {
  return dispatch => {
    return dispatch({
      type: GET_USER_PROFILE_BY_COMPANY_CODE,
      payload: getUserProfileByCompanyCodeForSalesApi(id),
    });
  };
};
export { getUserProfileByCompanyCode, getUserProfileByCompanyCodeForSales };
