import {
  convertToStringTime,
  convertToStringDate,
  SumDays,
} from '../../../../genericFunction/itineraryBuilder/timeHelper';
import {
  // getTotalDays,
  addDate,
  getHourAndMinute,
  convertDateFormat,
} from '../../../../genericFunction/moment';
import { copyObject } from '../../../../genericFunction/itineraryBuilder/guest';
import {
  setDateArrival,
  setDateSummaryProgram,
  setDateDeparture,
} from '../../../../genericFunction/itineraryBuilder/dailyProgram';
const fixDateDailyProgram = (date, dailyProgram) => {
  let newDp = dailyProgram.map((daily, i) => {
    let newDate = convertToStringDate(SumDays(date, i));
    daily.Date = `${newDate}T00:00:00`;
    daily.Movements.map(move => {
      move.DateTime = `${newDate}${convertToStringTime(move.DateTime)}`;
      return move;
    });
    return daily;
  });
  return newDp;
};

const fixDateHeadlineProgram = (date, headlineProgram) => {
  let setHourAndMinuteDeparture = getHourAndMinute(
    headlineProgram.Departures[0].Date
  );
  let setHourAndMinuteReturns = getHourAndMinute(
    headlineProgram.Returns[0].Date
  );
  // let totalDay = getTotalDays(
  //   headlineProgram.Departures[0].Date,
  //   headlineProgram.Returns[0].Date,
  //   true
  // );
  let endDate = addDate(date, headlineProgram.Returns[0].Day - 1, 'd');
  headlineProgram.Departures[0].Date = `${convertDateFormat(
    date,
    'YYYY-MM-DD'
  )}T${setHourAndMinuteDeparture}:00`;
  headlineProgram.Returns[0].Date = `${convertDateFormat(
    endDate,
    'YYYY-MM-DD'
  )}T${setHourAndMinuteReturns}:00`;

  headlineProgram.MainPrograms.map(item => {
    let lastDate = date;
    item.Date = lastDate;
    item.LeavingDate = addDate(lastDate, item.TotalDays - 1, 'd');
    let setHourAndMinuteCheckIn = getHourAndMinute(
      item.AccommodationSummary.CheckIn
    );
    let setHourAndMinuteCheckOut = getHourAndMinute(
      item.AccommodationSummary.CheckOut
    );
    item.AccommodationSummary.DateTime = lastDate;
    item.AccommodationSummary.CheckIn = `${convertDateFormat(
      lastDate,
      'YYYY-MM-DD'
    )}T${setHourAndMinuteCheckIn}:00`;
    item.AccommodationSummary.CheckOut = `${convertDateFormat(
      addDate(date, item.TotalDays - 1, 'd'),
      'YYYY-MM-DD'
    )}T${setHourAndMinuteCheckOut}:00`;
    lastDate = addDate(date, item.TotalDays - 1, 'd');
    return item;
  });
  return headlineProgram;
};

const setHeadlineFixedPrice = (date, headlineProgram) => {
  let headLineProg = copyObject(headlineProgram);
  let setHourAndMinuteDeparture = getHourAndMinute(
    headlineProgram.Departures[0].Date
  );
  let newDate = `${convertDateFormat(
    date,
    'YYYY-MM-DD'
  )}T${setHourAndMinuteDeparture}:00`;
  let newArrival = setDateArrival(headlineProgram.Departures[0], newDate);
  let newMainProg = setDateSummaryProgram(
    newArrival,
    headLineProg.MainPrograms
  );
  let newDeparture = setDateDeparture(headLineProg.Returns[0], newMainProg);
  headLineProg.Departures[0] = newArrival;
  headLineProg.MainPrograms = newMainProg;
  headLineProg.Returns[0] = newDeparture;
  return headLineProg;
};

export { fixDateDailyProgram, fixDateHeadlineProgram, setHeadlineFixedPrice };
