import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Header, Table } from 'semantic-ui-react';
import styles from './styles';
import Map from '../shared/components/map/Maps';
import { useTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div style={styles.marginBot50}>
      <DynamicTitle title="Contact Us" />
      <Container textAlign="center" style={styles.title}>
        {/* CONTACT US */}
        <h2>{t('ContactUs')} </h2>
      </Container>
      <Container>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <div id="map" style={styles.width100height300}>
                <Map
                  mapStyle={styles.width100height300}
                  lat={1.281731}
                  lng={103.851008}
                />
              </div>
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Connect World PTE.LTD</Header>
              <Table basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      {/* Address */}
                      <strong>{t('address')} </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      : 16 Raffles Quay, #33-02 Hong Leong Building, Singapore
                      048581
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      {/* General Inquiry */}
                      <strong>{t('generalInquiry')} </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      : info@touress.com
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      {/* Sales & Marketing */}
                      <strong>{t('salesAndMarketing')} </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      : sales@touress.com
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      {/* Customer Service */}
                      <strong>{t('customerService')} </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      : cs@touress.com
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      {/* Phone */}
                      <strong>{t('phone')} </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      : +622130053666 <br />
                      <span style={styles.marginLeft6}> +6531636895</span>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      {/* 24 Hours Hotline */}
                      <strong>{t('24HoursHotline')}</strong>
                    </Table.Cell>
                    <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                      : +6531636895
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

ContactUs.propTypes = {
  t: PropTypes.func,
};

export default ContactUs;
