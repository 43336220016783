import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Icon, Button, Message } from 'semantic-ui-react';
import Styles from './styles';

import { useTranslation } from 'react-i18next';

const RoomService = props => {
  const { t } = useTranslation();
  let { name, disable, handleChange, service, text } = props;
  let styleMessage, styleHeader, styleIcon, styleButton;
  if (disable) {
    styleHeader = Styles.greyColor;
    styleIcon = Styles.greyColor;
    styleButton = Styles.buttonChooseRoomDisable;
    styleMessage = Styles.messageDisable;
  } else {
    styleHeader = Styles.blackColor;
    styleIcon = Styles.goldColor;
    styleButton = Styles.buttonChooseRoom;
    styleMessage = Styles.message;
  }

  return (
    <Grid.Column disable width="4">
      <Message warning style={styleMessage}>
        <Grid>
          <Grid.Column width="16" style={Styles.paddingthree}>
            <Header as="h5" textAlign="center" style={styleHeader}>
              {/* {name} */}
              {text}
            </Header>
          </Grid.Column>
          <Grid.Column width="16" style={Styles.columnRoomService}>
            {name === 'Room Only' ? (
              <Icon name="bed" size="big" style={styleIcon} />
            ) : name === 'With Breakfast' ? (
              <div>
                <Icon name="bed" size="big" style={styleIcon} />
                <Icon name="food" size="big" style={styleIcon} />
              </div>
            ) : name === 'Half Board' ? (
              <div style={styleIcon}>
                <Icon name="bed" size="big" style={styleIcon} />
                <Icon name="food" size="big" style={styleIcon} />
                2x
              </div>
            ) : name === 'Full Board' ? (
              <div style={styleIcon}>
                <Icon name="bed" size="big" style={styleIcon} />
                <Icon name="food" size="big" style={styleIcon} />
                3x
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column width="16" style={Styles.textCenterPadding3px}>
            <Button
              name="room"
              value={name}
              service={service}
              size="small"
              disabled={disable}
              style={styleButton}
              onClick={handleChange}
            >
              {/* CHOOSE ROOM */}
              {t('ChooseRoom')}
            </Button>
          </Grid.Column>
        </Grid>
      </Message>
    </Grid.Column>
  );
};

RoomService.propTypes = {
  name: PropTypes.string,
  disable: PropTypes.bool,
  desc: PropTypes.string,
  handleChange: PropTypes.func,
  service: PropTypes.string,
  t: PropTypes.func,
  text: PropTypes.string,
};
export default RoomService;
