import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Icon } from 'semantic-ui-react';
import ImageCard from '../../../card/ImageCard';
import styles from './styles';

const CardHeader = props => {
  let { name, location, images, image } = props;
  return (
    <Segment.Group horizontal>
      <ImageCard images={images} image={image} isPromo={false} size="270px" />
      <Segment style={styles.noBorder}>
        <Grid style={styles.marginTopBottom3pxLeft3px}>
          <Header as="h3" style={styles.goldColor}>
            {name}
          </Header>
          {location && (
            <Grid.Row style={styles.noPaddingTop}>
              <Grid.Column width="1">
                <Icon name="map marker alternate" style={styles.goldColor} />
              </Grid.Column>
              <Grid.Column width="14">
                <p>{location}</p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Segment>
    </Segment.Group>
  );
};
CardHeader.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
  images: PropTypes.array,
  image: PropTypes.string,
};
export default CardHeader;
