const BACKGROUND_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';

const style = {
  backgroundPrimary: {
    background: BACKGROUND_COLOR,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  goldColor: {
    color: '#e6ca6b',
  },
  borderGold: {
    border: '1px solid #e6ca6b',
  },
  inputTimeButtonStyle: {
    color: GOLD_COLOR,
    background: BACKGROUND_COLOR,
    border: `2px solid ${GOLD_COLOR}`,
  },
  // ====================== input amount =========================
  inputStyleAmount: {
    margin: 0,
    cursor: 'pointer',
    borderLeft: 'none',
    borderRight: 'none',
  },
  labelAmount: {
    margin: 0,
    cursor: 'pointer',
    borderRight: 'none',
  },
  noMargin: {
    margin: 0,
  },
  inputAmount: {
    textAlign: 'center',
    paddingRight: '4px',
    paddingLeft: '4px',
  },
  labelAmountPlus: {
    margin: 0,
    cursor: 'pointer',
    borderLeft: 'none',
  },
  borderDatePicker: {
    border: '2px solid black',
  },
  lableDurationPlus: {
    margin: 0,
    cursor: 'pointer',
    borderLeft: 'none',
    borderRight: 'none',
  },
  textCenter: {
    textAlign: 'center',
  },
  noPadding: {
    padding: 0,
  },
  boxShadowTransparent: {
    boxShadow: '0 0 0 transparent',
  },
  marginTop47pxAbsolute: {
    position: 'absolute',
    marginTop: '47px',
  },
  timeBlack: { color: '#000' },
};

export default style;
