import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { noImageTransparant } from './../../../../assets/image';
import ProgressiveImage from 'react-progressive-image-loading';

const bgImage = imgUrl => {
  return imgUrl
    ? {
        width: '150PX',
        // height: '160px',
        flex: 'none',
        background: `url("${imgUrl}")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderBottom: '0px',
        border: 'none',
      }
    : {
        width: '150px',
        // height: '160px',
        flex: 'none',
        background: `url("${noImageTransparant}")`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        border: '0px',
      };
};

const CardImage = props => (
  <ProgressiveImage
    preview={
      props.image ? props.image.replace(/\s/g, '%20') : noImageTransparant
    }
    src={props.image ? props.image.replace(/\s/g, '%20') : noImageTransparant}
    render={(src, style) => (
      <Segment
        style={Object.assign(
          style,
          bgImage(props.image ? props.image.replace(/\s/g, '%20') : '')
        )}
      />
    )}
  />
);

CardImage.propTypes = {
  image: PropTypes.string,
};

export default CardImage;
