// const listDuration = [
//   { Id: '1', Name: '1 to 3', Days: [1, 2, 3] },
//   { Id: '2', Name: '4 to 6', Days: [4, 5, 6] },
//   { Id: '3', Name: '7 to 9', Days: [7, 8, 9] },
//   { Id: '4', Name: '10 to 12', Days: [10, 11, 12] },
//   { Id: '5', Name: '13 more', Days: [13] },
// ];

// export default listDuration;

const listDuration = [
  { Id: '1', Name: '1 - 3', Days: [1, 2, 3] },
  { Id: '2', Name: '4 - 6', Days: [4, 5, 6] },
  { Id: '3', Name: '7 - 9', Days: [7, 8, 9] },
  { Id: '4', Name: '10 - 12', Days: [10, 11, 12] },
  { Id: '5', Name: '13 +', Days: [13] },
];

export default listDuration;
