import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from './translations/en.json';
import common_id from './translations/id.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: common_en,
  id: common_id,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    whitelist: ['en', 'id'],
  });

export default i18n;
