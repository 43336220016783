import React from 'react';
import { Container } from 'semantic-ui-react';
import styles from './styles';
import DynamicTitle from './DynamicTitle';
const comingSoon = () => (
  <div>
    <DynamicTitle title="Coming Soon" />
    <Container textAlign="center" style={styles.comingSoonstyle}>
      <div>
        <h1> Coming Soon </h1>
      </div>
    </Container>
  </div>
);

export default comingSoon;
