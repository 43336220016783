import { generateDemoPrice } from '../../../../genericFunction/itineraryBuilder/demoPrice';
const getDemoPriceObj = (roomAllocation, HeadlineProgram, dailyProgram) => {
  let demoPrice = generateDemoPrice(
    null,
    roomAllocation,
    null,
    null,
    null,
    null,
    null,
    HeadlineProgram,
    dailyProgram,
    [],
    null,
    null,
    [],
    null,
    null,
    null
  );
  let newDemoPriceObj = {
    RoomAllocation: demoPrice.RoomAllocation,
    Attractions: demoPrice.Attractions,
    Transportations: demoPrice.Transportations,
    Accommodations: demoPrice.Accommodations.filter(item => item.ServiceItemId),
    Restaurants: demoPrice.Restaurants,
    TourGuides: [],
    AdditionalServices: [],
    FlightTickets: [],
    Movements: demoPrice.Movements,
  };
  return newDemoPriceObj;
};

export default getDemoPriceObj;
