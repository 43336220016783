import React from 'react';
import { Modal, Grid, Header } from 'semantic-ui-react';
import FixedDailyProgram from '../../../shared/components/dailyProgram/DailyProgram';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { useTranslation } from 'react-i18next';
// import { FIXED } from '../../../../constant/packageTypes';
const GuestListModal = props => {
  const { t } = useTranslation();
  let { dailyPrograms, isOpen, handleButton, currentPackage } = props;
  return (
    <Modal
      name="openModalItinerary"
      open={isOpen}
      onClose={handleButton}
      size="small"
      closeOnDimmerClick
      closeIcon
    >
      <Grid style={styles.margin10px}>
        <Grid.Row>
          <Grid.Column width={16} style={styles.marginTop10Bottom5px}>
            <Header as="h2">
              {/* Itinerary Detail */} {t('itineraryDetail')}
            </Header>
          </Grid.Column>
          <Grid.Column width={16}>
            <FixedDailyProgram
              currentPackage={currentPackage}
              dailyProgram={dailyPrograms}
              isStatic={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* </Modal.Content> */}
    </Modal>
  );
};
GuestListModal.propTypes = {
  dailyPrograms: PropTypes.array,
  handleButton: PropTypes.func,
  isOpen: PropTypes.bool,
  currentPackage: PropTypes.string,
  t: PropTypes.func,
};
export default GuestListModal;
