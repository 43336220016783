import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Button, Header } from 'semantic-ui-react';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const FilterHeader = props => {
  const { t } = useTranslation();
  const { header, resetFilter } = props;
  return (
    <Card.Content>
      <Grid columns="equal">
        <Grid.Column>
          <Header as="h3">{header}</Header>
        </Grid.Column>
        <Grid.Column style={styles.goldColor}>
          <Button
            floated="right"
            style={styles.buttonTransparent}
            onClick={resetFilter}
          >
            <Header as="h3" style={styles.goldColor}>
              {/* Reset */}
              {t('reset')}
            </Header>
          </Button>
        </Grid.Column>
      </Grid>
    </Card.Content>
  );
};

FilterHeader.propTypes = {
  header: PropTypes.string,
  resetFilter: PropTypes.func,
  t: PropTypes.func,
};

export default FilterHeader;
