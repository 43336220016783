import * as types from './constant/actionTypes';

const initialState = {
  errors: {},
  loading: false,
  createCompanyUserStatus: '',
  userCompanyList: [],
  getUserCompanyListStatus: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // POST_REGISTER_COMPANY_USER
    //---------------------------------
    case types.POST_REGISTER_COMPANY_USER:
      return { ...state };
    case types.POST_REGISTER_COMPANY_USER_PENDING:
      return { ...state, loading: true };
    case types.POST_REGISTER_COMPANY_USER_FULFILLED:
      return {
        ...state,
        errors: {},
        loading: false,
        createCompanyUserStatus: 'success',
      };
    case types.POST_REGISTER_COMPANY_USER_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loading: false,
        createCompanyUserStatus: 'failed',
      };
    case types.RESET_REGISTER_COMPANY_USER_STATUS:
      return {
        ...state,
        createCompanyUserStatus: '',
      };
    //---------------------------------
    // GET_USER_COMPANY_LIST
    //---------------------------------
    case types.GET_USER_COMPANY_LIST:
      return { ...state };
    case types.GET_USER_COMPANY_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_COMPANY_LIST_FULFILLED:
      return {
        ...state,
        userCompanyList: action.payload.data,
        errors: {},
        loading: false,
        getUserCompanyListStatus: 'success',
      };
    case types.GET_USER_COMPANY_LIST_REJECTED:
      return {
        ...state,
        errors: action.payload.response.data,
        loading: false,
        getUserCompanyListStatus: 'failed',
      };
    default:
      return state;
  }
};

export default reducer;
