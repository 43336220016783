import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Header, Button } from 'semantic-ui-react';
import styles from '../../styles';
import { convertDateFormat } from '../../../../genericFunction/moment';
import { CardImage } from '../../../shared/components/common';

import { withTranslation } from 'react-i18next';

// const bgImage = imgUrl => {
//   return {
//     width: '150PX',
//     height: '200px',
//     flex: 'none',
//     background: 'url(' + imgUrl + ')',
//     backgroundSize: 'cover',
//     borderBottom: '0px',
//   };
// };
class ExcursionCard extends Component {
  render() {
    const { t } = this.props;
    let { handleButtonInfo, excursion, ticket, day, date } = this.props;
    let image = excursion
      ? excursion.ObjectImages.length !== 0
        ? excursion.ObjectImages.find(item => item.IsPrimaryImage === true)
          ? excursion.ObjectImages.find(item => item.IsPrimaryImage === true)
              .ImageUrl
          : excursion.ImageUrl
        : excursion.ImageUrl
      : '';
    let category = excursion ? excursion.AttractionCategory : '';
    let name = excursion ? excursion.Name : '';
    return (
      <Grid.Row style={styles.padding0pxMarginRight}>
        <Segment.Group horizontal style={styles.segmentNoPadding}>
          <CardImage image={image} />
          {/* <Segment style={bgImage(image ? image : noImage)} /> */}
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  width={2}
                  style={styles.paddingTop2pxLeftRight15px}
                >
                  <Button
                    circular
                    icon="info circle"
                    style={styles.buttonIconInfo}
                    size="big"
                    onClick={handleButtonInfo}
                    name="openDetail"
                    value={excursion}
                  />
                </Grid.Column>
                <Grid.Column width={14} style={styles.padding2px}>
                  <Header
                    as="h3"
                    style={styles.goldColor}
                    className="margin-top-ten margin-bottom-ten no-padding"
                  >
                    {name}
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {category} {t('excursion')}
                    {/* Excursion */}
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {'Day ' +
                      day +
                      ', ' +
                      convertDateFormat(date, 'DD MMM YYYY')}
                  </Header>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Header as="h5" style={styles.marginTopBottom5px}>
                    {t('ticket')} :{/* Ticket : */}
                  </Header>
                  {ticket.length !== 0 &&
                    ticket.map((e, index) => (
                      <span key={index}>
                        {e.qty + ' ' + e.desc.split('_').join(' ') + ', '}
                      </span>
                    ))}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Segment.Group>
      </Grid.Row>
    );
  }
}
ExcursionCard.propTypes = {
  excursion: PropTypes.object,
  ticket: PropTypes.array,
  day: PropTypes.number,
  date: PropTypes.string,
  handleButtonInfo: PropTypes.func,
  t: PropTypes.func,
};
export default withTranslation()(ExcursionCard);
