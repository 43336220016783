const BACKGROUND_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const RED_COLOR = '#f44336';
const WHITE_COLOR = '#fefefe';
const BLUE_COLOR = '#6b82e6';

const style = {
  header: {
    background: BACKGROUND_COLOR,
    borderRadius: 0,
    borderRight: 0,
    borderTop: 0,
    borderLeft: 0,
    borderBottom: 0,
    marginBottom: 0,
    height: '55px',
  },
  logo: {
    width: '180px',
    paddingBottom: '5px',
  },
  item: {
    background: BACKGROUND_COLOR,
  },
  container: {
    background: BACKGROUND_COLOR,
  },
  footerMenu: {
    background: BACKGROUND_COLOR,
  },
  copyright: {
    color: 'white',
    marginBottom: '15px',
    marginTop: '20px',
  },
  columnMenu: {
    color: 'white',
    fontSize: '18px',
    marginBottom: '10px',
    marginTop: '15px',
  },
  link: {
    color: 'white',
    marginBottom: '10px',
  },
  lineDivider: {
    marginTop: '20px',
    marginBottom: '20px',
    color: 'white',
    border: '0',
    borderTop: '1px solid #cfcfe0',
  },
  backgroundPrimary: {
    background: BACKGROUND_COLOR,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  buttonNegative: {
    background: RED_COLOR,
    color: WHITE_COLOR,
  },
  buttonPositive: {
    background: BLUE_COLOR,
    color: WHITE_COLOR,
  },
  goldGradient: {
    borderColor: '#fffc9b',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  },
  noPadding: {
    padding: '0px',
  },
  paddingTen: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  goldRibbon: {
    color: 'white',
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  },
  comingSoonstyle: {
    fontSize: '30px',
    marginTop: '200px',
    marginBottom: '200px',
  },
  marginBottom20: { marginBottom: '20px' },
  customPackageShortcutstyle: { cursor: 'pointer', color: '#000000de' },
  margin4: { margin: '4px' },
  textAlignRight: { textAlign: 'right' },
  paddingTop3left5: { paddingTop: '3px', paddingLeft: '5px' },
  paddingTop3left15: { paddingTop: '3px', paddingLeft: '15px' },
  margin03: { margin: '0px 3px' },
  borderNone: { border: 'none' },
  goldLabel: {
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
    color: 'white',
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  goldPartnerHeader: {
    color: '#e6ca6b',
    marginTop: '2px',
    marginLeft: '10px',
  },
};

export default style;
