import apiClient from './apiClient';
import { GET_SCHEDULE_URL } from './apiUrl';

const getScheduleApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_SCHEDULE_URL}?bookingTemplateId=${id}`);
};

export { getScheduleApi };
