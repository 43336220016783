import { useState, useEffect } from 'react';
import optionsConverter from '../../../genericFunction/selectOptionsConverter';

const useApiCall = (api, des) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  //   fetch('https://swapi.co/api/people')
  useEffect(() => {
    setIsLoading(true);
    api()
      .then(result => {
        let optionsData = result.data.map(data =>
          optionsConverter(data.Id, data.Id, data[des])
        );
        setIsLoading(false);
        setFetchedData(optionsData);
      })
      .catch(err => {
        //eslint-disable-next-line
        console.log(err);
        setIsLoading(false);
      });
    //eslint-disable-next-line
  }, []);

  return [isLoading, fetchedData];
};

export default useApiCall;
