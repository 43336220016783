import * as types from '../actions/actionTypes';

const initialState = {
  countries: [],
  getCountriesStatus: '',
  loading: false,
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_COUNTRIES
    //---------------------------------
    case types.GET_COUNTRIES:
      return { ...state };
    case types.GET_COUNTRIES_PENDING:
      return { ...state, loading: true };
    case types.GET_COUNTRIES_FULFILLED:
      return {
        ...state,
        countries: action.payload.data,
        loading: false,
        errors: {},
        getCountriesStatus: 'success',
      };
    case types.GET_COUNTRIES_REJECTED:
      return {
        ...state,
        countries: [],
        loading: false,
        errors: action.payload.response.data,
        getCountriesStatus: 'failed',
      };
    case types.RESET_STATUS_GET_COUNTRIES:
      return {
        ...state,
        getCountriesStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
