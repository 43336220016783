import { GOLD_COLOR, BACKGROUND_COLOR } from '../../../../../constant/colour';
const style = {
  goldColor: { color: GOLD_COLOR },
  noDisplay: {
    display: 'none',
  },
  fixedMenuStyle: {
    backgroundColor: BACKGROUND_COLOR,
    border: `1px solid ${BACKGROUND_COLOR}`,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    color: GOLD_COLOR,
  },
  buttonBookNowStyle: {
    background: BACKGROUND_COLOR,
    color: GOLD_COLOR,
    border: `1px solid ${GOLD_COLOR}`,
  },
  paddingFive: { padding: '5px' },
  menuPrice: {
    backgroundColor: GOLD_COLOR,
    border: `1px solid ${BACKGROUND_COLOR}`,
  },
  menuContainer: { fontSize: '16px', justifyContent: 'center' },
  zIndex1: { zIndex: 1 },
};

export default style;
