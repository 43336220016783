import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Grid, Button } from 'semantic-ui-react';
import HeaderWithIcon from '../../../../../components/common/headerWithIcon';
import styles from './styles';
import { Collapse } from 'react-collapse';
import CollapseButton from '../../../../../components/button/BtnCollapse';
import TourOperatorPanel from './TourOperatorPanel';
import ModalTourOperator from './modalTourOperator/ModalTourOperator';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class TourOperator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
      openModal: false,
    };
  }
  handleCollapse = () => {
    let isCollapse = this.state.isCollapse;
    isCollapse = !isCollapse;
    this.setState({ ...this.state, isCollapse: isCollapse });
  };
  handleOffer = () => {
    this.props.generateTourOperator(true);
    this.handleOpen();
  };
  handleOpen = () => {
    this.setState({ ...this.state, openModal: true });
  };
  closeModal = () => {
    this.setState({ ...this.state, openModal: false });
  };
  render() {
    const { t } = this.props;
    // let { openModal } = this.state;
    let {
      tourOperator,
      tourOperatorList,
      // tourOperatorProfileList,
      setTourOperator,
      isFixPrice,
    } = this.props;
    let data = tourOperatorList.find(
      item => item.TourProfileId === tourOperator
    );
    // let profile = tourOperatorProfileList.find(
    //   item => item.Id === tourOperator
    // );
    return (
      <div>
        <Segment style={styles.segment}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={14}>
                {/* Tour Operator */}
                <HeaderWithIcon
                  as="h2"
                  title={`${t('tourOperator')}`}
                  icon="user"
                />
              </Grid.Column>
              <Grid.Column width={2} textAlign="right">
                <CollapseButton
                  handleCollapse={this.handleCollapse}
                  style={styles.shadowTransparant}
                  isCollapse={this.state.isCollapse}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Collapse isOpened={this.state.isCollapse}>
            <Grid textAlign="center" style={styles.paddingSideTwenty}>
              <Grid.Row>
                <Grid.Column>
                  {tourOperator && data && (
                    <TourOperatorPanel
                      tourOperator={tourOperator}
                      data={data}
                      profile={data.TourOperatorProfile}
                      noPrice={true}
                      isFixPrice={isFixPrice}
                    />
                  )}
                  {/* Have you finished your arrangement? */}
                  <Header as="h3">{t('haveYouFinishedYourArrangement')}</Header>
                  <Button
                    onClick={this.handleOffer}
                    style={styles.backgroundGold}
                  >
                    {/* 'Change Operator' : 'Yes, Send me offers' */}
                    {tourOperator
                      ? `${t('changeOperator')}`
                      : `${t('yesSendMeOffers')}`}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Collapse>
        </Segment>
        <ModalTourOperator
          open={this.props.openModal}
          closeModal={this.props.closeModal}
          setTourOperator={setTourOperator}
          isFixPrice={isFixPrice}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    tourOperatorList: state.tourOperator.tourOperatorList,
    tourOperatorProfileList: state.tourOperator.tourOperatorProfileList,
  };
};
TourOperator.propTypes = {
  tourOperatorProfileList: PropTypes.array,
  tourOperatorList: PropTypes.array,
  generateTourOperator: PropTypes.func,
  tourOperator: PropTypes.number,
  setTourOperator: PropTypes.func,
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  isFixPrice: PropTypes.bool,
  t: PropTypes.func,
};
export default connect(mapStateToProps)(withTranslation()(TourOperator));
