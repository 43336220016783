import {
  GET_FIXED_PACKAGE_ALL,
  RESET_GET_FIXED_PACKAGE_ALL_STATUS,
  GET_FIXED_PACKAGE_BY_ID,
  GET_FIXED_PACKAGE_LANDING_PAGE,
  RESET_GET_FIXED_PACKAGE_BY_ID_STATUS,
  RESET_FIXED_PACKAGE_LANDING_PAGE_STATUS,
  GET_FEATURED_PACKAGE_BEFORE_LOGIN,
  GET_FEATURED_PACKAGE_AFTER_LOGIN,
} from './actionTypes';
import {
  getFixedPackageAllApi,
  getFixedPackageByIdApi,
  getFixedPackageLandingPageApi,
  getFeaturedPackageAfterLoginApi,
  getFeaturedPackageBeforeLoginApi,
} from '../../../api/fixedPackageApi';

const getFixedPackageAll = data => {
  return dispatch => {
    return dispatch({
      type: GET_FIXED_PACKAGE_ALL,
      payload: getFixedPackageAllApi(data),
    });
  };
};

const getFixedPackageLandingPage = country => {
  return dispatch => {
    return dispatch({
      type: GET_FIXED_PACKAGE_LANDING_PAGE,
      payload: getFixedPackageLandingPageApi(country),
    });
  };
};

const resetFixedPackageLandingPageStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_FIXED_PACKAGE_LANDING_PAGE_STATUS });
  };
};

const resetGetFixedPackageAllStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_FIXED_PACKAGE_ALL_STATUS });
  };
};

const getFixedPackageById = (packageType, id) => {
  return dispatch => {
    return dispatch({
      type: GET_FIXED_PACKAGE_BY_ID,
      payload: getFixedPackageByIdApi(packageType, id),
    });
  };
};

const resetGetFixedPackageByIdStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_FIXED_PACKAGE_BY_ID_STATUS });
  };
};

const getFeaturedPackageBeforeLoginAction = country => {
  return dispatch => {
    return dispatch({
      type: GET_FEATURED_PACKAGE_BEFORE_LOGIN,
      payload: getFeaturedPackageBeforeLoginApi(country),
    });
  };
};

const getFeaturedPackageAfterLoginAction = () => {
  return dispatch => {
    return dispatch({
      type: GET_FEATURED_PACKAGE_AFTER_LOGIN,
      payload: getFeaturedPackageAfterLoginApi(),
    });
  };
};

export {
  getFixedPackageAll,
  resetGetFixedPackageAllStatus,
  getFixedPackageById,
  getFixedPackageLandingPage,
  resetGetFixedPackageByIdStatus,
  resetFixedPackageLandingPageStatus,
  getFeaturedPackageBeforeLoginAction,
  getFeaturedPackageAfterLoginAction,
};
