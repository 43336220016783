import React from 'react';
import PropTypes from 'prop-types';
import { Image, Label } from 'semantic-ui-react';
import image from '../../../../assets/image/view_on_map.png';
import styles from '../dailyProgram/modal/modalListExcursion/styles';

const MapCard = props => {
  const { handleOpenMap, labelText, labelStyle } = props;
  return (
    <Image
      src={image}
      rounded
      style={styles.mapImageStyle}
      onClick={handleOpenMap}
      label={
        <Label onClick={handleOpenMap} style={labelStyle}>
          {labelText}
        </Label>
      }
    />
  );
};
MapCard.propTypes = {
  handleOpenMap: PropTypes.func,
  labelText: PropTypes.string,
  labelStyle: PropTypes.object,
};
export default MapCard;
