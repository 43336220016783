import React, { useState, useEffect } from 'react';
import { Menu, Image, Container } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import { touressLogoWhiteS } from './../../../../assets/logo';
import { LogInHeaderMenu, LogOutHeaderMenu } from './';
import styles from './styles';
import {
  logout,
  resetLoginStatus,
  setExpiredToken,
  changeLoginFlag,
  resetLogoutStatus,
} from '../../../shared/actions/authenticationAction';
import { useSelector, useDispatch } from 'react-redux';
import Proptypes from 'prop-types';
import { getUserInfo } from './userHeaderAction';
import { ModalAlert } from '../modal';
import Loader from '../../../../components/common/loader';
import { ModalLogin } from '../modal/';
import { optionLanguage } from './../../constant/optionLanguage';
import isPathNoNeedAuth from './services/helper/getPathName';
import { useTranslation } from 'react-i18next';
import { getCompanyProfile } from '../../actions/companyAction';

const Header = props => {
  const { i18n, t } = useTranslation();
  //==============initial state=============
  const [language, setLanguage] = useState(optionLanguage[0].text);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [loginPopup, setLoginPopup] = useState(false);
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [backHome, setBackHome] = useState(false);
  const userName = useSelector(state => state.userHeader.userInfo.Username);
  const getUserInfoStatus = useSelector(
    state => state.userHeader.getUserInfoStatus
  );
  const login_status = useSelector(state => state.authentication.login_status);
  const descriptionLogin = useSelector(
    state => state.authentication.descriptionLogin
  );
  const loading = useSelector(state => state.authentication.loading);
  const expiredToken = useSelector(state => state.authentication.expiredToken);
  const logout_status = useSelector(
    state => state.authentication.logout_status
  );
  //=======actions================
  const dispatch = useDispatch();
  const logoutAction = () => dispatch(logout());
  const resetLoginStatusAction = () => dispatch(resetLoginStatus());
  const setExpiredTokenAction = expiredDateToken =>
    dispatch(setExpiredToken(expiredDateToken));
  const changeLoginFlagAction = () => dispatch(changeLoginFlag());
  const getUserInfoAction = () => dispatch(getUserInfo());
  const resetLogoutStatusAction = () => dispatch(resetLogoutStatus());
  const getCompanyProfileAction = () => dispatch(getCompanyProfile());
  //============lifecycle===============
  //===========get user info jika sukss login==========
  useEffect(() => {
    if (userName && localStorage.getItem('token') && getUserInfoStatus === '') {
      helperGetUserInfo();
      closePopup();
    }
    // eslint-disable-next-line
  }, [userName, getUserInfoStatus]);

  //==========jika user expired login=========
  useEffect(() => {
    if (login_status === 'success') {
      handleOpenModalAlert(`${t('successLogin')}`); // You have successfully login!
      resetLoginStatusAction();
      getUserInfoAction();
      getCompanyProfileAction();
    } else if (login_status === 'failed') {
      handleOpenModalAlert(descriptionLogin.error_description);
      resetLoginStatusAction();
    }
    // eslint-disable-next-line
  }, [login_status]);

  useEffect(() => {
    if (
      expiredToken &&
      new Date(expiredToken).getTime() < new Date().getTime() &&
      props.location.pathname !== '/'
    ) {
      handleOpenModalAlert(`${t('loginSessionExpire')}`); // Your Login session has been expired
    }
    // eslint-disable-next-line
  }, [expiredToken]);
  //CDU
  useEffect(() => {
    if (!userName && localStorage.getItem('token')) {
      helperGetUserInfo();
      changeLoginFlagAction(true);
    }
    if (
      !!localStorage.getItem('token') === false &&
      isPathNoNeedAuth(props.location.pathname)
    ) {
      setOpenModalLogin(true);
    }
    helperSetExpiredToken();
    helperSetLanguage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (logout_status === 'success') {
      handleOpenModalAlert(`${t('successLogout')}`); // Successfully logged out
    }
    // eslint-disable-next-line
  }, [logout_status]);

  useEffect(() => {
    if (props.location.pathname === '/') {
      setBackHome(false);
    }
  }, [props.location.pathname]);

  //===========handler==================
  const handleChangeLanguage = (e, { value }) => {
    let lang = optionLanguage.find(item => item.value === value);
    setLanguage(lang.text);
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };
  const handleLogout = () => {
    logoutAction();
    // handleOpenModalAlert('Successfully logged out');
    // setExpiredTokenAction('');
    // setBackHome(true);
    // eslint-disable-next-line
  };
  const handleOpenModalAlert = message => {
    setOpenModalAlert(true);
    setMessage(message);
  };
  const close = () => {
    setOpenModalAlert(false);
    if (logout_status === 'success') {
      resetLogoutStatusAction();
      setExpiredTokenAction('');
      if (props.location.pathname !== '/') {
        setBackHome(true);
      }
    }
  };
  const handleOpenLoginPopup = () => setLoginPopup(true);
  const closePopup = () => setLoginPopup(false);
  const closeModalLogin = () => setOpenModalLogin(false);
  //===============helper===========
  const helperGetUserInfo = () => {
    let promise = Promise.resolve([
      getUserInfoAction(),
      getCompanyProfileAction(),
    ]);
    promise.then().catch(() => {
      localStorage.clear();
      // handleOpenModalAlert('Your login session has expired');
      handleOpenModalAlert(`${t('loginSessionExpire')}`);
      setBackHome(true);
    });
  };
  const helperSetExpiredToken = () => {
    if (
      !expiredToken &&
      expiredToken !== localStorage.getItem('expiredToken')
    ) {
      setExpiredTokenAction(localStorage.getItem('expiredToken'));
    }
  };
  const helperPathNameNoNeedHeader = () => {
    return (
      props.location.pathname === '/register' ||
      props.location.pathname === '/forgot-password' ||
      props.location.pathname.substring(0, 15) === '/reset-password'
    );
  };
  const helperSetLanguage = () => {
    let language = localStorage.getItem('language');
    if (language) {
      i18n.changeLanguage(language);
      let lang = optionLanguage.find(item => item.value === language);
      setLanguage(lang.text);
    }
  };

  let isHeaderHidden = helperPathNameNoNeedHeader();

  if (backHome) {
    // setBackHome(false);
    return <Redirect to="/" />;
  } else {
    return (
      isHeaderHidden || (
        <div>
          <Menu borderless inverted style={styles.header}>
            <Container
              className="container-fit padding-side-twentyfive"
              style={styles.backgroundBlack}
            >
              <Menu.Item
                as={Link}
                to="/"
                position="left"
                header
                style={styles.item}
              >
                <Image style={styles.logo} src={touressLogoWhiteS} />
              </Menu.Item>
              {localStorage.getItem('token') ? (
                <LogInHeaderMenu
                  options={optionLanguage}
                  defaultValue={language}
                  value={language}
                  handleChange={handleChangeLanguage}
                  handleLogout={handleLogout}
                />
              ) : (
                <LogOutHeaderMenu
                  options={optionLanguage}
                  defaultValue={language}
                  value={language}
                  handleChange={handleChangeLanguage}
                  open={loginPopup}
                  onOpen={handleOpenLoginPopup}
                  handleClose={closePopup}
                />
              )}
            </Container>
          </Menu>
          <ModalAlert
            size="mini"
            openModal={openModalAlert}
            open={handleOpenModalAlert}
            close={close}
            message={message}
          />
          <Loader description="loading" active={loading} page={true} />
          <ModalLogin
            openModal={openModalLogin}
            size="mini"
            close={closeModalLogin}
          />
        </div>
      )
    );
  }
};

Header.propTypes = {
  logout: Proptypes.func,
  userName: Proptypes.string,
  getUserInfo: Proptypes.func,
  userInfo: Proptypes.object,
  location: Proptypes.object,
  getUserInfoStatus: Proptypes.string,
  login_status: Proptypes.string,
  descriptionLogin: Proptypes.object,
  resetLoginStatus: Proptypes.func,
  loading: Proptypes.bool,
  setExpiredToken: Proptypes.func,
  expiredToken: Proptypes.string,
  changeLoginFlag: Proptypes.func,
  i18n: Proptypes.object,
  t: Proptypes.func,
};

export default Header;
