const bgImage = (imgUrl, width, noImage) => {
  return {
    width: width,
    minHeight: '150px',
    flex: 'none',
    backgroundImage: `url("${imgUrl ? imgUrl : noImage}")`, // backgroundSize: 'cover',
    borderBottom: '0px',
    backgroundRepeat: 'no-repeate',
    backgroundSize: imgUrl ? 'cover' : '100% 100%',
    backgroundPosition: 'center',
    backgroundColor: '#f7f7f7',
    border: 'none',
  };
};

export default bgImage;
