const styles = {
  width80: {
    width: '800px',
  },
  segment: {
    marginTop: '10%',
    marginBottom: '15%',
  },
  buttonSubmit: {
    marginTop: '10px',
  },
  marginTop20: {
    marginTop: '20px',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
};

export default styles;
