import React from 'react';
import { Segment, Container, Grid, Icon, Header } from 'semantic-ui-react';
import image from '../../../../assets/image/BannerListPage.png';
import { styles, bannerBackground } from './styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Banner = props => {
  const { icon, title } = props;
  const { t } = useTranslation();
  return (
    <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
      <Segment style={bannerBackground(image)}>
        <Grid>
          <Grid.Column width={8}>
            <Segment.Group
              horizontal
              style={styles.bgTransparant}
              className="no-margin"
            >
              <Segment basic style={styles.segmentHeader}>
                <Header
                  as="h2"
                  className="font34"
                  style={styles.lineHeightHeader}
                >
                  {/* {title} */}
                  {t(title)}
                </Header>
              </Segment>
              <Segment basic style={styles.segmentHeader}>
                <Icon className={icon} />
              </Segment>
            </Segment.Group>
            <Header as="h5" className="no-margin">
              {/* Choose travel packages without extra work needed. Created by our
              expert trip advisor */}
              {t('labelBannerPackageList')}
            </Header>
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  t: PropTypes.func,
};

export default Banner;
