const isPathNoNeedAuth = pathName => {
  switch (pathName) {
    case '/':
      return false;
    case '/forgot-password':
      return false;
    case '/reset-password':
      return false;
    case '/privacy-policy':
      return false;
    case '/terms-of-use':
      return false;
    case '/about-us':
      return false;
    case '/contact-us':
      return false;
    case '/coming-soon':
      return false;
    default:
      return true;
  }
};

export default isPathNoNeedAuth;
