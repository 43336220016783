const converNumberToK = value => {
  let res = value / 1000;
  let mod = value % 1000;

  if (value < 1000000) {
    return value;
    // eslint-disable-next-line
  } else if (res >= 1 && mod != 0) {
    let data = Number.parseFloat(res); //.toFixed(2)
    return data + ' K';
    // eslint-disable-next-line
  } else if (res >= 1 && mod == 0) {
    return res + ' K';
  } else return res;
};

const formatCurrency = value => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const numberWithCommas = num => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

// eslint-disable-next-line
const returnIntegerIfInteger = number => {
  if (number % parseInt(number) === 0) return parseInt(number);
  else return number;
};

const numberWithDecimalCommas = number => {
  let newRoundedString = number.toString();
  let splitString = newRoundedString.split('.');
  let joinString =
    numberWithCommas(parseInt(splitString[0])) +
    ',' +
    splitString[1].slice(0, 2);
  return joinString;
};

const numberRoundUp2Decimal = number => {
  if (number % 1 === 0) {
    return numberWithCommas(number);
  } else if (number % number.toFixed(2) === 0) {
    return numberWithDecimalCommas(number);
  } else {
    let value = parseFloat(number) + 0.01;
    let newRounded = numberWithDecimalCommas(value);
    return newRounded;
  }
};

export { converNumberToK, formatCurrency, numberRoundUp2Decimal };
