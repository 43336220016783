import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const AdditionalInformation = props => {
  const { t } = useTranslation();
  return (
    <div>
      <Segment style={styles.segment}>
        {/* Additional Information */}
        <Header as="h2">{t('additionalInformation')}</Header>
        {props.descriptions && props.descriptions.length > 0 ? (
          props.descriptions.map(item => (
            <div key={item.Id}>
              <Header as="h3">
                {Parser(item.SubTitle ? item.SubTitle : item.Subtitle)}
              </Header>
              <p>{Parser(item.Content)}</p>
            </div>
          ))
        ) : (
          // No Additional Information
          <span>{t('nodditionalInformation')}</span>
        )}
      </Segment>
    </div>
  );
};

AdditionalInformation.propTypes = {
  descriptions: PropTypes.array,
  t: PropTypes.func,
};
export default AdditionalInformation;
