import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const RegisterButton = props => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      loading={props.loading}
    >
      <strong style={props.labelStyles}>
        {/* Register */}
        {t('register')}
      </strong>
    </Button>
  );
};

RegisterButton.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  labelStyles: PropTypes.object,
  t: PropTypes.func,
};

export default RegisterButton;
