import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import styles from './styles';
import IndoDestCard from './IndoDestCard';
import PropTypes from 'prop-types';
import { layerBali, layerBandung, layerPuncak } from '../../../../assets/image';
import indonesianSitePromotionData from '../../constant/indonesianSitePromotionData';

import { useTranslation } from 'react-i18next';

const IndonesianSitePromotion = props => {
  const { t } = useTranslation();
  let { openModalIndonesia } = props;
  return (
    <Container className="margin-bottom-fifteen margin-top-ten container-fit">
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            {/* Our Destinations */}
            <h1 style={styles.textCenter}>{t('ourDestinations')}</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid columns={3}>
            {indonesianSitePromotionData.map((item, i) => (
              <IndoDestCard
                key={i}
                image={
                  item.type === 'bali'
                    ? layerBali
                    : item.type === 'bandung'
                    ? layerBandung
                    : layerPuncak
                }
                name={item.name}
                type={item.type}
                location={item.location}
                description={item.description}
                openModal={openModalIndonesia}
              />
            ))}
          </Grid>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
IndonesianSitePromotion.propTypes = {
  openModalDetail: PropTypes.func,
  openModalIndonesia: PropTypes.func,
};
export default IndonesianSitePromotion;
