import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/WithLabel';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const ResetPasswordWithUsername = props => {
  const { t } = useTranslation();
  const {
    handleChange,
    username,
    newPassword,
    confirmPassword,
    validate,
  } = props;

  return (
    <div style={styles.inputWidth100}>
      <Form>
        <InputWithLabel
          label={t('username')} //"Username"
          placeholder={t('username')} //"Username"
          labelStyles={styles.label}
          inputStyles={styles.inputWidth100}
          handleChange={handleChange}
          value={username}
          name="username"
          disabled={true}
        />
        <InputWithLabel
          required
          type="password"
          label={t('newPassword')} //"New Password"
          placeholder={t('newPassword')} //"New Password"
          labelStyles={styles.label}
          inputStyles={styles.inputWidth100}
          handleChange={handleChange}
          value={newPassword}
          name="newPassword"
          validate={validate}
        />
        <InputWithLabel
          label={t('confirmPassword')} //"Confirm Password"
          placeholder={t('confirmPassword')} //"confirm Password"
          required
          labelStyles={styles.label}
          inputStyles={styles.inputWidth100}
          handleChange={handleChange}
          value={confirmPassword}
          name="confirmPassword"
          type="password"
          validate={validate}
        />
      </Form>
    </div>
  );
};

ResetPasswordWithUsername.propTypes = {
  handleChange: PropTypes.func,
  username: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  validate: PropTypes.object,
  t: PropTypes.func,
};

export default ResetPasswordWithUsername;
