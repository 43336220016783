import React from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
const MarkerNoLabel = props => {
  const { className } = props;
  return (
    <Grid.Row style={styles.noPadding}>
      <Grid.Column width={16} style={styles.textCenter}>
        <Popup
          trigger={<Icon className={className} />}
          content="Your Accommodation"
          position="top center"
        />
      </Grid.Column>
    </Grid.Row>
  );
};
MarkerNoLabel.propTypes = {
  className: PropTypes.string,
};
export default MarkerNoLabel;
