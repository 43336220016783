import React, { Component } from 'react';
import { Container, Grid, Header, Visibility, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  SegmentAccommodation,
  SegmentPackage,
} from '../../shared/components/summary';
import SegmentTransportation from './segmentTransportation/SegmentTransportation';
import SegmentExcursion from './segmentExcursion/SegmentExcursion';
import SegmentGuest from './SegmentGuest';
import SegmentRestaurant from './segmentRestaurant/SegmentRestaurant';
// import SegmentOrderedItem from './segmentOrderedItem/SegmentOrderedItem';
import AllButton from './AllButtonSummary';
import SegmentDetailItinenary from './SegmentItineraryDetail';
import { rangeDate } from '../../../genericFunction/moment';
import { convertTourPrice } from '../../../genericFunction/convertPackage';
import { getTotalPayable } from '../../../genericFunction/convertPackage';
import ModalListCompanies from './ModalListCompanies';
import ModalRegisCompany from './registerOnBehalf/ModalRegisCompany';
import { ModalAlert } from './../../shared/components/modal';
import ModalListUser from './ModalListUser';
import styles from './../styles';
// import { getListTourGuide } from '../services/scripts/initialOrderItem';
import { withTranslation } from 'react-i18next';

class Summary extends Component {
  constructor(props) {
    super(props);
    this.detailPackageRef = React.createRef('detailPackage');
    // this.summaryItineraryRef = React.createRef('summaryItinerary');
    this.itineraryDetailRef = React.createRef('itineraryDetail');
    this.guestListRef = React.createRef('guestList');
  }
  state = {
    menuFixed: false,
    isBookStickyHeader: false,
  };
  static propTypes = {
    packageType: PropTypes.string,
    demo: PropTypes.object,
    // dataGuest: PropTypes.object,
    tourOperatorProfile: PropTypes.object,
    companyProfile: PropTypes.object,
    guestList: PropTypes.array,
    isSales: PropTypes.bool,
    companies: PropTypes.bool,
    modalCompanies: PropTypes.bool,
    selectedCompany: PropTypes.object,
    searchCompany: PropTypes.string,
    handleModalCompanies: PropTypes.func,
    handlePickCompany: PropTypes.func,
    handlePickUser: PropTypes.func,
    handleChange: PropTypes.func,
    activePage: PropTypes.number,
    pageChange: PropTypes.func,
    handleModalRegisCompany: PropTypes.func,
    modalRegisCompany: PropTypes.func,
    simpleRegisterCompany: PropTypes.func,
    modalAlert: PropTypes.bool,
    modalAlertMessage: PropTypes.string,
    loadingGetUserProfileByCompany: PropTypes.bool,
    userProfileByCompany: PropTypes.array,
    modalListUser: PropTypes.bool,
    handleModalListUser: PropTypes.bool,
    handleModalAlert: PropTypes.func,
    searchUser: PropTypes.string,
    selectedUser: PropTypes.object,
    loadingGetCompany: PropTypes.bool,
    tourNote: PropTypes.string,
    t: PropTypes.func,
  };
  //===handle function sticky section===
  handleContextRef = contextRef => this.setState({ contextRef });
  stickTopMenu = () => this.setState({ menuFixed: true });
  unStickTopMenu = () => this.setState({ menuFixed: false });
  handleScrollToElement(e, { myref }) {
    window.scroll({ top: myref.current.offsetTop, behavior: 'smooth' });
  }
  convertOrderedItem = (type, demo) => {
    return demo.DailyPrograms
      ? demo.DailyPrograms.filter(e =>
          e.OrderedItems.some(order => order.ItemType === type)
        ).map(items => {
          let data = items.OrderedItems.filter(e => e.ItemType === type);
          return {
            day: items.Day,
            date: items.Date,
            data,
          };
        })
      : [];
  };
  // ========================================
  getDestination = mainPrograms => {
    let city = [];
    mainPrograms.map(
      destination =>
        destination.City &&
        !city.some(e => e === destination.City.Name) &&
        city.push(destination.City.Name)
    );
    return city;
  };
  getCommissions = fixedPackage => {
    let commission = fixedPackage
      ? fixedPackage.BookingCommission.ApplicableCommission
      : [];
    // let newCommission = [];
    // typeof commission === 'object'
    //   ? newCommission.push(commission)
    //   : commission;
    return commission;
  };

  handleBookNow = () => {
    this.setState({ ...this.state, isBookStickyHeader: true });
  };
  handleResetBookNow = () => {
    this.setState({ ...this.state, isBookStickyHeader: false });
  };

  render() {
    const { t } = this.props;
    let { menuFixed, isBookStickyHeader } = this.state;
    let {
      packageType,
      demo,
      // tourOperatorProfile,
      companyProfile,
      isSales,
      companies,
      modalCompanies,
      selectedCompany,
      handleModalCompanies,
      handlePickUser,
      handlePickCompany,
      handleChange,
      searchCompany,
      activePage,
      pageChange,
      handleModalRegisCompany,
      modalRegisCompany,
      simpleRegisterCompany,
      modalAlert,
      modalAlertMessage,
      handleModalAlert,
      modalListUser,
      loadingGetUserProfileByCompany,
      userProfileByCompany,
      handleModalListUser,
      searchUser,
      selectedUser,
      loadingGetCompany,
      tourNote,
    } = this.props;
    let { TourPriceSum, TransactionSupplements, BookingDetailSum } = demo;
    let listGuest =
      packageType !== 'fixed'
        ? this.props.guestList
        : demo.TourGuestSum
        ? demo.TourGuestSum
        : [];
    const tourDate = demo.BookingDetailSum
      ? rangeDate(
          demo.BookingDetailSum.StartDate,
          demo.BookingDetailSum.EndDate
        )
      : '-';
    let tourPriceSummary = convertTourPrice(TourPriceSum);
    let totalGuest = demo.BookingDetailSum
      ? demo.BookingDetailSum.AdultQty +
        demo.BookingDetailSum.ChildQty +
        demo.BookingDetailSum.InfantQty
      : 0;
    let imageTourOperator = demo.BookingDetailSum
      ? demo.BookingDetailSum.TourOperatorProfile.ImageUrl
      : '';
    let nameTourOperator = demo.BookingDetailSum
      ? demo.BookingDetailSum.TourOperatorProfile.Name
      : '-';
    let tourTitle = demo.BookingDetailSum ? demo.BookingDetailSum.Title : '-';
    let paymentTerms = demo.BookingDetailSum
      ? demo.BookingDetailSum.PaymentTerms
      : [];
    let mainAccommodation = demo.HeadlineProgram
      ? demo.HeadlineProgram.MainPrograms.filter(e => e.AccommodationSummary)
      : [];
    let transportationSum = this.convertOrderedItem('Transportation', demo);
    let excursionSum = this.convertOrderedItem('Attraction', demo);
    let restaurantSum = this.convertOrderedItem('Restaurant', demo);
    let destinationCity =
      demo.HeadlineProgram &&
      this.getDestination(demo.HeadlineProgram.MainPrograms);
    let totalPayable = getTotalPayable(BookingDetailSum);
    let commissions = BookingDetailSum
      ? this.getCommissions(BookingDetailSum.FixedPackage)
      : [];
    return (
      <div ref={this.handleContextRef}>
        <Container
          style={styles.marginBottom15pxPositionRelative}
          className="padding-side-twentyfive container-fit"
        >
          {packageType !== 'fixed' && (
            <Visibility
              onBottomPassed={this.stickTopMenu}
              onBottomVisible={this.unStickTopMenu}
              once={false}
            >
              <Menu
                borderless
                className={menuFixed ? 'fixed' : ''}
                style={
                  menuFixed ? styles.fixedMenuStyle : styles.menuStyle // fixed={menuFixed && 'top'}
                }
              >
                <Container text>
                  <Menu.Item
                    myref={
                      this.detailPackageRef // as="a"
                    }
                    onClick={this.handleScrollToElement}
                  >
                    <strong style={styles.goldColor}>
                      {/* Package Detail */}
                      {t('packageDetail')}
                    </strong>
                  </Menu.Item>
                  <Menu.Item
                    myref={
                      this.itineraryDetailRef // as="a"
                    }
                    onClick={this.handleScrollToElement}
                  >
                    <strong style={styles.goldColor}>
                      {/* Tour Itinerary Detail */}
                      {t('tourItineraryDetail')}
                    </strong>
                  </Menu.Item>
                  <Menu.Item
                    myref={
                      this.guestListRef // as="a"
                    }
                    onClick={this.handleScrollToElement}
                  >
                    <strong style={styles.goldColor}>
                      {/* Guest List */}
                      {t('guestList')}
                    </strong>
                  </Menu.Item>
                  <Menu.Item
                    style={styles.backgroundGold}
                    onClick={this.handleBookNow}
                  >
                    <strong style={styles.colorBlack}>
                      {/* Book Now */}
                      {t('bookNow')}
                    </strong>
                  </Menu.Item>
                </Container>
              </Menu>
            </Visibility>
          )}
          <Grid>
            <Grid.Row>
              <Grid.Column width={9}>
                {/* What you get in package */}
                <Header as="h2">{t('whatYouGetInPackage')}</Header>
                <SegmentAccommodation mainPrograms={mainAccommodation} />
                <SegmentTransportation
                  transportationSum={transportationSum}
                  packageType={packageType}
                />
                <SegmentExcursion excursionSum={excursionSum} />
                <SegmentRestaurant restaurantSum={restaurantSum} />
                {/* {packageType !== 'fixed' && ( */}
                {/* <SegmentOrderedItem
                  orderedItems={tourGuides}
                  title="Tour Guide"
                  type="TOURGUIDES"
                  loading={false}
                /> */}
                <div ref={this.itineraryDetailRef}>
                  <SegmentDetailItinenary
                    dailyProgram={demo.DailyPrograms ? demo.DailyPrograms : []}
                    bookingDetail={BookingDetailSum}
                  />
                </div>
                {/* // )} */}
              </Grid.Column>
              <Grid.Column width={7}>
                <div ref={this.detailPackageRef}>
                  {/* TourOperator ? TourOperator.Name : '-' */}
                  <SegmentPackage
                    packageType={packageType}
                    imageTourOperator={imageTourOperator}
                    nameTourOperator={nameTourOperator}
                    tourTitle={tourTitle}
                    tourDate={tourDate}
                    totalGuest={totalGuest}
                    destinationCity={destinationCity}
                    paymentTerms={paymentTerms}
                    currency={
                      demo.BookingDetailSum
                        ? demo.BookingDetailSum.CurrencyId
                        : ''
                    }
                    tourPriceSum={tourPriceSummary}
                    additional={TransactionSupplements || []}
                    totalTourPrice={
                      demo.BookingDetailSum
                        ? demo.BookingDetailSum.TourTotalPrice
                        : 0
                    }
                    splitCommission={
                      BookingDetailSum &&
                      BookingDetailSum.IsSplitStaffCommission
                    }
                    commissions={commissions}
                    totalPayable={totalPayable}
                    isSales={isSales}
                    handleModalCompanies={handleModalCompanies}
                    handleModalRegisCompany={handleModalRegisCompany}
                    selectedCompany={selectedCompany}
                    searchUser={searchUser}
                    handleChange={handleChange}
                    selectedUser={selectedUser}
                    FoC={BookingDetailSum ? BookingDetailSum.FoC : null}
                    tourNote={tourNote}
                    message="(Optional)"
                    additionalServices={demo.AdditionalServices || []}
                  />
                </div>

                {/* <div>
                  <SegmentTourGuide
                    listGuest={listGuest}
                    dailyPrograms={demo.DailyPrograms ? demo.DailyPrograms : []}
                  />
                </div> */}

                {totalGuest !== 0 && (
                  <div ref={this.guestListRef}>
                    <SegmentGuest listGuest={listGuest} />
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
            <AllButton
              packageType={packageType}
              companyProfile={companyProfile}
              data={demo}
              listGuest={listGuest}
              isSales={isSales}
              selectedCompany={selectedCompany}
              selectedUser={selectedUser}
              tourNote={tourNote}
              isBookStickyHeader={isBookStickyHeader}
              handleResetBookNow={this.handleResetBookNow}
              additionalServices={demo.AdditionalServices || []}
            />
          </Grid>
          <ModalListCompanies
            isOpen={modalCompanies}
            onClose={handleModalCompanies}
            companies={companies}
            handleButtonPick={handlePickCompany}
            handleChange={handleChange}
            searchCompany={searchCompany}
            activePage={activePage}
            pageChange={pageChange}
            loading={loadingGetCompany}
          />
          <ModalRegisCompany
            isOpen={modalRegisCompany}
            onClose={handleModalRegisCompany}
            simpleRegisterCompany={simpleRegisterCompany}
          />
          <ModalAlert
            openModal={modalAlert}
            size="tiny"
            close={handleModalAlert}
            message={modalAlertMessage}
          />
          <ModalListUser
            isOpen={modalListUser}
            onClose={handleModalListUser}
            activePage={activePage}
            pageChange={pageChange}
            loading={loadingGetUserProfileByCompany}
            userProfileByCompany={userProfileByCompany}
            handleChange={handleChange}
            handleButtonPick={handlePickUser}
          />
        </Container>
      </div>
    );
  }
}
export default withTranslation()(Summary);
