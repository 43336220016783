import React from 'react';
import PropTypes from 'prop-types';
import { Form, Container, Button } from 'semantic-ui-react';
import InputWithLabel from '../../../components/input/WithLabel';
import styles from './styles';

const resetPasswordForm = props => {
  const {
    handleChange,
    password,
    confirmPassword,
    passwordIcon,
    onClickPassIcon,
    confirmPasswordIcon,
    onClickConfPassIcon,
    passwordInputType,
    confirmPasswordInputType,
    validate,
  } = props;

  return (
    <div>
      <div>
        <h4 style={styles.h4}>
          <strong>Reset Password</strong>
        </h4>
        <span>Please input your new password</span>
      </div>
      <div>
        <Container style={styles.width70} textAlign="left">
          <Form style={styles.form}>
            <InputWithLabel
              label="New Password"
              labelStyles={styles.label}
              name="newPassword"
              handleChange={handleChange}
              value={password}
              inputStyles={styles.width70}
              required
              inline
              type={passwordInputType}
              action={
                <Button
                  name="password"
                  icon={passwordIcon}
                  onClick={onClickPassIcon}
                />
              }
              validate={validate}
            />
            <InputWithLabel
              label="Confirm New Password"
              labelStyles={styles.label}
              name="confirmNewPassword"
              handleChange={handleChange}
              value={confirmPassword}
              inputStyles={styles.width70}
              required
              inline
              type={confirmPasswordInputType}
              action={
                <Button
                  name="confirmPassword"
                  icon={confirmPasswordIcon}
                  onClick={onClickConfPassIcon}
                />
              }
              validate={validate}
            />
          </Form>
        </Container>
      </div>
    </div>
  );
};

resetPasswordForm.propTypes = {
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  passwordIcon: PropTypes.string,
  confirmPasswordIcon: PropTypes.string,
  onClickPassIcon: PropTypes.func,
  onClickConfPassIcon: PropTypes.func,
  passwordInputType: PropTypes.string,
  confirmPasswordInputType: PropTypes.string,
  validate: PropTypes.object,
};

export default resetPasswordForm;
