import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import AccommodationMarker from './markers/AccommodationMarker';
import { updateCoordinateAddress } from '../../actions/locationAction';
import { connect } from 'react-redux';
import googleAPIKey from '../../../../config/envApiKey';
const createMapOptions = () => {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    gestureHandling: 'greedy',
    styles: [
      {
        featureType: 'poi.business',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text',
        stylers: [
          {
            visibility: 'on',
          },
        ],
      },
    ],
  };
};
class MapAccommodations extends Component {
  state = {
    center: {
      lat: -6.59712,
      lng: 106.79522,
    },
    zoom: 13,
  };
  shouldComponentUpdate(nextProps) {
    if (nextProps.coordinates !== this.props.coordinates) {
      return true;
    } else return true;
  }
  handleMarkerOnclick = index => {
    let { coordinates } = this.props;
    let newCoordinate = coordinates.map((item, i) => {
      if (i === index) {
        item.isOpen = true;
      } else {
        item.isOpen = false;
      }
      return item;
    });
    this.props.updateCoordinateAddress(newCoordinate);
  };
  handleMarkerOnClose = () => {
    let { coordinates } = this.props;
    let newCoordinate = coordinates.map(item => {
      item.isOpen = false;
      return item;
    });
    this.props.updateCoordinateAddress(newCoordinate);
  };
  filterCoordinateInAccommodation = () => {
    let { data, coordinates } = this.props;
    return coordinates.filter(function(obj) {
      return data.some(function(obj2) {
        return obj.id === obj2.Id;
      });
    });
  };
  render() {
    const { mapStyle, data, handleOpenRooms } = this.props;
    const { zoom } = this.state;
    let newCoordinate = this.filterCoordinateInAccommodation();
    const center =
      newCoordinate.length > 0
        ? newCoordinate[newCoordinate.length - 1].coordinate
        : null;
    return (
      <div style={mapStyle}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleAPIKey }}
          // defaultCenter={center}
          defaultZoom={zoom}
          center={center}
          options={createMapOptions}
          gestureHandling="greedy"
        >
          {newCoordinate.length > 0 &&
            newCoordinate.map((item, index) => (
              <AccommodationMarker
                index={index}
                key={index}
                lat={item.coordinate.lat}
                lng={item.coordinate.lng}
                id={item.id}
                type="hotel"
                data={data}
                isOpen={item.isOpen}
                handleOpenRooms={handleOpenRooms}
                handleMarkerOnclick={this.handleMarkerOnclick}
                handleMarkerOnClose={this.handleMarkerOnClose}
              />
            ))}
          {/* <AnyReactComponent lat={lat} lng={lng} text={text} type={type} />
          <AnyReactComponent lat={lat} lng={lng} text={text} type={type} /> */}
        </GoogleMapReact>
      </div>
    );
  }
}

MapAccommodations.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  mapStyle: PropTypes.object,
  lat: PropTypes.number,
  lng: PropTypes.number,
  text: PropTypes.string,
  type: PropTypes.string,
  coordinates: PropTypes.array,
  data: PropTypes.array,
  handleOpenRooms: PropTypes.func,
  updateCoordinateAddress: PropTypes.func,
};
const mapStateToProps = () => {};
export default connect(
  mapStateToProps,
  { updateCoordinateAddress }
)(MapAccommodations);
