import apiClient from './apiClient';
import {
  GET_REVENUE_RANGE_URL,
  GET_TOTAL_BRANCH_URL,
  GET_TOTAL_EMPLOYEE_URL,
} from './apiUrl';

const getRevenueRangeApi = () => {
  return apiClient.get(GET_REVENUE_RANGE_URL);
};

const getTotalBranchApi = () => {
  return apiClient.get(GET_TOTAL_BRANCH_URL);
};

const getTotalEmployeeApi = () => {
  return apiClient.get(GET_TOTAL_EMPLOYEE_URL);
};

export { getRevenueRangeApi, getTotalBranchApi, getTotalEmployeeApi };
