import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Header, Button, Message } from 'semantic-ui-react';
import styles from './styles';
import HeaderWithIcon from '../../../../../components/common/headerWithIcon';
import CollapseButton from '../../../../../components/button/BtnCollapse';
import { Collapse } from 'react-collapse';
import TableAdditionalServices from './components/TableAdditionalServices';
import ModalAddAdditionalServices from './components/ModalAddAdditionalServices';
import { getAdditionalServicesListTransactionApi } from '../../../../../api/additionalServicesApi';
import {
  getIndexofInArrayObject,
  getCountryFromArray,
  filterAdditionalServicesBySelected,
  filterAdditionalServicesByArrayId,
} from '../../../../shared/services/additionalServices/processData';
import { useTranslation } from 'react-i18next';

const AdditionalServices = props => {
  const { t } = useTranslation();
  // --------------- initial ---------------
  const {
    selectedAdditionalServices,
    headlineProgram,
    setAdditionalServices,
    handleIncrease,
    handleDecrease,
    handleDeleteService,
    companyProfile,
    roomPrices,
    fixedPackageById,
    demoPriceLoading,
    handleChangeAmount,
    // currentPackage,
    errorAdditionalService,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [additionalServicesList, setAdditionalServicesList] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);

  // --------------- helper ---------------
  const getAdditionalServiceFromApi = async (data, idx = 0, res = []) => {
    if (idx >= data.length) return res;
    else if (data.length) {
      setLoadingModal(true);
      let resData = [...res];
      await getAdditionalServicesListTransactionApi(
        data[idx].from,
        data[idx].to,
        fixedPackageById &&
          fixedPackageById.BookingDetailSum &&
          fixedPackageById.BookingDetailSum.PackageType === 'FixedDateVariable'
          ? roomPrices[0].Currency
          : fixedPackageById &&
            fixedPackageById.BookingDetailSum &&
            fixedPackageById.BookingDetailSum.CurrencyId
          ? fixedPackageById.BookingDetailSum.CurrencyId
          : companyProfile.Currency
      )
        .then(result => {
          // let listDataRes = result.data;
          let listDataRes =
            data[idx].from === 'DEF'
              ? result.data.map(i => {
                  return {
                    ...i,
                    OriginalCountries: i.OriginalCountries.filter(
                      j => j.Value === 'DEF'
                    ),
                  };
                })
              : result.data;
          resData = resData.concat(listDataRes);
          setLoadingModal(false);
        })
        .catch(() => setLoadingModal(false));
      return getAdditionalServiceFromApi(data, idx + 1, resData);
    }
  };

  // --------------- handler ---------------
  const handleClose = () => setOpenModal(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    if (headlineProgram && headlineProgram.MainPrograms.City !== null) {
      let resultGetApi = getAdditionalServiceFromApi(getCountry);
      resultGetApi &&
        resultGetApi.then(items => {
          let selected = [...selectedAdditionalServices];
          let addServ = [...items];
          let newAdditionalServicesList = filterAdditionalServicesBySelected(
            addServ,
            selected
          );
          setAdditionalServicesList(newAdditionalServicesList);
        });
    }
  };

  // arrayId is additional services id's selected in modal
  const handleSave = arrayId => {
    let selected = [...selectedAdditionalServices];
    let addServ = [...additionalServicesList];
    let filtered = filterAdditionalServicesByArrayId(addServ, arrayId);
    let newSelected = filtered.reduce((res, cur) => {
      let item = {
        AdditionalServiceId: cur.Id,
        Quantity: 0,
        AdditionalServiceName: cur.AdditionalServicesName,
        Amount: cur.OriginalCountries[0].Amount,
        Currency: cur.OriginalCountries[0].Currency,
        OriginalCountryId: cur.OriginalCountries[0].OriginalCountryId,
        IsMandatory: false,
        DestinationCountry: cur.DestinationCountry.Text,
        DestinationCountryId: cur.DestinationCountry.Value,
      };
      res.push(item);
      return res;
    }, []);
    selected = selected.concat(newSelected);
    // setSelectedAdditionalServices(selected);
    setAdditionalServices(selected);
    setOpenModal(false);
  };

  const handleDelete = async e => {
    let newSelected = [...selectedAdditionalServices];
    const id = parseInt(e.target.id);
    let index = getIndexofInArrayObject(newSelected, 'AdditionalServiceId', id);
    newSelected.splice(index, 1);
    await setAdditionalServices(newSelected);
    handleDeleteService();
    // setSelectedAdditionalServices(newSelected);
  };

  const handleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  // --------------- variable ---------------
  let resultFilter =
    headlineProgram &&
    headlineProgram.MainPrograms.filter(
      item => typeof item.AccommodationSummary !== 'undefined'
    );
  let getCountry = getCountryFromArray(resultFilter);

  return (
    <div>
      <Segment style={styles.segment} loading={demoPriceLoading}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <HeaderWithIcon
                as="h2"
                title={t('additionalServices')}
                // Additional Services
                icon="clipboard list"
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              <CollapseButton
                isCollapse={openCollapse}
                handleCollapse={handleCollapse}
                style={styles.shadowTransparant}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Collapse isOpened={openCollapse}>
          <Grid textAlign="center" style={styles.paddingSideTwenty}>
            <Grid.Row>
              <Grid.Column>
                {errorAdditionalService && errorAdditionalService.isError && (
                  <Message negative style={styles.messageError}>
                    {errorAdditionalService.message}
                  </Message>
                )}
                <TableAdditionalServices
                  data={selectedAdditionalServices}
                  handleDelete={handleDelete}
                  handleIncrease={handleIncrease}
                  handleDecrease={handleDecrease}
                  demoPriceLoading={demoPriceLoading}
                  handleChangeAmount={handleChangeAmount}
                />
                <Header as="h3">
                  {selectedAdditionalServices.length
                    ? ''
                    : t('wantToAddAdditionalService') // 'Want to add additional service?'
                  }
                </Header>
                <Button style={styles.backgroundGold} onClick={handleOpenModal}>
                  {selectedAdditionalServices.length
                    ? t('addMoreAdditionalService') //'Add more additional service'
                    : t('addAdditionalService') //'Add additional service'
                  }
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Collapse>
      </Segment>

      {/* modal add additional service */}
      <ModalAddAdditionalServices
        open={openModal}
        onClose={handleClose}
        loading={loadingModal}
        data={additionalServicesList}
        handleSave={handleSave}
      />
    </div>
  );
};

AdditionalServices.propTypes = {
  headlineProgram: PropTypes.object,
  setAdditionalServices: PropTypes.func,
  handleChangeAmount: PropTypes.func,
  handleIncrease: PropTypes.func,
  handleDecrease: PropTypes.func,
  selectedAdditionalServices: PropTypes.array,
  currentPackage: PropTypes.object,
  guests: PropTypes.array,
  handleDeleteService: PropTypes.func,
  companyProfile: PropTypes.object,
  roomPrices: PropTypes.array,
  fixedPackageById: PropTypes.object,
  demoPriceLoading: PropTypes.bool,
  t: PropTypes.func,
  errorAdditionalService: PropTypes.object,
};

export default React.memo(AdditionalServices);
