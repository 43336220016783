import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import { ButtonCloseModal } from '../../../common';
import MapExcursions from '../../../map/MapExcursions';
import styles from './styles';
const ModalExcursionMap = props => {
  let { open, onClose, coordinates, data, accommodation } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      size="large"
      closeOnDimmerClick
      style={styles.height400}
    >
      <Modal.Header>
        Nearby Excursion with your Accommodation
        <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content>
        <MapExcursions
          mapStyle={styles.mapSize}
          coordinates={coordinates}
          data={data}
          accommodation={accommodation}
        />
      </Modal.Content>
    </Modal>
  );
};
ModalExcursionMap.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  coordinates: PropTypes.array,
  data: PropTypes.array,
  accommodation: PropTypes.object,
};
export default ModalExcursionMap;
