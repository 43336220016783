const BLACK_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';
const GREY_COLOR = '#fbfbfb';

const style = {
  backgroundModal: {
    backgroundColor: GREY_COLOR,
  },
  backgroundCard: {
    backgroundColor: WHITE_COLOR,
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  blackColor: {
    color: BLACK_COLOR,
  },
  greyColor: {
    color: '#d3d3d3',
  },
  message: {
    border: '0.3px solid #e6ca6b',
    backgroundColor: 'white',
    color: 'black',
  },
  messageDisable: {
    border: '0.5px solid #d3d3d3',
    backgroundColor: 'white',
    color: 'black',
  },
  buttonGold: {
    background: GOLD_COLOR,
    color: WHITE_COLOR,
  },
  transparentButton: {
    backgroundColor: WHITE_COLOR,
    color: GOLD_COLOR,
    padding: '0px',
  },
  buttonChooseRoom: {
    backgroundColor: GOLD_COLOR,
    color: BLACK_COLOR,
    padding: '10px',
  },
  buttonChooseRoomDisable: {
    backgroundColor: '#d3d3d3',
    color: '#252525',
    padding: '10px',
  },
  imageAttachment: {
    backgroundColor: BLACK_COLOR,
    opacity: 0.5,
  },
  noMarginBottom: {
    marginBottom: '0px',
  },
  marginBottomfifteen: { marginBottom: '15px' },
  noPaddingTop: { paddingTop: '0px' },
  paddingthree: {
    padding: '3px',
  },
  segmentSearch: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '1px 5px',
  },
  inputWithPaddingNine: {
    width: '100%',
    padding: '9px',
  },
  textTransparent: {
    color: WHITE_COLOR,
    fontWeight: 'bold',
  },
  borderNone: { border: 'none' },
  gridMargins: { margin: '3px 3px 0px 3px' },
  marginTopFive: { marginTop: '5px' },
  marginBottomThree: { marginBottom: '3px' },
  marginTopTen: { marginTop: '10px' },
  segmentTransparent: {
    border: 'none',
    background: 'transparent',
    minHeight: '50px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  label: {
    border: '1px solid #6b82e6',
    color: '#6b82e6',
    borderRadius: '50px',
  },
  buttonGoldCard: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
    // width: '100%',
  },
};
export default style;
