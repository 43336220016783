import React, { Component } from 'react';
import { Grid, Container, Sticky } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  GeneralInformation,
  AccommodationAndDate,
  TourItinerary,
  TourOperator,
  SideMenu,
  StickyHeader,
} from '../shared/components/createPackage';
import { BreadCrumb } from '../shared/components/common';
import Loader from '../../components/common/loader';
import { getCountries } from '../shared/actions/countriesAction';
import {
  getGuestTitleTypes,
  getIdentityTypes,
} from '../shared/actions/tourTransactionAction';
import {
  getCityInCountry,
  resetStatusGetCityInCountry,
} from '../shared/actions/locationAction';
import {
  getTourTypes,
  getTourPaxTypes,
} from '../shared/actions/tourTransactionAction';
import {
  // setSummaryProgram,
  // setInitialAirport,
  // dailyProgramNull,
  isAccommodationEmpty,
  validateDailyProgramActivityTime,
  transportValidation,
  validateDailyProgramOverloadTime,
} from '../../genericFunction/itineraryBuilder/dailyProgram';
import {
  // generateInitialDailyProgram,
  initialSetCustomPackage,
} from '../../genericFunction/itineraryBuilder/itineraryBuilder';
import {
  setHeadlineProgram,
  setDailyProgram,
  getMovementList,
  setGuestList,
  resetSetDailyProgramStatus,
  resetCustomPackage,
  resetFirstDestination,
  resetGuests,
  resetRoomAllocation,
} from '../shared/actions/customPackageAction';
import { setCurrentPackage } from '../shared/actions/generalAction';
import { generateDemoPrice } from '../../genericFunction/itineraryBuilder/demoPrice';
import { getTourOperatorList } from '../shared/actions/tourOperatorAction';
import {
  postDemoPrice,
  resetDemoPrice,
} from '../shared/actions/tourTransactionAction';
import {
  // generateGuestTBA,
  changeGuest,
  sumValuesObject,
  fillFewGuests,
} from '../../genericFunction/itineraryBuilder/guest';
import { setTempDemoPrice } from '../shared/actions/tempAction';
import SegmentFormGuest from '../shared/components/guest/SegmentFormGuest';
import {
  validationFillGuest,
  isGuestError,
} from '../../genericFunction/validation';
import { ModalAlert } from './../shared/components/modal';
import {
  resetTempBackCustomReady,
  setTempTourCustomReady,
} from '../shared/actions/tempAction';
import breadCrumb from './constant/breadCrumb';
import { getLanguageAll } from '../shared/actions/languageAction';
import styles from './styles';
import sumObjectValue from '../../genericFunction/sumObjectValue';
import { getPlaceByPlaceTypeIdAction } from '../shared/actions/locationAction';
import { RECREATION } from '../../constant/activityTypes';
import { isCheckInDifferentDate } from '../../genericFunction/dailyProgramValidation';
import getErrorMessage from '../shared/services/helper/getErrorMessage';
import { withTranslation } from 'react-i18next';

import AdditionalServices from '../shared/components/createPackage/additionalServices/AdditionalServices';
import {
  updateAdditionalServiceAfterChangeDestination,
  additionalServicesValidation,
} from '../shared/services/additionalServices/processData';
import DynamicTitle from '../../components/common/DynamicTitle';
class CustomPackage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.generalInfoRef = React.createRef('generalInfo');
    this.destinationsRef = React.createRef('destinations');
    this.itineraryRef = React.createRef('itinerary');
    this.operatorRef = React.createRef('operator');
    this.guestsRef = React.createRef('guests');
    this.additionalServiceRef = React.createRef('additionalService');
    this.state = {
      generalInformation: {
        TourName: '',
        GroupCapacity: '',
        FirstDestination: '',
        TourType: '',
        TourCategory: '',
      },
      guests: { adults: 0, childs: 0, infants: 0 },
      foc: {
        Qty: 0,
        Description: 'FoC',
      },
      roomAllocation: {
        ChildExtraBedQty: 0,
        ChildSharingRoomQty: 0,
        ChildSingleRoomQty: 0,
        ExtraBedQty: 0,
        BabyCrib: 0,
        NoBed: 0,
        SharingBedQty: 0,
        SharingRoomQty: 0,
        SingleRoomQty: 0,
      },
      HeadlineProgram: { Departures: [], MainPrograms: [], Returns: [] },
      overlayFixed: false,
      calculations: {
        topPassed: false,
        bottomPassed: false,
        topVisible: false,
        bottomVisible: false,
      },
      menuFixed: false,
      bookSegmentCalculations: { bottomPassed: false },
      tourOperator: null,
      nextPageSummary: false,
      errors: {
        firstName: { error: false, message: '' },
        lastName: { error: false, message: '' },
        identityNbr: { error: false, message: '' },
        identityType: { error: false, message: '' },
        guestTitle: { error: false, message: '' },
        countryId: { error: false, message: '' },
      },
      messageConfirm: '',
      modalAlert: false,
      openModalOperator: false,
      errorGeneralInformation: {
        TourName: { error: false, message: '' },
        GroupCapacity: { error: false, message: '' },
        TourType: { error: false, message: '' },
        TourCategory: { error: false, message: '' },
        FirstDestination: { error: false, message: '' },
      },
      TourNote: '',
      additionalServices: [],
    };
  }
  componentDidMount() {
    if (!this.props.dataTempBackCustomReady) {
      this.props.resetCustomPackage();
      this.props.resetDemoPrice();
      this.props.resetFirstDestination();
      this.props.resetGuests();
      this.props.resetRoomAllocation();
      this.props.getLanguageAll();
      this.initialSet();
    } else if (this.props.dataTempBackCustomReady) {
      this.setTour();
    }
    if (this.props.countries.length === 0) {
      this.props.getCountries();
    }
    if (this.props.identityTypes.length === 0) {
      this.props.getIdentityTypes();
    }
    if (this.props.guestTitles.length === 0) {
      this.props.getGuestTitleTypes();
    }
    if (this.props.cityList.length === 0) {
      this.props.getCityInCountry();
    }
    if (this.props.placeByType) {
      this.props.getPlaceByPlaceTypeIdAction(1);
    }
    this.props.setCurrentPackage('CUSTOM');
    this.props.getMovementList();
    this.props.getTourTypes();
    this.props.getTourPaxTypes();
  }
  componentDidUpdate(prevProps) {
    if (this.props.cityInCountryStatus === 'success') {
      this.props.resetStatusGetCityInCountry();
    }
    if (this.props.isSetDailyProgramSuccess && this.isGuestAllotmentEqual()) {
      this.getDemoPriceAfterUpdate();
      // this.props.resetSetDailyProgramStatus();
    }
    // if (this.props.guests !== prevProps.guests) {
    //   this.setGuestInMandatory(this.props.guestList.length);
    // }
    if (this.props.HeadlineProgram !== prevProps.HeadlineProgram) {
      let additionalServices = updateAdditionalServiceAfterChangeDestination(
        this.props.HeadlineProgram,
        this.state.additionalServices
      );
      this.setState({ ...this.state, additionalServices });
    }
  }
  setTour = () => {
    let { dataTempTourCustomReady } = this.props;
    this.setState(
      {
        ...this.state,
        generalInformation: dataTempTourCustomReady.generalInformation,
        tourOperator: dataTempTourCustomReady.tourOperator,
        foc: dataTempTourCustomReady.foc,
        TourNote: dataTempTourCustomReady.TourNote,
        additionalServices: dataTempTourCustomReady.additionalServices,
      },
      () => this.props.resetTempBackCustomReady()
    );
  };
  initialSet = () => {
    let headlineProgram = { ...this.props.HeadlineProgram };
    // headlineProgram.Departures = [
    //   setInitialAirport([], null, 1, 'Arrival', new Date(), 'Custom'),
    // ];
    // headlineProgram.MainPrograms = [
    //   setSummaryProgram(null, this.props.roomAllocation),
    // ];
    // headlineProgram.Returns = [
    //   setInitialAirport(
    //     [],
    //     null,
    //     2,
    //     'Departure',
    //     headlineProgram.MainPrograms[0].LeavingDate,
    //     'Custom'
    //   ),
    // ];
    let result = initialSetCustomPackage(
      headlineProgram,
      this.props.roomAllocation
    );
    this.props.setHeadlineProgram(result.headlineProgram);
    // let dP = generateInitialDailyProgram(headlineProgram);
    // let dP = dailyProgramNull(
    //   headlineProgram.Departures[0],
    //   headlineProgram.MainPrograms,
    //   headlineProgram.Returns[0]
    // );
    this.props.setDailyProgram(result.dailyProgram);
  };
  handleContextRef = contextRef => this.setState({ contextRef });
  handleScrollToElement(e, { myref }) {
    window.scroll({ top: myref.current.offsetTop, behavior: 'smooth' });
  }
  scrollToElement = myref => {
    window.scroll({ top: myref.current.offsetTop, behavior: 'smooth' });
  };
  handleGeneralInfo = (name, value) => {
    let generalInfo = { ...this.state.generalInformation };
    generalInfo[name] = value;
    this.setState({ ...this.state, generalInformation: generalInfo });
  };
  stickTopMenu = () => this.setState({ menuFixed: true });
  unStickTopMenu = () => {
    this.setState({ menuFixed: false });
  };
  getDemoPriceObject = () => {
    let {
      HeadlineProgram,
      dailyProgram,
      roomAllocation,
      guests,
      guestList,
      tourNote,
    } = this.props;
    let {
      TourName,
      GroupCapacity,
      FirstDestination,
      TourType,
      TourCategory,
    } = this.state.generalInformation;
    let { foc, additionalServices } = this.state;
    let demoPrice = generateDemoPrice(
      guests,
      roomAllocation,
      TourName,
      GroupCapacity,
      FirstDestination,
      TourType,
      TourCategory,
      HeadlineProgram,
      dailyProgram,
      guestList,
      foc,
      tourNote,
      additionalServices
    );
    return demoPrice;
  };
  generateTourOperator = async () => {
    const context = this;
    let totalGuest = sumValuesObject(this.props.guests);
    let validateDailyProgram = validateDailyProgramActivityTime(
      this.props.dailyProgram,
      RECREATION
    );
    let validateCheckinNextDate = isCheckInDifferentDate(
      this.props.dailyProgram
    );
    let isDailyProgramOverload = validateDailyProgramOverloadTime(
      this.props.dailyProgram
    );
    let validateTransport = transportValidation(this.props.dailyProgram);
    let validateAdditionalServices = additionalServicesValidation(
      this.state.additionalServices
    );
    if (validateTransport) {
      this.setModalAlert(
        true,
        // 'Please check your transportation, transportation with type Transfer exceed the duration.'
        this.props.t('transportationExceedTheDuration')
      );
    } else if (totalGuest === 0) {
      this.setModalAlert(
        true,
        // 'Please check your pax arrangement, at least one adult guest'
        this.props.t('fillAtLeastOneAdultGest')
      );
    } else if (validateDailyProgram) {
      this.setModalAlert(
        true,
        // 'Please check your Itinerary, something wrong with excursion time'
        this.props.t('somethingWrongWithExcursionTime')
      );
    } else if (isDailyProgramOverload) {
      this.setModalAlert(
        true,
        // 'Please check your Itinerary, something wrong with activity time'
        this.props.t('somethingWrongWithActivityTime')
      );
    } else if (validateCheckinNextDate) {
      this.setModalAlert(
        true,
        // 'Please check your Itinerary, something wrong with check in time'
        this.props.t('somethingWrongWithCheckInTime')
      );
    } else if (validateAdditionalServices) {
      this.setModalAlert(
        true,
        'Please check your additional services, quantity can not be 0'
      );
    } else {
      context.setState({ ...context.state, openModalOperator: true });
      let demoPrice = this.getDemoPriceObject();
      this.props
        .getTourOperatorList(demoPrice, false)
        .then(() => {
          // context.getTourOperatorProfiles(res.value.data);
        })
        .catch(err => {
          context.setState({
            ...context.state,
            modalAlert: true,
            messageConfirm: getErrorMessage(err),
          });
        });
    }
  };
  setTourOperator = operatorId => {
    let state = this.state;
    state.tourOperator = operatorId;
    this.setState({ state });
    this.getDemoPrice(operatorId);
  };
  getDemoPrice = operatorId => {
    let demoPrice = this.getDemoPriceObject();
    demoPrice.TourOperatorId = operatorId;
    this.props.setTempDemoPrice(demoPrice);
    this.props.postDemoPrice(demoPrice);
  };
  getDemoPriceAfterUpdate = () => {
    let { tourOperator } = this.state;
    let { HeadlineProgram } = this.props;
    if (
      tourOperator &&
      isAccommodationEmpty(HeadlineProgram.MainPrograms) === false
    ) {
      this.getDemoPrice(tourOperator);
    }
  };
  setModalAlert = (value, message) => {
    this.setState({
      ...this.state,
      modalAlert: value,
      messageConfirm: message,
    });
  };
  handleSummary = () => {
    let { errors } = this.state;
    let { guestList, dailyProgram } = this.props;
    let newErrors = validationFillGuest(guestList, errors);
    let validateDailyProgram = validateDailyProgramActivityTime(
      dailyProgram,
      RECREATION
    );
    let validateCheckinNextDate = isCheckInDifferentDate(dailyProgram);
    let isDailyProgramOverload = validateDailyProgramOverloadTime(dailyProgram);
    let validateTransport = transportValidation(this.props.dailyProgram);
    if (validateTransport) {
      this.setModalAlert(
        true,
        // 'Please check your transportation, transportation with type Transfer exceed the duration.'
        this.props.t('transportationExceedTheDuration')
      );
    } else if (validateDailyProgram) {
      this.setModalAlert(
        true,
        // 'Please check your Itinerary, something wrong with excursion time'
        this.props.t('somethingWrongWithExcursionTime')
      );
    } else if (validateCheckinNextDate) {
      this.setModalAlert(
        true,
        // 'Please check your Itinerary, something wrong with check in time'
        this.props.t('somethingWrongWithCheckInTime')
      );
    } else if (isDailyProgramOverload) {
      this.setModalAlert(
        true,
        // 'Please check your Itinerary, something wrong with activity time'
        this.props.t('somethingWrongWithActivityTime')
      );
    } else if (isGuestError(newErrors)) {
      this.setState(
        {
          ...this.state,
          errors: newErrors,
        },
        () => this.scrollToElement(this.guestsRef)
      );
    } else {
      let data = {
        generalInformation: this.state.generalInformation,
        tourOperator: this.state.tourOperator,
        foc: this.state.foc,
        TourNote: this.state.TourNote,
        additionalServices: this.state.additionalServices,
      };
      this.props.setTempTourCustomReady(data);
      this.setState({ nextPageSummary: !this.state.nextPageSummary });
    }
  };

  handleChangeGuest = async (e, { name, value, objectname }) => {
    let { guestList } = this.props;
    let { errors } = this.state;
    let newListGuest = await changeGuest(name, guestList, objectname, value);
    let newErrorsGuests = (await isGuestError(errors))
      ? await validationFillGuest(newListGuest, errors)
      : errors;
    await this.props.setGuestList(newListGuest);
    this.setState({ ...this.state, errors: newErrorsGuests });
  };
  uploadTemplate = guests => {
    let detailGuests = fillFewGuests(guests.rows, this.props.guestList, 'new');
    this.props.setGuestList(detailGuests);
  };
  closeModal = () => {
    this.setState({ modalAlert: false });
  };
  closeModalOperator = () => {
    this.setState({ ...this.state, openModalOperator: false });
  };
  setErrorGeneralInformation = errorGeneralInformation =>
    this.setState({ ...this.state, errorGeneralInformation });
  addFocAmount = val => {
    this.setState({ ...this.state, foc: val }, () => {
      this.getDemoPriceAfterUpdate();
    });
  };
  isGuestAllotmentEqual = () => {
    let { roomAllocation, guests } = this.props;
    let totalGuest = sumObjectValue(guests);
    let totalAllocation = sumObjectValue(roomAllocation);
    return totalGuest === totalAllocation ? true : false;
  };

  // ================== additional services ==================
  setAdditionalServicesList = data => {
    this.setState({ ...this.state, additionalServices: data });
  };

  getAddService = (name, addServicesList) => {
    let addService = addServicesList.find(item => {
      return item.AdditionalServiceId === name; // name === id
    });
    let index = addServicesList.findIndex(item => {
      return item.AdditionalServiceId === name;
    });
    return { addService: addService, index: index };
  };

  handleIncreaseAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    addService.Quantity = value + 1;
    addServicesList[index] = addService;
    this.setState(
      { ...this.state, additionalServices: addServicesList },
      () => {
        this.getDemoPriceAfterUpdate();
      }
    );
  };

  handleDecreaseAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    if (value - 1 > 0) {
      addService.Quantity = value - 1;
      addServicesList[index] = addService;
      this.setState(
        { ...this.state, additionalServices: addServicesList },
        () => {
          this.getDemoPriceAfterUpdate();
        }
      );
    }
  };

  handleChangeAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    if (value > 0) {
      addService.Quantity = value;
      addServicesList[index] = addService;
      this.setState(
        { ...this.state, additionalServices: addServicesList },
        () => {
          this.getDemoPriceAfterUpdate();
        }
      );
    }
  };

  handleDelete = () => {
    this.getDemoPriceAfterUpdate();
  };

  // ================== end additional services ==================

  render() {
    const {
      generalInformation,
      tourOperator,
      errors,
      messageConfirm,
      modalAlert,
      contextRef,
      menuFixed,
      errorGeneralInformation,
      additionalServices,
    } = this.state;
    const { demoPriceLoading, demoPrice, guestList } = this.props;
    let disable = Object.keys(demoPrice).length > 0 ? false : true;
    let detail = demoPrice ? demoPrice.BookingDetailSum : null;
    if (this.state.nextPageSummary) {
      return <Redirect to="/summary/custom" />;
    }
    return (
      <Container
        style={styles.marginBotFifteenRelative}
        className="padding-side-twentyfive container-fit"
      >
        <DynamicTitle title="Custom Package" />
        <BreadCrumb sections={breadCrumb} />
        <div ref={this.handleContextRef}>
          <Grid>
            <Grid.Row style={styles.noPaddingBottom}>
              <Grid.Column width={9}>
                <div ref={this.generalInfoRef}>
                  <GeneralInformation
                    handleGeneralInfo={this.handleGeneralInfo}
                    generalInfo={generalInformation}
                    errorGeneralInformation={errorGeneralInformation}
                    setErrorGeneralInformation={this.setErrorGeneralInformation}
                    foc={this.state.foc}
                    addFocAmount={this.addFocAmount}
                    guests={this.props.guests}
                  />
                </div>
                <div ref={this.destinationsRef}>
                  <AccommodationAndDate
                    headlineProgram={this.props.HeadlineProgram}
                    generalInfo={generalInformation}
                    errorGeneralInformation={errorGeneralInformation}
                    setErrorGeneralInformation={this.setErrorGeneralInformation}
                    guests={this.props.guests}
                  />
                </div>
                <div ref={this.itineraryRef}>
                  <TourItinerary
                    getDemoPriceAfterUpdate={this.getDemoPriceAfterUpdate}
                  />
                </div>
                <div ref={this.additionalServiceRef}>
                  <AdditionalServices
                    selectedAdditionalServices={additionalServices}
                    headlineProgram={this.props.HeadlineProgram}
                    guests={guestList}
                    setAdditionalServices={this.setAdditionalServicesList}
                    handleIncrease={this.handleIncreaseAmount}
                    handleDecrease={this.handleDecreaseAmount}
                    handleDeleteService={this.handleDelete}
                    companyProfile={this.props.companyProfile}
                    demoPriceLoading={demoPriceLoading}
                    handleChangeAmount={this.handleChangeAmount}
                  />
                </div>
                <div ref={this.operatorRef}>
                  <TourOperator
                    generateTourOperator={this.generateTourOperator}
                    tourOperator={tourOperator}
                    setTourOperator={this.setTourOperator}
                    closeModal={this.closeModalOperator}
                    openModal={this.state.openModalOperator}
                  />
                </div>
                <div />
              </Grid.Column>
              <Grid.Column width={7}>
                <Sticky context={contextRef}>
                  <SideMenu
                    generalInfoRef={this.generalInfoRef}
                    destinationsRef={this.destinationsRef}
                    itineraryRef={this.itineraryRef}
                    operatorRef={this.operatorRef}
                    guestsRef={this.guestsRef}
                    handleScroll={this.handleScrollToElement}
                    demoPriceLoading={demoPriceLoading}
                    demoPrice={demoPrice}
                    handleSummary={this.handleSummary}
                  />
                </Sticky>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <StickyHeader
          menuFixed={menuFixed}
          disable={disable}
          detail={detail}
          handleSummary={this.handleSummary}
          generalInfoRef={this.generalInfoRef}
          destinationsRef={this.destinationsRef}
          itineraryRef={this.itineraryRef}
          operatorRef={this.operatorRef}
          guestsRef={this.guestsRef}
          handleScrollToElement={this.handleScrollToElement}
          stickTopMenu={this.stickTopMenu}
          unStickTopMenu={this.unStickTopMenu}
          context={contextRef}
          additionalServiceRef={this.additionalServiceRef}
        />

        <div ref={this.guestsRef}>
          <Grid>
            <Grid.Row style={styles.noPaddingTop}>
              <Grid.Column width={16}>
                <SegmentFormGuest
                  guests={guestList}
                  handleChange={this.handleChangeGuest}
                  errors={errors}
                  uploadTemplate={this.uploadTemplate}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Loader description="loading" active={this.props.loadingCountries} />
        <ModalAlert
          openModal={modalAlert}
          size="tiny"
          close={this.closeModal}
          message={messageConfirm}
        />
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    countries: state.countries.countries,
    identityTypes: state.tourTransaction.identityTypes,
    guestTitles: state.tourTransaction.guestTitleTypes,
    cityList: state.location.cityInCountry,
    cityInCountryStatus: state.location.cityInCountryStatus,
    roomAllocation: state.customPackage.roomAllocation,
    HeadlineProgram: state.customPackage.HeadlineProgram,
    dailyProgram: state.customPackage.dailyProgram,
    guests: state.customPackage.guests,
    guestList: state.customPackage.guestList,
    tourOperatorList: state.tourOperator.tourOperatorList,
    demoPriceLoading: state.tourTransaction.loading,
    demoPrice: state.tourTransaction.demoPrice,
    isSetDailyProgramSuccess: state.customPackage.isSetDailyProgramSuccess,
    dataTempBackCustomReady: state.temp.dataBackCustomReady,
    dataTempTourCustomReady: state.temp.dataTourCustomReady,
    tourNote: state.customPackage.tourNote,
    placeByType: state.location.placeByType,
    companyProfile: state.company.companyProfile,
  };
};
CustomPackage.propTypes = {
  loadingCountries: PropTypes.bool,
  getCountries: PropTypes.func,
  countries: PropTypes.array,
  identityTypes: PropTypes.array,
  guestTitles: PropTypes.array,
  getGuestTitleTypes: PropTypes.func,
  getIdentityTypes: PropTypes.func,
  getCityInCountry: PropTypes.func,
  resetStatusGetCityInCountry: PropTypes.func,
  cityInCountryStatus: PropTypes.string,
  getTourTypes: PropTypes.func,
  getTourPaxTypes: PropTypes.func,
  cityList: PropTypes.array,
  roomAllocation: PropTypes.object,
  HeadlineProgram: PropTypes.object,
  setHeadlineProgram: PropTypes.func,
  setDailyProgram: PropTypes.func,
  getMovementList: PropTypes.func,
  setCurrentPackage: PropTypes.func,
  dailyProgram: PropTypes.array,
  guests: PropTypes.object,
  guestList: PropTypes.array,
  getTourOperatorList: PropTypes.func,
  tourOperatorList: PropTypes.array,
  postDemoPrice: PropTypes.func,
  demoPriceLoading: PropTypes.bool,
  demoPrice: PropTypes.object,
  resetSetDailyProgramStatus: PropTypes.func,
  isSetDailyProgramSuccess: PropTypes.bool,
  resetCustomPackage: PropTypes.func,
  resetDemoPrice: PropTypes.func,
  setTempDemoPrice: PropTypes.func,
  setGuestList: PropTypes.func,
  dataTempBackCustomReady: PropTypes.bool,
  dataTempTourCustomReady: PropTypes.object,
  resetTempBackCustomReady: PropTypes.func,
  setTempTourCustomReady: PropTypes.func,
  resetFirstDestination: PropTypes.func,
  resetGuests: PropTypes.func,
  resetRoomAllocation: PropTypes.func,
  foc: PropTypes.number,
  getLanguageAll: PropTypes.func,
  tourNote: PropTypes.string,
  placeByType: PropTypes.array,
  getPlaceByPlaceTypeIdAction: PropTypes.func,
  t: PropTypes.func,
  companyProfile: PropTypes.object,
};
export default connect(
  mapStateToProps,
  {
    getCountries,
    getGuestTitleTypes,
    getIdentityTypes,
    getCityInCountry,
    resetStatusGetCityInCountry,
    getTourTypes,
    getTourPaxTypes,
    setHeadlineProgram,
    setDailyProgram,
    getMovementList,
    setCurrentPackage,
    getTourOperatorList,
    postDemoPrice,
    resetSetDailyProgramStatus,
    resetCustomPackage,
    resetDemoPrice,
    setTempDemoPrice,
    setGuestList,
    resetTempBackCustomReady,
    setTempTourCustomReady,
    resetFirstDestination,
    resetGuests,
    resetRoomAllocation,
    getLanguageAll,
    getPlaceByPlaceTypeIdAction,
  }
)(withTranslation()(CustomPackage));
