const style = {
  paddingBottomZeroNoBorder: {
    paddingBottom: 0,
    border: 'none',
  },
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  backgroundGold: {
    background: '#e6ca6b',
  },
  textFight: { textAlign: 'right' },
};

export default style;
