import React, { Component } from 'react';
import {
  GeneralInformation,
  AccommodationAndDate,
  TourItinerary,
  TourOperator,
  SideMenu,
  AdditionalInformation,
  PackageDescription,
} from '../shared/components/createPackage';
import { BreadCrumb } from '../shared/components/common';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../components/common/loader';
import { Grid, Container, Sticky } from 'semantic-ui-react';
import { SummaryQuotation } from '../shared/components/summary';
import { getCountries } from '../shared/actions/countriesAction';
import {
  getCityInCountry,
  resetStatusGetCityInCountry,
} from '../shared/actions/locationAction';
import {
  getGuestTitleTypes,
  getIdentityTypes,
  getTourTypes,
  getTourPaxTypes,
  postDemoPrice,
  resetDemoPrice,
} from '../shared/actions/tourTransactionAction';
import {
  dailyGenerateCopy,
  // setInitialAirport,
  dailyGenerate4,
  // setSummaryProgramReady,
  isAccommodationEmpty,
  validateDailyProgramActivityTime,
  transportValidation,
  validateDailyProgramOverloadTime,
} from '../../genericFunction/itineraryBuilder/dailyProgram';
import {
  setHeadlineProgram,
  setDailyProgram,
  getMovementList,
  setFirstDestination,
  resetSetDailyProgramStatus,
  resetCustomPackage,
  setGuestList,
  setGuestsAmount,
  setRoomAllocation,
  resetGuests,
  resetRoomAllocation,
} from '../shared/actions/customPackageAction';
import { setCurrentPackage } from '../shared/actions/generalAction';
import { generateDemoPrice } from '../../genericFunction/itineraryBuilder/demoPrice';
import { getTourOperatorList } from '../shared/actions/tourOperatorAction';
import {
  // postDemoPrice,
  // resetDemoPrice,
  postDemoJoinTourVariableDate,
  resetDemoJoinTour,
} from '../shared/actions/tourTransactionAction';
// import TourGalery from './components/tourGallery/TourGallery';
import {
  getReadyPackageById,
  resetGetReadyPackageByIdStatus,
} from '../shared/actions/readyPackageAction';
import { getPlaceByRegion } from '../shared/actions/locationAction';
import { Redirect } from 'react-router-dom';
import {
  sumValuesObject,
  changeGuest,
  fillFewGuests,
} from '../../genericFunction/itineraryBuilder/guest';
import {
  getTourTransactionSummary,
  resetStatusTourTransactionSummary,
} from '../shared/actions/bookingAction';
import { postEditQuotation } from '../shared/actions/quotationAction';
import {
  setTempDemoPrice,
  setTempDemoJoinTour,
} from '../shared/actions/tempAction';
//import { ModalConfirm } from './../shared/components/modal';
import { ModalAlert, ModalConfirmWithNote } from './../shared/components/modal';
// import { setTempDemoPrice } from '../shared/actions/tempAction';
import SegmentFormGuest from '../shared/components/guest/SegmentFormGuest';
import {
  validationFillGuest,
  isGuestError,
} from '../../genericFunction/validation';
import {
  resetTempBackCustomReady,
  setTempTourCustomReady,
  setTempIdTour,
} from '../shared/actions/tempAction';
import _ from 'lodash';
import { breadCrumbFixPrice, breadCrumb } from './constant/breadCrumb';
import StickyHeader from './components/stickyHeader/StickyHeader';
import styles from './styles';
import helperSetRoomAllocation from './service/data/setRoomAllocation';
import { getLanguageAll } from '../shared/actions/languageAction';
// import getImage from './service/helper/getImage';
import { setTourGuideInDailyProgram } from '../../genericFunction/tourGuide';
import {
  // convertToStringDate,
  // convertToStringTime,
  // SumDays,
  convertDateFormat,
} from '../../genericFunction/itineraryBuilder/timeHelper';
// ready package fix price
import {
  getFixedPackageById,
  resetGetFixedPackageByIdStatus,
} from '../shared/actions/fixedPackageAction';
import {
  fixDateDailyProgram,
  // setHeadlineFixedPrice,
} from '../seriesPackage/service/helper/dailyProgram';
import getRoomPrices from '../seriesPackage/service/data/getRoomPrices';
import {
  convertDataBookNowFixed,
  // getGuests,
} from '../../genericFunction/convertPackage';
import setStayPeriod from './service/data/setStayPeriod';
import sumObjectValue from '../../genericFunction/sumObjectValue';
import {
  initialSetReadyPackage,
  // changeFirstDepartureDate,
  changeFirstArrivalDate,
} from '../../genericFunction/itineraryBuilder/itineraryBuilder';
import { getPlaceByPlaceTypeIdAction } from '../shared/actions/locationAction';
import TitleAndImageGallery from '../seriesPackage/components/titleImageGallery/TitleAndImageGallery';
import { validateEmptyAirport } from './service/validation/headlineProgramValidation';
import { RECREATION } from '../../constant/activityTypes';
import { isCheckInDifferentDate } from '../../genericFunction/dailyProgramValidation';
import getErrorMessage from '../shared/services/helper/getErrorMessage';
import AdditionalServices from '../shared/components/createPackage/additionalServices/AdditionalServices';
import {
  initialAdditionalServices,
  updateAdditionalServiceAfterChangeDestination,
  additionalServicesValidation,
  getNewAdditonalServicesFromAdditionalServiceBookingTemplate,
  destructureAdditionalServices,
} from '../shared/services/additionalServices/processData';
// import { getHourAndMinute } from '../../genericFunction/moment';
import { withTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

class ReadyPackage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.generalInfoRef = React.createRef('generalInfo');
    this.destinationsRef = React.createRef('destinations');
    this.itineraryRef = React.createRef('itinerary');
    this.additionalInfoRef = React.createRef('additionalInfo');
    this.operatorRef = React.createRef('operator');
    this.guestsRef = React.createRef('guests');
    this.additionalServiceRef = React.createRef('additionalService');
    // this.additionalInformationRef = React.createRef('additionalInformation');
    this.state = {
      generalInformation: {
        TourName: '',
        GroupCapacity: '',
        FirstDestination: '',
        TourType: '',
        TourCategory: '',
        MinPax: 0,
      },
      guests: { adults: 0, childs: 0, infants: 0 },
      foc: {
        Qty: 0,
        Description: 'FoC',
      },
      roomAllocation: {
        ChildExtraBedQty: 0,
        ChildSharingRoomQty: 0,
        ChildSingleRoomQty: 0,
        ExtraBedQty: 0,
        BabyCrib: 0,
        NoBed: 0,
        SharingBedQty: 0,
        SharingRoomQty: 0,
        SingleRoomQty: 0,
      },
      HeadlineProgram: { Departures: [], MainPrograms: [], Returns: [] },
      overlayFixed: false,
      calculations: {
        topPassed: false,
        bottomPassed: false,
        topVisible: false,
        bottomVisible: false,
      },
      tourOperator: null,
      nextPageSummary: false,
      packageType: '',
      modalConfirm: false,
      messageConfirm: '',
      nameConfirm: '',
      nextPage: '',
      modalAlert: false,
      modalAlertSuccess: false,
      modalAlertFailed: false,
      goToPage: false,
      errors: {
        firstName: { error: false, message: '' },
        lastName: { error: false, message: '' },
        identityNbr: { error: false, message: '' },
        identityType: { error: false, message: '' },
        guestTitle: { error: false, message: '' },
        countryId: { error: false, message: '' },
      },
      openModalOperator: false,
      errorGeneralInformation: {
        TourName: { error: false, message: '' },
        GroupCapacity: { error: false, message: '' },
        TourType: { error: false, message: '' },
        TourCategory: { error: false, message: '' },
        FirstDestination: { error: false, message: '' },
      },
      //ready fix price
      selectedDateForVariableDate: new Date(),
      isNotValidDate: false,
      expiredDate: new Date(),
      isVariableDate: false,
      activeItem: 'generalInfo',
      linkGoto: '/summary/ready',
      availableDay: [],
      //end
      TourNote: '',
      Description: '',
      additionalInformation: [],
      additionalServices: [],
      errorAdditionalService: {
        isError: false,
        message: '',
      },
    };
  }
  componentDidMount() {
    const { packageType, id } = this.props.match.params;
    if (!this.props.dataTempBackCustomReady) {
      this.props.resetDemoJoinTour();
      this.props.resetCustomPackage();
      this.props.resetDemoPrice();
      this.props.resetGuests();
      this.props.resetRoomAllocation();
      this.props.getLanguageAll();
      this.getPackage(packageType, id);
    } else if (this.props.dataTempBackCustomReady) {
      this.setTour();
    }

    if (this.props.countries.length === 0) {
      this.props.getCountries();
    }
    if (this.props.identityTypes.length === 0) {
      this.props.getIdentityTypes();
    }
    if (this.props.guestTitles.length === 0) {
      this.props.getGuestTitleTypes();
    }
    if (this.props.placeByType) {
      this.props.getPlaceByPlaceTypeIdAction(1);
    }
    // if (this.props.cityList.length === 0) {
    this.props.getCityInCountry();
    // }
    this.props.setCurrentPackage('READY');
    this.props.getMovementList();
    this.props.getTourTypes();
    this.props.getTourPaxTypes();

    // this.props.getReadyPackageById(id);
    // this.initialSet();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.getReadyPackageByIdStatus === 'success' &&
      this.props.cityInCountryStatus === 'success'
    ) {
      this.initialSet(this.props.readyPackageById);
      this.props.resetGetReadyPackageByIdStatus();
      this.props.resetStatusGetCityInCountry();
    }
    if (
      this.props.getTourTransactionSummaryStatus === 'success' &&
      this.props.cityInCountryStatus === 'success'
    ) {
      this.initialSet(this.props.tourTransactionSummary);
      this.props.resetStatusTourTransactionSummary();
      this.props.resetStatusGetCityInCountry();
    }
    if (this.props.isSetDailyProgramSuccess && this.isGuestAllotmentEqual()) {
      this.getDemoPriceAfterUpdate();
      // this.props.resetSetDailyProgramStatus();
    }

    // additional service
    if (this.props.guests !== prevProps.guests) {
      this.setGuestInMandatory(this.props.guests);
    }
    if (
      this.props.HeadlineProgram.MainPrograms !==
      prevProps.HeadlineProgram.MainPrograms
    ) {
      let additionalServices = updateAdditionalServiceAfterChangeDestination(
        this.props.HeadlineProgram,
        this.state.additionalServices
      );
      this.setState({ ...this.state, additionalServices });
    }

    // ready package fix price
    if (
      this.props.getFixedPackageByIdStatus === 'success' &&
      this.props.cityInCountryStatus === 'success'
    ) {
      this.initialSet(this.props.fixedPackageById);
      this.props.resetGetFixedPackageByIdStatus();
      this.props.resetStatusGetCityInCountry();
    }
    //end
    //the function below is to get package data when user first login in ready package page
    this.getPackageAfterLogin();
  }
  setTour = () => {
    let { dataTempTourCustomReady } = this.props;
    const { packageType } = this.props.match.params;
    this.setState(
      {
        ...this.state,
        generalInformation: dataTempTourCustomReady.generalInformation,
        tourOperator: dataTempTourCustomReady.tourOperator,
        foc: dataTempTourCustomReady.foc,
        expiredDate: dataTempTourCustomReady.expiredDate,
        isVariableDate: dataTempTourCustomReady.isVariableDate,
        selectedDateForVariableDate:
          dataTempTourCustomReady.selectedDateForVariableDate,
        availableDay: dataTempTourCustomReady.availableDay,
        TourNote: dataTempTourCustomReady.TourNote,
        Description: dataTempTourCustomReady.Description,
        additionalInformation: dataTempTourCustomReady.additionalInformation,
        additionalServices: dataTempTourCustomReady.additionalServices,
        packageType:
          packageType === 'similar'
            ? 'similar'
            : packageType === 'ready-package-variable'
            ? 'FixedDateVariable'
            : packageType === 'quotation'
            ? 'quotation'
            : 'ready',
      },
      () => this.props.resetTempBackCustomReady()
    );
  };
  getPackage = (packageType, id) => {
    let type = '';
    switch (packageType) {
      case 'ready-package':
        type = 'ready';
        this.props.getReadyPackageById(id);
        break;
      case 'ready-package-variable':
        type = 'FixedDateVariable';
        this.props.getFixedPackageById(type, id);
        break;
      default:
        type = packageType === 'quotation' ? 'quotation' : 'similar';
        this.props.getTourTransactionSummary(id);
    }
    this.setState({ packageType: type });
  };
  getPackageAfterLogin = () => {
    if (
      this.props.expiredToken &&
      new Date(this.props.expiredToken).getTime() > new Date().getTime() &&
      !this.props.bookingLoading &&
      !this.props.readyPackageLoading &&
      !this.props.fixedPackageloading
    ) {
      const { packageType, id } = this.props.match.params;
      let currentPackage = packageType
        ? packageType === 'ready-package'
          ? this.props.readyPackageById
          : packageType === 'ready-package-variable'
          ? this.props.fixedPackageById
          : this.props.tourTransactionSummary
        : null;
      if (_.isEmpty(currentPackage)) {
        this.getPackage(packageType, id);
      }
    }
  };
  isFistDepartureLastArrivalExist = dailyProgram => {
    let firstDeparture = dailyProgram[0].Movements[0].MovementName;
    let lastArrival =
      dailyProgram[dailyProgram.length - 1].Movements[
        dailyProgram[dailyProgram.length - 1].Movements.length - 1
      ].MovementName;

    if (firstDeparture === 'DEPARTURE' && lastArrival === 'ARRIVAL') {
      return true;
    } else {
      return false;
    }
  };
  initialSet = async packageData => {
    let { packageType, selectedDateForVariableDate } = this.state;
    let { cityList, roomAllocation, movementList } = this.props;
    let headlineProgram = { ...packageData.HeadlineProgram };
    let type =
      packageType === 'ready'
        ? 'Ready'
        : packageType === 'FixedDateVariable'
        ? 'FixedDateVariable'
        : packageType === 'quotation'
        ? 'Quotation'
        : 'Similar';
    let isVariableDate =
      packageData.BookingDetailSum.PackageType === 'FixedDateVariable';
    let availableDay =
      isVariableDate && setStayPeriod(packageData.BookingTemplatePeriodDates);
    selectedDateForVariableDate =
      availableDay.length > 0 ? availableDay[0] : new Date();
    let roomAllocations =
      type === 'Quotation'
        ? helperSetRoomAllocation(packageData.TourPriceSum)
        : roomAllocation;
    headlineProgram = initialSetReadyPackage(
      packageData,
      type,
      cityList,
      roomAllocations,
      isVariableDate
    );
    packageData.DailyPrograms = setTourGuideInDailyProgram(
      packageData.DailyPrograms
    );
    let headlineProgramFixPrice =
      isVariableDate &&
      changeFirstArrivalDate(
        convertDateFormat(selectedDateForVariableDate, 'YYYY-MM-DDTHH:mm:ss'),
        headlineProgram
      );
    let newArrDepExist = this.isFistDepartureLastArrivalExist(
      packageData.DailyPrograms
    );
    let dP = !isVariableDate
      ? newArrDepExist
        ? dailyGenerate4(
            headlineProgram.Departures,
            headlineProgram.Returns,
            headlineProgram.MainPrograms,
            packageData.DailyPrograms,
            movementList,
            isVariableDate,
            this.props.listCityInCountry
          )
        : dailyGenerateCopy(
            headlineProgram.Departures,
            headlineProgram.Returns,
            headlineProgram.MainPrograms,
            packageData.DailyPrograms,
            movementList,
            isVariableDate,
            this.props.listCityInCountry
          )
      : fixDateDailyProgram(
          convertDateFormat(
            headlineProgramFixPrice.Departures[0].Date,
            'YYYY-MM-DD'
          ),
          packageData.DailyPrograms
        );

    this.props.setDailyProgram(dP);
    this.props.setHeadlineProgram(
      isVariableDate ? headlineProgramFixPrice : headlineProgram
    );
    this.props.setFirstDestination(headlineProgram.MainPrograms[0].City);
    let guests = null;
    if (type === 'Quotation') {
      guests = {
        adults: packageData.BookingDetailSum.AdultQty,
        childs: packageData.BookingDetailSum.ChildQty,
        infants: packageData.BookingDetailSum.InfantQty,
      };
      this.props.setGuestList(packageData.TourGuestSum);
      this.props.setGuestsAmount(guests);
      this.props.setRoomAllocation(roomAllocations);
    }
    await this.initialGeneralInfo(
      packageData.BookingDetailSum,
      headlineProgram.MainPrograms[0].City,
      guests,
      isVariableDate,
      availableDay,
      selectedDateForVariableDate,
      packageData.MinPax
    );
    await this.initialTourNote(packageData.TourNote);
    await this.initialPackageDescription(
      packageData.BookingDetailSum.Description
    );
    await this.initialSetAdditionalInformation(packageType, packageData);
    await this.initialSetAdditionalServices(packageData);
    this.getPlaceByRegion(headlineProgram.MainPrograms);
    if (type === 'Quotation') {
      this.setState(
        {
          ...this.state,
          tourOperator: packageData.BookingDetailSum.TourOperatorProfileId,
          foc: packageData.BookingDetailSum.FoC
            ? packageData.BookingDetailSum.FoC
            : {
                Qty: 0,
                Description: 'FoC',
              },
        },
        () => this.setDemoPriceQuotation()
      );
    }
  };

  initialSetAdditionalServices = async packageData => {
    const { companyProfile, fixedPackageById } = this.props;
    const { packageType } = this.props.match.params;
    const roomPrices = getRoomPrices(
      fixedPackageById ? fixedPackageById.Prices : []
    );
    let result = await getNewAdditonalServicesFromAdditionalServiceBookingTemplate(
      packageData.AdditionalServices || [],
      fixedPackageById &&
        fixedPackageById.BookingDetailSum &&
        fixedPackageById.BookingDetailSum.PackageType === 'FixedDateVariable'
        ? roomPrices[0].Currency
        : fixedPackageById &&
          fixedPackageById.BookingDetailSum &&
          fixedPackageById.BookingDetailSum.CurrencyId
        ? fixedPackageById.BookingDetailSum.CurrencyId
        : companyProfile.Currency
    );
    // ini buat ready
    let data = initialAdditionalServices(result || []);

    // ini buat quotation
    let originalData = initialAdditionalServices(
      packageData.AdditionalServices ? packageData.AdditionalServices : []
    );
    this.setState({
      ...this.state,
      additionalServices: packageType === 'quotation' ? originalData : data,
    });
  };

  initialSetAdditionalInformation = (packageType, packageData) => {
    let additionalinfo =
      packageType === 'ready'
        ? packageData
          ? packageData.Descriptions
          : []
        : packageType === 'similar' || packageType === 'quotation'
        ? packageData
          ? packageData.BookingTemplateDescriptionBindingModels
          : []
        : packageType === 'FixedDateVariable'
        ? packageData
          ? packageData.Descriptions
          : []
        : [];
    this.setState({ additionalInformation: additionalinfo });
  };
  setDemoPriceQuotation = () => {
    this.generateTourOperator(); //mengambil semua list tour operator
    this.setTourOperator(this.state.tourOperator);
    this.getDemoPrice(this.state.tourOperator);
  };
  handleContextRef = contextRef => this.setState({ contextRef });
  handleScrollToElement(e, { myref }) {
    window.scroll({ top: myref.current.offsetTop, behavior: 'smooth' });
  }
  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };
  scrollToElement = myref => {
    window.scroll({ top: myref.current.offsetTop, behavior: 'smooth' });
  };

  stickTopMenu = () => this.setState({ menuFixed: true });
  unStickTopMenu = () => {
    this.setState({ menuFixed: false });
  };
  handleGeneralInfo = (name, value) => {
    let generalInfo = { ...this.state.generalInformation };
    generalInfo[name] = value;
    this.setState({ ...this.state, generalInformation: generalInfo });
  };
  handleTourNote = (e, { value }) => {
    this.setState({ ...this.state, TourNote: value });
  };
  initialGeneralInfo = (
    detail,
    firstDestination,
    guests,
    isVariableDate,
    availableDay,
    selectedDateForVariableDate,
    minPax
  ) => {
    let generalInformation = {
      TourName: detail.Title,
      GroupCapacity: detail.GroupType,
      FirstDestination: firstDestination.Id,
      TourType: detail.TourCategory.Id,
      TourCategory: detail.TourPaxType.Id,
      MinPax: minPax,
    };
    this.setState({
      ...this.state,
      generalInformation: generalInformation,
      guests: guests !== null ? guests : this.state.guests,
      expiredDate: detail.ExpiredOn,
      isVariableDate,
      availableDay: availableDay.length > 0 ? availableDay : [],
      selectedDateForVariableDate,
    });
  };
  initialTourNote = tourNote => {
    this.setState({
      ...this.state,
      TourNote: tourNote,
    });
  };
  initialPackageDescription = description => {
    this.setState({ ...this.state, Description: description });
  };
  getDemoPriceAfterUpdate = () => {
    let { tourOperator, isVariableDate } = this.state;
    let { HeadlineProgram } = this.props;
    if (
      tourOperator &&
      isAccommodationEmpty(HeadlineProgram.MainPrograms) === false
    ) {
      isVariableDate
        ? this.getDemoJoinTour(tourOperator)
        : this.getDemoPrice(tourOperator);
    }
  };
  getDemoPriceObject = () => {
    let {
      HeadlineProgram,
      dailyProgram,
      roomAllocation,
      guests,
      guestList,
      readyPackageById,
      tourTransactionSummary,
    } = this.props;
    let {
      TourName,
      GroupCapacity,
      FirstDestination,
      TourType,
      TourCategory,
    } = this.state.generalInformation;
    let { foc, TourNote, additionalServices } = this.state;
    let demoPrice = generateDemoPrice(
      guests,
      roomAllocation,
      TourName,
      GroupCapacity,
      FirstDestination,
      TourType,
      TourCategory,
      HeadlineProgram,
      dailyProgram,
      guestList,
      foc,
      TourNote,
      additionalServices,
      readyPackageById,
      this.state.packageType,
      tourTransactionSummary
    );
    return demoPrice;
  };
  generateTourOperator = async openModal => {
    // untuk menghapush error
    await this.setState({
      ...this.state,
      errorAdditionalService: {
        isError: false,
        message: '',
      },
    });
    const context = this;
    const { guests, dailyProgram, HeadlineProgram } = this.props;
    let totalGuest = sumValuesObject(guests);
    let validateDailyProgram = validateDailyProgramActivityTime(
      dailyProgram,
      RECREATION
    );
    let validateCheckinNextDate = isCheckInDifferentDate(dailyProgram);
    let isDailyProgramOverload = validateDailyProgramOverloadTime(dailyProgram);
    let validateTransport = transportValidation(dailyProgram);
    let validatePlace = validateEmptyAirport(HeadlineProgram);
    let validateAdditionalServices = additionalServicesValidation(
      this.state.additionalServices
    );
    if (validatePlace) {
      this.setModalAlert(
        true,
        this.props.t('pleaseCheckYourFlight')
        // 'Please check your Flight, Flight place cannot be empty.'
      );
    } else if (validateTransport) {
      this.setModalAlert(
        true,
        this.props.t('transportationExceedTheDuration')
        // 'Please check your transportation, transportation with type Transfer exceed the duration.'
      );
    } else if (totalGuest === 0) {
      this.setModalAlert(
        true,
        this.props.t('fillAtLeastOneAdultGest')
        // 'Please check your pax arrangement, at least one adult guest'
      );
    } else if (validateDailyProgram) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithExcursionTime')
        // 'Please check your Itinerary, something wrong with excursion time'
      );
    } else if (isDailyProgramOverload) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithActivityTime')
        // 'Please check your Itinerary, something wrong with activity time'
      );
    } else if (validateCheckinNextDate) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithCheckInTime')
        // 'Please check your Itinerary, something wrong with check in time'
      );
    } else if (validateAdditionalServices) {
      this.setState(
        {
          ...this.state,
          errorAdditionalService: {
            isError: true,
            message:
              'Please check your additional services, quantity can not be 0',
          },
        },
        () => {
          window.scroll({
            top: this.additionalServiceRef.current.offsetTop,
            behavior: 'smooth',
          });
        }
      );
    } else {
      if (openModal) {
        context.setState({
          ...this.state,
          openModalOperator: openModal,
        });
      }
      let isReadyFixedPrice = this.state.packageType === 'FixedDateVariable';
      let demoPrice = this.getDemoPriceObject();
      this.props
        .getTourOperatorList(demoPrice, isReadyFixedPrice)
        .then(() => {
          // context.getTourOperatorProfiles(res.value.data);
        })
        .catch(err => {
          context.setState({
            ...context.state,
            modalAlert: true,
            messageConfirm: getErrorMessage(err),
            openModalOperator: false,
          });
        });
    }
  };
  setTourOperator = operatorId => {
    const { isVariableDate } = this.state;
    let state = this.state;
    state.tourOperator = operatorId;
    state.openModalOperator = false;
    this.setState({ state });
    isVariableDate
      ? this.getDemoJoinTour(operatorId)
      : this.getDemoPrice(operatorId);
  };
  getDemoPrice = operatorId => {
    let demoPrice = this.getDemoPriceObject();
    demoPrice.TourOperatorId = operatorId;
    this.props.setTempDemoPrice(demoPrice);
    this.props.postDemoPrice(demoPrice);
    demoPrice.TourNote = this.props.dataTempTourCustomReady.TourNote;
  };

  getPlaceByRegion = summaryProgram => {
    // eslint-disable-next-line
    summaryProgram.map(item => {
      if (item.AccommodationSummary) {
        item.Region && this.props.getPlaceByRegion(item.Region);
      }
      return item;
    });
  };

  setModalAlert = (value, message) => {
    this.setState({
      ...this.state,
      modalAlert: value,
      messageConfirm: message,
    });
  };
  handleSummary = () => {
    let {
      errors,
      expiredDate,
      packageType,
      selectedDateForVariableDate,
      isVariableDate,
      availableDay,
    } = this.state;
    let { guestList, dailyProgram, HeadlineProgram } = this.props;
    let newErrors = validationFillGuest(guestList, errors);
    let validateDailyProgram = validateDailyProgramActivityTime(
      dailyProgram,
      RECREATION
    );
    let validateCheckinNextDate = isCheckInDifferentDate(dailyProgram);
    let isDailyProgramOverload = validateDailyProgramOverloadTime(dailyProgram);
    let validateTransport = transportValidation(dailyProgram);
    let validatePlace = validateEmptyAirport(HeadlineProgram);
    if (validatePlace) {
      this.setModalAlert(
        true,
        this.props.t('pleaseCheckYourFlight')
        // 'Please check your Flight, Flight place cannot be empty.'
      );
    } else if (validateTransport) {
      this.setModalAlert(
        true,
        this.props.t('transportationExceedTheDuration')
        // 'Please check your transportation, transportation with type Transfer exceed the duration.'
      );
    } else if (validateDailyProgram) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithExcursionTime')
        // 'Please check your Itinerary, something wrong with excursion time'
      );
    } else if (isDailyProgramOverload) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithActivityTime')
        // 'Please check your Itinerary, something wrong with activity time'
      );
    } else if (validateCheckinNextDate) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithCheckInTime')
        // 'Please check your Itinerary, something wrong with check in time'
      );
    } else if (isGuestError(newErrors)) {
      this.setState(
        {
          ...this.state,
          errors: newErrors,
        },
        () => this.scrollToElement(this.guestsRef)
      );
    } else {
      let data = {
        generalInformation: this.state.generalInformation,
        tourOperator: this.state.tourOperator,
        foc: this.state.foc,
        isVariableDate: isVariableDate,
        expiredDate: expiredDate,
        selectedDateForVariableDate: selectedDateForVariableDate,
        availableDay: availableDay,
        additionalInformation: this.state.additionalInformation,
        Description: this.state.Description,
        TourNote: this.state.TourNote,
        additionalServices: this.state.additionalServices,
      };
      this.props.setTempTourCustomReady(data);
      this.props.setTempIdTour(this.props.match.params.id);
      this.setState({
        nextPageSummary: !this.state.nextPageSummary,
        linkGoto: isVariableDate
          ? '/summary/ready-package-variable'
          : `/summary/${packageType === 'similar' ? 'similar' : 'ready'}`,
      });
    }
  };
  handleEditQuotation = (e, { name }) => {
    const { readyPackageById, tourTransactionSummary } = this.props;
    const { packageType, TourNote } = this.state;
    const context = this;
    this.closeModal();
    let packageData =
      packageType === 'ready-package'
        ? readyPackageById
        : Object.keys(tourTransactionSummary).length > 0
        ? tourTransactionSummary
        : null;
    let { tourOperator } = this.state;
    let demoPrice = this.getDemoPriceObject();
    demoPrice.TourTransactionId = packageData.BookingDetailSum.Id;
    demoPrice.AcceptQuotationAtOnce = name === 'save' ? false : true;
    demoPrice.TourOperatorId = tourOperator;
    demoPrice.IsQuotation = name === 'save' ? true : false;
    demoPrice.CompanyCode = packageData.BookingDetailSum.CreatedBy.CompanyCode;
    demoPrice.TourNote = TourNote;
    this.props
      .postEditQuotation(packageData.BookingDetailSum.Id, demoPrice)
      .then(res => {
        context.setState({
          ...context.state,
          nextPage: name === 'save' ? 'quotation' : 'booking',
          modalAlertSuccess: true,
          modalAlertFailed: false,
          messageConfirm:
            name === 'save'
              ? this.props.t('quotationHasBeenSaved', {
                  id: packageData.BookingDetailSum.Id,
                })
              : this.props.t('bookingSuccessfullyCreated', {
                  id: res.value.data.BookingDetailSum.Id,
                }),
          // ? `Quotation ${packageData.BookingDetailSum.Id} has been saved`
          // : `Booking code ${
          //     res.value.data.BookingDetailSum.Id
          //   } successfully created`,
        });
      })
      // eslint-disable-next-line
      .catch(err => {
        context.setState({
          ...context.state,
          modalAlertFailed: true,
          modalAlertSuccess: false,
          messageConfirm: getErrorMessage(err),
        });
      });
  };
  confirmQuotation = name => {
    let { errors } = this.state;
    let { guestList, dailyProgram } = this.props;
    let state = { ...this.state };
    let newErrors = validationFillGuest(guestList, errors);
    let validateDailyProgram = validateDailyProgramActivityTime(
      dailyProgram,
      RECREATION
    );
    let validateCheckinNextDate = isCheckInDifferentDate(dailyProgram);
    let isDailyProgramOverload = validateDailyProgramOverloadTime(dailyProgram);
    let validateTransport = transportValidation(this.props.dailyProgram);
    if (validateTransport) {
      this.setModalAlert(
        true,
        this.props.t('transportationExceedTheDuration')
        // 'Please check your transportation, transportation with type Transfer exceed the duration.'
      );
    } else if (validateDailyProgram) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithExcursionTime')
        // 'Please check your Itinerary, something wrong with excursion time'
      );
    } else if (isDailyProgramOverload) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithActivityTime')
        // 'Please check your Itinerary, something wrong with activity time'
      );
    } else if (validateCheckinNextDate) {
      this.setModalAlert(
        true,
        this.props.t('somethingWrongWithCheckInTime')
        // 'Please check your Itinerary, something wrong with check in time'
      );
    } else {
      if (name === 'deal') {
        if (isGuestError(newErrors)) {
          this.setState(
            {
              ...this.state,
              errors: newErrors,
            },
            () => this.scrollToElement(this.guestsRef)
          );
        } else {
          state.messageConfirm = this.props.t('createTransactionFromQuotation');
          // 'Do you want to create transaction from this Quotation?';
          state.nameConfirm = 'deal';
          this.handleQuotationConfirmation(state);
        }
      } else {
        state.messageConfirm = this.props.t('saveThisQuotation');
        // 'Do you want to save this Quotation?';
        state.nameConfirm = 'save';
        this.handleQuotationConfirmation(state);
      }
    }
  };
  handleQuotationConfirmation = state => {
    this.setState({
      ...this.state,
      modalConfirm: true,
      messageConfirm: state.messageConfirm,
      nameConfirm: state.nameConfirm,
      generalInformation: state.generalInformation,
      TourNote: state.TourNote,
    });
  };
  closeModal = () => {
    this.setState({ modalConfirm: false });
  };
  handleCloseAlert = () => {
    let alertStatus = this.state.modalAlertSuccess;
    this.setState({
      ...this.state,
      goToPage: alertStatus ? true : false,
      modalAlert: false,
      modalAlertSuccess: false,
      modalAlertFailed: false,
    });
  };
  handleChangeGuest = async (e, { name, value, objectname }) => {
    let { guestList } = this.props;
    let { errors } = this.state;
    let newListGuest = await changeGuest(name, guestList, objectname, value);
    let newErrorsGuests = (await isGuestError(errors))
      ? await validationFillGuest(newListGuest, errors)
      : errors;
    await this.props.setGuestList(newListGuest);
    this.setState({ ...this.state, errors: newErrorsGuests });
  };
  uploadTemplate = guests => {
    let detailGuests = fillFewGuests(guests.rows, this.props.guestList, 'new');
    this.props.setGuestList(detailGuests);
  };
  closeModalOperator = () => {
    this.setState({ ...this.state, openModalOperator: false });
  };
  generateOperator = () => {
    this.generateTourOperator(true);
  };
  setErrorGeneralInformation = errorGeneralInformation =>
    this.setState({ ...this.state, errorGeneralInformation });
  addFocAmount = val => {
    this.setState({ ...this.state, foc: val }, () =>
      this.getDemoPriceAfterUpdate()
    );
  };

  // ready fix price
  handleChangeSelectedDateForVariable = async date => {
    let contex = this;

    const { DailyPrograms } = contex.props.fixedPackageById;
    let headlineafterChangeDate = changeFirstArrivalDate(
      convertDateFormat(date, 'YYYY-MM-DDTHH:mm:ss'),
      this.props.HeadlineProgram
    );
    let dailyProgramAfterChangeDate = await fixDateDailyProgram(
      convertDateFormat(
        headlineafterChangeDate.Departures[0].Date,
        'YYYY-MM-DD'
      ),
      DailyPrograms
    );
    // let headlineafterChangeDate = await setHeadlineFixedPrice(
    //   convertDateFormat(date, 'YYYY-MM-DDTHH:mm:ss'),
    //   this.props.HeadlineProgram
    // );
    contex.props.setDailyProgram(dailyProgramAfterChangeDate);
    contex.props.setHeadlineProgram(headlineafterChangeDate);
    this.setState({
      selectedDateForVariableDate: date,
    });
  };

  getDemoJoinTour = TourOperatorProfileId => {
    let { id } = this.props.match.params;
    let { HeadlineProgram, roomAllocation, guests, guestList } = this.props;
    const { additionalServices } = this.state;
    let _additionalServices = destructureAdditionalServices(additionalServices);
    let dataBookNow = convertDataBookNowFixed(
      false,
      false,
      guests,
      roomAllocation,
      [],
      guestList
    );
    dataBookNow.TourOperatorProfileId = TourOperatorProfileId;
    this.props.setTempDemoJoinTour({
      splitCommision: false,
      printCommision: false,
      guests: guests,
      roomAllocation: roomAllocation,
      supplementObject: [],
      detailGuest: guestList,
      startTour: HeadlineProgram.Departures[0].Date,
      endTour: HeadlineProgram.Returns[HeadlineProgram.Returns.length - 1].Date,
      tourOperatorId: TourOperatorProfileId,
      title: this.state.generalInformation.TourName,
      additionalServices: _additionalServices,
    });
    dataBookNow.StartTour = HeadlineProgram.Departures[0].Date;
    dataBookNow.EndTour =
      HeadlineProgram.Returns[HeadlineProgram.Returns.length - 1].Date;
    dataBookNow.Title = this.state.generalInformation.TourName;
    // dataBookNow.Description = this.state.Description;
    // dataBookNow.AdditionalInformation = this.state.additionalInformation;
    // dataBookNow.Guests = getGuests(this.props.listGuest);
    dataBookNow.AdditionalServices = _additionalServices;
    this.props.postDemoJoinTourVariableDate(id, dataBookNow).catch(err => {
      this.setState({
        ...this.state,
        modalAlert: true,
        messageConfirm: getErrorMessage(err),
      });
    });
    this.props.setTempIdTour(id);
  };

  //end/
  handleChange = () => {
    //e, { value }
    let GeneralInformation = { ...this.state.generalInformation };
    this.setState({ ...this.state, generalInformation: GeneralInformation });
  };
  getBreadCrumbLabel = packageType => {
    switch (packageType) {
      case 'ready':
        return 'Ready Package';
      case 'similar':
        return 'Similar Package';
      case 'quotation':
        return 'Quotation';
      default:
        return '';
    }
  };
  isGuestAllotmentEqual = () => {
    let { roomAllocation, guests } = this.props;
    let totalGuest = sumObjectValue(guests);
    let totalAllocation = sumObjectValue(roomAllocation);
    return totalGuest === totalAllocation ? true : false;
  };

  // ================== additional services ==================
  setAdditionalServicesList = data => {
    this.setState({ ...this.state, additionalServices: data });
  };

  getAddService = (name, addServicesList) => {
    let addService = addServicesList.find(item => {
      return item.AdditionalServiceId === name; // name === id
    });
    let index = addServicesList.findIndex(item => {
      return item.AdditionalServiceId === name;
    });
    return { addService: addService, index: index };
  };

  handleIncreaseAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    addService.Quantity = value + 1;
    addServicesList[index] = addService;
    this.setState(
      { ...this.state, additionalServices: addServicesList },
      () => {
        this.getDemoPriceAfterUpdate();
      }
    );
  };

  handleDecreaseAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    if (value - 1 > 0) {
      addService.Quantity = value - 1;
      addServicesList[index] = addService;
      this.setState(
        { ...this.state, additionalServices: addServicesList },
        () => {
          this.getDemoPriceAfterUpdate();
        }
      );
    }
  };

  handleChangeAmount = (e, { name, value }) => {
    let addServicesList = [...this.state.additionalServices];
    let { addService, index } = this.getAddService(name, addServicesList);
    if (value - 1 > 0) {
      addService.Quantity = value;
      addServicesList[index] = addService;
      this.setState(
        { ...this.state, additionalServices: addServicesList },
        () => {
          this.getDemoPriceAfterUpdate();
        }
      );
    }
  };

  handleDeleteService = () => {
    this.getDemoPriceAfterUpdate();
  };

  setGuestInMandatory = guests => {
    const { additionalServices } = this.state;
    let additionalServices_newMandatoryQty = additionalServices.map(item => {
      let result = item.IsInfantCount
        ? {
            ...item,
            Quantity: guests.adults + guests.childs + guests.infants,
          }
        : item.IsMandatory
        ? {
            ...item,
            Quantity: guests.adults + guests.childs,
          }
        : {
            ...item,
          };
      return result;
    });
    this.setState({
      ...this.state,
      additionalServices: additionalServices_newMandatoryQty,
    });
  };

  // ================== end additional services ==================

  render() {
    const {
      generalInformation,
      tourOperator,
      packageType,
      modalConfirm,
      messageConfirm,
      nameConfirm,
      nextPageSummary,
      nextPage,
      modalAlertSuccess,
      modalAlertFailed,
      goToPage,
      errors,
      contextRef,
      modalAlert,
      menuFixed,
      errorGeneralInformation,
      selectedDateForVariableDate,
      isNotValidDate,
      expiredDate,
      isVariableDate,
      activeItem,
      linkGoto,
      TourNote,
      Description,
      additionalInformation,
      additionalServices,
      errorAdditionalService,
    } = this.state;
    const {
      demoPriceLoading,
      demoPrice,
      guestList,
      tourTransactionSummary,
      demoJoinTour,
      // readyPackageById,
    } = this.props;

    const roomPrices = getRoomPrices(
      this.props.fixedPackageById ? this.props.fixedPackageById.Prices : []
    );
    // let images = getImage(this.props);

    let disable =
      Object.keys(!isVariableDate ? demoPrice : demoJoinTour).length > 0
        ? false
        : true;
    let detail = !isVariableDate
      ? demoPrice
      : demoPrice.BookingDetailSum
      ? demoJoinTour
      : demoJoinTour.BookingDetailSum;
    // let disable = Object.keys(demoPrice).length > 0 ? false : true;
    // let detail = demoPrice ? demoPrice.BookingDetailSum : null;
    // let description =
    //   packageType === 'ready'
    //     ? readyPackageById
    //       ? readyPackageById.Descriptions
    //       : []
    //     : packageType === 'similar' || packageType === 'quotation'
    //     ? tourTransactionSummary
    //       ? tourTransactionSummary.BookingTemplateDescriptionBindingModels
    //       : []
    //     : packageType === 'FixedDateVariable'
    //     ? this.props.fixedPackageById
    //       ? this.props.fixedPackageById.Descriptions
    //       : []
    //     : [];
    let breadCrumbLabel = this.getBreadCrumbLabel(packageType);
    if (nextPageSummary) {
      return <Redirect to={linkGoto} />;
    }

    if (goToPage && nextPage === 'quotation') {
      return <Redirect to="/my-profile/quotation" />;
    } else if (goToPage && nextPage === 'booking') {
      return <Redirect to="/my-profile/booking" />;
    }
    return (
      <Container
        style={styles.readyPackageContainer}
        className="padding-side-twentyfive container-fit"
      >
        <DynamicTitle title="Ready Package" />
        <ModalConfirmWithNote
          openModal={modalConfirm}
          size="tiny"
          close={this.closeModal}
          confirm={this.handleEditQuotation}
          message={messageConfirm}
          nameConfirm={nameConfirm}
          tourNote={TourNote}
          handleTourNote={this.handleTourNote}
          packageType={packageType}
        />
        {/* <ModalConfirm
          openModal={modalConfirm}
          size="tiny"
          close={this.closeModal}
          confirm={this.handleEditQuotation}
          message={messageConfirm}
          nameConfirm={nameConfirm}
        /> */}
        <ModalAlert
          openModal={modalAlertSuccess || modalAlertFailed || modalAlert}
          size="tiny"
          close={this.handleCloseAlert}
          message={messageConfirm}
        />
        <BreadCrumb
          sections={
            isVariableDate ? breadCrumbFixPrice : breadCrumb(breadCrumbLabel)
          }
        />
        <StickyHeader
          menuFixed={menuFixed}
          disable={disable}
          detail={detail}
          handleSummary={this.handleSummary}
          generalInfoRef={this.generalInfoRef}
          destinationsRef={this.destinationsRef}
          itineraryRef={this.itineraryRef}
          operatorRef={this.operatorRef}
          guestsRef={this.guestsRef}
          handleScrollToElement={this.handleScrollToElement}
          stickTopMenu={this.stickTopMenu}
          unStickTopMenu={this.unStickTopMenu}
          confirmQuotation={this.confirmQuotation}
          packageType={packageType}
          roomPrices={roomPrices}
          activeItem={activeItem}
          handleItemClick={this.handleItemClick}
          isFixedPrice={isVariableDate}
          // additionalInformationRef={this.additionalInformationRef}
          additionalServiceRef={this.additionalServiceRef}
          additionalInfoRef={this.additionalInfoRef}
        />

        <div ref={this.handleContextRef}>
          <Grid>
            <Grid.Row style={styles.noPaddingBot}>
              <Grid.Column width={9}>
                {/* {isVariableDate ? (
                  <TitleAndImageGallery package={this.props.fixedPackageById} />
                ) : (
                  <TourGalery
                    title={generalInformation.TourName}
                    images={images}
                  />
                )} */}
                <TitleAndImageGallery
                  package={
                    isVariableDate
                      ? this.props.fixedPackageById
                      : ['similar', 'quotation'].indexOf(packageType) !== -1
                      ? this.props.tourTransactionSummary
                        ? this.props.tourTransactionSummary
                        : this.props.readyPackageById
                      : this.props.readyPackageById
                  }
                />

                <div ref={this.generalInfoRef}>
                  <PackageDescription description={Description} />
                  <GeneralInformation
                    handleGeneralInfo={this.handleGeneralInfo}
                    generalInfo={generalInformation}
                    errorGeneralInformation={errorGeneralInformation}
                    setErrorGeneralInformation={this.setErrorGeneralInformation}
                    foc={this.state.foc}
                    addFocAmount={this.addFocAmount}
                    guests={this.props.guests}
                    // ready fix price
                    isVariableDate={isVariableDate}
                    selectedDate={selectedDateForVariableDate}
                    handleChangeSelectedDate={
                      this.handleChangeSelectedDateForVariable
                    }
                    isNotValidDate={isNotValidDate}
                    expiredDate={expiredDate}
                    availableDay={this.state.availableDay}
                    roomPrices={
                      this.props.fixedPackageById
                        ? this.props.fixedPackageById.Prices
                        : {}
                    }
                    //end
                  />
                </div>
                <div ref={this.destinationsRef}>
                  <AccommodationAndDate
                    headlineProgram={this.props.HeadlineProgram}
                    generalInfo={generalInformation}
                    errorGeneralInformation={errorGeneralInformation}
                    setErrorGeneralInformation={this.setErrorGeneralInformation}
                    guests={this.props.guests}
                    isVariableDate={isVariableDate}
                    selectedDate={selectedDateForVariableDate}
                  />
                </div>
                <div ref={this.itineraryRef}>
                  <TourItinerary isVariableDate={isVariableDate} />
                </div>
                <div ref={this.additionalServiceRef}>
                  <AdditionalServices
                    selectedAdditionalServices={additionalServices}
                    headlineProgram={this.props.HeadlineProgram}
                    guests={guestList}
                    setAdditionalServices={this.setAdditionalServicesList}
                    handleIncrease={this.handleIncreaseAmount}
                    handleDecrease={this.handleDecreaseAmount}
                    handleDeleteService={this.handleDeleteService}
                    companyProfile={this.props.companyProfile}
                    roomPrices={roomPrices}
                    fixedPackageById={
                      _.isEmpty(this.props.fixedPackageById)
                        ? this.props.tourTransactionSummary
                        : this.props.fixedPackageById
                    }
                    demoPriceLoading={demoPriceLoading}
                    handleChangeAmount={this.handleChangeAmount}
                    errorAdditionalService={errorAdditionalService}
                  />
                </div>
                <div ref={this.operatorRef}>
                  <TourOperator
                    generateTourOperator={this.generateOperator}
                    tourOperator={tourOperator}
                    setTourOperator={this.setTourOperator}
                    closeModal={this.closeModalOperator}
                    openModal={this.state.openModalOperator}
                    isFixPrice={isVariableDate}
                  />
                </div>
                <div ref={this.guestsRef} />
                <div ref={this.additionalInformationRef} />
              </Grid.Column>
              <Grid.Column width={7}>
                <Sticky context={contextRef}>
                  <SideMenu
                    generalInfoRef={this.generalInfoRef}
                    destinationsRef={this.destinationsRef}
                    itineraryRef={this.itineraryRef}
                    operatorRef={this.operatorRef}
                    guestsRef={this.guestsRef}
                    additionalInfoRef={this.additionalInfoRef}
                    additionalServiceRef={this.additionalServiceRef}
                    handleScroll={this.handleScrollToElement}
                    demoPriceLoading={demoPriceLoading}
                    demoPrice={demoPrice}
                    handleSummary={this.handleSummary}
                    packageType={packageType}
                    confirmQuotation={this.confirmQuotation}
                    nextPage={nextPage}
                    // ready fix price
                    roomPrices={roomPrices}
                    expiredDate={expiredDate}
                    isVariableDate={isVariableDate}
                    menuFixed={menuFixed}
                    demoJoinTour={demoJoinTour}
                    //end
                    isAdditionalThereInfo={true}
                  />
                </Sticky>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>

        {/* <StickyHeader
            menuFixed={menuFixed}
            disable={disable}
            detail={detail}
            handleSummary={this.handleSummary}
            generalInfoRef={this.generalInfoRef}
            destinationsRef={this.destinationsRef}
            itineraryRef={this.itineraryRef}
            operatorRef={this.operatorRef}
            guestsRef={this.guestsRef}
            handleScrollToElement={this.handleScrollToElement}
            stickTopMenu={this.stickTopMenu}
            unStickTopMenu={this.unStickTopMenu}
            confirmQuotation={this.confirmQuotation}
            packageType={packageType}
          />
       */}
        <div>
          <Grid>
            <Grid.Row style={styles.noPaddingTop}>
              <Grid.Column width={16}>
                <SegmentFormGuest
                  guests={guestList}
                  handleChange={this.handleChangeGuest}
                  errors={errors}
                  uploadTemplate={this.uploadTemplate}
                />
                {packageType === 'quotation' && (
                  <SummaryQuotation
                    tourTransactionSummary={tourTransactionSummary}
                    demoPrice={demoPrice}
                    confirmQuotation={this.confirmQuotation}
                    demoPriceLoading={demoPriceLoading}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div ref={this.additionalInfoRef}>
          <AdditionalInformation descriptions={additionalInformation} />
        </div>
        <Loader
          description="loading"
          active={this.props.loadingCountries || this.props.loadingQuotation}
          page={true}
        />
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    countries: state.countries.countries,
    identityTypes: state.tourTransaction.identityTypes,
    guestTitles: state.tourTransaction.guestTitleTypes,
    readyPackageById: state.readyPackage.readyPackageById,
    cityList: state.location.cityInCountry,
    cityInCountryStatus: state.location.cityInCountryStatus,
    roomAllocation: state.customPackage.roomAllocation,
    HeadlineProgram: state.customPackage.HeadlineProgram,
    dailyProgram: state.customPackage.dailyProgram,
    guests: state.customPackage.guests,
    guestList: state.customPackage.guestList,
    tourOperatorList: state.tourOperator.tourOperatorList,
    demoPriceLoading: state.tourTransaction.loading,
    demoPrice: state.tourTransaction.demoPrice,
    getReadyPackageByIdStatus: state.readyPackage.getReadyPackageByIdStatus,
    movementList: state.customPackage.movementList,
    tourTransactionSummary: state.booking.tourTransactionSummary,
    getTourTransactionSummaryStatus:
      state.booking.getTourTransactionSummaryStatus,
    isSetDailyProgramSuccess: state.customPackage.isSetDailyProgramSuccess,
    loadingQuotation: state.quotation.loading,
    dataTempBackCustomReady: state.temp.dataBackCustomReady,
    dataTempTourCustomReady: state.temp.dataTourCustomReady,
    expiredToken: state.authentication.expiredToken,
    bookingLoading: state.booking.loadingSeeDetail,
    readyPackageLoading: state.readyPackage.loading,
    // ready package fix price
    fixedPackageById: state.fixedPackage.fixedPackageById,
    getFixedPackageByIdStatus: state.fixedPackage.getFixedPackageByIdStatus,
    fixedPackageloading: state.fixedPackage.loading,
    demoJoinTour: state.tourTransaction.demoJoinTour,
    //end
    placeByType: state.location.placeByType,
    // foc: state.readyPackage.foc,
    listCityInCountry: state.location.cityInCountry,
    companyProfile: state.company.companyProfile,
  };
};
ReadyPackage.propTypes = {
  loadingCountries: PropTypes.bool,
  getCountries: PropTypes.func,
  countries: PropTypes.array,
  identityTypes: PropTypes.array,
  guestTitles: PropTypes.array,
  getGuestTitleTypes: PropTypes.func,
  getIdentityTypes: PropTypes.func,
  getCityInCountry: PropTypes.func,
  resetStatusGetCityInCountry: PropTypes.func,
  cityInCountryStatus: PropTypes.string,
  getTourTypes: PropTypes.func,
  getTourPaxTypes: PropTypes.func,
  cityList: PropTypes.array,
  roomAllocation: PropTypes.object,
  HeadlineProgram: PropTypes.object,
  setHeadlineProgram: PropTypes.func,
  setDailyProgram: PropTypes.func,
  getMovementList: PropTypes.func,
  setCurrentPackage: PropTypes.func,
  dailyProgram: PropTypes.array,
  guests: PropTypes.object,
  guestList: PropTypes.array,
  getTourOperatorList: PropTypes.func,
  tourOperatorList: PropTypes.array,
  postDemoPrice: PropTypes.func,
  demoPriceLoading: PropTypes.bool,
  demoPrice: PropTypes.object,
  match: PropTypes.object,
  readyPackageById: PropTypes.object,
  getReadyPackageById: PropTypes.func,
  resetGetReadyPackageByIdStatus: PropTypes.func,
  getReadyPackageByIdStatus: PropTypes.string,
  movementList: PropTypes.array,
  setFirstDestination: PropTypes.func,
  // setAdditionalInfo: PropTypes.func,
  getPlaceByRegion: PropTypes.func,
  getTourTransactionSummary: PropTypes.func,
  tourTransactionSummary: PropTypes.object,
  getTourTransactionSummaryStatus: PropTypes.string,
  resetStatusTourTransactionSummary: PropTypes.func,
  postEditQuotation: PropTypes.func,
  isSetDailyProgramSuccess: PropTypes.bool,
  resetSetDailyProgramStatus: PropTypes.func,
  resetCustomPackage: PropTypes.func,
  resetDemoPrice: PropTypes.func,
  setTempDemoPrice: PropTypes.func,
  setGuestList: PropTypes.func,
  setGuestsAmount: PropTypes.func,
  setRoomAllocation: PropTypes.func,
  loadingQuotation: PropTypes.bool,
  dataTempBackCustomReady: PropTypes.bool,
  resetTempBackCustomReady: PropTypes.func,
  resetGuests: PropTypes.func,
  resetRoomAllocation: PropTypes.func,
  setTempTourCustomReady: PropTypes.func,
  setTempIdTour: PropTypes.func,
  dataTempTourCustomReady: PropTypes.object,
  expiredToken: PropTypes.string,
  bookingLoading: PropTypes.bool,
  readyPackageLoading: PropTypes.bool,
  foc: PropTypes.number,
  getLanguageAll: PropTypes.func,
  //ready package variable
  getFixedPackageByIdStatus: PropTypes.string,
  fixedPackageById: PropTypes.object,
  resetGetFixedPackageByIdStatus: PropTypes.func,
  getFixedPackageById: PropTypes.func,
  fixedPackageloading: PropTypes.bool,
  setTempDemoJoinTour: PropTypes.func,
  postDemoJoinTourVariableDate: PropTypes.func,
  demoJoinTour: PropTypes.func,
  resetDemoJoinTour: PropTypes.func,
  //end
  TourNote: PropTypes.object,
  getPlaceByPlaceTypeIdAction: PropTypes.func,
  placeByType: PropTypes.array,
  listCityInCountry: PropTypes.array,
  t: PropTypes.func,
  companyProfile: PropTypes.object,
};
export default connect(
  mapStateToProps,
  {
    getCountries,
    getGuestTitleTypes,
    getIdentityTypes,
    getCityInCountry,
    resetStatusGetCityInCountry,
    getTourTypes,
    getTourPaxTypes,
    setHeadlineProgram,
    setDailyProgram,
    getMovementList,
    setCurrentPackage,
    getTourOperatorList,
    postDemoPrice,
    getReadyPackageById,
    resetGetReadyPackageByIdStatus,
    setFirstDestination,
    getPlaceByRegion,
    getTourTransactionSummary,
    resetStatusTourTransactionSummary,
    postEditQuotation,
    resetSetDailyProgramStatus,
    resetCustomPackage,
    resetDemoPrice,
    setTempDemoPrice,
    setGuestList,
    setGuestsAmount,
    setRoomAllocation,
    resetTempBackCustomReady,
    resetGuests,
    resetRoomAllocation,
    setTempTourCustomReady,
    setTempIdTour,
    getLanguageAll,
    // ready fix price
    getFixedPackageById,
    resetGetFixedPackageByIdStatus,
    postDemoJoinTourVariableDate,
    setTempDemoJoinTour,
    resetDemoJoinTour,
    //end
    getPlaceByPlaceTypeIdAction,
  }
)(withTranslation()(ReadyPackage));
