import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Button } from 'semantic-ui-react';
import styles from '../../styles';
import { ButtonCloseModal } from '../../../shared/components/common';
import RegisterCompany from './ShortRegisterCompany';
import RegisterUser from './ShortRegisterUser';
import { connect } from 'react-redux';
import { getCountries } from '../../../shared/actions/countriesAction';
import { getCurrencies } from '../../../shared/actions/currenciesAction';
import options from '../../../../genericFunction/selectOptionsConverter';
import { validationShortRegisCompany } from '../../../../genericFunction/validation';
import { withTranslation } from 'react-i18next';

class ModalRegisCompany extends Component {
  state = {
    formCompany: {
      companyName: { data: '', error: false, message: '' },
      companyCountry: { data: '', error: false, message: '' },
      companyTelephone: { data: '', error: false, message: '' },
      companyAddress: { data: '', error: false, message: '' },
      companyCurrency: { data: '', error: false, message: '' },
      userFirstName: { data: '', error: false, message: '' },
      userLastName: { data: '', error: false, message: '' },
      userPhoneNumber: { data: '', error: false, message: '' },
      userEmail: { data: '', error: false, message: '' },
    },
  };

  componentDidMount() {
    this.props.getCountries();
    this.props.getCurrencies();
  }

  handleChange = (e, { name, value }) => {
    const state = { ...this.state.formCompany };
    const objectInState = state[name];
    objectInState.data = value;
    this.setState({ ...this.state, [name]: objectInState });
  };

  handleRegister = () => {
    let { formCompany } = this.state;
    let validationForm = validationShortRegisCompany(formCompany);
    if (!validationForm.isValid) {
      this.setState({ ...this.state, formCompany: validationForm.data });
    } else {
      let dataSimpleCompany = {
        CompanyName: formCompany.companyName.data,
        CompanyAddress: formCompany.companyAddress.data,
        CompanyPhone: formCompany.companyTelephone.data,
        CountryId: formCompany.companyCountry.data,
        CurrencyId: formCompany.companyCurrency.data,
        Firstname: formCompany.userFirstName.data,
        Lastname: formCompany.userLastName.data,
        UserPhone: formCompany.userPhoneNumber.data,
        Email: formCompany.userEmail.data,
      };
      this.props.simpleRegisterCompany(dataSimpleCompany);
    }
  };

  render() {
    const { t } = this.props;
    let {
      isOpen,
      onClose,
      countryDataLoading,
      currenciesDataLoading,
    } = this.props;
    let { formCompany } = this.state;
    let loadingFormCompany = countryDataLoading || currenciesDataLoading;
    return (
      <Modal open={isOpen} onClose={onClose} size="small" closeOnDimmerClick>
        <Modal.Header style={styles.backgroundModal}>
          {/* Registration Company */}
          {t('registrationCompany')} <ButtonCloseModal onClose={onClose} />
        </Modal.Header>
        <Modal.Content style={styles.backgroundModal}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16} style={styles.paddingBottom5px}>
                <RegisterCompany
                  handleChange={this.handleChange}
                  companyName={formCompany.companyName}
                  companyCountry={formCompany.companyCountry}
                  companyTelephone={formCompany.companyTelephone}
                  companyAddress={formCompany.companyAddress}
                  companyCurrency={formCompany.companyCurrency}
                  countryOptions={this.props.countryList}
                  currencyOptions={this.props.currenciesList}
                  loading={loadingFormCompany}
                  validate={formCompany}
                />
              </Grid.Column>
              <Grid.Column width={16} style={styles.paddingBottom5px}>
                <RegisterUser
                  handleChange={this.handleChange}
                  userFirstName={formCompany.userFirstName}
                  userLastName={formCompany.userLastName}
                  userPhoneNumber={formCompany.userPhoneNumber}
                  userEmail={formCompany.userEmail}
                  validate={formCompany}
                />
              </Grid.Column>
              <Grid.Column
                width={16}
                textAlign="right"
                style={styles.paddingBottom5px}
              >
                <Button onClick={this.handleRegister} style={styles.buttonGold}>
                  {/* Register */} {t('register')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

ModalRegisCompany.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  getCountries: PropTypes.func,
  getCurrencies: PropTypes.func,
  countryDataLoading: PropTypes.bool,
  currenciesDataLoading: PropTypes.bool,
  countryList: PropTypes.array,
  currenciesList: PropTypes.array,
  simpleRegisterCompany: PropTypes.func,
  t: PropTypes.object,
};

const mapStateToProps = state => {
  const currenciesList = state.currencies.currencies.map(data =>
    options(data.Id, data.Id, data.Description)
  );

  const countryList = state.countries.countries.map(data =>
    options(data.Id, data.Id, data.Name)
  );

  return {
    currenciesList,
    countryList,
    countryDataLoading: state.countries.loading,
    currenciesDataLoading: state.currencies.loading,
  };
};

export default connect(
  mapStateToProps,
  {
    getCurrencies,
    getCountries,
  }
)(withTranslation()(ModalRegisCompany));
