import React, { Component } from 'react';
import { Grid, Header, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import { TextMoney } from '../common';
import { withTranslation } from 'react-i18next';
class PriceDetail extends Component {
  render() {
    const { t } = this.props;
    let {
      tourPriceRoom,
      additionalItem,
      currency,
      tourTotalPrice,
      commission,
      totalPayable,
      packageType,
      FoC,
      additionalServices,
    } = this.props;
    let styleCard =
      packageType !== 'Quotation' ? styles.backgroundGoldTransparent : null;
    return (
      <Grid.Row style={styleCard}>
        <Grid.Column width={8} style={styles.marginBottomTop10px}>
          {/* Price Detail */}
          <Header as="h2">{t('priceDetail')} </Header>
        </Grid.Column>
        <Grid.Column width={16}>
          <Table style={styles.paddingLeftRight20px} basic="very">
            <Table.Header>
              <Table.Row style={styles.noPadding}>
                <Table.HeaderCell style={styles.tableHeader}>
                  {/* Room Type */} {t('roomType')}
                </Table.HeaderCell>
                <Table.HeaderCell style={styles.tableHeader}>
                  {/* Guest */}
                  {t('guest')}
                </Table.HeaderCell>
                <Table.HeaderCell style={styles.tableHeader} textAlign="right">
                  {/* Price/Pax */} {t('price')}/{t('pax')}
                </Table.HeaderCell>
                <Table.HeaderCell style={styles.tableHeader} textAlign="right">
                  {/* Sub Total */} {t('subTotal')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {tourPriceRoom &&
                tourPriceRoom.map(
                  (priceRoom, index) =>
                    priceRoom.data &&
                    priceRoom.data.Pax !== 0 && (
                      <Table.Row key={index}>
                        <Table.Cell width={6} style={styles.tableHeader}>
                          {priceRoom.name}
                        </Table.Cell>
                        <Table.Cell width={3} style={styles.tableHeader}>
                          {priceRoom.data.Pax}
                        </Table.Cell>
                        <Table.Cell
                          width={3}
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney
                            currencyId={priceRoom.data.Currency}
                            money={
                              priceRoom.data.PricePerPax
                                ? priceRoom.data.PricePerPax
                                : 0
                            }
                          />
                          {/* {priceRoom.data.Currency}{' '}
                          {priceRoom.data.PricePerPax &&
                            priceRoom.data.PricePerPax.toLocaleString('id')} */}
                        </Table.Cell>
                        <Table.Cell
                          width={4}
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney
                            currencyId={priceRoom.data.Currency}
                            money={priceRoom.data.TotalPrice}
                          />
                          {/* {priceRoom.data.Currency}{' '}
                          {priceRoom.data.TotalPrice &&
                            priceRoom.data.TotalPrice.toLocaleString('id')} */}
                        </Table.Cell>
                      </Table.Row>
                    )
                )}
              {/* additional services */}
              {additionalServices && additionalServices.length !== 0 && (
                <Table.Row>
                  <Table.Cell width={4} style={styles.tableNoBorderTitle}>
                    <strong style={styles.tableHeader}>
                      {/* Additional Services */}
                      {t('additionalServices')}
                    </strong>
                  </Table.Cell>
                  <Table.Cell collspan="3" style={styles.tableNoBorder} />
                </Table.Row>
              )}
              {additionalServices &&
                additionalServices.map(item => {
                  let total = item.Amount * item.Quantity;
                  return (
                    item.Quantity !== 0 && (
                      <Table.Row
                        key={item.BookingTemplateSupplementId}
                        style={styles.noPadding}
                      >
                        <Table.Cell style={styles.tableHeader}>
                          {item.AdditionalServiceName}
                        </Table.Cell>
                        <Table.Cell style={styles.tableHeader}>
                          {item.Quantity}
                        </Table.Cell>
                        <Table.Cell
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney
                            currencyId={item.Currency}
                            money={item.Amount ? item.Amount : 0}
                          />
                          {/* {currency} {item.Price.toLocaleString('id')} */}
                        </Table.Cell>
                        <Table.Cell
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney
                            currencyId={item.Currency}
                            money={item.Quantity ? total : 0}
                          />
                          {/* {currency} {total.toLocaleString('id')} */}
                        </Table.Cell>
                      </Table.Row>
                    )
                  );
                })}
              {/* end additional services */}
              {additionalItem && additionalItem.length !== 0 && (
                <Table.Row>
                  <Table.Cell width={4} style={styles.tableNoBorderTitle}>
                    <strong style={styles.tableHeader}>
                      {/* Additional Price */}
                      {t('additionalPrice')}
                    </strong>
                  </Table.Cell>
                  <Table.Cell collspan="3" style={styles.tableNoBorder} />
                </Table.Row>
              )}
              {additionalItem &&
                additionalItem.map(item => {
                  let total = item.Qty * item.UnitCost; //item.Price
                  return (
                    item.Qty !== 0 && (
                      <Table.Row
                        key={item.Id}
                        //item.BookingTemplateSupplementId
                        style={styles.noPadding}
                      >
                        <Table.Cell style={styles.tableHeader}>
                          {item.Description}
                          {/* item.Name */}
                        </Table.Cell>
                        <Table.Cell style={styles.tableHeader}>
                          {item.Qty}
                        </Table.Cell>
                        <Table.Cell
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney
                            currencyId={currency}
                            money={item.UnitCost}
                          />

                          {/* {currency} {item.Price.toLocaleString('id')} {item.Price}*/}
                        </Table.Cell>
                        <Table.Cell
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney currencyId={currency} money={total} />
                          {/* {currency} {total.toLocaleString('id')} */}
                        </Table.Cell>
                      </Table.Row>
                    )
                  );
                })}
              <Table.Row style={styles.noPadding}>
                <Table.Cell style={styles.tableNoBorder}>
                  <strong>
                    {/* Total Price */}
                    {t('totalPrice')}
                  </strong>
                </Table.Cell>
                <Table.Cell style={styles.tableNoBorder} />
                <Table.Cell style={styles.tableNoBorder} />
                <Table.Cell style={styles.tableNoBorder} textAlign="right">
                  <TextMoney currencyId={currency} money={tourTotalPrice} />
                  {/* {currency} {tourTotalPrice.toLocaleString('id')} */}
                </Table.Cell>
              </Table.Row>
              {packageType !== 'Fixed' && FoC && FoC.Qty ? (
                <Table.Row>
                  <Table.Cell width={4} style={styles.tableNoBorderTitle}>
                    <strong style={styles.tableHeader}>
                      {/* Special Adjustment */}
                      {t('specialAdjustment')}
                    </strong>
                  </Table.Cell>
                  <Table.Cell collspan="3" style={styles.tableNoBorder} />
                </Table.Row>
              ) : null}
              {packageType !== 'Fixed' && FoC && FoC.Qty ? (
                <Table.Row style={styles.noPadding}>
                  <Table.Cell style={styles.tableHeader}>
                    {/* Free of Charge (FOC) */}
                    {t('freeOfCharge')}
                  </Table.Cell>
                  <Table.Cell style={styles.tableHeader}>
                    {FoC ? FoC.Qty : 0}
                  </Table.Cell>
                  <Table.Cell style={styles.tableHeader} textAlign="right">
                    <TextMoney currencyId={currency} money={0} />
                    {/* {currency} 0 */}
                  </Table.Cell>
                  <Table.Cell style={styles.tableHeader} textAlign="right">
                    {/* {currency} 0 */}
                    <TextMoney currencyId={currency} money={0} />
                  </Table.Cell>
                </Table.Row>
              ) : null}
              {commission.length > 0 && (
                <Table.Row>
                  <Table.Cell width={4} style={styles.tableNoBorderTitle}>
                    <strong style={styles.tableHeader}>
                      {/* Commission Detail */}
                      {t('commissionDetail')}
                    </strong>
                  </Table.Cell>
                  <Table.Cell collspan="3" style={styles.tableNoBorder} />
                </Table.Row>
              )}
              {commission.length > 0 &&
                commission.map((e, idx) => (
                  <Table.Row style={styles.noPadding} key={idx}>
                    <Table.Cell style={styles.tableHeader}>
                      {e.Description}
                    </Table.Cell>
                    <Table.Cell style={styles.tableHeader}>
                      {e.TotalPax}
                    </Table.Cell>
                    <Table.Cell style={styles.tableHeader} textAlign="right">
                      <TextMoney currencyId={currency} money={e.UnitPrice} />
                      {/* {currency} {e.UnitPrice.toLocaleString('id')} */}
                    </Table.Cell>
                    <Table.Cell style={styles.tableHeader} textAlign="right">
                      <span style={styles.redColor}>
                        <TextMoney
                          currencyId={currency}
                          money={
                            e.TotalPrice ? e.TotalPrice * -1 : e.TotalPrice
                          }
                        />
                        {/* {currency} &#40;{e.TotalPrice.toLocaleString('id')}
                        &#41; */}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                ))}
              {packageType === 'Fixed' && (
                <Table.Row>
                  <Table.Cell style={styles.tableNoBorder}>
                    <Header as="h2">
                      {/* Total Payable */}
                      {t('totalPayable')}
                    </Header>
                  </Table.Cell>
                  <Table.Cell style={styles.tableNoBorder} />
                  <Table.Cell style={styles.tableNoBorder} />
                  <Table.Cell style={styles.tableNoBorder} textAlign="right">
                    <Header as="h2">
                      <TextMoney currencyId={currency} money={totalPayable} />
                      {/* {currency} {totalPayable.toLocaleString('id')} */}
                    </Header>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    );
  }
}
PriceDetail.propTypes = {
  tourPriceRoom: PropTypes.array,
  additionalItem: PropTypes.array,
  currency: PropTypes.string,
  tourTotalPrice: PropTypes.number,
  commission: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  totalPayable: PropTypes.number,
  packageType: PropTypes.string,
  FoC: PropTypes.object,
  t: PropTypes.func,
  additionalServices: PropTypes.array,
};
export default withTranslation()(PriceDetail);
