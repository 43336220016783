import React from 'react';
import { Grid, Header, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { convertDateFormat } from '../../../../genericFunction/moment';
import { TextMoney } from '../../../shared/components/common';
import { useTranslation } from 'react-i18next';

const PaymentHistory = props => {
  const { t } = useTranslation();
  let { listPaymentTerm, currency } = props;
  return (
    <Grid.Row>
      <Grid.Column width={16} style={styles.marginTop10pxBottom5px}>
        <Header as="h2">
          {/* Payment History */}
          {t('paymentHistory')}
        </Header>
      </Grid.Column>
      <Grid.Column width={16}>
        <Table style={styles.paddingLeftRight20px} basic="very">
          <Table.Header>
            <Table.Row style={styles.noPadding}>
              <Table.HeaderCell style={styles.tableHeader}>
                {/* Description */}
                {t('description')}
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.tableHeader}>
                {/* Percentage */}
                {t('percentage')}
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.tableHeader} textAlign="right">
                {/* Amount */}
                {t('amount')}
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.tableHeader} textAlign="center">
                {/* Expiry Date */}
                {t('expiryDate')}
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.tableHeader}>
                {/* Pay Date */}
                {t('payDate')}
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.tableHeader}>
                {/* Status */}
                {t('status')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {listPaymentTerm &&
              listPaymentTerm.map((paymentTerm, index) => {
                let styleText = paymentTerm.PaidOff
                  ? styles.blueColor
                  : styles.redColor;
                return (
                  <Table.Row key={index}>
                    <Table.Cell style={styles.tableBody}>
                      <strong>{paymentTerm.Description}</strong>
                    </Table.Cell>
                    <Table.Cell style={styles.tableBody}>
                      <strong>{paymentTerm.Percentage}%</strong>
                    </Table.Cell>
                    <Table.Cell style={styles.tableBody} textAlign="right">
                      <strong style={styleText}>
                        <TextMoney
                          currencyId={currency}
                          money={
                            Math.floor(paymentTerm.PaidValue) !== 0
                              ? paymentTerm.PaymentTermBasedValue !==
                                paymentTerm.PaidValue
                                ? paymentTerm.RestOfTheBill.toLocaleString('id')
                                : paymentTerm.PaymentTermBasedValue.toLocaleString(
                                    'id'
                                  )
                              : paymentTerm.PaymentTermBasedValue.toLocaleString(
                                  'id'
                                )
                          }
                        />
                        {/* {currency}{' '}
                          {Math.floor(paymentTerm.PaidValue) !== 0
                            ? paymentTerm.PaymentTermBasedValue !==
                              paymentTerm.PaidValue
                              ? paymentTerm.RestOfTheBill.toLocaleString('id')
                              : paymentTerm.PaymentTermBasedValue.toLocaleString(
                                  'id'
                                )
                            : paymentTerm.PaymentTermBasedValue.toLocaleString(
                                'id'
                              )} */}
                        {/* {paymentTerm.RestOfTheBill.toLocaleString('id')} */}
                      </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.tableBody} textAlign="center">
                      <strong style={styleText}>
                        {convertDateFormat(paymentTerm.DueDate, 'DD MMM YYYY')}
                      </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.tableBody}>
                      <strong style={styleText}>
                        {paymentTerm.PaidOff
                          ? convertDateFormat(
                              paymentTerm.PayDate,
                              'DD MMM YYYY'
                            )
                          : '-'}
                      </strong>
                    </Table.Cell>
                    <Table.Cell style={styles.tableBody}>
                      <strong style={styleText}>
                        {/* {paymentTerm.PaidOff ? 'Paid' : 'Unpaid'} */}
                        {paymentTerm.PaidOff
                          ? `${t('paid')}`
                          : `${t('unpaid')}`}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
  );
};
PaymentHistory.propTypes = {
  listPaymentTerm: PropTypes.array,
  currency: PropTypes.string,
};
export default PaymentHistory;
