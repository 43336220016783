import { GOLD_COLOR, WHITE_COLOR } from '../../constant/colour';

const COLOR_WHITE = '#fefefe';
const COLOR_GOLD = '#e6ca6b';
const COLOR_RED = '#f44336';
const COLOR_BLUE = '#6b82e6';
const COLOR_BLACK = '#000000';

const styles = {
  borderNone: {
    border: 'none',
  },
  segment: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '10px 30px 10px 30px',
    margin: '5px 0px 20px 0px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  backgroundModal: {
    background: COLOR_WHITE,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  segmentNoBorderPaddingLeftRight20px: {
    border: 'none',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  segmentNoBorderTransparentMinHeight50px: {
    border: 'none',
    background: 'transparent',
    minHeight: '50px',
  },
  //   // segmentWithShadowBox: {
  //   //   border: 'none',
  //   //   boxShadow: '0 2px 6px 1px  rgba(0,0,0,0.2)',
  //   //   padding: '30px 30px',
  //   //   margin: '5px 0px 20px 0px',
  //   // },
  goldColor: { color: COLOR_GOLD },
  greyColor: { color: '#dbdbdb' },
  colorBlack: {
    color: COLOR_BLACK,
  },
  whiteCardColor: { background: WHITE_COLOR },
  transparentGold: {
    background: COLOR_WHITE,
    color: COLOR_GOLD,
    padding: '0px',
  },
  //   // goldCardColor: {
  //   //   padding: '5px 5px',
  //   //   background: 'rgba(230, 202, 107,0.1)',
  //   // },
  //   // goldCardColor15px: {
  //   //   padding: '15px',
  //   //   background: 'rgba(230, 202, 107,0.1)',
  //   // },
  widthCard: { width: '490px' },
  //   // titleTable: {
  //   //   borderBottom: '1px solid #252525',
  //   //   borderTop: 'none',
  //   // },
  //   tableHeader: {
  //     padding: '3px',
  //     borderBottom: '1px solid #252525',
  //     borderTop: 'none',
  //   },
  //   tableBody: {
  //     padding: '3px',
  //     border: 'none',
  //   },
  //   tableNoBorder: {
  //     border: 'none',
  //     padding: '3px',
  //   },
  buttonGold: {
    margin: '0px 10px',
    color: COLOR_BLACK,
    background: COLOR_GOLD,
    border: '2px solid' + COLOR_GOLD,
  },
  buttonGoldUserList: {
    background: COLOR_GOLD,
    color: 'black',
    borderStyle: 'solid',
    borderColor: COLOR_GOLD,
    // width: '100%',
  },

  //   buttonGoldNoPadding: {
  //     color: COLOR_BLACK,
  //     background: COLOR_GOLD,
  //   },
  buttonRed: {
    margin: '0px 10px',
    color: COLOR_WHITE,
    background: COLOR_RED,
    border: '2px solid' + COLOR_RED,
  },
  buttonBlue: {
    margin: '0px 10px',
    color: COLOR_BLUE,
    background: COLOR_WHITE,
    border: '2px solid #6b82e6',
  },
  buttonIconInfo: {
    color: '#252525',
    padding: '0px',
  },
  paddingBottom5px: {
    paddingBottom: '5px',
  },
  //   backgroundWhiteWithPadding15px: {
  //     padding: '15px',
  //     background: COLOR_WHITE,
  //   },
  //   noPadding: {
  //     padding: '0px',
  //   },
  padding2px: {
    padding: '2px',
  },
  padding10px: {
    padding: '10px',
  },
  paddingRight15px: {
    paddingRight: '15px',
  },
  //   paddingRight10Left20: {
  //     padding: '0px 10px 0px 20px',
  //   },
  paddingTop2pxLeftRight15px: {
    padding: '2px 15px 0px 15px',
  },
  //   paddingTop20px: {
  //     paddingTop: '10px',
  //   },
  paddingTop10px: {
    paddingTop: '10px',
  },
  //   paddingTop10pxBackgroundGold: {
  //     paddingTop: '10px',
  //     background: 'rgba(230, 202, 107,0.1)',
  //   },
  marginTopBottom5px: {
    margin: '5px 0px 5px 0px',
  },
  noMargin: {
    margin: '0px',
  },
  colorRed: {
    color: COLOR_RED,
  },
  width100percent: {
    width: '100%',
  },
  noPaddingMarginRight10px: {
    padding: '0px',
    marginRight: '10px',
  },
  paddingLeftRight10px: {
    padding: '0px 10px',
  },
  paddingLeftRight10pxRedBackground: {
    padding: '0px 10px',
    background: 'rgba(230, 202, 107,0.1)',
  },
  paddingTopBottom10pxLeftRight5px: {
    padding: '10px 5px',
  },
  marginLeft5px: {
    marginLeft: '5px',
  },
  marginBottom3px: {
    marginBottom: '3px',
  },
  marginBottom5px: {
    marginBottom: '5px',
  },
  marginTopBottom5pxWidth130px: {
    margin: '5px 0px',
    width: '130px',
  },
  marginBottom20px: {
    marginBottom: '20px',
  },
  h4: {
    margin: '0',
    fontSize: '18px',
  },
  label: {
    width: '50%',
    marginRight: '0',
  },
  input: {
    width: '50%',
  },
  menuStyle: {
    display: 'none',
  },
  fixedMenuStyle: {
    backgroundColor: '#252525',
    border: '1px solid #252525',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    color: COLOR_GOLD,
  },
  marginBottom15pxPositionRelative: {
    marginBottom: '15px',
    position: 'relative',
  },
  padding0pxMarginRight: {
    padding: '0px',
    marginRight: '10px',
  },
  noPadding: {
    padding: '0px',
  },
  collapseButtonStyle: {
    color: GOLD_COLOR,
    background: 'transparent',
    padding: '0px',
  },
  marginTopTen: {
    marginTop: '10px',
  },
};

export default styles;
