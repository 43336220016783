import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import MultiCarousell from './MultipleCarousel';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const TopDestination = () => {
  const { t } = useTranslation();
  return (
    <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
      <h1
        className="margin-bottom-twentyfive margin-bottom-ten"
        style={styles.headerStyle}
      >
        {/* Top Destination */}
        {t('topDestination')}
      </h1>
      <MultiCarousell />
    </Container>
  );
};

TopDestination.propTypes = {
  t: PropTypes.func,
};

export default TopDestination;
