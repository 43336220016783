import { GOLD_COLOR } from '../../../../../constant/colour';

const style = {
  paddingFive: { padding: '5px' },
  noPaddingBot: { paddingBottom: 0 },
  goldColor: {
    color: GOLD_COLOR,
  },
  font700: { fontWeight: '700' },
  buttonIconEdit: { color: '#6b82e6', cursor: 'pointer' },
  borderBottomGrey: { borderBottom: '1px solid #e8e8e8' },
  buttonIconDelete: { color: 'red', cursor: 'pointer' },
  noMargin: { margin: 0 },
  pointerStyle: { cursor: 'pointer' },
};

export default style;
