import React, { Component } from 'react';
import { Button, Modal, Form, Message, Header, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputAmount from '../../../../../components/input/Amount';
import styles from './styles';
import {
  setGuestsAmount,
  setRoomAllocation,
  setGuestList,
  setHeadlineProgram,
} from './../../../actions/customPackageAction';
import {
  addGuest,
  deleteGuest,
  generateGuest,
} from '../../../../../genericFunction/itineraryBuilder/guest';
import { setRoomAllocationSummaryProg } from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import {
  validationTourPax,
  isErrorFormPackage,
  validationAllocationPax,
  validationMinPax,
  isRoomAllocationValid,
} from '../../../../../genericFunction/validation';
import MessageError from '../../../../../components/message/messageError';
import { ButtonCloseModal } from '../../common';
import { withTranslation } from 'react-i18next';

class ModalPaxArrangement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      guests: { adults: 0, childs: 0, infants: 0 },
      foc: {
        Qty: 0,
        Description: 'Foc',
      },
      roomAllocation: {
        ChildExtraBedQty: 0,
        ChildSharingRoomQty: 0,
        ChildSingleRoomQty: 0,
        ExtraBedQty: 0,
        BabyCrib: 0,
        NoBed: 0,
        SharingBedQty: 0,
        SharingRoomQty: 0,
        SingleRoomQty: 0,
      },
      guestList: [],
      loading: false,
      errors: {
        tourPax: { error: false, message: [] },
        roomAllocation: { error: false, message: [] },
        minimumPax: { error: false, message: [] },
      },
    };
  }
  show = size => () => this.setState({ size, open: true });
  sumValues = obj => {
    return Object.values(obj).reduce((sum, current) => sum + current);
  };
  resetError = () => {
    let error = { ...this.state.errors };
    error = {
      tourPax: { error: false, message: [] },
      roomAllocation: { error: false, message: [] },
      minimumPax: { error: false, message: [] },
    };
    this.setState({
      ...this.state,
      errors: error,
    });
  };
  handleChangeGuestAmount = (e, { name, value, objectname }) => {
    let obj = { ...this.state[objectname] };
    if (value === '') {
      obj[name] = 0;
    } else {
      obj[name] = parseInt(value.replace(/\D/g, ''), 10);
    }
    this.setState({ ...this.state, [objectname]: obj });
  };
  handleIncrease = async (e, { name, value, objectname }) => {
    await this.resetError();

    let obj = { ...this.state[objectname] };
    let maxFocValue = this.state.guests.adults;
    let newValue = 0;
    objectname === 'foc'
      ? maxFocValue !== 0
        ? (newValue = value + 1 < maxFocValue ? value + 1 : maxFocValue - 1)
        : (newValue = 0)
      : (newValue = 0);
    objectname === 'foc' ? (obj[name] = newValue) : (obj[name] = value + 1);
    // let guestList =
    //   objectname === 'guests' ? this.addGuests(name) : this.state.guests;
    this.setState({ ...this.state, [objectname]: obj });
    //
  };
  handleDecrease = async (e, { name, value, objectname }) => {
    await this.resetError();
    let obj = { ...this.state[objectname] };
    let objFoc = { ...this.state.foc };
    let focValue = this.state.foc.Qty;
    let newValue = value - 1 < 0 ? 0 : value - 1;
    focValue =
      name === 'adults'
        ? newValue === 0
          ? focValue
          : newValue <= focValue
          ? newValue - 1
          : focValue
        : focValue;
    obj[name] = newValue;
    objFoc.Qty = focValue;
    // let guestList =
    //   objectname === 'guests' ? this.removeGuests(name) : this.state.guests;
    this.setState({ ...this.state, foc: objFoc, [objectname]: obj });
  };
  addGuests = name => {
    const { guests } = this.state;
    if (name === 'adults') {
      if (guests.filter(g => g.GuestCategory === 'ADULT').length > 0) {
        return addGuest(guests, 'TOURLEADER', 'ADULT');
      } else return addGuest(guests, 'TOURMEMBER', 'ADULT');
    } else if (name === 'childs')
      return addGuest(guests, 'TOURMEMBER', 'CHILDREN');
    else return addGuest(guests, 'TOURMEMBER', 'INFANT');
  };
  removeGuests = name => {
    const { guests } = this.state;
    let last = null;
    if (name === 'adults') {
      last = guests.lastIndexOf(g => g.GuestCategory === 'ADULT');
      return last.length !== -1 ? deleteGuest(guests, last) : guests;
    } else if (name === 'childs') {
      last = guests.lastIndexOf(g => g.GuestCategory === 'CHILDREN');
      return last.length !== -1 ? deleteGuest(guests, last) : guests;
    } else {
      last = guests.lastIndexOf(g => g.GuestCategory === 'INFANT');
      return last.length !== -1 ? deleteGuest(guests, last) : guests;
    }
  };
  generateGuest = () => {
    let newGuest = generateGuest(this.state.guestList, this.state.guests);
    this.setState({
      ...this.state,
      guestList: newGuest,
    });
    return newGuest;
  };

  doPaxArrangement = async (guests, roomAllocation, foc) => {
    await this.props.addFoc(foc);
    this.setState({ ...this.state, loading: true }, () => {
      setTimeout(this.setState({ ...this.state, loading: false }), 3000);
    });
    let guestList = this.generateGuest();
    let headLine = setRoomAllocationSummaryProg(
      this.props.HeadlineProgram,
      roomAllocation
    );
    let mixedPromisesArray = [
      this.props.setRoomAllocation(roomAllocation),
      this.props.setGuestList(guestList),
      this.props.setGuestsAmount(guests),
      this.props.setHeadlineProgram(headLine),
      this.props.closeModal(),
    ];
    Promise.all(mixedPromisesArray).then(() =>
      this.setState({ ...this.state, loading: false })
    );
  };

  setPaxArrangement = () => {
    // -------- validation -----------
    const { isVariableDate } = this.props;
    const { guests, roomAllocation, errors, foc } = this.state;
    const { minPax } = this.props;
    let newErrors = validationTourPax(guests, errors);
    newErrors = isVariableDate
      ? isRoomAllocationValid(newErrors, guests, roomAllocation)
      : validationAllocationPax(guests, roomAllocation, newErrors);
    newErrors = validationMinPax(guests, newErrors, minPax);
    // -------- end validation -----------
    if (isErrorFormPackage(newErrors)) {
      this.setState({ ...this.state, errors: newErrors });
    } else {
      this.setState({ ...this.state, loading: true }, () =>
        this.doPaxArrangement(guests, roomAllocation, foc)
      );
    }
  };

  resetGuestList = () => {
    this.handleOpen();
  };

  handleOpen = () => {
    this.props.openModal();
    this.setState({
      ...this.state,
      guestList: this.props.guestList,
      guests: this.props.guests,
      foc: this.props.foc,
      roomAllocation: this.props.roomAllocation,
      errors: {
        tourPax: { error: false, message: [] },
        roomAllocation: { error: false, message: [] },
        minimumPax: { error: false, message: [] },
      },
    });
  };
  render() {
    const { t } = this.props;
    const { open, roomPrices, isVariableDate } = this.props;
    const { guests, roomAllocation, loading, errors, foc } = this.state;
    const totalGuest = this.sumValues(guests);
    const totalAllocation = this.sumValues(roomAllocation);
    const totalAllocAdult =
      guests.adults -
      (roomAllocation.SingleRoomQty + roomAllocation.SharingRoomQty);
    const totalAllocChild =
      guests.childs -
      (roomAllocation.ChildSingleRoomQty +
        roomAllocation.ChildSharingRoomQty +
        roomAllocation.ChildExtraBedQty +
        roomAllocation.SharingBedQty);
    const totalAllocInfant =
      guests.infants - (roomAllocation.NoBed + roomAllocation.BabyCrib);
    const totalAllocAdultAndChild = isVariableDate
      ? guests.adults +
        guests.childs -
        (roomAllocation.SharingRoomQty +
          roomAllocation.SingleRoomQty +
          roomAllocation.ExtraBedQty +
          roomAllocation.ChildExtraBedQty +
          roomAllocation.SharingBedQty)
      : 0;

    return (
      <div>
        <Modal
          trigger={
            <div style={styles.textCenter}>
              <Button style={styles.backgroundGold}>
                {/* Set Guest and Room Allocation */}
                {t('setGuestAndRoomAllocation')}
              </Button>
            </div>
          }
          size="tiny"
          open={open}
          onClose={this.props.closeModal}
          onOpen={this.resetGuestList}
        >
          <Modal.Header>
            {/* Pax Arrangement */}
            {t('paxArrangement')}{' '}
            <ButtonCloseModal onClose={this.props.closeModal} />{' '}
          </Modal.Header>
          <Modal.Content>
            <div style={styles.marginLeftRight30px}>
              <Header as="h3">
                {/* Pax Amount */}
                {t('paxAmount')}
              </Header>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field
                    style={{ ...styles.paddingRight20, alignSelf: 'flex-end' }}
                  >
                    <InputAmount
                      label={`${t('adults')}(>12 ${t('years')})`} //"Adults(>12 Years)"
                      // labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="adults"
                      objectname="guests"
                      value={guests.adults}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                    />
                  </Form.Field>
                  <Form.Field style={{ alignSelf: 'flex-end' }}>
                    <InputAmount
                      label={`${t('children')}(2-12 ${t('years')})`} //"Children(2-12 Years)"
                      // labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="childs"
                      objectname="guests"
                      value={guests.childs}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                      disabled={
                        isVariableDate
                          ? roomPrices &&
                            roomPrices.ChildExtraBedPrice !== 0 &&
                            roomPrices.SharingBedPrice !== 0
                            ? false
                            : true
                          : false
                      }
                    />
                  </Form.Field>
                  <Form.Field
                    style={{ ...styles.paddingLeft20, alignSelf: 'flex-end' }}
                  >
                    <InputAmount
                      label={`${t('infants')}(<2 ${t('years')})`} //"Infants(<2 Years)"
                      // labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="infants"
                      objectname="guests"
                      value={guests.infants}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChangeAmount={this.handleChangeGuestAmount}
                      disabled={
                        isVariableDate
                          ? roomPrices && roomPrices.NoBedPrice !== 0
                            ? false
                            : true
                          : false
                      }
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              {this.props.capacity === 'Large' ? (
                <div>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={11} verticalAlign="middle">
                        <span>
                          {/* Included Free of Charge (FOC) Person from your guests: */}
                          {t('includeFOC')}:
                        </span>
                      </Grid.Column>
                      <Grid.Column width={5} style={styles.paddingLeft8px}>
                        <Form>
                          <Form.Group>
                            <Form.Field
                              style={{
                                ...styles.paddingLeft4pxWidth100percent,
                                alignSelf: 'flex-end',
                              }}
                            >
                              <InputAmount
                                label={t('focAmount')} //"Foc Amount"
                                // labelStyle={styles.textAlignCenter}
                                inputStyle={styles.borderBottom}
                                // name="foc"
                                objectname="foc"
                                name="Qty"
                                value={foc.Qty}
                                handleIncrease={this.handleIncrease}
                                handleDecrease={this.handleDecrease}
                                handleOnChange={this.handleChangeGuestAmount}
                              />
                            </Form.Field>
                          </Form.Group>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              ) : null}
              <Header as="h3" style={styles.marginTop10px}>
                {/* Room Allocation */}
                {t('')}
              </Header>
              {!isVariableDate ? (
                <Form>
                  <Header as="h4">{t('adults')}</Header>
                  <Form.Group widths="equal">
                    <Form.Field
                      style={{
                        ...styles.paddingRight20,
                        alignSelf: 'flex-end',
                      }}
                    >
                      <InputAmount
                        label={t('sharingRoom')} //"Sharing Room"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="SharingRoomQty"
                        objectname="roomAllocation"
                        value={roomAllocation.SharingRoomQty}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeGuestAmount}
                        disabled={
                          isVariableDate
                            ? roomPrices && roomPrices.SharingRoomPrice !== 0
                              ? false
                              : true
                            : false
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ alignSelf: 'flex-end' }}>
                      <InputAmount
                        label={t('singleRoom')} //"Single Room"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="SingleRoomQty"
                        objectname="roomAllocation"
                        value={roomAllocation.SingleRoomQty}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeGuestAmount}
                        disabled={
                          isVariableDate
                            ? roomPrices && roomPrices.SingleRoomPrice !== 0
                              ? false
                              : true
                            : false
                        }
                      />
                    </Form.Field>
                    <Form.Field
                      style={{ ...styles.paddingLeft20, alignSelf: 'flex-end' }}
                    >
                      <InputAmount
                        label={t('extraBed')} //"Extra bed"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="ExtraBedQty"
                        objectname="roomAllocation"
                        value={roomAllocation.ExtraBedQty}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChangeAmount={this.handleChangeGuestAmount}
                        disabled={
                          isVariableDate
                            ? roomPrices && roomPrices.AdultExtraBedPrice !== 0
                              ? false
                              : true
                            : false
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  <Header as="h4">{t('children')}</Header>
                  <Form.Group widths="equal">
                    <Form.Field
                      style={{
                        ...styles.paddingRight20,
                        alignSelf: 'flex-end',
                      }}
                    >
                      <InputAmount
                        label={t('sharingRoom')} //"Sharing Room"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="ChildSharingRoomQty"
                        objectname="roomAllocation"
                        value={roomAllocation.ChildSharingRoomQty}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeGuestAmount}
                      />
                    </Form.Field>
                    <Form.Field style={{ alignSelf: 'flex-end' }}>
                      <InputAmount
                        label={t('singleRoom')} //"Single Room"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="ChildSingleRoomQty"
                        objectname="roomAllocation"
                        value={roomAllocation.ChildSingleRoomQty}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeGuestAmount}
                      />
                    </Form.Field>
                    <Form.Field
                      style={{ ...styles.paddingLeft20, alignSelf: 'flex-end' }}
                    >
                      <InputAmount
                        label={t('extraBed')} //"Extra bed"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="ChildExtraBedQty"
                        objectname="roomAllocation"
                        value={roomAllocation.ChildExtraBedQty}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChangeAmount={this.handleChangeGuestAmount}
                        disabled={
                          isVariableDate
                            ? roomPrices && roomPrices.ChildExtraBedPrice !== 0
                              ? false
                              : true
                            : false
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      style={{
                        ...styles.paddingRight20,
                        alignSelf: 'flex-end',
                      }}
                    >
                      <InputAmount
                        label={t('sharingWithParents')} //"Sharing with parent"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="SharingBedQty"
                        objectname="roomAllocation"
                        value={roomAllocation.SharingBedQty}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChangeAmount={this.handleChangeGuestAmount}
                        disabled={
                          isVariableDate
                            ? roomPrices && roomPrices.SharingBedPrice !== 0
                              ? false
                              : true
                            : false
                        }
                      />
                    </Form.Field>
                    <Form.Field /> <Form.Field />
                  </Form.Group>

                  <Header as="h4">{t('others')}</Header>
                  <Form.Group widths="equal">
                    <Form.Field
                      style={{
                        ...styles.paddingRight20,
                        alignSelf: 'flex-end',
                      }}
                    >
                      <InputAmount
                        label={`${t('babyCrib')}(${t('infants')})`} //"Baby Crib(infants)"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="BabyCrib"
                        objectname="roomAllocation"
                        value={roomAllocation.BabyCrib}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeGuestAmount}
                        disabled={
                          isVariableDate
                            ? roomPrices && roomPrices.NoBedPrice !== 0
                              ? false
                              : true
                            : false
                        }
                      />
                    </Form.Field>
                    <Form.Field style={{ alignSelf: 'flex-end' }}>
                      <InputAmount
                        label={`${t('noBed')}(${t('infants')})`} //"No bed(infants)"
                        // labelStyle={styles.textAlignCenter}
                        inputStyle={styles.borderBottom}
                        name="NoBed"
                        objectname="roomAllocation"
                        value={roomAllocation.NoBed}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeGuestAmount}
                        disabled={
                          isVariableDate
                            ? roomPrices && roomPrices.NoBedPrice !== 0
                              ? false
                              : true
                            : false
                        }
                      />
                    </Form.Field>
                    <Form.Field
                      style={{ ...styles.paddingLeft20, alignSelf: 'flex-end' }}
                    />
                  </Form.Group>
                </Form>
              ) : (
                <Grid columns={3}>
                  <Grid.Column style={{ alignSelf: 'flex-end' }}>
                    <InputAmount
                      label={t('sharingRoom')} // "Sharing Room"
                      labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="SharingRoomQty"
                      objectname="roomAllocation"
                      value={roomAllocation.SharingRoomQty}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                      disabled={
                        roomPrices && roomPrices.SharingRoomPrice !== 0
                          ? false
                          : true
                      }
                    />
                  </Grid.Column>
                  <Grid.Column style={{ alignSelf: 'flex-end' }}>
                    <InputAmount
                      label={t('singleRoom')} //"Single Room"
                      labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="SingleRoomQty"
                      objectname="roomAllocation"
                      value={roomAllocation.SingleRoomQty}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                      disabled={
                        roomPrices && roomPrices.SingleRoomPrice !== 0
                          ? false
                          : true
                      }
                    />
                  </Grid.Column>
                  <Grid.Column style={{ alignSelf: 'flex-end' }}>
                    <InputAmount
                      label={`${t('extraBed')}(${t('adult')})`} //"Extra Bed(Adult)"
                      labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="ExtraBedQty"
                      objectname="roomAllocation"
                      value={roomAllocation.ExtraBedQty}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                      disabled={
                        roomPrices && roomPrices.AdultExtraBedPrice !== 0
                          ? false
                          : true
                      }
                    />
                  </Grid.Column>
                  <Grid.Column style={{ alignSelf: 'flex-end' }}>
                    <InputAmount
                      label={`${t('extraBed')}(${t('child')})`} //"Extra Bed(Child)"
                      labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="ChildExtraBedQty"
                      objectname="roomAllocation"
                      value={roomAllocation.ChildExtraBedQty}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                      disabled={
                        roomPrices && roomPrices.ChildExtraBedPrice !== 0
                          ? false
                          : true
                      }
                    />
                  </Grid.Column>
                  <Grid.Column style={{ alignSelf: 'flex-end' }}>
                    <InputAmount
                      label={t('sharingWithParents')} //"Sharing with parents"
                      labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="SharingBedQty"
                      objectname="roomAllocation"
                      value={roomAllocation.SharingBedQty}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                      disabled={
                        roomPrices && roomPrices.SharingBedPrice !== 0
                          ? false
                          : true
                      }
                    />
                  </Grid.Column>
                  <Grid.Column style={{ alignSelf: 'flex-end' }}>
                    <InputAmount
                      label={`${t('babyCrib')}/${t('noBed')}`} //"Baby Crib/No Bed"
                      labelStyle={styles.textAlignCenter}
                      inputStyle={styles.borderBottom}
                      name="NoBed"
                      objectname="roomAllocation"
                      value={roomAllocation.NoBed}
                      handleIncrease={this.handleIncrease}
                      handleDecrease={this.handleDecrease}
                      handleOnChange={this.handleChangeGuestAmount}
                      disabled={
                        roomPrices && roomPrices.NoBedPrice !== 0 ? false : true
                      }
                    />
                  </Grid.Column>
                </Grid>
              )}

              <Message>
                <strong>
                  {t('youHaveAllocated')} <span> {totalAllocation}</span>{' '}
                  {t('guestOutOf')}
                  <span>{totalGuest}</span>
                </strong>
                <p>
                  {isVariableDate ? (
                    <>
                      <strong>{totalAllocAdultAndChild}</strong>{' '}
                      {t('adultPlural')} {t('or')} {t('childPlural')}{' '}
                    </>
                  ) : (
                    <>
                      <strong>{totalAllocAdult}</strong> {t('adultPlural')},{' '}
                      <strong>{totalAllocChild}</strong> {t('childPlural')}{' '}
                    </>
                  )}
                  {t('and')} <strong>{totalAllocInfant}</strong>{' '}
                  {t('infantPlural')} {t('remaining')}
                </p>
              </Message>
              <MessageError
                isErrors={
                  errors.tourPax.error
                    ? errors.tourPax.error
                    : errors.roomAllocation.error
                    ? errors.roomAllocation.error
                    : errors.minimumPax.error
                    ? errors.minimumPax.error
                    : false
                }
                listErrors={
                  errors.tourPax.error
                    ? errors.tourPax.message
                    : errors.roomAllocation.error
                    ? errors.roomAllocation.message
                    : errors.minimumPax.error
                    ? errors.minimumPax.message
                    : []
                }
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={loading ? true : false}
              style={styles.backgroundGold}
              onClick={this.setPaxArrangement}
              content={t('ok')} //"Ok"
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    guests: state.customPackage.guests,
    roomAllocation: state.customPackage.roomAllocation,
    guestList: state.customPackage.guestList,
    HeadlineProgram: state.customPackage.HeadlineProgram,
  };
};
ModalPaxArrangement.propTypes = {
  guests: PropTypes.object,
  roomAllocation: PropTypes.object,
  setGuestsAmount: PropTypes.func,
  setRoomAllocation: PropTypes.func,
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  guestList: PropTypes.array,
  setGuestList: PropTypes.func,
  openModal: PropTypes.func,
  HeadlineProgram: PropTypes.object,
  setHeadlineProgram: PropTypes.func,
  capacity: PropTypes.string,
  foc: PropTypes.object,
  addFoc: PropTypes.func,
  isVariableDate: PropTypes.bool,
  roomPrices: PropTypes.object,
  t: PropTypes.func,
  minPax: PropTypes.bool,
};
export default connect(
  mapStateToProps,
  {
    setGuestsAmount,
    setRoomAllocation,
    setGuestList,
    setHeadlineProgram,
  }
)(withTranslation()(ModalPaxArrangement));
