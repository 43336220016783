import React, { useEffect } from 'react';
import { Grid, Segment, Header, Icon } from 'semantic-ui-react';
import GoldRibbon from '../../../shared/components/common/GoldRibbon';
import { finlandImage } from './../../../../assets/image';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { convertDateFormat } from '../../../../genericFunction/moment';
// import { useSelector } from 'react-redux';
import ProgressiveImage from 'react-progressive-image-loading';
import PopUpButton from './PopUpButton';
import convertStatus from '../../services/helper/convertStatus';
import bgImage from './imageStyle';
import { useTranslation } from 'react-i18next';

const CardBackgroundImage = props => {
  const { t } = useTranslation();
  //============initial props==========
  let {
    title,
    id,
    expiredOn,
    image,
    startDate,
    endDate,
    city,
    country,
    status,
    isAcceptedQuotation,
    tourTransactionIdQuotation,
    packageType,
    //ready fix price
    bookingTemplateRef,
    handlegetInvoiceCustomer,
    handlegetInvoiceTravelAgent,
    handlegetScheduleTourTransaction,
    handleresetStatusGetInvoiceSchedule,
    handlegetVoucherConfirmation,
  } = props;

  //=============store===========
  // const invoice = useSelector(state => state.booking.invoice);
  // const schedule = useSelector(state => state.booking.schedule);
  // const voucherConfirmation = useSelector(
  //   state => state.booking.voucherConfirmation
  // );

  //==========lifecycle==============
  useEffect(() => {
    handleresetStatusGetInvoiceSchedule();
    // eslint-disable-next-line
  }, []);

  // const handleDownloadFile = (dataUrl, filename) => {
  //   let link = document.createElement('a');
  //   link.download = filename;
  //   link.href = dataUrl;
  //   link.target = '_blank';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const handleDownloadInvoice = (e, { value, name }) => {
    if (name === 'agent') {
      handlegetInvoiceTravelAgent(value).then(result =>
        window.open(result.value.data)
      );
    } else {
      handlegetInvoiceCustomer(value).then(result =>
        window.open(result.value.data)
      );
    }
  };
  const handleDownloadSchedule = (e, { value }) =>
    handlegetScheduleTourTransaction(value).then(result =>
      window.open(result.value.data)
    );
  const handleDownloadVoucherConfirmation = (e, { value }) =>
    handlegetVoucherConfirmation(value).then(result =>
      window.open(result.value.data)
    );
  // handleCreateSimilar = (e, { value }) => this.props.createSimilar(value);
  const handleCreateSimilar = (e, { value }) =>
    props.createSimilar(value, packageType, expiredOn);

  status = convertStatus(status, isAcceptedQuotation);
  return (
    <ProgressiveImage
      preview={image ? image : finlandImage}
      src={image ? image : finlandImage}
      transitionTime={1000}
      render={(src, style) => (
        <Segment
          style={Object.assign(style, bgImage(image ? image : finlandImage))}
        >
          <Grid>
            <Grid.Row>
              <Grid.Column width={7} style={styles.borderRight10pxDashedWhite}>
                <GoldRibbon
                  size="big"
                  text={status.value}
                  style={status.style}
                />
                <Header as="h3" style={styles.colorWhiteMarginLeft20px}>
                  {/* {status.key === 'Booking_draft'
                    ? 'Quotation Code'
                    : 'Booking Code'} */}
                  {status.key === 'Booking_draft'
                    ? t('quotationCode')
                    : t('bookingCode')}
                </Header>
                <Header
                  as="h3"
                  style={styles.marginLeft20MarginTop10pxColorWhite}
                >
                  {id}
                </Header>
                {[
                  'Created',
                  'Created (On Hold)',
                  'DP confirm',
                  'SP confirm',
                  'In Payment',
                ].indexOf(status.value) !== -1 ? (
                  <div style={styles.noMargin}>
                    <Header
                      as="h3"
                      style={styles.marginLeft20MarginTop10pxColorWhite}
                    >
                      {/* Expiry Date */}
                      {t('expiryDate')}
                    </Header>
                    <Header
                      as="h3"
                      style={styles.marginLeft20MarginTop10pxColorRed}
                    >
                      {convertDateFormat(expiredOn, 'DD MMM YYYY')}
                    </Header>
                  </div>
                ) : null}
              </Grid.Column>
              <Grid.Column width={9}>
                <Header as="h3" style={styles.colorGoldMarginTop20px}>
                  {title}
                </Header>
                <Header
                  as="h4"
                  style={styles.colorGoldMarginBottomTop15pxLeftRight0px}
                >
                  <Icon className="fas fa-map-marker-alt" /> {city}, {country}
                </Header>
                <Header
                  as="h4"
                  style={styles.colorGoldMarginBottomTop15pxLeftRight0px}
                >
                  <Icon className="fas fa-calendar-alt" />{' '}
                  {convertDateFormat(startDate, 'DD MMM YYYY')} -{' '}
                  {convertDateFormat(endDate, 'DD MMM YYYY')}
                </Header>
                <Grid.Row
                  columns={3}
                  textAlign="center"
                  style={styles.paddingTop10Left20}
                >
                  <PopUpButton
                    buttonStyle={styles.bottonDownload}
                    name="agent"
                    value={id}
                    handleClick={handleDownloadInvoice}
                    iconClassName="fas fa-file-invoice fa-2x"
                    iconStyle={styles.iconButton}
                    content={
                      status.key !== 'Booking_draft'
                        ? t('printAgentInvoice') // 'Print Agent Invoice'
                        : t('printQuotation') // 'Print Quotation'
                    }
                    popupShowOn="hover"
                  />

                  {status.key !== 'Booking_draft' && (
                    <PopUpButton
                      buttonStyle={styles.bottonDownload}
                      name="customer"
                      value={id}
                      handleClick={handleDownloadInvoice}
                      iconClassName="fas fa-receipt fa-2x"
                      iconStyle={styles.iconButton}
                      content={t('printCustomerInvoice')} //"Print Customer Invoice"
                      popupShowOn="hover"
                    />
                  )}
                  <PopUpButton
                    buttonStyle={styles.bottonDownload}
                    name="schedule"
                    value={id}
                    handleClick={handleDownloadSchedule}
                    iconClassName="fas fa-clipboard-list fa-2x"
                    iconStyle={styles.iconButton}
                    content={t('printSchedule')} //"Print Schedule"
                    popupShowOn="hover"
                  />

                  {status.key === 'Booking_Confirmed' ? (
                    <PopUpButton
                      buttonStyle={styles.bottonDownload}
                      name="voucherConfirmation"
                      value={id}
                      handleClick={handleDownloadVoucherConfirmation}
                      iconClassName="fas fa-file-alt fa-2x"
                      iconStyle={styles.iconButton}
                      content={t('printVoucherConfirmation')} //"Print Voucher Confirmation"
                      popupShowOn="hover"
                    />
                  ) : null}
                  {((packageType !== 'Fixed' &&
                    status.key !== 'Booking_draft') ||
                    isAcceptedQuotation) && (
                    <PopUpButton
                      buttonStyle={styles.bottonDownload}
                      name="copy"
                      value={
                        packageType !== 'FixedDateVariable'
                          ? isAcceptedQuotation
                            ? tourTransactionIdQuotation
                            : id
                          : bookingTemplateRef
                      }
                      handleClick={handleCreateSimilar}
                      iconClassName="fas fa-copy fa-2x"
                      iconStyle={styles.iconButton}
                      content={t('createSimilar')} //"Create Similar"
                      popupShowOn="hover"
                    />
                  )}
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    />
  );
};
CardBackgroundImage.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  expiredOn: PropTypes.string,
  image: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  status: PropTypes.string,
  getInvoiceCustomer: PropTypes.func,
  getInvoiceTravelAgent: PropTypes.func,
  getScheduleTourTransaction: PropTypes.func,
  resetStatusGetInvoiceSchedule: PropTypes.func,
  invoice: PropTypes.object,
  schedule: PropTypes.object,
  isAcceptedQuotation: PropTypes.bool,
  tourTransactionIdQuotation: PropTypes.number,
  createSimilar: PropTypes.func,
  packageType: PropTypes.string,
  getVoucherConfirmation: PropTypes.func,
  voucherConfirmation: PropTypes.object,
  isSales: PropTypes.bool,
  bookingTemplateRef: PropTypes.number,
  handlegetInvoiceCustomer: PropTypes.func,
  handlegetInvoiceTravelAgent: PropTypes.func,
  handlegetScheduleTourTransaction: PropTypes.func,
  handleresetStatusGetInvoiceSchedule: PropTypes.func,
  handlegetVoucherConfirmation: PropTypes.func,
};

export default CardBackgroundImage;
