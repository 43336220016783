// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { Modal, Grid } from 'semantic-ui-react';
// import Filter from '../../../formFilter/FormFilter';
// import ListCard from './ListCard';
// import { ButtonCloseModal } from '../../../common';
// import styles from './styles';
// import { resetStatusExcursion } from '../../../../actions/excursionAction';
// import ModalExcursionMap from '../modalExcursionMap/ModalExcursionMap';
// import axios from 'axios';
// import googleAPIKey from '../../../../../../config/envApiKey';
// import { filterDataBySecondDataReverse } from '../../../../services/helper/filterData';
// import { setCoordinateAddress } from '../../../../actions/locationAction';
// const listExcursionCategory = [
//   { key: 'package', text: 'Fixed Timing', checked: false },
//   { key: 'single', text: 'Flexible Timing', checked: false },
// ];
// class ModalListExcursion extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       formFilter: [],
//       activePage: 1,
//       searchName: '',
//       openMaps: false,
//       centerMap: null,
//       accommodation: null,
//     };
//   }
//   componentDidUpdate = () => {
//     if (this.props.excursionTypeStatus === 'success') {
//       let data = [
//         { name: 'Excursion Category', data: listExcursionCategory },
//         {
//           name: 'Excursion Type',
//           data: this.showOptions(this.props.listExcursionType),
//         },
//       ];
//       this.setState({
//         ...this.state,
//         formFilter: data,
//       });
//       this.props.resetStatusExcursion();
//     }
//   };
//   static propTypes = {
//     open: PropTypes.bool,
//     onClose: PropTypes.func,
//     name: PropTypes.string,
//     data: PropTypes.array,
//     location: PropTypes.string,
//     resetStatusExcursion: PropTypes.func,
//     listExcursionType: PropTypes.array,
//     excursionTypeStatus: PropTypes.string,
//     loading: PropTypes.bool,
//     loadingExcursionByFilter: PropTypes.bool,
//     setExcursion: PropTypes.func,
//     coordinates: PropTypes.array,
//     movements: PropTypes.array,
//     index: PropTypes.number,
//     mainPrograms: PropTypes.array,
//     setCoordinateAddress: PropTypes.func,
//   };
//   showOptions = data => {
//     return data.map(e => {
//       return { key: e.Id, text: e.Name, checked: false };
//     });
//   };
//   filterExcursion = (data, formFilter, searchName) => {
//     let dataFilter, indexForm;
//     return data.filter(data => {
//       let { AttractionCategory, AttractionType } = data;
//       dataFilter = true;
//       if (searchName) {
//         dataFilter =
//           dataFilter &&
//           data.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1;
//       }
//       indexForm = formFilter.findIndex(e => e.name === 'Excursion Category');
//       indexForm !== -1 &&
//         formFilter[indexForm].data.some(e => e.checked) &&
//         (dataFilter =
//           dataFilter &&
//           formFilter[indexForm].data.findIndex(
//             e => e.key === AttractionCategory.toLowerCase() && e.checked
//           ) !== -1);

//       indexForm = formFilter.findIndex(e => e.name === 'Excursion Type');
//       indexForm !== -1 &&
//         formFilter[indexForm].data.some(e => e.checked) &&
//         (dataFilter =
//           dataFilter &&
//           formFilter[indexForm].data.findIndex(
//             e => e.key === AttractionType.Id && e.checked
//           ) !== -1);
//       return dataFilter;
//     });
//   };
//   filteredData = data => {
//     let { formFilter, searchName } = this.state;
//     return this.filterExcursion(data, formFilter, searchName);
//   };

//   resetFilter = listData => {
//     return listData.map(data => {
//       return {
//         key: data.key,
//         text: data.text,
//         checked: false,
//         desc: data.desc,
//       };
//     });
//   };
//   pageChange = (e, { activePage }) => {
//     this.setState({ ...this.state, activePage });
//   };
//   //============Handler===========
//   handleChange = (e, { name, value }) => {
//     this.setState({
//       ...this.state,
//       [name]: value,
//       activePage: 1,
//     });
//   };
//   handleChangeCheckbox = (e, { name, value }) => {
//     let dataList = this.state.formFilter;
//     let indexForm = dataList.findIndex(e => e.name === name);
//     let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
//     dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
//       indexData
//     ].checked;
//     this.setState({ ...this.state, formFilter: dataList, activePage: 1 });
//   };
//   handleResetFilter = () => {
//     let { formFilter } = this.state;
//     formFilter = formFilter.map(filter =>
//       filter.data.some(e => e.checked)
//         ? { name: filter.name, data: this.resetFilter(filter.data) }
//         : filter
//     );
//     return this.setState({ ...this.state, formFilter, activePage: 1 });
//   };
//   handleOpenMap = () => {
//     this.setState({ openMaps: true }, () => {
//       this.shouldGetCoordinate();
//       this.getCenterCoordinate();
//     });
//   };
//   handleCloseMap = () => {
//     this.setState({ openMaps: false });
//   };

//   //============helper================
//   shouldGetCoordinate = () => {
//     let { data, coordinates } = this.props;
//     let newData = filterDataBySecondDataReverse(
//       data,
//       coordinates,
//       'ServiceItemId',
//       'id'
//     );
//     let length = newData.length;
//     if (length > 0) {
//       for (var i = 0; i < length; i++) {
//         // eslint-disable-next-line
//         this.saveCoordinate(newData[i]);
//       }
//     }
//   };
//   getCoordinateFromProfile = (serviceItemId, data) => {
//     let newData = data.find(item => item.ServiceItemId === serviceItemId);
//     return newData ? newData.AddressObject.Coordinate : null;
//   };
//   saveCoordinate = data => {
//     let { Coordinate } = data.AddressObject;
//     let newCoordinate = {
//       lat: Coordinate ? Number(Coordinate.Lat) : null,
//       lng: Coordinate ? Number(Coordinate.Lng) : null,
//     };
//     this.props.setCoordinateAddress(
//       newCoordinate,
//       data.ServiceItemId,
//       'excursion'
//     );
//   };
//   getLocation = async (address, id, type) => {
//     const context = this.props;
//     await axios
//       .get(
//         `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleAPIKey}`
//       )
//       .then(res => {
//         res.status === 'ZERO_RESULTS'
//           ? context.setCoordinateAddress(
//               {
//                 lat: null,
//                 lng: null,
//               },
//               id,
//               type
//             )
//           : context.setCoordinateAddress(
//               res.data.results[0].geometry.location,
//               id,
//               type
//             );
//       });
//   };
//   getAccommodationObject = () => {
//     let { movements, index, mainPrograms } = this.props;
//     let move = movements[index];
//     let accom1 = mainPrograms.find(
//       item =>
//         item.AccommodationSummary &&
//         this.inRange(
//           move.DateTime,
//           item.AccommodationSummary.CheckIn,
//           item.AccommodationSummary.Checkout
//         )
//     );
//     let accommodationObj = { id: '', address: '' };
//     if (accom1) {
//       accommodationObj.id = accom1.AccommodationSummary.ServiceItemId;
//       accommodationObj.address = accom1.AccommodationSummary.Address;
//     } else {
//       let accom2 = movements.find(
//         item =>
//           item.Destination === movements[index].Destination &&
//           ['CHECKIN', 'CHECKOUT', 'DAYSTART', 'DAYEND'].indexOf(
//             item.MovementName
//           ) !== -1
//       );
//       accommodationObj.id = accom2.Item.ServiceItemId;
//       accommodationObj.address = accom2.Item.Address;
//     }
//     return accommodationObj;
//   };
//   getCenterCoordinate = () => {
//     let { coordinates } = this.props;
//     let accommodation = this.getAccommodationObject();
//     let center = coordinates.find(
//       item => item.id === accommodation.id && item.placeType === 'accommodation'
//     );
//     if (center !== null) {
//       this.getLocation(
//         accommodation.address,
//         accommodation.id,
//         'accommodation'
//       );
//     }
//     this.setState({
//       ...this.state,
//       accommodation: accommodation,
//     });
//   };
//   inRange = (dateTime, startDate, EndDate) => {
//     return (
//       new Date(startDate).getTime() < new Date(dateTime).getTime() &&
//       new Date(dateTime).getTime() < new Date(EndDate).getTime()
//     );
//   };
//   render() {
//     let {
//       open,
//       onClose,
//       name,
//       data,
//       location,
//       loading,
//       loadingExcursionByFilter,
//       setExcursion,
//       coordinates,
//     } = this.props;
//     let {
//       formFilter,
//       activePage,
//       searchName,
//       openMaps,
//       centerMap,
//       accommodation,
//     } = this.state;
//     data = data.length !== 0 ? this.filteredData(data) : data;
//     data = !loadingExcursionByFilter ? data : [];
//     return (
//       <Modal open={open} onClose={onClose} size="large" closeOnDimmerClick>
//         <Modal.Header style={styles.backgroundModal}>
//           {name}
//           <ButtonCloseModal
//             onClose={onClose}
//             style={styles.marginLeftMinus5px}
//           />
//         </Modal.Header>
//         <Modal.Content style={styles.backgroundModal}>
//           <Modal.Description>
//             <Grid>
//               <Grid.Column width={5}>
//                 <Filter
//                   loading={loading}
//                   formFilter={formFilter}
//                   handleChange={this.handleChangeCheckbox}
//                   resetFilter={this.handleResetFilter}
//                 />
//               </Grid.Column>
//               <Grid.Column width={11}>
//                 <ListCard
//                   location={location}
//                   data={data}
//                   loading={loadingExcursionByFilter}
//                   activePage={activePage}
//                   pageChange={this.pageChange}
//                   searchName={searchName}
//                   handleChange={this.handleChange}
//                   setExcursion={setExcursion}
//                   handleOpenMap={this.handleOpenMap}
//                 />
//               </Grid.Column>
//             </Grid>
//           </Modal.Description>
//         </Modal.Content>
//         <ModalExcursionMap
//           open={openMaps}
//           onClose={this.handleCloseMap}
//           data={data}
//           coordinates={coordinates}
//           centerMap={centerMap}
//           accommodation={accommodation}
//         />
//       </Modal>
//     );
//   }
// }
// const mapStateToProps = state => {
//   return {
//     listExcursionType: state.excursion.excursionTypes,
//     excursionTypeStatus: state.excursion.getExcursionTypeStatus,
//     loading: state.excursion.loading,
//     loadingExcursionByFilter: state.excursion.loadingExcursionByFilter,
//     coordinates: state.location.coordinates,
//     mainPrograms: state.customPackage.HeadlineProgram.MainPrograms,
//   };
// };
// export default connect(
//   mapStateToProps,
//   {
//     resetStatusExcursion,
//     setCoordinateAddress,
//   }
// )(ModalListExcursion);

//===============
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Grid } from 'semantic-ui-react';
import Filter from '../../../formFilter/FormFilter';
import ListCard from './ListCard';
import { ButtonCloseModal } from '../../../common';
import styles from './styles';
import { resetStatusExcursion } from '../../../../actions/excursionAction';
import ModalExcursionMap from '../modalExcursionMap/ModalExcursionMap';
import axios from 'axios';
import googleAPIKey from '../../../../../../config/envApiKey';
import { filterDataBySecondDataReverse } from '../../../../services/helper/filterData';
import { setCoordinateAddress } from '../../../../actions/locationAction';
const listExcursionCategory = [
  { key: 'package', text: 'Fixed Timing', checked: false },
  { key: 'single', text: 'Flexible Timing', checked: false },
];
const ModalListExcursion = props => {
  //========initial state========
  const [formFilter, setFormFilter] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [openMaps, setOpenMaps] = useState(false);
  // const [centerMap, setCenterMap] = useState(null);
  const [accommodation, setAccommodation] = useState(null);

  let {
    open,
    onClose,
    name,
    data,
    location,
    setExcursion,
    movements,
    index,
  } = props;
  //=========store==========
  const listExcursionType = useSelector(
    state => state.excursion.excursionTypes
  );
  const excursionTypeStatus = useSelector(
    state => state.excursion.getExcursionTypeStatus
  );
  const loading = useSelector(state => state.excursion.loading);
  const loadingExcursionByFilter = useSelector(
    state => state.excursion.loadingExcursionByFilter
  );
  const coordinates = useSelector(state => state.location.coordinates);
  const mainPrograms = useSelector(
    state => state.customPackage.HeadlineProgram.MainPrograms
  );
  const dispatch = useDispatch();
  const resetStatusExcursionAction = () => dispatch(resetStatusExcursion());
  const setCoordinateAddressAction = data =>
    dispatch(setCoordinateAddress(data));
  useEffect(() => {
    if (excursionTypeStatus === 'success') {
      let data = [
        { name: 'Excursion Category', data: listExcursionCategory },
        {
          name: 'Excursion Type',
          data: showOptions(listExcursionType),
        },
      ];
      setFormFilter(data);
      resetStatusExcursionAction();
    }
    // eslint-disable-next-line
  }, [excursionTypeStatus]);

  // componentDidUpdate = () => {
  //   if (props.excursionTypeStatus === 'success') {
  //     let data = [
  //       { name: 'Excursion Category', data: listExcursionCategory },
  //       {
  //         name: 'Excursion Type',
  //         data: showOptions(props.listExcursionType),
  //       },
  //     ];
  //     setState({
  //       ...state,
  //       formFilter: data,
  //     });
  //     props.resetStatusExcursion();
  //   }
  // };

  const showOptions = list => {
    return list.map(e => {
      return { key: e.Id, text: e.Name, checked: false };
    });
  };
  const filterExcursion = (listData, formFilter, searchName) => {
    let dataFilter, indexForm;
    return listData.filter(item => {
      let { AttractionCategory, AttractionType } = item;
      dataFilter = true;
      if (searchName) {
        dataFilter =
          dataFilter &&
          item.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1;
      }
      indexForm = formFilter.findIndex(e => e.name === 'Excursion Category');
      indexForm !== -1 &&
        formFilter[indexForm].data.some(e => e.checked) &&
        (dataFilter =
          dataFilter &&
          formFilter[indexForm].data.findIndex(
            e => e.key === AttractionCategory.toLowerCase() && e.checked
          ) !== -1);

      indexForm = formFilter.findIndex(e => e.name === 'Excursion Type');
      indexForm !== -1 &&
        formFilter[indexForm].data.some(e => e.checked) &&
        (dataFilter =
          dataFilter &&
          formFilter[indexForm].data.findIndex(
            e => e.key === AttractionType.Id && e.checked
          ) !== -1);
      return dataFilter;
    });
  };
  const filteredData = listData => {
    return filterExcursion(listData, formFilter, searchName);
  };

  const resetFilter = listData => {
    return listData.map(item => {
      return {
        key: item.key,
        text: item.text,
        checked: false,
        desc: item.desc,
      };
    });
  };
  const pageChange = (e, { activePage }) => {
    setActivePage(activePage);
  };
  //============Handler===========
  const handleChange = (e, { value }) => {
    setSearchName(value);
    setActivePage(1);
  };
  const handleChangeCheckbox = (e, { name, value }) => {
    let dataList = [...formFilter];
    let indexForm = dataList.findIndex(e => e.name === name);
    let indexData = dataList[indexForm].data.findIndex(e => e.key === value);
    dataList[indexForm].data[indexData].checked = !dataList[indexForm].data[
      indexData
    ].checked;
    setFormFilter(dataList);
    setActivePage(1);
  };

  const handleResetFilter = () => {
    let newFormFilter = formFilter.map(item =>
      item.data.some(e => e.checked)
        ? { name: item.name, data: resetFilter(item.data) }
        : item
    );
    setFormFilter(newFormFilter);
    setActivePage(1);
  };
  const handleOpenMap = () => {
    setOpenMaps(true);
    shouldGetCoordinate();
    getCenterCoordinate();
  };
  const handleCloseMap = () => setOpenMaps(false);
  //============helper================
  const shouldGetCoordinate = () => {
    let newData = filterDataBySecondDataReverse(
      data,
      coordinates,
      'ServiceItemId',
      'id'
    );
    let length = newData.length;
    if (length > 0) {
      for (var i = 0; i < length; i++) {
        // eslint-disable-next-line
        saveCoordinate(newData[i]);
      }
    }
  };
  // const getCoordinateFromProfile = (serviceItemId, data) => {
  //   let newData = data.find(item => item.ServiceItemId === serviceItemId);
  //   return newData ? newData.AddressObject.Coordinate : null;
  // };
  const saveCoordinate = data => {
    let { Coordinate } = data.AddressObject;
    let newCoordinate = {
      lat: Coordinate ? Number(Coordinate.Lat) : null,
      lng: Coordinate ? Number(Coordinate.Lng) : null,
    };
    setCoordinateAddressAction(newCoordinate, data.ServiceItemId, 'excursion');
  };
  const getLocation = async (address, id, type) => {
    await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleAPIKey}`
      )
      .then(res => {
        res.status === 'ZERO_RESULTS'
          ? setCoordinateAddressAction(
              {
                lat: null,
                lng: null,
              },
              id,
              type
            )
          : setCoordinateAddressAction(
              res.data.results[0].geometry.location,
              id,
              type
            );
      });
  };
  const getAccommodationObject = () => {
    // let { movements, index, mainPrograms } = this.props;
    let move = movements[index];
    let accom1 = mainPrograms.find(
      item =>
        item.AccommodationSummary &&
        inRange(
          move.DateTime,
          item.AccommodationSummary.CheckIn,
          item.AccommodationSummary.Checkout
        )
    );
    let accommodationObj = { id: '', address: '' };
    if (accom1) {
      accommodationObj.id = accom1.AccommodationSummary.ServiceItemId;
      accommodationObj.address = accom1.AccommodationSummary.Address;
    } else {
      let accom2 = movements.find(
        item =>
          item.Destination === movements[index].Destination &&
          ['CHECKIN', 'CHECKOUT', 'DAYSTART', 'DAYEND'].indexOf(
            item.MovementName
          ) !== -1
      );
      accommodationObj.id = accom2.Item.ServiceItemId;
      accommodationObj.address = accom2.Item.Address;
    }
    return accommodationObj;
  };
  const getCenterCoordinate = () => {
    let accommodation = getAccommodationObject();
    let center = coordinates.find(
      item => item.id === accommodation.id && item.placeType === 'accommodation'
    );
    if (center !== null) {
      getLocation(accommodation.address, accommodation.id, 'accommodation');
    }
    setAccommodation(accommodation);
  };
  const inRange = (dateTime, startDate, EndDate) => {
    return (
      new Date(startDate).getTime() < new Date(dateTime).getTime() &&
      new Date(dateTime).getTime() < new Date(EndDate).getTime()
    );
  };

  let newData = data.length !== 0 ? filteredData(data) : data;
  newData = !loadingExcursionByFilter ? newData : [];
  return (
    <Modal open={open} onClose={onClose} size="large" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {name}
        <ButtonCloseModal onClose={onClose} style={styles.marginLeftMinus5px} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Modal.Description>
          <Grid>
            <Grid.Column width={5}>
              <Filter
                loading={loading}
                formFilter={formFilter}
                handleChange={handleChangeCheckbox}
                resetFilter={handleResetFilter}
              />
            </Grid.Column>
            <Grid.Column width={11}>
              <ListCard
                location={location}
                data={newData}
                loading={loadingExcursionByFilter}
                activePage={activePage}
                pageChange={pageChange}
                searchName={searchName}
                handleChange={handleChange}
                setExcursion={setExcursion}
                handleOpenMap={handleOpenMap}
              />
            </Grid.Column>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <ModalExcursionMap
        open={openMaps}
        onClose={handleCloseMap}
        data={newData}
        coordinates={coordinates}
        // centerMap={centerMap}
        accommodation={accommodation}
      />
    </Modal>
  );
};

ModalListExcursion.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  data: PropTypes.array,
  location: PropTypes.string,
  resetStatusExcursion: PropTypes.func,
  listExcursionType: PropTypes.array,
  excursionTypeStatus: PropTypes.string,
  loading: PropTypes.bool,
  loadingExcursionByFilter: PropTypes.bool,
  setExcursion: PropTypes.func,
  coordinates: PropTypes.array,
  movements: PropTypes.array,
  index: PropTypes.number,
  mainPrograms: PropTypes.array,
  setCoordinateAddress: PropTypes.func,
};

export default ModalListExcursion;
