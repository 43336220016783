import React, { Component } from 'react';
import { Modal, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TourOperatorSegment from './TourOperatorSegment';
import Loader from '../../../../../../components/common/loader';
import { ButtonCloseModal } from '../../../common';
import { withTranslation } from 'react-i18next';

class ModalTourOperator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalExcursion: false,
      openModalRestaurant: false,
      activityData: {
        Name: '',
        Startime: '',
        Duration: 0,
        optimumDuration: 0,
        Note: null,
      },
      activityObj: null,
    };
  }
  show = size => () => this.setState({ size, open: true });
  close = () => {
    this.props.closeModal();
  };
  render() {
    const { t } = this.props;
    let {
      open,
      tourOperatorList,
      setTourOperator,
      closeModal,
      loading,
      isFixPrice,
    } = this.props;
    return (
      <div>
        <Modal size="small" open={open} onClose={this.props.closeModal}>
          <Modal.Header>
            {/* Tour Operator  */}
            {t('tourOperator')}{' '}
            <ButtonCloseModal onClose={this.props.closeModal} />
          </Modal.Header>
          <Modal.Content>
            {tourOperatorList.length > 0 && !loading ? (
              tourOperatorList.map((item, i) => (
                <TourOperatorSegment
                  key={i}
                  data={item}
                  setTourOperator={setTourOperator}
                  closeModal={closeModal}
                  isFixPrice={isFixPrice}
                />
              ))
            ) : (
              <Header as="h3" textAlign="center">
                {/* No Tour Operator Available */}
                {t('noTourOperatorAvailable')}
              </Header>
            )}
          </Modal.Content>
          <Loader active={loading} inverted={true} />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    tourOperatorList: state.tourOperator.tourOperatorList,
    loading: state.tourOperator.loading,
  };
};
ModalTourOperator.propTypes = {
  tourOperatorList: PropTypes.array,
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  setTourOperator: PropTypes.func,
  loading: PropTypes.bool,
  isFixPrice: PropTypes.bool,
  t: PropTypes.func,
};
export default connect(mapStateToProps)(withTranslation()(ModalTourOperator));
