import React from 'react';
import Proptypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image-loading';
import noImage from '../../assets/image/NoImage.png';
import { Image } from 'semantic-ui-react';
// import styleProgressiveImage from './style';

const imageProgressive = props => {
  let {
    preview,
    src,
    styles,
    transitionTime,
    transitionFunction,
    // Image Semantic ui props
    avatar,
    bordered,
    centered,
    children,
    circular,
    className,
    content,
    dimmer,
    disabled,
    floated,
    fluid,
    hidden,
    href,
    inline,
    label,
    rounded,
    size,
    spaced,
    ui,
    verticalAlign,
    wrapped,
  } = props;
  return (
    <ProgressiveImage
      preview={preview ? preview : noImage}
      src={src ? src : noImage}
      transitionTime={transitionTime ? transitionTime : 500}
      transitionFunction={transitionFunction ? transitionFunction : 'ease'}
      // Image Semantic ui props
      avatar={avatar ? avatar : false}
      bordered={bordered ? bordered : false}
      centered={centered ? centered : false}
      // eslint-disable-next-line
      children={children ? children : null}
      circular={circular ? circular : false}
      className={className ? className : null}
      content={content ? content : null}
      dimmer={dimmer ? dimmer : null}
      disabled={disabled ? disabled : false}
      floated={floated ? floated : null}
      fluid={fluid ? fluid : false}
      hidden={hidden ? hidden : false}
      href={href ? href : null}
      inline={inline ? inline : false}
      label={label ? label : null}
      rounded={rounded ? rounded : false}
      size={size ? size : null}
      spaced={spaced ? spaced : null}
      ui={ui ? ui : false}
      verticalAlign={verticalAlign ? verticalAlign : null}
      wrapped={wrapped ? wrapped : false}
      // render
      render={(src, style) => (
        <Image
          avatar={avatar}
          bordered={bordered}
          centered={centered}
          // eslint-disable-next-line
          children={children}
          circular={circular}
          className={className}
          content={content}
          dimmer={dimmer}
          disabled={disabled}
          floated={floated}
          fluid={fluid}
          hidden={hidden}
          href={href}
          inline={inline}
          label={label}
          rounded={rounded}
          size={size}
          spaced={spaced}
          ui={ui}
          verticalAlign={verticalAlign}
          wrapped={wrapped}
          src={preview}
          style={Object.assign(style, styles)}
          alt="img"
        />
      )}
    />
  );
};

imageProgressive.propTypes = {
  preview: Proptypes.string,
  src: Proptypes.string,
  styles: Proptypes.object,
  transitionTime: Proptypes.number,
  transitionFunction: Proptypes.string,
  // Image Semantic ui prop
  avatar: Proptypes.bool,
  bordered: Proptypes.bool,
  centered: Proptypes.bool,
  children: Proptypes.node,
  circular: Proptypes.bool,
  className: Proptypes.string,
  content: Proptypes.string,
  dimmer: Proptypes.string,
  disabled: Proptypes.bool,
  floated: Proptypes.string,
  fluid: Proptypes.bool,
  hidden: Proptypes.bool,
  href: Proptypes.string,
  inline: Proptypes.bool,
  label: Proptypes.object,
  rounded: Proptypes.bool,
  size: Proptypes.string,
  spaced: Proptypes.string,
  ui: Proptypes.bool,
  verticalAlign: Proptypes.string,
  wrapped: Proptypes.bool,
};

export default imageProgressive;
