import React from 'react';
import { Grid, Icon, Header, Button } from 'semantic-ui-react';
import styles from './styles';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const CreateCustomPackageSection = () => {
  const { t } = useTranslation();
  return (
    // <Container style={styles.backgroundWhite}>
    <Grid>
      <Grid.Row style={styles.noPaddingBot}>
        <Grid.Column width={1}>
          <Header as="h2" style={styles.roundShape}>
            OR
          </Header>
        </Grid.Column>
        <Grid.Column width={14} textAlign="center">
          <Icon
            className="Custom-Package-Icon-Circle-2x"
            style={styles.marginTop25}
          />
        </Grid.Column>
        {/* <Grid.Column width={1} style={styles.floatRight} /> */}
      </Grid.Row>
      <Grid.Row style={{ ...styles.noPaddingBot, ...styles.noPaddingTop }}>
        <Grid.Column textAlign="center">
          <Header as="h2" style={styles.colorGold}>
            {/* Create your own */}
            {t('createYourOwn')}
            <br />
            {/* customized tour */}
            {t('CustomizedTour')}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={styles.noPaddingTop}>
        <Grid.Column style={styles.centerAlign}>
          <Button
            size="big"
            style={styles.buttonGetStarted}
            as={Link}
            to="/custom-package"
          >
            {/* GET STARTED! */}
            <strong>{t('getStarted')}</strong>
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    // </Container>
  );
};

export default CreateCustomPackageSection;
