const GOLD_COLOR = '#e6ca6b';
const style = {
  goldColor: {
    color: GOLD_COLOR,
  },
  filterHeaderStyle: { fontSize: '25px', fontWeight: '900' },
  featuredPAckageHeaderStyle: {
    fontSize: '25px',
    fontWeight: '900',
    textAlign: 'center',
  },
  loadingStyle: { background: 'transparent', height: '50px' },
  textCenter: { textAlign: 'center' },
  paddingTourPackages: { padding: '0px 150px' },
  marginLeftRight20: { marginLeft: '20px', marginRight: '20px' },
};

export default style;
