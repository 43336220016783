import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Grid,
  Radio,
  TextArea,
  Form,
  Header,
  Divider,
} from 'semantic-ui-react';
import style from './styles';
import LoaderModal from '../../../shared/components/common/LoaderModal';

const ModalListCancellationReason = props => {
  //==============initial=============
  const {
    data,
    handleConfirm,
    open,
    close,
    loadingModal,
    loadingPostCancel,
  } = props;
  const [cancellationReasonNote, setCancellationReasonNote] = useState('');
  const [countCharacter, setCountCharacter] = useState(0);
  const [checkNote, setCheckNote] = useState(false);
  const [checkRadio, setCheckRadio] = useState(undefined);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e, { value }) => {
    setCancellationReasonNote(value);
    setCountCharacter(value.length);
    setError(false);
  };

  const handleRadioButton = (e, { value }) => {
    if (value === null) {
      setCheckNote(true);
      setCheckRadio(value);
      setError(false);
    } else {
      setCheckNote(false);
      setCheckRadio(value);
      setError(false);
    }
  };

  const handleConfirmButton = () => {
    if (checkRadio === undefined) {
      setError(true);
      setErrorMessage('Please choose cancellation reason');
    } else if (checkRadio === null && cancellationReasonNote === '') {
      setError(true);
      setErrorMessage('Field cannot be empty');
    } else if (!error) {
      let data = {
        CancellationReasonId: checkRadio,
        CancellationReasonNote: cancellationReasonNote
          ? cancellationReasonNote
          : null,
      };
      handleConfirm(data);
    }
  };

  return (
    <Modal
      dimmer="blurring"
      open={open}
      onClose={close}
      size="small"
      style={style.modalWidthCancel}
    >
      <Modal.Header>Why you cancel this booking ?</Modal.Header>
      <LoaderModal loading={loadingModal || loadingPostCancel} />
      <Modal.Content>
        <Grid style={style.marginModalCancellationReason.marginTop0}>
          <React.Fragment>
            <div className={data ? 'scrollableMenu' : ''}>
              {data
                ? data.map((item, idx) => {
                    return (
                      // eslint-disable-next-line
                      <Grid>
                        <Grid.Row key={idx} columns={2}>
                          <Grid.Column width={14}>
                            {item && item.Name ? item.Name : null}
                          </Grid.Column>
                          <Grid.Column width={1}>
                            <Radio
                              name="cancellationReason"
                              value={item && item.Id ? item.Id : null}
                              onClick={handleRadioButton}
                              checked={checkRadio === item.Id}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Divider
                          style={{
                            ...style.marginModalCancellationReason
                              .marginBottom1,
                            ...style.marginModalCancellationReason.marginTop1,
                          }}
                        />
                      </Grid>
                    );
                  })
                : ''}
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Radio
                      name="cancellationReason"
                      value={null}
                      label="I have another reason"
                      onClick={handleRadioButton}
                      checked={checkRadio === null}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  style={style.marginModalCancellationReason.marginTopMin20}
                >
                  <Grid.Column>
                    <Form>
                      <Form.Field error={error}>
                        <TextArea
                          placeholder="Type here for your reason"
                          fluid
                          maxLength="140"
                          disabled={!checkNote}
                          name="cancellationReasonNote"
                          value={cancellationReasonNote}
                          onChange={handleChange}
                          error={error}
                        />
                      </Form.Field>
                    </Form>
                    <Header
                      as="h5"
                      color={countCharacter === 140 ? 'red' : 'black'}
                      style={style.marginModalCancellationReason.marginTop0}
                    >
                      {`${countCharacter} / 140 characters`}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </React.Fragment>
        </Grid>
      </Modal.Content>
      <Modal.Content
        style={{
          ...style.marginModalCancellationReason.marginTopMin20,
          ...style.marginModalCancellationReason.marginBottom1,
        }}
      >
        {error && (
          <span style={style.marginModalCancellationReason.cancelButton}>
            {errorMessage}
          </span>
        )}
      </Modal.Content>
      <Modal.Actions style={style.center}>
        <Button
          onClick={handleConfirmButton}
          style={style.buttonCancel}
          content="Send"
        />
      </Modal.Actions>
    </Modal>
  );
};
ModalListCancellationReason.propTypes = {
  data: PropTypes.array,
  handleConfirm: PropTypes.func,
  open: PropTypes.func,
  close: PropTypes.func,
  loadingModal: PropTypes.bool,
  loadingPostCancel: PropTypes.bool,
};
export default ModalListCancellationReason;
