import * as types from './constant/actionTypes';

const initialState = {
  userProfileByCompany: [],
  getUserProfileByCompanyStatus: '',
  errors: {},
  loadingGetUserProfileByCompany: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_USER_PROFILE_BY_COMPANY_CODE
    //---------------------------------
    case types.GET_USER_PROFILE_BY_COMPANY_CODE:
      return { ...state };
    case types.GET_USER_PROFILE_BY_COMPANY_CODE_PENDING:
      return { ...state, loadingGetUserProfileByCompany: true };
    case types.GET_USER_PROFILE_BY_COMPANY_CODE_FULFILLED:
      return {
        ...state,
        userProfileByCompany: action.payload.data,
        errors: {},
        getUserProfileByCompanyStatus: 'success',
        loadingGetUserProfileByCompany: false,
      };
    case types.GET_USER_PROFILE_BY_COMPANY_CODE_REJECTED:
      return {
        ...state,
        userProfileByCompany: [],
        errors: action.payload.response.data,
        getUserProfileByCompanyStatus: 'failed',
        loadingGetUserProfileByCompany: false,
      };
    //---------------------------------
    // GET_USER_PROFILE_BY_CODE_FOR_SALES
    //---------------------------------
    case types.GET_USER_PROFILE_BY_CODE_FOR_SALES:
      return { ...state };
    case types.GET_USER_PROFILE_BY_CODE_FOR_SALES_PENDING:
      return { ...state, loadingGetUserProfileByCompany: true };
    case types.GET_USER_PROFILE_BY_CODE_FOR_SALES_FULFILLED:
      return {
        ...state,
        userProfileByCompany: action.payload.data,
        errors: {},
        getUserProfileByCompanyStatus: 'success',
        loadingGetUserProfileByCompany: false,
      };
    case types.GET_USER_PROFILE_BY_CODE_FOR_SALES_REJECTED:
      return {
        ...state,
        userProfileByCompany: [],
        errors: action.payload.response.data,
        getUserProfileByCompanyStatus: 'failed',
        loadingGetUserProfileByCompany: false,
      };
    default:
      return state;
  }
};

export default reducer;
