import { BACKGROUND_COLOR, GOLD_COLOR } from '../../constant/colour';

const style = {
  displayNone: {
    display: 'none',
  },
  fixedMenuStyle: {
    backgroundColor: BACKGROUND_COLOR,
    border: `1px solid ${BACKGROUND_COLOR}`,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    color: GOLD_COLOR,
  },
  marginBotFifteenRelative: { marginBottom: '15px', position: 'relative' },
  menuContainerStyle: { fontSize: '16px', justifyContent: 'center' },
  goldColor: {
    color: GOLD_COLOR,
  },
  menuPriceStyle: {
    backgroundColor: GOLD_COLOR,
    borderRight: `1px solid ${BACKGROUND_COLOR}`,
  },
  paddingFive: { padding: '5px' },
  marginTopFifteen: { marginTop: '15px' },
  zeroZIndex: { zIndex: 0 },
  paddingZero: { paddingTop: 0 },
  backgroundPrimaryWithBorder: {
    background: BACKGROUND_COLOR,
    color: GOLD_COLOR,
    border: `1px solid ${GOLD_COLOR}`,
  },
  zIndex1: { zIndex: 1 },
};

export default style;
