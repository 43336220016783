import apiClient from './apiClient';
import { POST_ACCEPT_QUOTATION_URL, POST_EDIT_QUOTATION_URL } from './apiUrl';
const postAcceptQuotationApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(`${POST_ACCEPT_QUOTATION_URL}?tourTransactionId=${id}`);
};
const postEditQuotationApi = (id, data) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(
    `${POST_EDIT_QUOTATION_URL}?tourTransactionId=${id}`,
    data
  );
};
export { postAcceptQuotationApi, postEditQuotationApi };
