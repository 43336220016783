import {
  GET_TRANSPORTATION_UNIT_BY_ID,
  RESET_STATUS_TRANSPORTATION_UNIT_BY_ID,
} from './constant/actionTypes';
import { getTransportationUnitByIdApi } from './../../api/transportationApi';

const resetStatusTransportationUnitById = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TRANSPORTATION_UNIT_BY_ID,
    });
  };
};
const getTransportationUnitById = id => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_UNIT_BY_ID,
      payload: getTransportationUnitByIdApi(id),
    });
  };
};

export { resetStatusTransportationUnitById, getTransportationUnitById };
