import { convertDateFormat } from '../../../../genericFunction/moment';
import convertStatus from './convertStatus';

export const getDataForBookingSegment = (
  tourTransactionSummary,
  isAcceptedQuotation
) => {
  let data = {
    title: '',
    city: '',
    country: '',
    expiredOn: '',
    startDate: '',
    endDate: '',
    status: '',
    currency: '',
    totalPrice: '',
    commision: '',
    paymentTerm: '',
    percentagePaymentTerm: '',
    totalPayable: '',
    totalPaid: '',
    createdBy: '',
    tourTransactionIdQuotation: '',
    bookingTemplateRef: '',
  };
  if (tourTransactionSummary.BookingDetailSum) {
    data = {
      title: tourTransactionSummary.BookingDetailSum.Title,
      city: tourTransactionSummary.BookingDetailSum.City.Name,
      country: tourTransactionSummary.BookingDetailSum.Country.Name,
      expired: tourTransactionSummary.BookingDetailSum.ExpiredOn,
      expiredOn: convertDateFormat(
        tourTransactionSummary.BookingDetailSum.ExpiredOn,
        'DD MMM YYYY'
      ),
      startDate: convertDateFormat(
        tourTransactionSummary.BookingDetailSum.StartDate,
        'DD MMM YYYY'
      ),
      endDate: convertDateFormat(
        tourTransactionSummary.BookingDetailSum.EndDate,
        'DD MMM YYYY'
      ),
      status: convertStatus(
        tourTransactionSummary.BookingDetailSum.Status,
        isAcceptedQuotation
      ),
      currency: tourTransactionSummary.BookingDetailSum.CurrencyId,
      totalPrice: tourTransactionSummary.BookingDetailSum.TourTotalPrice,
      commision:
        tourTransactionSummary.BookingDetailSum.PackageType === 'Fixed'
          ? tourTransactionSummary.BookingDetailSum.FixedPackage.BookingCommission.ApplicableCommission.reduce(
              (acc, commission) => {
                return acc + commission.TotalPrice;
              },
              0
            )
          : 0,
      paymentTerm: tourTransactionSummary.BookingDetailSum.PaymentTerms
        ? tourTransactionSummary.BookingDetailSum.PaymentTerms.length > 0
          ? tourTransactionSummary.BookingDetailSum.PaymentTerms.filter(
              paid => !paid.IsPayed
            )[0]
          : ''
        : '',
      percentagePaymentTerm: data.paymentTerm
        ? data.paymentTerm.PaymentPercentage
        : 0,
      totalPayable:
        tourTransactionSummary.BookingDetailSum.PackageType === 'Fixed'
          ? tourTransactionSummary.BookingDetailSum.FixedPackage
              .BookingCommission.TotalPriceForCustomer
          : 0,
      totalPaid: tourTransactionSummary.BookingDetailSum.TotalPayed,
      createdBy: `${
        tourTransactionSummary.BookingDetailSum.CreatedBy.FirstName
      } ${tourTransactionSummary.BookingDetailSum.CreatedBy.LastName}`,
      packageType: tourTransactionSummary.BookingDetailSum.PackageType,
      tourTransactionIdQuotation: tourTransactionSummary.BookingDetailSum
        .Quotation
        ? tourTransactionSummary.BookingDetailSum.Quotation.TourTransactionId
        : 0,
      bookingTemplateRef:
        tourTransactionSummary.BookingDetailSum.PackageType ===
          'FixedDateVariable' &&
        tourTransactionSummary.BookingDetailSum.BookingTemplateRef,
    };
  }
  return data;
};
