const GET_BROCHURE = 'GET_BROCHURE';
const GET_BROCHURE_PENDING = 'GET_BROCHURE_PENDING';
const GET_BROCHURE_FULFILLED = 'GET_BROCHURE_FULFILLED';
const GET_BROCHURE_REJECTED = 'GET_BROCHURE_REJECTED';
const GET_BROCHURE_OVERLAY = 'GET_BROCHURE_OVERLAY';
const GET_BROCHURE_OVERLAY_PENDING = 'GET_BROCHURE_OVERLAY_PENDING';
const GET_BROCHURE_OVERLAY_FULFILLED = 'GET_BROCHURE_OVERLAY_FULFILLED';
const GET_BROCHURE_OVERLAY_REJECTED = 'GET_BROCHURE_OVERLAY_REJECTED';
const RESET_GET_BROCHURE = 'RESET_GET_BROCHURE';
const GET_SCHEDULE = 'GET_SCHEDULE';
const GET_SCHEDULE_PENDING = 'GET_SCHEDULE_PENDING';
const GET_SCHEDULE_FULFILLED = 'GET_SCHEDULE_FULFILLED';
const GET_SCHEDULE_REJECTED = 'GET_SCHEDULE_REJECTED';
const RESET_GET_SCHEDULE = 'RESET_GET_SCHEDULE';
export {
  GET_BROCHURE,
  GET_BROCHURE_PENDING,
  GET_BROCHURE_FULFILLED,
  GET_BROCHURE_REJECTED,
  GET_BROCHURE_OVERLAY,
  GET_BROCHURE_OVERLAY_PENDING,
  GET_BROCHURE_OVERLAY_FULFILLED,
  GET_BROCHURE_OVERLAY_REJECTED,
  RESET_GET_BROCHURE,
  GET_SCHEDULE,
  GET_SCHEDULE_PENDING,
  GET_SCHEDULE_FULFILLED,
  GET_SCHEDULE_REJECTED,
  RESET_GET_SCHEDULE,
};
