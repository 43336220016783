import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const BasicConfirmationModal = props => {
  const { title, content, status, onClick, modalOpen } = props;
  let color = '';
  let icon = '';
  let buttonLabel = '';
  if (status) {
    color = 'green';
    icon = 'checkmark';
    buttonLabel = 'ok';
  } else {
    color = 'red';
    icon = 'remove';
    buttonLabel = 'close';
  }

  return (
    <Modal size="tiny" open={modalOpen} closeOnDimmerClick="false">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color={color} inverted onClick={onClick}>
          <Icon name={icon} /> {buttonLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

BasicConfirmationModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  status: PropTypes.bool,
  onClick: PropTypes.func,
  modalOpen: PropTypes.bool,
};
export default BasicConfirmationModal;
