import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Container } from 'semantic-ui-react';
import BenefitItem from './BenefitItem';
// import benefitsData from '../../constant/benefitsData';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const Benefit = () => {
  const { t } = useTranslation();
  const translatedBenefitData = t('benefitData', { returnObjects: true });
  return (
    <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
      <Segment style={styles.noBorder}>
        <h1
          className="margin-bottom-twentyfive margin-bottom-ten"
          style={styles.headerStyle}
        >
          {/* {`Travel Agent's Benefits`} */}
          {t('travelAgentBenefit')}
        </h1>
        <Grid style={styles.gridStyles}>
          <Grid.Row>
            <Grid.Column width={8}>
              {translatedBenefitData.slice(0, 3).map((item, i) => (
                <BenefitItem
                  key={`left${i}`}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Grid.Column>
            <Grid.Column width={8}>
              {translatedBenefitData.slice(3).map((item, i) => (
                <BenefitItem
                  key={`right${i}`}
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

Benefit.propTypes = {
  t: PropTypes.func,
};

export default Benefit;
