import apiClient from './apiClient';
import {
  GET_FIXED_PACKAGE_ALL_URL,
  GET_FIXED_PACKAGE_BY_ID_URL,
  GET_FIXED_PACKAGE_LANDING_PAGE_URL,
  GET_FIXED_PACKAGE_WITH_FILTER_URL,
  GET_FEATURED_PACKAGE_BEFORE_LOGIN_URL,
  GET_FEATURED_PACKAGE_AFTER_LOGIN_URL,
} from './apiUrl';

const getFixedPackageAllApi = data => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return data
    ? apiClient.get(
        `${GET_FIXED_PACKAGE_WITH_FILTER_URL}?cityId=${data.location ||
          ''}&desiredDays=&tourCategoryId=${data.tourType ||
          ''}&tourPaxTypeId=${data.tourPaxType ||
          ''}&accommodationType=${data.accommodationType ||
          ''}&accommodationRating=${data.accommodationRating ||
          ''}&accommodationLocation=${data.accommodationLocation ||
          ''}&startingDate=${data.startingDate ||
          ''}&endingDate=${data.endingDate || ''}`
      )
    : apiClient.get(GET_FIXED_PACKAGE_ALL_URL);
};

const getFixedPackageByIdApi = (packageType, id) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_FIXED_PACKAGE_BY_ID_URL(packageType)}/${id}`);
};

const getFixedPackageLandingPageApi = country => {
  return apiClient.get(
    `${GET_FIXED_PACKAGE_LANDING_PAGE_URL}?countryId=${country}`
  );
};

const getFeaturedPackageBeforeLoginApi = country => {
  return apiClient.get(`${GET_FEATURED_PACKAGE_BEFORE_LOGIN_URL}${country}`);
};
const getFeaturedPackageAfterLoginApi = () => {
  return apiClient.get(GET_FEATURED_PACKAGE_AFTER_LOGIN_URL);
};
export {
  getFixedPackageAllApi,
  getFixedPackageByIdApi,
  getFixedPackageLandingPageApi,
  getFeaturedPackageBeforeLoginApi,
  getFeaturedPackageAfterLoginApi,
};
