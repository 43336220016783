import React from 'react';
import { Segment, Grid, Image, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const PanelThumbnailImage = props => {
  return (
    <Segment>
      <Grid column={3}>
        <Grid.Row width={16}>
          {props.image.map((img, i) => {
            return (
              <Grid.Column key={i} width={5} style={{ marginBottom: '5em' }}>
                <Checkbox
                  checked={img.IsPrimaryImage}
                  value={i}
                  name={i}
                  onChange={props.handleChange}
                  radio={true}
                />
                <Image
                  key={i}
                  size="medium"
                  src={img.ImageUrl ? img.ImageUrl : img.ImagePreview}
                />
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
PanelThumbnailImage.propTypes = {
  image: PropTypes.array,
  handleChange: PropTypes.func,
};
export default PanelThumbnailImage;
