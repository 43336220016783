import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Item } from 'semantic-ui-react';

const ItemWithIcon = props => {
  const {
    labelText,
    labelStyle,
    withIcon,
    iconStyle,
    descriptionText,
    descriptionStyle,
    iconName,
  } = props;
  return (
    <Item>
      <Item.Content>
        <strong style={labelStyle}>{labelText}</strong>
        <Item.Description>
          {withIcon && <Icon name={iconName} style={iconStyle} />}
          <span style={descriptionStyle}>{descriptionText}</span>
        </Item.Description>
      </Item.Content>
    </Item>
  );
};

ItemWithIcon.propTypes = {
  labelText: PropTypes.string,
  labelStyle: PropTypes.object,
  withIcon: PropTypes.bool,
  iconName: PropTypes.string,
  iconStyle: PropTypes.object,
  descriptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  descriptionStyle: PropTypes.object,
};
export default ItemWithIcon;
