//---library---
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Segment,
  Pagination,
  Header,
} from 'semantic-ui-react';
//---component----
import Input from '../../../../../components/input/WithLabel';
import { LoaderModal } from '../../common';
import Card from './Card';
import styles from './styles';
import MapCard from '../../map/MapCard';
//---utils---
import { ucwords } from '../../../../../genericFunction/convertWord';
import { convertDateFormat } from '../../../../../genericFunction/itineraryBuilder/timeHelper';
import { pagination } from '../../../services/helper/paginationHelper';
import { useTranslation } from 'react-i18next';

const ListCard = props => {
  const { t } = useTranslation();
  let {
    data,
    loading,
    searchName,
    handleChange,
    pageChange,
    activePage,
    rating,
    activeSort,
    locationName,
    handleOpenRooms,
    dataGetAccommodation,
    handleOpenMap,
  } = props;
  // let { activePage } = this.state;
  // let pagedData = pagination(data, 6);
  let pagedData = pagination(data, 6);
  let listData = pagedData[activePage - 1];
  let newlocationName = ucwords(locationName);
  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={styles.marginBottom15px}>
            <Segment style={styles.segmentSearch}>
              <Input
                name="searchName"
                type="input"
                icon="search"
                iconPosition="left"
                inputStyles={styles.inputWithPaddingNine}
                transparent={true}
                labelStyles={styles.textTransparent}
                value={searchName}
                handleChange={handleChange}
                placeholder={t('searchByAccommodationName')} //"Search by accommodation name"
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={9}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                type="input"
                icon="map marker alternate"
                iconPosition="left"
                inputStyles={styles.inputStyles}
                label={`${data.length} ${t('accommodationFound')}`} //Accommodation Found
                labelStyles={styles.boldGoldColor}
                value={newlocationName}
                handleChange={handleChange}
                disabled={true}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={7}>
            <Segment style={styles.segmentNoPadding}>
              <Input
                name="activeSort"
                type="select"
                inputStyles={styles.width100percent}
                label={`${t('sortBy')}:`} //"Sort by:"
                labelStyles={styles.fontBold}
                options={rating}
                value={activeSort}
                placeholder={t('sortAccommodation')} //"Sort accommodation"
                handleChange={handleChange}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={16} style={styles.marginBottom15px}>
            {dataGetAccommodation ? (
              <span>
                {t('accommodation')} <b>{t('from')}</b>{' '}
                {convertDateFormat(
                  dataGetAccommodation.requestedDate,
                  'YYYY-MM-DD'
                )}{' '}
                <b>{t('until')}</b>{' '}
                {convertDateFormat(
                  dataGetAccommodation.checkOutDate,
                  'YYYY-MM-DD'
                )}
              </span>
            ) : null}
          </Grid.Column>
          <Grid.Column width={16}>
            <MapCard
              labelText={`${t('viewAccommodationIn')}  ${newlocationName}`} //View accommodation in
              handleOpenMap={handleOpenMap}
              labelStyle={styles.labelViewMap}
            />
          </Grid.Column>
          <Grid.Column width={16} style={styles.minHeight50px}>
            <LoaderModal loading={loading} />
            {listData && !loading
              ? listData.map((data, index) => {
                  return (
                    <Card
                      key={index}
                      images={data.ProfileImages.sort(
                        (a, b) => +b.IsPrimaryImage - +a.IsPrimaryImage
                      )}
                      image={data.ImageUrl}
                      isPromo={data.IsPromo}
                      name={data.Name}
                      type={data.AccommodationType.Name}
                      address={data.Address}
                      id={data.Id}
                      facilities={data.ProfileFacilities}
                      rating={data.AccommodationRating.Id}
                      handleOpenRooms={handleOpenRooms}
                      data={data}
                    />
                  );
                })
              : !loading && (
                  <Header textAlign="center" as="h4">
                    {/* There is no result of accommodation for your destination */}
                    {t('noResultAccommodation')}
                  </Header>
                )}
          </Grid.Column>
          <Grid.Column textAlign="center" width={16}>
            {pagedData && !loading && pagedData.length > 1 && (
              <Pagination
                totalPages={pagedData.length}
                onPageChange={pageChange}
                activePage={activePage}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};
ListCard.propTypes = {
  data: PropTypes.array,
  locationName: PropTypes.string,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  searchName: PropTypes.string,
  pageChange: PropTypes.func,
  activePage: PropTypes.number,
  rating: PropTypes.array,
  activeSort: PropTypes.string,
  handleOpenRooms: PropTypes.func,
  dataGetAccommodation: PropTypes.object,
  handleOpenMap: PropTypes.func,
  t: PropTypes.func,
};
export default ListCard;
