import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Icon, Button, Grid } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';
import {
  shortFormatDate,
  getYear,
} from '../../../../../genericFunction/moment';
import { useTranslation } from 'react-i18next';

const TimeLineDay = props => {
  const { t } = useTranslation();
  const { day, date, handleCollapse, isCollapse } = props;
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      date={`${t('day')} ${day}`} //Day
      iconStyle={styles.timeLineIcon}
      position="right"
      style={styles.noMarginBot}
    >
      <Grid style={styles.marginTopMinFive}>
        <Grid.Column width={13}>
          <h3 className="vertical-timeline-element-title">
            {`${shortFormatDate(date)} ${getYear(date)}`}
          </h3>
        </Grid.Column>

        <Grid.Column width={3} textAlign="right">
          <Button
            basic
            icon
            name="day1"
            onClick={handleCollapse}
            style={styles.boxTransparent}
          >
            <Icon name={isCollapse ? 'angle up' : 'angle down'} />
          </Button>
        </Grid.Column>
      </Grid>
    </VerticalTimelineElement>
  );
};
TimeLineDay.propTypes = {
  date: PropTypes.string,
  isCollapse: PropTypes.bool,
  handleCollapse: PropTypes.func,
  day: PropTypes.number,
  isSelectDate: PropTypes.string,
  isVariableDate: PropTypes.bool,
  t: PropTypes.func,
};
export default TimeLineDay;
