import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';
import styles from './styles';
import { convertDateFormat } from '../../../../../genericFunction/moment';

const TimeLineDaySeparator = props => {
  const { date } = props;
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      iconStyle={styles.timeLineSeparator}
      position="right"
      icon={<h4>{convertDateFormat(date, 'DD MMM YYYY')}</h4>}
      style={styles.noMargin}
    >
      <div style={styles.separatorStyle} />
    </VerticalTimelineElement>
  );
};
TimeLineDaySeparator.propTypes = {
  date: PropTypes.string,
};
export default TimeLineDaySeparator;
