import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Segment,
  Header,
  Form,
  Grid,
  Table,
  Input,
  Message,
  Icon,
} from 'semantic-ui-react';
import InputWithLabel from '../../../../../components/input/WithLabel';
import HeaderWithIcon from '../../../../../components/common/headerWithIcon';
import styles from './styles';
import { Collapse } from 'react-collapse';
import CollapseButton from '../../../../../components/button/BtnCollapse';
import options from '../../../../../genericFunction/selectOptionsConverter';
import { connect } from 'react-redux';
import {
  setGuestsAmount,
  setTourName,
  setRoomAllocation,
  setFirstDestination,
  setHeadlineProgram,
  setAccommodationOnChangeIndex,
} from '../../../actions/customPackageAction';
import { getPlaceByRegion } from '../../../actions/locationAction';
import {
  changeDestinationSummaryProgram,
  setPlaceInArrivalDepartureByHeadLine,
} from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import ModalPaxArrangement from './ModalPaxArrangement';
import {
  isGroupCapacityValid,
  validationGeneralInform,
  validationCapacityPackage,
} from '../../../../../genericFunction/validation';
import { sumValuesObject } from '../../../../../genericFunction/itineraryBuilder/guest';
import groupCapacity from '../../../constant/groupCapacity';
import Loader from '../../../../../components/common/loader';
import InputDate from '../../../../../components/input/DateInput';
import { withTranslation } from 'react-i18next';
class GeneralInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
      firstDestination: '',
      open: false,
    };
  }
  handleCollapse = () => {
    let isCollapse = this.state.isCollapse;
    isCollapse = !isCollapse;
    this.setState({ ...this.state, isCollapse: isCollapse });
  };
  handleChangeGuestAmount = (e, { name, value, objectname }) => {
    const obj =
      objectname === 'guests'
        ? { ...this.props.guestsAmount }
        : { ...this.props.roomAllocation };
    if (value === '') {
      obj[name] = 0;
    } else {
      obj[name] = value;
    }
    objectname === 'guests'
      ? this.props.setGuestsAmount(obj)
      : this.props.setRoomAllocation(obj);
  };
  handleIncrease = (e, { name, value, objectname }) => {
    const obj =
      objectname === 'guests'
        ? { ...this.props.guestsAmount }
        : { ...this.props.roomAllocation };
    obj[name] = value + 1;
    objectname === 'guests'
      ? this.props.setGuestsAmount(obj)
      : this.props.setRoomAllocation(obj);
  };
  handleDecrease = (e, { name, value, objectname }) => {
    const obj =
      objectname === 'guests'
        ? { ...this.props.guestsAmount }
        : { ...this.props.roomAllocation };
    const newValue = value - 1 < 0 ? 0 : value - 1;
    obj[name] = newValue;
    objectname === 'guests'
      ? this.props.setGuestsAmount(obj)
      : this.props.setRoomAllocation(obj);
  };
  resetFocValue = async () => {
    let obj = { ...this.props.foc };
    obj.Qty = 0;
    let newErrors = validationCapacityPackage(
      this.props.generalInfo,
      this.props.errorGeneralInformation,
      this.props.guests
    );
    if (newErrors.isError) {
      this.props.setErrorGeneralInformation(newErrors.data);
    } else {
      await this.props.setErrorGeneralInformation(newErrors.data);
    }
    this.props.addFocAmount(obj);
  };
  handleOnchange = async (e, { name, value }) => {
    await this.props.handleGeneralInfo(name, value);
    name === 'GroupCapacity' && this.resetFocValue();
    name === 'FirstDestination' && this.setFirstDestination(value);
  };
  setFirstDestination = value => {
    let city = this.props.cityInCountry.find(item => item.Id === value);
    let headLineProg = { ...this.props.HeadlineProgram };
    headLineProg.MainPrograms = changeDestinationSummaryProgram(
      headLineProg.MainPrograms,
      0,
      city
    );
    this.props.setFirstDestination(city);
    let context = this;
    this.props
      .getPlaceByRegion(city.Region.Id)
      .then(res => {
        if (res.value.data.length > 0) {
          context.setPlace(headLineProg);
        } else {
          context.props.setHeadlineProgram(headLineProg);
        }
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
      });
    this.props.setAccommodationOnChangeIndex(0);
  };
  setPlace = headline => {
    const { places, cityInCountry } = this.props;
    let newHeadline = setPlaceInArrivalDepartureByHeadLine(
      headline,
      places,
      cityInCountry
    );
    this.props.setHeadlineProgram(newHeadline);
  };

  openModal = () => {
    let { generalInfo, firstDestination, errorGeneralInformation } = this.props;
    let newErrors = validationGeneralInform(
      generalInfo,
      firstDestination,
      errorGeneralInformation
    );
    if (newErrors.isError) {
      this.props.setErrorGeneralInformation(newErrors.data);
    } else {
      this.setState({ ...this.state, open: true }, () =>
        this.props.setErrorGeneralInformation(newErrors.data)
      );
    }
  };
  close = () => {
    this.setState({ ...this.state, open: false }, () => this.validation());
  };
  // ------------ validation -------------------
  validation = () => {
    let { guestsAmount, generalInfo, errorGeneralInformation } = this.props;
    let newErrors = isGroupCapacityValid(
      errorGeneralInformation,
      guestsAmount,
      generalInfo.GroupCapacity
    );
    this.props.setErrorGeneralInformation(newErrors);
  };
  // ------------ validation -------------------
  addFoc = async val => {
    this.props.addFocAmount(val);
  };

  //ready fix price
  handleChangeDate(date) {
    const { handleChangeSelectedDate } = this.props;
    handleChangeSelectedDate(date);
    // this.contex.props.handleChangeSelectedDate(date);
    // console.log(date);
  }
  //end
  render() {
    const { t } = this.props;
    const {
      guestsAmount,
      roomAllocation,
      cityList,
      listTourType,
      listTourPaxType,
      firstDestination,
      generalInfo,
      errorGeneralInformation,
      foc,
      isVariableDate,
      selectedDate,
      handleChangeSelectedDate,
      availableDay,
      roomPrices,
    } = this.props;
    const { isCollapse, open } = this.state;
    let title = generalInfo.TourName;
    let capacity = generalInfo.GroupCapacity;
    let type = generalInfo.TourType;
    let category = generalInfo.TourCategory;
    const destination = firstDestination ? firstDestination.Id : null;
    const totalAllocation = sumValuesObject(roomAllocation);
    return (
      <Segment style={styles.segment}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <HeaderWithIcon
                as="h2"
                title={t('generalInformation')} //"General Information"
                icon="clipboard list"
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              <CollapseButton
                handleCollapse={this.handleCollapse}
                style={styles.shadowTransparant}
                isCollapse={isCollapse}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Collapse isOpened={isCollapse}>
          <div style={styles.paddingSideTwenty}>
            {/* Tour Information */}
            <Header as="h3">{t('tourInformation')}</Header>
            {/* <Header as="h3">Tour Information</Header> */}
            {isVariableDate && (
              <React.Fragment>
                {generalInfo.MinPax ? (
                  <Message color="yellow">
                    {/* Minimum pax for this package */}
                    <Icon name="warning circle" color="yellow" />
                    {t('warningMinimumPax')}:
                    <strong>
                      {' '}
                      {generalInfo.MinPax} {t('pax')}
                    </strong>
                  </Message>
                ) : null}
              </React.Fragment>
            )}
            <Form>
              <Form.Group widths="equal">
                <InputWithLabel
                  label={t('tourName')} //"Tour Name"
                  type="text"
                  name="TourName"
                  value={title}
                  handleChange={this.handleOnchange}
                  labelStyles={styles.displayBlockBold}
                  required
                  fluid={true}
                  validate={errorGeneralInformation}
                />
                <InputWithLabel
                  label={t('firstArrivalDestination')} //"First Arrival Destination"
                  inline={false}
                  type="select"
                  name="FirstDestination"
                  value={destination}
                  handleChange={this.handleOnchange}
                  placeholder={t('placeholderSelectCity')} //"Select City"
                  labelStyles={styles.fontBold}
                  options={cityList}
                  required
                  fluid={true}
                  validate={errorGeneralInformation}
                  disabled={isVariableDate}
                />
              </Form.Group>
              <Form.Group>
                {isVariableDate && (
                  <Form.Field
                    width={4}
                    inline={true}
                    required={true}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    <InputDate
                      label={t('firstArrivalDate')} //"First Arrival Date"
                      value={selectedDate}
                      // value={
                      //   availableDay.length > 0 ? availableDay[0] : new Date()
                      // }
                      // labelStyle={{
                      //   display: 'block',
                      //   fontWeight: 'bold',
                      //   marginBottom: '4px',
                      // }}
                      labelStyle={{
                        ...styles.fontBold,
                        ...styles.paddingBottom3,
                      }}
                      // range={true}
                      setDate={handleChangeSelectedDate}
                      setOtherRange={true}
                      // expiredOn={this.props.expiredDate}
                      // date={new Date()}
                      date={new Date()}
                      includeDates={availableDay}
                      customInput={<Input fluid iconPosition="left" />}
                    />
                  </Form.Field>
                )}
                <Form.Field
                  width={isVariableDate ? 4 : 8}
                  style={{ alignSelf: 'flex-end' }}
                >
                  <InputWithLabel
                    label={t('groupCapacity')} //"Group Capacity"
                    inline={false}
                    type="select"
                    name="GroupCapacity"
                    value={capacity}
                    handleChange={this.handleOnchange}
                    placeholder={t('placeholderGroupCity')} //"Amount of Pax"
                    labelStyles={styles.fontBold}
                    options={groupCapacity}
                    required
                    fluid={true}
                    validate={errorGeneralInformation}
                  />
                </Form.Field>
                <Form.Field width={4} style={{ alignSelf: 'flex-end' }}>
                  <InputWithLabel
                    label={t('tourType')} //"Tour Type"
                    inline={false}
                    type="select"
                    name="TourType"
                    value={type}
                    placeholder={t('tourType')} //"Tour Type"
                    handleChange={this.handleOnchange}
                    labelStyles={styles.fontBold}
                    options={listTourType}
                    required
                    fluid={true}
                    validate={errorGeneralInformation}
                  />
                </Form.Field>
                <Form.Field width={4} style={{ alignSelf: 'flex-end' }}>
                  <InputWithLabel
                    label={t('tourCategory')} //"Tour Category"
                    inline={false}
                    type="select"
                    name="TourCategory"
                    value={category}
                    handleChange={this.handleOnchange}
                    placeholder={t('tourPaxType')} //"Tour Pax Type"
                    labelStyles={styles.fontBold}
                    options={listTourPaxType}
                    required
                    fluid={true}
                    validate={errorGeneralInformation}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            {totalAllocation > 0 ? (
              <div>
                {/* Pax Arrangement */}
                <Header as="h3">{t('paxArrangement')}</Header>
                <Table basic="very">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={8}>
                        {t('guests')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={8}>
                        {t('roomAllocation')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {isVariableDate ? (
                      <>
                        <Table.Row verticalAlign="top">
                          <Table.Cell>
                            {guestsAmount.adults > 0 && (
                              <div>
                                <strong>{guestsAmount.adults}</strong>{' '}
                                {t('adult')}
                                {foc && foc.Qty ? (
                                  <span>
                                    {' '}
                                    ({t('including')} {foc.Qty} FOC)
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                            {guestsAmount.childs > 0 && (
                              <div>
                                <strong>{guestsAmount.childs}</strong>{' '}
                                {t('children')}
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {roomAllocation.SingleRoomQty > 0 && (
                              <div>
                                <strong>{roomAllocation.SingleRoomQty}</strong>{' '}
                                {t('in')} {/* Single Room (Adult) */}
                                {t('singleRoom')}
                                <br />
                              </div>
                            )}
                            {roomAllocation.SharingRoomQty > 0 && (
                              <div>
                                <strong>{roomAllocation.SharingRoomQty}</strong>{' '}
                                {t('in')} {/* Sharing Room (Adult) */}
                                {t('sharingRoom')}
                                <br />
                              </div>
                            )}
                            {roomAllocation.ExtraBedQty > 0 && (
                              <div>
                                <strong>{roomAllocation.ExtraBedQty}</strong>{' '}
                                {t('in')} {/* Extra Bed (Adult) */}
                                {t('extraBed')}
                              </div>
                            )}
                            {roomAllocation.ChildExtraBedQty > 0 && (
                              <div>
                                <strong>
                                  {roomAllocation.ChildExtraBedQty}
                                </strong>{' '}
                                {t('in')} {t('extraBed')}
                                {/* Extra Bed (Child) */}
                                <br />
                              </div>
                            )}
                            {roomAllocation.SharingBedQty > 0 && (
                              <div>
                                <strong>{roomAllocation.SharingBedQty}</strong>{' '}
                                {t('sharingWithParents')}{' '}
                              </div>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </>
                    ) : (
                      <>
                        <Table.Row verticalAlign="top">
                          <Table.Cell>
                            {guestsAmount.adults > 0 && (
                              <div>
                                <strong>{guestsAmount.adults}</strong>{' '}
                                {t('adult')}
                                {foc && foc.Qty ? (
                                  <span>
                                    {' '}
                                    ({t('including')} {foc.Qty} FOC)
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {roomAllocation.SingleRoomQty > 0 && (
                              <div>
                                <strong>{roomAllocation.SingleRoomQty}</strong>{' '}
                                {t('in')} {/* Single Room (Adult) */}
                                {t('singleRoom')}({t('adult')})
                                <br />
                              </div>
                            )}
                            {roomAllocation.SharingRoomQty > 0 && (
                              <div>
                                <strong>{roomAllocation.SharingRoomQty}</strong>{' '}
                                {t('in')} {/* Sharing Room (Adult) */}
                                {t('sharingRoom')}({t('adult')})
                                <br />
                              </div>
                            )}
                            {roomAllocation.ExtraBedQty > 0 && (
                              <div>
                                <strong>{roomAllocation.ExtraBedQty}</strong>{' '}
                                {t('in')} {/* Extra Bed (Adult) */}
                                {t('extraBed')} ({t('adult')})
                              </div>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row verticalAlign="top">
                          <Table.Cell>
                            {guestsAmount.childs > 0 && (
                              <div>
                                <strong>{guestsAmount.childs}</strong>{' '}
                                {t('children')}
                              </div>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {roomAllocation.ChildSingleRoomQty > 0 && (
                              <div>
                                <strong>
                                  {roomAllocation.ChildSingleRoomQty}
                                </strong>{' '}
                                {t('in')} {t('singleRoom')} ({t('child')})
                                {/* Single Room (Child) */}
                                <br />
                              </div>
                            )}
                            {roomAllocation.ChildSharingRoomQty > 0 && (
                              <div>
                                <strong>
                                  {roomAllocation.ChildSharingRoomQty}
                                </strong>{' '}
                                {t('in')} {t('sharingRoom')} ({t('child')})
                                {/* Sharing Room (Child) */}
                                <br />
                              </div>
                            )}
                            {roomAllocation.ChildExtraBedQty > 0 && (
                              <div>
                                <strong>
                                  {roomAllocation.ChildExtraBedQty}
                                </strong>{' '}
                                {t('in')} {t('extraBed')}({t('child')})
                                {/* Extra Bed (Child) */}
                                <br />
                              </div>
                            )}
                            {roomAllocation.SharingBedQty > 0 && (
                              <div>
                                <strong>{roomAllocation.SharingBedQty}</strong>{' '}
                                {t('sharingWithParents')} ({t('child')})
                                {/* Sharing with Parent (Child) */}
                              </div>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </>
                    )}
                    <Table.Row verticalAlign="top">
                      <Table.Cell>
                        {guestsAmount.infants > 0 && (
                          <div>
                            <strong>{guestsAmount.infants}</strong>{' '}
                            {t('infant')}
                          </div>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {roomAllocation.BabyCrib > 0 && (
                          <div>
                            <strong>{roomAllocation.BabyCrib}</strong>
                            {t('babyCrib')}({t('infant')})
                            <br />
                          </div>
                        )}
                        {roomAllocation.NoBed > 0 && (
                          <div>
                            <strong>{roomAllocation.NoBed}</strong>
                            {t('noBed')} ({t('infant')})
                            {/* No Bed
                            (Infant) */}
                          </div>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>{' '}
              </div>
            ) : null}
          </div>
          <ModalPaxArrangement
            open={open}
            closeModal={this.close}
            openModal={this.openModal}
            capacity={capacity}
            foc={foc}
            addFoc={this.addFoc}
            roomPrices={roomPrices}
            isVariableDate={isVariableDate}
            minPax={generalInfo.MinPax}
          />
        </Collapse>
        <Loader active={this.props.loading} inverted={true} />
      </Segment>
    );
  }
}
const mapStateToProps = state => {
  return {
    cityInCountry: state.location.cityInCountry,
    cityList: state.location.cityInCountry.map((item, index) => {
      let result = options(`${item.Id} ${index}`, item.Id, item.Name);
      result.content = (
        <p>
          <strong>{item.Name}</strong>
          <br />
          {item.Region.Name}, {item.Country.Name}
        </p>
      );
      return result;
    }),
    listTourType: state.tourTransaction.tourTypes.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    listTourPaxType: state.tourTransaction.tourPaxTypes.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    tourName: state.customPackage.tourName,
    guestsAmount: state.customPackage.guests,
    roomAllocation: state.customPackage.roomAllocation,
    HeadlineProgram: state.customPackage.HeadlineProgram,
    firstDestination: state.customPackage.firstDestination,
    places: state.location.places,
    loading: state.customPackage.loading,
  };
};
GeneralInformation.propTypes = {
  cityInCountry: PropTypes.array,
  cityList: PropTypes.array,
  listTourType: PropTypes.array,
  listTourPaxType: PropTypes.array,
  setGuestsAmount: PropTypes.func,
  setTourName: PropTypes.func,
  setRoomAllocation: PropTypes.func,
  tourName: PropTypes.string,
  guestsAmount: PropTypes.object,
  roomAllocation: PropTypes.object,
  setFirstDestination: PropTypes.func,
  HeadlineProgram: PropTypes.object,
  setHeadlineProgram: PropTypes.func,
  setAccommodationOnChangeIndex: PropTypes.func,
  getPlaceByRegion: PropTypes.func,
  firstDestination: PropTypes.object,
  handleGeneralInfo: PropTypes.func,
  places: PropTypes.array,
  generalInfo: PropTypes.object,
  errorGeneralInformation: PropTypes.object,
  setErrorGeneralInformation: PropTypes.func,
  foc: PropTypes.object,
  addFocAmount: PropTypes.func,
  guests: PropTypes.object,
  loading: PropTypes.bool,
  isVariableDate: PropTypes.bool,
  selectedDate: PropTypes.string,
  handleChangeSelectedDate: PropTypes.func,
  expiredDate: PropTypes.string,
  availableDay: PropTypes.array,
  roomPrices: PropTypes.object,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    setGuestsAmount,
    setTourName,
    setRoomAllocation,
    setFirstDestination,
    setHeadlineProgram,
    setAccommodationOnChangeIndex,
    getPlaceByRegion,
  }
)(withTranslation()(GeneralInformation));
