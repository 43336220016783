import { GOLD_COLOR } from '../../../../constant/colour';

const style = {
  goldColor: { color: GOLD_COLOR },
  buttonTransparent: {
    background: '#fff',
    color: GOLD_COLOR,
    padding: '0px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segmentLoader: {
    border: 'none',
    background: 'transparent',
    minHeight: '50px',
  },
  marginTopBottom15: { marginBottom: '15px', marginTop: '15px' },
  paddingTopBottom5: { paddingTop: '5px', paddingBottom: '5px' },
};
export default style;
