import {
  POST_LOGIN,
  RESET_LOGIN_STATUS,
  RESET_LOGOUT_STATUS,
  LOGOUT,
  SET_EXPIRED_TOKEN,
  CHANGE_LOGIN_FLAG,
} from './actionTypes';
import { postLoginApi } from '../../../api/authenticationApi';

const postLogin = data => {
  return dispatch => {
    return dispatch({
      type: POST_LOGIN,
      payload: postLoginApi(data),
    });
  };
};
const resetLoginStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_LOGIN_STATUS });
  };
};
const logout = () => {
  return dispatch => {
    dispatch({ type: LOGOUT });
    localStorage.clear();
    // dispatch({ type: "logout_successful" })
  };
};
const resetLogoutStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_LOGOUT_STATUS });
  };
};
const setExpiredToken = expiredToken => {
  return dispatch => {
    dispatch({ type: SET_EXPIRED_TOKEN, payload: expiredToken });
  };
};
const changeLoginFlag = loginFlag => {
  return dispatch => {
    dispatch({ type: CHANGE_LOGIN_FLAG, payload: loginFlag });
  };
};
export {
  postLogin,
  resetLoginStatus,
  logout,
  resetLogoutStatus,
  setExpiredToken,
  changeLoginFlag,
};
