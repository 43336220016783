// import React, { Component } from 'react';
// import { Grid, Button } from 'semantic-ui-react';
// import Loader from '../../../components/common/loader';
// import PropTypes from 'prop-types';
// import styles from '../styles';
// import { connect } from 'react-redux';
// import {
//   postCreateTour,
//   postJoinTour, //fixed
//   postCreateTourOnBehalf,
//   postJoinTourOnBehalf, //fixed
//   resetStatusTransaction,
//   postJoinTourVariable,
// } from '../../shared/actions/tourTransactionAction';
// import {
//   resetTempDemoJoinTour,
//   setTempBackCustomReady,
// } from '../../shared/actions/tempAction';
// import {
//   getTotalDays,
//   convertDateFormat,
// } from '../../../genericFunction/moment';
// import { ModalConfirm } from './../../shared/components/modal';
// import { ModalAlert } from './../../shared/components/modal';
// import { Redirect } from 'react-router-dom';
// import {
//   convertDataBookNowFixed,
//   getGuests,
// } from '../../../genericFunction/convertPackage';
// class AllButtonSummary extends Component {
//   state = {
//     modalConfirmBook: false,
//     modalConfirmQuotation: false,
//     modalConfirmMessage: '',
//     modalAlertSuccess: false,
//     modalAlertFailed: false,
//     modalConfirmVerificationBook: false,
//     verification: false,
//     isQuotation: false,
//     modalAlertMessage: '',
//     modalConfirmCancel: false,
//     gotoMyBooking: false,
//     gotoMyQuotation: false,
//     gotoPrev: false,
//     gotoHome: false,
//     data: {},
//     companyProfile: {},
//     idBook: 0,
//     // isReadyPackage: false,
//   };
//   componentDidUpdate = () => {
//     if (
//       this.props.postCreateTourStatus === 'success' ||
//       this.props.postJoinTourStatus === 'success' ||
//       this.props.postCreateTourOnBeHalfStatus === 'success' ||
//       this.props.postJoinTourOnBeHalfStatus === 'success'
//     ) {
//       let {
//         joinTour, //fixed
//         createTour,
//         packageType,
//         createTourOnBeHalf,
//         joinTourOnBeHalf,
//         isSales,
//       } = this.props;
//       // let dataJoinTour = isSales ? joinTourOnBeHalf : joinTour;
//       let dataJoinTour = isSales
//         ? packageType !== 'ready-package-variable'
//           ? joinTourOnBeHalf
//           : joinTour
//         : joinTour;
//       let dataCreateTour = isSales ? createTourOnBeHalf : createTour;
//       let idBook =
//         packageType === 'fixed' || packageType === 'ready-package-variable'
//           ? dataJoinTour.BookingDetailSum.Id
//           : packageType !== 'fixed' && dataCreateTour.BookingDetailSum.Id;
//       let status =
//         packageType === 'fixed' || packageType === 'ready-package-variable'
//           ? dataJoinTour.BookingDetailSum.Status
//           : packageType !== 'fixed' && dataCreateTour.BookingDetailSum.Status;
//       let message =
//         packageType !== 'fixed' || packageType !== 'ready-package-variable'
//           ? this.state.isQuotation
//             ? 'Quotation number ' + idBook + ' successfully created'
//             : status === 'Booking_created'
//             ? 'Booking number ' + idBook + ' successfully created'
//             : 'Your Booking is on hold'
//           : 'Booking number ' + idBook + ' successfully created';
//       this.setState({
//         ...this.state,
//         modalAlertSuccess: true,
//         modalAlertMessage: message,
//         idBook: idBook,
//       });
//       this.props.resetTempDemoJoinTour();
//       this.props.resetStatusTransaction();
//     } else if (
//       this.props.postCreateTourStatus === 'failed' ||
//       this.props.postJoinTourStatus === 'failed' ||
//       this.props.postCreateTourOnBeHalfStatus === 'failed' ||
//       this.props.postJoinTourOnBeHalfStatus === 'failed'
//     ) {
//       this.setState({
//         ...this.state,
//         modalAlertFailed: true,
//         modalAlertMessage: this.props.error.Message,
//       });
//       this.props.resetStatusTransaction();
//     }
//   };

//   filterGuest = tourGuestSum => {
//     return tourGuestSum.filter(
//       guest =>
//         guest.CountryId &&
//         guest.FirstName &&
//         guest.IdentityNbr &&
//         guest.IdentityType &&
//         guest.LastName &&
//         guest.GuestTitle
//     );
//   };
//   handleBookNow = name => {
//     this.setState({
//       ...this.state,
//       isQuotation: name === 'quotation',
//       modalConfirmBook: false,
//       modalConfirmQuotation: false,
//       modalConfirmVerificationBook: false,
//     });
//     let { selectedUser } = this.props;

//     if (this.props.packageType !== 'fixed') {
//       let dataDemoPrice = { ...this.props.dataDemoPrice };
//       dataDemoPrice.TourNote = this.props.tourNote;
//       let { companyProfile } = this.state;
//       let { BookingDetailSum } = this.state.data;
//       dataDemoPrice.CompanyCode = companyProfile.Code;
//       dataDemoPrice.IsQuotation = name === 'quotation';
//       dataDemoPrice.PackageType =
//         BookingDetailSum.PackageType === 'Fixed' ? 2 : 0;
//       dataDemoPrice.TourName = BookingDetailSum.TourOperatorProfile.Name;
//       dataDemoPrice.Guests = getGuests(this.props.listGuest);
//       if (this.props.isSales) {
//         dataDemoPrice.CompanyCode = this.props.selectedCompany.code;
//         selectedUser.code && (dataDemoPrice.UserProfileId = selectedUser.code);
//         this.props.postCreateTourOnBehalf(dataDemoPrice);
//       } else {
//         this.props.postCreateTour(dataDemoPrice);
//       }
//     } else if (this.props.packageType === 'fixed') {
//       let {
//         splitCommision,
//         printCommision,
//         guests,
//         roomAllocation,
//         supplementObject,
//         detailGuest,
//       } = this.props.dataDemoJoinTour;
//       let dataDemoJoinTour = convertDataBookNowFixed(
//         splitCommision,
//         printCommision,
//         guests,
//         roomAllocation,
//         supplementObject,
//         detailGuest,
//         this.props.tourNote,
//         this.props.idTour
//       );
//       if (this.props.isSales) {
//         dataDemoJoinTour.CompanyCode = this.props.selectedCompany.code;
//         selectedUser.code &&
//           (dataDemoJoinTour.UserProfileId = selectedUser.code);
//         this.props.postJoinTourOnBehalf(this.props.idTour, dataDemoJoinTour);
//       } else {
//         this.props.postJoinTour(this.props.idTour, dataDemoJoinTour);
//       }
//     } else if (this.props.packageType === 'ready-package-variable') {
//       // let {
//       //   splitCommision,
//       //   printCommision,
//       //   guests,
//       //   roomAllocation,
//       //   supplementObject,
//       //   detailGuest,
//       // } = this.props.dataDemoJoinTour;
//       let dataDemoJoinTour = convertDataBookNowFixed(
//         this.props.dataDemoJoinTour.splitCommision,
//         this.props.dataDemoJoinTour.printCommision,
//         this.props.dataDemoJoinTour.guests,
//         this.props.dataDemoJoinTour.roomAllocation,
//         this.props.dataDemoJoinTour.supplementObject,
//         this.props.dataDemoJoinTour.detailGuest
//       );
//       dataDemoJoinTour.StartTour = this.props.dataDemoJoinTour.startTour;
//       dataDemoJoinTour.EndTour = this.props.dataDemoJoinTour.endTour;
//       dataDemoJoinTour.TourOperatorProfileId = this.props.data.BookingDetailSum.TourOperatorProfileId;
//       dataDemoJoinTour.Title = this.props.dataDemoJoinTour.title;
//       if (this.props.isSales) {
//         dataDemoJoinTour.CompanyCode = this.props.selectedCompany.code;
//         selectedUser.code &&
//           (dataDemoJoinTour.UserProfileId = selectedUser.code);
//         // this.props.postJoinTourOnBehalf(this.props.idTour, dataDemoJoinTour);
//         this.props.postJoinTourVariable(this.props.idTour, dataDemoJoinTour);
//       } else {
//         this.props.postJoinTourVariable(this.props.idTour, dataDemoJoinTour);
//       }
//     } else if (this.props.packageType !== 'fixed') {
//       let dataDemoPrice = this.props.dataDemoPrice;
//       let { companyProfile } = this.state;
//       let { BookingDetailSum } = this.state.data;
//       dataDemoPrice.CompanyCode = companyProfile.Code;
//       dataDemoPrice.IsQuotation = name === 'quotation';
//       dataDemoPrice.PackageType =
//         BookingDetailSum.PackageType === 'Fixed' ? 2 : 0;
//       dataDemoPrice.TourName = BookingDetailSum.TourOperatorProfile.Name;
//       dataDemoPrice.Guests = getGuests(this.props.listGuest);
//       if (this.props.isSales) {
//         dataDemoPrice.CompanyCode = this.props.selectedCompany.code;
//         selectedUser.code && (dataDemoPrice.UserProfileId = selectedUser.code);
//         this.props.postCreateTourOnBehalf(dataDemoPrice);
//       } else {
//         this.props.postCreateTour(dataDemoPrice);
//       }
//     }
//   };
//   handleButton = (e, { name, value, desc }) => {
//     if (
//       (name === 'modalConfirmBook' || name === 'modalConfirmQuotation') &&
//       this.props.isSales &&
//       !this.props.selectedCompany.code
//     ) {
//       // this.props.selectedCompany.code ||
//       this.setState({
//         ...this.state,
//         modalAlertFailed: true,
//         modalAlertMessage: 'Please choose customer on behalf first',
//       });
//     } else {
//       let message =
//         name === 'modalConfirmBook'
//           ? 'Are you sure want to book this tour?'
//           : name === 'modalConfirmQuotation'
//           ? 'Are you sure want create quotation this tour?'
//           : name === 'modalConfirmCancel' &&
//             'Are you sure want cancel this transaction?';
//       this.setState({
//         ...this.state,
//         [name]: !this.state[name],
//         modalConfirmMessage: message,
//         data: value,
//         companyProfile: desc,
//       });
//     }
//   };
//   handleCloseBookNow = () =>
//     this.setState({
//       ...this.state,
//       modalConfirmBook: false,
//       modalConfirmQuotation: false,
//       modalConfirmVerificationBook: false,
//       modalConfirmCancel: false,
//     });
//   handleCloseAlert = () => {
//     // modalAlertSuccess ? modalAlertSuccess : modalAlertFailed;
//     let { isQuotation, modalAlertSuccess, modalAlertFailed } = this.state;
//     let gotoMyBooking = modalAlertFailed
//       ? false
//       : modalAlertSuccess && !isQuotation
//       ? true
//       : false;
//     let gotoMyQuotation = modalAlertFailed ? false : isQuotation;
//     return this.setState({
//       ...this.state,
//       modalAlertSuccess: false,
//       modalAlertFailed: false,
//       gotoMyBooking,
//       gotoMyQuotation,
//     });
//   };
//   handleConfirmBookNow = (e, { name }) => {
//     getTotalDays(
//       this.state.data.BookingDetailSum.ExpiredOn,
//       convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
//       false
//     ) < 2
//       ? name !== 'quotation'
//         ? this.setState({
//             ...this.state,
//             modalConfirmBook: false,
//             modalConfirmQuotation: false,
//             modalConfirmVerificationBook: true,
//             verification: true,
//           })
//         : this.handleBookNow(name)
//       : this.handleBookNow(name);
//   };
//   handleConfirmVerificationBook = (e, { name }) => this.handleBookNow(name);
//   handleBack = async (e, { name }) => {
//     this.props.packageType !== 'fixed' &&
//       (await this.props.setTempBackCustomReady(true));
//     this.setState({ [name]: true });
//   };
//   render() {
//     let { loading, packageType } = this.props;
//     let {
//       modalConfirmBook,
//       modalConfirmQuotation,
//       modalConfirmMessage,
//       modalConfirmVerificationBook,
//       verification,
//       modalAlertSuccess,
//       modalAlertFailed,
//       modalAlertMessage,
//       modalConfirmCancel,
//       gotoMyBooking,
//       gotoMyQuotation,
//       gotoPrev,
//       gotoHome,
//       idBook,
//     } = this.state;
//     if (gotoMyBooking) {
//       if (verification && packageType === 'fixed') {
//         return <Redirect to={'/partial-payment/' + idBook} />;
//       } else {
//         return <Redirect to={'/my-profile/booking'} />;
//       }
//     } else if (gotoMyQuotation) {
//       return <Redirect to={'/my-profile/quotation'} />;
//     } else if (gotoPrev) {
//       if (packageType === 'fixed') {
//         return <Redirect to={'/series-package/' + this.props.idTour} />;
//       } else if (packageType === 'ready') {
//         return <Redirect to={'/ready/ready-package/' + this.props.idTour} />;
//       } else if (packageType === 'ready-package-variable') {
//         return (
//           <Redirect to={'/ready/ready-package-variable/' + this.props.idTour} />
//         );
//       } else if (packageType === 'custom') {
//         return <Redirect to={'/custom-package/'} />;
//       }
//     } else if (gotoHome) {
//       return <Redirect to={'/'} />;
//     }
//     return (
//       <Grid.Row style={styles.marginBottom20px}>
//         <Grid.Column width={16} textAlign="center">
//           <Button
//             style={styles.buttonGold}
//             size="huge"
//             onClick={this.handleBack}
//             name="gotoPrev"
//           >
//             Back
//           </Button>
//           <Button
//             style={styles.buttonRed}
//             size="huge"
//             name="modalConfirmCancel"
//             onClick={this.handleButton}
//           >
//             Cancel
//           </Button>
//           {packageType !== 'fixed' && packageType !== 'ready-package-variable' && (
//             <Button
//               style={styles.buttonBlue}
//               onClick={this.handleButton}
//               name="modalConfirmQuotation"
//               value={this.props.data}
//               desc={this.props.companyProfile}
//               size="huge"
//             >
//               Create Quotation
//             </Button>
//           )}
//           <Button
//             style={styles.buttonGold}
//             onClick={this.handleButton}
//             name="modalConfirmBook"
//             value={this.props.data}
//             desc={this.props.companyProfile}
//             size="huge"
//           >
//             Book Now
//           </Button>
//         </Grid.Column>
//         <ModalConfirm
//           openModal={
//             modalConfirmBook ? modalConfirmBook : modalConfirmQuotation
//           }
//           size="tiny"
//           message={modalConfirmMessage}
//           close={this.handleCloseBookNow}
//           confirm={this.handleConfirmBookNow}
//           nameConfirm={modalConfirmBook ? 'book' : 'quotation'}
//           packageType={packageType}
//         />
//         <ModalConfirm
//           openModal={modalConfirmVerificationBook}
//           size="tiny"
//           message="This booking is expiring soon. Do you want to book and pay now?"
//           close={this.handleCloseBookNow}
//           confirm={this.handleConfirmVerificationBook}
//           nameConfirm="book"
//         />
//         <ModalConfirm
//           openModal={modalConfirmCancel}
//           size="tiny"
//           message={modalConfirmMessage}
//           close={this.handleCloseBookNow}
//           confirm={this.handleBack}
//           nameConfirm="gotoHome"
//         />
//         <ModalAlert
//           openModal={modalAlertSuccess ? modalAlertSuccess : modalAlertFailed}
//           size="tiny"
//           close={this.handleCloseAlert}
//           message={modalAlertMessage}
//         />
//         <Loader active={loading} description="Loading" page={true} />
//       </Grid.Row>
//     );
//   }
// }

// AllButtonSummary.propTypes = {
//   data: PropTypes.object,
//   companyProfile: PropTypes.object,
//   postCreateTour: PropTypes.func,
//   resetStatusTransaction: PropTypes.func,
//   loading: PropTypes.bool,
//   packageType: PropTypes.string,
//   postCreateTourStatus: PropTypes.string,
//   error: PropTypes.object,
//   dataDemoJoinTour: PropTypes.object,
//   dataDemoPrice: PropTypes.object,
//   idTour: PropTypes.number,
//   postJoinTour: PropTypes.func,
//   joinTour: PropTypes.object,
//   createTour: PropTypes.object,
//   postJoinTourStatus: PropTypes.string,
//   resetTempDemoJoinTour: PropTypes.func,
//   listGuest: PropTypes.array,
//   isSales: PropTypes.bool,
//   selectedCompany: PropTypes.object,
//   postCreateTourOnBehalf: PropTypes.func,
//   postJoinTourOnBehalf: PropTypes.func,
//   createTourOnBeHalf: PropTypes.object,
//   postCreateTourOnBeHalfStatus: PropTypes.string,
//   joinTourOnBeHalf: PropTypes.object,
//   postJoinTourOnBeHalfStatus: PropTypes.string,
//   selectedUser: PropTypes.object,
//   setTempBackCustomReady: PropTypes.func,
//   postJoinTourVariable: PropTypes.func,
//   tourNote: PropTypes.string,
// };
// const mapStateToProps = state => {
//   return {
//     loading: state.tourTransaction.loading,
//     postCreateTourStatus: state.tourTransaction.postCreateTourStatus,
//     error: state.tourTransaction.errors,
//     dataDemoJoinTour: state.temp.dataDemoJoinTour,
//     dataDemoPrice: state.temp.dataDemoPrice,
//     idTour: state.temp.idTour,
//     joinTour: state.tourTransaction.joinTour,
//     createTour: state.tourTransaction.createTour,
//     postJoinTourStatus: state.tourTransaction.postJoinTourStatus,
//     createTourOnBeHalf: state.tourTransaction.createTourOnBeHalf,
//     postCreateTourOnBeHalfStatus:
//       state.tourTransaction.postCreateTourOnBeHalfStatus,
//     joinTourOnBeHalf: state.tourTransaction.joinTourOnBeHalf,
//     postJoinTourOnBeHalfStatus:
//       state.tourTransaction.postJoinTourOnBeHalfStatus,
//   };
// };
// export default connect(
//   mapStateToProps,
//   {
//     postCreateTour,
//     resetStatusTransaction,
//     postJoinTour,
//     resetTempDemoJoinTour,
//     postCreateTourOnBehalf,
//     postJoinTourOnBehalf,
//     setTempBackCustomReady,
//     postJoinTourVariable,
//   }
// )(AllButtonSummary);

import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import Loader from '../../../components/common/loader';
import PropTypes from 'prop-types';
import styles from '../styles';
import { connect } from 'react-redux';
import {
  postCreateTour,
  postJoinTour, //fixed
  postCreateTourOnBehalf,
  postJoinTourOnBehalf, //fixed
  resetStatusTransaction,
  postJoinTourVariable,
} from '../../shared/actions/tourTransactionAction';
import {
  resetTempDemoJoinTour,
  setTempBackCustomReady,
  setTempTourCustomReady,
  setTempDemoJoinTour,
} from '../../shared/actions/tempAction';
import {
  getTotalDays,
  convertDateFormat,
} from '../../../genericFunction/moment';
import { ModalConfirm } from './../../shared/components/modal';
import { ModalAlert } from './../../shared/components/modal';
import { Redirect } from 'react-router-dom';
import {
  convertDataBookNowFixed,
  getGuests,
} from '../../../genericFunction/convertPackage';
import { withTranslation } from 'react-i18next';
import {
  destructureAdditionalServices,
  initialAdditionalServices,
} from '../../shared/services/additionalServices/processData';
import { isHideQuotation } from '../../../constant/hideFeature';
class AllButtonSummary extends Component {
  state = {
    modalConfirmBook: false,
    modalConfirmQuotation: false,
    modalConfirmMessage: '',
    modalAlertSuccess: false,
    modalAlertFailed: false,
    modalConfirmVerificationBook: false,
    verification: false,
    isQuotation: false,
    modalAlertMessage: '',
    modalConfirmCancel: false,
    gotoMyBooking: false,
    gotoMyQuotation: false,
    gotoPrev: false,
    gotoHome: false,
    data: {},
    companyProfile: {},
    idBook: 0,
  };
  componentDidUpdate = () => {
    const { t } = this.props;
    if (
      this.props.postCreateTourStatus === 'success' ||
      this.props.postJoinTourStatus === 'success' ||
      this.props.postCreateTourOnBeHalfStatus === 'success' ||
      this.props.postJoinTourOnBeHalfStatus === 'success'
    ) {
      let {
        joinTour, //fixed
        createTour,
        packageType,
        createTourOnBeHalf,
        joinTourOnBeHalf,
        isSales,
      } = this.props;
      // let dataJoinTour = isSales ? joinTourOnBeHalf : joinTour;
      let dataJoinTour = isSales
        ? packageType !== 'ready-package-variable'
          ? joinTourOnBeHalf
          : joinTour
        : joinTour;
      let dataCreateTour = isSales ? createTourOnBeHalf : createTour;
      let idBook =
        packageType === 'fixed' || packageType === 'ready-package-variable'
          ? dataJoinTour.BookingDetailSum.Id
          : packageType !== 'fixed' && dataCreateTour.BookingDetailSum.Id;
      let status =
        packageType === 'fixed' || packageType === 'ready-package-variable'
          ? dataJoinTour.BookingDetailSum.Status
          : packageType !== 'fixed' && dataCreateTour.BookingDetailSum.Status;
      let message =
        packageType !== 'fixed' || packageType !== 'ready-package-variable'
          ? this.state.isQuotation
            ? `${t('quotationCode')} ${idBook} ${t('successfullyCreated')}` //'Quotation code ' + idBook + ' successfully created'
            : status === 'Booking_created'
            ? `${t('bookingCode')} ${idBook} ${t('successfullyCreated')}` //'Booking code ' + idBook + ' successfully created'
            : t('yourBookingIsOnHold') //'Your Booking is on hold'
          : `${t('bookingCode')} ${idBook} ${t('successfullyCreated')}`; // 'Booking code ' + idBook + ' successfully created';
      //   ? 'Quotation code ' + idBook + ' successfully created'
      //   : status === 'Booking_created'
      //   ? 'Booking code ' + idBook + ' successfully created'
      //   : 'Your Booking is on hold'
      // : 'Booking code ' + idBook + ' successfully created';

      this.setState({
        ...this.state,
        modalAlertSuccess: true,
        modalAlertMessage: message,
        idBook: idBook,
      });
      this.props.resetTempDemoJoinTour();
      this.props.resetStatusTransaction();
    } else if (
      this.props.postCreateTourStatus === 'failed' ||
      this.props.postJoinTourStatus === 'failed' ||
      this.props.postCreateTourOnBeHalfStatus === 'failed' ||
      this.props.postJoinTourOnBeHalfStatus === 'failed'
    ) {
      this.setState({
        ...this.state,
        modalAlertFailed: true,
        modalAlertMessage: this.props.error.Message,
      });
      this.props.resetStatusTransaction();
    }
    if (this.props.isBookStickyHeader) {
      this.handleButtonStickyHeader(
        'modalConfirmBook',
        this.props.data,
        this.props.companyProfile
      );
    }
  };

  filterGuest = tourGuestSum => {
    return tourGuestSum.filter(
      guest =>
        guest.CountryId &&
        guest.FirstName &&
        guest.IdentityNbr &&
        guest.IdentityType &&
        guest.LastName &&
        guest.GuestTitle
    );
  };
  handleBookNow = name => {
    this.setState({
      ...this.state,
      isQuotation: name === 'quotation',
      modalConfirmBook: false,
      modalConfirmQuotation: false,
      modalConfirmVerificationBook: false,
    });
    let { selectedUser } = this.props;
    if (this.props.packageType === 'fixed') {
      let {
        splitCommision,
        printCommision,
        guests,
        roomAllocation,
        supplementObject,
        detailGuest,
      } = this.props.dataDemoJoinTour;
      let dataDemoJoinTour = convertDataBookNowFixed(
        splitCommision,
        printCommision,
        guests,
        roomAllocation,
        supplementObject,
        detailGuest,
        this.props.tourNote,
        this.props.idTour
      );
      dataDemoJoinTour.Guests = getGuests(this.props.listGuest);
      if (this.props.isSales) {
        dataDemoJoinTour.CompanyCode = this.props.selectedCompany.code;
        selectedUser.code &&
          (dataDemoJoinTour.UserProfileId = selectedUser.code);
        this.props.postJoinTourOnBehalf(this.props.idTour, dataDemoJoinTour);
      } else {
        this.props.postJoinTour(this.props.idTour, dataDemoJoinTour);
      }
    } else if (this.props.packageType === 'ready-package-variable') {
      let dataDemoJoinTour = convertDataBookNowFixed(
        this.props.dataDemoJoinTour.splitCommision,
        this.props.dataDemoJoinTour.printCommision,
        this.props.dataDemoJoinTour.guests,
        this.props.dataDemoJoinTour.roomAllocation,
        this.props.dataDemoJoinTour.supplementObject,
        this.props.dataDemoJoinTour.detailGuest,
        this.props.tourNote,
        this.props.idTour,
        this.props.dataDemoJoinTour.additionalServices
      );
      dataDemoJoinTour.StartTour = this.props.dataDemoJoinTour.startTour;
      dataDemoJoinTour.EndTour = this.props.dataDemoJoinTour.endTour;
      dataDemoJoinTour.TourOperatorProfileId = this.props.data.BookingDetailSum.TourOperatorProfileId;
      dataDemoJoinTour.Title = this.props.dataDemoJoinTour.title;
      dataDemoJoinTour.Guests = getGuests(this.props.listGuest);
      if (this.props.isSales) {
        dataDemoJoinTour.CompanyCode = this.props.selectedCompany.code;
        selectedUser.code &&
          (dataDemoJoinTour.UserProfileId = selectedUser.code);
        // this.props.postJoinTourOnBehalf(this.props.idTour, dataDemoJoinTour);
        this.props.postJoinTourVariable(this.props.idTour, dataDemoJoinTour);
      } else {
        this.props.postJoinTourVariable(this.props.idTour, dataDemoJoinTour);
      }
    } else if (this.props.packageType !== 'fixed') {
      let dataDemoPrice = this.props.dataDemoPrice;
      dataDemoPrice.TourNote = this.props.tourNote;
      let { companyProfile } = this.state;
      let { BookingDetailSum } = this.state.data;
      let additionalServiceList = initialAdditionalServices(
        this.props.additionalServices ? this.props.additionalServices : []
      );
      dataDemoPrice.CompanyCode = companyProfile.Code;
      dataDemoPrice.IsQuotation = name === 'quotation';
      dataDemoPrice.PackageType =
        BookingDetailSum.PackageType === 'Fixed' ? 2 : 0;
      dataDemoPrice.TourName = BookingDetailSum.TourOperatorProfile.Name;
      dataDemoPrice.Guests = getGuests(this.props.listGuest);
      dataDemoPrice.AdditionalServices = destructureAdditionalServices(
        additionalServiceList
      );
      if (this.props.isSales) {
        dataDemoPrice.CompanyCode = this.props.selectedCompany.code;
        selectedUser.code && (dataDemoPrice.UserProfileId = selectedUser.code);
        this.props.postCreateTourOnBehalf(dataDemoPrice);
      } else {
        this.props.postCreateTour(dataDemoPrice);
      }
    }
  };
  handleButton = (e, { name, value, desc }) => {
    const { t } = this.props;
    if (
      (name === 'modalConfirmBook' || name === 'modalConfirmQuotation') &&
      this.props.isSales &&
      !this.props.selectedCompany.code
    ) {
      // this.props.selectedCompany.code ||
      this.setState({
        ...this.state,
        modalAlertFailed: true,
        modalAlertMessage: t('pleaseChooseCustomerOnBehalf'), //'Please choose customer on behalf first',
      });
    } else {
      let message =
        name === 'modalConfirmBook'
          ? t('areYouSureWantToBook') // 'Are you sure want to book this tour?'
          : name === 'modalConfirmQuotation'
          ? t('areYouSureWantCreateQuotationThisTour') //'Are you sure want create quotation this tour?'
          : name === 'modalConfirmCancel' &&
            t('areYouSureWantCancelThisTransaction'); //'Are you sure want cancel this transaction?';
      this.setState({
        ...this.state,
        [name]: !this.state[name],
        modalConfirmMessage: message,
        data: value,
        companyProfile: desc,
      });
    }
  };

  handleButtonStickyHeader = (name, value, desc) => {
    const { t } = this.props;
    this.props.handleResetBookNow();
    if (
      (name === 'modalConfirmBook' || name === 'modalConfirmQuotation') &&
      this.props.isSales &&
      !this.props.selectedCompany.code
    ) {
      // this.props.selectedCompany.code ||
      this.setState({
        ...this.state,
        modalAlertFailed: true,
        modalAlertMessage: t('pleaseChooseCustomerOnBehalf'), // 'Please choose customer on behalf first',
      });
    } else {
      let message =
        name === 'modalConfirmBook'
          ? t('areYouSureWantToBook') //'Are you sure want to book this tour?'
          : name === 'modalConfirmQuotation'
          ? t('areYouSureWantCreateQuotationThisTour') //'Are you sure want create quotation this tour?'
          : name === 'modalConfirmCancel' &&
            t('areYouSureWantCancelThisTransaction'); //'Are you sure want cancel this transaction?';
      this.setState({
        ...this.state,
        [name]: !this.state[name],
        modalConfirmMessage: message,
        data: value,
        companyProfile: desc,
      });
    }
  };

  handleCloseBookNow = () =>
    this.setState({
      ...this.state,
      modalConfirmBook: false,
      modalConfirmQuotation: false,
      modalConfirmVerificationBook: false,
      modalConfirmCancel: false,
    });
  handleCloseAlert = () => {
    // modalAlertSuccess ? modalAlertSuccess : modalAlertFailed;
    let { isQuotation, modalAlertSuccess, modalAlertFailed } = this.state;
    let gotoMyBooking = modalAlertFailed
      ? false
      : modalAlertSuccess && !isQuotation
      ? true
      : false;
    let gotoMyQuotation = modalAlertFailed ? false : isQuotation;
    return this.setState({
      ...this.state,
      modalAlertSuccess: false,
      modalAlertFailed: false,
      gotoMyBooking,
      gotoMyQuotation,
    });
  };
  handleConfirmBookNow = (e, { name }) => {
    getTotalDays(
      this.state.data.BookingDetailSum.ExpiredOn,
      convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
      false
    ) < 2
      ? name !== 'quotation'
        ? this.setState({
            ...this.state,
            modalConfirmBook: false,
            modalConfirmQuotation: false,
            modalConfirmVerificationBook: true,
            verification: true,
          })
        : this.handleBookNow(name)
      : this.handleBookNow(name);
  };
  handleConfirmVerificationBook = (e, { name }) => this.handleBookNow(name);
  handleBack = async (e, { name }) => {
    if (this.props.packageType !== 'fixed') {
      this.props.setTempBackCustomReady(true);
      let data = this.props.dataTempTourCustomReady;
      data.TourNote = this.props.tourNote;
      this.props.setTempTourCustomReady(data);
    } else {
      let data = this.props.dataDemoJoinTour;
      data.tourNote = this.props.tourNote;
      this.props.setTempDemoJoinTour(data);
    }
    this.setState({ [name]: true });
  };
  render() {
    const { t } = this.props;
    let { loading, packageType } = this.props;
    let {
      modalConfirmBook,
      modalConfirmQuotation,
      modalConfirmMessage,
      modalConfirmVerificationBook,
      verification,
      modalAlertSuccess,
      modalAlertFailed,
      modalAlertMessage,
      modalConfirmCancel,
      gotoMyBooking,
      gotoMyQuotation,
      gotoPrev,
      gotoHome,
      idBook,
    } = this.state;
    if (gotoMyBooking) {
      if (verification && packageType === 'fixed') {
        return <Redirect to={'/partial-payment/' + idBook} />;
      } else {
        return <Redirect to={'/my-profile/booking'} />;
      }
    } else if (gotoMyQuotation) {
      return <Redirect to={'/my-profile/quotation'} />;
    } else if (gotoPrev) {
      if (packageType === 'fixed') {
        return <Redirect to={'/series-package/' + this.props.idTour} />;
      } else if (packageType === 'ready') {
        return <Redirect to={'/ready/ready-package/' + this.props.idTour} />;
      } else if (packageType === 'similar') {
        return <Redirect to={'/ready/similar/' + this.props.idTour} />;
      } else if (packageType === 'ready-package-variable') {
        return (
          <Redirect to={'/ready/ready-package-variable/' + this.props.idTour} />
        );
      } else if (packageType === 'custom') {
        return <Redirect to={'/custom-package/'} />;
      }
    } else if (gotoHome) {
      return <Redirect to={'/'} />;
    }
    return (
      <Grid.Row style={styles.marginBottom20px}>
        <Grid.Column width={16} textAlign="center">
          <Button
            style={styles.buttonGold}
            size="huge"
            onClick={this.handleBack}
            name="gotoPrev"
          >
            {/* Back */}
            {t('back')}
          </Button>
          <Button
            style={styles.buttonRed}
            size="huge"
            name="modalConfirmCancel"
            onClick={this.handleButton}
          >
            {/* Cancel */}
            {t('cancel')}
          </Button>
          {isHideQuotation
            ? null
            : packageType !== 'fixed' &&
              packageType !== 'ready-package-variable' && (
                <Button
                  style={styles.buttonBlue}
                  onClick={this.handleButton}
                  name="modalConfirmQuotation"
                  value={this.props.data}
                  desc={this.props.companyProfile}
                  size="huge"
                >
                  {/* Create Quotation */}
                  {t('createQuotation')}
                </Button>
              )}
          <Button
            style={styles.buttonGold}
            onClick={this.handleButton}
            name="modalConfirmBook"
            value={this.props.data}
            desc={this.props.companyProfile}
            size="huge"
          >
            {/* Book Now */}
            {t('bookNow')}
          </Button>
        </Grid.Column>
        <ModalConfirm
          openModal={
            modalConfirmBook ? modalConfirmBook : modalConfirmQuotation
          }
          size="tiny"
          message={modalConfirmMessage}
          close={this.handleCloseBookNow}
          confirm={this.handleConfirmBookNow}
          nameConfirm={modalConfirmBook ? 'book' : 'quotation'}
        />
        <ModalConfirm
          openModal={modalConfirmVerificationBook}
          size="tiny"
          message={t('thisBookingIsExpireSoon')} //"This booking is expiring soon. Do you want to book and pay now?"
          close={this.handleCloseBookNow}
          confirm={this.handleConfirmVerificationBook}
          nameConfirm="book"
        />
        <ModalConfirm
          openModal={modalConfirmCancel}
          size="tiny"
          message={modalConfirmMessage}
          close={this.handleCloseBookNow}
          confirm={this.handleBack}
          nameConfirm="gotoHome"
        />
        <ModalAlert
          openModal={modalAlertSuccess ? modalAlertSuccess : modalAlertFailed}
          size="tiny"
          close={this.handleCloseAlert}
          message={modalAlertMessage}
        />
        <Loader active={loading} description="Loading" page={true} />
      </Grid.Row>
    );
  }
}

AllButtonSummary.propTypes = {
  data: PropTypes.object,
  companyProfile: PropTypes.object,
  postCreateTour: PropTypes.func,
  resetStatusTransaction: PropTypes.func,
  loading: PropTypes.bool,
  packageType: PropTypes.string,
  postCreateTourStatus: PropTypes.string,
  error: PropTypes.object,
  dataDemoJoinTour: PropTypes.object,
  dataDemoPrice: PropTypes.object,
  idTour: PropTypes.number,
  postJoinTour: PropTypes.func,
  joinTour: PropTypes.object,
  createTour: PropTypes.object,
  postJoinTourStatus: PropTypes.string,
  resetTempDemoJoinTour: PropTypes.func,
  listGuest: PropTypes.array,
  isSales: PropTypes.bool,
  selectedCompany: PropTypes.object,
  postCreateTourOnBehalf: PropTypes.func,
  postJoinTourOnBehalf: PropTypes.func,
  createTourOnBeHalf: PropTypes.object,
  postCreateTourOnBeHalfStatus: PropTypes.string,
  joinTourOnBeHalf: PropTypes.object,
  postJoinTourOnBeHalfStatus: PropTypes.string,
  selectedUser: PropTypes.object,
  setTempBackCustomReady: PropTypes.func,
  postJoinTourVariable: PropTypes.func,
  tourNote: PropTypes.string,
  isBookStickyHeader: PropTypes.bool,
  handleResetBookNow: PropTypes.func,
  dataTempTourCustomReady: PropTypes.object,
  setTempTourCustomReady: PropTypes.func,
  setTempDemoJoinTour: PropTypes.func,
  t: PropTypes.func,
  additionalServices: PropTypes.array,
};
const mapStateToProps = state => {
  return {
    loading: state.tourTransaction.loading,
    postCreateTourStatus: state.tourTransaction.postCreateTourStatus,
    error: state.tourTransaction.errors,
    dataDemoJoinTour: state.temp.dataDemoJoinTour,
    dataDemoPrice: state.temp.dataDemoPrice,
    idTour: state.temp.idTour,
    joinTour: state.tourTransaction.joinTour,
    createTour: state.tourTransaction.createTour,
    postJoinTourStatus: state.tourTransaction.postJoinTourStatus,
    createTourOnBeHalf: state.tourTransaction.createTourOnBeHalf,
    postCreateTourOnBeHalfStatus:
      state.tourTransaction.postCreateTourOnBeHalfStatus,
    joinTourOnBeHalf: state.tourTransaction.joinTourOnBeHalf,
    postJoinTourOnBeHalfStatus:
      state.tourTransaction.postJoinTourOnBeHalfStatus,
    dataTempTourCustomReady: state.temp.dataTourCustomReady,
  };
};
export default connect(
  mapStateToProps,
  {
    postCreateTour,
    resetStatusTransaction,
    postJoinTour,
    resetTempDemoJoinTour,
    postCreateTourOnBehalf,
    postJoinTourOnBehalf,
    setTempBackCustomReady,
    postJoinTourVariable,
    setTempTourCustomReady,
    setTempDemoJoinTour,
  }
)(withTranslation()(AllButtonSummary));
