const filterDataBySecondData = (firstData, secondData, dataName, dataName2) => {
  return firstData.filter(obj => {
    return secondData.some(obj2 => {
      return obj[dataName] === obj2[dataName2];
    });
  });
};

const filterDataBySecondDataReverse = (
  firstData,
  secondData,
  dataName,
  dataName2
) => {
  return firstData.filter(obj => {
    return !secondData.some(obj2 => {
      return obj[dataName] === obj2[dataName2];
    });
  });
};
export { filterDataBySecondDataReverse, filterDataBySecondData };
