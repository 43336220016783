import {
  SET_TEMP_FILTER_QUICK_LAUNCH,
  SET_TEMP_DEMO_JOIN_TOUR,
  SET_TEMP_ID_TOUR,
  RESET_TEMP_DEMO_JOIN_TOUR,
  SET_TEMP_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2,
  SET_TEMP_DEMO_PRICE,
  SET_TEMP_BACK_CUSTOM_READY,
  SET_TEMP_TOUR_CUSTOM_READY,
  RESET_TEMP_BACK_CUSTOM_READY,
  RESET_TEMP_DEMO_PRICE,
  RESET_TEMP_FILTER_QUICK_LAUNCH,
} from './actionTypes';

const setTempFilterQuickLaunch = data => {
  return dispatch => {
    return dispatch({
      type: SET_TEMP_FILTER_QUICK_LAUNCH,
      data,
    });
  };
};

const setTempDemoJoinTour = data => {
  return dispatch => {
    return dispatch({
      type: SET_TEMP_DEMO_JOIN_TOUR,
      data,
    });
  };
};

const setTempIdTour = data => {
  return dispatch => {
    return dispatch({
      type: SET_TEMP_ID_TOUR,
      data,
    });
  };
};

const resetTempDemoJoinTour = () => {
  return dispatch => {
    return dispatch({
      type: RESET_TEMP_DEMO_JOIN_TOUR,
    });
  };
};

const setTempTourTransactionsConfirmationDemoV2 = data => {
  return dispatch => {
    return dispatch({
      type: SET_TEMP_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2,
      data,
    });
  };
};

const setTempDemoPrice = data => {
  return dispatch => {
    return dispatch({
      type: SET_TEMP_DEMO_PRICE,
      data,
    });
  };
};

const setTempTourCustomReady = data => {
  return dispatch => {
    return dispatch({
      type: SET_TEMP_TOUR_CUSTOM_READY,
      data,
    });
  };
};

const setTempBackCustomReady = data => {
  return dispatch => {
    return dispatch({
      type: SET_TEMP_BACK_CUSTOM_READY,
      data,
    });
  };
};
const resetTempBackCustomReady = data => {
  return dispatch => {
    return dispatch({
      type: RESET_TEMP_BACK_CUSTOM_READY,
      data,
    });
  };
};
const resetTempDemoPrice = () => {
  return dispatch => {
    return dispatch({
      type: RESET_TEMP_DEMO_PRICE,
    });
  };
};
const resetTempFilterQuickLaunch = () => {
  return dispatch => {
    return dispatch({
      type: RESET_TEMP_FILTER_QUICK_LAUNCH,
    });
  };
};
export {
  setTempFilterQuickLaunch,
  setTempDemoJoinTour,
  setTempIdTour,
  setTempTourTransactionsConfirmationDemoV2,
  resetTempDemoJoinTour,
  setTempDemoPrice,
  resetTempDemoPrice,
  setTempBackCustomReady,
  setTempTourCustomReady,
  resetTempBackCustomReady,
  resetTempFilterQuickLaunch,
};
