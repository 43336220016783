import React from 'react';
import { Grid, Segment, Button, Icon } from 'semantic-ui-react';
import { styles, bgImage } from './styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { converNumberToK } from '../../../../genericFunction/numberConverter';
import { rangeDate, getTotalDays } from '../../../../genericFunction/moment';
import { noImage } from '../../../../assets/image';
import ProgressiveImage from 'react-progressive-image-loading';
import GoldRibbon from '../../../shared/components/common/GoldRibbon';
import { useTranslation } from 'react-i18next';

const TourPackageCard = props => {
  const { t } = useTranslation();
  const { item, token, openModal } = props;
  const available = item && item.FixedPackage ? item.FixedPackage.PaxLeft : 0;
  const tourDate = item ? rangeDate(item.StartDate, item.EndDate) : '';
  const image =
    item && item.Images
      ? item.Images.length > 0
        ? item.Images[0].ImageUrl
        : item.City.ImageUrl
      : item.City.ImageUrl
      ? item.City.ImageUrl
      : noImage;
  const totalCommission =
    item && item.Commissions
      ? item.Commissions.length !== 0
        ? item.Commissions.map(e => e.Value).reduce((acc, cur) => acc + cur)
        : 0
      : 0;
  const srcImage = image ? image.replace(/\s/g, '%20') : noImage;
  const isReadyFixPrice =
    item && item.PackageType ? item.PackageType === 'FixedDateVariable' : false;
  return (
    <Grid.Column>
      <ProgressiveImage
        src={srcImage}
        preview={srcImage}
        render={(src, style) => (
          <Segment style={{ ...style, ...bgImage(src) }} attached="top">
            {isReadyFixPrice ? (
              <GoldRibbon
                size="big"
                text={'Fixed Price'}
                style={styles.ribbonTypePackage}
              />
            ) : null}
            <Grid.Row style={styles.absoluteRow}>
              <h3 style={styles.goldColor}>{item.Title}</h3>
            </Grid.Row>
          </Segment>
        )}
      />

      <Segment attached="bottom" style={styles.bgGradDark}>
        <Grid>
          <Grid.Row style={styles.noPaddingBottom}>
            {item.SharingRoomPrice ? (
              <Grid.Column width={10}>
                {isReadyFixPrice && (
                  <>
                    <text style={{ color: '#fff' }}>{`${getTotalDays(
                      item.StartDate,
                      item.EndDate,
                      true
                    )} ${t('day')} ${getTotalDays(
                      item.StartDate,
                      item.EndDate,
                      true
                    ) - 1} ${t('night')}`}</text>
                    <br />
                    {/* Day Night */}
                  </>
                )}
                {/* Starting from */}
                <text style={styles.whiteColor}>{t('startingFrom')}</text>
                <strong style={styles.priceStyle}>
                  {item.CurrencyId +
                    ' ' +
                    (item.SharingRoomPrice
                      ? converNumberToK(item.SharingRoomPrice)
                      : 0)}
                </strong>
                <strong style={styles.labelRoomStyle}>
                  {/* Per Pax on Twin Sharing */}
                  {t('perPaxOnTwinSharing')}
                </strong>
              </Grid.Column>
            ) : null}

            {available !== 0 && item.SharingRoomPrice && !isReadyFixPrice ? (
              <Grid.Column width={6}>
                <strong style={styles.labelPaxAvailableStyle}>
                  {/* Pax Available */}
                  {t('paxAvailable')}
                </strong>
                <strong style={styles.labelAvailableStyle}>
                  {/* Left */}
                  {available}{' '}
                  <span style={styles.labelLeftStyle}>{t('left')}</span>
                </strong>
              </Grid.Column>
            ) : null}
            {isReadyFixPrice ? (
              token ? (
                <Grid.Column width={6}>
                  <Button
                    floated="right"
                    as={Link}
                    to={
                      item.PackageType === 'Fixed'
                        ? '/series-package/' + item.Id
                        : item.PackageType === 'FixedDateVariable'
                        ? `/ready/ready-package-variable/${item.Id}` // `/ready-package-variable/${item.Id}`
                        : '/ready/ready-package/' + item.Id
                    }
                    size="medium"
                    style={styles.bookNowStyleFixPrice}
                  >
                    {/* BOOK NOW */}
                    <strong style={styles.blackColor}>{t('BookNow')}</strong>
                  </Button>
                </Grid.Column>
              ) : (
                <Grid.Column width={6}>
                  <Button
                    floated="right"
                    size="medium"
                    style={styles.bookNowStyleFixPrice}
                    onClick={openModal}
                  >
                    {/* BOOK NOW */}
                    <strong style={styles.blackColor}>{t('BookNow')}</strong>
                  </Button>
                </Grid.Column>
              )
            ) : null}
          </Grid.Row>
          <Grid.Row style={styles.paddingTopBottomFive}>
            <Grid.Column width={1} verticalAlign="top">
              {!isReadyFixPrice && (
                <Icon style={styles.goldColor} name="calendar alternate" />
              )}
            </Grid.Column>
            <Grid.Column width={7} verticalAlign="top">
              {!isReadyFixPrice && (
                <span style={styles.fontSize12}>
                  <strong>{tourDate}</strong>
                </span>
              )}
            </Grid.Column>
            <Grid.Column width={7}>
              {!isReadyFixPrice ? (
                token ? (
                  <Button
                    floated="right"
                    as={Link}
                    to={
                      item.PackageType === 'Fixed'
                        ? '/series-package/' + item.Id
                        : item.PackageType === 'FixedDateVariable'
                        ? `/ready/ready-package-variable/${item.Id}` // `/ready-package-variable/${item.Id}`
                        : '/ready/ready-package/' + item.Id
                    }
                    size="medium"
                    style={styles.bookNowStyle}
                  >
                    {/* BOOK NOW */}
                    <strong style={styles.blackColor}>{t('BookNow')}</strong>
                  </Button>
                ) : (
                  <Button
                    floated="right"
                    size="medium"
                    style={styles.bookNowStyle}
                    onClick={openModal}
                  >
                    {/* BOOK NOW */}
                    <strong style={styles.blackColor}>{t('BookNow')}</strong>
                  </Button>
                )
              ) : null}
            </Grid.Column>
          </Grid.Row>
          {token && totalCommission !== 0 ? (
            <Grid.Row centered style={styles.paddingTopBotFiveSideThirty}>
              <span style={styles.marginLeftFive}>
                {/* Total Commission */}
                {`${t('totalCommission')}`} : <br />
              </span>
              <span style={styles.goldColor}>
                {' ' +
                  item.CurrencyId +
                  ' ' +
                  (totalCommission ? converNumberToK(totalCommission) : 0)}
              </span>
            </Grid.Row>
          ) : (
            <Grid.Row centered style={styles.paddingTopBotFiveSideThirty} />
          )}
        </Grid>
      </Segment>
    </Grid.Column>
  );
};
TourPackageCard.propTypes = {
  item: PropTypes.object,
  token: PropTypes.string,
  openModal: PropTypes.func,
  t: PropTypes.func,
};
export default TourPackageCard;
