import * as types from '../actions/actionTypes';

const initialState = {
  tourName: '',
  groupCapacity: '',
  firstDestination: null,
  tourType: '',
  tourCategory: '',
  guests: { adults: 0, childs: 0, infants: 0 },
  roomAllocation: {
    ChildExtraBedQty: 0,
    ChildSharingRoomQty: 0,
    ChildSingleRoomQty: 0,
    ExtraBedQty: 0,
    BabyCrib: 0,
    NoBed: 0,
    SharingBedQty: 0,
    SharingRoomQty: 0,
    SingleRoomQty: 0,
  },
  guestList: [],
  HeadlineProgram: {
    Departures: [],
    MainPrograms: [],
    Returns: [],
  },
  setHeadLineStatus: '',
  accommodationChangeIndex: null,
  errors: {},
  loading: false,
  dailyProgram: [],
  movementList: [],
  getMovementListStatus: '',
  durationStore: [],
  durationMap: [],
  drivingData: null,
  getDurationStatus: '',
  getDurationCount: 0,
  isSetDailyProgramSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CUSTOM_PACKAGE: {
      const newState = {
        tourName: '',
        groupCapacity: '',
        firstDestination: null,
        tourType: '',
        tourCategory: '',
        guests: { adults: 0, childs: 0, infants: 0 },
        roomAllocation: {
          ChildExtraBedQty: 0,
          ChildSharingRoomQty: 0,
          ChildSingleRoomQty: 0,
          ExtraBedQty: 0,
          BabyCrib: 0,
          NoBed: 0,
          SharingBedQty: 0,
          SharingRoomQty: 0,
          SingleRoomQty: 0,
        },
        guestList: [],
        HeadlineProgram: {
          Departures: [],
          MainPrograms: [],
          Returns: [],
        },
        setHeadLineStatus: '',
        accommodationChangeIndex: null,
        errors: {},
        loading: false,
        dailyProgram: [],
        movementList: [],
        getMovementListStatus: '',
        durationStore: [],
        durationMap: [],
        drivingData: null,
        getDurationStatus: '',
        getDurationCount: 0,
        isSetDailyProgramSuccess: false,
      };
      return { ...state, newState };
    }
    case types.SET_GUESTS_AMOUNT:
      return { ...state, guests: action.payload };
    case types.SET_TOUR_NAME:
      return { ...state, tourName: action.payload };
    case types.SET_ROOMALLOCATION:
      return { ...state, roomAllocation: action.payload };
    case types.SET_HEADLINE_PROGRAM:
      return {
        ...state,
        HeadlineProgram: action.payload,
        setHeadLineStatus: 'success',
      };
    case types.RESET_SET_HEADLINE_STATUS:
      return { ...state, setHeadLineStatus: '' };
    case types.SET_FIRST_DESTINATION:
      return { ...state, firstDestination: action.payload };
    case types.SET_ACCOMMODATION_ONCHANGE_INDEX:
      return { ...state, accommodationChangeIndex: action.payload };
    case types.RESET_ACCOMMODATION_ONCHANGE_INDEX:
      return { ...state, accommodationChangeIndex: null };
    case types.SET_DAILYPROGRAM:
      return {
        ...state,
        dailyProgram: action.payload,
        isSetDailyProgramSuccess: true,
      };
    case types.RESET_SET_DAILY_PROGRAM_STATUS:
      return {
        ...state,
        isSetDailyProgramSuccess: false,
      };
    //---------------------------------
    // GET_MOVEMENT_LIST
    //---------------------------------
    case types.GET_MOVEMENT_LIST:
      return { ...state };
    case types.GET_MOVEMENT_LIST_PENDING:
      return { ...state, loading: true };
    case types.GET_MOVEMENT_LIST_FULFILLED:
      return {
        ...state,
        movementList: action.payload.data,
        errors: {},
        loading: false,
        getMovementListStatus: 'success',
      };
    case types.GET_MOVEMENT_LIST_REJECTED:
      return {
        ...state,
        movementList: [],
        errors: action.payload,
        loading: false,
        getMovementListStatus: 'failed',
      };
    //---------------------------------
    // GET_DURATION_ADDRESS_TO_ID
    //---------------------------------
    case types.GET_DURATION_ADDRESS_TO_ID:
      return { ...state };
    case types.GET_DURATION_ADDRESS_TO_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_DURATION_ADDRESS_TO_ID_FULFILLED: {
      if (action.data) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: action.payload.Duration.value,
        };
        let store = {
          from: action.data.from,
          to: action.data.to,
          duration: action.payload.Duration.value,
        };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationStatus: 'success',
          getDurationCount: state.getDurationCount + 1,
          errors: {},
          loading: false,
        };
      } else return { ...state };
    }
    case types.GET_DURATION_ADDRESS_TO_ID_REJECTED: {
      if (action.data) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: 0,
        };
        let store = { from: action.data.from, to: action.data.to, duration: 0 };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'failed',
          errors: {},
          loading: false,
        };
      } else return { ...state };
    }
    //---------------------------------
    // GET_DURATION_ID_TO_ADDRESS
    //---------------------------------
    case types.GET_DURATION_ID_TO_ADDRESS:
      return { ...state };
    case types.GET_DURATION_ID_TO_ADDRESS_PENDING:
      return { ...state, loading: true };
    case types.GET_DURATION_ID_TO_ADDRESS_FULFILLED: {
      if (action.data) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: action.payload.Duration.value,
        };
        let store = {
          from: action.data.from,
          to: action.data.to,
          duration: action.payload.Duration.value,
        };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'success',
          errors: {},
          loading: false,
        };
      } else return { ...state };
    }
    case types.GET_DURATION_ID_TO_ADDRESS_REJECTED: {
      if (action.data) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: 0,
        };
        let store = { from: action.data.from, to: action.data.to, duration: 0 };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'failed',
          errors: {},
          loading: false,
        };
      } else return { ...state };
    }
    //---------------------------------
    // GET_DURATION_ID_TO_ID
    //---------------------------------
    case types.GET_DURATION_ID_TO_ID:
      return { ...state };
    case types.GET_DURATION_ID_TO_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_DURATION_ID_TO_ID_FULFILLED: {
      if (action.data) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: action.payload.Duration.value,
        };
        let store = {
          from: action.data.from,
          to: action.data.to,
          duration: action.payload.Duration.value,
        };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'success',
          errors: {},
          loading: false,
        };
      } else return { ...state };
    }
    case types.GET_DURATION_ID_TO_ID_REJECTED: {
      if (action.data) {
        let mapping = {
          day: action.data.day,
          index: action.data.index,
          duration: 0,
        };
        let store = { from: action.data.from, to: action.data.to, duration: 0 };
        return {
          ...state,
          durationStore: state.durationStore.concat(store),
          durationMap: state.durationMap.concat(mapping),
          getDurationCount: state.getDurationCount + 1,
          getDurationStatus: 'failed',
          errors: {},
          loading: false,
        };
      } else return { ...state };
    }
    case types.SET_DRIVING_DATA:
      return { ...state, drivingData: action.payload };
    case types.RESET_GET_DURATION_STATUS:
      return { ...state, getDurationStatus: '' };
    case types.RESET_MAPPING_DURATION:
      return { ...state, durationMap: [] };
    case types.RESET_COUNT_GET_DURATION:
      return { ...state, getDurationCount: 0 };
    case types.SET_COUNT_GET_DURATION:
      return { ...state, getDurationCount: action.payload };
    case types.SET_GUEST_LIST:
      return { ...state, guestList: action.payload };
    case types.SET_MAPPING_OBJ:
      return {
        ...state,
        durationMap: state.durationMap.concat(action.payload),
      };
    case types.RESET_FIRST_DESTINATION:
      return { ...state, firstDestination: null };
    case types.RESET_GUESTS:
      return {
        ...state,
        guests: { adults: 0, childs: 0, infants: 0 },
        guestList: [],
      };
    case types.RESET_ROOM_ALLOCATION:
      return {
        ...state,
        roomAllocation: {
          ChildExtraBedQty: 0,
          ChildSharingRoomQty: 0,
          ChildSingleRoomQty: 0,
          ExtraBedQty: 0,
          BabyCrib: 0,
          NoBed: 0,
          SharingBedQty: 0,
          SharingRoomQty: 0,
          SingleRoomQty: 0,
        },
      };
    default:
      return state;
  }
};

export default reducer;
