import React from 'react';
import { Segment, Header, Grid, Divider, Menu, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../styles';
import { useTranslation } from 'react-i18next';
import { isHideQuotation } from '../../../constant/hideFeature';

const Panel = props => {
  const { t } = useTranslation();
  let { tabActive, firstName, lastName, companyId, currency, balance } = props;
  return (
    <div>
      <Segment style={styles.segment}>
        <Grid>
          <Grid.Row style={styles.paddingTop20pxBottom5pxMarginLeftRight10px}>
            <Grid.Column width={16}>
              <Header as="h3" textAlign="center">
                {/* Hello */}
                {t('hello')} {firstName} {lastName}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.paddingTop10pxMarginLeftRight10px}>
            <Grid.Column width={8}>
              <Header floated="left" as="h5" style={styles.marginTopBottom5px}>
                {/* Company ID */} {t('companyId')}
              </Header>
              <Header floated="right" as="h5" style={styles.marginTopBottom5px}>
                :
              </Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={styles.paddingTopBottom5pxLeftRight3px}
            >
              <span>{companyId}</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.noPaddingMarginLeftRight10px}>
            <Grid.Column width={8}>
              <Header
                floated="left"
                as="h5"
                style={styles.marginTopBottom5pxWidth90px}
              >
                {/* Touress Wallet */} {t('touressWallet')}
              </Header>
              <Header floated="right" as="h5" style={styles.marginTopBottom5px}>
                :
              </Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={styles.paddingTopBottom5pxLeftRight3px}
            >
              {currency && (
                <span>
                  {currency} {balance.toLocaleString('id')}
                </span>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.noPadding}>
            <Grid.Column width={16}>
              <Divider />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={styles.noPaddingMarginBottom20pxLeftRight15px}>
            <Menu vertical text style={styles.width100percent}>
              <Menu.Item
                as={Link}
                to="/my-profile/account"
                style={
                  tabActive === 'account'
                    ? styles.menuActive
                    : styles.menuNonActive
                }
              >
                <Header as="h4">
                  <Icon name="user" /> {t('myAccount')}
                  {/* My Account */}
                </Header>
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/my-profile/company"
                style={
                  tabActive === 'company'
                    ? styles.menuActive
                    : styles.menuNonActive
                }
              >
                <Header as="h4">
                  <Icon name="building" /> {t('myCompany')}
                  {/* My Company */}
                </Header>
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/my-profile/booking"
                style={
                  tabActive === 'booking'
                    ? styles.menuActive
                    : styles.menuNonActive
                }
              >
                <Header as="h4">
                  <Icon name="file alternate" /> {t('myBooking')}
                  {/* My Booking */}
                </Header>
              </Menu.Item>
              {!isHideQuotation && (
                <Menu.Item
                  as={Link}
                  to="/my-profile/quotation"
                  style={
                    tabActive === 'quotation'
                      ? styles.menuActive
                      : styles.menuNonActive
                  }
                >
                  <Header as="h4">
                    <Icon name="file alternate" /> {t('myQuotation')}
                  </Header>
                </Menu.Item>
              )}
            </Menu>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};
Panel.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyId: PropTypes.string,
  currency: PropTypes.string,
  balance: PropTypes.number,
  tabActive: PropTypes.string,
  t: PropTypes.func,
};
export default Panel;
