const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';
const style = {
  backgroundModal: {
    background: '#fbfbfb',
  },

  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  boldGoldColor: {
    color: GOLD_COLOR,
    fontWeight: 'bold',
  },

  textBold: {
    fontWeight: 'bold',
  },
  justify: {
    textAlign: 'justify',
  },

  buttonGold: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
    // width: '100%',
  },

  buttonWhite: {
    background: WHITE_COLOR,
    color: GOLD_COLOR,
    // width: '100%',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
  },

  inputStyles: {
    width: '100%',
  },

  noPaddingTop: { paddingTop: '0px' },

  label: {
    border: '1px solid #6b82e6',
    color: '#6b82e6',
    borderRadius: '50px',
  },

  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  borderNone: { border: 'none' },
  paddingFiveZero: { padding: '5px 0px' },
  marginTopFive: { marginTop: '5px' },
  paddingTopThree: { paddingTop: '3px' },
  paddingTopTen: { paddingTop: '10px' },
  mapImageStyle: {
    // marginLeft: '5px',
    cursor: 'pointer',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    marginBottom: '10px',
  },
  labelViewMap: {
    backgroundColor: 'Transparent',
    position: 'absolute',
    bottom: '20px',
    right: '30%',
    color: '#252525',
    cursor: 'pointer',
    fontSize: '14px',
  },
  noMargin: { margin: 0 },
};
export default style;
