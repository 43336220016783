import { DRIVING, CHECKIN, DAYSTART } from '../constant/activityTypes';
import { addDate } from './moment';
// import { setDailyProgram } from '../actions/customPackageAction';

const initialGuide = {
  DateTime: '',
  EndTime: '',
  TourTransactionId: 0,
  TourGuideServiceType: 501,
  CityId: '',
  Language: null,
  Qty: 0,
  SeqNumber: 0,
};

const getCities = data => {
  let cities = data.Movements.reduce((result, current) => {
    let res = result;
    if (res.length === 0) {
      res.push({
        CityId: current.Destination,
        CityName: current.DestinationName,
        DateTime: current.DateTime,
      });
    } else {
      if (!res.find(item => item.CityId === current.Destination)) {
        if ([CHECKIN, DAYSTART].indexOf(current.MovementName) !== -1) {
          res[res.length - 1].EndTime = addDate(
            current.DateTime,
            current.Duration,
            's'
          );
          res.push({
            CityId: current.Destination,
            CityName: current.DestinationName,
            DateTime: current.DateTime,
          });
        }
      } else {
        res[res.length - 1].EndTime = addDate(
          current.DateTime,
          current.Duration,
          's'
        );
      }
    }
    return res;
  }, []);
  return cities;
};

const getTransportation = (dataDaily, cityId) => {
  let transportation = dataDaily.Movements.reduce((transport, data) => {
    let isSelfTransport = data.Item.ServiceItemId === null;
    if (
      data.MovementName === DRIVING &&
      !isSelfTransport &&
      cityId === data.Item.CityId
    ) {
      if (transport === null) {
        transport = {
          Name: data.Item.Name,
          Id: data.Item.ServiceItemId,
          Qty: data.Item.Capacity,
        };
      }
    }
    return transport;
  }, null);
  return transportation;
};

const createTourGuideArray = data => {
  return data.reduce((res, cur) => {
    if (cur.tourGuide.length > 0) {
      cur.tourGuide.map(g => {
        let guide = { ...initialGuide };
        guide.CityId = cur.CityId;
        guide.CityName = cur.CityName;
        guide.Language = { Id: g.language.value, Text: g.language.text };
        guide.Qty = g.guideAmount;
        guide.DateTime = cur.DateTime;
        guide.EndTime = cur.EndTime;
        res.push(guide);
        return g;
      });
    }
    return res;
  }, []);
};

const setTourGuideInDailyProgram = dailyProgram => {
  return dailyProgram.map(daily => {
    daily.TourGuides = createTourGuideData(daily);
    return daily;
  });
};

const createTourGuideData = daily => {
  return daily.OrderedItems.reduce((res, cur) => {
    if (cur.ItemType === 'TourGuide') {
      let initData = { ...initialGuide };
      initData.CityId = cur.CityId;
      initData.CityName = cur.CityName;
      initData.Language = cur.Language;
      initData.Qty = cur.Qty;
      res.push(initData);
    }
    return res;
  }, []);
};
export {
  getCities,
  getTransportation,
  createTourGuideArray,
  setTourGuideInDailyProgram,
};
