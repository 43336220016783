import React, { Component } from 'react';
import styles from './styles';
import { Segment, Grid } from 'semantic-ui-react';
import GuestForm from './FormGuest';
import PropTypes from 'prop-types';
import UploadGuest from '../../../../components/button/BtnUploadTemplate';
import { getGuestListTemplate } from '../../actions/tourTransactionAction';
import { connect } from 'react-redux';
import { selectTourLeader } from '../../../../genericFunction/validation';
import { withTranslation } from 'react-i18next';
class SegmentFormGuest extends Component {
  render() {
    const { t } = this.props;
    let {
      guests,
      countries,
      identityTypes,
      guestTitleTypes,
      loadingCountry,
      loadingTourTransaction,
      handleChange,
      errors,
      isCustomOrReadyPackage,
    } = this.props;
    let tourLeader = selectTourLeader(guests);
    let idTourLeader = tourLeader ? tourLeader.Id : 0;
    let adults = guests.filter(
      e => e.GuestCategory === 'ADULT' && e.Id !== idTourLeader
    );
    let children = guests.filter(e => e.GuestCategory === 'CHILDREN');
    let infants = guests.filter(e => e.GuestCategory === 'INFANT');
    return (
      <div style={styles.marginBotFourteen}>
        <Segment.Group style={styles.segment}>
          <Segment style={styles.noBorder}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8} verticalAlign="middle">
                  {/* Guest Form */}
                  <h2>{t('guestForm')}</h2>
                </Grid.Column>
                {guests.length ? (
                  <UploadGuest uploadTemplate={this.props.uploadTemplate} />
                ) : null}
              </Grid.Row>
              {guests.length === 0 && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <h5>{t('noGuest')}</h5>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            {/* {guests.length === 0 && <h5>No Guest</h5>} */}
          </Segment>
          <Segment style={styles.noBorder}>
            {/* Tour Leader */}
            {isCustomOrReadyPackage
              ? tourLeader && <h5>{t('tourLeader')}</h5>
              : tourLeader && (
                  <h5>
                    {t('adult')} &#40; &#62; 12 {t('years')} &#41;
                  </h5>
                )}
            {tourLeader && (
              <div>
                <GuestForm
                  key={tourLeader.Id}
                  listCountry={countries}
                  listTitleType={guestTitleTypes}
                  listIdentityType={identityTypes}
                  loadingCountry={loadingCountry}
                  loadingIdentityType={loadingTourTransaction}
                  loadingTitleType={loadingTourTransaction}
                  identityType={tourLeader.IdentityType}
                  identityNbr={tourLeader.IdentityNbr}
                  countryId={tourLeader.CountryId}
                  guestTitle={tourLeader.GuestTitle}
                  firstName={tourLeader.FirstName}
                  lastName={tourLeader.LastName}
                  handleChange={handleChange}
                  id={tourLeader.Id}
                  validate={errors}
                />
              </div>
            )}
            {isCustomOrReadyPackage && (
              <h5>
                {/* Tour Member */}
                {t('tourMember')} <br />
                <br /> {t('adult')} &#40; &#62; 12 {t('years')} &#41;
              </h5>
            )}
            {adults.length !== 0 &&
              adults.map(adult => (
                <GuestForm
                  key={adult.Id}
                  listCountry={countries}
                  listTitleType={guestTitleTypes}
                  listIdentityType={identityTypes}
                  loadingCountry={loadingCountry}
                  loadingIdentityType={loadingTourTransaction}
                  loadingTitleType={loadingTourTransaction}
                  identityType={adult.IdentityType}
                  identityNbr={adult.IdentityNbr}
                  countryId={adult.CountryId}
                  guestTitle={adult.GuestTitle}
                  firstName={adult.FirstName}
                  lastName={adult.LastName}
                  handleChange={handleChange}
                  id={adult.Id}
                />
              ))}
          </Segment>
          {children.length !== 0 && (
            <Segment style={styles.noBorder}>
              <h5>
                {t('children')} &#40; 2-12 {t('years')} &#41;
              </h5>
              {children.map(child => (
                <GuestForm
                  key={child.Id}
                  listCountry={countries}
                  listTitleType={guestTitleTypes}
                  listIdentityType={identityTypes}
                  loadingCountry={loadingCountry}
                  loadingIdentityType={loadingTourTransaction}
                  loadingTitleType={loadingTourTransaction}
                  identityType={child.IdentityType}
                  identityNbr={child.IdentityNbr}
                  countryId={child.CountryId}
                  guestTitle={child.GuestTitle}
                  firstName={child.FirstName}
                  lastName={child.LastName}
                  handleChange={handleChange}
                  id={child.Id}
                />
              ))}
            </Segment>
          )}
          {infants.length !== 0 && (
            <Segment style={styles.noBorder}>
              <h5>
                {t('infants')} &#40; &#60; 2 {t('years')} &#41;
              </h5>{' '}
              {infants.map(infant => (
                <GuestForm
                  key={infant.Id}
                  listCountry={countries}
                  listTitleType={guestTitleTypes}
                  listIdentityType={identityTypes}
                  loadingCountry={loadingCountry}
                  loadingIdentityType={loadingTourTransaction}
                  loadingTitleType={loadingTourTransaction}
                  identityType={infant.IdentityType}
                  identityNbr={infant.IdentityNbr}
                  countryId={infant.CountryId}
                  guestTitle={infant.GuestTitle}
                  firstName={infant.FirstName}
                  lastName={infant.LastName}
                  handleChange={handleChange}
                  id={infant.Id}
                />
              ))}
            </Segment>
          )}
        </Segment.Group>
      </div>
    );
  }
}
SegmentFormGuest.propTypes = {
  guests: PropTypes.array,
  countries: PropTypes.array,
  identityTypes: PropTypes.array,
  guestTitleTypes: PropTypes.array,
  loadingCountry: PropTypes.bool,
  loadingTourTransaction: PropTypes.bool,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  isCustomOrReadyPackage: PropTypes.bool,
  uploadTemplate: PropTypes.func,
  getGuestListTemplate: PropTypes.func,
  guestListTemplate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  t: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    countries: state.countries.countries.map(type => {
      return { text: type.Name, value: type.Id };
    }),
    identityTypes: state.tourTransaction.identityTypes.map(type => {
      return { text: type.Description, value: type.Name };
    }),
    guestTitleTypes: state.tourTransaction.guestTitleTypes.map(type => {
      return { text: type.Description, value: type.Name };
    }),
    loadingCountry: state.countries.loading,
    loadingTourTransaction: state.tourTransaction.loading,
    guestListTemplate: state.tourTransaction.guestTemplate,
  };
};
export default connect(
  mapStateToProps,
  { getGuestListTemplate }
)(withTranslation()(SegmentFormGuest));
