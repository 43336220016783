import React, { Component } from 'react';
import { Grid, Form, Button } from 'semantic-ui-react';
import Input from '../../../../components/input/WithLabel';
import { noImage } from './../../../../assets/image/';
import PropTypes from 'prop-types';
import stylesMyProfile from './../../styles';
import { UpdateCompanyButton } from '..';
import { ModalUploadImage } from '../../../shared/components/modal';
import ImageProgressive from '../../../../components/progressiveImage/ImageProgressive';
import { withTranslation } from 'react-i18next';

class CompanyDetail extends Component {
  state = {
    isDetail: true,
    isOpenModalImage: false,
  };
  handleChangeMode = () => {
    const { isDetail } = this.state;
    this.setState(
      { isDetail: !isDetail },
      () => isDetail || this.props.handleUpdateCompany()
    );
  };
  handleModalImage = () =>
    this.setState(state => ({
      ...this.state,
      isOpenModalImage: !state.isOpenModalImage,
    }));
  render() {
    const { t } = this.props;
    let {
      companyProfile,
      isAdminCustomer,
      handleChangeCompany,
      optionCountries,
      handleUpdateImage,
    } = this.props;
    let {
      CompanyName,
      CountryID,
      Address,
      Email,
      Telephone,
      FaxNo,
      ImageUrl,
    } = companyProfile;
    let { isDetail, isOpenModalImage } = this.state;
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign="center">
              <Form>
                <Form.Field>
                  <ImageProgressive
                    preview={ImageUrl ? ImageUrl : noImage}
                    src={ImageUrl ? ImageUrl : noImage}
                    centered
                    styles={Object.assign(
                      stylesMyProfile.image,
                      stylesMyProfile.verticalAlign
                    )}
                  />
                </Form.Field>
                {isAdminCustomer && (
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Button
                        style={stylesMyProfile.buttonGoldNoPadding}
                        content={t('updateLogo')} //"Update Logo"
                        onClick={this.handleModalImage}
                      />
                    </Form.Field>
                  </Form.Group>
                )}
              </Form>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form>
                {isAdminCustomer && (
                  <UpdateCompanyButton
                    stylesButton={stylesMyProfile}
                    contextstate={this.state}
                    // onClick={this.props.handleUpdateCompany}
                    onClick={this.handleChangeMode}
                    text={isDetail ? `${t('EDIT')}` : `${t('SAVE')}`}
                    // text={isDetail ? 'EDIT' : 'SAVE'}
                  />
                )}
                <Input
                  label={t('companyName')} //"Company Name"
                  placeholder={t('companyName')} //"Company Name"
                  type="text"
                  labelStyles={stylesMyProfile.displayBlockFontBold}
                  value={CompanyName}
                  disabled={!isAdminCustomer || isDetail}
                  required
                  fluid={true}
                  companyProfileData="companyProfileData"
                  handleChange={handleChangeCompany}
                  objectname="companyProfileData"
                  name="CompanyName"
                />
                <Input
                  label={t('country')} //"Country"
                  inline={false}
                  type="select"
                  placeholder={t('selectCountry')} //"Select Country"
                  labelStyles={stylesMyProfile.fontBold}
                  required
                  value={CountryID}
                  options={optionCountries}
                  fluid={true}
                  handleChange={handleChangeCompany}
                  objectname="companyProfileData"
                  name="CountryID"
                  disabled={!isAdminCustomer || isDetail}
                />
                <Input
                  label={t('address')} //"Address"
                  placeholder={t('address')} //"Address"
                  type="text"
                  labelStyles={stylesMyProfile.displayBlockFontBold}
                  required
                  disabled={!isAdminCustomer || isDetail}
                  value={Address}
                  fluid={true}
                  handleChange={handleChangeCompany}
                  objectname="companyProfileData"
                  name="Address"
                />
                <Input
                  label={t('email')} //"Email"
                  placeholder={t('email')} //"Email"
                  type="email"
                  labelStyles={stylesMyProfile.displayBlockFontBold}
                  required
                  value={Email ? Email : ''}
                  disabled={!isAdminCustomer || isDetail}
                  fluid={true}
                  handleChange={handleChangeCompany}
                  objectname="companyProfileData"
                  name="Email"
                />
                <Input
                  label={t('telephone')} //"Telephone"
                  placeholder={t('telephone')} //"Telephone"
                  type="number"
                  labelStyles={stylesMyProfile.displayBlockFontBold}
                  required
                  disabled={!isAdminCustomer || isDetail}
                  value={Telephone}
                  fluid={true}
                  handleChange={handleChangeCompany}
                  objectname="companyProfileData"
                  name="Telephone"
                />
                <Input
                  label={t('faxNo')} //"Fax No."
                  placeholder={t('updateLogo')} //"Fax No."
                  type="number"
                  labelStyles={stylesMyProfile.displayBlockFontBold}
                  required
                  disabled={!isAdminCustomer || isDetail}
                  value={FaxNo ? FaxNo : ''}
                  fluid={true}
                  handleChange={handleChangeCompany}
                  objectname="companyProfileData"
                  name="FaxNo"
                />
                {/* </Form.Field> */}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ModalUploadImage
          openModal={isOpenModalImage}
          handleClose={this.handleModalImage}
          header={t('uploadLogo')} //"Upload Logo"
          image={ImageUrl}
          handleUpdateImage={handleUpdateImage}
          data={companyProfile}
        />
      </div>
    );
  }
}
CompanyDetail.propTypes = {
  companyProfile: PropTypes.shape({
    companyName: PropTypes.string,
    countryID: PropTypes.string,
    country: PropTypes.object,
    address: PropTypes.string,
    email: PropTypes.string,
    telephone: PropTypes.string,
    faxNo: PropTypes.string,
  }),
  handleChangeImage: PropTypes.func,
  imgSrc: PropTypes.string,
  handleUpdateCompany: PropTypes.func,
  isAdminCustomer: PropTypes.bool,
  handleChangeCompany: PropTypes.func,
  optionCountries: PropTypes.array,
  handleUpdateImage: PropTypes.func,
  t: PropTypes.func,
};
// const mapStateToProps = () => {
//   return {};
// };

// export default connect(mapStateToProps)(companyDetail);
export default withTranslation()(CompanyDetail);
