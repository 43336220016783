import React from 'react';
import { Container, Grid, Segment, Header, Icon } from 'semantic-ui-react';
import { BreadCrumb } from '../../shared/components/common';
import PropTypes from 'prop-types';
import styles from '../styles';
import ButtonBackAndNext from '../../../components/button/BtnBackAndNext';
import { breadCrumbDeposit } from '../constant/breadCrumb';
import { useTranslation } from 'react-i18next';

const Xendit = props => {
  const { t } = useTranslation();
  let {
    id,
    title,
    tourDate,
    totalGuest,
    url,
    currencyCompany,
    balance,
    handleButton,
  } = props;
  return (
    <div style={{ background: '#fbfbfb' }}>
      <BreadCrumb sections={breadCrumbDeposit} />
      <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment style={styles.segment}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Grid style={styles.paddingTopBottom20px}>
                        <Grid.Row style={styles.paddingTop10pxBottom5px}>
                          <Grid.Column width={5}>
                            {/* BOOKING ID */}
                            <Header as="h2">{t('BookingCode')} </Header>
                          </Grid.Column>
                          <Grid.Column width={11}>
                            {/* #{id} */}
                            <Header as="h2">{id}</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={styles.paddingTop10pxBottom5px}>
                          <Grid.Column width={16}>
                            {/* Booking Detail */}
                            <Header as="h4">{t('bookingDetail')} </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={styles.paddingTop10pxBottom5px}>
                          <Grid.Column width={16}>
                            <Header as="h4">{title}</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={styles.paddingBottom5px}>
                          <Grid.Column width={7}>
                            <Icon
                              style={styles.goldColor}
                              name="calendar alternate"
                            />
                            {/* tour date */}
                            <span>
                              {t('tourDate')} : {tourDate}
                            </span>
                          </Grid.Column>
                          <Grid.Column width={9}>
                            <Icon style={styles.goldColor} name="users" />
                            {/* total guest : */}
                            <span>
                              {t('totalGuest')} : {totalGuest} {t('guest')}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={8} verticalAlign="middle">
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <Header as="h4">
                              {/* Your Balance: */}
                              {t('yourBalance')}: {currencyCompany}{' '}
                              {balance.toLocaleString('ID')}
                            </Header>
                            {/* {minPayment > 0 && (
                                <Message
                                  error
                                  style={styles.borderRedBackgroundRed}
                                  content={
                                    'You Need ' +
                                    curreny +
                                    ' ' +
                                    minPayment +
                                    ' or more for payment'
                                  }
                                />
                              )} */}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16} textAlign="center">
                    <iframe
                      title="IframePaymentGateway"
                      height="900"
                      width="100%"
                      src={url}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <ButtonBackAndNext
            nameBack="gotoPage"
            nameNext="goto"
            valueBack="/my-profile/booking"
            valueNext={null}
            titleButtonBack={t('backToMyBooking')} //"Back to My Booking"
            titleButtonNext={t('confirmation')} //"Confirmation"
            handleButton={handleButton}
          />
        </Grid>
      </Container>
    </div>
  );
};
Xendit.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  tourDate: PropTypes.string,
  totalGuest: PropTypes.number,
  url: PropTypes.string,
  currencyCompany: PropTypes.string,
  curreny: PropTypes.string,
  balance: PropTypes.number,
  amountPay: PropTypes.number,
  handleButton: PropTypes.func,
};
export default Xendit;
