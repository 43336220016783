import {
  GET_CITY_IN_COUNTRY,
  RESET_STATUS_GET_CITY_IN_COUNTRY,
  GET_PLACE_BY_REGION,
  RESET_GET_PLACE_BY_REGION,
  SET_COORDINATE_ADDRESS,
  UPDATE_COORDINATE_ADDRESS,
  UPDATE_COORDINATE_CENTER,
  GET_PLACE_BY_PLACE_TYPE_ID,
} from './actionTypes';
import {
  getCityInCountryApi,
  getPlaceByRegionApi,
  getPlaceByPlaceTypeIdApi,
} from '../../../api/locationApi';

const getCityInCountry = () => {
  return dispatch => {
    return dispatch({
      type: GET_CITY_IN_COUNTRY,
      payload: getCityInCountryApi(),
    });
  };
};

const resetStatusGetCityInCountry = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_GET_CITY_IN_COUNTRY,
    });
  };
};

const getPlaceByRegion = region => {
  return dispatch => {
    return dispatch({
      type: GET_PLACE_BY_REGION,
      payload: getPlaceByRegionApi(region),
    });
  };
};

const resetStatusGetPlaceByRegion = () => {
  return dispatch => {
    return dispatch({ type: RESET_GET_PLACE_BY_REGION });
  };
};
const setCoordinateAddress = (coordinate, id, type) => {
  return dispatch => {
    return dispatch({
      type: SET_COORDINATE_ADDRESS,
      payload: coordinate,
      id: id,
      placeType: type,
    });
  };
};
const updateCoordinateAddress = coordinates => {
  return dispatch => {
    return dispatch({
      type: UPDATE_COORDINATE_ADDRESS,
      payload: coordinates,
    });
  };
};

const updateCoordinateCenter = coordinates => {
  return dispatch => {
    return dispatch({
      type: UPDATE_COORDINATE_CENTER,
      payload: coordinates,
    });
  };
};
const getPlaceByPlaceTypeIdAction = id => {
  return dispatch => {
    return dispatch({
      type: GET_PLACE_BY_PLACE_TYPE_ID,
      payload: getPlaceByPlaceTypeIdApi(id),
    });
  };
};
export {
  getCityInCountry,
  resetStatusGetCityInCountry,
  getPlaceByRegion,
  resetStatusGetPlaceByRegion,
  setCoordinateAddress,
  updateCoordinateAddress,
  updateCoordinateCenter,
  getPlaceByPlaceTypeIdAction,
};
