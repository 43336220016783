import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Grid } from 'semantic-ui-react';
import ImageProgressive from '../../../../components/progressiveImage/ImageProgressive';
import styleSlider from './styles';
import ArrowNavigation from '../../../shared/components/arrowNavigation/ArrowNavigation';

const CarouselSection = props => {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    nextArrow: (
      <ArrowNavigation
        additionalClassname="mainSlideNext"
        arrowStyle={styleSlider.arrowNavNextStyle}
      />
    ),
    prevArrow: (
      <ArrowNavigation
        additionalClassname="mainSlidePrev"
        arrowStyle={styleSlider.arrowNavPrevStyle}
      />
    ),
    autoplay: true,
  };
  let { token, dataImage } = props;
  return (
    <Grid columns="equal">
      <Grid.Row stretched>
        <Grid.Column
          style={
            token
              ? { ...styleSlider.noPadding, ...styleSlider.marginLeft10 }
              : styleSlider.noPadding
          }
        >
          <Slider {...settings}>
            {dataImage.map((item, i) => (
              <div key={i}>
                <ImageProgressive
                  preview={item.ImageUrl}
                  src={item.ImageUrl}
                  styles={
                    token
                      ? styleSlider.slider1CarouselSection
                      : styleSlider.slider2CarouselSection
                  }
                />
              </div>
            ))}
          </Slider>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

CarouselSection.propTypes = {
  dataImage: PropTypes.array,
  token: PropTypes.string,
};

export default CarouselSection;
