import React, { Component } from 'react';
import { Segment, Header, Icon, Loader } from 'semantic-ui-react';
import AccommodationCarousel from './AccommodationCarousel';
// import styles from '../../../../../components/summary/styles';
import styles from '../styles';
import PropTypes from 'prop-types';
import {
  getAccommodationProfileById,
  resetGetAccommodationProfileById,
  selectAccommodationProfile,
  resetSelectAccommodation,
} from '../../../actions/accommodationAction';
import { connect } from 'react-redux';
import { setCoordinateAddress } from '../../../actions/locationAction';
import axios from 'axios';
import googleAPIKey from '../../../../../config/envApiKey';
import { withTranslation } from 'react-i18next';
class SegmentAccommodation extends Component {
  state = {
    mainPrograms: [],
    selectAccommodation: [],
  };
  componentDidUpdate = () => {
    if (
      JSON.stringify(this.state.mainPrograms) !==
      JSON.stringify(this.props.mainPrograms)
    ) {
      this.setState(
        {
          mainPrograms: this.props.mainPrograms,
          selectAccommodation: [],
        },
        () => this.getAccommodationProfile(this.props.mainPrograms)
      );
      // this.props.resetSelectAccommodation();
      // this.getAccommodationProfile(this.props.mainPrograms);
    }
    if (this.props.getAccommodationProfileByIdStatus === 'success') {
      // this.props.selectAccommodationProfile(this.props.accommodationProfile);
      this.setState({
        ...this.state,
        selectAccommodation: this.state.selectAccommodation.concat(
          this.props.accommodationProfile
        ),
      });
      this.props.resetGetAccommodationProfileById();
    }
  };
  getAccommodationProfile = mainPrograms => {
    return (
      mainPrograms.length > 0 &&
      mainPrograms.map(item => {
        return (
          item.AccommodationSummary &&
          this.props.getAccommodationProfileById(
            item.AccommodationSummary.AccommodationProfileId
          )
        );
      })
    );
  };

  getCoordinateFromAddress = async (address, id) => {
    const context = this;
    await axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleAPIKey}`
      )
      .then(res => {
        res.status === 'ZERO_RESULTS'
          ? context.props.setCoordinateAddress(
              {
                lat: null,
                lng: null,
              },
              id,
              'accommodation'
            )
          : context.props.setCoordinateAddress(
              res.data.results[0].geometry.location,
              id,
              'accommodation'
            );
      });
  };
  render() {
    const { t } = this.props;
    let { mainPrograms, selectAccommodation } = this.state;
    let { loadingAccommodation, isVariableDate } = this.props;
    return (
      <div>
        <Segment style={styles.segment}>
          <Header as="h3" className="no-margin">
            <Icon className="fas fa-hotel fa-2x" style={styles.goldColor} />{' '}
            {/* Accommodation */}
            {t('accommodation')}
          </Header>
          {/* {mainPrograms.length !== 0 ? (
            
          ) : (
            ''
          )} */}
          <Loader active={loadingAccommodation} inline="centered" />
          {!loadingAccommodation &&
          selectAccommodation !== 0 &&
          mainPrograms.length !== 0 ? (
            <div>
              <span className="margin-bottom-five" style={styles.greyColor}>
                {/* Note : We will provide you these or similar hotels depending on
                availability */}
                {`${t('note')} : ${t('weWillProvideSimilarHotels')}`}
              </span>
              <AccommodationCarousel
                accommodations={selectAccommodation}
                mainPrograms={mainPrograms}
                getCoordinateFromAddress={this.getCoordinateFromAddress}
                isVariableDate={isVariableDate}
              />
            </div>
          ) : (
            !loadingAccommodation && (
              <Header as="h4" textAlign="center">
                {/* No Accommodation Ordered in this Package */}
                {t('noAccommodationOrdered')}
              </Header>
            )
          )}

          {/* <Loader
            active={this.props.loadingAccommodation}
            description="getting accommodations"
          /> */}
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    // fixedPackageById: state.fixedPackage.fixedPackageById,
    // getFixedPackageByIdStatus: state.fixedPackage.getFixedPackageByIdStatus,
    // loadingFixedPackage: state.fixedPackage.loading,
    getAccommodationProfileByIdStatus:
      state.accommodation.getAccommodationProfileByIdStatus,
    accommodationProfile: state.accommodation.accommodationProfile,
    loadingAccommodation: state.accommodation.loading,
  };
};
SegmentAccommodation.propTypes = {
  resetSelectAccommodation: PropTypes.func,
  selectAccommodationProfile: PropTypes.func,
  resetGetAccommodationProfileById: PropTypes.func,
  getAccommodationProfileById: PropTypes.func,
  accommodationProfile: PropTypes.object,
  getAccommodationProfileByIdStatus: PropTypes.string,
  loadingAccommodation: PropTypes.bool,
  mainPrograms: PropTypes.array,
  setCoordinateAddress: PropTypes.func,
  isVariableDate: PropTypes.bool,
  t: PropTypes.func,
};
// export default SegmentAccommodation;
// export default connect(mapStateToProps)(SegmentAccommodation);
export default connect(
  mapStateToProps,
  {
    getAccommodationProfileById,
    resetGetAccommodationProfileById,
    selectAccommodationProfile,
    resetSelectAccommodation,
    setCoordinateAddress,
  }
)(withTranslation()(SegmentAccommodation));
