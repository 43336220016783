import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Table,
  Button,
  Grid,
  Pagination,
  Segment,
} from 'semantic-ui-react';
import styles from './../styles';
import Input from '../../../components/input/WithLabel';
import {
  LoaderModal,
  ButtonCloseModal,
} from './../../shared/components/common';
import { useTranslation } from 'react-i18next';

const pagination = (data, perPage) => {
  let sets = [],
    chunks = data.length / perPage;
  for (let i = 0, j = 0; i < chunks; i++, j += perPage)
    sets[i] = data.slice(j, j + perPage);
  return sets;
};
const ModalListCompanies = props => {
  const { t } = useTranslation();
  let {
    isOpen,
    onClose,
    companies,
    handleButtonPick,
    handleChange,
    searchCompany,
    activePage,
    pageChange,
    loading,
  } = props;
  let pagedData = pagination(companies, 10);
  let listCompanies = pagedData[activePage - 1];
  return (
    <Modal open={isOpen} onClose={onClose} size="large" closeOnDimmerClick>
      <Modal.Header style={styles.backgroundModal}>
        {/* Showing List Company */}
        {t('showingListCompany')} <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={styles.backgroundModal}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Input
                type="text"
                placeholder={t('searchCompanyByName')} //"Search company by name"
                icon="search"
                iconPosition="left"
                inputStyles={styles.width100percent}
                handleChange={handleChange}
                value={searchCompany}
                name="searchCompany"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Table basic="very" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('code')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('name')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('address')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('telephone')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('action')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {listCompanies &&
                    listCompanies.map((company, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{company.Code}</Table.Cell>
                        <Table.Cell>{company.Name}</Table.Cell>
                        <Table.Cell>{company.Address}</Table.Cell>
                        <Table.Cell>{company.Telephone}</Table.Cell>
                        <Table.Cell>
                          <Button
                            style={styles.buttonGold}
                            value={{ code: company.Code, name: company.Name }}
                            onClick={handleButtonPick}
                            name="selectedCompany"
                          >
                            {t('pick')}
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column width={16}>
              {loading && (
                <Segment style={styles.segmentNoBorderTransparentMinHeight50px}>
                  <LoaderModal loading={loading} />
                </Segment>
              )}
            </Grid.Column>
            <Grid.Column
              textAlign="center"
              width={16}
              style={styles.paddingTop10px}
            >
              {!loading && pagedData && pagedData.length > 1 && (
                <Pagination
                  totalPages={pagedData.length}
                  onPageChange={pageChange}
                  activePage={activePage}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

ModalListCompanies.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  companies: PropTypes.array,
  handleButtonPick: PropTypes.func,
  handleChange: PropTypes.func,
  searchCompany: PropTypes.string,
  activePage: PropTypes.number,
  pageChange: PropTypes.func,
  loading: PropTypes.bool,
  t: PropTypes.func,
};

export default ModalListCompanies;
