const styles = {
  input: {
    width: '50%',
  },
  h4: {
    margin: '0',
    fontSize: '18px',
  },
  inputWidth100: {
    width: '100%',
  },
  marginTop50: {
    marginTop: '50px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  width70: {
    width: '70%',
  },
  label: {
    width: '30%',
    marginRight: '0px',
  },
  form: {
    marginTop: '50px',
    marginBottom: '10px',
  },
  buttonSubmit: {
    marginTop: '10px',
  },
};

export default styles;
