import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Button, Segment, Image, Grid } from 'semantic-ui-react';
import ResetPasswordForm from './form/ResetPasswordForm';
import { postResetPasswordApi } from '../../api/forgotPasswordApi';
import { BasicConfirmationModal } from '../shared/components/modal/';
import {
  handleValidationPassword,
  handleValidationConfirmPassword,
} from '../../genericFunction/validation';
import handlePassClickIcon from '../shared/services/helper/passIconClickHooks';
import styles from './styles';
import { touressLogoBlack } from '../../assets/logo/index';
import DynamicTitle from '../../components/common/DynamicTitle';

const ResetPassword = props => {
  const [resetPassData, setResetPassData] = useState({
    key: '',
    email: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [postOk, setPostOk] = useState(null);

  const [message, setMessage] = useState('');

  const [modalTitle, setModalTitle] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const [passIcon, setPassIcon] = useState({
    passwordIcon: 'eye slash',
    passwordInputType: 'password',
    confirmPasswordIcon: 'eye slash',
    confirmPasswordInputType: 'password',
    passwordShow: false,
    confirmPasswordShow: false,
  });

  const [backHome, setBackHome] = useState(false);

  const [validate, setValidate] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = props.match.params.token;
    const key = token
      .split('_')
      .join('/')
      .split('$')
      .join('+');
    //    const key2 = key.;
    const email = props.match.params.email;

    setResetPassData({
      ...resetPassData,
      key: key,
      email: email,
    });
    //eslint-disable-next-line
  }, []);

  const handleChange = (e, { name, value }) => {
    setResetPassData({ ...resetPassData, [name]: value });
    setValidate(true);
  };

  const onClickPassIcon = (e, { name }) => {
    handlePassClickIcon(name, passIcon, result => setPassIcon(result));
  };

  const onButtonModalClick = () => {
    if (postOk) {
      setOpenModal(false);
      setBackHome(true);
      setResetPassData({
        ...resetPassData,
        confirmNewPassword: '',
        newPassword: '',
      });
    } else {
      setOpenModal(false);
      setResetPassData({
        ...resetPassData,
        confirmNewPassword: '',
        newPassword: '',
      });
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    const data = {
      key: resetPassData.key,
      email: resetPassData.email,
      newPassword: resetPassData.newPassword,
      confirmPassword: resetPassData.confirmNewPassword,
    };

    postResetPasswordApi(data)
      .then(() => {
        setPostOk(true);
        setOpenModal(true);
        setMessage('Youre successfully reset your password!!!');
        setModalTitle('Reset password success');
        setLoading(false);
      })
      .catch(err => {
        setPostOk(false);
        setOpenModal(true);
        setMessage(err.response.data.Message);
        setModalTitle('Reset password failed');
        setLoading(false);
      });
  };

  let dataValidation = {
    newPassword: { error: false, message: '' },
    confirmNewPassword: { error: false, message: '' },
  };

  if (validate) {
    if (resetPassData.newPassword) {
      dataValidation = handleValidationPassword(
        resetPassData.newPassword,
        dataValidation,
        'newPassword'
      );
    }

    if (resetPassData.confirmNewPassword) {
      dataValidation = handleValidationConfirmPassword(
        resetPassData.confirmNewPassword,
        resetPassData.newPassword,
        dataValidation,
        'confirmNewPassword'
      );
    }
  }

  const handleBackHome = () => setBackHome(true);
  if (backHome) {
    // eslint-disable-next-line
    return <Redirect to="/" />;
  } else {
    return (
      <Container style={styles.width80}>
        <DynamicTitle title="Reset Password" />
        <Grid style={styles.marginTop20}>
          <Grid.Row>
            <Grid.Column width="4" style={styles.cursorPointer}>
              <Image src={touressLogoBlack} onClick={handleBackHome} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment textAlign="center" style={styles.segment}>
          <ResetPasswordForm
            handleChange={handleChange}
            password={resetPassData.newPassword}
            confirmPassword={resetPassData.confirmNewPassword}
            passwordIcon={passIcon.passwordIcon}
            confirmPasswordIcon={passIcon.confirmPasswordIcon}
            onClickPassIcon={onClickPassIcon}
            onClickConfPassIcon={onClickPassIcon}
            passwordInputType={passIcon.passwordInputType}
            confirmPasswordInputType={passIcon.confirmPasswordInputType}
            validate={dataValidation}
          />
          <Button
            onClick={handleSubmit}
            style={styles.buttonSubmit}
            loading={loading}
          >
            Submit
          </Button>
          <BasicConfirmationModal
            modalOpen={openModal}
            title={modalTitle}
            content={message}
            status={postOk}
            onClick={onButtonModalClick}
          />
        </Segment>
      </Container>
    );
  }
};

export default ResetPassword;
