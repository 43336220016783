const setBreadCrumb = packageType => {
  let title = '';
  let icon = '';
  let breadCrumb = [];
  switch (packageType) {
    case 'series':
      title = 'Series Package';
      icon = 'Fixed-Package-Icon-Circle-2x';
      breadCrumb = [
        {
          key: 'Home',
          content: 'Home',
          link: true,
          active: false,
          href: '/',
        },
        {
          key: 'Series Package',
          content: 'Series Package',
          link: false,
          active: true,
        },
      ];
      break;
    case 'ready':
      title = 'Ready Package';
      icon = 'Ready-Package-Icon-Circle-2x';
      breadCrumb = [
        {
          key: 'Home',
          content: 'Home',
          link: true,
          active: false,
          href: '/',
        },
        {
          key: 'Ready Package',
          content: 'Ready Package',
          link: false,
          active: true,
        },
      ];
      break;
    case 'promo':
      title = 'Promo Package';
      icon = 'Promo-Package-Icon-Circle-2x';
      breadCrumb = [
        {
          key: 'Home',
          content: 'Home',
          link: true,
          active: false,
          href: '/',
        },
        {
          key: 'Promo Package',
          content: 'Promo Package',
          link: false,
          active: true,
        },
      ];
      break;
    default:
      title = 'Tour Package';
      icon = '';
      breadCrumb = [
        {
          key: 'Home',
          content: 'Home',
          link: true,
          active: false,
          href: '/',
        },
        {
          key: 'Tour Package',
          content: 'Tour Package',
          link: false,
          active: true,
        },
      ];
      break;
  }
  return {
    packageType,
    title,
    icon,
    breadCrumb,
  };
};

export default setBreadCrumb;
