import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import {
  diffDate,
  convertDateFormat,
} from '../../../../../genericFunction/moment';
import {
  FREETIME,
  EAT,
  RECREATION,
  CHECKIN,
} from '../../../../../constant/activityTypes';
import { useTranslation } from 'react-i18next';

const MessageOverload = props => {
  const { t } = useTranslation();
  let { dateDay, dateActivity, movementName } = props;
  const isOverload =
    diffDate(
      convertDateFormat(dateDay, 'YYYY-MM-DD'),
      convertDateFormat(dateActivity, 'YYYY-MM-DD'),
      'days'
    ) !== 0;
  return isOverload &&
    [FREETIME, EAT, RECREATION].indexOf(movementName) !== -1 ? (
    <Message error>
      {/* Warning!! This activity is started on the next day. please arrange your
      activities or delete this activity and add on the next day. */}
      {t('warningActivityStartedOnNextDay')}
    </Message>
  ) : isOverload && movementName === CHECKIN ? (
    <Message error>
      {/* Warning!! Check in is started on the next day. please arrange your Check
      in on the next day. */}
      {t('warningCheckinStatedOnNextDay')}
    </Message>
  ) : null;
};
MessageOverload.propTypes = {
  dateDay: PropTypes.string,
  dateActivity: PropTypes.string,
  movementName: PropTypes.string,
  t: PropTypes.func,
};

export default MessageOverload;
