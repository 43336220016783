import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import AccommodationCard from './AccommodationCard';
// import ModalDetail from './../../modal/modalDetailAccommodation';
import ModalDetail from '../../modal/modalDetailAccommodation/ModalDetailAccommodation';
import styles from '../styles';

const NextArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' next'} onClick={onClick} />;
};
const PrevArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' prev'} onClick={onClick} />;
};
class AccommodationCarousel extends React.Component {
  state = {
    profile: {},
    openDetail: false,
  };
  handleButtonInfo = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: !this.state[name], profile: value });
    this.props.getCoordinateFromAddress(value.Address, value.Id);
  };
  handleClose = () => this.setState({ ...this.state, openDetail: false });
  render() {
    let { openDetail, profile } = this.state;
    const settings = {
      className: 'slider variable-width',
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    return (
      <div style={styles.marginLeft5px}>
        <Slider {...settings}>
          {this.props.mainPrograms.length > 0 &&
            this.props.mainPrograms.map((day, index) => {
              let data =
                this.props.accommodations &&
                this.props.accommodations.find(
                  e => day.AccommodationSummary.AccommodationProfileId === e.Id
                );
              return (
                <div key={index} style={styles.widthCard}>
                  <AccommodationCard
                    accommodation={data}
                    data={day}
                    handleButtonInfo={this.handleButtonInfo}
                    isVariableDate={this.props.isVariableDate}
                  />
                </div>
              );
            })}
        </Slider>
        <ModalDetail
          open={openDetail}
          onClose={this.handleClose}
          images={profile.ProfileImages ? profile.ProfileImages : []}
          image={profile.ImageUrl}
          name={profile.Name}
          address={profile.Address}
          rating={
            profile.AccommodationRating ? profile.AccommodationRating.Id : '0'
          }
          description={profile.Desc}
          facilities={profile.ProfileFacilities}
          location={profile.AccommodationLocations}
          type={profile.AccommodationType ? profile.AccommodationType.Name : ''}
          profile={profile}
        />
      </div>
    );
  }
}
NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
AccommodationCarousel.propTypes = {
  accommodations: PropTypes.array,
  mainPrograms: PropTypes.array,
  getCoordinateFromAddress: PropTypes.func,
  isVariableDate: PropTypes.bool,
};

export default AccommodationCarousel;
