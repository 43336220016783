import React, { useState, useEffect } from 'react';
import { Segment, Grid, Loader } from 'semantic-ui-react';
import { PriceDetail, OrderedItem } from '../../../shared/components/summary';
import {
  PaymentHistory,
  GuestList,
  GuestListModal,
  ModalItinerary,
  CardSummaryItinerary,
  NoteTravelAgent,
} from './';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { resetStatusTourTransactionSummary } from './../../../shared/actions/bookingAction';
import { useDispatch, useSelector } from 'react-redux';
import setCurrentPackage from '../../../shared/services/helper/setCurrentPackage';
import createTourPriceObj from '../../services/helper/createTourPriceObj';
import { initialAdditionalServices } from '../../../shared/services/additionalServices/processData';

const CardDetail = props => {
  //=========initial state=========
  const [tourPriceRoom, setTourPriceRoom] = useState([]);
  const [orderedItem, setOrderedItem] = useState([]);
  const [tourTransactionSummaryById, setTourTransactionSummaryById] = useState(
    {}
  );
  const [openModalGuest, setOpenModalGuest] = useState(false);
  const [openModalItinerary, setOpenModalItinerary] = useState(false);

  //=============action==========
  const dispatch = useDispatch();
  const resetStatusTourTransactionSummaryAction = () =>
    dispatch(resetStatusTourTransactionSummary());
  //============store===========
  const loadingSeeDetail = useSelector(state => state.booking.loadingSeeDetail);
  const tourTransactionSummary = useSelector(
    state => state.booking.tourTransactionSummary
  );
  const getTourTransactionSummaryStatus = useSelector(
    state => state.booking.getTourTransactionSummaryStatus
  );
  const countries = useSelector(state => state.countries.countries);
  const tourTransactionBills = useSelector(
    state => state.booking.tourTransactionBills
  );
  // const getTourTransactionBillsStatus = useSelector(
  //   state => state.booking.getTourTransactionBillsStatus
  // );

  //============lifecycle============
  useEffect(() => {
    if (getTourTransactionSummaryStatus === 'success') {
      let { id } = props;
      if (tourTransactionSummary.BookingDetailSum.Id === id) {
        convertTourPrice(tourTransactionSummary.TourPriceSum);
        convertOrderedItem(tourTransactionSummary.DailyPrograms);
        setTourTransactionSummaryById(tourTransactionSummary);
        resetStatusTourTransactionSummaryAction();
      }
    }
    // eslint-disable-next-line
  }, [getTourTransactionSummaryStatus]);

  const convertTourPrice = tourPriceSum => {
    let tourPriceRoom = createTourPriceObj(tourPriceSum);
    setTourPriceRoom(tourPriceRoom);
  };
  const convertOrderedItem = dailyPrograms => {
    let orderedItem = dailyPrograms
      .filter(e => e.OrderedItems.length !== 0)
      .map(daily => {
        return { day: daily.Day, date: daily.Date, items: daily.OrderedItems };
      });
    setOrderedItem(orderedItem);
  };

  const handleButtonGuest = () => setOpenModalGuest(!openModalGuest);
  const handleButtonItinerary = () =>
    setOpenModalItinerary(!openModalItinerary);
  let { packageType } = props;
  // let {
  //   tourPriceRoom,
  //   tourTransactionSummaryById,
  //   orderedItem,
  //   openModalGuest,
  //   openModalItinerary,
  // } = this.state;
  let currency = tourTransactionSummaryById
    ? tourTransactionSummaryById.BookingDetailSum
      ? tourTransactionSummaryById.BookingDetailSum.CurrencyId
      : ''
    : '';
  let tourTotalPrice = tourTransactionSummaryById
    ? tourTransactionSummaryById.BookingDetailSum
      ? tourTransactionSummaryById.BookingDetailSum.TourTotalPrice
      : 0
    : 0;
  let fixedPackage = tourTransactionSummaryById
    ? tourTransactionSummaryById.BookingDetailSum
      ? tourTransactionSummaryById.BookingDetailSum.FixedPackage
      : {}
    : {};
  let commission = fixedPackage
    ? fixedPackage.BookingCommission
      ? fixedPackage.BookingCommission.ApplicableCommission
      : []
    : [];
  let totalPayable = fixedPackage
    ? fixedPackage.BookingCommission
      ? fixedPackage.BookingCommission.TotalPriceForCustomer
      : 0
    : 0;
  let listTourGuest = tourTransactionSummaryById
    ? tourTransactionSummaryById.TourGuestSum
    : [];
  let departure = tourTransactionSummaryById.HeadlineProgram
    ? tourTransactionSummaryById.HeadlineProgram.Departures
    : [];
  let returns = tourTransactionSummaryById.HeadlineProgram
    ? tourTransactionSummaryById.HeadlineProgram.Returns
    : [];
  let dataSummaryItinerary = tourTransactionSummaryById.HeadlineProgram
    ? tourTransactionSummaryById.HeadlineProgram.MainPrograms
    : [];
  let dailyPrograms = tourTransactionSummaryById.DailyPrograms
    ? tourTransactionSummaryById.DailyPrograms
    : [];
  let FoC = tourTransactionSummaryById.BookingDetailSum
    ? tourTransactionSummaryById.BookingDetailSum.FoC
    : null;
  let currentPackage = tourTransactionSummaryById.BookingDetailSum
    ? setCurrentPackage(tourTransactionSummaryById.BookingDetailSum.PackageType)
    : 'FIXED';
  let tourNote = tourTransactionSummaryById
    ? tourTransactionSummaryById.TourNote
      ? tourTransactionSummaryById.TourNote
      : ''
    : '';
  let additionalServiceList = initialAdditionalServices(
    tourTransactionSummaryById.AdditionalServices || []
  );
  return (
    <Segment style={styles.segementDetail}>
      <Loader
        active={loadingSeeDetail && tourPriceRoom.length === 0}
        inline="centered"
      />
      {tourPriceRoom.length !== 0 && (
        <Grid>
          <PaymentHistory
            listPaymentTerm={tourTransactionBills}
            currency={currency}
          />
          <PriceDetail
            tourPriceRoom={tourPriceRoom}
            additionalItem={tourTransactionSummaryById.TransactionSupplements}
            currency={currency}
            tourTotalPrice={tourTotalPrice}
            commission={commission}
            totalPayable={totalPayable}
            packageType={packageType}
            FoC={FoC}
            additionalServices={additionalServiceList}
          />
          <GuestList
            list={listTourGuest}
            handleButton={handleButtonGuest}
            countries={countries}
          />
          <OrderedItem orderedItem={orderedItem} />
          <NoteTravelAgent tourNote={tourNote} />
          <CardSummaryItinerary
            departure={departure}
            returns={returns}
            dataSummaryItinerary={dataSummaryItinerary}
            handleButton={handleButtonItinerary}
          />
          <GuestListModal
            list={listTourGuest}
            isOpen={openModalGuest}
            handleButton={handleButtonGuest}
            countries={countries}
          />
          <ModalItinerary
            dailyPrograms={dailyPrograms}
            isOpen={openModalItinerary}
            handleButton={handleButtonItinerary}
            currentPackage={currentPackage}
          />
        </Grid>
      )}
    </Segment>
  );
};

CardDetail.propTypes = {
  openDetail: PropTypes.bool,
  id: PropTypes.number,
  resetStatusTourTransactionSummary: PropTypes.func,
  loadingSeeDetail: PropTypes.bool,
  tourTransactionSummaryById: PropTypes.object,
  getTourTransactionSummaryStatus: PropTypes.string,
  countries: PropTypes.array,
  packageType: PropTypes.string,
  getTourTransactionBillsStatus: PropTypes.string,
  tourTransactionBills: PropTypes.array,
};

export default CardDetail;
