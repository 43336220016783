import React from 'react';
import { Collapse } from 'react-collapse';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';

const messageErrors = props => {
  let { isErrors, listErrors } = props;
  return (
    <Collapse isOpened={isErrors}>
      <Message error style={styles.error} list={listErrors} />
    </Collapse>
  );
};

messageErrors.propTypes = {
  isErrors: PropTypes.bool,
  listErrors: PropTypes.array,
};
export default messageErrors;
