import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header, Icon, Grid, Popup, Label } from 'semantic-ui-react';
import styles from './styles';
const iconFacilitiesAccommodation = [
  { key: 'AC', icon: 'fas fa-snowflake fa-2x', text: 'AC', width: 2 },
  { key: 'WIFI', icon: 'fas fa-wifi fa-2x', text: 'WiFi', width: 2 },
  {
    key: 'SWIMMINGPOOL',
    icon: 'fas fa-swimming-pool fa-2x',
    text: 'Swimming Pool',
    width: 2,
  },
  {
    key: 'PARKING',
    icon: 'fas fa-parking fa-2x',
    text: 'Parking',
    width: 2,
  },
  {
    key: 'RESTAURANT',
    icon: 'fas fa-utensils fa-2x',
    text: 'Restaurant',
    width: 2,
  },
];

class facilities extends Component {
  static propTypes = {
    facilities: PropTypes.array,
    header: PropTypes.string,
    isIconWidthTrue: PropTypes.bool,
  };

  filterIcon = listIcon => {
    let { facilities } = this.props;
    return listIcon.filter(icon => {
      let { key } = icon;
      return facilities.findIndex(e => e.Id.indexOf(key) !== -1) !== -1;
    });
  };
  render() {
    let { header, isIconWidthTrue } = this.props;
    let listIcon = this.filterIcon(iconFacilitiesAccommodation);
    return (
      <Grid>
        <Grid.Row style={styles.paddingTop3left5}>
          <Grid.Column width="16">
            <Header as="h5">{header}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.paddingTop3left15} columns="5">
          {listIcon.map(icon => (
            <Grid.Column
              key={icon.key}
              width={isIconWidthTrue ? icon.width : ''}
              style={styles.margin03}
            >
              <Popup
                trigger={
                  <Label basic style={styles.borderNone}>
                    <Icon className={icon.icon} style={styles.goldColor} />
                  </Label>
                }
                content={icon.text}
                on="hover"
              />
              {/* <Icon className={icon.icon} style={{ color: '#e6ca6b' }} /> */}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    );
  }
}
export default facilities;
