import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Image } from 'semantic-ui-react';
import { phone } from '../../../../assets/image';
import {
  googlePlayBadge,
  appStoreBadgeBlack,
} from '../../../../assets/apps badges';
import { appStoreUrl, playStoreUrl } from '../../../../constant/appStoreUrl';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const MobileAppsPromotion = () => {
  const { t } = useTranslation();
  return (
    <Container className="padding-side-twentyfive container-fit">
      <Grid style={styles.marginLeftRight20}>
        <Grid.Row>
          <Grid.Column verticalAlign="middle" width={12}>
            <h1 style={styles.font36}>
              {/* Design and organize your best travels with touress */}
              {t('designAndOrganize')}
            </h1>
            <p className="font24">
              {/* Find travel packages fit with your customer. */}
              {t('designAndOrganizeContent1')}
              <br />
              {/* You can set yourself where you are going, date and time. */}
              {t('designAndOrganizeContent2')}
            </p>
            <h3 className="font24" style={styles.textDownloadApps}>
              {/* Download Apps Now */}
              {t('downloadAppsNow')}
            </h3>
            <Image.Group>
              <Image
                as="a"
                href={playStoreUrl}
                target="_blank"
                src={googlePlayBadge}
                size="medium"
              />
              <Image>
                <a href={appStoreUrl} target="_blank" rel="noreferrer noopener">
                  <img
                    src={appStoreBadgeBlack}
                    style={styles.height78}
                    alt="appStore"
                  />
                </a>
              </Image>
            </Image.Group>
          </Grid.Column>
          <Grid.Column width={4}>
            <Image src={phone} size="medium" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

MobileAppsPromotion.propTypes = {
  t: PropTypes.func,
};

export default MobileAppsPromotion;
