const BLACK_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';

const style = {
  segment: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '0px',
    margin: '5px 10px 20px 10px',
  },
  backgroundPrimary: {
    background: BLACK_COLOR,
  },
  backgroundWhite: {
    background: WHITE_COLOR,
    height: '100%',
  },

  colorWhite: {
    color: WHITE_COLOR,
  },
  roundShape: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '60px',
    height: '60px',
    backgroundColor: WHITE_COLOR,
    border: 'solid 4px',
    borderRadius: '50%',
    position: 'absolute',
    // top: '-6px',
    left: '-30px',
    marginTop: '115px',
    color: GOLD_COLOR,
    textAlign: 'center',
    paddingTop: '10px',
  },
  centerAlignIcon: {
    textAlign: 'center',
    marginTop: '15px',
    marginLeft: '100px',
  },
  centerAlign: {
    textAlign: 'center',
    // marginLeft: '100px',
  },
  centerAlignGold: {
    color: GOLD_COLOR,
    textAlign: 'center',
    marginTop: '120px',
  },
  button: {
    background: GOLD_COLOR,
    paddingLeft: '45px',
    paddingRight: '45px',
    marginBottom: '10px',
    marginTop: '0px',
  },
  buttonGetStarted: {
    background: GOLD_COLOR,

    marginTop: '0px',
  },
  quickLaunchContainerStyle: {
    marginBottom: '15px',
    position: 'relative',
    top: '5px',
  },
  noMarginTop: { marginTop: 0 },
  noPadding: { padding: '0px' },
  marginLeft: { marginLeft: '20px' },
  quickLaunchSecondColumnStyle: { paddingLeft: '0px', height: '100%' },
  floatRight: { float: 'right' },
  textCenterMarginBotFive: {
    textAlign: 'center',
    marginBottom: '5px',
    marginLeft: '100px',
  },
  cardFilterPackage: {
    width: '896px',
    height: '281px',
  },
  cardCreateCustomPackage: {
    width: '297px',
    height: '281px',
  },
  formFilterStyle: {
    width: '578px',
    paddingRight: '40px',
  },
  shortCutStyle: {
    width: '380px',
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  noPaddingBot: {
    paddingBottom: 0,
  },
  marginTop25: {
    marginTop: '25px',
  },
};

export default style;
