import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  RECREATION,
  FREETIME,
  EAT,
} from '../../../../../constant/activityTypes';
import { Dropdown, Icon } from 'semantic-ui-react';
import { FIXED } from '../../../../../constant/packageTypes';
import { withTranslation } from 'react-i18next';
class TimeLineAddActivity extends React.Component {
  openModal = (e, { name }) => {
    this.props.openModalSetActivity(
      name,
      this.props.activity,
      this.props.movements,
      this.props.index,
      this.props.day,
      'ADD'
    );
  };
  render() {
    const { t } = this.props;
    return (
      this.props.currentPackage !== FIXED &&
      !this.props.isStatic && (
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={styles.timeLineAddActivityIcon}
          position="right"
          icon={<Icon name="plus" style={styles.iconPlusStyle} />}
          style={styles.noMargin}
        >
          <Dropdown
            text={t('addSomeActivities')} // "Add Some Activities"
            pointing
            button
            style={styles.addActivitiesStyle}
          >
            <Dropdown.Menu>
              <Dropdown.Item name={RECREATION} onClick={this.openModal}>
                {/* Add Excursion */}
                {t('addExcursion')}
              </Dropdown.Item>
              <Dropdown.Item name={EAT} onClick={this.openModal}>
                {/* Add Meal */}
                {t('addMeal')}
              </Dropdown.Item>
              <Dropdown.Item name={FREETIME} onClick={this.openModal}>
                {/* Add Free Time */}
                {t('addFreeTime')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </VerticalTimelineElement>
      )
    );
  }
}
TimeLineAddActivity.propTypes = {
  activity: PropTypes.object,
  DateTime: PropTypes.string,
  openModalSetActivity: PropTypes.func,
  movements: PropTypes.array,
  index: PropTypes.number,
  day: PropTypes.number,
  currentPackage: PropTypes.string,
  isStatic: PropTypes.bool,
  t: PropTypes.func,
};
export default withTranslation()(TimeLineAddActivity);
