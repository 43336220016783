import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container, Pagination, Card, Grid, Loader } from 'semantic-ui-react';
import PackageCard from '../../../shared/components/tourPackageCard/TourPackageCard'; //'./../home/tourPackage/packageCard';
import CustomPackageShorcut from '../../../../components/common/customPackageShortcut';
import FilterAndShorting from '../filter/FilterAndShortingTourPackage';
import Input from '../../../../components/input/WithLabel';
import { getTotalDays, sortDate } from '../../../../genericFunction/moment';
import styles from './styles';
// import sortingOptions from '../../constant/sortingOptions';
import { useTranslation } from 'react-i18next';

const CardList = props => {
  const { t } = useTranslation();
  const {
    packageType,
    // listFormFilter,
    fixPackageList,
    readyPackageList,
    // countries,
    rangePrice,
    handleChangeTab,
    tab,
    similarFixPackageList,
    similarReadyPackageList,
  } = props;

  const [state, setState] = useState({
    searchInput: '',
    searchName: '',
    activePage: 1,
    activeSort: '',
  });
  const loadingReadyPackage = useSelector(state => state.readyPackage.loading);
  const loadingFixedPackage = useSelector(state => state.fixedPackage.loading);
  useEffect(() => {
    setState({ ...state, activePage: 1 });
    // eslint-disable-next-line
  }, [packageType]);
  // shouldComponentUpdate = nextProps => {
  //   if (this.props.packageType !== nextProps.packageType) {
  //     this.setState({ ...this.state, activePage: 1 });
  //     return false;
  //   }
  //   return true;
  // };
  const handleChangeTabView = (e, { value }) => {
    setState({ ...state, activePage: 1 });
    handleChangeTab(value);
  };
  const handleChange = (e, { name, value }) =>
    setState({ ...state, [name]: value, activePage: 1 });

  const pagination = (data, perPage) => {
    let sets = [],
      chunks = data.length / perPage;

    for (var i = 0, j = 0; i < chunks; i++, j += perPage)
      sets[i] = data.slice(j, j + perPage);

    return sets;
  };

  const filteredData = (fixPackageList, readyPackageList) => {
    let { listTourType, listDuration } = props.listFormFilter;
    let { searchName, searchInput } = state;
    let dataFilter, data;
    packageType === 'series'
      ? (data = fixPackageList)
      : packageType === 'ready'
      ? (data = readyPackageList)
      : packageType === 'promo'
      ? (data = [])
      : tab === 'Series'
      ? (data = fixPackageList)
      : (data = readyPackageList);

    return data
      ? data.filter(data => {
          let { TourCategory, Title } = data;
          let totalDays = getTotalDays(data.StartDate, data.EndDate);
          if (packageType === 'tour') {
            dataFilter =
              Title.toLowerCase().indexOf(searchName.toLowerCase()) !== -1;
          } else {
            dataFilter =
              Title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1;
          }
          if (data.PackageType === 'Fixed') {
            dataFilter =
              dataFilter &&
              rangePrice[0] <= data.SharingRoomPrice &&
              data.SharingRoomPrice <= rangePrice[1];
          }
          if (listTourType.some(e => e.checked)) {
            dataFilter =
              dataFilter &&
              listTourType.findIndex(
                e => e.key === TourCategory.Id && e.checked
              ) !== -1;
          }

          if (listDuration.some(e => e.checked)) {
            if (totalDays >= 13) {
              dataFilter =
                dataFilter &&
                listDuration.findIndex(
                  e => e.desc.indexOf(13) !== -1 && e.checked
                );
            }
            dataFilter =
              dataFilter &&
              listDuration.findIndex(
                e => e.desc.indexOf(parseInt(totalDays, 10)) !== -1 && e.checked
              ) !== -1;
          }

          return dataFilter;
        })
      : [];
  };
  const pageChange = (e, { activePage }) => {
    setState({ ...state, activePage });
  };

  const compare = (a, b) => {
    let comparison = 0;

    if (a.LowestPrice > b.LowestPrice) {
      comparison = 1;
    } else if (b.LowestPrice > a.LowestPrice) {
      comparison = -1;
    }
    return comparison;
  };
  const sortData = (listPackage, type) => {
    return type === 'lowest price'
      ? listPackage.sort(compare)
      : type === 'highest price'
      ? listPackage.sort(compare).reverse()
      : type === 'earlier tour'
      ? sortDate(listPackage, 'StartDate')
      : type === 'lates tour'
      ? sortDate(listPackage, 'StartDate').reverse()
      : listPackage;
  };

  // let {
  //   packageType,
  //   fixPackageList,
  //   readyPackageList,
  //   loadingReadyPackage,
  //   loadingFixedPackage,
  //   similarFixPackageList,
  //   similarReadyPackageList,
  //   tab,
  // } = this.props;

  let filterData = filteredData(fixPackageList, readyPackageList);
  filterData = sortData(filterData, state.activeSort);
  let pagedData = pagination(filterData, 6);
  let listData = pagedData[state.activePage - 1];
  let loading = loadingFixedPackage || loadingReadyPackage;
  let authToken = localStorage.getItem('token');
  const isFixedPackageMode =
    packageType === 'tour' ? tab === 'Series' : packageType === 'series';
  const showSimilar = isFixedPackageMode
    ? similarFixPackageList
    : similarReadyPackageList;

  const optionSort = t('sortingOptionsTourPackage', { returnObjects: true });

  return (
    <div>
      <Container>
        <Grid>
          {packageType === 'tour' ? (
            <FilterAndShorting
              handleChange={handleChange}
              activeTab={tab}
              searchInput={state.searchName}
              activeSort={state.activeSort}
              options={optionSort} //sortingOptions
              handleChangeTab={handleChangeTabView}
            />
          ) : (
            <Grid.Row style={{ paddingBottom: 0 }}>
              <Grid.Column width={8}>
                <Card color="grey" fluid style={styles.segmentNoPadding}>
                  <Card.Content>
                    <Input
                      name="searchInput"
                      label={t('filterByName')} //"Filter By Name"
                      type="text"
                      labelStyles={styles.textBlackBold}
                      inputStyles={styles.selectTransparent}
                      placeholder={t('filterbyNamePackage')} //"Filter by Name Package"
                      handleChange={handleChange}
                      value={state.searchInput}
                    />
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column width={8}>
                <Card color="grey" fluid style={styles.segmentNoPadding}>
                  <Card.Content>
                    <Input
                      name="activeSort"
                      label={t('sortBy')} //"Sort by"
                      type="select"
                      labelStyles={styles.textBlackBold}
                      inputStyles={styles.selectTransparent}
                      placeholder={t('selectSomething')} //"Select something"
                      options={optionSort} //sortingOptions
                      handleChange={handleChange}
                      value={state.activeSort}
                    />
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row style={styles.paddingBottomTopZero}>
            <Grid.Column>
              <CustomPackageShorcut />
            </Grid.Column>
          </Grid.Row>
          {filterData && (
            <Grid.Row style={styles.paddingTopBottom10px}>
              <Grid.Column>
                <Loader active={loading} inline="centered" />
                {loading || (
                  <h3 style={{ margin: 0 }}>
                    {filterData.length !== 0
                      ? `${filterData.length} ${t('packagesFound')} `
                      : `${t('noPackagesFound')} `}
                    {/* No Package Found */}
                  </h3>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>

        <div>
          <Grid columns={3}>
            {!loading &&
              listData &&
              listData.map(data => {
                return (
                  <PackageCard key={data.Id} item={data} token={authToken} />
                );
              })}
          </Grid>
        </div>
        <div style={styles.divPagination}>
          {!loading && pagedData && pagedData.length > 1 && (
            <Pagination
              totalPages={pagedData.length}
              onPageChange={pageChange}
              activePage={state.activePage}
            />
          )}
        </div>
        <div style={styles.textSimilarPackage}>
          {!loading && showSimilar && showSimilar.length !== 0 && (
            // Similar packages that you want to visit
            <h3>{t('similarPackagesThatYouWantToVisit')}</h3>
          )}
        </div>
        <div>
          <Grid columns={3}>
            {!loading &&
              showSimilar &&
              showSimilar.map(data => {
                return (
                  // <PackageCard key={data.Id} package={data} token={authToken} />
                  <PackageCard key={data.Id} item={data} token={authToken} />
                );
              })}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

CardList.propTypes = {
  fixPackageList: PropTypes.array,
  readyPackageList: PropTypes.array,
  listFormFilter: PropTypes.object,
  packageType: PropTypes.string,
  loadingFixedPackage: PropTypes.bool,
  loadingReadyPackage: PropTypes.bool,
  countries: PropTypes.array,
  rangePrice: PropTypes.array,
  handleChangeTab: PropTypes.func,
  tab: PropTypes.string,
  similarFixPackageList: PropTypes.array,
  similarReadyPackageList: PropTypes.array,
  t: PropTypes.func,
};

export default CardList;
