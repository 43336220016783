const WHITE_COLOR = '#FFF';
const RED_COLOR = '#f44336';
const styles = {
  buttonClose: {
    background: WHITE_COLOR,
    color: RED_COLOR,
    padding: '0px',
    margin: '0px',
  },
  goldRibbon: {
    color: 'white',
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  },
  redColor: {
    color: RED_COLOR,
  },
};
export default styles;
