const style = {
  pointer: {
    pointer: 'cursor',
  },
  imageSize: {
    width: '280px',
    height: '350px',
  },
  headerStyle: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '900',
  },
  marginLeftRight20: { marginLeft: '20px', marginRight: '20px' },
  font20: { fontSize: '20px' },
  textCenter: { textAlign: 'center' },
  minHeight175: { minHeight: '175px' },
  imageStyle: {
    // minHeight: '175x',
    // marginLeft: '20px',
    // marginRight: '20px',
    width: '450px',
    height: '175px',
    marginLeft: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};

export default style;
