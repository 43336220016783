import {
  GET_READY_PACKAGE_ALL,
  RESET_GET_READY_PACKAGE_ALL_STATUS,
  GET_READY_PACKAGE_BY_ID,
  RESET_GET_READY_PACKAGE_BY_ID_STATUS,
} from './actionTypes';
import {
  getReadyPackageAllApi,
  getReadyPackageByIdApi,
} from '../../../api/readyPackageApi';

const getReadyPackageAll = data => {
  return dispatch => {
    return dispatch({
      type: GET_READY_PACKAGE_ALL,
      payload: getReadyPackageAllApi(data),
    });
  };
};

const resetGetReadyPackageAllStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_READY_PACKAGE_ALL_STATUS });
  };
};

const getReadyPackageById = id => {
  return dispatch => {
    return dispatch({
      type: GET_READY_PACKAGE_BY_ID,
      payload: getReadyPackageByIdApi(id),
    });
  };
};

const resetGetReadyPackageByIdStatus = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_READY_PACKAGE_BY_ID_STATUS });
  };
};

export {
  getReadyPackageAll,
  resetGetReadyPackageAllStatus,
  getReadyPackageById,
  resetGetReadyPackageByIdStatus,
};
