import React from 'react';
import { Table, Image, Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import { noImage } from '../../../../../assets/image';
import { formatCurrency } from '../../../../../genericFunction/numberConverter';
import { useTranslation } from 'react-i18next';

const TourOperatorPanel = props => {
  const { t } = useTranslation();
  const { data, profile, noPrice, isFixPrice } = props;
  return (
    <div>
      <div style={styles.textCenter}>
        <Header as="h3">{data ? data.TourProfileName : '-'}</Header>
      </div>
      <Grid>
        <Grid.Column width={4}>
          <Image
            src={
              profile
                ? profile.ImageUrl
                  ? profile.ImageUrl
                  : noImage
                : noImage
            }
            size="small"
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4} style={styles.paddingBottomZeroNoBorder}>
                  {/* Address */}
                  {t('address')}
                </Table.Cell>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  : {profile ? profile.Address : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4} style={styles.paddingBottomZeroNoBorder}>
                  {/* Email */}
                  {t('email')}
                </Table.Cell>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  : {profile ? profile.Email : '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4} style={styles.paddingBottomZeroNoBorder}>
                  {t('telephone')}
                  {/* Telephone */}
                </Table.Cell>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  : {profile ? profile.Telephone : '-'}
                </Table.Cell>
              </Table.Row>
              {!isFixPrice
                ? noPrice || (
                    <Table.Row>
                      <Table.Cell
                        width={4}
                        style={styles.paddingBottomZeroNoBorder}
                      >
                        {t('priceOffer')}
                        {/* Price Offer */}
                      </Table.Cell>
                      <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                        :{' '}
                        {data
                          ? `${data.CurrencyId} ${formatCurrency(
                              data.TotalPrice
                            )}`
                          : `-`}
                      </Table.Cell>
                    </Table.Row>
                  )
                : null}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </div>
  );
};

TourOperatorPanel.propTypes = {
  ImageUrl: PropTypes.string,
  data: PropTypes.object,
  profile: PropTypes.object,
  noPrice: PropTypes.bool,
  isFixPrice: PropTypes.bool,
};

export default TourOperatorPanel;
