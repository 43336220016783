const BACKGROUND_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
// const WHITE_COLOR = '#fff';

const style = {
  backgroundPrimary: {
    background: BACKGROUND_COLOR,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  featureMiniIcon: {
    margin: '0px 5px 5px 5px',
  },
  featureItemText: { marginTop: '30px', color: 'white' },
  noMarginTop: {
    marginTop: '0px',
  },
  noPadding: {
    padding: '0px',
  },
  marginLeft10: { marginLeft: '10px' },
  marginLeft16: { marginLeft: '16px', marginRight: '-5px' },
  slider1CarouselSection: {
    width: '100%',
    height: '282px',
    backgroundImage: '#BFCAC4',
  },
  slider2CarouselSection: {
    width: '100%',
    height: '458px',
  },
  navigationStyle: { height: '100%', right: '0%', zIndex: 1 },
  arrowNavPrevStyle: { height: '100%', left: '0%', zIndex: 1 },
  arrowNavNextStyle: { height: '100%', right: '0%', zIndex: 1 },
  height85: { height: '85px' },
  maxHeightWidth55: { maxHeight: '55px', maxWidth: '55px' },
  maxWidth283: { maxWidth: '283px' },
};

export default style;
