import React, { PureComponent } from 'react';
import { Form, Grid, Modal, Button } from 'semantic-ui-react';
import styles from '../styles';
import PropTypes from 'prop-types';
import InputWithLabel from '../../../components/input/WithLabel';
import { connect } from 'react-redux';
import { ButtonCloseModal } from '../../shared/components/common';
import { withTranslation } from 'react-i18next';

class ModalUserCompanyList extends PureComponent {
  static propTypes = {
    openModal: PropTypes.bool,
    open: PropTypes.func,
    onClose: PropTypes.func,
    name: PropTypes.string,
    companyProfile: PropTypes.object,
    handleChange: PropTypes.func,
    companyUserState: PropTypes.object,
    handleCreateCompanyUser: PropTypes.func,
    validate: PropTypes.object,
    t: PropTypes.func,
  };

  render() {
    const { t } = this.props;
    let {
      onClose,
      name,
      openModal,
      companyProfile,
      companyUserState,
      validate,
    } = this.props;
    return (
      <Modal open={openModal} onClose={onClose} size="small">
        <Modal.Header style={styles.backgroundModal}>
          {name} <ButtonCloseModal onClose={onClose} />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <InputWithLabel
                type="text"
                label={t('companyName')} //"Company Name"
                name={t('companyName')} //"CompanyName"
                value={companyProfile.CompanyName}
                handleChange={this.props.handleChange}
                placeholder="Company Name"
                disabled
                required
              />
            </Form.Field>

            <Form.Field required>
              <Grid>
                <Grid.Column width={8}>
                  <label>
                    <InputWithLabel
                      type="text"
                      label={t('firstName')} //"First Name"
                      name="Firstname"
                      value={companyUserState.Firstname}
                      handleChange={this.props.handleChange}
                      placeholder={t('firstName')} //"First Name"
                      required
                      validate={validate}
                    />
                  </label>
                </Grid.Column>
                <Grid.Column width={8}>
                  <InputWithLabel
                    type="text"
                    label={t('lastName')} //"Last Name"
                    name="Lastname"
                    value={companyUserState.Lastname}
                    handleChange={this.props.handleChange}
                    placeholder={t('lastName')} //"Last Name"
                    required
                    validate={validate}
                  />
                </Grid.Column>
              </Grid>
            </Form.Field>
            <Form.Field>
              <InputWithLabel
                type="text"
                label={t('email')} //"Email"
                name="Email"
                value={companyUserState.Email}
                handleChange={this.props.handleChange}
                placeholder={t('email')} //"Email"
                required
                validate={validate}
              />
              <Form.Field>
                <InputWithLabel
                  type="text"
                  label={t('username')} //"Username"
                  name="Username"
                  value={companyUserState.Username}
                  handleChange={this.props.handleChange}
                  placeholder={t('username')} //"Username"
                  required
                  validate={validate}
                />
              </Form.Field>
              <Form.Field>
                <InputWithLabel
                  type="password"
                  label={t('password')} //"Password"
                  name="Password"
                  value={companyUserState.Password}
                  handleChange={this.props.handleChange}
                  placeholder={t('password')} //"Password"
                  required
                  validate={validate}
                />
              </Form.Field>
              <Form.Field>
                <InputWithLabel
                  type="password"
                  label={t('confirmPassword')} //"Confirm Password"
                  name="ConfirmPassword"
                  value={companyUserState.ConfirmPassword}
                  handleChange={this.props.handleChange}
                  placeholder={t('confirmPassword')} //"Confirm Password"
                  required
                  validate={validate}
                />
              </Form.Field>
            </Form.Field>
            <div className="ui center aligned container">
              <Button
                onClick={this.props.handleCreateCompanyUser}
                content={t('createNewUser')} //"Create New User"
                style={styles.buttonGoldUser}
              />
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    login_status: state.authentication.login_status,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(ModalUserCompanyList));
