const GOLD_COLOR = '#e6ca6b';

const style = {
  whiteGoldBorderStyle: {
    background: 'white',
    border: `1px solid ${GOLD_COLOR}`,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 6px',
  },
  mapImageStyle: {
    // marginLeft: '5px',
    cursor: 'pointer',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    marginBottom: '10px',
  },
  noPadding: { padding: 0 },
  textCenter: { textAlign: 'center' },
  marginBot4: { marginBottom: '4px' },
  markerStyle: { color: '#EA4335', fontSize: '26px' },
};
export default style;
