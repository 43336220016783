const BLACK_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';
// const GREY_COLOR = '#f2f2f2';

const style = {
  backgroundModal: {
    backgroundColor: '#fbfbfb',
  },
  backgroundCard: {
    backgroundColor: WHITE_COLOR,
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  blackColor: {
    color: BLACK_COLOR,
  },
  greyColor: {
    color: '#d3d3d3',
  },
  message: {
    border: '0.3px solid #e6ca6b',
    backgroundColor: 'white',
    color: 'black',
  },
  messageDisable: {
    border: '0.5px solid #d3d3d3',
    backgroundColor: 'white',
    color: 'black',
  },
  //   boldGoldColor: {
  //     color: GOLD_COLOR,
  //     fontWeight: 'bold',
  //   },
  //   justify: {
  //     textAlign: 'justify',
  //   },
  //   labelGold: {
  //     borderColor: '#b2993d',
  //     background: 'linear-gradient(to right, #fffd9b, #b2993d)',
  //     color: 'white',
  //     paddingBottom: '5px',
  //     paddingTop: '5px',
  //   },
  buttonGold: {
    background: GOLD_COLOR,
    color: WHITE_COLOR,
  },
  buttonGoldColorBlack: {
    background: GOLD_COLOR,
    color: BLACK_COLOR,
  },
  transparentButton: {
    backgroundColor: WHITE_COLOR,
    color: GOLD_COLOR,
    padding: '0px',
  },
  buttonChooseRoom: {
    backgroundColor: GOLD_COLOR,
    color: BLACK_COLOR,
    padding: '10px',
  },
  buttonChooseRoomDisable: {
    backgroundColor: '#d3d3d3',
    color: '#252525',
    padding: '10px',
  },
  //   imageAttachment: {
  //     backgroundColor: BLACK_COLOR,
  //     opacity: 0.5,
  //   },
  //   inputStyles: {
  //     width: '100%',
  //     padding: '9px',
  //     borderBottom: '2px solid lightgrey',
  //   },
  // noMarginTop: {
  //   marginTop: '0px',
  // },
  noMarginBottom: {
    marginBottom: '0px',
  },
  paddingLeftRight15px: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  marginBottomfifteen: { marginBottom: '15px' },
  noPaddingTop: { paddingTop: '0px' },
  noPaddingBottom: {
    paddingBottom: '0px',
  },
  noPaddingTopBottom: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  paddingthree: {
    padding: '3px',
  },
  labelGold: {
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
    color: 'white',
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  imageAttachment: {
    backgroundColor: BLACK_COLOR,
    opacity: 0.5,
  },
  minHeight50px: {
    minHeight: '50px',
  },
  noBorder: {
    border: 'none',
  },
  marginTopBottom3pxLeft3px: {
    margin: '3px 3px 0px 3px',
  },
  borderNonePaddingLeftRight: {
    border: 'none',
    padding: '0px 20px',
  },
  marginBottom3px: {
    marginBottom: '3px',
  },
  marginTop10px: {
    marginTop: '10px',
  },
  border03pxSolid: {
    border: '0.3px solid #e6ca6b',
  },
  bgGoldcolorWhite: {
    backgroundColor: GOLD_COLOR,
    color: 'white',
  },
  marginTop10pxMarginBottom10px: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
};
export default style;
