export const searchFilterAdditionalService = (data, searchInput) => {
  return data.filter(item => {
    return (
      (item.AdditionalServicesName !== null &&
        item.AdditionalServicesName.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1) ||
      (item.AdditionalServiceType.TypeName !== null &&
        item.AdditionalServiceType.TypeName.toLowerCase().indexOf(
          searchInput.toLowerCase()
        ) !== -1)
      // (item.AdditionalServiceType.Text !== null &&
      //   item.AdditionalServiceType.Text.toLowerCase().indexOf(
      //     searchInput.toLowerCase()
      //   ) !== -1)
    );
  });
};
