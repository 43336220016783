import apiClient from './apiClient';
import { POST_FORGOT_PASSWORD_URL, POST_RESET_PASSWORD_URL } from './apiUrl';

const postForgotPasswordApi = data => {
  const API_URL = `${POST_FORGOT_PASSWORD_URL}/${data}`;
  return apiClient.post(API_URL);
};

const postResetPasswordApi = data => {
  return apiClient.post(POST_RESET_PASSWORD_URL, data);
};

export { postForgotPasswordApi, postResetPasswordApi };
