import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Button } from 'semantic-ui-react';
import styles from './styles';
import FilterContent from '../../../shared/components/formFilter/FilterContent';
import InputRange from './InputRange';
import { useTranslation } from 'react-i18next';

const Filter = props => {
  const { t } = useTranslation();
  let { listTourType, listDuration } = props.dataFilter;
  let {
    handleChange,
    resetFilter,
    maxPrice,
    rangePrice,
    onLowerBoundChange,
    onUpperBoundChange,
    onSliderChange,
    packageType,
    loadingTourTransaction,
    tab,
  } = props;
  let showFilterPrice =
    packageType === 'series'
      ? true
      : packageType === 'ready'
      ? false
      : tab.toLowerCase() === 'series'
      ? true
      : false;
  return (
    <Card color="grey" fluid style={styles.segmentNoPadding}>
      <Card.Content>
        <Grid columns="equal">
          <Grid.Column>
            {/* Filter */}
            <h3>{t('filter')}</h3>
          </Grid.Column>
          <Grid.Column style={styles.goldColor}>
            <Button
              floated="right"
              style={styles.buttonTransparent}
              onClick={resetFilter}
            >
              {/* Reset */}
              <h3>{t('reset')}</h3>
            </Button>
          </Grid.Column>
        </Grid>
      </Card.Content>
      <FilterContent
        columns={1}
        gridStyles={styles.paddingTopBottom10px}
        columnStyles={styles.paddingTopBottom2px}
        header={t('tourType')} //"Tour Type"
        data={listTourType}
        name="listTourType"
        handleChange={handleChange}
        loading={loadingTourTransaction}
      />
      <FilterContent
        columns={1}
        gridStyles={styles.paddingTopBottom10px}
        columnStyles={styles.paddingTopBottom2px}
        header={t('durationInDays')} //"Duration (in a days)"
        data={listDuration}
        name="listDuration"
        handleChange={handleChange}
        loading={loadingTourTransaction}
      />
      {showFilterPrice && (
        <InputRange
          maxPrice={maxPrice}
          rangePrice={rangePrice}
          onLowerBoundChange={onLowerBoundChange}
          onUpperBoundChange={onUpperBoundChange}
          onSliderChange={onSliderChange}
        />
      )}
    </Card>
  );
};
Filter.propTypes = {
  dataFilter: PropTypes.object,
  handleChange: PropTypes.func,
  resetFilter: PropTypes.func,
  maxPrice: PropTypes.number,
  rangePrice: PropTypes.array,
  onLowerBoundChange: PropTypes.func,
  onUpperBoundChange: PropTypes.func,
  onSliderChange: PropTypes.func,
  packageType: PropTypes.string,
  loadingTourTransaction: PropTypes.bool,
  tab: PropTypes.string,
  t: PropTypes.func,
};
export default Filter;
