import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Form, Message, Segment, Button } from 'semantic-ui-react';
import InputWithLabel from '../../../../../components/input/WithLabel';
import InputAmount from '../../../../../components/input/Amount';
import IconButton from '../../../../../components/button/BtnIcon';
// import HeaderWithIcon from '../common/headerWithIcon';
// import CollapseButton from '../button/collapseButton';
import InputDate from '../../../../../components/input/DateInput';
import InputTime from '../../../../../components/input/Time';
import styles from '../styles';
import {
  getHourAndMinute,
  convertDateFormat,
} from '../../../../../genericFunction/moment';
import options from '../../../../../genericFunction/selectOptionsConverter';
import {
  changeDuration,
  copyObject,
  // setDateDeparture,
  changeDestinationSummaryProgram,
  // isAccommodationEmpty,
  changeChecInCheckOut,
  setPlaceInArrivalDepartureByHeadLine,
  addConnectionFlight,
  // delSummaryProgram,
  setDateSummaryProgramByIndex,
  isEarlyCheck,
  // isOverlapping,
  isThereActivity,
  getPlaces,
  delConectionFlight,
} from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import {
  // getAccommodations,
  getAccommodationRatings,
  getAccommodationTypes,
  getAccommodationLocations,
  getAccommodationFacilities,
} from '../../../actions/accommodationAction';
// import { setFirstDestination } from '../../../actions/customPackageAction';
import { getPlaceByRegion } from '../../../actions/locationAction';
import { connect } from 'react-redux';
import Loader from '../../../../../components/common/loader';
import { ModalConfirm, ModalAlert } from './../';
import {
  isOverlapping,
  setReturnsDate,
  delSummaryProgram,
} from '../../../../../genericFunction/itineraryBuilder/itineraryBuilder';
import { withTranslation } from 'react-i18next';
class Accommodation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkinDate: props.data.AccommodationSummary.CheckIn,
      checkoutDate: props.data.AccommodationSummary.CheckOut,
      checkinTime: getHourAndMinute(props.data.AccommodationSummary.CheckIn),
      checkoutTime: getHourAndMinute(props.data.AccommodationSummary.CheckOut),
      destination: props.data.City ? props.data.City.Id : null,
      timeChange: '',
      openModalConfirm: false,
      message: '',
      actionConfirm: '',
      openModalAlert: false,
      messageModalAlert: '',
    };
  }
  componentDidUpdate() {
    const { checkinDate, checkoutDate, checkinTime, checkoutTime } = this.state;
    const { CheckIn, CheckOut } = this.props.data.AccommodationSummary;
    if (
      checkinDate !== CheckIn ||
      checkoutDate !== CheckOut ||
      checkinTime !== getHourAndMinute(CheckIn) ||
      checkoutTime !== getHourAndMinute(CheckOut)
    )
      this.setState({
        ...this.state,
        checkinDate: CheckIn,
        checkoutDate: CheckOut,
        checkinTime: getHourAndMinute(CheckIn),
        checkoutTime: getHourAndMinute(CheckOut),
      });
  }
  openModalConfirmTime = (context, value, name) => {
    let { dailyProgram } = this.props;
    let { checkinDate, checkoutDate } = this.state;
    if (
      isThereActivity(
        dailyProgram,
        name === 'checkin' ? checkinDate : checkoutDate
      )
    ) {
      this.setState({
        ...this.state,
        value: value,
        timeChange: name,
        openModalConfirm: true,
        actionConfirm: 'ChangeTime',
        message: this.props.t('warningChangeAccommodationTime'),
        // 'Please be careful, changing the time of accommodation will reset the activity time in the current day',
      });
    } else {
      if (name === 'checkout') {
        this.setCheckoutTime(this, value);
      } else if (name === 'checkin') {
        this.setCheckinTime(this, value);
      }
    }
  };
  openModalConfirmAccommodation = () => {
    let data = this.props.data;
    if (data.AccommodationSummary.ServiceItemId) {
      this.setState({
        ...this.state,
        openModalConfirm: true,
        actionConfirm: 'ChangeAccommodation',
        message: this.props.t('warningChangeAccommodation'),
        // 'Please be careful, changing the accommodation will reset the activity item like excursion, transportation, and meal',
      });
    } else {
      this.openModal();
    }
  };
  closeModal = () => this.setState({ openModalConfirm: false });
  confirmTime = () => {
    let { timeChange, value } = this.state;
    if (timeChange === 'checkout') {
      this.setCheckoutTime(this, value);
    } else if (timeChange === 'checkin') {
      this.setCheckinTime(this, value);
    }
  };
  setCheckinTime(context, value) {
    const { index, headlineProgram } = context.props;
    let headLineProg = { ...headlineProgram };
    let date = `${convertDateFormat(
      context.state.checkinDate,
      'YYYY-MM-DD'
    )}T${value}:00`;
    headLineProg.MainPrograms = changeChecInCheckOut(
      headlineProgram.MainPrograms,
      index,
      date,
      'in'
    );
    context.setState({
      ...context.state,
      checkinTime: value,
      openModalConfirm: false,
    });
    context.props.setHeadLines(headLineProg);
  }
  setCheckoutTime(context, value) {
    let time = value.split(':');
    if (+time[0] < 12 || (+time[0] === 12 && +time[1] === 0)) {
      const { index, headlineProgram } = context.props;
      let headLineProg = { ...headlineProgram };
      let date = `${convertDateFormat(
        context.state.checkoutDate,
        'YYYY-MM-DD'
      )}T${value}:00`;
      headLineProg.MainPrograms = changeChecInCheckOut(
        headlineProgram.MainPrograms,
        index,
        date,
        'out'
      );
      context.setState({ ...context.state, checkoutTime: value });
      context.props.setHeadLines(headLineProg);
    } else {
      alert(`${this.props.t('checkinShouldBeMoreThen12')}`); //'Your check-out time should not be more than 12:00'
      context.setState({
        ...context.state,
        checkoutTime: context.state.checkoutTime,
        openModalConfirm: false,
      });
    }
  }
  handleChangeTotalDay = (e, { value }) => {
    value = value === '' ? 0 : value;
    this.setTotalDay(value);
  };
  handleIncrease = (e, { value }) => {
    value = value + 1;
    this.setTotalDay(value);
  };
  handleDecrease = (e, { value }) => {
    const newValue = value - 1 < 0 ? 0 : value - 1;
    this.setTotalDay(newValue);
  };
  setTotalDay = value => {
    let { headlineProgram, index } = this.props;
    let headLineProg = copyObject(headlineProgram);
    let newMainProg = changeDuration(headLineProg.MainPrograms, index, value);
    let newDeparture = setReturnsDate(headLineProg.Returns, newMainProg);
    headLineProg.MainPrograms = newMainProg;
    headLineProg.Returns = newDeparture;
    this.props.setHeadLines(headLineProg);
  };
  openModal = () => {
    let context = this;
    context.props.openModal(context.props.data, context.props.index);
    context.closeModal();
  };
  changeDestination = (e, { value }) => {
    this.setDestination(value);
  };
  setDestination = value => {
    let { index, cityInCountry, headlineProgram } = this.props;
    let city = cityInCountry.find(item => item.Id === value);
    let headLineProg = { ...headlineProgram };
    headLineProg.MainPrograms = changeDestinationSummaryProgram(
      headLineProg.MainPrograms,
      index,
      city
    );
    // index === 0 && this.props.setFirstDestination(city);
    // this.props.setHeadLines(headLineProg);
    headLineProg.MainPrograms = this.deleteDeleteConnectionFlightWhenSameRegion(
      headLineProg.MainPrograms,
      index,
      city,
      headLineProg.Departures[headLineProg.Departures.length - 1]
    );
    let context = this;
    this.props
      .getPlaceByRegion(city.Region.Id)
      .then(res => {
        if (res.value.data.length > 0) {
          context.setPlace(headLineProg);
        } else {
          context.props.setHeadLines(headLineProg);
        }
      })
      .catch(err => {
        // eslint-disable-next-line
        console.log(err);
      });
    this.props.setAccommodationOnChangeIndex(index);
  };

  deleteDeleteConnectionFlightWhenSameRegion = (
    mainProg,
    index,
    city,
    departures
  ) => {
    if (index === 0) {
      // jika yg diganti adalah akomodasi pertama
      let nextObj = mainProg[index + 1] ? mainProg[index + 1] : null;
      if (nextObj && nextObj.TransferType) {
        if (mainProg[index + 3].City.Region.Id === city.Region.Id) {
          mainProg = delConectionFlight(departures, mainProg, index + 1);
        }
      }
    } else {
      // selain akomodasi pertama
      let prevObj = mainProg[index - 1] ? mainProg[index - 1] : null;
      if (prevObj && prevObj.TransferType) {
        if (mainProg[index - 3].City.Region.Id === city.Region.Id) {
          mainProg = delConectionFlight(departures, mainProg, index - 2);
        }
      }
    }
    return mainProg;
  };
  setPlace = headline => {
    const { places, cityInCountry } = this.props;
    let newHeadline = setPlaceInArrivalDepartureByHeadLine(
      headline,
      places,
      cityInCountry
    );
    this.props.setHeadLines(newHeadline);
  };
  renderButtonConnectionFlight = () => {
    const { index, headlineProgram, data } = this.props;
    const mainProg = headlineProgram.MainPrograms;
    if (index !== 0 && mainProg[index - 1].AccommodationSummary) {
      if (data.City && mainProg[index - 1].City) {
        if (
          data.City.Region &&
          mainProg[index - 1].City.Region &&
          data.City.Region.Id !== mainProg[index - 1].City.Region.Id
        ) {
          return (
            <div style={styles.margin10Center}>
              <Button
                style={styles.backgroundGold}
                onClick={this.handleAddConnectionFlight}
              >
                {/* Add Connection Flight */}
                {this.props.t('addConnectionFlight')}
              </Button>
            </div>
          );
        }
      }
    }
  };
  handleAddConnectionFlight = () => {
    let { index, headlineProgram, places } = this.props;
    let originPlace = getPlaces(
      headlineProgram.MainPrograms[index - 1].Region,
      places
    );
    let destPlace = getPlaces(
      headlineProgram.MainPrograms[index].Region,
      places
    );
    if (originPlace.length > 0 && destPlace.length > 0) {
      let headLineProg = copyObject(headlineProgram);
      headLineProg.MainPrograms = addConnectionFlight(
        headLineProg.MainPrograms,
        index
      );
      this.setPlace(headLineProg);
    } else {
      this.setState({
        ...this.state,
        openModalAlert: true,
        messageModalAlert: this.props.t('cannotFindNearestAirport'),
        // 'We cannot find the nearest airport / transit place in your destination',
      });
    }

    // this.props.setHeadLines(hLProg);
  };

  delete = () => {
    let {
      cityInCountry,
      places,
      index,
      headlineProgram,
      dailyProgram,
    } = this.props;
    let result = delSummaryProgram(
      headlineProgram.Departures[headlineProgram.Departures.length - 1],
      headlineProgram.Returns,
      headlineProgram.MainPrograms,
      dailyProgram,
      index
    );
    let headLineProg = { ...headlineProgram };
    headLineProg.MainPrograms = result.summaryProgram;
    headLineProg.Returns = result.returns;
    // this.setDailyProgram(result.dailyProgram);
    headLineProg = setPlaceInArrivalDepartureByHeadLine(
      headLineProg,
      places,
      cityInCountry
    );
    // eslint-disable-next-line
    this.props.setHeadLineAndDaily(headLineProg, result.dailyProgram);
    // alert('tert');
  };
  setRangeStartDate = () => {
    let { headlineProgram, index } = this.props;
    let mainProg = headlineProgram.MainPrograms;
    let date;
    if (index === 0) {
      date = headlineProgram.Departures[0].Date;
    } else {
      if (mainProg[index - 1] && mainProg[index - 1].AccommodationSummary) {
        date = mainProg[index - 1].LeavingDate;
      } else {
        date = mainProg[index - 1].Date;
      }
    }

    return date;
  };
  setDate = date => {
    let { headlineProgram, index } = this.props;
    let newHeadline = { ...headlineProgram };
    newHeadline.MainPrograms = setDateSummaryProgramByIndex(
      newHeadline.MainPrograms,
      newHeadline.Departures[0],
      index,
      date
    );
    // newHeadline.Returns[0] = setDateDeparture(
    //   newHeadline.Returns[0],
    //   newHeadline.MainPrograms
    // );
    newHeadline.Returns = setReturnsDate(
      newHeadline.Returns,
      newHeadline.MainPrograms
    );
    this.props.setHeadLines(newHeadline);
  };
  confirmAction = () => {
    let { actionConfirm } = this.state;
    if (actionConfirm === 'ChangeTime') {
      this.confirmTime();
    } else {
      this.openModal();
    }
  };
  isPlaceEmpty = () => {
    let { data, places } = this.props;
    let place = getPlaces(data.Region, places);
    return place.length < 1 ? true : false;
  };
  //===============
  handleCloseAlert = () => {
    this.setState({ openModalAlert: false });
  };
  render() {
    const { t } = this.props;
    const { index, data, loading, headlineProgram } = this.props;
    const {
      checkinTime,
      checkoutTime,
      checkinDate,
      checkoutDate,
      timeChange,
      openModalConfirm,
      message,
      messageModalAlert,
      openModalAlert,
    } = this.state; // destination,
    const accommodation = data.AccommodationSummary.Name
      ? `${data.AccommodationSummary.Name}, ${
          data.AccommodationSummary.RoomName
            ? data.AccommodationSummary.RoomName
            : ''
        }, ${
          data.AccommodationSummary.RoomService === 'Bed'
            ? 'Room Only'
            : data.AccommodationSummary.RoomService.replace('_', ' ')
        }`
      : '';
    const destination = data.City ? data.City.Id : null;
    const startDate = this.setRangeStartDate();
    const earlyCheck = isEarlyCheck(data.AccommodationSummary.CheckIn);
    const isOverlapp = isOverlapping(headlineProgram, 'accommodation', index);
    const isPlaceEmpty = this.isPlaceEmpty();
    return (
      <div>
        {this.renderButtonConnectionFlight()}
        <Segment style={styles.segmentInside}>
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="right">
                {index !== 0 && (
                  <IconButton
                    name="times"
                    iconStyle={styles.buttonDeleteStyle}
                    handleOnClick={this.delete}
                    size="large"
                  />
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field width={16}>
                      <InputDate
                        label={t('checkInDate')} //"Checkin Date"
                        value={checkinDate}
                        labelStyle={styles.labelInputStyle}
                        inputStyles={styles.inputDateStyle}
                        iconStyle={styles.primaryColor}
                        date={startDate}
                        range={true}
                        setDate={this.setDate}
                      />
                    </Form.Field>
                    <Form.Field width={16}>
                      <InputTime
                        labelText={t('checkInTime')} //"Checkin Time"
                        labelStyles={styles.labelInputStyle}
                        context={this}
                        value={checkinTime}
                        name="checkin"
                        setTime={
                          this.openModalConfirmTime //this.setCheckinTime // inputStyles={styles.inputTimeStyle}
                        }
                      />
                    </Form.Field>
                    <Form.Field width={16}>
                      <InputAmount
                        label={t('totalDays')} //"Total Days"
                        labelStyles={styles.labelInputAmountStyle}
                        inputStyle={styles.inputAmountStyle}
                        labelStyle={styles.textAlignCenter}
                        value={data.TotalDays}
                        handleIncrease={this.handleIncrease}
                        handleDecrease={this.handleDecrease}
                        handleOnChange={this.handleChangeTotalDay}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field width={16}>
                      <InputDate
                        label={t('checkOutDate')} //"Checkout Date"
                        value={checkoutDate}
                        labelStyle={styles.labelInputStyle}
                        inputStyles={styles.inputDateStyle}
                        iconStyle={styles.primaryColor}
                        readonly={true}
                      />
                    </Form.Field>
                    <Form.Field width={16}>
                      <InputTime
                        labelText={t('checkOutTime')} //"Checkout Time"
                        labelStyles={styles.labelInputStyle}
                        context={this}
                        value={checkoutTime}
                        name="checkout"
                        setTime={
                          this.openModalConfirmTime //this.setCheckoutTime
                        }
                      />
                    </Form.Field>
                    <Form.Field width={16}>
                      <InputWithLabel
                        label={t('cityDestination')} //"City Destination"
                        inline={false}
                        type="select"
                        placeholder={t('selectPlace')} //"Select Place"
                        labelStyles={styles.labelInputStyle}
                        options={
                          this.props.cityList // inputStyles={styles.inputSelectionStyle}
                        }
                        value={destination}
                        handleChange={this.changeDestination}
                        fluid={
                          true //   options={this.props.listCountry}
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <InputWithLabel
                      handleClick={this.openModalConfirmAccommodation}
                      label={t('accommodation')} //"Accommodation"
                      inline={
                        false // transparent
                      }
                      type="text"
                      value={accommodation}
                      placeholder={t('selectPlace')} //"Select Place"
                      labelStyles={styles.labelInputStyle}
                      fluid={
                        true // inputStyles={styles.inputTextStyle} //   options={this.props.listCountry}
                      }
                    />
                  </Form.Field>
                </Form>
                <Message warning>
                  <Message.Header>{t('note')}:</Message.Header>
                  <p>
                    {/* Check in date can be changed to Day + 1 from Arrival Date or
                    Check out date on previous Accommodation */}
                    {t('modalDestinaitonSummaryNote')}
                  </p>
                  {earlyCheck && (
                    <p style={styles.redColor}>
                      {/* Your check in time is less than 2 PM, there will be extra
                      charge for early checkin. */}
                      {t('warningEarlyCheckIn')}
                    </p>
                  )}
                  {isOverlapp && (
                    <p style={styles.redColor}>
                      <strong>
                        {/* Warning!! your accommodation time is overlapping. */}
                        {t('warningAccommodationOverlapping')}.
                      </strong>{' '}
                    </p>
                  )}
                  {isPlaceEmpty && (
                    <p style={styles.redColor}>
                      <strong>
                        {/* {`Warning!! we cannot find the nearest airport / transit
                        place in '${
                          data.City ? data.City.Name : ''
                        }', please change your destination.`} */}
                        {t('cannotFindNearentAirportInPlace', {
                          place: data.City ? data.City.Name : '',
                        })}
                      </strong>{' '}
                    </p>
                  )}
                </Message>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Loader active={loading} inverted={true} />
          <ModalConfirm
            openModal={openModalConfirm}
            size="small"
            close={this.closeModal}
            confirm={this.confirmAction}
            message={message}
            nameConfirm={timeChange}
            contentOk={t('okContinue')} //"Ok, Continue"
            contentNo={t('back')} //"Back"
          />
          <ModalAlert
            close={this.handleCloseAlert}
            openModal={openModalAlert}
            message={messageModalAlert}
            size="mini"
          />
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    cityInCountry: state.location.cityInCountry,
    cityList: state.location.cityInCountry.map((item, index) => {
      let result = options(`${item.Id} ${index}`, item.Id, item.Name);
      result.content = (
        <p>
          <strong>{item.Name}</strong>
          <br />
          {item.Region.Name}, {item.Country.Name}
        </p>
      );
      return result;
    }),
    places: state.location.places,
    loading: state.location.loading,
  };
};
Accommodation.propTypes = {
  data: PropTypes.object,
  cityList: PropTypes.array,
  index: PropTypes.number,
  headlineProgram: PropTypes.object,
  setHeadLines: PropTypes.func,
  getAccommodationRatings: PropTypes.func,
  getAccommodationTypes: PropTypes.func,
  getAccommodationLocations: PropTypes.func,
  getAccommodationFacilities: PropTypes.func,
  // getAccommodations: PropTypes.func,
  cityInCountry: PropTypes.array,
  getPlaceByRegion: PropTypes.func,
  setAccommodationOnChangeIndex: PropTypes.func,
  // setFirstDestination: PropTypes.func,
  openModal: PropTypes.func,
  places: PropTypes.array,
  deleteAccommodation: PropTypes.func,
  dailyProgram: PropTypes.array,
  loading: PropTypes.bool,
  setHeadLineAndDaily: PropTypes.func,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    // getAccommodations,
    getAccommodationRatings,
    getAccommodationTypes,
    getAccommodationLocations,
    getAccommodationFacilities,
    getPlaceByRegion,
    // setFirstDestination,
  }
)(withTranslation()(Accommodation));
