import React from 'react';
import { Modal, Grid, Header } from 'semantic-ui-react';
import { ucwords } from '../../../../genericFunction/convertWord';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { useTranslation, Translation } from 'react-i18next';

const HeaderList = props => (
  <Grid.Column width={16} style={styles.padding15px}>
    <Header as="h5">{props.header}</Header>
  </Grid.Column>
);
const ListGuest = props => (
  <Grid.Column width={16}>
    <Grid>
      <Grid.Row style={styles.paddingTopBottom5pxLeftRight35px}>
        <Grid.Column width={1}>
          <strong>
            <Translation>{t => <>{t('title')}</>}</Translation>
            {/* Title */}
          </strong>
        </Grid.Column>
        <Grid.Column width={3}>
          <strong>
            {/* FirstName */}
            <Translation>{t => <>{t('firstName')}</>}</Translation>
          </strong>
        </Grid.Column>
        <Grid.Column width={3}>
          <strong>
            {/* LastName */}
            <Translation>{t => <>{t('lastName')}</>}</Translation>
          </strong>
        </Grid.Column>
        <Grid.Column width={3}>
          <strong>
            {/* Identity Type */}
            <Translation>{t => <>{t('identityType')}</>}</Translation>
          </strong>
        </Grid.Column>
        <Grid.Column width={3}>
          <strong>
            {/* Identity Number */}
            <Translation>{t => <>{t('identityNumber')}</>}</Translation>
          </strong>
        </Grid.Column>
        <Grid.Column width={3}>
          <strong>
            {/* Home Country */}
            <Translation>{t => <>{t('homeCountry')}</>}</Translation>
          </strong>
        </Grid.Column>
      </Grid.Row>
      {props.list.map((guest, index) => {
        let country = guest.CountryId
          ? props.countries.filter(e => e.Id === guest.CountryId)[0].Name
          : '';
        return guest.FirstName ? (
          <Grid.Row key={index} style={styles.paddingTopBottom5pxLeftRight35px}>
            <Grid.Column width={1}>{ucwords(guest.GuestTitle)}</Grid.Column>
            <Grid.Column width={3}>{ucwords(guest.FirstName)}</Grid.Column>
            <Grid.Column width={3}>{ucwords(guest.LastName)}</Grid.Column>
            <Grid.Column width={3}>{ucwords(guest.IdentityType)}</Grid.Column>
            <Grid.Column width={3}>{guest.IdentityNbr}</Grid.Column>
            <Grid.Column width={3}>{ucwords(country)}</Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row key={index} style={styles.paddingTopBottom5pxLeftRight35px}>
            <Grid.Column width={1}>-</Grid.Column>
            <Grid.Column width={3}>-</Grid.Column>
            <Grid.Column width={3}>-</Grid.Column>
            <Grid.Column width={3}>-</Grid.Column>
            <Grid.Column width={3}>-</Grid.Column>
            <Grid.Column width={3}>-</Grid.Column>
          </Grid.Row>
        );
      })}
    </Grid>
  </Grid.Column>
);
const GuestListModal = props => {
  const { t } = useTranslation();
  let { list, isOpen, handleButton, countries } = props;
  let adult = list.filter(guest => guest.GuestCategory === 'ADULT');
  let children = list.filter(guest => guest.GuestCategory === 'CHILDREN');
  let infant = list.filter(guest => guest.GuestCategory === 'INFANT');
  return (
    <Modal
      name="openModalGuest"
      open={isOpen}
      onClose={handleButton}
      size="large"
      closeOnDimmerClick
      closeIcon
    >
      {/* <Modal.Header style={styles.backgroundModal}>{name}</Modal.Header> */}
      <Grid style={styles.margin10}>
        <Grid.Row>
          <Grid.Column width={8} style={styles.marginTop10Bottom5px}>
            <Header as="h2">
              {/* Guest Detail */}
              {t('guestDetail')}
            </Header>
          </Grid.Column>
          {adult.length !== 0 && (
            <>
              {/* <HeaderList header="Adult &#40; &#62; 12 Years &#41;" /> */}
              <HeaderList header={`${t('adult')} ( > 12 ${t('years')} )`} />
              <ListGuest list={adult} countries={countries} />{' '}
            </>
          )}

          {children.length !== 0 && (
            <>
              {/* <HeaderList header="Children &#40; 2 - 12 Years &#41;" /> */}
              <HeaderList
                header={`${t('children')} ( 2 - 12 ${t('years')} )`}
              />
              <ListGuest list={children} countries={countries} />{' '}
            </>
          )}

          {infant.length !== 0 && (
            <>
              {/* <HeaderList header="Infants &#40; &#60; 2 Years &#41;" /> */}
              <HeaderList header={`${t('infants')} ( < 2 ${t('years')} )`} />
              <ListGuest list={infant} countries={countries} />
            </>
          )}
        </Grid.Row>
      </Grid>
    </Modal>
  );
};
HeaderList.propTypes = {
  header: PropTypes.string,
};
ListGuest.propTypes = {
  list: PropTypes.array,
  countries: PropTypes.array,
};
GuestListModal.propTypes = {
  list: PropTypes.array,
  handleButton: PropTypes.func,
  isOpen: PropTypes.bool,
  countries: PropTypes.array,
};
export default GuestListModal;
