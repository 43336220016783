import React, { Component } from 'react';
import {
  Segment,
  Header,
  Grid,
  Image,
  Icon,
  Table,
  Button,
  Form,
} from 'semantic-ui-react';
import TablePaymentTerms from './TablePaymentTermsSummary';
import styles from '../styles';
import noImage from './../../../../../assets/image/NoImagetrans.png';
import PropTypes from 'prop-types';
import Input from '../../../../../components/input/WithLabel';
import { TextMoney } from '../../common';
import { withTranslation } from 'react-i18next';
import { initialAdditionalServices } from '../../../services/additionalServices/processData';

class SegmentPackageSummary extends Component {
  static propTypes = {
    imageTourOperator: PropTypes.string,
    nameTourOperator: PropTypes.string,
    tourTitle: PropTypes.string,
    tourDate: PropTypes.string,
    totalGuest: PropTypes.number,
    paymentTerms: PropTypes.array,
    tourPriceSum: PropTypes.array,
    additional: PropTypes.array,
    currency: PropTypes.string,
    totalTourPrice: PropTypes.number,
    splitCommission: PropTypes.bool,
    commissions: PropTypes.array,
    totalPayable: PropTypes.number,
    packageType: PropTypes.string,
    destinationCity: PropTypes.array,
    isSales: PropTypes.bool,
    handleModalCompanies: PropTypes.func,
    selectedCompany: PropTypes.object,
    handleModalRegisCompany: PropTypes.func,
    selectedUser: PropTypes.object,
    FoC: PropTypes.object,
    tourNote: PropTypes.string,
    disableField: PropTypes.bool,
    handleChange: PropTypes.func,
    message: PropTypes.string,
    t: PropTypes.func,
    additionalServices: PropTypes.array,
  };
  render() {
    const { t } = this.props;
    let {
      imageTourOperator,
      nameTourOperator,
      tourTitle,
      tourDate,
      totalGuest,
      paymentTerms,
      tourPriceSum,
      additional,
      currency,
      totalTourPrice,
      splitCommission,
      commissions,
      totalPayable,
      packageType,
      destinationCity,
      isSales,
      handleModalCompanies,
      selectedCompany,
      handleModalRegisCompany,
      selectedUser,
      FoC,
      tourNote,
      disableField,
      handleChange,
      message,
      additionalServices,
      // tourNote,
      // disableField,
      // handleChange,
    } = this.props;
    let valueOnBeHalf =
      selectedCompany && selectedCompany.name
        ? `${selectedCompany.name} ${
            selectedUser.name ? `(${selectedUser.name})` : ''
          }`
        : '';
    packageType = packageType ? packageType.toLowerCase() : '';
    let additionalServiceList = initialAdditionalServices(
      additionalServices ? additionalServices : []
    );
    return (
      <div>
        <Segment style={styles.segmentNoPadding}>
          <Grid>
            <Grid.Row style={styles.paddingBottom5px}>
              <Grid.Column width={6} verticalAlign="top">
                <Header as="h3" className="no-margin">
                  {/* Package Detail */}
                  {t('packageDetail')}
                </Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Image
                  src={imageTourOperator || noImage}
                  size="tiny"
                  centered
                />
              </Grid.Column>
              <Grid.Column width={6} verticalAlign="top">
                <Header as="h4">
                  {/* Tour Detail */}
                  {t('tourDetail')}
                </Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as="h5" className="no-margin" textAlign="center">
                  {/* Operated by :  */}
                  {t('operatedBy')} : {nameTourOperator}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={styles.paddingTop10pxBackgroundGold}>
              <Grid.Column width={16}>
                <Header as="h5">{tourTitle}</Header>
              </Grid.Column>
              <Grid.Column width={9}>
                <span>
                  <Icon
                    className="fas fa-calendar-alt"
                    style={styles.goldColor}
                  />
                  {'  '}
                  {/* Tour Date  */}
                  {t('tourDate')} : {tourDate}
                </span>
              </Grid.Column>
              <Grid.Column width={7}>
                <span>
                  <Icon className="fas fa-users" style={styles.goldColor} />{' '}
                  {/* Total Guest : {totalGuest} Guest{' '} */}
                  {t('totalGuest')} : {totalGuest} {t('guest')}{' '}
                </span>
              </Grid.Column>
              <Grid.Column width={16} style={styles.marginTopBottom5px}>
                <span>
                  {/* Cities */}
                  {t('cities')} :{' '}
                  {destinationCity && destinationCity.join(', ')}
                </span>
              </Grid.Column>
              <TablePaymentTerms paymentTerms={paymentTerms} />
              <Grid.Column width={16} style={styles.paddingTop20px}>
                <Header as="h4">
                  {/* Price Detail */}
                  {t('priceDetail')}
                </Header>
                <Table style={styles.noMargin} basic="very">
                  <Table.Header>
                    <Table.Row style={styles.noPadding}>
                      <Table.HeaderCell style={styles.tableHeader}>
                        {/* Room Type */}
                        {t('roomType')}
                      </Table.HeaderCell>
                      <Table.HeaderCell style={styles.tableHeader}>
                        {/* Guest */}
                        {t('guest')}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={styles.tableHeader}
                        textAlign="right"
                      >
                        {/* Price/Pax */}
                        {t('price')}/{t('pax')}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={styles.tableHeader}
                        textAlign="right"
                      >
                        {/* Sub Total */}
                        {t('subTotal')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {tourPriceSum.length !== 0 &&
                      tourPriceSum.map(
                        (item, index) =>
                          item.data.Pax !== 0 && (
                            <Table.Row key={index}>
                              <Table.Cell style={styles.tableHeader}>
                                {item.name}
                              </Table.Cell>
                              <Table.Cell style={styles.tableHeader}>
                                {item.data.Pax || 0}
                              </Table.Cell>
                              <Table.Cell
                                style={styles.tableHeader}
                                textAlign="right"
                              >
                                <TextMoney
                                  currencyId={item.data.Currency}
                                  money={
                                    item.data.PricePerPax
                                      ? item.data.PricePerPax
                                      : 0
                                  }
                                />
                                {/* {item.data.PricePerPax
                                  ? item.data.Currency +
                                    ' ' +
                                    item.data.PricePerPax.toLocaleString('ID')
                                  : item.data.Currency + ' 0'} */}
                              </Table.Cell>
                              <Table.Cell
                                style={styles.tableHeader}
                                textAlign="right"
                              >
                                <TextMoney
                                  currencyId={item.data.Currency}
                                  money={
                                    item.data.TotalPrice
                                      ? item.data.TotalPrice
                                      : 0
                                  }
                                />
                                {/* {item.data.TotalPrice
                                  ? item.data.Currency +
                                    ' ' +
                                    item.data.TotalPrice.toLocaleString('ID')
                                  : item.data.Currency + ' 0'} */}
                              </Table.Cell>
                            </Table.Row>
                          )
                      )}
                    {/* additional service */}
                    {additionalServiceList.length !== 0 && (
                      <Table.Row>
                        <Table.Cell style={styles.tableHeader}>
                          {/* Additional Services */}
                          <strong>{t('additionalServices')}</strong>
                        </Table.Cell>
                        <Table.Cell collspan="3" style={styles.tableNoBorder} />
                      </Table.Row>
                    )}
                    {additionalServiceList.map(item => {
                      let subTotal = item.Amount * item.Quantity;
                      return (
                        item.Quantity !== 0 && (
                          <Table.Row key={item.AdditionalServiceId}>
                            <Table.Cell style={styles.tableHeader}>
                              {item.AdditionalServiceName}
                            </Table.Cell>
                            <Table.Cell style={styles.tableHeader}>
                              {item.Quantity}
                            </Table.Cell>
                            <Table.Cell
                              style={styles.tableHeader}
                              textAlign="right"
                            >
                              <TextMoney
                                currencyId={item.Currency}
                                money={item.Amount ? item.Amount : 0}
                              />
                            </Table.Cell>
                            <Table.Cell
                              style={styles.tableHeader}
                              textAlign="right"
                            >
                              <TextMoney
                                currencyId={item.Currency}
                                money={item.Quantity ? subTotal : 0}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )
                      );
                    })}
                    {/* end additional service */}
                    {additional.length !== 0 && (
                      <Table.Row>
                        <Table.Cell style={styles.tableHeader}>
                          <strong>
                            {/* Additional Price */}
                            {t('additionalPrice')}
                          </strong>
                        </Table.Cell>
                        <Table.Cell collspan="3" style={styles.tableNoBorder} />
                      </Table.Row>
                    )}
                    {additional.map(item => {
                      let subTotal = item.Price * item.Qty;
                      return (
                        item.Qty !== 0 && (
                          <Table.Row
                            key={
                              item.BookingTemplateSupplementId ||
                              item.bookingTemplateSupplementId
                            }
                          >
                            <Table.Cell style={styles.tableHeader}>
                              {item.Name || item.name}
                            </Table.Cell>
                            <Table.Cell style={styles.tableHeader}>
                              {item.Qty || item.qty}
                            </Table.Cell>
                            <Table.Cell
                              style={styles.tableHeader}
                              textAlign="right"
                            >
                              <TextMoney
                                currencyId={currency}
                                money={
                                  item.Price
                                    ? item.Price
                                    : item.currencyId
                                    ? item.unitCost
                                    : 0
                                }
                              />
                              {/* {currency + ' '}{' '}
                              {item.Price
                                ? item.Price.toLocaleString(currency)
                                : item.currencyId
                                ? item.unitCost.toLocaleString('ID')
                                : 0} */}
                            </Table.Cell>
                            <Table.Cell
                              style={styles.tableHeader}
                              textAlign="right"
                            >
                              <TextMoney
                                currencyId={currency}
                                money={
                                  subTotal
                                    ? subTotal
                                    : item.currencyId
                                    ? item.totalCost
                                    : 0
                                }
                              />
                              {/* {currency + ' '}
                              {subTotal
                                ? subTotal.toLocaleString('ID')
                                : item.currencyId
                                ? item.totalCost.toLocaleString('ID')
                                : 0} */}
                            </Table.Cell>
                          </Table.Row>
                        )
                      );
                    })}
                    <Table.Row>
                      <Table.Cell style={styles.tableNoBorder}>
                        {/* Total Price */}
                        {t('totalPrice')}
                      </Table.Cell>
                      <Table.Cell style={styles.tableNoBorder} />
                      <Table.Cell style={styles.tableNoBorder} />
                      <Table.Cell
                        style={styles.tableNoBorder}
                        textAlign="right"
                      >
                        <TextMoney
                          currencyId={currency}
                          money={currency ? totalTourPrice : 0}
                        />
                        {/* {currency
                          ? currency + ' ' + totalTourPrice.toLocaleString('ID')
                          : '0'} */}
                      </Table.Cell>
                    </Table.Row>
                    {packageType !== 'fixed' && FoC && FoC.Qty ? (
                      <Table.Row>
                        <Table.Cell style={styles.tableHeader}>
                          <strong>
                            {/* Special Adjustment */}
                            {t('specialAdjustment')}
                          </strong>
                        </Table.Cell>
                        <Table.Cell colSpan={3} style={styles.tableNoBorder} />
                      </Table.Row>
                    ) : null}
                    {packageType !== 'fixed' && FoC && FoC.Qty ? (
                      <Table.Row>
                        <Table.Cell style={styles.tableHeader}>
                          {/* Free of Charge (FOC) */}
                          {t('freeOfCharge')}
                        </Table.Cell>
                        <Table.Cell style={styles.tableHeader}>
                          {FoC ? FoC.Qty : 0}
                        </Table.Cell>
                        <Table.Cell
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney currencyId={currency} money={0} />
                          {/* {currency + ' 0'} */}
                        </Table.Cell>
                        <Table.Cell
                          style={styles.tableHeader}
                          textAlign="right"
                        >
                          <TextMoney currencyId={currency} money={0} />
                          {/* {currency + ' 0'} */}
                        </Table.Cell>
                      </Table.Row>
                    ) : null}
                    {packageType === 'fixed' && (
                      <Table.Row>
                        <Table.Cell style={styles.tableHeader}>
                          <strong>
                            {/* Commission Detail */}
                            {t('commissionDetail')}
                          </strong>
                        </Table.Cell>
                        <Table.Cell colSpan={3} style={styles.tableNoBorder} />
                      </Table.Row>
                    )}
                    {packageType === 'fixed' && (
                      <Table.Row>
                        <Table.Cell colSpan={4} style={styles.tableBody}>
                          <strong>
                            {/* Do you want to split the staff commission? */}
                            {t('doYouWantToSplitStaffCommision')} ?{' '}
                            {splitCommission ? t('yes') : t('no')}{' '}
                            {/* ' Yes' : ' No'} */}
                          </strong>
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {packageType === 'fixed' &&
                      commissions &&
                      commissions.map((commission, index) => (
                        <Table.Row key={index}>
                          <Table.Cell style={styles.tableHeader}>
                            {commission.Description}
                          </Table.Cell>
                          <Table.Cell style={styles.tableHeader}>
                            {commission.TotalPax}
                          </Table.Cell>
                          <Table.Cell
                            style={styles.tableHeader}
                            textAlign="right"
                          >
                            <TextMoney
                              currencyId={currency}
                              money={commission ? commission.UnitPrice : 0}
                            />
                            {/* {currency + ' '}
                            {commission
                              ? commission.UnitPrice.toLocaleString('ID')
                              : '-'} */}
                          </Table.Cell>
                          <Table.Cell
                            style={styles.tableHeader}
                            textAlign="right"
                          >
                            <span style={styles.colorRed}>
                              <TextMoney
                                currencyId={currency}
                                money={
                                  commission ? commission.TotalPrice * -1 : 0
                                }
                              />
                              {/* {'- ' + currency + ' '}
                              {commission
                                ? commission.TotalPrice.toLocaleString('ID')
                                : '-'} */}
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    {/* {packageType === 'fixed' && 
                      // (splitCommission ? (
                      //   <Table.Row>
                      //     <Table.Cell style={styles.tableHeader}>
                      //       Agent Commission
                      //     </Table.Cell>
                      //     <Table.Cell style={styles.tableHeader}>
                      //       {commission.AgentCommission
                      //         ? commission.AgentCommission.TotalPax
                      //         : 0}
                      //     </Table.Cell> 
                      //     <Table.Cell
                      //       style={styles.tableHeader}
                      //       textAlign="right"
                      //     >
                      //       {currency + ' '}
                      //       {commission.AgentCommission && currency
                      //         ? commission.AgentCommission.UnitPrice.toLocaleString(
                      //             'ID'
                      //           )
                      //         : '0'}
                      //     </Table.Cell>
                      //     <Table.Cell
                      //       style={styles.tableHeader}
                      //       textAlign="right"
                      //     >
                      //       <span syle={{ color: '#f44336' }}>
                      //         {currency + ' '}
                      //         {commission.AgentCommission && currency
                      //           ? commission.AgentCommission.TotalPrice.toLocaleString(
                      //               'ID'
                      //             )
                      //           : '0'}
                      //       </span>
                      //     </Table.Cell>
                      //   </Table.Row>
                      // ) : (
                      //   <Table.Row>
                      //     <Table.Cell style={styles.tableHeader}>
                      //       Commission
                      //     </Table.Cell>
                      //     <Table.Cell style={styles.tableHeader}>
                      //       {commission.ApplicableCommission
                      //         ? commission.ApplicableCommission.TotalPax
                      //         : 0}
                      //     </Table.Cell>
                      //     <Table.Cell
                      //       style={styles.tableHeader}
                      //       textAlign="right"
                      //     >
                      //       {currency + ' '}
                      //       {commission.ApplicableCommission && currency
                      //         ? commission.ApplicableCommission.UnitPrice.toLocaleString(
                      //             'ID'
                      //           )
                      //         : '0'}
                      //     </Table.Cell>
                      //     <Table.Cell
                      //       style={styles.tableHeader}
                      //       textAlign="right"
                      //     >
                      //       <span syle={{ color: '#f44336' }}>
                      //         {currency + ' '}
                      //         {commission.ApplicableCommission && currency
                      //           ? commission.ApplicableCommission.TotalPrice.toLocaleString(
                      //               'ID'
                      //             )
                      //           : '0'}
                      //       </span>
                      //     </Table.Cell>
                      //   </Table.Row>
                      // ))
                    } */}
                    {packageType === 'fixed' && (
                      <Table.Row>
                        <Table.Cell style={styles.tableNoBorder}>
                          <strong>
                            {/* Total Payable */}
                            {t('totalPayable')}
                          </strong>
                        </Table.Cell>
                        <Table.Cell style={styles.tableNoBorder} />
                        <Table.Cell style={styles.tableNoBorder} />
                        <Table.Cell
                          style={styles.tableNoBorder}
                          textAlign="right"
                        >
                          <TextMoney
                            currencyId={currency}
                            money={currency ? totalPayable : 0}
                          />
                          {/* {currency
                            ? currency + ' ' + totalPayable.toLocaleString('ID')
                            : '0'} */}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            {isSales && (
              <Grid.Row style={styles.paddingTop10px}>
                <Grid.Column width={16}>
                  <Header as="h4" style={styles.paddingBottom5px}>
                    {/* On Behalf Customer */}
                    {t('onBehalfCustomer')}
                  </Header>
                </Grid.Column>
                <Grid.Column width={9}>
                  <Input
                    type="text"
                    value={valueOnBeHalf}
                    handleClick={handleModalCompanies}
                    inputStyles={styles.width100percent}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button
                    circular
                    icon="search"
                    style={styles.buttonGoldNoPadding}
                    floated="left"
                    onClick={handleModalCompanies}
                  />
                  <Button
                    circular
                    icon="add"
                    style={styles.buttonGoldNoPadding}
                    floated="left"
                    onClick={handleModalRegisCompany}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row style={styles.paddingTop10px}>
              <Grid.Column width={16}>
                <Header as="h4" style={styles.paddingBottom5px}>
                  {/* Notes */}
                  {t('notes')} {message}
                </Header>
              </Grid.Column>
              <Grid.Column width={16}>
                <Form>
                  <Input
                    placeholder={t('haveInstructionsOrRequests')} //"Have instructions or requests about this tour for us?"
                    type="textArea"
                    name="tourNote"
                    value={tourNote}
                    inputStyles={{
                      ...styles.width100padding30,
                      ...styles.placeholder,
                    }}
                    handleChange={handleChange}
                    disabled={disableField}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}
export default withTranslation()(SegmentPackageSummary);
