import React, { Component } from 'react';
import { Header, Segment, Table, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
// import { Image } from 'semantic-ui-react';
import ImageProgressive from '../../../../components/progressiveImage/ImageProgressive';
import { withTranslation, Translation } from 'react-i18next';

class SegmentOperatorAndItemPriceInformation extends Component {
  renderRoomPrices = () => {
    return (
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={styles.paddingBottomZero}>
              {/* Room Type */}
              <Translation>{t => <>{t('roomType')}</>}</Translation>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={styles.paddingBottomZero}
            >
              {/* Price */}
              <Translation>{t => <>{t('price')}</>}</Translation>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.roomPrices.length > 0
            ? this.props.roomPrices.map(item => (
                <Table.Row key={item.Id}>
                  <Table.Cell style={styles.paddingBottomZero}>
                    {/* {item.Room} */}
                    <Translation>{t => <>{t(item.Room)}</>}</Translation>
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    style={styles.paddingBottomZero}
                  >
                    {item.Currency + ' ' + item.Price.toLocaleString('id')}
                  </Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
    );
  };
  renderPaymentTerms = () => {
    return (
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={styles.paddingBottomZero}>
              {/* Description */}
              <Translation>{t => <>{t('description')}</>}</Translation>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              style={styles.paddingBottomZero}
            >
              {/* Payment Percentage */}
              <Translation>{t => <>{t('paymentPercentage')}</>}</Translation>
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="right"
              style={styles.paddingBottomZero}
            >
              {/* Paid On */}
              <Translation>{t => <>{t('paidOn')}</>}</Translation>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.paymentTerms.length > 0
            ? this.props.paymentTerms.map((item, i) => (
                <Table.Row key={i}>
                  <Table.Cell style={styles.paddingBottomZero}>
                    {item.Description}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="center"
                    style={styles.paddingBottomZero}
                  >
                    {item.PaymentPercentage}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    style={styles.paddingBottomZero}
                  >
                    {item.PaidOn}
                  </Table.Cell>
                </Table.Row>
              ))
            : null}
        </Table.Body>
      </Table>
    );
  };
  renderTourOperatorInformation = () => {
    let tourOperator = this.props.tourOperator;
    return (
      <Grid>
        <Grid.Column width={4}>
          {/* <Image src={tourOperator ? tourOperator.ImageUrl : ''} size="small" /> */}
          <ImageProgressive
            preview={tourOperator ? tourOperator.ImageUrl : ''}
            src={tourOperator ? tourOperator.ImageUrl : ''}
            size="small"
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  {/* Tour Operator Name */}
                  <Translation>{t => <>{t('tourOperatorName')}</>}</Translation>
                </Table.Cell>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  : {tourOperator ? tourOperator.Name : null}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  {/* Email */}
                  <Translation>{t => <>{t('email')}</>}</Translation>
                </Table.Cell>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  : {tourOperator ? tourOperator.Email : null}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  {/* Telephone */}
                  <Translation>{t => <>{t('telephone')}</>}</Translation>
                </Table.Cell>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  : {tourOperator ? tourOperator.Telephone : null}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  {/* Region */}
                  <Translation>{t => <>{t('region')}</>}</Translation>
                </Table.Cell>
                <Table.Cell style={styles.paddingBottomZeroNoBorder}>
                  : {tourOperator ? tourOperator.RegionName : null}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    );
  };
  render() {
    const { t } = this.props;
    return (
      <div style={styles.segmentNoPadding}>
        <Header style={styles.headerGoldNoPadding} as="h5" attached="top">
          {/* Room Allocation Price */}
          {t('roomAllocationPrice')}
        </Header>
        <Segment attached>{this.renderRoomPrices()}</Segment>
        {!this.props.isVariableDate && (
          <React.Fragment>
            <Header style={styles.headerGoldNoPadding} as="h5" attached>
              {/* Payment Terms */}
              {t('paymentTerms')}
            </Header>
            <Segment attached>{this.renderPaymentTerms()}</Segment>
          </React.Fragment>
        )}
        <Header style={styles.headerGoldNoPadding} as="h5" attached>
          {/* Tour Operator Information */}
          {t('tourOperatorInformation')}
        </Header>
        <Segment attached="bottom">
          {this.renderTourOperatorInformation()}
        </Segment>
      </div>
    );
  }
}
SegmentOperatorAndItemPriceInformation.propTypes = {
  paymentTerms: PropTypes.array,
  roomPrices: PropTypes.array,
  tourOperator: PropTypes.object,
  isVariableDate: PropTypes.bool,
  t: PropTypes.func,
};
export default withTranslation()(SegmentOperatorAndItemPriceInformation);
