import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Header, Label, Icon, Button } from 'semantic-ui-react';
import styles from './styles';
import Facilities from '../../../../../components/common/facilities';
import ModalDetailListRoom from '../../modal/modalDetailListRoom/ModalDetailListRoom';
import { connect } from 'react-redux';

class AccommodationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  openCloseModal = () => {
    const { data, handleOpenRooms, closePopup } = this.props;
    closePopup();
    handleOpenRooms(data.Id);
  };

  render() {
    const {
      data,
      loading,
      listAccommodationRoomById,
      setAccommodationRoom,
      closeModalAccommodation,
    } = this.props;
    const { openModalRoom } = this.state;
    const stars = Array(data.AccommodationRating);
    const type = data.AccommodationType.Name;
    const facilities = data.ProfileFacilities;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h4">{data.Name}</Header>
            <Header as="h4" style={styles.noMarginTop}>
              <Label basic style={styles.label}>
                {type + ' '}
              </Label>{' '}
              <label>
                {stars.map((data, index) => (
                  <Icon
                    key={data + index}
                    name="star"
                    style={styles.goldColor}
                  />
                ))}
              </label>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={styles.noPaddingTop}>
          <Grid.Column width="1">
            <Icon name="map marker alternate" style={styles.goldColor} />
          </Grid.Column>
          <Grid.Column width="14">
            <p>{data.Address}</p>
          </Grid.Column>
        </Grid.Row>
        <Facilities facilities={facilities} />
        <Grid.Row>
          <Grid.Column width={16} textAlign="right">
            <Button onClick={this.openCloseModal} style={styles.buttonGold}>
              See Room
            </Button>
          </Grid.Column>
        </Grid.Row>
        <ModalDetailListRoom
          open={openModalRoom}
          onClose={this.closeModal}
          data={listAccommodationRoomById}
          header={data.Name}
          desc={data.Name}
          amenities={facilities}
          closeModalAccommodation={closeModalAccommodation}
          loading={loading}
          setAccommodationRoom={setAccommodationRoom}
          accommodationProfile={data}
        />
      </Grid>
    );
  }
}
const mapStateToProps = state => {
  return {
    listAccommodationRoomById: state.accommodation.accommodationRooms,
    loading: state.accommodation.loading,
  };
};
AccommodationPanel.propTypes = {
  data: PropTypes.object,
  listAccommodationRoomById: PropTypes.array,
  loading: PropTypes.bool,
  headlineProgram: PropTypes.object,
  accomIndex: PropTypes.number,
  setAccommodationRoom: PropTypes.func,
  closeModalAccommodation: PropTypes.func,
  closePopup: PropTypes.func,
  handleOpenRooms: PropTypes.func,
};

export default connect(mapStateToProps)(AccommodationPanel);
