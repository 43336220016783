import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Collapse } from 'react-collapse';
import HeaderWithIcon from '../../../../../components/common/headerWithIcon';
import styles from './styles';
import CollapseButton from '../../../../../components/button/BtnCollapse';
import DailyProgram from '../../dailyProgram/DailyProgram';
import { withTranslation } from 'react-i18next';

import {
  resetHeadLineStatus,
  setDailyProgram,
  getDurationAddresstoId,
  getDurationIdtoAddress,
  getDurationIdtoId,
  resetGetDurationStatus,
  resetMappingDuration,
  resetGetDurationCount,
  setGetDurationCount,
  setMappingObj,
  resetSetDailyProgramStatus,
  setHeadlineProgram,
} from '../../../actions/customPackageAction';
import {
  dailyGenerate4,
  getDrivingArray,
  isAccommodationEmpty,
  // mappingDuration,
  getFunctionAfterDriving,
  copyObject,
  getFunctionDP,
  editFunctionDP,
  editFunctionDPBot,
  getFunctionDPBot,
  deleteActivity,
  fixafterDelete,
  addItemTransportation,
  deleteTransportation,
  changeTimeSummaryProgramAfterAddActivity,
  isAccommodationTimeNeedChange,
  isBeforeDeparture,
  fixDrivingDestination,
} from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import {
  getTransportationTypes,
  getTransportationSeatTypes,
  getTransportationUnitsFilter,
} from '../../../actions/transportationAction';
import { getTransportationProfileCategories } from '../../../actions/transportationAction';
import Loader from '../../../../../components/common/loader';
import EmptyImg from '../../../../../assets/svg/Itinerary.svg';
import { setTransport } from '../../../../../genericFunction/itineraryBuilder/demoPrice';
import { CUSTOM, FIXED } from '../../../../../constant/packageTypes';
import getDemoPriceObj from '../../../services/helper/getDemoPriceObject';
class TourItinerary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true,
      totalGetDurations: 0,
      transportStore: [],
      generateDaily: true,
    };
  }
  componentDidUpdate() {
    if (this.props.setHeadLineStatus === 'success') {
      this.props.resetHeadLineStatus();
      if (this.state.generateDaily) {
        this.generateDailyProgram(this.props);
      } else this.setState({ generateDaily: true });
    }
    if (['success', 'failed'].indexOf(this.props.getDurationStatus) !== -1) {
      this.props.resetGetDurationStatus();
    }
    if (
      this.state.totalGetDurations &&
      this.props.getDurationCount &&
      this.state.totalGetDurations === this.props.getDurationCount
    ) {
      this.props.resetMappingDuration();
      this.props.resetGetDurationCount();
      this.setState({
        ...this.state,
        totalGetDurations: 0,
      });
    }
    if (this.props.isSetDailyProgramSuccess) {
      let generateDaily = true;
      this.props.resetSetDailyProgramStatus();
      if (
        isAccommodationTimeNeedChange(
          this.props.dailyProgram,
          this.props.headlineProgram.MainPrograms
        )
      ) {
        let newMainProg = changeTimeSummaryProgramAfterAddActivity(
          this.props.dailyProgram,
          this.props.headlineProgram.MainPrograms
        );
        let headline = { ...this.props.headlineProgram };
        headline.MainPrograms = newMainProg;
        this.props.setHeadlineProgram(headline);
        generateDaily = false;
      }
      let transport = setTransport(this.props.dailyProgram);
      this.setState({
        ...this.state,
        transportStore: transport,
        generateDaily: generateDaily,
      });
    }
  }
  handleCollapse = () => {
    let isCollapse = this.state.isCollapse;
    isCollapse = !isCollapse;
    this.setState({ ...this.state, isCollapse: isCollapse });
  };
  generateDailyProgram = props => {
    let { headlineProgram, dailyProgram, movementList, isVariableDate } = props;
    let hlProg = { ...headlineProgram };
    let dP = dailyProgram;
    if (
      movementList.length > 0 &&
      isAccommodationEmpty(hlProg.MainPrograms) === false
    ) {
      dP = dailyGenerate4(
        hlProg.Departures,
        hlProg.Returns,
        hlProg.MainPrograms,
        dailyProgram,
        movementList,
        isVariableDate,
        this.props.listCityInCountry
      );
      this.createDrivingArray(dP, isVariableDate);
    }
    this.props.setDailyProgram(dP);
  };
  async createDrivingArray(dailyProgram, isVariable) {
    let newDP = {};
    if (!isVariable) {
      let drivings = getDrivingArray(dailyProgram);
      this.setState({ totalGetDurations: drivings.length });
      newDP = await this.getDrivingDurations3(drivings, dailyProgram);
      newDP = getFunctionAfterDriving(newDP);
    }

    this.props.setDailyProgram(isVariable ? dailyProgram : newDP);
  }
  setDailyProgramAfterAdd = (
    dailyProgram,
    dayIdx,
    moveIdx,
    move,
    movementList,
    action
  ) => {
    const { isVariableDate } = this.props;
    this.generateDailyPrograms(
      dailyProgram,
      dayIdx,
      moveIdx,
      move,
      movementList,
      action,
      isVariableDate
    );
  };
  async generateDailyPrograms(
    dailyProgram,
    dayIdx,
    moveIdx,
    move,
    movementList,
    action,
    isVariableDate
  ) {
    let newDP = {};
    let drivings = getDrivingArray(dailyProgram);
    // this.setState({ totalGetDurations: drivings.length });
    newDP = await this.getDrivingDurations3(drivings, dailyProgram);
    newDP = fixDrivingDestination(newDP);
    let isBeforeDepartures = isBeforeDeparture(newDP, dayIdx, moveIdx);
    if (action === 'ADD') {
      if (dayIdx !== newDP.length - 1 && !isBeforeDepartures) {
        newDP = getFunctionDP(newDP, dayIdx, moveIdx, move, movementList);
      } else
        newDP = getFunctionDPBot(newDP, dayIdx, moveIdx, move, movementList);
    } else {
      if (dayIdx !== newDP.length - 1 && !isBeforeDepartures) {
        newDP = editFunctionDP(newDP, dayIdx, moveIdx, move, movementList);
      } else
        newDP = editFunctionDPBot(newDP, dayIdx, moveIdx, move, movementList);
    }
    this.props.setDailyProgram(isVariableDate ? dailyProgram : newDP);
  }
  async getDrivingDurations3(drivings, daillyProgram) {
    let { durationStore } = this.props;
    let dp = copyObject(daillyProgram);
    for (var i = 0; i < drivings.length; i++) {
      let obj = drivings[i];
      let existingDuration = durationStore.find(
        item => item.from === obj.from && item.to === obj.to
      );
      if (existingDuration) {
        dp[obj.day].Movements[obj.index].Duration = existingDuration.duration;
      } else if (obj.fromType === 'ID' && obj.toType === 'ADDRESS') {
        let response = await this.props.getDurationIdtoAddress(
          obj.from,
          obj.to,
          obj.day,
          obj.index
        );

        dp[obj.day].Movements[obj.index].Duration = response
          ? response.payload.Duration.value
          : 0;
      } else if (obj.fromType === 'ADDRESS' && obj.toType === 'ID') {
        let response = await this.props.getDurationAddresstoId(
          obj.from,
          obj.to,
          obj.day,
          obj.index
        );
        dp[obj.day].Movements[obj.index].Duration = response
          ? response.payload.Duration.value
          : 0;
      } else {
        let response = await this.props.getDurationIdtoId(
          obj.from,
          obj.to,
          obj.day,
          obj.index
        );
        dp[obj.day].Movements[obj.index].Duration = response
          ? response.payload.Duration.value
          : 0;
      }
    }
    return dp;
  }
  deleteAct = async (moveIdx, dayIdx) => {
    let { dailyProgram } = this.props;
    let newDP = deleteActivity(dayIdx, moveIdx, dailyProgram);
    newDP = fixDrivingDestination(newDP);
    let drivings = getDrivingArray(newDP);
    newDP = await this.getDrivingDurations3(drivings, newDP);
    newDP = fixafterDelete(dailyProgram, newDP, dayIdx, moveIdx);
    this.props.setDailyProgram(newDP);
  };

  //==================================
  //========Transportation============
  //==================================
  getTransportationData = transData => {
    this.props.getTransportationTypes();
    this.props.getTransportationSeatTypes();
    this.props.getTransportationUnitsFilter(
      transData,
      this.helperGetDemoPriceObjForExtimatedPrice(transData)
    );
    this.props.getTransportationProfileCategories();
  };

  helperDeleteOldActivityInEstimatedPrice = (demoPrice, transData) => {
    let newDemoPrice = { ...demoPrice };
    let dataToFilter = newDemoPrice.Transportations;
    newDemoPrice.Transportations = dataToFilter.filter(
      item => item.DateTime !== transData.requestedDateTime
    );
    return newDemoPrice;
  };

  helperGetDemoPriceObjForExtimatedPrice = transData => {
    const { roomAllocation, dailyProgram, headlineProgram } = this.props;
    let obj = getDemoPriceObj(roomAllocation, headlineProgram, dailyProgram);
    let newObj = this.helperDeleteOldActivityInEstimatedPrice(obj, transData);
    return newObj;
    // return obj;
  };
  setTransportItem = (dayIdx, moveIdx, transport, service, hour) => {
    this.setTransport(dayIdx, moveIdx, transport, service, hour);
  };
  setTransport = (dayIdx, moveIdx, transport, service, hour) => {
    const { dailyProgram } = this.props;
    let newDP = addItemTransportation(
      dailyProgram,
      dayIdx,
      moveIdx,
      transport,
      service,
      hour
    );
    this.props.setDailyProgram(newDP);
  };
  deleteTransportItem = (dayIdx, moveIdx) => {
    this.deleteTransport(dayIdx, moveIdx);
  };
  deleteTransport = (dayIdx, moveIdx) => {
    const { dailyProgram } = this.props;
    let newDP = deleteTransportation(dailyProgram, dayIdx, moveIdx);
    this.props.setDailyProgram(newDP);
  };
  render() {
    const { t } = this.props;
    const { transportStore } = this.state;
    const {
      dailyProgram,
      loading,
      headlineProgram,
      isVariableDate,
    } = this.props;
    const isAccomEmpty = isAccommodationEmpty(headlineProgram.MainPrograms);
    return (
      <Segment style={styles.segment}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={14}>
              <HeaderWithIcon
                as="h2"
                title={t('tourItinerary')} //"Tour Itinerary"
                icon="clipboard list"
              />
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              <CollapseButton
                handleCollapse={this.handleCollapse}
                style={styles.shadowTransparant}
                isCollapse={this.state.isCollapse}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Collapse isOpened={this.state.isCollapse}>
          <div style={styles.paddingSideTwenty}>
            {isAccomEmpty ? (
              <div style={styles.textCenter}>
                <img src={EmptyImg} width="400" alt="itinerary" />
                <p>
                  {/* Please complete general information and destinations summary{' '} */}
                  {t('completeGeneralInformation')}
                </p>
              </div>
            ) : (
              <DailyProgram
                dailyProgram={dailyProgram}
                setDailyProgramAfterAdd={this.setDailyProgramAfterAdd}
                deleteActivity={this.deleteAct}
                getTransportationData={this.getTransportationData}
                setTransportItem={this.setTransportItem}
                deleteTransportItem={this.deleteTransportItem}
                transportStore={transportStore}
                currentPackage={isVariableDate ? FIXED : CUSTOM}
                setDailyProgram={this.props.setDailyProgram}
              />
            )}
          </div>
        </Collapse>
        <Loader active={loading} inverted={true} />
      </Segment>
    );
  }
}
const mapStateToProps = state => {
  return {
    dailyProgram: state.customPackage.dailyProgram,
    headlineProgram: state.customPackage.HeadlineProgram,
    setHeadLineStatus: state.customPackage.setHeadLineStatus,
    movementList: state.customPackage.movementList,
    getDurationStatus: state.customPackage.getDurationStatus,
    getDurationCount: state.customPackage.getDurationCount,
    durationMap: state.customPackage.durationMap,
    durationStore: state.customPackage.durationStore,
    loading: state.customPackage.loading,
    isSetDailyProgramSuccess: state.customPackage.isSetDailyProgramSuccess,
    listCityInCountry: state.location.cityInCountry,
    roomAllocation: state.customPackage.roomAllocation,
  };
};
TourItinerary.propTypes = {
  dailyProgram: PropTypes.array,
  headlineProgram: PropTypes.object,
  setHeadLineStatus: PropTypes.string,
  resetHeadLineStatus: PropTypes.func,
  movementList: PropTypes.array,
  setDailyProgram: PropTypes.func,
  getDurationAddresstoId: PropTypes.func,
  getDurationIdtoAddress: PropTypes.func,
  getDurationIdtoId: PropTypes.func,
  getDurationStatus: PropTypes.string,
  resetGetDurationStatus: PropTypes.func,
  resetMappingDuration: PropTypes.func,
  resetGetDurationCount: PropTypes.func,
  getDurationCount: PropTypes.number,
  durationMap: PropTypes.array,
  mappingDuration: PropTypes.func,
  durationStore: PropTypes.array,
  setGetDurationCount: PropTypes.func,
  setMappingObj: PropTypes.func,
  getTransportationTypes: PropTypes.func,
  getTransportationSeatTypes: PropTypes.func,
  getTransportationUnitsFilter: PropTypes.func,
  getTransportationProfileCategories: PropTypes.func,
  addItemTransportation: PropTypes.func,
  loading: PropTypes.bool,
  resetSetDailyProgramStatus: PropTypes.func,
  isSetDailyProgramSuccess: PropTypes.bool,
  setHeadlineProgram: PropTypes.func,
  isVariableDate: PropTypes.bool,
  listCityInCountry: PropTypes.array,
  t: PropTypes.func,
  roomAllocation: PropTypes.object,
};
export default connect(
  mapStateToProps,
  {
    resetHeadLineStatus,
    setDailyProgram,
    getDurationAddresstoId,
    getDurationIdtoAddress,
    getDurationIdtoId,
    resetGetDurationStatus,
    resetMappingDuration,
    resetGetDurationCount,
    setGetDurationCount,
    setMappingObj,
    getTransportationTypes,
    getTransportationSeatTypes,
    getTransportationUnitsFilter,
    getTransportationProfileCategories,
    resetSetDailyProgramStatus,
    setHeadlineProgram,
  }
)(withTranslation()(TourItinerary));
