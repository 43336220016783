import * as types from './constant/actionTypes';

const initialState = {
  loading: false,
  userInfo: {},
  getUserInfoStatus: '',
  errors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_USER_INFO
    //---------------------------------
    case types.GET_USER_INFO:
      return { ...state };
    case types.GET_USER_INFO_PENDING:
      return { ...state, loading: true };
    case types.GET_USER_INFO_FULFILLED:
      return {
        ...state,
        userInfo: action.payload.data,
        getUserInfoStatus: 'success',
        errors: {},
        loading: false,
      };
    case types.GET_USER_INFO_REJECTED:
      return {
        ...state,
        userInfo: {},
        getUserInfoStatus: 'failed',
        errors: action.payload.response.data,
        loading: false,
      };
    case types.RESET_GET_USER_INFO_STATUS:
      return {
        ...state,
        getUserInfoStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
