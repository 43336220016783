import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import googleAPIKey from '../../../../config/envApiKey';
import MarkerWithLabel from './markers/MarkerWithLabel';
import MarkerNoLabel from './markers/MarkerNoLabel';
import { filterDataBySecondData } from '../../services/helper/filterData';
// import style from './styles';
import createMapOptions from './constant/markerOptions';

class Maps extends Component {
  state = {
    center: {
      lat: 1.28,
      lng: 103.85,
    },
    zoom: 13,
  };
  shouldComponentUpdate(nextProps) {
    if (nextProps.coordinates !== this.props.coordinates) {
      return true;
    } else return true;
  }
  render() {
    const { mapStyle, data, coordinates, accommodation } = this.props;
    const { zoom } = this.state;
    let newCoordinate = filterDataBySecondData(
      coordinates,
      data,
      'id',
      'ServiceItemId'
    );
    const centerByAccom = accommodation
      ? coordinates.find(item => item.id === accommodation.id)
      : null;
    const center = centerByAccom ? centerByAccom.coordinate : null;

    return (
      <div style={mapStyle}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleAPIKey }}
          defaultZoom={zoom}
          center={center}
          gestureHandling="greedy"
          options={createMapOptions}
        >
          {newCoordinate &&
            newCoordinate.length > 1 &&
            newCoordinate.map((item, i) => {
              let excursion =
                data.length > 0
                  ? data.find(data => data.ServiceItemId === item.id)
                  : null;
              return (
                <MarkerWithLabel
                  key={i}
                  lat={item.coordinate.lat}
                  lng={item.coordinate.lng}
                  text={excursion ? excursion.Name : ''}
                  icon="excursion-map-marker"
                  className="labelStyleMap"
                />
              );
            })}
          <MarkerNoLabel
            lat={center ? center.lat : null}
            lng={center ? center.lng : null}
            className="hotelMarker-red"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

Maps.propTypes = {
  center: PropTypes.object,
  zoom: PropTypes.number,
  mapStyle: PropTypes.object,
  lat: PropTypes.number,
  lng: PropTypes.number,
  text: PropTypes.string,
  coordinates: PropTypes.array,
  data: PropTypes.array,
  accommodation: PropTypes.object,
};
export default Maps;
