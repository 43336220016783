import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import MultiCarousell from './MultipleCarousel';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const Partners = () => {
  const { t } = useTranslation();

  return (
    <Container className="margin-bottom-fifteen padding-side-twentyfive container-fit">
      <h1
        className="margin-bottom-twentyfive margin-bottom-ten"
        style={styles.headerStyle}
      >
        {/* Our Partners */}
        {t('ourPartner')}
      </h1>
      <p style={{ ...styles.textCenter, ...styles.font20 }}>
        {/* We work with various networks throughout the world to */}
        {t('ourPartnerContent1')}
        <br />
        {/* ensure you get the best price for you to sell. */}
        {t('ourPartnerContent2')}
      </p>
      <MultiCarousell />
    </Container>
  );
};

Partners.propTypes = {
  t: PropTypes.func,
};

export default Partners;
