import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';

const BenefitItem = props => (
  <Grid>
    <Grid.Row>
      <Grid.Column width={3} style={styles.noPaddingRightCenter}>
        <Icon className={props.icon} />
      </Grid.Column>
      <Grid.Column width={13}>
        <h3 style={styles.goldColor} className="font24">
          {props.title}
        </h3>
        <p>{props.description}</p>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

BenefitItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default BenefitItem;
