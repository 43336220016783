import React from 'react';
import { Modal, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Login from '../login/Login';

const ModalLogin = props => {
  return (
    <Modal
      closeIcon
      size={props.size}
      open={props.openModal}
      onClose={props.close}
    >
      <Modal.Content>
        <Grid.Row>
          <Grid.Column>
            <Login closePopup={props.close} />
          </Grid.Column>
        </Grid.Row>
      </Modal.Content>
    </Modal>
  );
};

ModalLogin.propTypes = {
  openModal: PropTypes.bool,
  size: PropTypes.string,
  close: PropTypes.func,
};

export default ModalLogin;
