import React from 'react';
import { Grid, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
const BtnBackAndNext = props => (
  <Grid.Row>
    <Grid.Column width={8} textAlign="right">
      <Button
        name={props.nameBack}
        value={props.valueBack}
        style={styles.buttonRedPadding15px}
        onClick={props.handleButton}
      >
        {props.titleButtonBack}
      </Button>
    </Grid.Column>
    <Grid.Column width={8}>
      <Button
        name={props.nameNext}
        value={props.valueNext}
        style={styles.buttonGoldPadding15px}
        onClick={props.handleButton}
      >
        {props.titleButtonNext}
      </Button>
    </Grid.Column>
  </Grid.Row>
);

BtnBackAndNext.propTypes = {
  nameBack: PropTypes.string,
  nameNext: PropTypes.string,
  valueBack: PropTypes.string,
  valueNext: PropTypes.string,
  titleButtonBack: PropTypes.string,
  titleButtonNext: PropTypes.string,
  handleButton: PropTypes.func,
};
export default BtnBackAndNext;
