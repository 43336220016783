const style = {
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  paddingSideTwenty: {
    padding: '10px 20px',
  },
  shadowTransparant: { boxShadow: '0 0 0 transparent' },
  textCenter: { textAlign: 'center' },
};

export default style;
