import {
  GOLD_COLOR,
  BACKGROUND_COLOR,
  WHITE_COLOR,
} from '../../../../constant/colour';
const style = {
  goldColor: { color: GOLD_COLOR },
  navbarTopGold: {
    fontSize: '16px',
    color: GOLD_COLOR,
    // marginTop: '-2em',
    backgroundColor: 'transparent',
    // padding: '0px',
    paddingRight: '0px',
    paddingLeft: '0px',
    marginBottom: '-1.4em',
  },
  navbarTopWhite: {
    fontSize: '16px',
    color: WHITE_COLOR,
    // marginTop: '-2em',
    backgroundColor: 'transparent',
    // padding: '0px',
    paddingRight: '0px',
    paddingLeft: '0px',
    marginBottom: '-1.4em',
  },
  noDisplay: {
    display: 'none',
  },
  readyFixPriceMenuStyle: {
    backgroundColor: BACKGROUND_COLOR,
    border: `1px solid ${BACKGROUND_COLOR}`,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    color: GOLD_COLOR,
    zIndex: '999',
    minHeight: '4em',
  },
  buttonBookNowStyle: {
    background: BACKGROUND_COLOR,
    color: GOLD_COLOR,
    border: `1px solid ${GOLD_COLOR}`,
    // marginTop: '-4em',
  },
  paddingFive: { padding: '5px' },
  menuPrice: {
    backgroundColor: GOLD_COLOR,
    border: `1px solid ${BACKGROUND_COLOR}`,
    width: '11em',
  },
  menuContainer: { fontSize: '16px', justifyContent: 'left' },
  readyMenuStyle: {
    backgroundColor: BACKGROUND_COLOR,
    border: `1px solid ${BACKGROUND_COLOR}`,
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
    color: GOLD_COLOR,
    zIndex: '999',
    minHeight: '4em',
    justifyContent: 'center',
  },
  zIndex1: { zIndex: 1 },
  marginleft2em: { marginLeft: '2em' },
  noPaddingTop: { paddingTop: '0px' },
  noPaddingBottom: { paddingBottom: '0px' },
};

export default style;
