import React from 'react';
import { Segment, Grid, Icon, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  DRIVING,
  FREETIME,
  FREETIMELOCKED,
} from '../../../../../constant/activityTypes';
import { timeDuration } from '../../../../../genericFunction/moment';
import IconButton from '../../../../../components/button/BtnIcon';
// import { connect } from 'react-redux';
import { getOvertimeTransport } from '../../../../../genericFunction/itineraryBuilder/demoPrice';
import {
  convertDateFormat,
  isSameDate,
} from '../../../../../genericFunction/moment';
import { isTransportValid } from '../../../../../genericFunction/itineraryBuilder/dailyProgram';
import { CUSTOM, READY, FIXED } from '../../../../../constant/packageTypes';
import { MessageOverload } from './';
import { withTranslation, Translation } from 'react-i18next';
class ActivityWithLine extends React.Component {
  iconRender() {
    const { MovementName } = this.props.data;
    switch (MovementName) {
      case DRIVING:
        return 'fas fa-car fa-2x';
      case FREETIME:
        return 'fas fa-clock fa-2x';
      case FREETIMELOCKED:
        return 'fas fa-clock fa-2x';
      default:
        return null;
    }
  }
  handleEdit = () => {
    let { openTransportationModal, index, movements, data, day } = this.props;
    let prevMove = movements[index - 1];
    let nextMove = movements[index + 1];
    openTransportationModal(
      prevMove.OriginDestination,
      nextMove.OriginDestination,
      data.DateTime,
      index,
      day,
      prevMove.DestinationName,
      nextMove.DestinationName
    );
  };
  handleDelete = () => {
    let { day, index } = this.props;
    this.props.deleteTransportItem(day, index);
  };
  handleEditActivity = () => {
    this.props.openModalSetActivity(
      this.props.data.MovementName,
      this.props.data,
      this.props.movements,
      this.props.index,
      this.props.day,
      'EDIT'
    );
  };
  renderNote = () => {
    const { Note } = this.props.data;
    if (Note) {
      return (
        <span>
          {/* Note */}
          <span style={styles.font700}>
            <Translation>{t => <>{t('note')}</>}</Translation>
          </span>
          : {Note}
        </span>
      );
    }
  };
  handleDeleteActivity = () => {
    this.props.deleteActivity(this.props.index, this.props.day);
  };
  getOverTime = () => {
    let { transportStore, data } = this.props;
    let overTime = 0;
    if (
      data.Item.ServiceItemId &&
      ['Transfer_with_guide', 'Transfer'].indexOf(data.Item.ServiceType) ===
        -1 &&
      transportStore
    ) {
      let transport = transportStore.find(
        item =>
          item.ServiceItemId === data.Item.ServiceItemId &&
          convertDateFormat(item.DateTime, 'YYYY-MM-DD') ===
            convertDateFormat(data.DateTime, 'YYYY-MM-DD')
      );
      overTime = transport ? getOvertimeTransport(transport) : 0;
    }
    if (overTime > 0) {
      return (
        <Message warning>
          {/* Overtime {overTime} h, there will be extra charge for transportation
          overtime. */}

          <Translation>
            {t => <>{t('warningTransportationOvertime', { hour: overTime })}</>}
          </Translation>
        </Message>
      );
    } else return null;
  };
  renderWarningMessage = () => {
    let { data, movements } = this.props;
    if (
      [READY, CUSTOM].indexOf(this.props.currentPackage) !== -1 &&
      data.MovementName === DRIVING
    ) {
      if (isTransportValid(movements, data)) {
        return (
          <Message warning>
            {/* {`Transportation type cannot be used because it exceeds the duration
          limit. Please change the type of transportation.`} */}
            <Translation>
              {t => <>{t('warningTransportationExceedsDuration')}</>}
            </Translation>
          </Message>
        );
      } else return null;
    }
  };
  renderButtonAction = (
    MovementName,
    isStatic,
    currentPackage,
    isActivityInCurrentDate,
    item
  ) => {
    if (!isStatic && [READY, CUSTOM].indexOf(currentPackage) !== -1) {
      if ([DRIVING, FREETIME].indexOf(MovementName) !== -1) {
        return (
          <Grid.Column width={6} textAlign="right">
            {(MovementName === FREETIME && isActivityInCurrentDate) ||
            MovementName === DRIVING ? (
              <IconButton
                name="edit"
                iconStyle={styles.buttonIconEdit}
                handleOnClick={
                  MovementName === DRIVING
                    ? this.handleEdit
                    : this.handleEditActivity
                }
                size="large"
              />
            ) : null}
            {(MovementName === DRIVING && !item.ServiceItemId) || (
              <IconButton
                name="times"
                iconStyle={styles.buttonIconDelete}
                handleOnClick={
                  MovementName === DRIVING
                    ? this.handleDelete
                    : this.handleDeleteActivity
                }
                size="large"
              />
            )}
          </Grid.Column>
        );
      }
    }
  };
  render() {
    const { t } = this.props;
    const { date } = this.props;
    const { data, currentPackage, isStatic } = this.props;
    const {
      MovementName,
      MovementDescription,
      DestinationName,
      DurationText,
      Duration,
      Item,
      DateTime,
    } = data;
    let str =
      [FREETIME, FREETIMELOCKED].indexOf(MovementName) !== -1 ? '' : 'with ';
    let isActivityInCurrentDate = isSameDate(date, DateTime);
    return (
      <Segment basic style={styles.noMargin}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}>
              <Icon style={styles.goldColor} className={this.iconRender()} />
            </Grid.Column>
            <Grid.Column width={14}>
              <Grid>
                <Grid.Row style={styles.borderBottomGrey}>
                  <Grid.Column width={10}>
                    <h5>
                      {MovementName === FREETIMELOCKED
                        ? `${t('freeTime')}` // 'Free time'
                        : MovementName === DRIVING &&
                          !Item.ServiceItemId &&
                          [READY, CUSTOM].indexOf(currentPackage) !== -1
                        ? `${t('selfTransportation')}` // 'Self Transportation'
                        : MovementDescription}
                    </h5>
                  </Grid.Column>
                  {this.renderButtonAction(
                    MovementName,
                    isStatic,
                    currentPackage,
                    isActivityInCurrentDate,
                    Item
                  )}
                </Grid.Row>
                <Grid.Row style={styles.noPaddingBot}>
                  <Grid.Column width={16}>
                    {DestinationName ? `${t('at')} ${DestinationName} -` : ``}
                    {t('duration')}{' '}
                    {currentPackage === FIXED && DurationText
                      ? DurationText
                      : timeDuration(Duration)}
                    <br />
                    <span>
                      {Item
                        ? Item.Name
                          ? `${str}${
                              MovementName === DRIVING
                                ? `${Item.Name} ${
                                    Item.Category ? `- ${Item.Category}` : ''
                                  } ${
                                    Item.Capacity
                                      ? `, ${Item.Capacity} ${t('seats')}` //Seats
                                      : ''
                                  }`
                                : Item.Name
                            }${
                              MovementName === DRIVING
                                ? ` - ${
                                    Item.ServiceType
                                      ? Item.ServiceType.replace(/_/g, ' ')
                                      : ''
                                  }`
                                : ''
                            }`
                          : null
                        : null}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={styles.paddingFive}>
                  <Grid.Column width={16}>{this.renderNote()}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {[READY, CUSTOM].indexOf(currentPackage) !== -1 && this.getOverTime()}
        <div>{this.renderWarningMessage()}</div>
        <MessageOverload
          dateDay={date}
          dateActivity={DateTime}
          movementName={MovementName}
        />
      </Segment>
    );
  }
}

ActivityWithLine.propTypes = {
  data: PropTypes.object,
  MovementName: PropTypes.string,
  MovementDescription: PropTypes.string,
  DestinationName: PropTypes.string,
  DurationText: PropTypes.string,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  openTransportationModal: PropTypes.func,
  index: PropTypes.number,
  day: PropTypes.number,
  movements: PropTypes.array,
  deleteTransportItem: PropTypes.func,
  currentPackage: PropTypes.string,
  openModalSetActivity: PropTypes.func,
  deleteActivity: PropTypes.func,
  transportStore: PropTypes.array,
  isStatic: PropTypes.bool,
  date: PropTypes.string,
  t: PropTypes.func,
};
export default withTranslation()(ActivityWithLine);
