import * as types from '../actions/actionTypes';

const defaultQuickLaunch = {
  tourType: null,
  tourPaxType: null,
  desireDay: null,
  accommodationType: null,
  accommodationRating: null,
  accommodationLocation: null,
  startingDate: null,
  location: null,
};
const initialState = {
  dataFilterQuickLaunch: defaultQuickLaunch, //{}
  dataDemoJoinTour: {},
  dataDemoPrice: {},
  idTour: 0,
  dataBackCustomReady: false,
  dataTourCustomReady: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TEMP_FILTER_QUICK_LAUNCH:
      return { ...state, dataFilterQuickLaunch: action.data };
    case types.RESET_TEMP_FILTER_QUICK_LAUNCH:
      return { ...state, dataFilterQuickLaunch: defaultQuickLaunch };
    case types.SET_TEMP_DEMO_JOIN_TOUR:
      return { ...state, dataDemoJoinTour: action.data };
    case types.SET_TEMP_ID_TOUR:
      return { ...state, idTour: action.data };
    case types.SET_TEMP_TOUR_TRANSACTIONS_CONFIRMATION_DEMO_V2:
      return { ...state, dataTourTransactionsConfirmationDemoV2: action.data };
    case types.SET_TEMP_DEMO_PRICE:
      return { ...state, dataDemoPrice: action.data };
    case types.SET_TEMP_BACK_CUSTOM_READY:
      return { ...state, dataBackCustomReady: action.data };
    case types.SET_TEMP_TOUR_CUSTOM_READY:
      return { ...state, dataTourCustomReady: action.data };
    case types.RESET_TEMP_DEMO_JOIN_TOUR:
      return { ...state, dataDemoJoinTour: {} };
    case types.RESET_TEMP_DEMO_PRICE:
      return { ...state, dataDemoPrice: {} };
    case types.RESET_TEMP_BACK_CUSTOM_READY:
      return { ...state, dataBackCustomReady: false, dataTourCustomReady: {} };
    default:
      return state;
  }
};

export default reducer;
