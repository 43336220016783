import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputWithLabel from '../../../../components/input/WithLabel';
import styles from './styles';
import { withTranslation } from 'react-i18next';

class FormGuest extends Component {
  render() {
    const { t } = this.props;
    let {
      loadingCountry,
      loadingIdentityType,
      loadingTitleType,
      handleChange,
      identityType,
      identityNbr,
      countryId,
      guestTitle,
      firstName,
      lastName,
      id,
      validate,
      disabled,
    } = this.props;
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <InputWithLabel
              label={t('identityType')} //"Identity Type"
              inline={false}
              type="select"
              placeholder={t('placeholderIdentityType')} //"Select Type"
              labelStyles={styles.fontWeightBold}
              inputStyles={styles.inputSelectionStyle}
              options={this.props.listIdentityType}
              fluid={true}
              loading={loadingIdentityType}
              name="identityType"
              objectname={id}
              value={identityType}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label={t('identityNo')} //"Identity no."
              type="text"
              labelStyles={styles.displayBlockFontWeight}
              inputStyles={styles.inputTextStyle}
              fluid={true}
              name="identityNbr"
              objectname={id}
              value={identityNbr}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label={t('homeCountry')} //"Home Country"
              inline={false}
              type="select"
              placeholder={t('placeholderSelectCity')} //"Select City"
              labelStyles={styles.fontWeightBold}
              inputStyles={styles.inputSelectionStyle}
              options={this.props.listCountry}
              fluid={true}
              loading={loadingCountry}
              name="countryId"
              objectname={id}
              value={countryId}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label={t('title')} //"Title"
              inline={false}
              type="select"
              placeholder="Mr,Ms,etc"
              labelStyles={styles.fontWeightBold}
              inputStyles={styles.inputSelectionStyle}
              options={this.props.listTitleType}
              fluid={true}
              loading={loadingTitleType}
              name="guestTitle"
              objectname={id}
              value={guestTitle}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label={t('firstName')} //"First Name"
              type="text"
              labelStyles={styles.displayBlockFontWeight}
              inputStyles={styles.inputTextStyle}
              fluid={true}
              name="firstName"
              objectname={id}
              value={firstName}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
          <Form.Field>
            <InputWithLabel
              label={t('lastName')} //"Last Name"
              type="text"
              labelStyles={styles.displayBlockFontWeight}
              inputStyles={styles.inputTextStyle}
              fluid={true}
              name="lastName"
              objectname={id}
              value={lastName}
              handleChange={handleChange}
              validate={validate}
              disabled={disabled}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}
FormGuest.propTypes = {
  listCountry: PropTypes.array,
  listTitleType: PropTypes.array,
  listIdentityType: PropTypes.array,
  loadingCountry: PropTypes.bool,
  loadingIdentityType: PropTypes.bool,
  loadingTitleType: PropTypes.bool,
  identityType: PropTypes.string,
  identityNbr: PropTypes.string,
  countryId: PropTypes.string,
  guestTitle: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.number,
  handleChange: PropTypes.func,
  validate: PropTypes.object,
  disabled: PropTypes.bool,
  t: PropTypes.func,
};
export default withTranslation()(FormGuest);
