import { GOLD_COLOR } from '../../../../constant/colour';

const style = {
  timeLineIcon: {
    width: '20px',
    height: '20px',
    left: '10%',
    marginLeft: '-10px',
    marginTop: '20px',
    background: GOLD_COLOR,
  },
};

export default style;
