import React from 'react';
import { Breadcrumb, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const BreadCrumb = props => {
  const { t } = useTranslation();
  let { sections } = props;
  return (
    <Container className="margin-top-fifteen margin-bottom-fifteen padding-side-twentyfive container-fit">
      {sections.map((section, index) => (
        <Breadcrumb key={index}>
          <Breadcrumb.Section active={section.active}>
            {section.link ? (
              <Link to={section.href}>{section.content}</Link>
            ) : (
              // section.content
              t(section.content)
            )}
          </Breadcrumb.Section>
          {section.link ? <Breadcrumb.Divider icon="right angle" /> : null}
        </Breadcrumb>
      ))}
    </Container>
  );
};

BreadCrumb.propTypes = {
  sections: PropTypes.array,
  t: PropTypes.func,
};
export default BreadCrumb;
