//----library----
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal, Grid, Segment } from 'semantic-ui-react';
//----component------
import RoomCard from './RoomCard';
import Input from '../../../../../components/input/WithLabel';
import { LoaderModal, ButtonCloseModal } from '../../common';
import AccommodationDetailCard from './AccommodationDetailCard';
//-----style------
import Styles from './styles';

import { useTranslation } from 'react-i18next';

// const ModalAccommodationRoom = props =>
const ModalDetailListRoom = props => {
  const { t } = useTranslation();
  const [searchName, setSearchName] = useState('');

  const handleChange = (e, { value }) => {
    setSearchName(value);
  };
  const filterData = (listRoom, searchName) => {
    return listRoom.filter(
      menu => menu.Name.toLowerCase().indexOf(searchName.toLowerCase()) !== -1
    );
  };

  let {
    open,
    onClose,
    data,
    header,
    desc,
    amenities,
    loadingRoom,
    closeModalAccommodation,
    setAccommodationRoom,
    accommodationProfile,
  } = props;

  data = filterData(data, searchName);
  data = !loadingRoom ? data : [];
  return (
    <Modal open={open} onClose={onClose} size="small" closeOnDimmerClick>
      <Modal.Header style={Styles.backgroundModal}>
        Room Accommodation <ButtonCloseModal onClose={onClose} />
      </Modal.Header>
      <Modal.Content style={Styles.backgroundModal}>
        <Modal.Description style={Styles.margin10px}>
          <Grid>
            <Grid.Row>
              <AccommodationDetailCard data={accommodationProfile} />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header as="h5">
                  {/* Showing {data.length} {header}&#39;s Room */}
                  {t('showingAccRoom', {
                    count: data.length,
                    accommodation: header,
                  })}
                </Header>
              </Grid.Column>
              <Grid.Column width={16}>
                <Segment style={Styles.segmentSearch}>
                  <Input
                    name="searchName"
                    type="input"
                    icon="search"
                    iconPosition="left"
                    labelStyles={Styles.textTransparent}
                    transparent={true}
                    inputStyles={Styles.padding9pxWidth100percent}
                    value={searchName}
                    handleChange={handleChange}
                    placeholder={t('searchByRoomName')} //"Search by room name"
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {loadingRoom && (
                  <Segment style={Styles.segmentLoader}>
                    <LoaderModal loading={loadingRoom} />
                  </Segment>
                )}{' '}
                <LoaderModal loading={loadingRoom} />
                {data.length !== 0 && !loadingRoom ? (
                  data.map((data, index) => (
                    <RoomCard
                      key={index}
                      desc={desc}
                      amenities={amenities}
                      name={data.Name}
                      type={data.AccommodationType.Name}
                      capacity={data.AccommodationType.Capacity}
                      image={data.ImageUrl}
                      images={data.ItemImages}
                      serviceType={data.AccommodationItemServiceTypes}
                      isPromo={data.IsPromo}
                      room={data}
                      onClose={onClose}
                      closeModalAccommodation={closeModalAccommodation}
                      setAccommodationRoom={setAccommodationRoom}
                      isInstantConfirmation={data.IsInstantConfirmation}
                    />
                  ))
                ) : !loadingRoom ? (
                  <Header textAlign="center" as="h4">
                    {/* There is no result of room for your accommodation{' '} */}
                    {t('noResultRoomAccommodation')}
                  </Header>
                ) : (
                  <LoaderModal loadingRoom={loadingRoom} />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

ModalDetailListRoom.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.array,
  header: PropTypes.string,
  desc: PropTypes.string,
  amenities: PropTypes.array,
  closeModalAccommodation: PropTypes.func,
  loadingRoom: PropTypes.bool,
  loadingGetRoom: PropTypes.bool,
  setAccommodationRoom: PropTypes.func,
  accommodationProfile: PropTypes.object,
  t: PropTypes.func,
};
export default ModalDetailListRoom;
