import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { withTranslation } from 'react-i18next';

class TabMenu extends Component {
  render() {
    const { t } = this.props;
    let { tabActive, handleTab } = this.props;
    return (
      <Menu secondary>
        <Menu.Item
          name="tabActive"
          value="account detail"
          onClick={handleTab}
          style={
            tabActive === 'account detail'
              ? styles.tabActive
              : styles.tabNonActive
          }
        >
          {/* Account Detail */} {t('accountDetail')}
        </Menu.Item>
        <Menu.Item
          name="tabActive"
          value="change password"
          onClick={handleTab}
          style={
            tabActive === 'change password'
              ? styles.tabActive
              : styles.tabNonActive
          }
        >
          {/* Change Password */} {t('changePassword')}
        </Menu.Item>
      </Menu>
    );
  }
}
TabMenu.propTypes = {
  tabActive: PropTypes.string,
  handleTab: PropTypes.func,
  t: PropTypes.func,
};
export default withTranslation()(TabMenu);
