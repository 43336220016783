const BACKGROUND_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
// const WHITE_COLOR = '#fff';

const style = {
  backgroundPrimary: {
    background: BACKGROUND_COLOR,
  },

  font20: {
    fontSize: '20px',
  },
  font36: {
    fontSize: '36px',
  },
  margin14: {
    marginTop: '14px',
    marginBottom: '14px',
  },

  loginPanelPadding: {
    padding: '5px 25px',
  },
  textDownloadApps: {
    color: GOLD_COLOR,
    marginBottom: '0px',
  },
  textCenter: {
    textAlign: 'center',
  },
  noPadding: {
    padding: '0px 0px 0px 0px',
  },
  paddingFifteen: {
    padding: '15px 15px 15px 15px',
  },
  textJustify: {
    textAlign: 'justify',
  },
  featureMiniIcon: {
    margin: '0px 5px 5px 5px',
  },
  noMarginTop: {
    marginTop: '0px',
  },
  buttonSeeDetailIndonesia: {
    backgroundColor: 'transparent',
    color: 'blue',
  },
  progressiveImage: {
    width: '100%',
    height: '280px',
    background: 'url',
    backgroundSize: 'cover',
    borderBottom: '0px',
    borderRadius: '5px 5px 0px 0px',
    padding: '0px 0px 0px 0px ',
  },
  slider1CarouselSection: {
    width: '100%',
    height: '282px',
    backgroundImage: '#BFCAC4',
  },
  slider2CarouselSection: {
    width: '100%',
    height: '458px',
  },
  //
  loginSegmentStyle: {
    boxShadow: 'none',
    border: 'none',
  },
  alignCenterMarginBotFive: { textAlign: 'center', marginBottom: '5px' },
  font14: {
    fontSize: '14px',
  },
  colorRed: { color: 'red' },
  alignRight: { textAlign: 'right' },
  goldColor: {
    color: GOLD_COLOR,
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  loginButton: {
    color: BACKGROUND_COLOR,
    fontSize: '14px',
  },
  linkSection: {
    textAlign: 'center',
    fontSize: '14px',
    marginBottom: '10px',
    marginTop: '10px',
  },
};

export default style;
