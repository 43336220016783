import React, { Component } from 'react';
import { Segment, Header, Icon, Loader } from 'semantic-ui-react';
import RestaurantCarousel from './RestaurantCarousel';
import styles from '../../styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getMenuById,
  getRestaurantProfileByServiceItem,
  resetStatusRestaurant,
} from '../../../shared/actions/restaurantAction';
import { withTranslation } from 'react-i18next';
class SegmentRestaurant extends Component {
  state = {
    restaurantSum: [],
    selectRestaurants: [],
    selectMenus: [],
  };
  componentDidUpdate = () => {
    if (this.state.restaurantSum.length !== this.props.restaurantSum.length) {
      let { restaurantSum } = this.props;
      this.setState({
        ...this.state,
        restaurantSum,
        selectRestaurants: [],
        selectMenus: [],
      });
      this.getMenus(restaurantSum);
    }
  };
  getMenus = restaurantSum => {
    let serviceItemId = [];
    restaurantSum.length > 0 &&
      restaurantSum.map(item => {
        return (
          item.data &&
          item.data.map(e => {
            return (
              serviceItemId.some(id => id === e.ServiceItemId) ||
              (serviceItemId = serviceItemId.concat(e.ServiceItemId))
            );
          })
        );
      });

    return (
      serviceItemId &&
      //eslint-disable-next-line
      serviceItemId.map(id => {
        this.props
          .getMenuById(id)
          .then(() => this.setStateRestaurant('selectMenus', this.props.menu));
        this.props
          .getRestaurantProfileByServiceItem(id)
          .then(() =>
            this.setStateRestaurant(
              'selectRestaurants',
              this.props.restaurantProfileByServiceItem
            )
          );
      })
    );
  };

  setStateRestaurant = (name, value) => {
    this.setState({
      ...this.state,
      [name]: this.state[name].concat(value),
    });
  };

  render() {
    const { t } = this.props;
    let { restaurantSum, selectRestaurants, selectMenus } = this.state;
    let { loading } = this.props;
    return (
      <div>
        <Segment style={styles.segment}>
          <Header as="h3" className="no-margin">
            <Icon className="fas fa-utensils fa-2x" style={styles.goldColor} />{' '}
            {/* Restaurant */}
            {t('restaurant')}
          </Header>
          <Loader active={loading} inline="centered" />
          {selectRestaurants.length !== 0 && restaurantSum.length !== 0 ? (
            <div>
              <span className="margin-bottom-five" style={styles.greyColor}>
                {/* Note : We will provide you these or similar restaurant depending
                on availability */}
                {`${t('note')} : ${t('weWillProvideSimilarRestaurant')}`}
              </span>
              <RestaurantCarousel
                restaurantSum={restaurantSum}
                selectRestaurants={selectRestaurants}
                selectMenus={selectMenus}
              />
            </div>
          ) : (
            !loading && (
              <Header as="h4" textAlign="center">
                {/* No Restaurant Ordered in this Package */}
                {t('noRestaurantOrderediInThisPackage')}
              </Header>
            )
          )}
          {/* <Loader
            active={this.props.loadingAccommodation}
            description="getting accommodations"
          /> */}
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    getRestaurantProfileByServiceItemStatus:
      state.restaurant.getRestaurantProfileByServiceItemStatus,
    restaurantProfileByServiceItem:
      state.restaurant.restaurantProfileByServiceItem,
    loading: state.restaurant.loading,
    menu: state.restaurant.menu,
    getMenuByIdStatus: state.restaurant.getMenuByIdStatus,
  };
};
SegmentRestaurant.propTypes = {
  restaurantSum: PropTypes.array,
  getRestaurantProfileByServiceItemStatus: PropTypes.string,
  restaurantProfileByServiceItem: PropTypes.object,
  resetStatusRestaurant: PropTypes.func,
  getRestaurantProfileByServiceItem: PropTypes.func,
  getMenuById: PropTypes.func,
  menu: PropTypes.object,
  getMenuByIdStatus: PropTypes.string,
  loading: PropTypes.bool,
  t: PropTypes.func,
};
export default connect(
  mapStateToProps,
  {
    getRestaurantProfileByServiceItem,
    getMenuById,
    resetStatusRestaurant,
  }
)(withTranslation()(SegmentRestaurant));
