import React from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Checkbox, Header, Loader } from 'semantic-ui-react';

const FilterContent = props => {
  const {
    header,
    data,
    columns,
    gridStyles,
    columnStyles,
    name,
    handleChange,
    loading,
  } = props;
  return (
    <Card.Content>
      <Header as="h3">{header}</Header>
      <Grid columns={columns} style={gridStyles}>
        {!loading ? (
          data.map((item, index) => (
            <Grid.Column key={index} style={columnStyles}>
              <Checkbox
                name={name}
                value={item.key}
                label={item.text}
                checked={item.checked ? item.checked : false}
                onChange={handleChange}
              />
            </Grid.Column>
          ))
        ) : (
          <Loader active={loading} inline="centered" />
        )}
      </Grid>
    </Card.Content>
  );
};

FilterContent.propTypes = {
  header: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.number,
  gridStyles: PropTypes.object,
  columnStyles: PropTypes.object,
  name: PropTypes.string,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
};

export default FilterContent;
