import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import ExcursionCard from './ExcursionCard';
import { ModalDetailExcursion } from './../../../shared/components/modal';
import styles from '../../styles';
const NextArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' next'} onClick={onClick} />;
};
const PrevArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' prev'} onClick={onClick} />;
};
class MultipleCarousel extends React.Component {
  state = {
    profile: {},
    openDetail: false,
  };
  handleButtonInfo = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: !this.state[name], profile: value });
  };
  handleClose = () => this.setState({ ...this.state, openDetail: false });
  groupOrderedItem = listData => {
    let ordered = [];
    listData.data &&
      listData.data.map(e => {
        let indexOrdered = ordered.findIndex(
          item => item.id === e.ServiceItemId
        );
        return indexOrdered === -1
          ? (ordered = ordered.concat({
              id: e.ServiceItemId,
              ticket: [{ qty: e.Qty, desc: e.Description }],
            }))
          : ordered[indexOrdered].ticket.push({
              qty: e.Qty,
              desc: e.Description,
            });
      });
    return ordered;
  };
  render() {
    const settings = {
      className: 'slider variable-width',
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    let { profile, openDetail } = this.state;
    let { excursionSum, selectExcursion } = this.props;
    return (
      <div style={styles.marginLeft5px}>
        <Slider {...settings}>
          {excursionSum.length > 0 &&
            excursionSum.map((day, index) => {
              let ordered = this.groupOrderedItem(day);
              return ordered.map(ticket => {
                let excursion = selectExcursion.filter(
                  e => e.ServiceItemId === ticket.id
                )[0];
                return (
                  <div key={index} style={styles.widthCard}>
                    <ExcursionCard
                      handleButtonInfo={this.handleButtonInfo}
                      excursion={excursion}
                      ticket={ticket.ticket}
                      day={day.day}
                      date={day.date}
                    />
                  </div>
                );
              });
            })}
        </Slider>
        <ModalDetailExcursion
          open={openDetail}
          onClose={this.handleClose}
          profile={profile}
          showButton={false}
        />
      </div>
    );
  }
}
NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
MultipleCarousel.propTypes = {
  excursionSum: PropTypes.array,
  selectExcursion: PropTypes.array,
};
export default MultipleCarousel;
