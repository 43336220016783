import {
  ballroom,
  bar,
  beachClub,
  diningInVilla,
  disabilityAccess,
  fitnessCenter,
  jacuzzi,
  kidsClub,
  lounge,
  meetingRoom,
  nightLife,
  parking,
  playground,
  privateBeach,
  restaurant,
  swimmingPool,
  tennisCourt,
  transfer,
  waterPark,
  wifi,
} from '../assets/icon/accommodation service/index';

const getServiceIcon = type => {
  switch (type) {
    case 'DISABILITYACCESS':
      return disabilityAccess;
    case 'AIRPORTTRANSFER':
      return transfer;
    case 'BALLROOM':
      return ballroom;
    case 'BAR':
      return bar;
    case 'BEACHCLUB':
      return beachClub;
    case 'FITNESSCENTRE':
      return fitnessCenter;
    case 'GYM':
      return fitnessCenter;
    case 'INVILLADINING':
      return diningInVilla;
    case 'JACUZZI':
      return jacuzzi;
    case 'KIDSCLUB':
      return kidsClub;
    case 'LOUNGE':
      return lounge;
    case 'MEETINGROOMS':
      return meetingRoom;
    case 'NIGHTLIFE':
      return nightLife;
    case 'PLAYGROUND':
      return playground;
    case 'PRIVATEBEACH':
      return privateBeach;
    case 'PRIVATEPARKINGAREA':
      return parking;
    case 'PRIVATEPOOL':
      return swimmingPool;
    case 'PUBLICPARKINGAREA':
      return parking;
    case 'RESTAURANT':
      return restaurant;
    case 'SAUNA':
      return jacuzzi;
    case 'SHUTTLE':
      return transfer;
    case 'SPA':
      return jacuzzi;
    case 'SWIMMINGPOOL':
      return swimmingPool;
    case 'TENNISCOURT':
      return tennisCourt;
    case 'WATERPARK':
      return waterPark;
    case 'WIFI':
      return wifi;
    default:
      return swimmingPool;
  }
};

export { getServiceIcon };
