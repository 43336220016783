let googleAPIKey;

const hostname = window && window.location && window.location.hostname;

if (
  hostname === 'touress.com' ||
  hostname === 'www.touress.com' ||
  hostname === 'touresswebprodv2.azurewebsites.net'
) {
  googleAPIKey = 'AIzaSyBS6CLSuZ1TMTQAkhJlD0fyicCNn_4pOGE';
} else if (hostname === 'touresswebqav2.azurewebsites.net') {
  googleAPIKey = 'AIzaSyAaQYc2o45vQ04S0FRruSY05XL1_TZ9FIg';
} else if (hostname === 'touresswebdemo.azurewebsites.net') {
  googleAPIKey = 'AIzaSyBS6CLSuZ1TMTQAkhJlD0fyicCNn_4pOGE';
} else {
  googleAPIKey = 'AIzaSyAaQYc2o45vQ04S0FRruSY05XL1_TZ9FIg';
}

export default googleAPIKey;
