import * as types from '../actions/actionTypes';

const initialState = {
  currentPackage: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_PACKAGE: {
      return { ...state, currentPackage: action.payload };
    }
    default:
      return state;
  }
}
