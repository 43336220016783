import React, { Component } from 'react';
import { Segment, Grid, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { ucwords } from '../../../genericFunction/convertWord';
import styles from './../styles';
import PropTypes from 'prop-types';
import { getCountries } from '../../shared/actions/countriesAction';
import { withTranslation } from 'react-i18next';

const HeaderList = props => (
  <Grid.Row style={styles.paddingLeftRight10px}>
    <Grid.Column width={16} style={styles.paddingTopBottom10pxLeftRight5px}>
      <Header as="h5">{props.header}</Header>
    </Grid.Column>
  </Grid.Row>
);
const ListGuest = props => {
  let { listGuest, countries } = props;
  return listGuest.map((guest, index) => {
    let country = guest.CountryId
      ? countries.filter(e => e.Id === guest.CountryId)[0]
      : '';
    return (
      <Grid.Row key={index} style={styles.paddingLeftRight10px}>
        <Grid.Column width={7}>
          <Header as="h5">
            {guest.GuestTitle &&
            guest.FirstName &&
            guest.LastName &&
            guest.GuestCategory
              ? ucwords(guest.GuestTitle) +
                ' ' +
                ucwords(guest.FirstName) +
                ' ' +
                ucwords(guest.LastName) +
                '(' +
                ucwords(guest.GuestCategory) +
                ')'
              : '-'}
          </Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Header as="h5">
            {country ? ucwords(country ? country.Name : '') : '-'}
          </Header>
        </Grid.Column>
        <Grid.Column width={4}>
          <Header as="h5">
            {guest.IdentityType && guest.IdentityNbr
              ? ucwords(guest.IdentityType ? guest.IdentityType + ': ' : '') +
                ' ' +
                guest.IdentityNbr
              : '-'}
          </Header>
        </Grid.Column>
      </Grid.Row>
    );
  });
};
class SegmentGuest extends Component {
  componentDidMount = () => {
    this.props.getCountries();
  };
  render() {
    const { t } = this.props;
    let { listGuest, countries } = this.props;
    let adult = listGuest.filter(guest => guest.GuestCategory === 'ADULT');
    let children = listGuest.filter(
      guest => guest.GuestCategory === 'CHILDREN'
    );
    let infant = listGuest.filter(guest => guest.GuestCategory === 'INFANT');
    return (
      <div>
        <Segment style={styles.segmentNoPadding}>
          <Grid style={styles.marginBottom5px}>
            <Grid.Row style={styles.paddingBottom5px}>
              <Grid.Column width={16}>
                <Header as="h3" className="no-margin">
                  {/* Detail Guest */}
                  {t('detailGuest')}
                </Header>
              </Grid.Column>
            </Grid.Row>
            {adult.length !== 0 && (
              // <HeaderList header="Adult &#40; &#62; 12 Years &#41;" />
              <HeaderList header={`${t('adult')} ( > 12 ${t('years')} )`} />
            )}
            <ListGuest listGuest={adult} countries={countries} />
            {children.length !== 0 && (
              // <HeaderList header="Children &#40; 2 - 12 Years &#41;" />
              <HeaderList
                header={`${t('children')} ( 2 - 12 ${t('years')} )`}
              />
            )}
            <ListGuest listGuest={children} countries={countries} />
            {infant.length !== 0 && (
              // <HeaderList header="Infants &#40; &#60; 2 Years &#41;" />
              <HeaderList header={`${t('infants')} ( < 2 ${t('years')} )`} />
            )}
            <ListGuest listGuest={infant} countries={countries} />
          </Grid>
        </Segment>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    countries: state.countries.countries,
  };
};
SegmentGuest.propTypes = {
  listGuest: PropTypes.array,
  getCountries: PropTypes.func,
  countries: PropTypes.array,
  t: PropTypes.func,
};
HeaderList.propTypes = {
  header: PropTypes.string,
};
ListGuest.propTypes = {
  listGuest: PropTypes.array,
  countries: PropTypes.array,
};
export default connect(
  mapStateToProps,
  {
    getCountries,
  }
)(withTranslation()(SegmentGuest));
