import React, { Component } from 'react';
import { Segment, Grid, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { styles, bookNowStyle } from './styles';
import { connect } from 'react-redux';
import { getSchedule, resetGetSchedule } from '../../scheduleAction';
import DownloadButton from '../../../../components/button/BtnDownload';
import { API_ROOT } from '../../../../config/envUrl';
import { GET_BROCHURE_OVERLAY_URL } from '../../../../api/apiUrl';
import {
  getBrochure,
  resetGetBrochure,
  getBrochureOverlay,
} from '../../brochureAction';
import { withTranslation } from 'react-i18next';
import ModalDownloadImage from '../../../shared/components/modal/ModalDownloadImage';
import { getUrlImageBrochureOverlay } from '../../service/helper/getUrlImageBrochureOverlay';
import {
  GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL,
  GET_DOWNLOAD_BROCHURE_USING_CUSTOM_PRICE_URL,
} from '../../../../api/apiUrl';

class SegmentDownloadsAndBookNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getSchedule: this.props.getSchedule,
      dataForImage: {
        openModalDownloadImage: false,
        imagesDownloadModal: [],
      },
      customPrice: 0,
    };
  }
  componentDidMount() {
    this.props.resetGetBrochure();
    this.props.resetGetSchedule();
  }
  componentDidUpdate() {
    if (this.props.getBrochureStatus === 'success') {
      this.handleDownloadFile(
        this.props.brochure.ImageUrl,
        this.props.brochure.ImageName
      );
      this.props.resetGetBrochure();
    }
    if (this.props.getScheduleStatus === 'success') {
      this.handleDownloadFile(this.props.schedule, this.props.title);
      this.props.resetGetSchedule();
    }
  }
  handleGetBrochure(e, { propscontext }) {
    propscontext.handleDownloadFile(
      `${API_ROOT}${GET_BROCHURE_OVERLAY_URL}/${
        propscontext.props.transactionId
      }/${propscontext.props.userCode}`
    );
    // propscontext.getBrochure(propscontext.transactionId);
  }

  handleGetSchedule(e, { propscontext }) {
    propscontext.transactionId &&
      propscontext.getSchedule(propscontext.transactionId);
  }
  handleDownloadFile(dataurl, filename) {
    var link = document.createElement('a');
    link.download = filename;
    link.href = dataurl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleClickDownloadBrochure = () => {
    const { fixedPackageById } = this.props;
    let listImage = [...fixedPackageById.Images];
    //let dataForImage = { ...this.state.dataForImage };
    // dataForImage.openModalDownloadImage = true;
    let dataForImage = {
      imagesDownloadModal: listImage,
      openModalDownloadImage: true,
    };
    this.setState({ dataForImage });
  };

  handleDownloadBrochure = () => {
    let dataImage = { ...this.state.dataForImage };
    let imagesDownload = dataImage.imagesDownloadModal;
    let imageToDownload = imagesDownload.find(item => item.IsPrimaryImage);
    // let imageUrl = imageToDownload.ImageUrl.replace(/(\r\n|\n|\r)/gm, '');
    let imageUrl;

    if (this.state.customPrice > 0) {
      imageUrl = getUrlImageBrochureOverlay(
        GET_DOWNLOAD_BROCHURE_USING_CUSTOM_PRICE_URL(
          this.props.id,
          this.props.userCode,
          imageToDownload.ImageId,
          this.props.currency,
          this.state.customPrice
        )
      );
    } else {
      imageUrl = getUrlImageBrochureOverlay(
        GET_DOWNLOAD_BROCHURE_BY_BOOKING_TEMPLATE_ID_AND_IMAGE_ID_URL(
          this.props.id,
          this.props.userCode,
          imageToDownload.ImageId
        )
      );
    }

    this.handleDownloadFile(imageUrl, '');
  };

  handleChangeSelectedImageDownload = (e, { value }) => {
    const { fixedPackageById } = this.props;
    let listImage = [...fixedPackageById.Images];
    let newList = [];
    listImage.map((img, i) => {
      img['IsPrimaryImage'] = i === value ? true : false;
      return newList.push(img);
    });
    let dataForImage = {
      ...this.state.dataForImage,
      imagesDownloadModal: newList,
    };
    this.setState({ ...this.state, dataForImage });
  };

  handleCloseModalDownload = () => {
    let dataForImage = {
      ...this.state.dataForImage,
      openModalDownloadImage: false,
    };
    this.setState({
      ...this.state,
      dataForImage,
    });
  };

  handleCustomPrice = (e, { value }) => {
    this.setState({ ...this.state, customPrice: value });
  };

  render() {
    const { t } = this.props;
    const { dataForImage } = this.state;
    const { isVariableDate, fixedPackageById } = this.props;

    return (
      <div>
        <Segment.Group style={styles.segmentNoPadding}>
          <Segment style={styles.noBorder}>
            {!isVariableDate && (
              <h2>
                {/* Pax Available */}
                {t('paxAvailable')} :{' '}
                <span style={styles.goldColor}>
                  {' '}
                  {this.props.availablePax} {t('pax')}
                </span>
              </h2>
            )}
            <h5>
              {t('duration')}: {this.props.totalDays} {t('days')}
            </h5>
            {!isVariableDate && (
              // Tour Date
              <h5 style={styles.noMargin}>
                {t('tourDate')}: {this.props.tourDate}
              </h5>
            )}
            {/* Cities */}
            <h5 style={styles.noMargin}>
              {t('cities')}: {this.props.cities}
            </h5>
          </Segment>
          <Segment style={styles.segmentGoldTransparent}>
            <Grid>
              <Grid.Row stretched>
                <Grid.Column width={8}>
                  <Grid>
                    <Grid.Row style={styles.paddingBottomFive}>
                      <Grid.Column width={16}>
                        <DownloadButton
                          buttonText={t('downloadSchedule')} //"Download Schedule"
                          style={styles.borderGold}
                          propscontext={this.props}
                          handleOnClick={this.handleGetSchedule}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {fixedPackageById.Images &&
                      fixedPackageById.Images.length > 0 && (
                        <Grid.Row style={styles.paddingTopFive}>
                          <Grid.Column width={16}>
                            <DownloadButton
                              buttonText={t('downloadBrochure')} //"Download Brochure"
                              style={styles.borderGold}
                              propscontext={this}
                              // handleOnClick={this.handleGetBrochure}
                              handleOnClick={this.handleClickDownloadBrochure}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                  </Grid>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    style={styles.backgroundGold}
                    onClick={this.props.handleBookNow}
                  >
                    {/* Book Now */}
                    <h2 style={bookNowStyle(this.props.brochures)}>
                      {t('bookNow')}
                    </h2>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <ModalDownloadImage
              openModal={dataForImage.openModalDownloadImage}
              handleClose={this.handleCloseModalDownload}
              header={t('downloadBrochureImage')} //"Download Brochure Image"
              image={fixedPackageById.Images}
              handleDownloadImage={this.handleDownloadBrochure}
              handleChangePrimaryImage={this.handleChangeSelectedImageDownload}
              handleCustomPrice={this.handleCustomPrice}
              customPrice={dataForImage.customPrice}
            />
          </Segment>
        </Segment.Group>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    schedule: state.schedule.schedule,
    brochure: state.brochure.brochure,
    getBrochureStatus: state.brochure.getBrochureStatus,
    getScheduleStatus: state.schedule.getScheduleStatus,
    userCode: state.userHeader.userInfo.CompanyCode,
  };
};
SegmentDownloadsAndBookNow.propTypes = {
  tourDate: PropTypes.string,
  availablePax: PropTypes.number,
  transactionId: PropTypes.number,
  getBrochure: PropTypes.func,
  getSchedule: PropTypes.func,
  resetGetBrochure: PropTypes.func,
  resetGetSchedule: PropTypes.func,
  schedule: PropTypes.string,
  brochure: PropTypes.object,
  getBrochureStatus: PropTypes.string,
  getScheduleStatus: PropTypes.string,
  title: PropTypes.string,
  totalDays: PropTypes.number,
  handleBookNow: PropTypes.func,
  cities: PropTypes.string,
  getBrochureOverlay: PropTypes.func,
  brochures: PropTypes.array,
  isVariableDate: PropTypes.bool,
  t: PropTypes.func,
  fixedPackageById: PropTypes.object,
  id: PropTypes.string,
  userCode: PropTypes.string,
  currency: PropTypes.string,
};
export default connect(
  mapStateToProps,
  {
    getBrochure,
    getSchedule,
    resetGetBrochure,
    resetGetSchedule,
    getBrochureOverlay,
  }
)(withTranslation()(SegmentDownloadsAndBookNow));
