// import React, { Component } from 'react';
// import PaymentMethod from './components/paymentMethod/PayMethod';
// import Deposit from './components/Xendit';
// import ModalTopUp from './components/ModalPaymentChoice';
// import { getTourTransactionSummary } from '../shared/actions/bookingAction';
// import { getCompanyProfile } from '../shared/actions/companyAction';
// import {
//   postTourTransactionsConfirmation,
//   postTourTransactionsTopUp,
//   getTourTransactionBilled,
//   resetStatusTransaction,
// } from '../shared/actions/tourTransactionAction';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import Loader from '../../components/common/loader';
// import { rangeDate } from '../../genericFunction/moment';
// import { Redirect } from 'react-router-dom';
// import { convertDateFormat } from '../../genericFunction/moment';
// import { ModalConfirm } from './../shared/components/modal';
// import { ModalAlert } from './../shared/components/modal';
// import styles from './styles';

// class PaymentPage extends Component {
//   state = {
//     isTopUp: false,
//     isSelectedMethod: null, //open list topup xendit
//     amountFunds: 0,
//     goto: null,
//     gotoPage: null,
//     isOpenModalConfirm: false,
//     nameModalConfirm: '',
//     messagesModalConfirm: '',
//     isOpenModalAlertSuccess: false,
//     isOpenModalAlertFailed: false,
//     messagesModalAlert: '',
//     amountToPay: 0,
//     selectManualPayment: null,
//     isSales: false,
//   };

//   componentDidMount() {
//     let { id } = this.props.match.params;
//     this.props.getTourTransactionSummary(id);
//     this.props.getCompanyProfile();
//     this.props.getTourTransactionBilled(id).then(() => {
//       this.setState({
//         ...this.state,
//         amountToPay: this.props.tourTransactionBilled.RestOfTheBill,
//       });
//     });
//   }

//   componentDidUpdate = () => {
//     // if (this.props.postTourTransactionsConfirmationDemoV2Status === 'success') {
//     //   // let data = this.props.tourTransactionsConfirmationDemoV2;
//     //   // this.setState({
//     //   //   ...this.state,
//     //   //   amountToPay: data.TermPayable + data.SupplementPayable,
//     //   // });
//     //   this.props.resetStatusTransaction();
//     // }
//   };

//   getPaymentTermNow = bookingDetail => {
//     return bookingDetail
//       ? bookingDetail.PaymentTerms.length !== 0
//         ? bookingDetail.FixedPackage.PaymentTerms.filter(
//             paid => !paid.IsPayed
//           )[0]
//         : {}
//       : {};
//   };

//   closeModalTopUp = () => this.setState({ ...this.state, isTopUp: false });
//   closeModalConfirm = () =>
//     this.setState({ ...this.state, isOpenModalConfirm: false });

//   closeModalAlert = () => {
//     let { isOpenModalAlertFailed, isOpenModalAlertSuccess } = this.state;
//     let gotoPage = isOpenModalAlertSuccess
//       ? '/my-profile/booking'
//       : isOpenModalAlertFailed && null;
//     this.setState({
//       ...this.state,
//       isOpenModalAlertFailed: false,
//       isOpenModalAlertSuccess: false,
//       gotoPage,
//     });
//   };

//   handleButton = (e, { name, value }) => {
//     if (name === 'isProcessPay') {
//       this.setState({
//         ...this.state,
//         isOpenModalConfirm: true,
//         nameModalConfirm: name,
//         messagesModalConfirm: 'Proceed payment?',
//       });
//     } else if (name === 'isSelectedMethod') {
//       value = value === this.state[name] ? null : value;
//       this.setState({ ...this.state, [name]: value });
//     } else if (name === 'goto') {
//       this.setState(
//         { ...this.state, [name]: value },
//         () => !value && this.props.getCompanyProfile()
//       );
//     } else {
//       this.setState({ ...this.state, [name]: value });
//     }
//   };

//   handleModalConfirm = (e, { name }) => {
//     if (name === 'isProcessPay') {
//       this.processPay();
//     }
//   };

//   handleChange = (e, { name, value }) => {
//     value = value ? parseInt(e.target.value.replace(/\D/g, ''), 10) : 0;
//     this.setState({ ...this.state, [name]: value });
//   };

//   handleAddFunds = (e, { name, value, method }) => {
//     let data = {
//       PaymentGateway: method === 'Deposit' ? 0 : method === 'Xendit' ? 1 : null,
//       Amount: value,
//       CompanyCode: this.props.companyProfile.Code,
//       CurrencyId: this.props.tourTransactionsConfirmationDemoV2.CurrencyId,
//     };
//     this.props.postTourTransactionsTopUp(data);
//     this.setState({ ...this.state, [name]: 1, isTopUp: false });
//   };

//   processPay = () => {
//     let { dataTempTourTransactionsConfirmationDemoV2 } = this.props;
//     let data = {
//       ...dataTempTourTransactionsConfirmationDemoV2,
//       PaymentChoice: 0,
//       CustomValue: this.state.amountToPay,
//       BundledValue: 0,
//       UseBundledValue: true,
//     };

//     this.props
//       .postTourTransactionsConfirmation(data)
//       .then(() =>
//         this.setState({
//           ...this.state,
//           isOpenModalAlertSuccess: true,
//           messagesModalAlert: 'Your payment is successfull',
//         })
//       )
//       .catch(() =>
//         this.setState({
//           ...this.state,
//           isOpenModalAlertFailed: true,
//           messagesModalAlert: this.props.errorsTransaction.Message,
//         })
//       );
//     this.setState({ ...this.state, isOpenModalConfirm: false });
//   };

//   render() {
//     let { step, id } = this.props.match.params;

//     let {
//       isTopUp,
//       isSelectedMethod,
//       amountFunds,
//       goto,
//       gotoPage,
//       isOpenModalConfirm,
//       nameModalConfirm,
//       messagesModalConfirm,
//       amountToPay,
//       isOpenModalAlertSuccess,
//       isOpenModalAlertFailed,
//       messagesModalAlert,
//       selectManualPayment,
//     } = this.state;

//     let {
//       loading,
//       loadingTourTransaction,
//       loadingCompany,
//       tourTransactionSummaryById,
//       tourTransactionsConfirmationDemoV2,
//       tourTransactionsTopUp,
//       companyProfile,
//       dataTempTourTransactionsConfirmationDemoV2,
//       tourTransactionBilled,
//     } = this.props;
//     // --------data tour Transaction Billed---------
//     let descNowPaymentTerm = tourTransactionBilled
//       ? tourTransactionBilled.Description
//       : '-';
//     // --------data Temp Tour Transactions Confirmation Demo V2-----------
//     let guests =
//       dataTempTourTransactionsConfirmationDemoV2 &&
//       dataTempTourTransactionsConfirmationDemoV2.UpdateGuest;
//     let totalGuest = guests
//       ? guests.AdultPax + guests.ChildPax + guests.InfantPax
//       : 0;
//     // let priceNowPaymentTerm = tourTransactionBilled
//     //   ? tourTransactionBilled.RestOfTheBill
//     //   : 0;
//     let priceNowPaymentTerm = tourTransactionBilled
//       ? Math.floor(tourTransactionBilled.PaidValue) !== 0
//         ? tourTransactionBilled.PaymentTermBasedValue !==
//           tourTransactionBilled.PaidValue
//           ? tourTransactionBilled.RestOfTheBill
//           : tourTransactionBilled.PaymentTermBasedValue
//         : tourTransactionBilled.PaymentTermBasedValue
//       : 0;
//     // --------data company profile ---------
//     let { Balance } = companyProfile;
//     // --------data tour Transactions Top Up ---------
//     let { Url } = tourTransactionsTopUp;
//     // -------- data tour Transactions Confirmation Demo V2---------
//     let {
//       TotalPriceForCustomer,
//       CurrencyId,
//       OutstandingBill,
//       PaymentGateAwayChoices,
//       Shortfall,
//     } = tourTransactionsConfirmationDemoV2;
//     let totalTourPrice = TotalPriceForCustomer ? TotalPriceForCustomer : 0;
//     let currency = CurrencyId ? CurrencyId : '';
//     // -------- data tour Transaction Summary By Id---------
//     let { BookingDetailSum } = tourTransactionSummaryById;
//     let title = BookingDetailSum ? BookingDetailSum.Title : '';
//     let tourDate = BookingDetailSum
//       ? rangeDate(BookingDetailSum.StartDate, BookingDetailSum.EndDate)
//       : '-';
//     let totalPaid = BookingDetailSum ? BookingDetailSum.TotalPayed : 0;
//     // let expiredDate = BookingDetailSum
//     //   ? convertDateFormat(BookingDetailSum.ExpiredOn, 'DD MMM YYYY')
//     //   : '-';
//     let dateExpired = BookingDetailSum
//       ? BookingDetailSum.PaymentTerms.find(item => item.IsPayed === false)
//       : '-';
//     let expiredDate = convertDateFormat(dateExpired.DueDate, 'DD MMM YYYY');
//     // --------loading---------
//     let loadingPage = loading || loadingTourTransaction || loadingCompany;
//     // ----- show summary booking -------
//     if (
//       !loadingTourTransaction &&
//       !dataTempTourTransactionsConfirmationDemoV2
//     ) {
//       return <Redirect to={'/partial-payment/' + id} />;
//     } else if (gotoPage) {
//       return <Redirect to={gotoPage} />;
//     }
//     return (
//       <div style={styles.backgroundWhite}>
//         {step === 'payment-method' && !goto ? (
//           <PaymentMethod
//             id={id}
//             title={title}
//             tourDate={tourDate}
//             totalGuest={totalGuest}
//             totalTourPrice={totalTourPrice}
//             descNowPaymentTerm={descNowPaymentTerm}
//             priceNowPaymentTerm={priceNowPaymentTerm}
//             totalPaid={totalPaid}
//             currency={currency}
//             currencyCompany={companyProfile ? companyProfile.Currency : ''}
//             expiredDate={expiredDate}
//             balance={Balance}
//             outstandingBill={OutstandingBill}
//             handleButton={this.handleButton}
//             handleChange={this.handleChange}
//             amountToPay={amountToPay}
//           />
//         ) : (
//           goto === 1 && (
//             <Deposit
//               id={id}
//               title={title}
//               tourDate={tourDate}
//               totalGuest={totalGuest}
//               currencyCompany={companyProfile ? companyProfile.Currency : ''}
//               balance={Balance}
//               amountPay={amountToPay}
//               url={Url}
//               curreny={currency}
//               handleButton={this.handleButton}
//             />
//           )
//         )}
//         <ModalTopUp
//           isOpen={isTopUp}
//           onClose={this.closeModalTopUp}
//           handleButton={this.handleButton}
//           isSelectedMethod={isSelectedMethod}
//           amountFunds={amountFunds}
//           paymentGateAwayChoices={PaymentGateAwayChoices}
//           currency={currency}
//           currencyCompany={companyProfile ? companyProfile.Currency : ''}
//           balance={Balance}
//           selectManualPayment={selectManualPayment}
//           handleChange={this.handleChange}
//           handleAddFunds={this.handleAddFunds}
//           shortfall={Shortfall}
//         />
//         <ModalConfirm
//           openModal={isOpenModalConfirm}
//           size="tiny"
//           close={this.closeModalConfirm}
//           confirm={this.handleModalConfirm}
//           message={messagesModalConfirm}
//           nameConfirm={nameModalConfirm}
//           selectManualPayment={selectManualPayment}
//         />
//         <ModalAlert
//           openModal={isOpenModalAlertSuccess || isOpenModalAlertFailed}
//           size="tiny"
//           close={this.closeModalAlert}
//           message={messagesModalAlert}
//         />
//         <Loader active={loadingPage} description="Loading" page={true} />
//       </div>
//     );
//   }
// }

// PaymentPage.propTypes = {
//   match: PropTypes.object,
//   loading: PropTypes.bool,
//   getCompanyProfile: PropTypes.func,
//   getTourTransactionSummary: PropTypes.func,
//   postTourTransactionsConfirmation: PropTypes.func,
//   postTourTransactionsTopUp: PropTypes.func,
//   tourTransactionSummaryById: PropTypes.object,
//   tourTransactionsConfirmationDemoV2: PropTypes.object,
//   dataTempTourTransactionsConfirmationDemoV2: PropTypes.object,
//   companyProfile: PropTypes.object,
//   tourTransactionsTopUp: PropTypes.object,
//   loadingTourTransaction: PropTypes.bool,
//   postTourTransactionsConfirmationDemoV2Status: PropTypes.string,
//   getTourTransactionBilled: PropTypes.func,
//   tourTransactionBilled: PropTypes.object,
//   errorsTransaction: PropTypes.object,
//   loadingCompany: PropTypes.bool,
//   resetStatusTransaction: PropTypes.bool,
// };

// const mapStateToProps = state => {
//   return {
//     loading: state.booking.loadingSeeDetail,
//     tourTransactionSummaryById: state.booking.tourTransactionSummary,
//     tourTransactionsConfirmationDemoV2:
//       state.tourTransaction.tourTransactionsConfirmationDemoV2,
//     postTourTransactionsConfirmationDemoV2Status:
//       state.tourTransaction.postTourTransactionsConfirmationDemoV2Status,
//     dataTempTourTransactionsConfirmationDemoV2:
//       state.temp.dataTourTransactionsConfirmationDemoV2,
//     companyProfile: state.company.companyProfile,
//     loadingCompany: state.company.loading,
//     loadingTourTransaction: state.tourTransaction.loading,
//     tourTransactionsTopUp: state.tourTransaction.tourTransactionsTopUp,
//     tourTransactionBilled: state.tourTransaction.tourTransactionBilled,
//     errorsTransaction: state.tourTransaction.errors,
//   };
// };

// export default connect(
//   mapStateToProps,
//   {
//     getTourTransactionSummary,
//     getCompanyProfile,
//     postTourTransactionsConfirmation,
//     postTourTransactionsTopUp,
//     getTourTransactionBilled,
//     resetStatusTransaction,
//   }
// )(PaymentPage);

///=====================
import React, { useState, useEffect } from 'react';
import PaymentMethod from './components/paymentMethod/PayMethod';
import Deposit from './components/Xendit';
import ModalTopUp from './components/ModalPaymentChoice';
import { getTourTransactionSummary } from '../shared/actions/bookingAction';
import { getCompanyProfile } from '../shared/actions/companyAction';
import {
  postTourTransactionsConfirmation,
  postTourTransactionsTopUp,
  getTourTransactionBilled,
  // resetStatusTransaction,
} from '../shared/actions/tourTransactionAction';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../components/common/loader';
import { rangeDate } from '../../genericFunction/moment';
import { Redirect } from 'react-router-dom';
import { convertDateFormat } from '../../genericFunction/moment';
import { ModalConfirm } from './../shared/components/modal';
import { ModalAlert } from './../shared/components/modal';
import styles from './styles';
import { useTranslation } from 'react-i18next';
import DynamicTitle from '../../components/common/DynamicTitle';

const PaymentPage = props => {
  const { t } = useTranslation();
  //=============== initial state ================
  const [isTopUp, setIsTopUp] = useState(false);
  const [isSelectedMethod, setIsSelectedMethod] = useState(null); //open list topup xendi
  const [amountFunds, setAmountFunds] = useState(0);
  const [goto, setGoto] = useState(null);
  const [gotoPage, setGotoPage] = useState(null);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [nameModalConfirm, setNameModalConfirm] = useState('');
  const [messagesModalConfirm, setMessagesModalConfirm] = useState('');
  const [isOpenModalAlertSuccess, setIsOpenModalAlertSuccess] = useState(false);
  const [isOpenModalAlertFailed, setIsOpenModalAlertFailed] = useState(false);
  const [messagesModalAlert, setMessagesModalAlert] = useState('');
  const [amountToPay, setAmountToPay] = useState(0);
  const [selectManualPayment, setSelectManualPayment] = useState(null);
  // const [isSales, setIsSales] = useState(false);

  //============== store ===============
  const loading = useSelector(state => state.booking.loadingSeeDetail);
  const tourTransactionSummaryById = useSelector(
    state => state.booking.tourTransactionSummary
  );
  const tourTransactionsConfirmationDemoV2 = useSelector(
    state => state.tourTransaction.tourTransactionsConfirmationDemoV2
  );
  // const postTourTransactionsConfirmationDemoV2Status = state =>
  //   state.tourTransaction.postTourTransactionsConfirmationDemoV2Status;
  const dataTempTourTransactionsConfirmationDemoV2 = useSelector(
    state => state.temp.dataTourTransactionsConfirmationDemoV2
  );
  const companyProfile = useSelector(state => state.company.companyProfile);
  const loadingCompany = useSelector(state => state.company.loading);
  const loadingTourTransaction = useSelector(
    state => state.tourTransaction.loading
  );
  const tourTransactionsTopUp = useSelector(
    state => state.tourTransaction.tourTransactionsTopUp
  );
  const tourTransactionBilled = useSelector(
    state => state.tourTransaction.tourTransactionBilled
  );
  const errorsTransaction = useSelector(state => state.tourTransaction.errors);
  //=============== action ===============
  const dispatch = useDispatch();
  const getTourTransactionSummaryAction = id =>
    dispatch(getTourTransactionSummary(id));
  const getCompanyProfileAction = () => dispatch(getCompanyProfile());
  const postTourTransactionsConfirmationAction = data =>
    dispatch(postTourTransactionsConfirmation(data));
  const postTourTransactionsTopUpAction = data =>
    dispatch(postTourTransactionsTopUp(data));
  const getTourTransactionBilledAction = id =>
    dispatch(getTourTransactionBilled(id));
  // const resetStatusTransactionAction = () => dispatch(resetStatusTransaction());
  //============== lifecycle =============
  useEffect(() => {
    let { id } = props.match.params;
    getTourTransactionSummaryAction(id);
    getCompanyProfileAction();
    getTourTransactionBilledAction(id).then(() => {
      setAmountToPay(tourTransactionBilled.RestOfTheBill);
    });
    // eslint-disable-next-line
  }, []);

  // const getPaymentTermNow = bookingDetail => {
  //   return bookingDetail
  //     ? bookingDetail.PaymentTerms.length !== 0
  //       ? bookingDetail.FixedPackage.PaymentTerms.filter(
  //           paid => !paid.IsPayed
  //         )[0]
  //       : {}
  //     : {};
  // };

  const closeModalTopUp = () => {
    setIsTopUp(false);
    setAmountFunds(0);
  };
  const closeModalConfirm = () => setIsOpenModalConfirm(false);
  const closeModalAlert = () => {
    // let { isOpenModalAlertFailed, isOpenModalAlertSuccess } = state;
    let gotoPage = isOpenModalAlertSuccess
      ? '/my-profile/booking'
      : isOpenModalAlertFailed && null;
    setIsOpenModalAlertFailed(false);
    setIsOpenModalAlertSuccess(false);
    setGotoPage(gotoPage);
  };

  const handleButton = (e, { name, value }) => {
    if (name === 'isProcessPay') {
      setIsOpenModalConfirm(true);
      setNameModalConfirm(name);
      setMessagesModalConfirm(t('proceedPayment?')); //Proceed payment?
    } else if (name === 'isSelectedMethod') {
      value = value === isSelectedMethod ? null : value;
      setIsSelectedMethod(value);
    } else if (name === 'goto') {
      setGoto(value);
      if (!value) getCompanyProfileAction();
    } else {
      switch (name) {
        case 'goto':
          setGoto(value);
          break;
        case 'selectManualPayment':
          setSelectManualPayment(value);
          break;
        case 'isTopUp':
          setIsTopUp(value);
          break;
        default:
          return null;
      }
      // setState({ ...state, [name]: value });
    }
  };

  const handleModalConfirm = (e, { name }) => {
    if (name === 'isProcessPay') {
      processPay();
    }
  };

  const handleChange = (e, { name, value }) => {
    value = value ? parseInt(e.target.value.replace(/\D/g, ''), 10) : 0;
    // setState({ ...state, [name]: value });
    if (name === 'amountToPay') setAmountToPay(value);
    else setAmountFunds(value);
  };

  const handleAddFunds = (e, { value, method }) => {
    let data = {
      PaymentGateway: method === 'Deposit' ? 0 : method === 'Xendit' ? 1 : null,
      Amount: value,
      CompanyCode: companyProfile.Code,
      CurrencyId: tourTransactionsConfirmationDemoV2.CurrencyId,
    };
    postTourTransactionsTopUpAction(data);
    setIsTopUp(false);
    setGoto(1);
    // setState({ ...state, [name]: 1, isTopUp: false });
  };

  const processPay = () => {
    // let { dataTempTourTransactionsConfirmationDemoV2 } = props;
    let data = {
      ...dataTempTourTransactionsConfirmationDemoV2,
      PaymentChoice: 0,
      CustomValue: amountToPay,
      BundledValue: 0,
      UseBundledValue: true,
    };

    postTourTransactionsConfirmationAction(data)
      .then(() => {
        setIsOpenModalAlertSuccess(true);
        setMessagesModalAlert(t('paymentSuccess')); //Your payment is successfull
      })
      .catch(() => {
        setIsOpenModalAlertFailed(true);
        setMessagesModalAlert(errorsTransaction.Message);
      });
    setIsOpenModalConfirm(false);
  };

  let { step, id } = props.match.params;

  // let {
  //   isTopUp,
  //   isSelectedMethod,
  //   amountFunds,
  //   goto,
  //   gotoPage,
  //   isOpenModalConfirm,
  //   nameModalConfirm,
  //   messagesModalConfirm,
  //   amountToPay,
  //   isOpenModalAlertSuccess,
  //   isOpenModalAlertFailed,
  //   messagesModalAlert,
  //   selectManualPayment,
  // } = state;

  // let {
  //   loading,
  //   loadingTourTransaction,
  //   loadingCompany,
  //   tourTransactionSummaryById,
  //   tourTransactionsConfirmationDemoV2,
  //   tourTransactionsTopUp,
  //   companyProfile,
  //   dataTempTourTransactionsConfirmationDemoV2,
  //   tourTransactionBilled,
  // } = props;
  // --------data tour Transaction Billed---------
  let descNowPaymentTerm = tourTransactionBilled
    ? tourTransactionBilled.Description
    : '-';
  // --------data Temp Tour Transactions Confirmation Demo V2-----------
  let guests =
    dataTempTourTransactionsConfirmationDemoV2 &&
    dataTempTourTransactionsConfirmationDemoV2.UpdateGuest;
  let totalGuest = guests
    ? guests.AdultPax + guests.ChildPax + guests.InfantPax
    : 0;
  // let priceNowPaymentTerm = tourTransactionBilled
  //   ? tourTransactionBilled.RestOfTheBill
  //   : 0;
  let priceNowPaymentTerm = tourTransactionBilled
    ? Math.floor(tourTransactionBilled.PaidValue) !== 0
      ? tourTransactionBilled.PaymentTermBasedValue !==
        tourTransactionBilled.PaidValue
        ? tourTransactionBilled.RestOfTheBill
        : tourTransactionBilled.PaymentTermBasedValue
      : tourTransactionBilled.PaymentTermBasedValue
    : 0;
  // --------data company profile ---------
  let { Balance } = companyProfile;
  // --------data tour Transactions Top Up ---------
  let { Url } = tourTransactionsTopUp;
  // -------- data tour Transactions Confirmation Demo V2---------
  let {
    TotalPriceForCustomer,
    CurrencyId,
    OutstandingBill,
    PaymentGateAwayChoices,
    Shortfall,
  } = tourTransactionsConfirmationDemoV2;
  let totalTourPrice = TotalPriceForCustomer ? TotalPriceForCustomer : 0;
  let currency = CurrencyId ? CurrencyId : '';
  // -------- data tour Transaction Summary By Id---------
  let { BookingDetailSum } = tourTransactionSummaryById;
  let title = BookingDetailSum ? BookingDetailSum.Title : '';
  let tourDate = BookingDetailSum
    ? rangeDate(BookingDetailSum.StartDate, BookingDetailSum.EndDate)
    : '-';
  let totalPaid = BookingDetailSum ? BookingDetailSum.TotalPayed : 0;
  // let expiredDate = BookingDetailSum
  //   ? convertDateFormat(BookingDetailSum.ExpiredOn, 'DD MMM YYYY')
  //   : '-';
  let dateExpired = BookingDetailSum
    ? BookingDetailSum.PaymentTerms.find(item => item.IsPayed === false)
    : '-';
  let expiredDate = convertDateFormat(dateExpired.DueDate, 'DD MMM YYYY');
  // --------loading---------
  let loadingPage = loading || loadingTourTransaction || loadingCompany;
  // ----- show summary booking -------
  if (!loadingTourTransaction && !dataTempTourTransactionsConfirmationDemoV2) {
    return <Redirect to={'/partial-payment/' + id} />;
  } else if (gotoPage) {
    return <Redirect to={gotoPage} />;
  }
  return (
    <div style={styles.backgroundWhite}>
      <DynamicTitle title="Payment" />
      {step === 'payment-method' && !goto ? (
        <PaymentMethod
          id={id}
          title={title}
          tourDate={tourDate}
          totalGuest={totalGuest}
          totalTourPrice={totalTourPrice}
          descNowPaymentTerm={descNowPaymentTerm}
          priceNowPaymentTerm={priceNowPaymentTerm}
          totalPaid={totalPaid}
          currency={currency}
          currencyCompany={companyProfile ? companyProfile.Currency : ''}
          expiredDate={expiredDate}
          balance={Balance}
          outstandingBill={OutstandingBill}
          handleButton={handleButton}
          handleChange={handleChange}
          amountToPay={amountToPay}
        />
      ) : (
        goto === 1 && (
          <Deposit
            id={id}
            title={title}
            tourDate={tourDate}
            totalGuest={totalGuest}
            currencyCompany={companyProfile ? companyProfile.Currency : ''}
            balance={Balance}
            amountPay={amountToPay}
            url={Url}
            curreny={currency}
            handleButton={handleButton}
          />
        )
      )}
      <ModalTopUp
        isOpen={isTopUp}
        onClose={closeModalTopUp}
        handleButton={handleButton}
        isSelectedMethod={isSelectedMethod}
        amountFunds={amountFunds}
        paymentGateAwayChoices={PaymentGateAwayChoices}
        currency={currency}
        currencyCompany={companyProfile ? companyProfile.Currency : ''}
        balance={Balance}
        selectManualPayment={selectManualPayment}
        handleChange={handleChange}
        handleAddFunds={handleAddFunds}
        shortfall={Shortfall}
      />
      <ModalConfirm
        openModal={isOpenModalConfirm}
        size="tiny"
        close={closeModalConfirm}
        confirm={handleModalConfirm}
        message={messagesModalConfirm}
        nameConfirm={nameModalConfirm}
        selectManualPayment={selectManualPayment}
      />
      <ModalAlert
        openModal={isOpenModalAlertSuccess || isOpenModalAlertFailed}
        size="tiny"
        close={closeModalAlert}
        message={messagesModalAlert}
      />
      <Loader active={loadingPage} description={t('loading')} page={true} />
      {/* Loading */}
    </div>
  );
};

PaymentPage.propTypes = {
  match: PropTypes.object,
  loading: PropTypes.bool,
  getCompanyProfile: PropTypes.func,
  getTourTransactionSummary: PropTypes.func,
  postTourTransactionsConfirmation: PropTypes.func,
  postTourTransactionsTopUp: PropTypes.func,
  tourTransactionSummaryById: PropTypes.object,
  tourTransactionsConfirmationDemoV2: PropTypes.object,
  dataTempTourTransactionsConfirmationDemoV2: PropTypes.object,
  companyProfile: PropTypes.object,
  tourTransactionsTopUp: PropTypes.object,
  loadingTourTransaction: PropTypes.bool,
  postTourTransactionsConfirmationDemoV2Status: PropTypes.string,
  getTourTransactionBilled: PropTypes.func,
  tourTransactionBilled: PropTypes.object,
  errorsTransaction: PropTypes.object,
  loadingCompany: PropTypes.bool,
  resetStatusTransaction: PropTypes.bool,
  t: PropTypes.func,
};

export default PaymentPage;
