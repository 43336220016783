import apiClient from './apiClient';
import {
  GET_TRANSPORTATION_PROFILE_RATINGS_URL,
  GET_TRANSPORTATION_PROFILE_CATEGORIES_URL,
  GET_TRANSPORTATION_PROFILES_URL,
  GET_TRANSPORTATION_TYPES_URL,
  GET_TRANSPORTATION_SEAT_TYPES_URL,
  GET_TRANSPORTATION_UNITS_FILTER_URL,
  GET_TRANSPORTATION_UNIT_BY_ID_URL,
} from './apiUrl';

const getTransportationProfileRatingsApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_TRANSPORTATION_PROFILE_RATINGS_URL);
};

const getTransportationProfileCategoriesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_TRANSPORTATION_PROFILE_CATEGORIES_URL);
};

const getTransportationProfilesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_TRANSPORTATION_PROFILES_URL);
};

const getTransportationTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_TRANSPORTATION_TYPES_URL);
};

const getTransportationSeatTypesApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_TRANSPORTATION_SEAT_TYPES_URL);
};

const getTransportationUnitsFilterApi = (data, bodyReq) => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.post(
    `${GET_TRANSPORTATION_UNITS_FILTER_URL}?fromCity=${
      data.fromCity
    }&toCities=${data.toCities}&RatingId=${data.RatingId}&seatTypeId=${
      data.seatTypeId
    }&typeId=${data.typeId}&requestedDate=${data.requestedDate}`,
    bodyReq
  );
};
const getTransportationUnitByIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_TRANSPORTATION_UNIT_BY_ID_URL}/${id}`);
};
export {
  getTransportationProfileRatingsApi,
  getTransportationProfileCategoriesApi,
  getTransportationProfilesApi,
  getTransportationTypesApi,
  getTransportationSeatTypesApi,
  getTransportationUnitsFilterApi,
  getTransportationUnitByIdApi,
};
