import { getTime, addDate } from './moment';
let isShortCompanyFormValid = false;
const selectTourLeader = guests => {
  let leader = guests.filter(e => e.GuestType === 'TOURLEADER');
  if (leader.length === 0) {
    let idMin = Math.min.apply(
      Math,
      guests.map(e => {
        return e.Id;
      })
    );
    leader = guests.filter(e => e.Id === idMin && e.GuestCategory === 'ADULT');
  }
  return leader[0];
};
const validationFillGuest = (guestList, errorFormGuest) => {
  let tourLeader = selectTourLeader(guestList);
  let newErrorFormGuest = copyObject(errorFormGuest);
  if (tourLeader) {
    newErrorFormGuest.firstName.error = tourLeader.FirstName === '';
    newErrorFormGuest.lastName.error = tourLeader.LastName === '';
    newErrorFormGuest.identityNbr.error = tourLeader.IdentityNbr === '';
    newErrorFormGuest.identityType.error = tourLeader.IdentityType === '';
    newErrorFormGuest.guestTitle.error = tourLeader.GuestTitle === '';
    newErrorFormGuest.countryId.error = tourLeader.CountryId === '';
  }
  return newErrorFormGuest;
};
const checkFillGuest = (guest, errors) => {
  if (!guest.IsDelete) {
    errors.firstName.error = guest.FirstName ? false : true;
    errors.lastName.error = guest.LastName ? false : true;
    errors.identityNbr.error = guest.IdentityNbr ? false : true;
    errors.identityType.error = guest.IdentityType ? false : true;
    errors.guestTitle.error = guest.GuestTitle ? false : true;
    errors.countryId.error = guest.CountryId ? false : true;
  }
  return errors;
};
const validationFillAllGuests = guestList => {
  let copyGuestList = copyObject(guestList);
  return copyGuestList.map(guest => {
    return { ...guest, errors: checkFillGuest(guest, guest.errors) };
  });
};
const isGuestError = errorGuest => {
  return (
    errorGuest.firstName.error ||
    errorGuest.lastName.error ||
    errorGuest.identityNbr.error ||
    errorGuest.identityType.error ||
    errorGuest.guestTitle.error ||
    errorGuest.countryId.error
  );
};
const isAllGuestsError = guestList => {
  let copyGuestList = copyObject(guestList);
  return copyGuestList.some(guest => isGuestError(guest.errors));
};
const copyObject = obj => {
  let newObj = JSON.stringify(obj);
  return JSON.parse(newObj);
};

const validationTourPax = (guests, errorFormPackage) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  if (guests.adults === 0) {
    newErrorFormPackage.tourPax.error = true;
    newErrorFormPackage.tourPax.message = ['Please input at least 1 adult'];
  } else {
    newErrorFormPackage.tourPax.error = false;
    newErrorFormPackage.tourPax.message = [];
  }
  return newErrorFormPackage;
};
const validationMinPax = (guests, errorFormPackage, minPax) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  let total = guests.adults + guests.childs + guests.infants;
  if (total < minPax) {
    newErrorFormPackage.minimumPax.error = true;
    newErrorFormPackage.minimumPax.message = [
      `Guest invalid, the minimum pax for this package is ${minPax} pax`,
    ];
  } else {
    newErrorFormPackage.minimumPax.error = false;
    newErrorFormPackage.minimumPax.message = [];
  }
  return newErrorFormPackage;
};
const validationAllocationPax = (guests, roomAllocation, errorFormPackage) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  newErrorFormPackage.roomAllocation.message = [];
  let { adults, childs, infants } = guests;
  let allocatedAdults =
    roomAllocation.ExtraBedQty +
    roomAllocation.SharingRoomQty +
    roomAllocation.SingleRoomQty;
  let allocatedChilds =
    roomAllocation.ChildExtraBedQty +
    roomAllocation.ChildSharingRoomQty +
    roomAllocation.ChildSingleRoomQty +
    roomAllocation.SharingBedQty;
  let allocatedInfants = roomAllocation.BabyCrib + roomAllocation.NoBed;
  let currentAdult = adults - allocatedAdults;
  let currentChild = childs - allocatedChilds;
  let currentInfant = infants - allocatedInfants;
  if (currentAdult !== 0) {
    let diff = Math.abs(currentAdult);
    let message1 = `Please allocate ${diff} more ${
      diff > 1 ? 'adults' : 'adult'
    } in room allocation`;
    let message2 = `Over allocated ${diff} ${
      diff > 1 ? 'adults' : 'adult'
    } in room allocation`;
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message.push(
      adults > allocatedAdults ? message1 : message2
    );
  }
  if (currentChild !== 0) {
    let diff = Math.abs(currentChild);
    let message1 = `Please allocate ${diff} more ${
      diff > 1 ? 'childs' : 'child'
    } in room allocation`;
    let message2 = `Over allocated ${diff} ${
      diff > 1 ? 'childs' : 'child'
    } in room allocation`;
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message.push(
      childs > allocatedChilds ? message1 : message2
    );
  }
  if (currentInfant !== 0) {
    let diff = Math.abs(currentInfant);
    let message1 = `Please allocate ${diff} more ${
      diff > 1 ? 'infants' : 'infant'
    } in room allocation`;
    let message2 = `Over allocated ${diff} ${
      diff > 1 ? 'infants' : 'infant'
    } in room allocation`;
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message.push(
      infants > allocatedInfants ? message1 : message2
    );
  }
  if (currentAdult === 0 && currentChild === 0 && currentInfant === 0) {
    newErrorFormPackage.roomAllocation.error = false;
    newErrorFormPackage.roomAllocation.message = [];
  }
  return newErrorFormPackage;
};
const validationTotalGuests = (guestList, guests, errorFormPackage) => {
  let copyGuestList = copyObject(guestList);
  let copyErrorFormPackage = copyObject(errorFormPackage);
  let adults = copyGuestList.filter(
    e => e.GuestCategory === 'ADULT' && !e.IsDelete
  );
  let children = copyGuestList.filter(
    e => e.GuestCategory === 'CHILDREN' && !e.IsDelete
  );
  let infants = copyGuestList.filter(
    e => e.GuestCategory === 'INFANT' && !e.IsDelete
  );
  copyErrorFormPackage.guests = { error: false, message: [] };
  if (adults.length < guests.adults) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please add ' + (guests.adults - adults.length) + ' adults'
    );
  } else if (adults.length > guests.adults) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please remove ' + (adults.length - guests.adults) + ' adults'
    );
  }
  if (children.length < guests.childs) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please add ' + (guests.childs - children.length) + ' children'
    );
  } else if (children.length > guests.childs) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please remove ' + (children.length - guests.childs) + ' children'
    );
  }
  if (infants.length < guests.infants) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please add ' + (guests.infants - infants.length) + ' infants'
    );
  } else if (infants.length > guests.infants) {
    copyErrorFormPackage.guests.error = true;
    copyErrorFormPackage.guests.message.push(
      'Please remove ' + (infants.length - guests.infants) + ' infants'
    );
  }
  return copyErrorFormPackage;
};
const isErrorFormPackage = errorFormPackage => {
  let errorTourPax = errorFormPackage.tourPax && errorFormPackage.tourPax.error;
  let errorRoomAllocation =
    errorFormPackage.roomAllocation && errorFormPackage.roomAllocation.error;
  let errorQuota = errorFormPackage.quota && errorFormPackage.quota.error;
  let errorCommission =
    errorFormPackage.commission && errorFormPackage.commission.error;
  let errorMinPax =
    errorFormPackage.minimumPax && errorFormPackage.minimumPax.error;
  return (
    errorTourPax ||
    errorRoomAllocation ||
    errorQuota ||
    errorCommission ||
    errorMinPax
  );
};
const isAdultChildAllocationValid = (guests, roomAllocation) => {
  const totalGuest = guests.adults + guests.childs;
  let totalAllocated =
    (roomAllocation.sharingRoom
      ? roomAllocation.sharingRoom
      : roomAllocation.SharingRoomSum
      ? roomAllocation.SharingRoomSum.Pax
      : roomAllocation.SharingRoomQty
      ? roomAllocation.SharingRoomQty
      : 0) +
    (roomAllocation.singleRoom
      ? roomAllocation.singleRoom
      : roomAllocation.SingleRoomSum
      ? roomAllocation.SingleRoomSum.Pax
      : roomAllocation.SingleRoomQty
      ? roomAllocation.SingleRoomQty
      : 0) +
    (roomAllocation.adultExtraBed
      ? roomAllocation.adultExtraBed
      : roomAllocation.ExtraBedSum
      ? roomAllocation.ExtraBedSum.Pax
      : roomAllocation.ExtraBedQty
      ? roomAllocation.ExtraBedQty
      : 0) +
    (roomAllocation.childExtraBed
      ? roomAllocation.childExtraBed
      : roomAllocation.ChildExtraBedSum
      ? roomAllocation.ChildExtraBedSum.Pax
      : roomAllocation.ChildExtraBedQty
      ? roomAllocation.ChildExtraBedQty
      : 0) +
    (roomAllocation.sharingBed
      ? roomAllocation.sharingBed
      : roomAllocation.SharingBedSum
      ? roomAllocation.SharingBedSum.Pax
      : roomAllocation.SharingBedQty
      ? roomAllocation.SharingBedQty
      : 0);
  // totalAllocated =
  //   totalAllocated ||
  //   (roomAllocation.SharingRoomSum.Pax || 0) +
  //     (roomAllocation.SingleRoomSum.Pax || 0) +
  //     roomAllocation.ExtraBedSum.Pax +
  //     roomAllocation.ChildExtraBedSum.Pax +
  //     roomAllocation.SharingBedSum.Pax;
  // totalAllocated = totalAllocated || 0;
  return totalAllocated < totalGuest
    ? `You need to allocate ${totalGuest -
        totalAllocated} more adult or children in room allocation`
    : totalAllocated > totalGuest
    ? `You over allocated ${totalAllocated -
        totalGuest} adult or children in room allocation`
    : '';
};
const isInfantAllocationValid = (guests, roomAllocation) => {
  let totalAllocated = roomAllocation.noBed
    ? roomAllocation.noBed
    : roomAllocation.NoBedSum
    ? roomAllocation.NoBedSum.Pax
    : roomAllocation.NoBed
    ? roomAllocation.NoBed
    : 0;
  return totalAllocated < guests.infants
    ? `You need to allocate
        ${guests.infants - totalAllocated} more infant in room allocation`
    : totalAllocated > guests.infants
    ? `You over allocated ${totalAllocated -
        guests.infants} infant in room allocation`
    : '';
};
const isRoomAllocationValid = (errorFormPackage, guests, roomAllocation) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  let isAdultChildValid = isAdultChildAllocationValid(guests, roomAllocation);
  let isInfantValid = isInfantAllocationValid(guests, roomAllocation);
  if (isAdultChildValid !== '' && isInfantValid !== '') {
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message = [
      isAdultChildValid,
      isInfantValid,
    ];
  } else if (isAdultChildValid !== '' && isInfantValid === '') {
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message = [isAdultChildValid];
  } else if (isAdultChildValid === '' && isInfantValid !== '') {
    newErrorFormPackage.roomAllocation.error = true;
    newErrorFormPackage.roomAllocation.message = [
      isAdultChildValid,
      isInfantValid,
    ];
  } else {
    newErrorFormPackage.roomAllocation.error = false;
    newErrorFormPackage.roomAllocation.message = [''];
  }
  return newErrorFormPackage;
};

const isQuotaValid = (
  errorFormPackage,
  minimumGuest,
  confirmedGuest,
  guests
) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  let availablePax = minimumGuest - confirmedGuest;
  let totalGuest = guests.adults + guests.childs + guests.infants;
  if (totalGuest > availablePax) {
    newErrorFormPackage.quota.error = true;
    newErrorFormPackage.quota.message = [
      'Fixed package out off quota, the remaining quota is ' +
        (totalGuest - availablePax) +
        ' person',
    ];
  } else {
    newErrorFormPackage.quota.error = false;
    newErrorFormPackage.quota.message = [''];
  }
  return newErrorFormPackage;
};
const isCommissionValid = (errorFormPackage, splitCommision) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  if (splitCommision !== 0 && splitCommision !== 1) {
    newErrorFormPackage.commission.error = true;
    newErrorFormPackage.commission.message = [
      'Please select commission confirmation first',
    ];
  } else {
    newErrorFormPackage.commission.error = false;
    newErrorFormPackage.commission.message = [''];
  }
  return newErrorFormPackage;
};
const isRoomValid = (name, value, guests, packageType) => {
  let isValid = true;
  if (packageType === 'Fixed') {
    if (name === 'SharingRoomSum' || name === 'SingleRoomSum') {
      isValid = guests.adults + guests.childs > value;
    } else if (name === 'ChildExtraBedSum' || name === 'ChildSharingRoomSum') {
      isValid = guests.childs > value;
    } else if (name === 'ExtraBedSum') {
      isValid = guests.infants > value;
    }
  } else {
    if (
      name === 'SharingRoomSum' ||
      name === 'SingleRoomSum' ||
      name === 'ExtraBedSum'
    ) {
      isValid = guests.adults > value;
    } else if (
      name === 'ChildSharingRoomSum' ||
      name === 'ChildSingleRoomSum' ||
      name === 'ChildExtraBedSum' ||
      name === 'SharingBedSum'
    ) {
      isValid = guests.childs > value;
    } else {
      isValid = guests.infants > value;
    }
  }
  return isValid;
};
const isGuestsValid = (name, value, bookingDetailSum) => {
  let copyBookingDetailSum = copyObject(bookingDetailSum);
  let isValid = false;
  if (name === 'adults') {
    isValid = copyBookingDetailSum.AdultQty > value;
  } else if (name === 'childs') {
    isValid = copyBookingDetailSum.ChildQty > value;
  } else {
    isValid = copyBookingDetailSum.InfantQty > value;
  }
  return isValid;
};

const validationShortRegisCompany = data => {
  let copyData = copyObject(data);
  let {
    companyName,
    companyCountry,
    companyTelephone,
    companyAddress,
    companyCurrency,
    userFirstName,
    userLastName,
    userEmail,
    userPhoneNumber,
  } = copyData;
  let messageRequired = 'This section must be filled';
  let isValid = true;

  if (companyName.data === '') {
    companyName.error = true;
    companyName.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyName.error = false;
    companyName.message = '';
    isValid = isValid && true;
  }

  if (companyCountry.data === '') {
    companyCountry.error = true;
    companyCountry.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyCountry.error = false;
    companyCountry.message = '';
    isValid = isValid && true;
  }

  if (companyTelephone.data === '') {
    companyTelephone.error = true;
    companyTelephone.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyTelephone.error = false;
    companyTelephone.message = '';
    isValid = isValid && true;
  }

  if (companyAddress.data === '') {
    companyAddress.error = true;
    companyAddress.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyAddress.error = false;
    companyAddress.message = '';
    isValid = isValid && true;
  }

  if (companyCurrency.data === '') {
    companyCurrency.error = true;
    companyCurrency.message = messageRequired;
    isValid = isValid && false;
  } else {
    companyCurrency.error = false;
    companyCurrency.message = '';
    isValid = isValid && true;
  }

  if (userFirstName.data === '') {
    userFirstName.error = true;
    userFirstName.message = messageRequired;
    isValid = isValid && false;
  } else {
    userFirstName.error = false;
    userFirstName.message = '';
    isValid = isValid && true;
  }

  if (userLastName.data === '') {
    userLastName.error = true;
    userLastName.message = messageRequired;
    isValid = isValid && false;
  } else {
    userLastName.error = false;
    userLastName.message = '';
    isValid = isValid && true;
  }

  if (userEmail.data === '') {
    userEmail.error = true;
    userEmail.message = messageRequired;
    isValid = isValid && false;
  } else {
    if (!validationEmail(userEmail.data)) {
      userEmail.error = true;
      userEmail.message = 'invalid email format';
      isValid = isValid && false;
    } else {
      userEmail.error = false;
      userEmail.message = '';
      isValid = isValid && true;
    }
  }

  if (userPhoneNumber.data === '') {
    userPhoneNumber.error = true;
    userPhoneNumber.message = messageRequired;
    isValid = isValid && false;
  } else {
    userPhoneNumber.error = false;
    userPhoneNumber.message = '';
    isValid = isValid && true;
  }

  return { data: copyData, isValid };
};

const validationEmail = email => {
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

const validationPassword = password => {
  return password.match(
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9~`)({}+!#$%^&*@_]{8,})$/
  );
};

const handleValidationPassword = (password, errors, name) => {
  let copyErrors = copyObject(errors);
  let data = {};
  if (!validationPassword(password)) {
    data = {
      error: true,
      message: 'Min. 8 characters ( uppercase, lowercase, and numeric )',
    };
  } else {
    data = {
      error: false,
      message: '',
    };
  }
  return {
    ...copyErrors,
    [name]: data,
  };
};

const handleValidationEmail = (email, errors, name) => {
  let copyErrors = copyObject(errors);
  let data = {};
  if (!validationEmail(email)) {
    data = {
      error: true,
      message: 'invalid email format',
    };
  } else {
    data = {
      error: false,
      message: '',
    };
  }
  return {
    ...copyErrors,
    [name]: data,
  };
};

const handleValidationConfirmPassword = (
  password,
  confirmPassword,
  errors,
  name
) => {
  let copyErrors = copyObject(errors);
  let data = {};
  if (password !== confirmPassword) {
    data = {
      error: true,
      message: 'Your password doesnt match',
    };
  } else {
    data = {
      error: false,
      message: '',
    };
  }
  return {
    ...copyErrors,
    [name]: data,
  };
};

const isGroupCapacityValid = (errors, guestsAmount, groupCapacity) => {
  let copyErrors = copyObject(errors);
  let errorCapacity = copyErrors.GroupCapacity;
  let { adults, childs, infants } = guestsAmount;
  let totalGuest = adults + childs + infants;
  if (totalGuest > 10 && groupCapacity === 'Small') {
    errorCapacity.error = true;
    errorCapacity.message = 'Maximum pax for small group type is 10 pax';
  } else if (totalGuest < 10 && groupCapacity === 'Large') {
    errorCapacity.error = true;
    errorCapacity.message = 'Minimum pax for large group type is 11 pax';
  } else {
    errorCapacity.error = false;
    errorCapacity.message = '';
  }
  return copyErrors;
};

const validationGeneralInform = (generalInfo, firstDestination, errors) => {
  let copyErrors = copyObject(errors);
  let fill = 'Please fill ';
  let choose = 'Please choose ';
  let isError = false;
  if (generalInfo) {
    if (generalInfo.TourName.length === 0) {
      isError = true;
      copyErrors.TourName.error = true;
      copyErrors.TourName.message = fill + 'tour name';
    } else {
      copyErrors.TourName.error = false;
      copyErrors.TourName.message = '';
    }

    if (generalInfo.GroupCapacity.length === 0) {
      isError = true;
      copyErrors.GroupCapacity.error = true;
      copyErrors.GroupCapacity.message = choose + 'group capacity';
    } else {
      copyErrors.GroupCapacity.error = false;
      copyErrors.GroupCapacity.message = '';
    }

    if (generalInfo.TourType.length === 0) {
      isError = true;
      copyErrors.TourType.error = true;
      copyErrors.TourType.message = choose + 'tour type';
    } else {
      copyErrors.TourType.error = false;
      copyErrors.TourType.message = '';
    }

    if (generalInfo.TourCategory.length === 0) {
      isError = true;
      copyErrors.TourCategory.error = true;
      copyErrors.TourCategory.message = choose + 'tour category';
    } else {
      copyErrors.TourCategory.error = false;
      copyErrors.TourCategory.message = '';
    }
  }
  if (firstDestination) {
    if (firstDestination.Id.length === 0) {
      isError = true;
      copyErrors.FirstDestination.error = true;
      copyErrors.FirstDestination.message = fill + 'first destination';
    } else {
      copyErrors.FirstDestination.error = false;
      copyErrors.FirstDestination.message = '';
    }
  } else {
    isError = true;
    copyErrors.FirstDestination.error = true;
    copyErrors.FirstDestination.message = fill + 'first destination';
  }
  return { isError, data: copyErrors };
};

const validationCapacityPackage = (generalInfo, errors, guest) => {
  let copyErrors = copyObject(errors);
  let isError = false;
  let totalGuest = guest.adults + guest.childs + guest.infants;
  if (generalInfo) {
    if (generalInfo.GroupCapacity === 'Large') {
      if (totalGuest <= 10) {
        isError = true;
        copyErrors.GroupCapacity.error = true;
        copyErrors.GroupCapacity.message =
          'Minimum pax for large group type is 11 pax';
      } else {
        copyErrors.GroupCapacity.error = false;
        copyErrors.GroupCapacity.message = '';
      }
    } else {
      if (generalInfo.GroupCapacity === 'Small') {
        if (totalGuest > 10) {
          isError = true;
          copyErrors.GroupCapacity.error = true;
          copyErrors.GroupCapacity.message =
            'Maximum pax for small group type is 10 pax';
        } else {
          copyErrors.GroupCapacity.error = false;
          copyErrors.GroupCapacity.message = '';
        }
      }
    }
  }
  return { isError, data: copyErrors };
};

//validationFor series package variable date

const checkSelectedDateForVariableDate = (
  date,
  dataToBeFiltered,
  totalDays
) => {
  let isValidDate = false;
  let endDateValid = addDate(date, totalDays, 'd');
  dataToBeFiltered.filter(d => {
    if (!isValidDate) {
      isValidDate =
        getTime(date) >= getTime(d.StartDate) &&
        getTime(date) <= getTime(d.EndDate) &&
        (getTime(endDateValid) >= getTime(d.StartDate) &&
          getTime(endDateValid) <= getTime(d.EndDate));
    }
    return isValidDate;
  });
  return isValidDate;
};

const validationSelectedDateSeriesVariable = (
  selectDate,
  errorFormPackage,
  periods,
  totalDays
) => {
  let newErrorFormPackage = copyObject(errorFormPackage);
  let validaDate = checkSelectedDateForVariableDate(
    selectDate,
    periods,
    totalDays
  );
  if (!selectDate || !validaDate) {
    newErrorFormPackage.selectedDate.error = true;
    newErrorFormPackage.selectedDate.message = ['Please select date'];
  } else {
    newErrorFormPackage.selectedDate.error = false;
    newErrorFormPackage.selectedDate.message = [];
  }
  return newErrorFormPackage;
};

export {
  validationFillGuest,
  selectTourLeader,
  isGuestError,
  validationTourPax,
  isErrorFormPackage,
  isRoomAllocationValid,
  isQuotaValid,
  isCommissionValid,
  isRoomValid,
  isGuestsValid,
  validationFillAllGuests,
  isAllGuestsError,
  validationTotalGuests,
  isGroupCapacityValid,
  validationShortRegisCompany,
  isShortCompanyFormValid,
  validationAllocationPax,
  validationGeneralInform,
  validationPassword,
  handleValidationEmail,
  handleValidationPassword,
  handleValidationConfirmPassword,
  validationCapacityPackage,
  validationMinPax,
  //==============================
  validationSelectedDateSeriesVariable,
};
