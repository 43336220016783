import React, { Component } from 'react';
import { Grid, Form, Button, Label } from 'semantic-ui-react';
import Input from '../../../../components/input/WithLabel';
import { UpdateCompanyButton } from './../';
import PropTypes from 'prop-types';
import styles from './../../styles';
import { ModalUploadImage } from './../../../shared/components/modal';
import ImageProgressive from '../../../../components/progressiveImage/ImageProgressive';
import { ModalAccountManagerProfile } from './';
import { optionsGender } from './../../constant/options';
import { withTranslation } from 'react-i18next';

class AccountDetail extends Component {
  state = { isDetail: true, isOpenModalImage: false };

  openModal = () => {
    this.setState({ openModalImage: true });
  };
  handleClose = () => {
    this.setState({ openModalImage: false });
  };
  openModal = () => {
    this.setState({ openModalAcoountManager: true });
  };
  handleClose = () => {
    this.setState({ openModalAccountManager: false });
  };
  handleChangeMode = () => {
    const { isDetail } = this.state;
    this.setState(
      { isDetail: !isDetail },
      () => isDetail || this.props.handleUpdateProfile()
    );
  };
  handleModalImage = () =>
    this.setState(state => ({
      ...this.state,
      isOpenModalImage: !state.isOpenModalImage,
    }));
  handleModalAccountManager = () =>
    this.setState(state => ({
      ...this.state,
      isOpenModalAccountManager: !state.isOpenModalAccountManager,
    }));

  render() {
    const { t } = this.props;
    let { profiles, imgSrc, handleUpdateImage } = this.props;
    let {
      Username,
      Firstname,
      Lastname,
      Email,
      PhoneNbr,
      Country,
      CountryID,
      Gender,
      AccountManager,
    } = profiles;
    const {
      isDetail,
      isOpenModalImage,
      isOpenModalAccountManager,
    } = this.state;
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={1} />
            <Grid.Column width={7} textAlign="center">
              <Form>
                <Form.Field>
                  <ImageProgressive
                    preview={imgSrc}
                    src={imgSrc}
                    centered
                    verticalAlign="top"
                    styles={styles.image}
                  />
                </Form.Field>
                <Form.Group widths="equal">
                  <Form.Field>
                    <Button
                      style={styles.buttonGoldNoPadding}
                      content={t('updateImage')} //"Update Image"
                      onClick={this.handleModalImage}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column width={7}>
              <Form>
                <UpdateCompanyButton
                  stylesButton={styles}
                  contextstate={this.state}
                  onClick={this.handleChangeMode}
                  text={isDetail ? `${t('EDIT')}` : `${t('SAVE')}`}
                  // 'EDIT' : 'SAVE'
                />
                <Form.Field>
                  <Input
                    label={t('username')} //"Username"
                    placeholder={t('username')} //"Username"
                    type="text"
                    labelStyles={styles.displayBlockFontBold}
                    inputStyles={styles.inputTextStyle}
                    value={Username}
                    handleChange={this.props.handleChange}
                    fluid={true}
                    name="Username"
                    disabled={isDetail}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={t('firstName')} //"First Name"
                    placeholder={t('firstName')} //"First Name"
                    type="text"
                    labelStyles={styles.displayBlockFontBold}
                    inputStyles={styles.inputTextStyle}
                    value={Firstname}
                    handleChange={this.props.handleChange}
                    name="Firstname"
                    fluid={true}
                    disabled={isDetail}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={t('lastName')} //"Last Name"
                    placeholder={t('lastName')} //"Last Name"
                    type="text"
                    labelStyles={styles.displayBlockFontBold}
                    inputStyles={styles.inputTextStyle}
                    value={Lastname}
                    handleChange={this.props.handleChange}
                    name="Lastname"
                    fluid={true}
                    disabled={isDetail}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={t('telephone')} //"Telephone"
                    placeholder={t('telephone')} //"Telephone"
                    type="number"
                    labelStyles={styles.displayBlockFontBold}
                    inputStyles={styles.inputTextStyle}
                    value={PhoneNbr}
                    handleChange={this.props.handleChange}
                    name="PhoneNbr"
                    fluid={true}
                    disabled={isDetail}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={t('country')} //"Country"
                    inline={false}
                    type="select"
                    labelStyles={styles.fontBold}
                    inputStyles={styles.inputSelectionStyle}
                    value={CountryID}
                    options={Country}
                    handleChange={this.props.handleChange}
                    disabled
                    fluid={true}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={t('email')} //"Email"
                    placeholder={t('email')} //"Email"
                    type="email"
                    labelStyles={styles.displayBlockFontBold}
                    inputStyles={styles.inputTextStyle}
                    value={Email}
                    handleChange={this.props.handleChange}
                    disabled={isDetail}
                    fluid={true}
                    name="Email"
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    label={t('gender')} //"Gender"
                    inline={false}
                    type="select"
                    placeholder="Select Gender"
                    labelStyles={styles.fontBold}
                    inputStyles={styles.inputSelectionStyle}
                    options={optionsGender}
                    value={Gender}
                    handleChange={this.props.handleChange}
                    name="Gender"
                    fluid={true}
                    disabled={isDetail}
                  />
                </Form.Field>
                <Form.Field>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Label style={styles.accountManagerLabel}>
                          {/* Touress Account Manager */}
                          {t('touressAccountManager')}
                        </Label>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width="10">
                              <span style={styles.accountManagerName}>
                                {AccountManager.FirstName
                                  ? `${AccountManager.FirstName} ${
                                      AccountManager.LastName
                                    }`
                                  : '-'}
                              </span>
                            </Grid.Column>
                            <Grid.Column textAlign="right" width="6">
                              <Button
                                onClick={this.handleModalAccountManager}
                                style={
                                  AccountManager.FirstName
                                    ? styles.accountManagerButton
                                    : styles.displayNone
                                }
                              >
                                {/* See Detail */}
                                {t('seeDetail')}
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={1} />
          </Grid.Row>
        </Grid>
        <ModalUploadImage
          openModal={isOpenModalImage}
          handleClose={this.handleModalImage}
          header="Upload Image"
          image={imgSrc}
          handleUpdateImage={handleUpdateImage}
          data={profiles}
        />
        <ModalAccountManagerProfile
          openModal={isOpenModalAccountManager}
          handleClose={this.handleModalAccountManager}
          header="Account Manager Profile"
          profiles={profiles}
        />
      </div>
    );
  }
}
AccountDetail.propTypes = {
  profiles: PropTypes.shape({
    firstName: PropTypes.string,
    username: PropTypes.string,
    phoneNbr: PropTypes.string,
    countryId: PropTypes.string,
    country: PropTypes.object,
    email: PropTypes.string,
    gender: PropTypes.string,
    imageUrl: PropTypes.string,
    accountManager: PropTypes.object,
  }),
  handleChange: PropTypes.func,
  handleUpdateProfile: PropTypes.func,
  onImageSelect: PropTypes.func,
  imgSrc: PropTypes.string,
  handleUpdateImage: PropTypes.func,
  t: PropTypes.func,
};
export default withTranslation()(AccountDetail);
