import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import RestaurantCard from './RestaurantCard';
import styles from './../../styles';
import ModalDetail from './ModalDetailRestaurant';
const NextArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' next'} onClick={onClick} />;
};
const PrevArrow = props => {
  const { className, onClick } = props;
  return <div className={className + ' prev'} onClick={onClick} />;
};
class RestaurantCarousel extends React.Component {
  state = {
    profile: {},
    openDetail: false,
  };
  handleButtonInfo = (e, { name, value }) => {
    this.setState({ ...this.state, [name]: !this.state[name], profile: value });
  };
  handleClose = () => this.setState({ ...this.state, openDetail: false });
  groupOrderedItem = listData => {
    let ordered = [];
    listData.data &&
      listData.data.map(e => {
        let indexOrdered = ordered.findIndex(
          item => item.id === e.ServiceItemId
        );
        return indexOrdered === -1
          ? (ordered = ordered.concat({
              id: e.ServiceItemId,
              ticket: [
                {
                  qty: e.Qty,
                  desc:
                    e.ItemName.split('-')[0] +
                    ' for ' +
                    e.Description.split('_')[1],
                },
              ],
            }))
          : ordered[indexOrdered].ticket.push({
              qty: e.Qty,
              desc:
                e.ItemName.split('-')[0] +
                ' for ' +
                e.Description.split('_')[1],
            });
      });
    return ordered;
  };
  render() {
    let { profile, openDetail } = this.state;
    const settings = {
      className: 'slider variable-width',
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    let { restaurantSum, selectRestaurants, selectMenus } = this.props;
    return (
      <div style={styles.marginLeft5px}>
        <Slider {...settings}>
          {restaurantSum.length > 0 &&
            restaurantSum.map(day => {
              let ordered = this.groupOrderedItem(day);
              return ordered.map((ticket, index) => {
                let menu = selectMenus.filter(
                  e => e.ServiceItemId === ticket.id
                )[0];
                let restaurant =
                  menu &&
                  selectRestaurants.filter(
                    e => e.Id === menu.RestaurantProfileId
                  )[0];
                return (
                  <div key={index} style={styles.widthCard}>
                    <RestaurantCard
                      handleButtonInfo={this.handleButtonInfo}
                      restaurant={restaurant}
                      menu={menu}
                      ticket={ticket.ticket}
                      day={day.day}
                      date={day.date}
                    />
                  </div>
                );
              });
            })}
        </Slider>
        <ModalDetail
          open={openDetail}
          onClose={this.handleClose}
          image={profile.ImageUrl}
          images={profile.RestaurantProfileImages}
          name={profile.Name}
          address={profile.Address}
          description={profile.Description}
        />
      </div>
    );
  }
}
NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
RestaurantCarousel.propTypes = {
  restaurantSum: PropTypes.array,
  selectRestaurants: PropTypes.array,
  selectMenus: PropTypes.array,
};
export default RestaurantCarousel;
