import { GOLD_COLOR, BACKGROUND_COLOR } from '../../../../constant/colour';
const GREY_COLOR = '#d4d4d4';
const style = {
  backgroundGold: {
    background: GOLD_COLOR,
  },
  goldColor: {
    color: GOLD_COLOR,
  },
  greyColor: {
    color: GREY_COLOR,
  },
  borderGold: {
    border: '2px solid #e6ca6b',
  },
  buttonGold: {
    background: GOLD_COLOR,
    color: BACKGROUND_COLOR,
  },
  buttonGold100percen: {
    background: GOLD_COLOR,
    color: BACKGROUND_COLOR,
    width: '100%',
  },
  paddingTopBottom2px: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  paddingTopBottom10px: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  paddingLeftRight15px: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  paddingTop0px: {
    paddingTop: '0px',
  },

  collapseSearchPackage: {
    paddingBottom: '0px',
    marginBottom: '0px',
    heightMax: '100px',
  },
  tabActive: {
    padding: '15px',
    fontWeight: 'bold',
    background: '#e6ca6b',
    color: BACKGROUND_COLOR,
  },
  tabNonActive: {
    padding: '15px',
    fontWeight: 'bold',
    color: '#e6ca6b',
  },
  inputTransparent: {
    width: '100%',
    border: 'none',
  },
  selectTransparent: {
    width: '100%',
  },
  buttonTransparent: {
    background: '#fff',
    color: GOLD_COLOR,
    padding: '0px',
  },
  labelInputRange: {
    borderRight: 'none',
    paddingRight: '0px',
  },
  textBlackBold: {
    color: '#252525',
    fontWeight: 'bold',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segmentPaddingTen: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
    padding: '10px',
  },
  segmentTabMenu: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
    padding: '17px',
    width: '100%',
  },
  width80percent: { width: '80%' },
  noPaddingBottom: { paddingBottom: 0 },
  width298: { width: '298px' },
  width190: { width: '190px' },
  width380: { width: '380px' },
  labelInputStyle: {
    color: '#252525',
    fontWeight: 'bold',
  },
};

export default style;
