import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
import style from './styles';
import { getCancellationReasonByTypeApi } from '../../../../api/cancellationReasonApi';
import ModalCancelBooking from './ModalCancelBooking';
import ModalListCancellationReason from './ModalListCancellationReason';

const ModalCancellationReason = props => {
  //==============initial=============
  const { openModal, handleClose, handleSave, loadingPostCancel } = props;
  const [
    openModalCancellationReasonList,
    setOpenModalCancellationReasonList,
  ] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataCancellationReason, setDataCancellationReason] = useState('');

  const openModalCancellationReason = () => {
    setOpenModalCancellationReasonList(true);
    setLoadingModal(true);
    getCancellationReasonByTypeApi('Travel_agent')
      .then(result => {
        setDataCancellationReason(result.data);
        setLoadingModal(false);
      })
      .catch(() => {
        //eslint-disable-next-line
        setLoadingModal(false);
      });
  };

  const handleCloseModal = () => {
    setOpenModalCancellationReasonList(false);
  };

  const handleConfirm = data => {
    handleSave(data);
    setOpenModalCancellationReasonList(false);
  };

  return (
    <Modal
      dimmer="blurring"
      open={openModal}
      onClose={handleClose}
      size="small"
      style={style.modalWidthCancel}
    >
      <ModalCancelBooking
        open={openModalCancellationReason}
        close={handleClose}
      />
      <ModalListCancellationReason
        open={openModalCancellationReasonList}
        close={handleCloseModal}
        data={dataCancellationReason}
        handleConfirm={handleConfirm}
        loadingModal={loadingModal}
        loadingPostCancel={loadingPostCancel}
      />
    </Modal>
  );
};
ModalCancellationReason.propTypes = {
  openModal: PropTypes.bool,
  loadingPostCancel: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
};
export default ModalCancellationReason;
