import {
  convertDateFormat,
  getHourAndMinute,
} from '../../../../../../genericFunction/moment';
const addActivityData = (activity, oldState, dateTime, activityType) => {
  let state = { ...oldState };
  state.activityData.Name = activity.Name;
  // state.activityData.Startime = activity.IsSolidStartTime
  //   ? `${convertDateFormat(dateTime, 'YYYY-MM-DD')}T${getHourAndMinute(
  //       activity.OperationStartTime ? activity.OperationStartTime : dateTime
  //     )}:00`
  //   : dateTime;
  state.activityData.Startime =
    activityType !== 'EAT'
      ? activity.IsSolidStartTime
        ? `${convertDateFormat(dateTime, 'YYYY-MM-DD')}T${getHourAndMinute(
            activity.OperationStartTime ? activity.OperationStartTime : dateTime
          )}:00`
        : state.activityData.Startime
      : `${convertDateFormat(dateTime, 'YYYY-MM-DD')}T${getHourAndMinute(
          activity.OperationStartTime
            ? activity.OperationStartTime
            : state.activityData.Startime
        )}:00`;
  state.activityData.Duration = activity.OptimumDuration
    ? activity.OptimumDuration
    : 3600;
  state.activityData.optimumDuration = activity.OptimumDuration
    ? activity.OptimumDuration
    : 3600;
  state.activityData.OperationEndTime = activity.OperationEndTime
    ? activity.OperationEndTime
    : null;
  state.activityData.OperationStartTime = activity.OperationStartTime
    ? activity.OperationStartTime
    : null;
  state.activityData.IsSolidStartTime = activity.IsSolidStartTime
    ? activity.IsSolidStartTime
    : false;
  state.activityObj = activity;
  state.tempStartTime = getHourAndMinute(
    activity.OperationStartTime ? activity.OperationStartTime : dateTime
  );
  return state;
};

export default addActivityData;
