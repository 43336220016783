import apiClient from './apiClient';
import { LANGUAGE_URL } from './apiUrl';

const getLanguageAllApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(LANGUAGE_URL);
};

export { getLanguageAllApi };
