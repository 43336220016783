import React, { Component } from 'react';
import Slider from 'react-slick';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styles from './styles';
const NextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' mainSlideNextGrey'}
      style={{
        ...style,
        ...styles.navStyle,
      }}
      onClick={onClick}
    />
  );
};
const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' mainSlidePrevGrey'}
      style={{
        ...style,
        ...styles.navStyle,
      }}
      onClick={onClick}
    />
  );
};

export default class SlideChangeHooks extends Component {
  state = {
    activeSlide: 0,
    activeSlide2: 0,
  };
  render() {
    const { width, children } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current }),
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    return (
      <Grid.Column width={width}>
        <Slider {...settings}>{children}</Slider>
      </Grid.Column>
    );
  }
}

SlideChangeHooks.propTypes = {
  width: PropTypes.number,
  children: PropTypes.object,
};
NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};
