import apiClient from './apiClient';
import {
  GET_CITY_IN_COUNTRY_URL,
  GET_PLACE_BY_REGION_URL,
  GET_PLACE_BY_PLACE_TYPE_ID_URL,
} from './apiUrl';

const getCityInCountryApi = () => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(GET_CITY_IN_COUNTRY_URL);
};

const getPlaceByRegionApi = region => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_PLACE_BY_REGION_URL}${region}`);
};

const getPlaceByPlaceTypeIdApi = id => {
  apiClient.defaults.headers['Authorization'] =
    'Bearer ' + localStorage.getItem('token');
  return apiClient.get(`${GET_PLACE_BY_PLACE_TYPE_ID_URL}${id}`);
};

export { getCityInCountryApi, getPlaceByRegionApi, getPlaceByPlaceTypeIdApi };
