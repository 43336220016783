import React from 'react';
import { Segment } from 'semantic-ui-react';
import styles from './styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SegmentTourDescription = props => {
  const { t } = useTranslation();
  return (
    <Segment style={styles.segmentNoPadding}>
      {/* Tour Description */}
      <h2>{t('tourDescription')}</h2>
      <p> {props.description} </p>
    </Segment>
  );
};
SegmentTourDescription.propTypes = {
  description: PropTypes.string,
  t: PropTypes.func,
};
export default SegmentTourDescription;
