import {
  GET_TRANSPORTATION_PROFILE_RATINGS,
  GET_TRANSPORTATION_PROFILE_CATEGORIES,
  GET_TRANSPORTATION_PROFILES,
  GET_TRANSPORTATION_TYPES,
  GET_TRANSPORTATION_SEAT_TYPES,
  GET_TRANSPORTATION_UNITS_FILTER,
  RESET_STATUS_TRANSPORTATION,
  RESET_STATUS_TRANSPORTATION_UNITS_FILTER,
  // GET_TRANSPORTATION_UNIT_BY_ID,
  // RESET_STATUS_TRANSPORTATION_UNIT_BY_ID,
} from './actionTypes';
import {
  getTransportationProfileRatingsApi,
  getTransportationProfileCategoriesApi,
  getTransportationProfilesApi,
  getTransportationTypesApi,
  getTransportationSeatTypesApi,
  getTransportationUnitsFilterApi,
  // getTransportationUnitByIdApi,
} from '../../../api/transportationApi';

const getTransportationProfileRatings = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_PROFILE_RATINGS,
      payload: getTransportationProfileRatingsApi(),
    });
  };
};

const getTransportationProfileCategories = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_PROFILE_CATEGORIES,
      payload: getTransportationProfileCategoriesApi(),
    });
  };
};

const getTransportationProfiles = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_PROFILES,
      payload: getTransportationProfilesApi(),
    });
  };
};

const getTransportationTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_TYPES,
      payload: getTransportationTypesApi(),
    });
  };
};

const getTransportationSeatTypes = () => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_SEAT_TYPES,
      payload: getTransportationSeatTypesApi(),
    });
  };
};

const getTransportationUnitsFilter = (data, bodyReq) => {
  return dispatch => {
    return dispatch({
      type: GET_TRANSPORTATION_UNITS_FILTER,
      payload: getTransportationUnitsFilterApi(data, bodyReq),
    });
  };
};

const resetStatusTransportation = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TRANSPORTATION,
    });
  };
};
const resetStatusTransportationUnitsFilter = () => {
  return dispatch => {
    return dispatch({
      type: RESET_STATUS_TRANSPORTATION_UNITS_FILTER,
    });
  };
};
// const resetStatusTransportationUnitById = () => {
//   return dispatch => {
//     return dispatch({
//       type: RESET_STATUS_TRANSPORTATION_UNIT_BY_ID,
//     });
//   };
// };
// const getTransportationUnitById = id => {
//   return dispatch => {
//     return dispatch({
//       type: GET_TRANSPORTATION_UNIT_BY_ID,
//       payload: getTransportationUnitByIdApi(id),
//     });
//   };
// };
export {
  getTransportationProfileRatings,
  getTransportationProfileCategories,
  getTransportationProfiles,
  getTransportationTypes,
  getTransportationSeatTypes,
  getTransportationUnitsFilter,
  resetStatusTransportation,
  resetStatusTransportationUnitsFilter,
  // resetStatusTransportationUnitById,
  // getTransportationUnitById,
};
