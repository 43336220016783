import * as types from './constant/actionTypes';

const initialState = {
  transportationUnit: {},
  getTransportationUnitStatus: '',
  errors: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //---------------------------------
    // GET_TRANSPORTATION_UNIT_BY_ID
    //---------------------------------
    case types.GET_TRANSPORTATION_UNIT_BY_ID:
      return { ...state };
    case types.GET_TRANSPORTATION_UNIT_BY_ID_PENDING:
      return { ...state, loading: true };
    case types.GET_TRANSPORTATION_UNIT_BY_ID_FULFILLED:
      return {
        ...state,
        transportationUnit: action.payload.data,
        errors: {},
        loading: false,
        getTransportationUnitStatus: 'success',
      };
    case types.GET_TRANSPORTATION_UNIT_BY_ID_REJECTED:
      return {
        ...state,
        transportationUnit: [],
        errors: action.payload.response.data,
        loading: false,
        getTransportationUnitStatus: 'failed',
      };
    case types.RESET_STATUS_TRANSPORTATION_UNIT_BY_ID:
      return {
        ...state,
        getTransportationUnitStatus: '',
      };
    default:
      return state;
  }
};

export default reducer;
