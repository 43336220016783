const COLOR_WHITE = '#fefefe';
const COLOR_RED = '#f44336';
const style = {
  inputSelectionStyle: {
    // border: 'none',
    // borderBottom: '1px solid grey',
    // borderRadius: '0px',
  },
  inputTextStyle: {
    // padding: '.638500em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  displayBlockFontWeight: {
    display: 'block',
    fontWeight: 'bold',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  noBorder: {
    border: 'none',
  },
  buttonDelete: {
    padding: '15px',
    color: COLOR_RED,
    background: COLOR_WHITE,
  },
  marginBotFourteen: { marginBottom: '14px' },
  segment: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
};

export default style;
