import React, { Component } from 'react';
import { Button, Modal, Form, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputTime from '../../../../../../components/input/Time';
import InputDuration from '../../../../../../components/input/Duration';
import InputWithLabel from '../../../../../../components/input/WithLabel';
// Excursion
import ModalListExcursion from '../modalListExcursion/ModalListExcursion';
import {
  getExcursionTypes,
  getExcursionByFilter,
  getExcursionById,
} from '../../../../actions/excursionAction';
// Excursion
// Restaurant
import ModalListRestaurant from '../modalListRestaurant/ModalListRestaurant';
import {
  getRestaurantByFilter,
  getRestaurantSpecializations,
  getRestaurantMenuClasses,
  getMenuById,
} from '../../../../actions/restaurantAction';
// Restaurant
import {
  convertDateFormat,
  getHourAndMinute,
} from '../../../../../../genericFunction/moment';
import { setDailyProgram } from '../../../../actions/customPackageAction';
import {
  addDailyDetails,
  editActivityData,
} from '../../../../../../genericFunction/itineraryBuilder/dailyProgram';
import { SumSecond } from '../../../../../../genericFunction/itineraryBuilder/timeHelper';
import {
  RECREATION,
  EAT,
  FREETIME,
  DEPARTURE,
  ARRIVAL,
} from '../../../../../../constant/activityTypes';
import styles from './styles';
import renderHeaderTitle from '../../services/helper/getModalHeader';
import isTimeOverlap from '../../services/helper/timeOverlap';
import addActivityData from '../../services/data/activityData';
import { withTranslation } from 'react-i18next';
import getDemoPriceObj from '../../../../services/helper/getDemoPriceObject';

const dataExcursion = {
  cityid: '',
  attractionTypeId: '',
  requestedDate: '2018-09-08',
  pax: 1,
};

const dataRestaurant = {
  cityId: '',
  ratingIds: '',
  locationIds: '',
  specializationId: '',
  menuClassId: '',
  facilityIds: '',
  requestedDate: '2018-17-28',
  pax: 1,
};
class ModalSetActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openModalExcursion: false,
      openModalRestaurant: false,
      activityData: {
        Name: '',
        Startime: '',
        Duration: 0,
        optimumDuration: 0,
        Note: null,
      },
      activityObj: null,
      isOpen: false,
      error: { error: false, message: '' },
    };
  }
  componentDidUpdate() {
    if (this.props.open !== this.state.isOpen) {
      this.setInitialData();
    }
  }
  close = () => {
    this.props.closeModal();
  };
  //general button open modal
  openModalExcursion = () => {
    this.setState({ openModalExcursion: true });
    let { guests, activity } = this.props;
    dataExcursion.pax = guests.adults + guests.childs;
    dataExcursion.requestedDate = convertDateFormat(
      activity.DateTime,
      'YYYY-MM-DD'
    );
    dataExcursion.cityid = activity.Destination;
    this.props.getExcursionTypes();
    this.props.getExcursionByFilter(dataExcursion, this.helperDemoPriceData());
  };
  //general button open modal
  openModalRestaurant = () => {
    this.setState({ openModalRestaurant: true });
    let { guests, activity } = this.props;
    dataRestaurant.pax = guests.adults + guests.childs;
    dataRestaurant.requestedDate = convertDateFormat(
      activity.DateTime,
      'YYYY-MM-DD'
    );
    dataRestaurant.cityId = activity.Destination;
    this.props.getRestaurantSpecializations();
    this.props.getRestaurantMenuClasses();
    this.props.getRestaurantByFilter(
      dataRestaurant,
      this.helperDemoPriceData()
    );
  };

  helperGetActionType = activityType => {
    if (activityType === 'EAT') {
      return 'Restaurants';
    } else if (activityType === 'RECREATION') {
      return 'Attractions';
    }
  };

  helperDeleteOldActivityInEstimatedPrice = demoPrice => {
    const { action, activity, activityType } = this.props;
    if (action === 'EDIT') {
      let obj = this.helperGetActionType(activityType);
      let newDemoPrice = { ...demoPrice };
      let dataToFilter = newDemoPrice[obj];
      newDemoPrice[obj] = dataToFilter.filter(
        item =>
          item.ServiceItemId !== activity.Item.ServiceItemId &&
          item.DateTime !== activity.Item.DateTime
      );
      return newDemoPrice;
    } else {
      return demoPrice;
    }
  };

  helperDemoPriceData = () => {
    const { roomAllocation, HeadlineProgram, dailyProgram } = this.props;
    let demoPrice = getDemoPriceObj(
      roomAllocation,
      HeadlineProgram,
      dailyProgram
    );
    let data = this.helperDeleteOldActivityInEstimatedPrice(demoPrice);
    return data;
    // return demoPrice;
  };
  closeModal = () => {
    this.setState({
      openModalExcursion: false,
      openModalRestaurant: false,
    });
  };

  setError = (value, message) => {
    this.setState({
      ...this.state,
      error: {
        error: value,
        message: message,
      },
    });
  };
  checkExcursion = () => {
    let { ServiceItemId } = this.state.activityObj;
    let { movements, action } = this.props;
    return (
      action === 'ADD' &&
      movements &&
      movements.some(
        move =>
          move.MovementName === RECREATION &&
          move.Item.ServiceItemId === ServiceItemId
      )
    );
  };
  handleSetActivity = () => {
    const { t } = this.props;
    let { activityType } = this.props;
    let { activityData } = this.state;
    if (activityType !== FREETIME && activityData.Name === '') {
      this.setError(
        true, //Please select your
        `${t('')} ${
          activityType === EAT ? `${t('restaurant')}` : `${t('excursion')}` // 'Restaurant' : 'Excursion'
        }`
      );
    } else if (activityType === FREETIME && activityData.Duration < 3600) {
      this.setError(true, `${t('')}`); //The Minimum duration is 1 hour
    } else if (activityType === RECREATION && this.checkExcursion()) {
      this.setError(true, `${t('')}`); //The Excursion is Already Selected
    } else if (activityType === RECREATION && this.checkAbsoluteTime()) {
      this.setError(true, `${t('')}`); //The Excursion have Fixed Time
    } else {
      this.setDaily();
      this.setError(false, '');
    }
  };
  setInitialData = async () => {
    let { activityData, activityObj } = this.state;
    let { activityType, action, activity } = this.props;
    let newActivityData = { ...activityData };
    let newActivityObj = { ...activityObj };
    if (activityType === FREETIME) {
      newActivityData.optimumDuration = 1800;
      newActivityData.Duration = action === 'EDIT' ? activity.Duration : 3600;
      newActivityData.Note = activity.Note;
      newActivityData.Startime =
        action === 'EDIT'
          ? activity.DateTime
          : SumSecond(activity.DateTime, activity.Duration);
    } else if ([RECREATION, EAT].indexOf(activityType) !== -1) {
      if (action === 'EDIT') {
        let response =
          activityType === RECREATION
            ? await this.props.getExcursionById(activity.Item.ServiceItemId)
            : await this.props.getMenuById(activity.Item.ServiceItemId);
        newActivityObj = response ? response.value.data : newActivityObj;
        newActivityData.Name = activity.Item.Name;
        newActivityData.Startime = activity.DateTime;
        newActivityData.Duration = activity.Duration;
        newActivityData.optimumDuration = response
          ? response.value.data.OptimumDuration
            ? response.value.data.OptimumDuration
            : 1800
          : 1800;
        newActivityData.OperationEndTime = response
          ? response.value.data.OperationEndTime
          : null;
        newActivityData.OperationStartTime = response
          ? response.value.data.OperationStartTime
          : null;
        newActivityData.IsSolidStartTime = response
          ? response.value.data.IsSolidStartTime
            ? response.value.data.IsSolidStartTime
            : false
          : false;
      } else {
        newActivityData = {
          Name: '',
          Startime: SumSecond(activity.DateTime, activity.Duration),
          Duration: 3600,
          optimumDuration: 1800,
          Note: null,
        };
        newActivityObj = null;
      }
    }
    this.setState({
      ...this.state,
      activityData: newActivityData,
      isOpen: this.props.open,
      activityObj: newActivityObj,
    });
  };
  setDaily = () => {
    let {
      dailyProgram,
      movementList,
      index,
      day,
      activityType,
      action,
      activity,
    } = this.props;
    let { activityObj, activityData } = this.state;
    let typeMovement = movementList.find(item => item.Name === activityType);
    let itemMovement = activityType === FREETIME ? null : activityObj;
    activityData.Startime = activityData.Startime
      ? activityData.Startime
      : activity.DateTime;
    const move = dailyProgram[day].Movements[index].MovementName;
    let newDaily =
      action === 'ADD'
        ? addDailyDetails(
            dailyProgram,
            day,
            index,
            typeMovement,
            movementList,
            itemMovement,
            activityData
          )
        : editActivityData(
            dailyProgram,
            day,
            index,
            typeMovement,
            itemMovement,
            activityData
          );
    this.props.setDailyProgramAfterAdd(
      newDaily,
      day,
      index,
      move,
      movementList,
      action
    );
    this.props.closeModal();
  };
  handleChange = (e, { value }) => {
    let activityData = { ...this.state.activityData };
    activityData.Note = value;
    this.setState({ ...this.state, activityData: activityData });
  };
  setActivity = activity => {
    let { activityType } = this.props;
    let { DateTime } = this.props.activity;
    let state = addActivityData(activity, this.state, DateTime, activityType);
    this.setState({
      ...this.state,
      activityData: state.activityData,
      activityObj: state.activityObj,
    });
    this.closeModal();
  };
  changeDuration = value => {
    let { activityData } = { ...this.state };
    activityData.Duration = value;
    this.setState({ ...this.state, activityData: activityData });
  };
  setStartTime = (context, value) => {
    let state = { ...this.state };
    state.activityData.Startime = `${convertDateFormat(
      state.activityData.Startime,
      'YYYY-MM-DD'
    )}T${value}:00`;
    context.setState({ ...context.state, activityData: state.activityData });
  };
  checkAbsoluteTime = () => {
    let { movements } = this.props;
    let { activityData } = this.state;
    if (activityData.IsSolidStartTime) {
      let arrival = movements.find(item => item.MovementName === ARRIVAL);
      let departure = movements.find(item => item.MovementName === DEPARTURE);
      let startTime = activityData.Startime;
      let endTime = SumSecond(activityData.Startime, activityData.Duration);
      let isoverlapArrival = arrival
        ? isTimeOverlap(arrival.DateTime, startTime, endTime)
        : false;
      let isoverlapDeparture = departure
        ? isTimeOverlap(departure.DateTime, startTime, endTime)
        : false;
      return isoverlapArrival || isoverlapDeparture;
    } else return false;
  };
  closeModalSetActivity = () => {
    let error = { error: false, message: '' };
    this.setState(
      {
        ...this.state,
        error,
      },
      () => this.props.closeModal()
    );
  };
  helperGetDestinationCity = () => {
    const { activity, cityInCountry } = this.props;
    return activity
      ? cityInCountry.find(item => item.Id === activity.Destination).Name
      : null;
  };
  render() {
    const { t } = this.props;
    let {
      openModalExcursion,
      openModalRestaurant,
      activityData,
      error,
    } = this.state;
    const {
      open,
      activityType,
      excursions,
      activity,
      movements,
      index,
    } = this.props;
    let startTime = activityData.Startime
      ? activityData.Startime
      : activity
      ? activity.DateTime
      : '00:00';
    let destLabel = this.helperGetDestinationCity();
    let modalHeaderTitle = renderHeaderTitle(activityType);
    let translateHeaderTitle =
      modalHeaderTitle === 'Set Excursion Activity'
        ? t('setExcursionActivity')
        : modalHeaderTitle === 'Set Meal Activity'
        ? t('setMealActivity')
        : modalHeaderTitle === 'Set Free Time Activity'
        ? t('setFreeTimeActivity')
        : '';
    return (
      <div>
        <Modal size="mini" open={open} onClose={this.closeModalSetActivity}>
          {/* <Modal.Header>{renderHeaderTitle(activityType)}</Modal.Header> */}
          <Modal.Header>{translateHeaderTitle}</Modal.Header>
          <Modal.Content>
            <div style={styles.marginLeftRight30}>
              <Form>
                <Form.Field>
                  <InputWithLabel
                    label={t('destination')} //"Destination"
                    placeholder="Destination"
                    type="text"
                    value={destLabel}
                    labelStyles={styles.labelStyles}
                    disabled
                    fluid={true}
                  />
                </Form.Field>
                {activityType !== FREETIME && (
                  <Form.Field>
                    <InputWithLabel
                      label={
                        activityType === EAT
                          ? `${t('restaurant')}`
                          : `${t('excursion')}`
                      } // 'Restaurant' : 'Excursion'}
                      placeholder={
                        activityType === EAT
                          ? `${t('restaurant')}`
                          : `${t('excursion')}` // 'Restaurant' : 'Excursion'
                      }
                      type="text"
                      value={activityData.Name}
                      labelStyles={styles.labelStyles}
                      handleClick={
                        activityType === EAT
                          ? this.openModalRestaurant
                          : this.openModalExcursion
                      }
                      fluid={true}
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <InputTime
                    labelText={t('startTime')} //"Start Time"
                    labelStyles={styles.labelStyles}
                    context={this}
                    value={getHourAndMinute(startTime)}
                    setTime={
                      this.setStartTime // inputStyles={styles.inputTimeStyle}
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <InputDuration
                    label={t('duration')} //"Duration"
                    labelStyles={styles.labelStyles}
                    min={activityData.optimumDuration}
                    value={activityData.Duration}
                    onChange={this.changeDuration}
                  />
                </Form.Field>
                {activityType !== RECREATION && (
                  <Form.Field>
                    <InputWithLabel
                      label={t('note')} //"Note"
                      placeholder={t('note')} //"Note"
                      type="textArea"
                      labelStyles={styles.labelStyles}
                      fluid={true}
                      value={activityData.Note}
                      handleChange={this.handleChange}
                    />
                  </Form.Field>
                )}
              </Form>
              {error.error && (
                <Message error>
                  <p>{error.message}</p>
                </Message>
              )}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={styles.backgroundGold}
              onClick={this.handleSetActivity}
              content={t('ok')} //"Ok"
            />
          </Modal.Actions>
        </Modal>
        <ModalListRestaurant
          open={openModalRestaurant}
          onClose={this.closeModal}
          name={t('restaurant')} //"Restaurant"
          location={destLabel}
          cityId={activity ? activity.Destination : ''}
          date={
            activity ? convertDateFormat(activity.DateTime, 'YYYY-MM-DD') : ''
          }
          pax={dataRestaurant.pax}
          setRestaurant={this.setActivity}
        />
        <ModalListExcursion
          open={openModalExcursion}
          onClose={this.closeModal}
          name={t('excursion')} //"Excursion"
          data={excursions}
          location={destLabel}
          setExcursion={this.setActivity}
          movements={movements}
          index={index}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    excursions: state.excursion.excursions,
    dailyProgram: state.customPackage.dailyProgram,
    movementList: state.customPackage.movementList,
    guests: state.customPackage.guests,
    cityInCountry: state.location.cityInCountry,
    HeadlineProgram: state.customPackage.HeadlineProgram,
    roomAllocation: state.customPackage.roomAllocation,
  };
};
ModalSetActivity.propTypes = {
  activityType: PropTypes.string,
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  getExcursionTypes: PropTypes.func,
  getExcursionByFilter: PropTypes.func,
  getRestaurantSpecializations: PropTypes.func,
  getRestaurantMenuClasses: PropTypes.func,
  getRestaurantByFilter: PropTypes.func,
  excursions: PropTypes.array,
  destination: PropTypes.string,
  activity: PropTypes.object,
  movements: PropTypes.array,
  index: PropTypes.number,
  day: PropTypes.number,
  dailyProgram: PropTypes.array,
  movementList: PropTypes.array,
  setDailyProgram: PropTypes.func,
  setDailyProgramAfterAdd: PropTypes.func,
  action: PropTypes.string,
  getExcursionById: PropTypes.func,
  getMenuById: PropTypes.func,
  guests: PropTypes.object,
  cityInCountry: PropTypes.array,
  t: PropTypes.func,
  HeadlineProgram: PropTypes.object,
  roomAllocation: PropTypes.object,
};
export default connect(
  mapStateToProps,
  {
    getExcursionTypes,
    getExcursionByFilter,
    getRestaurantByFilter,
    getRestaurantSpecializations,
    getRestaurantMenuClasses,
    setDailyProgram,
    getExcursionById,
    getMenuById,
  }
)(withTranslation()(ModalSetActivity));
