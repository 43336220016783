import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Grid, Image, Form } from 'semantic-ui-react';
import { noImage } from './../../../../assets/image';
import { ButtonCloseModal } from '../../../shared/components/common';
import styles from './../../styles';
import { useTranslation } from 'react-i18next';

const ModalAccountManagerProfile = props => {
  const { t } = useTranslation();
  let { profiles } = props;
  let { AccountManager } = profiles;

  const { openModal, handleClose, header } = props;

  return (
    <Modal inverted open={openModal} onClose={handleClose} size="tiny">
      <Modal.Header>
        {header}
        <ButtonCloseModal onClose={handleClose} />
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={1} />
            <Grid.Column width={6}>
              <Form>
                <Form.Field>
                  <Image
                    src={
                      AccountManager.ImageUrl
                        ? AccountManager.ImageUrl
                        : noImage
                    }
                    centered
                    verticalAlign="top"
                    style={styles.margin10px0px0px0px}
                  />
                </Form.Field>
                <Form.Group widths="equal" />
              </Form>
            </Grid.Column>

            <Grid.Column width={8} style={styles.margin10px0px0px0px}>
              <Form>
                <Form.Field>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="4">{t('name')}</Grid.Column>
                      <Grid.Column width="12">
                        {`: ${AccountManager.FirstName} ${
                          AccountManager.LastName
                        }`}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="4">{t('telephone')}</Grid.Column>
                      <Grid.Column width="12">
                        {`: ${AccountManager.PhoneNbr}`}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="4">{t('email')}</Grid.Column>
                      <Grid.Column width="12">
                        {`: ${AccountManager.Email}`}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              </Form>
            </Grid.Column>

            <Grid.Column width={1} />
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

ModalAccountManagerProfile.propTypes = {
  openModal: PropTypes.bool,
  handleClose: PropTypes.func,
  header: PropTypes.string,
  profiles: PropTypes.shape({
    firstName: PropTypes.string,
    username: PropTypes.string,
    phoneNbr: PropTypes.string,
    countryId: PropTypes.string,
    country: PropTypes.object,
    email: PropTypes.string,
    gender: PropTypes.string,
    imageUrl: PropTypes.string,
    accountManager: PropTypes.object,
  }),
  imgSrc: PropTypes.string,
  handleChange: PropTypes.func,
  image: PropTypes.string,
  t: PropTypes.func,
};
export default ModalAccountManagerProfile;
