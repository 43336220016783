const BLACK_COLOR = '#252525';
const GOLD_COLOR = '#e6ca6b';
const WHITE_COLOR = '#FFF';
const RED_COLOR = '#f44336';
const BLUE_COLOR = '#6b82e6';
// const GREY_COLOR = '#f2f2f2';

const style = {
  backgroundModal: {
    background: '#fbfbfb',
  },
  backgroundGold: {
    background: GOLD_COLOR,
  },
  backgroundGoldNoPadding: {
    background: GOLD_COLOR,
    padding: '0px',
  },
  backgroundCard: {
    background: WHITE_COLOR,
  },
  goldColor: {
    color: GOLD_COLOR,
    opacity: 1,
  },
  boldGoldColor: {
    color: GOLD_COLOR,
    fontWeight: 'bold',
  },
  textTransparent: {
    color: WHITE_COLOR,
    fontWeight: 'bold',
  },
  textBold: {
    fontWeight: 'bold',
  },
  justify: {
    textAlign: 'justify',
  },
  labelGold: {
    borderColor: '#b2993d',
    background: 'linear-gradient(to right, #fffd9b, #b2993d)',
    color: 'white',
    paddingBottom: '5px',
    paddingTop: '5px',
  },
  buttonGold: {
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
    // width: '100%',
  },
  buttonGoldNoPadding: {
    padding: '5px 0px',
    background: GOLD_COLOR,
    color: 'black',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
  },
  buttonWhite: {
    background: WHITE_COLOR,
    color: GOLD_COLOR,
    // width: '100%',
    borderStyle: 'solid',
    borderColor: GOLD_COLOR,
  },
  buttonClose: {
    background: WHITE_COLOR,
    color: RED_COLOR,
    padding: '0px',
    margin: '0px',
  },
  buttonTransparentBlack: {
    backgroundColor: 'transparent',
    color: BLACK_COLOR,
    padding: '0px',
    margin: '0px',
  },
  imageAttachment: {
    backgroundColor: BLACK_COLOR,
    opacity: 0.5,
    cursor: 'pointer',
  },
  inputWithPaddingNine: {
    width: '100%',
    padding: '9px',
  },
  inputStyles: {
    width: '100%',
    // padding: '9px',
    // borderBottom: '2px solid lightgrey',
  },
  noMarginTop: {
    marginTop: '0px',
  },
  noMargin: {
    margin: '0px',
  },
  noPaddingTop: { paddingTop: '0px' },
  noPadding: { padding: '0px' },
  paddingLeft25px: {
    paddingLeft: '25px',
  },
  padding10px: {
    padding: '10px',
  },
  buttonTransparent: {
    background: '#fff',
    color: GOLD_COLOR,
    padding: '0px',
  },
  label: {
    border: '1px solid #6b82e6',
    color: '#6b82e6',
    borderRadius: '50px',
  },
  segment: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '10px 30px 10px 30px',
    margin: '5px 0px 20px 0px',
  },
  segmentNoPadding: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    margin: '5px 0px 20px 0px',
  },
  segmentSearch: {
    border: 'none',
    boxShadow: '0px 4px 6px rgba(0,0,0,0.2)',
    padding: '1px 5px',
  },
  inputSelectionStyle: {
    border: 'none',
    // borderBottom: '1px solid grey',
    // borderRadius: '0px',
  },
  inputTextStyle: {
    padding: '.638500em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  inputDateStyle: {
    width: '100% !important',
  },
  inputTimeStyle: {
    padding: '.67857143em 1em',
    // border: 'none',
    // borderBottom: '1px solid grey',
  },
  borderBottom: {
    borderBottom: '1px solid grey',
  },
  segmentInside: {
    border: 'none',
    boxShadow: '0 2px 6px 1px rgba(0,0,0,0.2)',
    padding: '30px 30px',
    margin: '5px 0px 20px 0px',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  primaryColor: {
    color: '#000',
  },
  paddingLeft20: {
    paddingLeft: '20px',
  },
  paddingRight20: {
    paddingRight: '20px',
  },
  messageGold: {
    border: '1px solid rgba(230, 202, 107)',
    background: 'rgba(230, 202, 107,0.3)',
  },
  image: {
    margin: '10px 0px 0px 0px', //top right bottom left
  },
  profile: {
    margin: '10px 0px 0px 0px', //top right bottom left
  },
  buttonNegative: {
    background: RED_COLOR,
    color: WHITE_COLOR,
  },
  buttonPositive: {
    background: BLUE_COLOR,
    color: WHITE_COLOR,
  },
  marginTopBottom2em: {
    marginBottom: '2em',
    marginTop: '2em',
  },
  maxHeight250px: {
    maxHeight: '250px',
  },
  colorGreyFontItalic: {
    color: 'grey',
    fontStyle: 'italic',
  },
  marginTopBottom10: { margin: '10px 0px' },
  marginTop3: {
    marginTop: '3px',
  },
  marginTopMin10: { marginTop: '-10px' },
  width100percent: {
    width: '100%',
  },
  borderGrey: {
    borderColor: '#B9BBB6',
  },
  marginModalCancellationReason: {
    checkBoxMargin: { marginTop: '1em' },
    cancelButton: { color: '#ba000d' },
    goldButton: { color: '#e6ca6b' },
    marginTop0: { marginTop: '0px' },
    marginTop1: { marginTop: '1px' },
    marginBottom1: { marginBottom: '1px' },
    marginTopMin20: { marginTop: '-20px' },
    borderRadius25: { borderRadius: '25px' },
  },
  modalWidthCancel: {
    width: '450px',
  },
  buttonConfirm: {
    backgroundColor: 'white',
    color: 'black',
    border: '2px solid #e6ca6b',
    borderRadius: '25px',
  },
  buttonCancel: {
    backgroundColor: '#e6ca6b',
    color: 'black',
    border: '2px solid #e6ca6b',
    borderRadius: '25px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundTransparentColorBlack: {
    background: 'transparent',
    color: 'black',
    padding: '10px 0px 10px 0px',
    width: '100%',
  },
  noPaddingBackgroundTransparentColorBlue: {
    padding: '0px',
    background: 'transparent',
    color: 'blue',
  },
  buttonSelectPnr: {
    background: '#EEEEEE',
    margin: '15px',
    borderRadius: '5px',
  },
  buttonDeleteTicket: { background: 'white', color: 'red', padding: '5px' },
  paddingNull: {
    padding: '0px',
  },
  labelInputStyle: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '4px',
  },
  labelInputAmountStyle: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  inputAmountStyle: {
    borderBottom: '1px solid grey',
    marginTop: '12px',
  },
  buttonConFlightStyle: { margin: '10px', textAlign: 'center' },
  fontBold: { fontWeight: 'bold' },
  height400: { height: '400px' },
  height500: { height: '500px' },
  margin10Center: { margin: '10px', textAlign: 'center' },
  buttonDeleteStyle: { color: 'red', cursor: 'pointer' },
  redColor: { color: RED_COLOR },
  paddingTop3left5: { paddingTop: '3px', paddingLeft: '5px' },
  listIconStyle: {
    textAlign: 'center',
    padding: '10px 2px 10px 2px',
  },
  segmentAccommodationStyle: {
    border: 'none',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  marginBot3: { marginBottom: '3px' },
  paddingLeft10: { paddingLeft: '10px' },
  locationStyle: { paddingTop: '20px', paddingLeft: '10px' },
  noBorder: { border: 'none' },
  margin3: { margin: '3px 3px 0px 3px' },
};
export default style;
