import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const BookingDetailSegment = props => {
  const { t } = useTranslation();
  const {
    id,
    title,
    city,
    country,
    startDate,
    endDate,
    expiredOn,
    currency,
    totalPrice,
    packageType,
    commision,
    paymentTerm,
    totalPayable,
    totalPaid,
    createdBy,
    status,
  } = props;

  return (
    <>
      <Grid.Row columns="3">
        <Grid.Column
          style={{ ...styles.cardMark, ...styles.noPaddingBot }}
          verticalAlign="middle"
          width="8"
        >
          <Header as="h2">{t('bookingDetail')}</Header>
          {/* Booking Details */}
        </Grid.Column>
        <Grid.Column textAlign="right" verticalAlign="middle" width="8">
          <Header as="h3">{`${t('bookingCode')}: ${id}`}</Header>
          {/* Booking Code */}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <Grid columns="2">
            <Grid.Row>
              <Grid.Column>
                <Grid>
                  <Grid.Row style={styles.paddingTopBot5}>
                    <Grid.Column>{title}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={styles.paddingTopBot5}>
                    <Grid.Column>{`${city}, ${country}`}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={styles.paddingTopBot5}>
                    <Grid.Column>{`${startDate} - ${endDate}`}</Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={styles.paddingTopBot5}>
                    <Grid.Column>{`${t('status')}: ${status &&
                      status.value}`}</Grid.Column>
                  </Grid.Row>
                  {[
                    'Created',
                    'Created (On Hold)',
                    'DP confirm',
                    'SP confirm',
                    'In Payment',
                  ].indexOf(status && status.value) !== -1 ? (
                    <Grid.Row style={styles.paddingTopBot5}>
                      <Grid.Column>{`${t(
                        'expiryDate'
                      )}: ${expiredOn}`}</Grid.Column>
                      {/* Expiry Date */}
                    </Grid.Row>
                  ) : null}
                </Grid>
              </Grid.Column>
              <Grid.Column>
                <Grid>
                  <Grid.Row style={styles.paddingTopBot5}>
                    <Grid.Column>
                      {`${t(
                        'totalPrice'
                      )}: ${currency} ${totalPrice.toLocaleString('id')}`}
                    </Grid.Column>
                  </Grid.Row>
                  {packageType === 'Fixed' && (
                    <Grid.Row style={styles.paddingTopBot5}>
                      <Grid.Column>
                        {`${t(
                          'commission'
                        )}: ${currency} ${commision.toLocaleString('id')}`}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row style={styles.paddingTopBot5}>
                    <Grid.Column>
                      {`${t('paymentTerms')}: ${paymentTerm.Description} ${
                        paymentTerm.PaymentPercentage
                      }%`}
                    </Grid.Column>
                  </Grid.Row>
                  {packageType === 'Fixed' && (
                    <Grid.Row style={styles.paddingTopBot5}>
                      <Grid.Column>
                        {`${t(
                          'totalPayable'
                        )}: ${currency} ${totalPayable.toLocaleString('id')}`}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row style={styles.paddingTopBot5}>
                    <Grid.Column>
                      {`${t(
                        'totalPaid'
                      )}: ${currency} ${totalPaid.toLocaleString('id')}`}
                    </Grid.Column>
                  </Grid.Row>
                  {status && status.value === 'Quotation' && (
                    <Grid.Row style={styles.paddingTopBot5}>
                      <Grid.Column>{`${t(
                        'createdBy'
                      )} : ${createdBy}`}</Grid.Column>
                    </Grid.Row>
                  )}
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

BookingDetailSegment.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  expiredOn: PropTypes.string,
  currency: PropTypes.string,
  totalPrice: PropTypes.number,
  packageType: PropTypes.string,
  commision: PropTypes.number,
  paymentTerm: PropTypes.object,
  totalPayable: PropTypes.number,
  totalPaid: PropTypes.number,
  createdBy: PropTypes.string,
  status: PropTypes.object,
  t: PropTypes.func,
};

export default BookingDetailSegment;
