import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styles from './styles';
import { LanguageMenu } from './';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isHideQuotation } from '../../../../constant/hideFeature';

const customIcons = userName => (
  <div style={styles.paddingBottom12Point5}>
    <Icon className="User-Custom-Icon" />
    <strong>{userName}</strong>
  </div>
);
const LoginHeaderMenu = props => {
  const { t } = useTranslation();
  const { options, defaultValue, value, handleChange, handleLogout } = props;
  const userName = useSelector(state => state.userHeader.userInfo.Username);
  return (
    <Menu.Menu position="right" style={styles.backgroundBlack}>
      <Menu.Item
        as={Link}
        to="/package-list/ready/all"
        style={styles.itemMenu}
        active
      >
        <Icon className="Ready-Package-Icon" />
        <strong>Ready Package</strong>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/package-list/series/all"
        style={styles.itemMenu}
        active
      >
        <Icon className="Fixed-Package-Icon" />
        <strong>Series Package</strong>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/package-list/promo/all"
        style={styles.itemMenu}
        active
      >
        <Icon className="Promo-Package-Icon" />
        <strong>Promo Package</strong>
      </Menu.Item>
      <Dropdown item style={styles.dropdown} trigger={customIcons(userName)}>
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/my-profile/account">
            <Icon name="user" />
            {/* My Account */} {t('myAccount')}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/my-profile/company">
            <Icon name="building" />
            {/* My Company */} {t('myCompany')}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/my-profile/booking">
            <Icon name="file alternate" />
            {/* My Booking */} {t('myBooking')}
          </Dropdown.Item>
          {!isHideQuotation && (
            <Dropdown.Item as={Link} to="/my-profile/quotation">
              <Icon name="file alternate" />
              {/* My Quotation */} {t('myQuotation')}
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={handleLogout}>
            <Icon name="log out" />
            {/* Log Out */} {t('logOut')}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <LanguageMenu
        options={options}
        defaultValue={defaultValue}
        value={value}
        handleChange={handleChange}
      />
    </Menu.Menu>
  );
};

LoginHeaderMenu.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  handleLogout: PropTypes.func,
  userName: PropTypes.string,
  t: PropTypes.func,
};

export default LoginHeaderMenu;
